import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from 'chart.js'
import { equal, keys } from '../../../Utils/javascript'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
)

const options = {
  plugins: {
    legend: {
      position: 'bottom',
      ltr: true,
      maxSize: {
        height: 20,
      },
      labels: {
        boxWidth: 30,
        boxHeight: 1.5,
        pointStyle: 'line',
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      border: {
        display: false,
      },
      position: 'right',
      beginAtZero: true,
      min: 0,
      max: 100,
      stepSize: 10,
      ticks: {
        callback(value) {
          return `${value}%`
        },
      },
    },
  },
}

const DrawProcess = ({ scheduleDuration, drawProgressData }) => {
  const [chartData, setChartData] = useState({})

  useEffect(() => {
    if (keys(drawProgressData)?.length) {
      setChartData({
        ...drawProgressData,
        schedule_duration: drawProgressData?.schedule_duration?.filter(
          (item) =>
            !equal(item, '') &&
            !equal(item, null) &&
            (!equal(item, undefined) || equal(item, 0)),
        ),
        economic_completion_values:
          drawProgressData?.economic_completion_values?.filter(
            (item) =>
              !equal(item, '') &&
              !equal(item, null) &&
              (!equal(item, undefined) || equal(item, 0)),
          ),
        economic_months: drawProgressData?.economic_months?.filter(
          (item) =>
            !equal(item, '') && !equal(item, null) && !equal(item, undefined),
        ),
      })
    }
  }, [drawProgressData])

  const labels = [
    '0',
    ...(scheduleDuration && chartData?.economic_months?.length
      ? scheduleDuration !== chartData?.economic_months?.length &&
        scheduleDuration > chartData?.economic_months?.length
        ? Array.from(
            { length: +scheduleDuration },
            (_, index) => chartData?.economic_months[index] || index + 1,
          )
        : chartData.economic_months
      : scheduleDuration
      ? Array.from({ length: +scheduleDuration }, (_, index) => index + 1)
      : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']),
  ]

  const data = {
    labels,
    datasets: [
      {
        color: '#000',
        label: 'Economic Completion',
        fill: false,
        lineTension: 0.3,
        backgroundColor: 'rgb(4, 36, 64)',
        borderColor: 'rgb(4, 36, 64)',
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
        pointBorderColor: 'rgb(4, 36, 64)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgb(4, 36, 64)',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
        data: chartData?.economic_completion_values,
      },
      {
        color: '#fff',
        label: 'Standard Progression',
        fill: false,
        lineTension: 0.3,
        backgroundColor: '#afb0b3',
        borderColor: '#afb0b3',
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
        pointBorderColor: '#afb0b3',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#afb0b3',
        pointHoverBorderColor: '#fff',
        pointHoverBorderWidth: 5,
        pointRadius: 0,
        pointHitRadius: 5,
        data: chartData?.schedule_duration,
      },
    ],
  }
  return <Line options={options} data={data} />
}

export default DrawProcess
