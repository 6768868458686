import { Box, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { phaseTrackerFormFields } from '../../../Description/constructionScheduleReport.description'
import useForm from '../../../Hooks/useForm'
import {
  deletePhase,
  savePhase,
} from '../../../Redux/actions/constructionScheduleReport'

import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import { useStyles } from '../../../Styles/constructionScheduleReport.style'
import { entries, keys, ternary } from '../../../Utils/javascript'
import { renderFormFields } from '../../../Utils/renderFormFields'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import DKTForm from '../../../Shared/DKTForm'

const AddPhase = ({
  open = false,
  phaseToEdit,
  onClose,
  ocId,
  hasOnlyViewPermission,
  onSave = () => {},
}) => {
  const classes = useStyles()
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const { slug: projectId, reportId } = useParams()
  const theme = useTheme()

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    setErrors,
  } = useForm(phaseTrackerFormFields)
  const { isSavingPhaseTracker, isDeletingPhase, phaseTrackerError } =
    useSelector(({ constructionScheduleReport }) => constructionScheduleReport)
  const dispatch = useDispatch()

  useEffect(() => {
    resetForm()
    if (phaseToEdit) {
      entries(phaseToEdit).forEach(([fieldName, fieldValue]) => {
        setFieldValue(fieldName, fieldValue)
      })
    }
  }, [phaseToEdit, open])

  // Set errors on save
  useEffect(() => {
    if (phaseTrackerError)
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...phaseTrackerError,
      }))
  }, [phaseTrackerError, setErrors])

  // set isUnsavedData as true
  useEffect(() => {
    if (keys(phaseToEdit).length) {
      setIsUnSavedChanges(
        ternary(
          JSON.stringify(values) !== JSON.stringify(phaseToEdit),
          true,
          false,
        ),
      )
    } else if (keys(values).length) {
      setIsUnSavedChanges(true)
    }
  }, [phaseToEdit, values])
  const handleDeletePhase = () => {
    const onSuccess = () => {
      setIsDeleteNotificationOpen(false)
      onClose()
    }
    dispatch(
      deletePhase({
        phaseToDelete: phaseToEdit?.id,
        onSuccess,
        reportId,
        ocId,
        projectId,
      }),
    )
  }

  const handleSave = () => {
    const isFormValid = handleSubmit()
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
      return
    }
    setIsUnSavedChanges(false)
    const payload = keys(values).reduce((result, key) => {
      result[key] = values[key] === '' ? null : values[key]
      return result
    }, {})
    dispatch(
      savePhase({
        payload,
        reportId,
        projectId,
        ocId,
        phaseToEdit: phaseToEdit?.id,
        onSuccess: () => {
          onSave()
          onClose()
        },
      }),
    )
  }

  const actions = (
    <>
      {phaseToEdit && (
        <DKTButton
          variant="outlined"
          color="error"
          onClick={() => setIsDeleteNotificationOpen(true)}
          disabled={hasOnlyViewPermission}
        >
          Delete Phase
        </DKTButton>
      )}
      <DKTButton
        onClick={handleSave}
        disabled={hasOnlyViewPermission || isSavingPhaseTracker}
        sx={ternary(!phaseToEdit, { marginLeft: 'auto' }, {})}
      >
        {ternary(
          isSavingPhaseTracker,
          <DKTCircularProgress color={theme.palette.gray.dark} />,
          'Save',
        )}
      </DKTButton>
    </>
  )
  const customFormControlFields = ({ formControl }) => ({
    disabled: hasOnlyViewPermission || formControl.disabled,
  })

  const deleteNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => setIsDeleteNotificationOpen(false)}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleDeletePhase}
        disabled={isDeletingPhase}
      >
        {ternary(
          isDeletingPhase,
          <DKTCircularProgress color={theme.palette.gray.dark} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }
  const handleCloseAddPhase = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }

  return (
    <DKTDialog
      open={open}
      handleClose={handleCloseAddPhase}
      title={ternary(phaseToEdit, 'Edit Phase', 'Add Phase')}
      actions={actions}
    >
      <DKTForm autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          {renderFormFields({
            values,
            errors,
            formFields: phaseTrackerFormFields,
            handleChange,
            customFormControlFields,
          })}
        </Grid>
      </DKTForm>
      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={() => setIsDeleteNotificationOpen(false)}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Box className={classes.deleteNotificationContent}>
          <Typography variant="h5">Are you sure you want to delete?</Typography>
          <Typography variant="body1">
            You can&apos;t undo this action
          </Typography>
        </Box>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      {!hasOnlyViewPermission && (
        <>
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={handleSave}
          />
          <DKTReactRouterPrompt
            isDirty={isUnSavedChanges}
            onSave={handleSave}
          />
        </>
      )}
    </DKTDialog>
  )
}

export default AddPhase
