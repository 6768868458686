import { equal, ternary } from '../../Utils/javascript'
import {
  DELETE_CONSTRUCTION_SCHEDULE_REPORT_FAILED,
  DELETE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED,
  DELETE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS,
  DELETE_MILESTONE_TRACKER_FAILED,
  DELETE_MILESTONE_TRACKER_REQUESTED,
  DELETE_MILESTONE_TRACKER_SUCCESS,
  DELETE_PHASE_TRACKER_FAILED,
  DELETE_PHASE_TRACKER_REQUESTED,
  DELETE_PHASE_TRACKER_SUCCESS,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_FAILED,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_FAILED,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_REQUESTED,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_SUCCESS,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS,
  FETCH_MILESTONE_TRACKER_FAILED,
  FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA_REQUESTED,
  FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__FAILED,
  FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__SUCCESS,
  FETCH_MILESTONE_TRACKER_REQUESTED,
  FETCH_MILESTONE_TRACKER_SUCCESS,
  FETCH_PHASE_TRACKER_FAILED,
  FETCH_PHASE_TRACKER_LAST_REPORT_DATA_REQUESTED,
  FETCH_PHASE_TRACKER_LAST_REPORT_DATA_SUCCESS,
  FETCH_PHASE_TRACKER_LAST_REPORT_DATA_FAILED,
  FETCH_PHASE_TRACKER_REQUESTED,
  FETCH_PHASE_TRACKER_SUCCESS,
  IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_FAILED,
  IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_REQUESTED,
  IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_SUCCESS,
  SAVE_CONSTRUCTION_SCHEDULE_REPORT_FAILED,
  SAVE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED,
  SAVE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS,
  SAVE_MILESTONE_TRACKER_FAILED,
  SAVE_MILESTONE_TRACKER_REQUESTED,
  SAVE_MILESTONE_TRACKER_SUCCESS,
  SAVE_PHASE_TRACKER_FAILED,
  SAVE_PHASE_TRACKER_REQUESTED,
  SAVE_PHASE_TRACKER_SUCCESS,
  CLEAR_CONSTRUCTION_SCHEDULE_REPORT,
} from '../constants/constructionScheduleReport'

const initialState = {
  isSavingConstructionScheduleReport: false,
  isSavingPhaseTracker: false,
  isFetchingConstructionScheduleReport: true,
  isFetchingConstructionScheduleReportReferenceValues: true,
  constructionScheduleReport: {},
  phaseTracker: [],
  constructionScheduleReportReference: null,
  constructionScheduleReportReferenceError: null,
  constructionScheduleReportError: null,
  phaseTrackerError: null,
  isDeletingConstructionScheduleReport: false,
  isDeletingPhase: false,
  // Import from last report
  isImportingComments: false,
  importedComments: null,
  // Milestone tracker
  isSavingMilestoneTracker: false,
  isFetchingMilestoneTracker: false,
  isDeletingMilestoneTrackers: false,
  milestoneTracker: null,
  fetchMilestoneTrackerError: null,
  saveMilestoneTrackerError: null,
  deleteMilestoneError: null,
  // Milestone tracker last report data
  isFetchingMilestoneTrackerLastReportData: false,
  milestoneTrackerLastReportData: [],
  fetchMilestoneTrackerErrorLastReportData: null,
  // phase tracker last report data
  isFetchingPhaseTrackerLastReportData: false,
  fetchPhaseTrackerErrorLastReportData: null,
}

export const constructionScheduleReport = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    // Fetch construction schedule report reference values
    case FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_REQUESTED:
      return {
        ...state,
        isFetchingConstructionScheduleReportReferenceValues: true,
      }
    case FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_SUCCESS:
      return {
        ...state,
        isFetchingConstructionScheduleReportReferenceValues: false,
        constructionScheduleReportReference: action?.payload,
        constructionScheduleReportReferenceError: null,
      }
    case FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_FAILED:
      return {
        ...state,
        isFetchingConstructionScheduleReportReferenceValues: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        constructionScheduleReportReferenceError: action.payload,
      }
    // Fetch construction schedule report
    case FETCH_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED:
      return {
        ...state,
        isFetchingConstructionScheduleReport: true,
      }
    case FETCH_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS:
      return {
        ...state,
        isFetchingConstructionScheduleReport: false,
        constructionScheduleReport: action?.payload,
        constructionScheduleReportError: null,
      }
    case FETCH_CONSTRUCTION_SCHEDULE_REPORT_FAILED:
      return {
        ...state,
        isFetchingConstructionScheduleReport: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        constructionScheduleReportError: action.payload,
      }
    // Create construction schedule report
    case SAVE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED:
      return {
        ...state,
        isSavingConstructionScheduleReport: true,
        constructionScheduleReportError: null,
      }
    case SAVE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS:
      return {
        ...state,
        isSavingConstructionScheduleReport: false,
        constructionScheduleReport: action.payload,
        constructionScheduleReportError: null,
      }
    case SAVE_CONSTRUCTION_SCHEDULE_REPORT_FAILED:
      return {
        ...state,
        isSavingConstructionScheduleReport: false,
        constructionScheduleReportError: action.payload,
      }
    // Delete construction schedule report
    case DELETE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED:
      return {
        ...state,
        isDeletingConstructionScheduleReport: true,
      }
    case DELETE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS:
      return {
        ...state,
        constructionScheduleReport: action?.payload,
        isDeletingConstructionScheduleReport: false,
      }
    case DELETE_CONSTRUCTION_SCHEDULE_REPORT_FAILED:
      return {
        ...state,
        isDeletingConstructionScheduleReport: false,
      }

    // Import construction schedule report comments from last report
    case IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_REQUESTED:
      return {
        ...state,
        isImportingComments: true,
      }
    case IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_SUCCESS:
      return {
        ...state,
        importedComments: action.payload,
        isImportingComments: false,
      }
    case IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_FAILED:
      return {
        ...state,
        isImportingComments: false,
      }

    /* Phase tracker */
    // Fetch phase tracker
    case FETCH_PHASE_TRACKER_REQUESTED:
      return {
        ...state,
        isFetchingPhaseTracker: true,
      }
    case FETCH_PHASE_TRACKER_SUCCESS:
      return {
        ...state,
        isFetchingPhaseTracker: false,
        phaseTracker: action?.payload,
        phaseTrackerError: null,
      }
    case FETCH_PHASE_TRACKER_FAILED:
      return {
        ...state,
        isFetchingPhaseTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        phaseTrackerError: action.payload,
      }
    // Create phase tracker
    case SAVE_PHASE_TRACKER_REQUESTED:
      return {
        ...state,
        isSavingPhaseTracker: true,
        phaseTrackerError: null,
      }
    case SAVE_PHASE_TRACKER_SUCCESS:
      return {
        ...state,
        isSavingPhaseTracker: false,
        phaseTracker: action.payload,
        phaseTrackerError: null,
      }
    case SAVE_PHASE_TRACKER_FAILED:
      return {
        ...state,
        isSavingPhaseTracker: false,
        phaseTrackerError: action.payload,
      }
    // Delete phase tracker
    case DELETE_PHASE_TRACKER_REQUESTED:
      return {
        ...state,
        isDeletingPhase: true,
      }
    case DELETE_PHASE_TRACKER_SUCCESS:
      return {
        ...state,
        phaseTracker: action?.payload,
        isDeletingPhase: false,
      }
    case DELETE_PHASE_TRACKER_FAILED:
      return {
        ...state,
        isDeletingPhase: false,
      }

    /* Milestone tracker */
    // Fetch Milestone tracker
    case FETCH_MILESTONE_TRACKER_REQUESTED:
      return {
        ...state,
        isFetchingMilestoneTracker: true,
      }
    case FETCH_MILESTONE_TRACKER_SUCCESS:
      return {
        ...state,
        isFetchingMilestoneTracker: false,
        milestoneTracker: action?.payload,
      }
    case FETCH_MILESTONE_TRACKER_FAILED:
      return {
        ...state,
        isFetchingMilestoneTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchMilestoneTrackerError: action.payload,
      }
    // Save Milestone tracker
    case SAVE_MILESTONE_TRACKER_REQUESTED:
      return {
        ...state,
        isSavingMilestoneTracker: true,
        saveMilestoneTrackerError: null,
      }
    case SAVE_MILESTONE_TRACKER_SUCCESS:
      return {
        ...state,
        isSavingMilestoneTracker: false,
        milestoneTracker: action.payload,
        saveMilestoneTrackerError: null,
      }
    case SAVE_MILESTONE_TRACKER_FAILED:
      return {
        ...state,
        isSavingMilestoneTracker: false,
        saveMilestoneTrackerError: action.payload,
      }
    // Delete Milestone
    case DELETE_MILESTONE_TRACKER_REQUESTED:
      return {
        ...state,
        isDeletingMilestoneTrackers: true,
      }
    case DELETE_MILESTONE_TRACKER_SUCCESS:
      return {
        ...state,
        isDeletingMilestoneTrackers: false,
        milestoneTracker: action.payload,
      }
    case DELETE_MILESTONE_TRACKER_FAILED:
      return {
        ...state,
        isDeletingMilestoneTrackers: false,
        deleteMilestoneError: action.payload,
      }
    /* Milestone tracker last report data */
    // Fetch Milestone tracker
    case FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA_REQUESTED:
      return {
        ...state,
        isFetchingMilestoneTrackerLastReportData: true,
      }
    case FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__SUCCESS:
      return {
        ...state,
        isFetchingMilestoneTrackerLastReportData: false,
        milestoneTrackerLastReportData: action?.payload,
      }
    case FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__FAILED:
      return {
        ...state,
        isFetchingMilestoneTrackerLastReportData: false,
        fetchMilestoneTrackerErrorLastReportData: action.payload,
      }
    /* phase tracker last report data */

    case FETCH_PHASE_TRACKER_LAST_REPORT_DATA_REQUESTED:
      return {
        ...state,
        isFetchingPhaseTrackerLastReportData: true,
      }
    case FETCH_PHASE_TRACKER_LAST_REPORT_DATA_SUCCESS:
      return {
        ...state,
        isFetchingPhaseTrackerLastReportData: false,
        phaseTracker: [...state.phaseTracker, ...action.payload],
      }
    case FETCH_PHASE_TRACKER_LAST_REPORT_DATA_FAILED:
      return {
        ...state,
        isFetchingPhaseTrackerLastReportData: false,
        fetchPhaseTrackerErrorLastReportData: action.payload,
      }
    case CLEAR_CONSTRUCTION_SCHEDULE_REPORT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
