import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import { Grid, IconButton, Stack } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import React, { useEffect, useMemo, useState } from 'react'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { components } from 'react-select'
import {
  addNewSectionFormFields,
  addSectionFormFields,
} from '../../../Description/thirdPartyReports.description'
import useForm from '../../../Hooks/useForm'
import {
  createThirdPartyReportSection,
  updateThirdPartyReportSection,
} from '../../../Redux/actions/thirdPartyReport'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTSearchableSelect from '../../../Shared/DKTSearchableSelect'
import {
  checkIncludes,
  equal,
  lowerCase,
  ternary,
} from '../../../Utils/javascript'
import { renderFormFields } from '../../../Utils/renderFormFields'
import { showToast } from '../../../Utils/toastService'
import DKTForm from '../../../Shared/DKTForm'

const useStyles = makeStyles({
  addSectionDialog: {
    '& .MuiPaper-root': {
      height: '350px !important',
      overflow: 'hidden',
    },
  },
  reactSelect: {
    '& .react-select__menu': {
      overflow: 'hidden',
      height: 230,
      '& .react-select__menu-list': {
        height: 230,
        paddingTop: 0,
        '& .react-select__option': {
          marginTop: 5,
          '&:first-child': {
            paddingTop: 5,
          },
        },
      },
    },
  },
})

const MenuList =
  ({ props, children, ...args }) =>
  (handleOpenAddOptionDialog) =>
    (
      <components.MenuList {...props} {...args}>
        <DKTButton
          variant="text"
          startIcon={<AddCircleOutlineOutlinedIcon fontSize="small" />}
          sx={{
            color: '#000',
            justifyContent: 'start',
            position: 'sticky',
            top: 0,
            left: 0,
            backgroundColor: '#fff !important',
          }}
          fullWidth
          disableRipple
          onClick={handleOpenAddOptionDialog}
        >
          Add New
        </DKTButton>
        {children}
      </components.MenuList>
    )

const CustomOption = ({ children, handleOpenEditOptionDialog, ...rest }) => (
  <components.Option {...rest}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      {children}
      <IconButton
        sx={{
          height: 25,
          padding: 0,
          backgroundColor: ternary(rest.isSelected, 'white', 'inherit'),
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
        color="primary"
        onClick={() => handleOpenEditOptionDialog(rest?.data)}
      >
        <ModeEditIcon sx={{ height: 15 }} />
      </IconButton>
    </Stack>
  </components.Option>
)
const AddNewSection = ({
  open,
  onClose,
  ocId,
  handelSelectAddNewSections,
  editName,
}) => {
  const theme = useTheme()
  const { slug: projectId, reportId } = useParams()
  const dispatch = useDispatch()
  const { values, errors, handleChange, setFieldValue, resetForm } = useForm(
    addNewSectionFormFields,
  )
  const {
    isCreateThirdPartyReportSectionLoading,
    thirdPartyReportSectionList,
  } = useSelector(({ thirdPartyReport }) => thirdPartyReport)

  useEffect(() => {
    setFieldValue('name', ternary(editName?.label, editName?.label, ''))
  }, [editName, open])
  const data = thirdPartyReportSectionList?.find(
    (val) => val.id === editName?.value,
  )

  const handleAddNewSection = () => {
    if (values.name) {
      const isNotUniqueName = thirdPartyReportSectionList?.some((section) =>
        equal(lowerCase(section?.name), lowerCase(values.name?.trim())),
      )
      if (isNotUniqueName) {
        showToast(
          'This Section name is already exits, please try different Section name',
        )
        return
      }
      if (editName) {
        dispatch(
          updateThirdPartyReportSection({
            payload: {
              ...data,
              name: values?.name,
            },
            reportId,
            ocId,
            projectId,
            sectionId: editName?.value,
            onSuccess: (data) => {
              handelSelectAddNewSections(data)
              resetForm()
              onClose()
            },
          }),
        )
      } else {
        dispatch(
          createThirdPartyReportSection({
            name: values.name,
            reportId,
            ocId,
            projectId,
            onSuccess: (data) => {
              handelSelectAddNewSections(data)
              resetForm()
              onClose()
            },
          }),
        )
      }
    }
  }

  const addNewSectionsActions = (
    <DKTButton
      variant="contained"
      disableElevation
      onClick={handleAddNewSection}
      sx={{ marginLeft: 'auto', marginRight: '16px', marginTop: '40px' }}
      disabled={isCreateThirdPartyReportSectionLoading || !values.name}
    >
      {ternary(
        isCreateThirdPartyReportSectionLoading,
        <DKTCircularProgress color={theme.palette.gray.dark} />,
        ternary(editName, 'Update', 'Add'),
      )}
    </DKTButton>
  )
  return (
    <DKTDialog
      open={open}
      handleClose={() => {
        resetForm()
        onClose()
      }}
      title={ternary(editName?.label, 'Edit Section Name', 'Add New')}
      actions={addNewSectionsActions}
    >
      <DKTForm autoComplete="off">
        {renderFormFields({
          formFields: addNewSectionFormFields,
          values,
          errors,
          handleChange,
        })}
      </DKTForm>
    </DKTDialog>
  )
}

const AddThirdPartyReportsSection = ({
  open,
  onClose,
  addSection,
  ocId,
  thirdPartyReports,
}) => {
  const classes = useStyles()
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false)
  const [editName, setEditName] = useState(null)

  const { values, handleChange, resetForm, setFieldValue } =
    useForm(addSectionFormFields)

  const {
    isCreateThirdPartyReportSectionLoading,
    thirdPartyReportSectionList,
  } = useSelector(({ thirdPartyReport }) => thirdPartyReport)

  // Third Party Reports that are already created
  const createdThirdPartyReports = thirdPartyReports.map(
    ({ thirdPartyReportSection }) => thirdPartyReportSection.id,
  )

  const isOptionDisabled = (option) =>
    checkIncludes(option.value, createdThirdPartyReports)

  const sectionOptions = useMemo(
    () =>
      thirdPartyReportSectionList?.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [thirdPartyReportSectionList],
  )

  const handelSelectAddNewSections = (data) => {
    const { id, name } = data
    setFieldValue('name', { label: name, value: id })
  }

  const openAddNewModal = () => setIsAddNewModalOpen(true)
  const closeAddNewModal = () => setIsAddNewModalOpen(false)

  const handleOpenEditOptionDialog = (data) => {
    setEditName(data)
    openAddNewModal()
  }
  const handleOpenAddOptionDialog = () => {
    setEditName(null)
    openAddNewModal()
  }

  const addSectionsAction = (
    <DKTButton
      variant="contained"
      disableElevation
      onClick={() =>
        addSection(
          { name: values.name.label, id: values.name.value },
          resetForm,
        )
      }
      sx={{ marginLeft: 'auto', marginRight: '16px', marginTop: '160px' }}
      disabled={isCreateThirdPartyReportSectionLoading || !values.name}
    >
      Save
    </DKTButton>
  )

  return (
    <DKTDialog
      open={open}
      handleClose={() => {
        onClose()
        resetForm()
      }}
      className={classes.addSectionDialog}
      title="Add Section"
      actions={addSectionsAction}
    >
      <Grid container>
        <Grid item xs={12}>
          <DKTSearchableSelect
            label="Select Section"
            options={sectionOptions}
            placeholder="Select"
            value={values.name}
            onChange={(newValue) =>
              handleChange({
                target: {
                  name: 'name',
                  value: newValue,
                },
              })
            }
            className={classes.reactSelect}
            isClearable
            classNamePrefix="react-select"
            isOptionDisabled={isOptionDisabled}
            components={{
              MenuList: ({ props, ...args }) =>
                MenuList({ props, ...args })(handleOpenAddOptionDialog),
              Option: (args) =>
                CustomOption({ handleOpenEditOptionDialog, ...args }),
            }}
          />
        </Grid>
      </Grid>
      <AddNewSection
        open={isAddNewModalOpen}
        onClose={closeAddNewModal}
        ocId={ocId}
        handelSelectAddNewSections={handelSelectAddNewSections}
        editName={editName}
      />
    </DKTDialog>
  )
}

export default AddThirdPartyReportsSection
