import axios from 'axios'

import { defaultType, successStatus } from './constant'
import { checkIncludes, equal, ternary } from './javascript'
import { loadStateFn, removeStateFn } from './localStorage'
import { showToast } from './toastService'
import { replaceLocation } from './window'
import { getRefreshAccessToken } from '../Redux/actions/refreshAccessToken'

const api = async ({
  method,
  endPoint,
  data,
  showToastMessage = false,
  toastMessage = '',
  urlencoded = false,
  responseType = '',
  cancelToken = '',
  onUploadProgress = () => {},
  urlEndPoint = '',
  attachAccessToken = true,
  refreshAccessToken = true,
}) => {
  let accessToken = JSON.parse(loadStateFn('auth'))?.accessToken

  if (refreshAccessToken && accessToken) {
    await getRefreshAccessToken(accessToken)
    accessToken = JSON.parse(loadStateFn('auth'))?.accessToken
  }

  return new Promise((resolve) => {
    const headers = {
      'Content-Type': ternary(
        urlencoded,
        'application/x-www-form-urlencoded',
        defaultType,
      ),
    }
    if (accessToken && attachAccessToken) {
      headers.Authorization = `Bearer ${accessToken}`
    }

    axios({
      method,
      url: urlEndPoint || `${process.env.REACT_APP_API_URL}/${endPoint}`,
      data,
      headers,
      responseType,
      cancelToken,
      onUploadProgress,
    })
      .then((response) => {
        if (showToastMessage && successStatus.includes(response.status)) {
          showToast(
            ternary(!toastMessage, response.data.detail, toastMessage),
            'success',
          )
        }
        return resolve({
          data: response.data,
          status: checkIncludes(response.status, successStatus),
        })
      })
      .catch((error) => {
        const hasSingleErrorMessage = !!error?.response?.data?.detail
        const isInternalServerError = equal(error?.response?.data?.code, 500)
        if (
          showToastMessage &&
          (hasSingleErrorMessage || isInternalServerError)
        ) {
          showToast(
            ternary(
              isInternalServerError || equal(error?.response?.data?.code, 500),
              'Server not responding',
              error?.response?.data?.detail,
            ),
            'error',
          )
        }

        if (equal(error?.response?.status, 401)) {
          removeStateFn('auth')
          removeStateFn('company-id')
          if (window.location.pathname !== '/login') {
            replaceLocation('/login')
          }
        }

        if (equal(error?.response?.status, 403)) {
          if (window.location.pathname !== '/login') {
            replaceLocation('/dashboard')
          }
        }

        return resolve({
          status: false,
          error,
          statusCode: error?.response?.status,
        })
      })
  })
}
export default api
