// Fetch construction summary data
export const FETCH_CONSTRUCTION_SUMMARY_REQUESTED =
  'FETCH_CONSTRUCTION_SUMMARY_REQUESTED'
export const FETCH_CONSTRUCTION_SUMMARY_SUCCESS =
  'FETCH_CONSTRUCTION_SUMMARY_SUCCESS'
export const FETCH_CONSTRUCTION_SUMMARY_FAILED =
  'FETCH_CONSTRUCTION_SUMMARY_FAILED'

// Create construction summary data
export const CREATE_CONSTRUCTION_SUMMARY_REQUESTED =
  'CREATE_CONSTRUCTION_SUMMARY_REQUESTED'
export const CREATE_CONSTRUCTION_SUMMARY_SUCCESS =
  'CREATE_CONSTRUCTION_SUMMARY_SUCCESS'
export const CREATE_CONSTRUCTION_SUMMARY_FAILED =
  'CREATE_CONSTRUCTION_SUMMARY_FAILED'

// Update construction summary data
export const UPDATE_CONSTRUCTION_SUMMARY_REQUESTED =
  'UPDATE_CONSTRUCTION_SUMMARY_REQUESTED'
export const UPDATE_CONSTRUCTION_SUMMARY_SUCCESS =
  'UPDATE_CONSTRUCTION_SUMMARY_SUCCESS'
export const UPDATE_CONSTRUCTION_SUMMARY_FAILED =
  'UPDATE_CONSTRUCTION_SUMMARY_FAILED'

// Import from last report
export const IMPORT_FROM_LAST_REPORT_REQUESTED =
  'IMPORT_FROM_LAST_REPORT_REQUESTED'
export const IMPORT_FROM_LAST_REPORT_SUCCESS = 'IMPORT_FROM_LAST_REPORT_SUCCESS'
export const IMPORT_FROM_LAST_REPORT_FAILED = 'IMPORT_FROM_LAST_REPORT_FAILED'

// import phase based last report comments
export const IMPORT_PHASE_BASED_COMMENTS_REQUESTED =
  'IMPORT_PHASE_BASED_COMMENTS_REQUESTED'
export const IMPORT_PHASE_BASED_COMMENTS_SUCCESS =
  'IMPORT_PHASE_BASED_COMMENTS_SUCCESS'
export const IMPORT_PHASE_BASED_COMMENTS_FAILED =
  'IMPORT_PHASE_BASED_COMMENTS_FAILED'

// clear construction summary
export const CLEAR_CONSTRUCTION_SUMMARY = 'CLEAR_CONSTRUCTION_SUMMARY'

// Labor tracker
export const FETCH_MANPOWER_TRACKER_REQUESTED =
  'FETCH_MANPOWER_TRACKER_REQUESTED'
export const FETCH_MANPOWER_TRACKER_SUCCESS = 'FETCH_MANPOWER_TRACKER_SUCCESS'
export const FETCH_MANPOWER_TRACKER_FAILED = 'FETCH_MANPOWER_TRACKER_FAILED'

export const CREATE_UPDATE_MANPOWER_TRACKER_REQUESTED =
  'CREATE_UPDATE_MANPOWER_TRACKER_REQUESTED'
export const CREATE_UPDATE_MANPOWER_TRACKER_SUCCESS =
  'CREATE_UPDATE_MANPOWER_TRACKER_SUCCESS'
export const CREATE_UPDATE_MANPOWER_TRACKER_FAILED =
  'CREATE_UPDATE_MANPOWER_TRACKER_FAILED'

// Delete Labor Tracker
export const DELETE_MANPOWER_TRACKER_REQUESTED =
  'DELETE_MANPOWER_TRACKER_REQUESTED'
export const DELETE_MANPOWER_TRACKER_SUCCESS = 'DELETE_MANPOWER_TRACKER_SUCCESS'
export const DELETE_MANPOWER_TRACKER_FAILED = 'DELETE_MANPOWER_TRACKER_FAILED'
