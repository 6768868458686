import { Box, Grid, Typography, IconButton } from '@mui/material'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Delete } from '@mui/icons-material'
import DKTForm from '../../../Shared/DKTForm'
import FormSectionDivider from '../../../Shared/FormSectionDivider'
import {
  entries,
  equal,
  isArray,
  isEmptyString,
  keys,
  ternary,
} from '../../../Utils/javascript'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTInput from '../../../Shared/DKTInput'
import { keyPersonnelFormFields } from '../../../Description/companyInformation.description'
import DKTButton from '../../../Shared/DKTButton'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import useForm from '../../../Hooks/useForm'
import { fetchCompanyPersonnel } from '../../../Redux/actions/companyPersonnel'
import {
  addUpdateKeyPersonnel,
  getCompanyKeyPersonnel,
} from '../../../Redux/actions/companyInformation'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTDeleteNotificationModal from '../../../Shared/DKTDeleteNotificationModal'
import DKTTooltip from '../../../Shared/DKTTooltip'
import { contactForRFPText } from '../../../Utils/constant'

const KeyPersonnel = () => {
  const [dynamicFields, setDynamicFields] = useState([])
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] = useState({
    isOpen: false,
  })

  const {
    values,
    setValues,
    setDummyData,
    errors,
    handleChange,
    isDirty,
    setIsDirty,
    handleSubmit,
  } = useForm(keyPersonnelFormFields.primaryContact)
  const {
    companyData = [],
    keyPersonnel,
    isKeyPersonnelSaveLoading,
  } = useSelector(({ company }) => company)
  const dispatch = useDispatch()
  const { companyPersonnelList } = useSelector(
    (state) => state.companyPersonnel,
  )
  const isDisabledAddContact =
    !dynamicOptions?.personnelName?.length ||
    dynamicOptions?.personnelName?.every(({ disabled }) =>
      equal(disabled, true),
    )

  const personnelNameMapping = () =>
    entries(values)
      ?.filter(([key]) => key.startsWith('personnelName'))
      .reduce((acc, [key, value]) => {
        const index = key.split('.')[1]
        if (!acc[index]) acc[index] = []
        acc[index].push(value)
        return acc
      }, [])
      .flat()

  useEffect(() => {
    dispatch(fetchCompanyPersonnel())
    return () => {
      setDynamicFields([])
      setValues({})
      setDummyData({})
    }
  }, [])

  useEffect(() => {
    if (companyData[0]?.id) dispatch(getCompanyKeyPersonnel(companyData[0]?.id))
  }, [companyData[0]?.id])

  useEffect(() => {
    const dynamicOptionArray = (arr) =>
      arr?.map((item) => {
        const personnelNames = personnelNameMapping()

        const isAlreadyUsedId = personnelNames?.find((id) =>
          equal(+id, +item.id),
        )
        return {
          value: `${item?.id}`,
          label: `${item?.firstName} ${item?.lastName}`,
          disabled: !!isAlreadyUsedId,
        }
      })

    const personnelName = dynamicOptionArray(companyPersonnelList)

    setDynamicOptions((prevOptions) => ({
      ...prevOptions,
      personnelName: personnelName?.length
        ? personnelName
        : [{ label: 'No data available', disabled: true }],
    }))
  }, [companyPersonnelList, values])

  useEffect(() => {
    if (keys(keyPersonnel)?.length) {
      let cloneFields = []
      let tempValue = {}
      if (keyPersonnel?.personnelName?.length) {
        keyPersonnel.personnelName.forEach((loc, index) => {
          const updatedLocation = addPersonalNameField(index, cloneFields)
          cloneFields = [...updatedLocation]
          tempValue = {
            ...tempValue,
            [`personnelName.${index}`]: loc?.toString(),
          }
        })
      } else {
        const updatedLocation = addPersonalNameField(0, cloneFields)
        cloneFields = [...updatedLocation]
      }
      setDynamicFields([...cloneFields])
      setValues({
        ...keyPersonnel,
        ...tempValue,
      })
      setDummyData({
        ...keyPersonnel,
        ...tempValue,
      })
    }
  }, [keyPersonnel])

  const handleOpenCloseDeleteNotification = (isOpen = false, id = null) =>
    setIsDeleteNotificationOpen({ isOpen, id })

  const addPersonalNameField = (index, locationData = false) => {
    const location = locationData || [...dynamicFields]
    location[index] = {
      data: keyPersonnelFormFields?.contactForRFPs,
      id: index,
    }
    if (!locationData) setDynamicFields([...location])
    return [...location]
  }

  const handleDelete = () => {
    const cloneValues = { ...values }
    delete cloneValues[`personnelName.${isDeleteNotificationOpen?.id}`]
    setValues(cloneValues)
    setDynamicFields((prev) =>
      prev.filter(({ id }) => !equal(id, isDeleteNotificationOpen?.id)),
    )
    setIsDirty(true)
    handleOpenCloseDeleteNotification()
  }

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          { isRequired, isDynamicOptions, defaultValue, options, ...formField },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <>
            <Grid key={index} item xs={formField.xs ?? 12}>
              <FormControl
                {...formField}
                options={ternary(
                  isDynamicOptions,
                  dynamicOptions.personnelName || [],
                  options,
                )}
                id={name}
                name={name}
                value={(values && values[name]) || defaultValue || ''}
                onChange={handleChange}
                error={errors[name]}
              />
            </Grid>
            {makeFieldNameUnique && name.startsWith('personnelName') && (
              <Grid item xs={2}>
                <IconButton
                  color="error"
                  onClick={() => handleOpenCloseDeleteNotification(true, id)}
                  sx={{ marginTop: '16px' }}
                >
                  <Delete />
                </IconButton>
              </Grid>
            )}
          </>
        )
      },
    )

  const renderFormFields = (formFields) => (
    <Grid container spacing={2} alignItems="center">
      {isArray(formFields)
        ? formFields?.map((formField) =>
            mapFormFields({
              formFields: formField?.data,
              makeFieldNameUnique: true,
              id: formField?.id,
            }),
          )
        : mapFormFields({ formFields })}
    </Grid>
  )

  const handleSave = () => {
    const isFormValid = handleSubmit({
      flag: keyPersonnelFormFields.primaryContact,
      comp: 'Company Information',
    })
    if (isFormValid) {
      const personnelName = personnelNameMapping()
      const payload = { personnelName, id: companyData[0]?.id }
      keys(values)?.forEach((key) => {
        if (!key.startsWith('personnelName')) {
          payload[key] = ternary(isEmptyString(values?.[key]), '', values[key])
        }
      })

      const onSuccess = () => {
        setIsDirty(false)
      }
      dispatch(addUpdateKeyPersonnel(payload, companyData[0]?.id, onSuccess))
    } else {
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }
  return (
    <>
      <Grid container spacing={3.5}>
        <Grid item xs={4}>
          <FormSectionDivider sectionTitle="Primary Contact" />
          <Box sx={{ marginTop: '12px' }}>
            <DKTForm autoComplete="off">
              {renderFormFields(keyPersonnelFormFields.primaryContact)}
            </DKTForm>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <FormSectionDivider sectionTitle="Contact for RFPs" />
          <Box sx={{ marginTop: '12px' }}>
            <DKTForm autoComplete="off">
              {renderFormFields(dynamicFields)}
            </DKTForm>
          </Box>
          <DKTTooltip
            title={`${ternary(isDisabledAddContact, contactForRFPText, '')}`}
          >
            <span style={{ cursor: 'pointer' }}>
              <DKTButton
                variant="text"
                color="primary"
                sx={{ textTransform: 'initial', px: 1, mt: 4.5 }}
                startIcon={<CircledPlusIcon disabled={isDisabledAddContact} />}
                onClick={() => addPersonalNameField(dynamicFields.length)}
                disabled={isDisabledAddContact}
              >
                <Typography>Add Contact for RFP</Typography>
              </DKTButton>
            </span>
          </DKTTooltip>
        </Grid>
      </Grid>
      <DKTButton
        onClick={handleSave}
        disabled={isKeyPersonnelSaveLoading}
        variant="outlined"
        sx={{ marginTop: '30px' }}
      >
        {ternary(isKeyPersonnelSaveLoading, 'Saving...', 'Save')}
      </DKTButton>
      <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
      <DKTDeleteNotificationModal
        open={isDeleteNotificationOpen.isOpen}
        handleClose={() => handleOpenCloseDeleteNotification(false)}
        handleDelete={handleDelete}
      />
    </>
  )
}

export default KeyPersonnel
