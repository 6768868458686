import { SET_ON_SAVE_STATUS } from '../constants/confirmation'

export const updateOnSaveStatus = (payload) => ({
  type: SET_ON_SAVE_STATUS,
  payload,
})

export const updateSaveStatusAction = (data, error) => (dispatch) => {
  if (data) {
    dispatch(updateOnSaveStatus({ save: true }))
  } else if (error) {
    dispatch(updateOnSaveStatus({ cancel: true }))
  }
}
