import { InputAdornment } from '@mui/material'
const percentageAdornment = <InputAdornment position="end">%</InputAdornment>

export const formFields = {
  OwnerContractorFields: {
    aiaFormAgreement: {
      type: 'select',
      label: 'AIA Form Agreement',
      placeholder: 'Select',
      displayEmpty: true,
    },
    contractType: {
      type: 'select',
      label: 'Contract Type',
      placeholder: 'Select',
      displayEmpty: true,
    },
    contractDate: {
      type: 'date',
      clearable: true,
      label: 'Owner-Contractor Contract Date',
    },
    owner: {
      type: 'text',
      label: 'Owner',
    },
    contractor: {
      type: 'select',
      label: 'Contractor',
      displayEmpty: true,
      placeholder: 'Select',
    },
    contractTime: {
      type: 'text',
      label: 'Contract Time ',
      xs: 6,
    },
    durationType: {
      type: 'select',
      label: 'Duration Type',
      xs: 6,
      placeholder: 'Select',
      displayEmpty: true,
    },
    contractCommencement: {
      type: 'date',
      clearable: true,
      label: 'Contract Commencement ',
      xs: 6,
    },
    contractCompletion: {
      type: 'date',
      clearable: true,
      label: 'Contract Completion',
      isRequired: true,
      xs: 6,
    },
    contractSum: {
      type: 'text',
      label: 'Contract Sum',
      isRequired: true,
      isCurrency: true,
    },
    liquidatedDamages: {
      type: 'select',
      label: 'Liquidated Damages',
      placeholder: 'Select',
      displayEmpty: true,
    },
    retainageWork: {
      type: 'text',
      label: 'Retainage-Work % ',
      xs: 6,
      endAdornment: percentageAdornment,
    },
    retainageMaterials: {
      type: 'text',
      label: 'Retainage-Materials % ',
      xs: 6,
      endAdornment: percentageAdornment,
    },
  },
}
