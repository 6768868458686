import React from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { equal } from '../Utils/javascript'

const DKTCard = ({
  className,
  title,
  count,
  numberLineHeight = '48px',
  colorText = 'secondary.text',
  onClick,
  countColor,
}) => (
  <Card className={className} onClick={onClick}>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color={colorText} gutterBottom>
        {title}
      </Typography>
      <Box mt="auto">
        <Typography
          sx={{
            fontSize: countColor ? 25 : 46,
            color:
              countColor ||
              `${equal(title, 'UnPublished Reports') ? 'red' : '#1564FF'}`,
            fontWeight: 500,
            lineHeight: numberLineHeight,
          }}
        >
          {count}
        </Typography>
      </Box>
    </CardContent>
  </Card>
)

export default DKTCard
