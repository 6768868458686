import {
  FETCH_PROJECT_DASHBOARD_REQUESTED,
  FETCH_PROJECT_DASHBOARD_SUCCESS,
  FETCH_PROJECT_DASHBOARD_FAILED,
  UPLOAD_IMAGE_REQUESTED,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILED,
  CLEAR_PROJECT_DASHBOARD_DATA,
} from '../constants/projectDashboard'

const initialState = {
  isLoadingProjectDashboard: false,
  projectDashboard: {},
  fetchProjectDashboardError: null,
  isLoadingUploadImage: false,
  UploadImageError: null,
}

export const projectDashboard = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PROJECT_DASHBOARD_REQUESTED:
      return {
        ...state,
        isLoadingProjectDashboard: true,
      }
    case FETCH_PROJECT_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoadingProjectDashboard: false,
        projectDashboard: action.payload,
      }
    case FETCH_PROJECT_DASHBOARD_FAILED:
      return {
        ...state,
        isLoadingProjectDashboard: false,
        fetchProjectDashboardError: action.payload,
      }
    case UPLOAD_IMAGE_REQUESTED:
      return {
        ...state,
        isLoadingUploadImage: true,
      }
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isLoadingUploadImage: false,
        projectDashboard: {
          ...state.projectDashboard,
          project_dashboard_attachment_data: action.payload,
        },
      }
    case UPLOAD_IMAGE_FAILED:
      return {
        ...state,
        isLoadingUploadImage: false,
        UploadImageError: action.payload,
      }
    case CLEAR_PROJECT_DASHBOARD_DATA:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
