import { Box, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import { optionFields } from '../../../Description/thirdPartyReports.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import { getReportChoices } from '../../../Redux/actions/reportChoices'
import {
  createThirdPartyReport,
  getThirdPartyReportList,
  getThirdPartyReportSectionList,
  updateThirdPartyReport,
} from '../../../Redux/actions/thirdPartyReport'
import DKTButton from '../../../Shared/DKTButton'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { useStyles } from '../../../Styles/permitsEntitlements.style'
import { equal, isArray, ternary } from '../../../Utils/javascript'
import AddThirdPartyReportsSection from './AddThirdPartyReportsSection'
import ThirdPartyReport from './ThirdPartyReport'
import { CLEAR_THIRD_PARTY_REPORT_LIST } from '../../../Redux/constants/thirdPartyReport'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import usePermission from '../../../Hooks/usePermission'

const ThirdPartyReports = () => {
  const [ownerContractorId, setOwnerContractorId] = useState(null)
  const [ownerContractorUniqueCode, setOwnerContractorUniqueCode] =
    useState(null)
  const [data, setData] = useState([])
  const [isAddSectionModalOpen, setIsAddSectionModalOpen] = useState(false)
  const [isSaveWarningOpen, setIsSaveWarningOpen] = useState(false)
  const [tempOcData, setTempOcData] = useState({})

  const { slug } = useParams()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { slug: projectId, reportId } = useParams()
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)
  const { values, setFieldValue, setErrors, isDirty, setIsDirty, resetForm } =
    useForm()
  const { hasOnlyViewPermission } = usePermission()
  const {
    thirdPartyReportList,
    isThirdPartyReportLoading,
    isThirdPartyReportDeleting,
    isCreateAndUpdateThirdPartyReportLoading,
    isThirdPartyReportSectionLoading,
  } = useSelector(({ thirdPartyReport }) => thirdPartyReport)

  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionFields?.join(','),
        projectId: slug,
      }),
    )
  }, [dispatch, slug])

  useEffect(() => {
    if (reportId && ownerContractorId && projectId) {
      dispatch(
        getThirdPartyReportSectionList(reportId, ownerContractorId, projectId),
      )
      dispatch(getThirdPartyReportList(reportId, ownerContractorId, projectId))
    }
  }, [dispatch, ownerContractorId])

  useEffect(() => {
    setData(thirdPartyReportList)
  }, [thirdPartyReportList])

  useEffect(() => {
    if (data?.length && data?.length > thirdPartyReportList?.length) {
      setIsDirty(true)
    }
  }, [data])

  useEffect(() => {
    let activeContractor = null
    if (isArray(choices?.ownerContractor) && choices?.ownerContractor?.length) {
      activeContractor = choices?.ownerContractor[0]
    }
    setOwnerContractorId(activeContractor?.id)
    setOwnerContractorUniqueCode(activeContractor?.uniqueCode)
  }, [choices?.ownerContractor[0]?.id])

  useEffect(() => {
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    setFieldValue('ownerContractorDescription', activeContractorDescription)
  }, [ownerContractorId])
  // set oc based on form isUnsaved
  useEffect(() => {
    if (tempOcData?.id) {
      if (isDirty) {
        setIsSaveWarningOpen(true)
      } else {
        resetForm()
        setTimeout(() => {
          setOwnerContractorId(tempOcData?.id)
          setOwnerContractorUniqueCode(tempOcData?.uniqueCode)
        }, 500)
        setErrors({})
      }
    }
  }, [tempOcData])

  useEffect(
    () => () => {
      dispatch({ type: CLEAR_THIRD_PARTY_REPORT_LIST })
    },
    [],
  )

  const openAddSectionModal = () => setIsAddSectionModalOpen(true)
  const closeAddSectionModal = () => setIsAddSectionModalOpen(false)
  const addSection = (section, callback) => {
    const isReportAlreadyCreated = data.some((thirdPartyReport) =>
      equal(thirdPartyReport.thirdPartyReportSection.id, section.id),
    )
    if (!isReportAlreadyCreated) {
      setData((prevData) => [
        ...prevData,
        {
          uniqueId: new Date().valueOf(),
          description: '',
          report: reportId,
          ownerContractor: ownerContractorId,
          thirdPartyReportSection: section,
        },
      ])
    }
    callback()
    closeAddSectionModal()
  }

  const handleSave = () => {
    setIsDirty(false)
    const isAlreadyCreated =
      reportData.formCreatedList[ownerContractorUniqueCode]?.thirdPartyReport
    const payloadData = data.map(
      ({ thirdPartyReportSection, ...rest }, index) => ({
        ...rest,
        thirdPartyReportSection: thirdPartyReportSection.id,
        sortOrder: index,
      }),
    )
    const params = [payloadData, reportId, ownerContractorId, projectId]
    if (isAlreadyCreated) {
      dispatch(updateThirdPartyReport(...params))
      return
    }
    dispatch(createThirdPartyReport(...params))
  }

  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          gap={2}
          // className={classes.rightButton}
        >
          <DKTButtonSelect
            options={actionList}
            disabled={
              hasOnlyViewPermission ||
              isThirdPartyReportLoading ||
              isThirdPartyReportDeleting ||
              isCreateAndUpdateThirdPartyReportLoading ||
              isThirdPartyReportSectionLoading ||
              true
            }
          />
          <DKTButton
            className={classes.rightButton}
            onClick={handleSave}
            disabled={
              hasOnlyViewPermission ||
              isThirdPartyReportLoading ||
              isThirdPartyReportDeleting ||
              isCreateAndUpdateThirdPartyReportLoading ||
              isThirdPartyReportSectionLoading
            }
          >
            {ternary(
              isCreateAndUpdateThirdPartyReportLoading,
              'Saving...',
              'Save',
            )}
          </DKTButton>
        </Stack>
      </Box>
    </>
  )
  const actionList = [{ label: 'Delete', onClick: () => null }]

  const onDragEnd = (result) => {
    if (result.destination && result.source) {
      setData((prevData) => {
        const clonedData = [...prevData]
        clonedData.splice(result.source.index, 1)
        clonedData.splice(
          result.destination.index,
          0,
          prevData[result.source.index],
        )

        return clonedData
      })
    }
  }
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setOwnerContractorId(tempOcData?.id)
    setOwnerContractorUniqueCode(tempOcData?.uniqueCode)
    setIsDirty(false)
    setErrors({})
    setTempOcData({})
  }

  return (
    <div>
      <InnerLayout
        contentTitle="Third Party Reports"
        actions={actions}
        maxWidth="md"
        isShowMenu
      >
        {equal(isThirdPartyReportLoading, true) ||
        equal(isThirdPartyReportSectionLoading, true) ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <DKTCircularProgress />
          </Stack>
        ) : equal(isThirdPartyReportLoading, 'FAILED') ||
          equal(isThirdPartyReportSectionLoading, 'FAILED') ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching Third Party Reports data.
              Please try contacting the admin or refreshing this page.
            </Typography>
          </Stack>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} xl={9}>
                <Grid
                  container
                  columnSpacing={10}
                  columnGap={{ sm: 0, xl: 3 }}
                  rowSpacing={3}
                >
                  <Grid item lg={6} sm={6}>
                    <Grid container alignItems="end">
                      <Grid item xs={2}>
                        <DKTButton
                          variant="text"
                          color="primary"
                          sx={{
                            textTransform: 'initial',
                            px: 1,
                            ml: '10px',
                            whiteSpace: 'nowrap',
                          }}
                          startIcon={
                            <CircledPlusIcon disabled={hasOnlyViewPermission} />
                          }
                          onClick={openAddSectionModal}
                          disabled={hasOnlyViewPermission}
                        >
                          <Typography>Third Party Report Section</Typography>
                        </DKTButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={10} sm={12}>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="thirdPartyReports">
                        {(provided) => (
                          <Grid
                            ref={provided.innerRef}
                            container
                            spacing={2}
                            {...provided.droppableProps}
                          >
                            {data.map((thirdPartyReport, index) => (
                              <ThirdPartyReport
                                {...{
                                  key:
                                    thirdPartyReport.id ||
                                    thirdPartyReport.uniqueId,
                                  thirdPartyReport,
                                  index,
                                  values,
                                  ocId: ownerContractorId,
                                  setFieldValue,
                                  data,
                                  setData,
                                  hasOnlyViewPermission,
                                  setIsDirty,
                                }}
                              />
                            ))}
                            {provided.placeholder}
                          </Grid>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {ternary(
                      !thirdPartyReportList?.length &&
                        !data?.length &&
                        !isThirdPartyReportLoading,
                      <Box my={5}>
                        <Typography textAlign="center">
                          No data to display. Select &#39;Third Party Report
                          Section&#39; to add data.
                        </Typography>
                      </Box>,
                      null,
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <AddThirdPartyReportsSection
              open={isAddSectionModalOpen}
              onClose={closeAddSectionModal}
              addSection={addSection}
              ocId={ownerContractorId}
              thirdPartyReports={data}
            />
          </>
        )}
        {/* show modal when tries to navigate without save data */}
        {!hasOnlyViewPermission && (
          <>
            <DKTConfirmNavigateShowModal
              isActive={isSaveWarningOpen}
              onConfirm={confirmSaveWarningModal}
              onCancel={handleCloseSaveWarningModal}
              onSave={handleSave}
            />
            <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
          </>
        )}
      </InnerLayout>
    </div>
  )
}

export default ThirdPartyReports
