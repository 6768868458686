import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  companyPersonalTitle: {
    '&.MuiTypography-root': {
      padding: '25px 0',
      marginBottom: 0,
      display: 'block',
    },
  },
  projectAssignmentTable: {
    '& .MuiTableCell-body': {
      padding: '7px 16px',
      fontSize: 14,
      borderBottom: `1px solid ${theme.palette.gray.main}`,
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))
