import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { memo } from 'react'

import { equal, length, ternary } from '../Utils/javascript'
import DKTCircularProgress from './DKTCircularProgress'

const useDefaultToolbarSelectStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1563ff1b',
    flex: '1 1 100%',
    display: 'flex',
    position: 'relative',
    zIndex: 120,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: equal(typeof theme.spacing, 'function')
      ? theme.spacing(1)
      : theme.spacing.unit,
    paddingBottom: equal(typeof theme.spacing, 'function')
      ? theme.spacing(1)
      : theme.spacing.unit,
    '@media print': {
      display: 'none',
    },
  },
  title: {
    paddingLeft: '26px',
  },
  iconButton: {
    marginRight: '24px',
  },
  selectedRow: {
    backgroundColor: '#00000014',
    '&:hover': {
      backgroundColor: 'rgba(21, 100, 255, 0.12)',
    },
  },
}))

const DKTSortableTable = ({
  columns,
  data,
  onSort,
  rowIdentifier = '',
  customRowCellsRender,
  selectedRows,
  preventDrag,
  setIsDragFieldDirty,
  actions: Actions = () => null,
  loading = false,
  selectedRowsList = [],
}) => {
  const classes = useDefaultToolbarSelectStyles()
  const onDragStart = (e, row) => {
    e.dataTransfer.setData('data', JSON.stringify(row))
  }
  const onDragOver = (e) => {
    e.preventDefault()
  }

  const reorderArray = (array, identifier, elementsToSwap) => {
    const currentIndex = array.findIndex((item) =>
      equal(item[identifier], elementsToSwap[0][identifier]),
    )
    const targetIndex = array.findIndex((item) =>
      equal(item[identifier], elementsToSwap[1][identifier]),
    )
    if (currentIndex !== targetIndex) {
      setIsDragFieldDirty(true)
    }
    array.splice(currentIndex, 1)
    array.splice(targetIndex, 0, elementsToSwap[0])
    return array
  }

  const onDrop = (e, target) => {
    if (onSort instanceof Function)
      onSort(
        reorderArray([...data], rowIdentifier, [
          JSON.parse(e.dataTransfer.getData('data')),
          target,
        ]),
      )
  }

  return (
    <>
      {ternary(
        selectedRows,
        <Paper className={classes.root}>
          <div>
            <Typography variant="subtitle1" className={classes.title}>
              {selectedRows} row(s) selected
            </Typography>
          </div>
          <Actions />
        </Paper>,
        null,
      )}
      <Table>
        <TableHead>
          <TableRow>
            {columns?.map((column, index) => (
              <TableCell key={index}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {loading ? (
            <TableCell
              colSpan={columns ? columns.length : 1}
              sx={{ textAlign: 'center' }}
            >
              <DKTCircularProgress />
            </TableCell>
          ) : data && equal(length(data), 0) ? (
            <TableRow>
              <TableCell
                colSpan={ternary(columns, length(columns), 1)}
                sx={{ textAlign: 'center' }}
              >
                Sorry, no matching results found
              </TableCell>
            </TableRow>
          ) : (
            data?.map((row, index) => (
              <TableRow
                id={row.id}
                key={index}
                draggable={ternary(preventDrag, false, true)}
                onDragStart={(e) => onDragStart(e, row)}
                onDragOver={onDragOver}
                onDrop={(e) => onDrop(e, row)}
                className={
                  selectedRowsList?.includes(row?.id?.toString()) &&
                  classes.selectedRow
                }
              >
                {customRowCellsRender instanceof Function &&
                  customRowCellsRender(row)}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </>
  )
}

export default memo(DKTSortableTable)
