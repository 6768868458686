// Fetch projects list
export const FETCH_PROJECT_LIST_REQUESTED = 'FETCH_PROJECT_LIST_REQUESTED'
export const FETCH_PROJECT_LIST_SUCCESS = 'FETCH_PROJECT_LIST_SUCCESS'
export const FETCH_PROJECT_LIST_FAILED = 'FETCH_PROJECT_LIST_FAILED'

// Fetch particular projects
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS'

// Create and update projects
export const CREATE_AND_UPDATE_PROJECT_REQUESTED =
  'CREATE_AND_UPDATE_PROJECT_REQUESTED'
export const CREATE_AND_UPDATE_PROJECT_SUCCESS =
  'CREATE_AND_UPDATE_PROJECT_SUCCESS'
export const CREATE_AND_UPDATE_PROJECT_FAILED =
  'CREATE_AND_UPDATE_PROJECT_FAILED'

// Delete project
export const DELETE_PROJECT_REQUESTED = 'DELETE_PROJECT_REQUESTED'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED'
// Create and update environmental
export const FETCH_PROJECT_FIELD_CHOICES_REQUESTED =
  'FETCH_PROJECT_FIELD_CHOICES_REQUESTED'
export const SET_FIELD_CHOICES_DATA = 'SET_FIELD_CHOICES_DATA'
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS'
