import { Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, TableCell, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CircledPlusIcon, DragIcon } from '../../../Assets/SVGs'
import {
  columns,
  formFields,
  selectedRowFormFields,
  constructionSummaryFormateFeatures,
} from '../../../Description/constructionSummaryFormat.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/admin'
import {
  fetchConstructionSummaryFormat,
  saveConstructionSummaryFormat,
} from '../../../Redux/actions/constructionMonitoringReport'
import DKTButton from '../../../Shared/DKTButton'
import DKTCheckbox from '../../../Shared/DKTCheckbox'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTInput from '../../../Shared/DKTInput'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTSortableTable from '../../../Shared/DKTSortableTable'
import { useStyles } from '../../../Styles/constructionSummaryFormat.style'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import {
  checkIncludes,
  entries,
  equal,
  isArray,
  length,
  ternary,
} from '../../../Utils/javascript'
import DKTForm from '../../../Shared/DKTForm'
let tempData = []
const ConstructionSummaryFormat = ({ renderSelect, items }) => {
  const classes = useStyles()
  const [data, setData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const {
    isUnsavedData,
    setIsUnsavedData,
    discardUnsavedChanges,
    isUnsavedChangesModalOpen,
    closeUnsavedChangesModal,
  } = items

  const { values, errors, handleChange, resetForm, setFieldValue } =
    useForm(formFields)
  const {
    values: selectedRows,
    handleChange: handleRowSelectionChange,
    setValues,
  } = useForm(selectedRowFormFields)
  const [descriptionToUpdate, setDescriptionToUpdate] = useState(null)

  const dispatch = useDispatch()
  const {
    isSavingConstructionSummaryFormat,
    constructionSummaryFormat,
    isFetchingCMR,
  } = useSelector(
    ({ constructionMonitoringReport }) => constructionMonitoringReport,
  )

  // Prefill work descriptions
  useEffect(() => {
    if (
      constructionSummaryFormat &&
      isArray(constructionSummaryFormat) &&
      constructionSummaryFormat?.length
    ) {
      setData(constructionSummaryFormat)
    } else {
      setData(() =>
        constructionSummaryFormateFeatures.map((item) => ({
          id: Math.floor(new Date().valueOf() * Math.random()),
          workDescription: item,
        })),
      )
    }
  }, [constructionSummaryFormat])

  useEffect(() => {
    dispatch(fetchConstructionSummaryFormat())
  }, [dispatch])

  useEffect(() => {
    const PreviousData = ternary(
      isArray(constructionSummaryFormat),
      constructionSummaryFormat,
      constructionSummaryFormat?.constructionSummaryFormat,
    )
    if (tempData?.length) {
      const checkIncludesItem = tempData
        ?.flat()
        ?.map((rowId) => PreviousData?.some((val) => `row_${val.id}` === rowId))
      setIsUnsavedData(checkIncludesItem?.some((item) => item === true))
    }
  }, [data])
  const renderFormFields = useCallback(
    (formFields) => (
      <Grid container width="100%">
        {entries(formFields).map(
          ([name, { isRequired, ...formField }], index) => {
            const FormControl = ternary(
              equal(formField.type, 'select'),
              DKTSelect,
              DKTInput,
            )
            return (
              <Grid key={index} item xs={formField.xs ?? 12}>
                <FormControl
                  {...formField}
                  id={name}
                  name={name}
                  value={(values && values[name]) || ''}
                  onChange={handleChange}
                  error={errors[name]}
                />
              </Grid>
            )
          },
        )}
      </Grid>
    ),
    [values, handleChange, errors],
  )

  const removeWorkDescriptions = useCallback((rowId) => {
    if (rowId)
      setData((data) =>
        data.filter((row) => !checkIncludes(`row_${row.id}`, rowId)),
      )
    tempData.push(rowId)
    setValues((values) => ({ ...values, list: [] }))
  }, [])

  const onSort = useCallback((sortedData) => {
    setData(sortedData)
  }, [])

  const updateWorkDescription = useCallback(
    (row) => {
      setDescriptionToUpdate(row)
      setFieldValue('workDescription', row.workDescription)
      openModal()
    },
    [setFieldValue],
  )

  const customRowCellsRender = (row) => {
    const value = `row_${row.id}`
    return (
      <TableCell>
        <div className={classes.workDescription}>
          <div className={classes.iconWrapper}>
            <DragIcon />
          </div>
          <DKTCheckbox
            name="list"
            value={value}
            handleChange={handleRowSelectionChange}
            checked={checkIncludes(value, selectedRows?.list) || false}
            className={classes.workCheckBox}
          />
          <div className={classes.workDescriptionTitle}>
            <Typography
              sx={{ cursor: 'pointer', display: 'inline' }}
              onClick={() => updateWorkDescription(row)}
            >
              {row.workDescription}
            </Typography>
          </div>
        </div>
      </TableCell>
    )
  }

  const upsertDescription = () => {
    setData((data) =>
      ternary(
        descriptionToUpdate,
        data.map((item) =>
          ternary(
            equal(item.id, descriptionToUpdate?.id),
            { ...item, workDescription: values.workDescription },
            item,
          ),
        ),
        [
          ...data,
          { id: new Date().getTime(), workDescription: values.workDescription },
        ],
      ),
    )
    setIsUnsavedData(true)
    closeModal()
  }

  const dialogActions = (
    <Box ml="auto">
      <DKTButton
        variant="primary"
        onClick={upsertDescription}
        disabled={errors?.workDescription || !values?.workDescription}
      >
        Save
      </DKTButton>
    </Box>
  )

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => {
    setIsModalOpen(false)
    resetForm()
    if (descriptionToUpdate) {
      setDescriptionToUpdate(null)
    }
  }

  const handleSave = () => {
    setIsUnsavedData(false)
    tempData = []
    dispatch(saveConstructionSummaryFormat(data))
  }

  const actions = () => (
    <DKTButton
      onClick={handleSave}
      disabled={isSavingConstructionSummaryFormat}
    >
      {ternary(isSavingConstructionSummaryFormat, 'Saving...', 'Save')}
    </DKTButton>
  )

  const sortableTableActions = () => (
    <IconButton
      color="error"
      onClick={() => setIsDeleteModalOpen(true)}
      sx={{ marginRight: 1 }}
    >
      <Delete color="error" />
    </IconButton>
  )

  const handleRemoveWorkDescriptions = () => {
    if (selectedRows.list) removeWorkDescriptions(selectedRows.list)
    setIsDeleteModalOpen(false)
  }

  const deleteModalActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => setIsDeleteModalOpen(false)}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleRemoveWorkDescriptions}>
        Yes
      </DKTButton>
    </>
  )

  return (
    <InnerLayout
      actionTitle="Manage Construction Monitoring Report Settings"
      actions={actions}
      contentTitle="Construction Monitoring Report (CMR)"
    >
      {renderSelect()}
      <Box className={classes.companyPersonnelTable}>
        <DKTSortableTable
          columns={columns}
          data={data}
          onSort={onSort}
          rowIdentifier="id"
          customRowCellsRender={customRowCellsRender}
          selectedRows={ternary(
            selectedRows.list,
            length(selectedRows.list),
            0,
          )}
          selectedRowsList={selectedRows?.list}
          actions={sortableTableActions}
          setIsDragFieldDirty={setIsUnsavedData}
        />
        <DKTButton
          variant="text"
          color="primary"
          sx={{ textTransform: 'initial' }}
          startIcon={<CircledPlusIcon />}
          onClick={() => setIsModalOpen(true)}
        >
          Add Description
        </DKTButton>

        <DKTDialog
          title="Add/Edit Description"
          showCloseButton
          actions={dialogActions}
          open={isModalOpen}
          handleClose={closeModal}
        >
          <DKTForm autoComplete="off">{renderFormFields(formFields)}</DKTForm>
        </DKTDialog>
        <DKTDialog
          open={isDeleteModalOpen}
          handleClose={() => setIsDeleteModalOpen(false)}
          title="&nbsp;"
          actions={deleteModalActions}
          maxWidth="xs"
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.deleteNotificationContent}>
                <Typography variant="h5">
                  Are you sure you want to delete?
                </Typography>
                <Typography variant="body1">
                  You can&apos;t undo this action
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DKTDialog>
      </Box>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnsavedChangesModalOpen}
        onConfirm={discardUnsavedChanges}
        onCancel={closeUnsavedChangesModal}
        onSave={handleSave}
        loading={isFetchingCMR}
      />
      <DKTReactRouterPrompt isDirty={isUnsavedData} onSave={handleSave} />
    </InnerLayout>
  )
}

export default ConstructionSummaryFormat
