import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, ternary } from '../../Utils/javascript'
import {
  CREATE_AND_UPDATE_PROJECT_TEAM_FAILED,
  CREATE_AND_UPDATE_PROJECT_TEAM_REQUESTED,
  CREATE_AND_UPDATE_PROJECT_TEAM_SUCCESS,
  DELETE_PROJECT_TEAM_FAILED,
  DELETE_PROJECT_TEAM_REQUESTED,
  DELETE_PROJECT_TEAM_SUCCESS,
  FETCH_PROJECT_TEAM_FAILED,
  FETCH_PROJECT_TEAM_REQUESTED,
  FETCH_PROJECT_TEAM_SUCCESS,
  FETCH_REPORT_TIER_FAILED,
  FETCH_REPORT_TIER_REQUESTED,
  FETCH_REPORT_TIER_SUCCESS,
  SET_REPORT_TIER_FAILED,
  SET_REPORT_TIER_REQUESTED,
  SET_REPORT_TIER_SUCCESS,
} from '../constants/projectSettings'
import { updateSaveStatusAction } from './confirmation'

// Fetch project team
const fetchProjectTeamRequested = () => ({
  type: FETCH_PROJECT_TEAM_REQUESTED,
})
export const fetchProjectTeamSuccess = (payload) => ({
  type: FETCH_PROJECT_TEAM_SUCCESS,
  payload,
})
const fetchProjectTeamFailed = (payload) => ({
  type: FETCH_PROJECT_TEAM_FAILED,
  payload,
})

export const getProjectTeam = (id) => async (dispatch) => {
  dispatch(fetchProjectTeamRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.projectTeam}?projectId=${id}`,
    method: method.get,
    showToastMessage: false,
    toastMessage: 'Project team Fetch successfully!',
  })
  if (data) {
    dispatch(fetchProjectTeamSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchProjectTeamFailed(error.response.data))
  }
}

// Create and update project team
const createAndUpdateProjectTeamRequested = () => ({
  type: CREATE_AND_UPDATE_PROJECT_TEAM_REQUESTED,
})
const createAndUpdateProjectTeamSuccess =
  (payload, teamId) => (dispatch, getState) => {
    const state = getState()
    const projectTeamList = [...state.projectSetting.projectTeamList]
    const index = projectTeamList?.findIndex(({ id }) => equal(id, payload?.id))
    const updatedProjectTeamList = [...projectTeamList]
    if (~index) {
      updatedProjectTeamList?.splice(index, 1, payload)
    }
    dispatch({
      type: CREATE_AND_UPDATE_PROJECT_TEAM_SUCCESS,
      payload: ternary(teamId, updatedProjectTeamList, [
        ...projectTeamList,
        payload,
      ]),
    })
  }
const createAndUpdateProjectTeamFailed = (payload) => ({
  type: CREATE_AND_UPDATE_PROJECT_TEAM_FAILED,
  payload,
})

export const createAndUpdateProjectTeam = (payload, id) => async (dispatch) => {
  dispatch(createAndUpdateProjectTeamRequested())
  const { data, error } = await api({
    endPoint: ternary(
      id,
      `${apiEndPoints.projectTeam}${id}/`,
      `${apiEndPoints.projectTeam}`,
    ),
    method: ternary(id, method.patch, method.post),
    data: payload,
    showToastMessage: true,
    toastMessage: ternary(
      id,
      'Project team updated successfully!',
      'Project team created successfully!',
    ),
  })
  setTimeout(() => {
    dispatch(updateSaveStatusAction(data, error))
  }, 700)
  if (data) {
    dispatch(createAndUpdateProjectTeamSuccess(data, id))
    return
  }
  if (error) {
    dispatch(createAndUpdateProjectTeamFailed(error.response.data))
  }
}

// Delete project team
const deleteProjectTeamRequested = () => ({
  type: DELETE_PROJECT_TEAM_REQUESTED,
})
const deleteProjectTeamSuccess = (payload) => (dispatch, getState) => {
  const state = getState()
  const projectTeamList = [...state.projectSetting.projectTeamList]
  const updatedProjectTeamList = projectTeamList?.filter(
    (list) => !payload?.projectTeamMember?.includes(list?.id),
  )

  dispatch({
    type: DELETE_PROJECT_TEAM_SUCCESS,
    payload: updatedProjectTeamList,
  })
}
const deleteProjectTeamFailed = (payload) => ({
  type: DELETE_PROJECT_TEAM_FAILED,
  payload,
})

export const deleteProjectTeam = (payload, projectId) => async (dispatch) => {
  dispatch(deleteProjectTeamRequested())
  const { status, error } = await api({
    endPoint: `${apiEndPoints.deleteProjectTeam}?projectId=${projectId}`,
    method: method.delete,
    data: payload,
    showToastMessage: true,
    toastMessage: 'Project team deleted successfully!',
  })
  if (status) {
    dispatch(deleteProjectTeamSuccess(payload))
    return
  }
  if (error) {
    dispatch(deleteProjectTeamFailed(error.response.data))
  }
}

// Set report tier
const setReportTierRequested = () => ({
  type: SET_REPORT_TIER_REQUESTED,
})
export const setReportTierSuccess = (payload) => ({
  type: SET_REPORT_TIER_SUCCESS,
  payload,
})
const setReportTierFailed = (payload) => ({
  type: SET_REPORT_TIER_FAILED,
  payload,
})

export const setReportTier = (payload, id) => async (dispatch) => {
  dispatch(setReportTierRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.reportTier}${id}/`,
    method: method.patch,
    data: payload,
    showToastMessage: true,
    toastMessage: 'Report tier set successfully!',
  })
  setTimeout(() => {
    dispatch(updateSaveStatusAction(data, error))
  }, 700)
  if (data) {
    dispatch(setReportTierSuccess(data))
    return
  }
  if (error) {
    dispatch(setReportTierFailed(error.response.data))
  }
}

// Fetch report tier
const fetchReportTierRequested = () => ({
  type: FETCH_REPORT_TIER_REQUESTED,
})
export const fetchReportTierSuccess = (payload) => ({
  type: FETCH_REPORT_TIER_SUCCESS,
  payload,
})
const fetchReportTierFailed = (payload) => ({
  type: FETCH_REPORT_TIER_FAILED,
  payload,
})

export const fetchReportTier = (id) => async (dispatch) => {
  dispatch(fetchReportTierRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.reportTier}${id}/`,
    method: method.get,
    showToastMessage: false,
    toastMessage: 'Report tier fetch successfully!',
  })
  if (data) {
    dispatch(fetchReportTierSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchReportTierFailed(error.response.data))
  }
}
