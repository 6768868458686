export const options = {
  responsive: 'standard',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  selectableRows: 'none',
  pagination: false,
}

export const summaryColumnArr = [
  {
    name: 'reportCode',
    label: 'PROJECT SUMMARY',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'projectSummary',
    label: ' ',
    options: {
      filter: true,
      sort: false,
      someOptionToToggle: true,
    },
  },
]

export const projectDirectoryColumnArr = [
  {
    name: 'reportCode',
    label: 'PROJECT DIRECTORY',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'projectDirectory',
    label: ' ',
    options: {
      filter: true,
      sort: false,
    },
  },
]

export const projectSummaryArr = [
  {
    label: 'Project Type',
    name: 'project_type',
  },
  {
    label: 'Construction Type',
    name: 'construction_type',
  },
  {
    label: 'Site Acres',
    name: 'site_acres',
    formateType: 'number',
  },
  {
    label: 'Residential Buildings',
    name: 'residential_buildings',
  },
  {
    label: 'Residential Gross Square Feet',
    name: 'residential_gsf',
    formateType: 'number',
  },
  {
    label: 'Total Number of Units',
    name: 'total_number_of_units',
  },
  {
    label: 'Contract Type',
    name: 'contract_type',
  },
  {
    label: 'Contract Sum',
    name: 'contract_sum',
    formateType: 'currency',
  },
  {
    label: 'Contract Completion',
    name: 'contract_completion',
    formateType: 'date',
  },
  {
    label: 'Hard Cost Contingency',
    name: 'hard_cost_contingency',
    formateType: 'currency',
  },
  {
    label: 'Construction Start',
    name: 'gc_construction_start',
    formateType: 'date',
  },
  {
    label: 'Schedule Completion',
    name: 'gc_schedule_completion',
    formateType: 'date',
  },
  {
    label: 'Duration Days',
    name: 'duration_days',
  },
]
