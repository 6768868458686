import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import {
  FETCH_STATE_LIST_FAILED,
  FETCH_STATE_LIST_REQUESTED,
  FETCH_STATE_LIST_SUCCESS,
  US_STATE_CITY_FAILED,
  US_STATE_CITY_REQUESTED,
  US_STATE_CITY_SUCCESS,
} from '../constants/states'

// Fetch state list
const stateListRequested = () => ({ type: FETCH_STATE_LIST_REQUESTED })
const stateListSuccess = (payload) => ({
  type: FETCH_STATE_LIST_SUCCESS,
  payload,
})
const stateListFailed = (payload) => ({
  type: FETCH_STATE_LIST_FAILED,
  payload,
})

export const getStateList = (payload) => async (dispatch) => {
  dispatch(stateListRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.stateList,
    method: method.get,
    data: payload,
    showToastMessage: false,
    toastMessage: 'Fetch state list successfully!',
  })
  if (data) {
    dispatch(stateListSuccess(data))
    return
  }
  if (error) {
    dispatch(stateListFailed(error?.response?.data))
  }
}

// Fetch us state and cities
const usStateCityRequested = () => ({
  type: US_STATE_CITY_REQUESTED,
})
export const usStateCitySuccess = (payload) => ({
  type: US_STATE_CITY_SUCCESS,
  payload,
})
const usStateCityFailed = (payload) => ({
  type: US_STATE_CITY_FAILED,
  payload,
})

export const submitZipCode = (payload) => async (dispatch) => {
  dispatch(usStateCityRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.zipCodeForStateAndCity,
    method: method.post,
    data: payload,
    showToastMessage: false,
    toastMessage: 'Fetch state and city successfully!',
  })
  if (data) {
    dispatch(usStateCitySuccess(data))
    return
  }
  if (error) {
    dispatch(usStateCityFailed(error.response.data))
  }
}
