import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { equal, ternary } from '../Utils/javascript'
import { clientPortal } from '../Utils/constant'

const RequireAuth = ({ requiredRoles = [], children }) => {
  const { accessToken, systemAuthorization, portalType } = useSelector(
    ({ auth }) => auth,
  )
  return ternary(
    accessToken,
    ternary(
      ternary(
        equal(portalType, clientPortal),
        requiredRoles?.includes(systemAuthorization) &&
          requiredRoles?.includes(clientPortal),
        requiredRoles?.includes(systemAuthorization),
      ),
      children,
      <Navigate to="/dashboard" replace />,
    ),
    <Navigate to="/login" replace />,
  )
}

export default RequireAuth
