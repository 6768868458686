export const LOGIN_REQUESTED = 'LOGIN_REQUESTED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS'

export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED'

export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

// Activate User
export const USER_ACTIVATION_REQUESTED = 'USER_ACTIVATION_REQUESTED'
export const USER_ACTIVATION_SUCCESS = 'USER_ACTIVATION_SUCCESS'
export const USER_ACTIVATION_FAILED = 'USER_ACTIVATION_FAILED'

// User logout
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'

// System authorization
export const FETCH_SYSTEM_REQUESTED = 'FETCH_SYSTEM_REQUESTED'
export const FETCH_SYSTEM_SUCCESS = 'FETCH_SYSTEM_SUCCESS'
export const FETCH_SYSTEM_FAILED = 'FETCH_SYSTEM_FAILED'

// Check reset link expire
export const CHECK_LINK_EXPIRE_REQUESTED = 'CHECK_LINK_EXPIRE_REQUESTED'
export const CHECK_LINK_EXPIRE_SUCCESS = 'CHECK_LINK_EXPIRE_SUCCESS'
export const CHECK_LINK_EXPIRE_FAILED = 'CHECK_LINK_EXPIRE_FAILED'

// resend email
export const RESEND_EMAIL_REQUESTED = 'RESEND_EMAIL_REQUESTED'
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS'
export const RESEND_EMAIL_FAILED = 'RESEND_EMAIL_FAILED'
