import { List, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { projectMenu } from '../../Description/projects.description'
import { clientPortal } from '../../Utils/constant'
import { equal, ternary } from '../../Utils/javascript'
import ProjectMenuLink from '../ProjectMenuLink'

const useStyles = makeStyles(() => ({
  projectMenu: {
    display: 'flex',
    '& .MuiListItem-root': {
      whiteSpace: 'nowrap',
      '& .MuiTypography-root': {
        fontWeight: '700',
      },
    },
  },
}))

const PageTitleBar = ({ title, isShowMenu = true }) => {
  const classes = useStyles()
  const { portalType } = useSelector(({ auth }) => auth)

  const getMatchingPath = (id) => `/projects/:slug/${id}`

  return (
    <Stack
      height="50px"
      backgroundColor="gray.dark"
      px={3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      top={64}
      left={0}
      right={0}
      zIndex={999}
    >
      <Typography variant="h5" component="h2" fontWeight={700}>
        {title}
      </Typography>
      {ternary(
        isShowMenu,
        <List className={classes.projectMenu} disablePadding>
          {projectMenu
            ?.filter(
              (column) =>
                !(
                  equal(portalType, clientPortal) &&
                  equal(column.menu, 'Project Info')
                ),
            )
            ?.map((data, i) => (
              <ProjectMenuLink
                key={i}
                data={data}
                getMatchingPath={getMatchingPath}
              />
            ))}
        </List>,
        null,
      )}
    </Stack>
  )
}

export default memo(PageTitleBar)
