import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import MUIDataTable from 'mui-datatables'
import React, { memo } from 'react'

const useStyles = makeStyles((theme) => ({
  commonTable: {
    '& .tss-1dqj55d-MUIDataTableToolbarSelect-root': {
      backgroundColor: theme.palette.primary.light,
      height: 56,
      boxSizing: 'border-box',
    },
    '&.MuiPaper-elevation': {
      boxShadow: 'none',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& th': {
            background: theme.palette.gray.main,
            '& .MuiButton-root': {
              padding: 0,
              width: '100%',
              textTransform: 'capitalize',
              justifyContent: 'space-between',
              margin: 0,
              '&:hover': {
                background: 'transparent',
              },
              '& > .tss-1h9t3sl-MUIDataTableHeadCell-sortAction': {
                width: '100%',
                justifyContent: 'space-between',
              },
            },
          },
        },
      },
      '& .MuiTableCell-footer ': {
        borderBottom: 0,
      },
    },
  },
}))

const DKTMuiDataTable = ({
  title = '',
  data,
  columns,
  options,
  className,
  components,
}) => {
  const classes = useStyles()
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
      className={classNames(className, classes.commonTable)}
      components={components}
    />
  )
}

export default memo(DKTMuiDataTable)
