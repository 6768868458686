import React from 'react'
import { Grid } from '@mui/material'
import { formFields } from '../../../Description/projectSetting.description'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'

const ReportTier = ({
  renderFormFields,
  isUnsavedData,
  handleSetReportTier,
}) => (
  <>
    <Grid container spacing={2} mb={2}>
      <Grid item xs={4}>
        {renderFormFields(formFields.reportTier)}
      </Grid>
    </Grid>
    <DKTReactRouterPrompt
      isDirty={isUnsavedData}
      onSave={handleSetReportTier}
    />
  </>
)

export default ReportTier
