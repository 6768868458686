import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  fieldsToInherit,
  formFields,
  optionChoices,
  optionFields,
} from '../../../Description/permitsEntitlements.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import { getProjectChoices } from '../../../Redux/actions/choices'
import {
  getCertificateOfOccupancy,
  getLastReportPermitsEntitlements,
  getPermitsEntitlements,
  importFromLastReport,
  savePermitsEntitlements,
} from '../../../Redux/actions/permitsEntitlements'
import { getReportChoices } from '../../../Redux/actions/reportChoices'
import DKTButton from '../../../Shared/DKTButton'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTTooltip from '../../../Shared/DKTTooltip'
import DKTForm from '../../../Shared/DKTForm'
import { useStyles } from '../../../Styles/permitsEntitlements.style'
import { importButtonTooltip, spinnerSize } from '../../../Utils/constant'
import {
  entries,
  equal,
  handleTextEditor,
  isArray,
  keys,
  ternary,
} from '../../../Utils/javascript'
import { renderFormFields } from '../../../Utils/renderFormFields'
import CertificateOfOccupancy from './CertificateOfOccupancy'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { CLEAR_PERMITS_ENTITLEMENTS } from '../../../Redux/constants/permitsEntitlements'
import PermitTracker from '../trackers/PermitTracker'
import usePermission from '../../../Hooks/usePermission'
import DKTEditor from '../../../Shared/DKTEditor'

const initialFormFields = entries(formFields).reduce((result, formField) => {
  const formFieldValue = formField[1]
  return {
    ...result,
    ...formFieldValue,
  }
}, {})

const getFieldsToInherit = (obj = {}) =>
  fieldsToInherit.reduce(
    (result, field) => ({ ...result, [field]: obj[field] }),
    {},
  )

const ImportButton = ({ name, label, handleImport, hasOnlyViewPermission }) => {
  const theme = useTheme()
  const { isImporting } = useSelector(
    ({ permitsEntitlements }) => permitsEntitlements,
  )
  return (
    <Stack
      direction="row"
      alignItems="end"
      justifyContent="space-between"
      mb={1}
    >
      <Typography
        variant="body2"
        color="gray.extraDark"
        sx={{ fontWeight: 'medium' }}
      >
        {label}
      </Typography>

      <DKTTooltip title={importButtonTooltip}>
        <Box>
          <DKTButton
            variant="outlined"
            onClick={() => handleImport(name)}
            sx={{
              borderColor: '#ACACAC',
              color: '#2D2D2D',
              width: '91px',
              height: '32px',
              '&:hover': {
                borderColor: '#000',
              },
            }}
            disabled={hasOnlyViewPermission || isImporting[name]}
          >
            {ternary(
              isImporting[name],
              <DKTCircularProgress
                size={spinnerSize?.sm}
                color={theme.palette.gray.dark}
              />,
              'Import',
            )}
          </DKTButton>
        </Box>
      </DKTTooltip>
    </Stack>
  )
}

const PermitsEntitlements = () => {
  const [ownerContractorId, setOwnerContractorId] = useState(null)
  const [ownerContractorUniqueCode, setOwnerContractorUniqueCode] =
    useState(null)
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [isImportWarningOpen, setIsImportWarningOpen] = useState(false)
  const [mergedFormFields, setMergedFormFields] = useState({
    ...initialFormFields,
  })
  const [isHandleChange, setHandleChange] = useState(false)
  const [ckEditorChange, setCkEditorChange] = useState(false)
  const [isPermitTrackerOpen, setIsPermitTrackerOpen] = useState(false)

  const importFieldName = useRef(null)
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const { slug: projectId, reportId } = useParams()
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)
  const { choices: projectChoices } = useSelector(
    ({ projectChoices }) => projectChoices,
  )
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    setValues,
    resetForm,
    setIsDirty,
    setDummyData,
    dummyData,
  } = useForm(mergedFormFields, CLEAR_PERMITS_ENTITLEMENTS)
  const { hasOnlyViewPermission } = usePermission()
  const {
    permitsEntitlements,
    lastReportPermitsEntitlements,
    isImporting,
    isSavingPermitsEntitlements,
    isFetchingPermitsEntitlements,
    isFetchingLastReportPermitsEntitlements,
  } = useSelector(({ permitsEntitlements }) => permitsEntitlements)

  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionFields?.join(','),
        projectId,
      }),
    )
    dispatch(getProjectChoices({ fieldName: optionChoices.join(',') }))
  }, [dispatch, projectId])

  useEffect(() => {
    if (reportId && ownerContractorId)
      dispatch(getPermitsEntitlements(reportId, ownerContractorId))

    if (projectId && reportId && ownerContractorId) {
      dispatch(
        getLastReportPermitsEntitlements({
          projectId,
          reportId,
          ocId: ownerContractorId,
        }),
      )
      dispatch(
        getCertificateOfOccupancy({
          reportId,
          ocId: ownerContractorId,
          projectId,
        }),
      )
    }
  }, [dispatch, ownerContractorId])

  useEffect(() => {
    if (choices) {
      const dynamicDropdown = (ary) => {
        const res = ary?.map((element) => ({
          label: element?.uniqueCode || element?.projectName,
          value: `${element?.id}`,
        }))
        return res
      }
      const ownerContractor = dynamicDropdown(choices?.ownerContractor)
      setDynamicOptions({
        ...choices,
        ownerContractor,
      })
    }
  }, [choices])

  useEffect(() => {
    let activeContractor = null
    if (isArray(choices?.ownerContractor) && choices?.ownerContractor?.length) {
      activeContractor = choices?.ownerContractor[0]
    }
    setOwnerContractorId(activeContractor?.id)
    setOwnerContractorUniqueCode(activeContractor?.uniqueCode)
  }, [choices?.ownerContractor[0]?.id])

  useEffect(() => {
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    setFieldValue('ownerContractorDescription', activeContractorDescription)
    return () => resetForm()
  }, [ownerContractorId])

  // Prefill construction schedule form fields
  useEffect(() => {
    if (!permitsEntitlements) {
      setValues((prevValues) => ({
        ownerContractorDescription: prevValues.ownerContractorDescription,
        ...getFieldsToInherit(lastReportPermitsEntitlements || {}),
      }))
      setDummyData((prevValues) => ({
        ownerContractorDescription: prevValues.ownerContractorDescription,
        ...getFieldsToInherit(lastReportPermitsEntitlements || {}),
      }))
      return
    }
    setValues((prevValues) => ({
      ...prevValues,
      ...getFieldsToInherit(lastReportPermitsEntitlements || {}),
      ...permitsEntitlements,
    }))
    setDummyData((prevValues) => ({
      ...prevValues,
      ...getFieldsToInherit(lastReportPermitsEntitlements || {}),
      ...permitsEntitlements,
    }))
  }, [permitsEntitlements, lastReportPermitsEntitlements])

  // Set field required based on another
  useEffect(() => {
    const isTcoDateRequired = equal(
      values.temporaryCertificateOfOccupancyReceived,
      'YES',
    )
    const isCertificateOfOccupancyIssuedDateRequired = equal(
      values.certificateOfOccupancyReceived,
      'YES',
    )
    setErrors((prevErrors) => {
      const clonedErrors = { ...prevErrors }
      if (!isTcoDateRequired) delete clonedErrors.tcoDate
      if (!isCertificateOfOccupancyIssuedDateRequired)
        delete clonedErrors.certificateOfOccupancyIssuedDate
      return clonedErrors
    })
    setMergedFormFields((prevFields) => ({
      ...prevFields,
      // tcoDate: {
      //   ...prevFields.tcoDate,
      //   isRequired: isTcoDateRequired,
      // },
      // certificateOfOccupancyIssuedDate: {
      //   ...prevFields.certificateOfOccupancyIssuedDate,
      //   isRequired: isCertificateOfOccupancyIssuedDateRequired,
      // },
    }))
  }, [
    values.temporaryCertificateOfOccupancyReceived,
    values.certificateOfOccupancyReceived,
  ])

  // Reset and disable values
  useEffect(() => {
    setValues((values) => ({
      ...values,
      certificateOfOccupancyIssuedDate:
        values?.certificateOfOccupancyReceived === 'NO'
          ? 'N/A'
          : values?.certificateOfOccupancyIssuedDate === 'N/A'
          ? null
          : values.certificateOfOccupancyIssuedDate,
      tcoDate:
        values?.temporaryCertificateOfOccupancyReceived === 'NO'
          ? 'N/A'
          : values?.tcoDate === 'N/A'
          ? null
          : values.tcoDate,
      tcoExpiration:
        values?.temporaryCertificateOfOccupancyReceived === 'NO'
          ? 'N/A'
          : values?.tcoExpiration === 'N/A'
          ? null
          : values.tcoExpiration,
    }))
  }, [
    values?.certificateOfOccupancyReceived,
    values?.temporaryCertificateOfOccupancyReceived,
  ])

  const handleOpenClosePermitTrackerModal = (status) =>
    setIsPermitTrackerOpen(status)

  const handleSave = () => {
    const isFormValid = handleSubmit()
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
      return
    }
    setIsDirty(false)
    setHandleChange(false)
    let cloneValues = {
      ...values,
    }
    keys(cloneValues)?.forEach((key) => {
      if (equal(cloneValues[key], '')) {
        cloneValues[key] = null
      }
    })
    if (equal(cloneValues.temporaryCertificateOfOccupancyReceived, 'NO')) {
      cloneValues = {
        ...cloneValues,
        tcoDate: null,
        tcoExpiration: null,
      }
    }
    if (equal(values.certificateOfOccupancyReceived, 'NO')) {
      cloneValues = {
        ...cloneValues,
        certificateOfOccupancyIssuedDate: null,
      }
    }
    dispatch(
      savePermitsEntitlements({
        payload: cloneValues,
        ocId: ownerContractorId,
        projectId,
        reportId,
        ownerContractorUniqueCode,
      }),
    )
  }

  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          gap={2}
          // className={classes.rightButton}
        >
          <DKTButtonSelect
            options={actionList}
            disabled={
              hasOnlyViewPermission ||
              isFetchingPermitsEntitlements ||
              isFetchingLastReportPermitsEntitlements ||
              true
            }
          />
          <DKTButton
            className={classes.rightButton}
            onClick={handleSave}
            disabled={
              hasOnlyViewPermission ||
              isSavingPermitsEntitlements ||
              isFetchingPermitsEntitlements ||
              isFetchingLastReportPermitsEntitlements
            }
          >
            {ternary(isSavingPermitsEntitlements, 'Saving...', 'Save')}
          </DKTButton>
        </Stack>
      </Box>
    </>
  )
  const actionList = [{ label: 'Delete', onClick: () => null }]

  const customFormControlFields = ({ name, formControl }) => ({
    options: ternary(
      formControl.isDynamicOptions,
      dynamicOptions,
      ternary(
        mergedFormFields[name]?.isDynamicOptions,
        projectChoices[name],
        formControl.options,
      ),
    ),
    // isRequired: ternary(
    //   (equal(name, 'tcoDate') &&
    //     equal(values.temporaryCertificateOfOccupancyReceived, 'YES')) ||
    //     (equal(name, 'certificateOfOccupancyIssuedDate') &&
    //       equal(values.certificateOfOccupancyReceived, 'YES')),
    //   true,
    //   formControl.isRequired,
    // ),
    disabled:
      hasOnlyViewPermission ||
      formControl.disabled ||
      ((equal(name, 'tcoDate') || equal(name, 'tcoExpiration')) &&
        equal(values.temporaryCertificateOfOccupancyReceived, 'NO')) ||
      (equal(name, 'certificateOfOccupancyIssuedDate') &&
        equal(values.certificateOfOccupancyReceived, 'NO')),
  })

  const handleFieldChange = (...rest) => {
    handleChange(...rest)
    setHandleChange(true)
  }

  const renderFormFieldsWithOptions = useCallback(
    (formFields) =>
      renderFormFields({
        values,
        errors,
        handleChange: handleFieldChange,
        formFields,
        customFormControlFields,
      }),
    [values, errors, handleFieldChange, customFormControlFields],
  )

  /* IMPORT FROM LAST REPORT */
  const showImportWarning = () => setIsImportWarningOpen(true)
  const hideImportWarning = () => setIsImportWarningOpen(false)
  const handleImport = useCallback(
    (name) => {
      importFieldName.current = null
      if (values[name]) {
        importFieldName.current = name
        showImportWarning()
        return
      }

      dispatch(
        importFromLastReport({
          query: name,
          projectId,
          ocId: ownerContractorId,
          onSuccess: (value) => setFieldValue(name, value),
          reportId,
        }),
      )
    },
    [projectId, reportId, ownerContractorId, dispatch, values],
  )
  const handleImportOverWrite = () => {
    if (importFieldName.current) {
      const onSuccess = (value) => {
        setFieldValue(importFieldName.current, value)
        hideImportWarning()
      }
      dispatch(
        importFromLastReport({
          query: importFieldName.current,
          projectId,
          ocId: ownerContractorId,
          onSuccess,
          reportId,
        }),
      )
      setHandleChange(true)
    }
  }
  const importWarningActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={hideImportWarning}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleImportOverWrite}
        disabled={Object.values(isImporting).some((value) => value)}
      >
        {ternary(
          Object.values(isImporting).some((value) => value),
          <DKTCircularProgress
            size={spinnerSize?.sm}
            color={theme.palette.gray.dark}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  useEffect(() => {
    ckEditorChangeFN('permitAndViolationComments')
  }, [values.permitAndViolationComments])

  useEffect(() => {
    ckEditorChangeFN('certificateOfOccupancyComments')
  }, [values.certificateOfOccupancyComments])

  const ckEditorChangeFN = useCallback(
    (key) => {
      const valuesKey = keys(values)

      if (
        valuesKey?.includes(key) &&
        !equal(values?.[key], '') &&
        !equal(values?.[key], null)
      ) {
        if (values?.[key] !== dummyData?.[key] || isHandleChange) {
          setCkEditorChange(true)
        } else {
          setCkEditorChange(false)
        }
      }
    },
    [
      values,
      values?.certificateOfOccupancyComments,
      values?.permitAndViolationComments,
    ],
  )

  useEffect(() => {
    if (ckEditorChange) {
      setHandleChange(true)
    }
  }, [ckEditorChange])

  const handleBlurEventOnCkEditor = (key, editor) => {
    const data = editor.getData()
    const previousValue = dummyData?.[key] || ''
    if (data !== previousValue) {
      setHandleChange(true)
    }
  }
  const permitsAndViolationsFirstRow = renderFormFieldsWithOptions(
    formFields.permitsAndViolationsFirstRow,
  )
  const certificateOfOccupancyFields = renderFormFieldsWithOptions(
    formFields.certificateOfOccupancy,
  )
  return (
    <div>
      <InnerLayout
        contentTitle="Permits & Entitlements"
        actions={actions}
        maxWidth="md"
        isShowMenu
      >
        {equal(isFetchingLastReportPermitsEntitlements, true) ||
        equal(isFetchingPermitsEntitlements, true) ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <DKTCircularProgress />
          </Stack>
        ) : equal(isFetchingLastReportPermitsEntitlements, 'FAILED') ||
          equal(isFetchingPermitsEntitlements, 'FAILED') ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching Permits & Entitlements
              data. Please try contacting the admin or refreshing this page.
            </Typography>
          </Stack>
        ) : (
          <Grid container>
            <Grid item xs={12} lg={10}>
              <Grid container columnSpacing={10} rowSpacing={3}>
                <Grid item lg={6} xs={12}>
                  <Box mb={3}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        variant="body2"
                        color="gray.extraDark"
                        sx={{ fontWeight: 'medium' }}
                      >
                        Permits and Violations
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        color="primary"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleOpenClosePermitTrackerModal(true)}
                      >
                        Permit Tracker
                      </Typography>
                    </Box>
                    <Divider light sx={{ mb: '5px' }} />
                  </Box>
                  <DKTForm autoComplete="off">
                    <Grid container spacing={2}>
                      {permitsAndViolationsFirstRow}
                      {/* P&V Comments */}
                      <Grid item xs={12} mt={2}>
                        <ImportButton
                          label="Comments"
                          handleImport={handleImport}
                          name="permitAndViolationComments"
                          hasOnlyViewPermission={hasOnlyViewPermission}
                        />
                        <DKTEditor
                          value={values?.permitAndViolationComments || ''}
                          onChange={(event, editor) => {
                            handleTextEditor(
                              editor,
                              'permitAndViolationComments',
                              setFieldValue,
                            )
                          }}
                          onBlur={(event, editor) => {
                            handleBlurEventOnCkEditor(
                              'permitAndViolationComments',
                              editor,
                            )
                          }}
                          disabled={hasOnlyViewPermission}
                          className={classes.projectDesc}
                        />
                      </Grid>
                    </Grid>
                  </DKTForm>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <FormSectionDivider sectionTitle="Certificate of Occupancy" />
                  <DKTForm autoComplete="off">
                    <Grid container spacing={2}>
                      {certificateOfOccupancyFields}
                      {/* CoO Comments */}
                      <Grid item xs={12} mt={2}>
                        <ImportButton
                          label="Comments"
                          handleImport={handleImport}
                          name="certificateOfOccupancyComments"
                          hasOnlyViewPermission={hasOnlyViewPermission}
                        />
                        <DKTEditor
                          value={values?.certificateOfOccupancyComments || ''}
                          onChange={(event, editor) => {
                            handleTextEditor(
                              editor,
                              'certificateOfOccupancyComments',
                              setFieldValue,
                            )
                          }}
                          onBlur={(event, editor) => {
                            handleBlurEventOnCkEditor(
                              'certificateOfOccupancyComments',
                              editor,
                            )
                          }}
                          disabled={hasOnlyViewPermission}
                          className={classes.projectDesc}
                        />
                      </Grid>
                    </Grid>
                  </DKTForm>
                </Grid>
                <CertificateOfOccupancy
                  hasOnlyViewPermission={hasOnlyViewPermission}
                  ocId={ownerContractorId}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Import content over-write warning */}
        <DKTDialog
          open={isImportWarningOpen}
          handleClose={hideImportWarning}
          title="&nbsp;"
          actions={importWarningActions}
          maxWidth="xs"
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.deleteNotificationContent}>
                <Typography variant="h5">
                  Existing data will be overwritten.
                </Typography>
                <Typography variant="body1">Continue?</Typography>
              </Box>
            </Grid>
          </Grid>
        </DKTDialog>

        {isPermitTrackerOpen && (
          <PermitTracker
            open={isPermitTrackerOpen}
            onClose={() => handleOpenClosePermitTrackerModal(false)}
            ownerContractor={ownerContractorId}
            uniqueCode={values.payApplicationNumber}
            {...{ reportId, projectId, hasOnlyViewPermission }}
          />
        )}
        {/* show modal when tries to navigate without save data */}
        {!hasOnlyViewPermission && (
          <DKTReactRouterPrompt isDirty={isHandleChange} onSave={handleSave} />
        )}
      </InnerLayout>
    </div>
  )
}

const FormSectionDivider = ({ sectionTitle, action, ...rest }) => (
  <Box {...rest}>
    <Stack direction="row" justifyContent="space-between">
      <Typography
        variant="body2"
        color="gray.extraDark"
        sx={{ fontWeight: 'medium' }}
      >
        {sectionTitle}
      </Typography>

      {action}
    </Stack>
    <Divider sx={{ mb: 3 }} />
  </Box>
)

export default PermitsEntitlements
