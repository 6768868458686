export const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      display: false,
    },
  },
  {
    name: 'projectName',
    label: 'Project Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'city',
    label: 'City',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'state',
    label: 'State',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'completionDate',
    label: 'Completion Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'editor',
    label: 'Editor',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'viewer',
    label: 'Viewer',
    options: {
      filter: false,
      sort: false,
    },
  },
]

export const data = [
  {
    id: 1,
    projectName: 'Project 1',
    city: 'City',
    state: 'State',
    completionDate: 'MM/DD/YY',
  },
  {
    id: 2,
    projectName: 'Project 2',
    city: 'City',
    state: 'State',
    completionDate: 'MM/DD/YY',
  },
  {
    id: 3,
    projectName: 'Project 3',
    city: 'City',
    state: 'State',
    completionDate: 'MM/DD/YY',
  },
]

export const options = {
  filter: false,
  viewColumns: false,
  selectableRows: 'none',
  responsive: 'standard',
}

export const personnelFormFields = {
  name: {
    type: 'select',
    label: 'Personnel Name',
    xs: 4,
    options: [],
    placeholder: 'Select',
    displayEmpty: true,
  },
  systemAuthorization: {
    type: 'select',
    label: 'System Authorization',
    xs: 4,
    options: [
      { value: 'Administrator', label: 'Administrator' },
      { value: 'Global Editor', label: 'Global Editor' },
      { value: 'Global Viewer', label: 'Global Viewer' },
      { value: 'Project Level', label: 'Project Level' },
    ],
    placeholder: 'Select',
    displayEmpty: true,
  },
}
