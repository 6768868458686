import { mobileNumber, zipCodeValidation } from '../Utils/regex'

export const formFields = {
  contactType: {
    type: 'select',
    label: 'Contact Type',
    isRequired: true,
    placeholder: 'Select',
    displayEmpty: true,
  },
  companyName: {
    type: 'select',
    label: 'Company Name',
    isSearchable: true,
    isRequired: true,
  },
  address: {
    type: 'text',
    label: 'Address 1',
  },
  city: {
    type: 'text',
    label: 'City',
  },
  state: {
    type: 'select',
    label: 'State',
    options: [],
    xs: 6,
    placeholder: 'Select',
    displayEmpty: true,
  },
  zipCode: {
    type: 'text',
    label: 'Zip Code',
    xs: 6,
    validate: [zipCodeValidation],
  },
  workPhone: {
    type: 'text',
    label: 'Work Phone # (W)',
    isPhoneNumber: true,
    xs: 6,
    validate: [mobileNumber],
  },
  extension: {
    type: 'text',
    label: 'Extension',
    xs: 6,
  },
}
