import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal } from '../../Utils/javascript'
import {
  ADD_UPDATE_ISSUE_FAILED,
  ADD_UPDATE_ISSUE_REQUESTED,
  ADD_UPDATE_ISSUE_SUCCESS,
  DELETE_ISSUE_FAILED,
  DELETE_ISSUE_REQUESTED,
  DELETE_ISSUE_SUCCESS,
  FETCH_ISSUES_LOG_FAILED,
  FETCH_ISSUES_LOG_REQUESTED,
  FETCH_ISSUES_LOG_SUCCESS,
} from '../constants/issuesLog'
import { updateSaveStatusAction } from './confirmation'

// fetch total counts of tiles
const fetchIssuesLogRequested = () => ({
  type: FETCH_ISSUES_LOG_REQUESTED,
})
const fetchIssuesLogSuccess = (payload) => ({
  type: FETCH_ISSUES_LOG_SUCCESS,
  payload,
})
const fetchIssuesLogFailed = (payload) => ({
  type: FETCH_ISSUES_LOG_FAILED,
  payload,
})

export const getIssuesLogData =
  ({ reportId, ocId, projectId, issueFilter, isLoading = true }) =>
  async (dispatch) => {
    if (isLoading) {
      dispatch(fetchIssuesLogRequested())
    }
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.issueLog}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}&issueFilter=${issueFilter}`,
    })

    if (data) {
      dispatch(fetchIssuesLogSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(
          fetchIssuesLogSuccess({
            results: [],
            canAddIssueLog: error?.response?.data?.canAddIssueLog,
          }),
        )
      }
      dispatch(fetchIssuesLogFailed(error.response))
    }
  }

const addUpdateIssueRequested = () => ({
  type: ADD_UPDATE_ISSUE_REQUESTED,
})
const addUpdateIssueSuccess = (payload) => ({
  type: ADD_UPDATE_ISSUE_SUCCESS,
  payload,
})
const addUpdateIssueFailed = (payload) => ({
  type: ADD_UPDATE_ISSUE_FAILED,
  payload,
})

export const addOrUpdateIssue =
  ({
    reportId,
    ocId,
    projectId,
    id = '',
    issueFilter,
    payload,
    adIssueSuccessfully,
  }) =>
  async (dispatch, getState) => {
    dispatch(addUpdateIssueRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.issueLog}${
        id && `${id}/`
      }?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: id ? method.patch : method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: `Issue ${id ? 'Updated' : 'Saved'} successfully!`,
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    const { issuesLogData } = getState().issuesLog
    if (data) {
      dispatch(
        getIssuesLogData({
          reportId,
          ocId,
          projectId,
          issueFilter,
          isLoading: false,
        }),
      )
      dispatch(addUpdateIssueSuccess([...issuesLogData, data]))
      adIssueSuccessfully()
    }
    if (error) {
      dispatch(addUpdateIssueFailed(error.response))
    }
  }

// Delete Issue
const deleteIssueRequested = () => ({
  type: DELETE_ISSUE_REQUESTED,
})
export const deleteIssueSuccess = (payload) => ({
  type: DELETE_ISSUE_SUCCESS,
  payload,
})
const deleteIssueFailed = (payload) => ({
  type: DELETE_ISSUE_FAILED,
  payload,
})

export const deleteIssueLog =
  ({ issueLogDelete, reportId, ocId, projectId }) =>
  async (dispatch, getState) => {
    dispatch(deleteIssueRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.issueLog}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: { issueLog: [issueLogDelete] },
      showToastMessage: true,
      toastMessage: 'Issue deleted successfully!',
    })
    if (status) {
      const { issuesLogData } = getState().issuesLog
      const permitTrackerDataAfterDeletion = issuesLogData.filter(
        ({ id }) => !equal(id, issueLogDelete),
      )
      dispatch(deleteIssueSuccess(permitTrackerDataAfterDeletion))
    }
    if (error) {
      dispatch(deleteIssueFailed(error.response))
    }
  }
