import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { routes } from '../Description/routes.description'
import { spinnerSize } from '../Utils/constant'
import { ternary } from '../Utils/javascript'
import RequireAuth from './RequireAuth'
import RestrictPostLogin from './RestrictPostLogin'
import RestrictUnauthorizedProject from './RestrictUnauthorizedProject'
import DKTCircularProgress from '../Shared/DKTCircularProgress'
import RestrictUnauthorizedReport from './RestrictUnauthorizedReport'

const renderRoutes = (routes, systemAuthorization) =>
  routes.map(
    (
      {
        subRoutes,
        isProtected,
        isRestrictUnauthorizedProject,
        isRestrictUnauthorizedReport,
        sideBarLinkId,
        postLoginAccess = true,
        redirectRoleWise = false,
        element,
        ...route
      },
      index,
    ) => (
      <Route
        {...route}
        element={ternary(
          redirectRoleWise,
          <Navigate to="/dashboard" />,
          ternary(
            isProtected,
            isRestrictUnauthorizedProject && sideBarLinkId ? (
              <RestrictUnauthorizedProject
                sideBarLinkId={sideBarLinkId}
                requiredRoles={route?.requiredRoles}
              >
                {element}
              </RestrictUnauthorizedProject>
            ) : isRestrictUnauthorizedReport && sideBarLinkId ? (
              <RestrictUnauthorizedReport
                sideBarLinkId={sideBarLinkId}
                requiredRoles={route?.requiredRoles}
              >
                {element}
              </RestrictUnauthorizedReport>
            ) : (
              <RequireAuth {...route}>{element}</RequireAuth>
            ),
            ternary(
              postLoginAccess,
              element,
              <RestrictPostLogin>{element}</RestrictPostLogin>,
            ),
          ),
        )}
        key={index}
      >
        {subRoutes && renderRoutes(subRoutes, systemAuthorization)}
      </Route>
    ),
  )

const index = () => {
  const { systemAuthorization, isSystemAuthorizationLoading } = useSelector(
    ({ auth }) => auth,
  )
  const [authorizationLoading, setAuthorizationLoading] = useState(true)
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    setAuthorizationLoading(isSystemAuthorizationLoading)
  }, [isSystemAuthorizationLoading])

  return ternary(
    !authorizationLoading,
    <Routes>{renderRoutes(routes, systemAuthorization)}</Routes>,
    <Stack height="100vh" alignItems="center" justifyContent="center">
      <DKTCircularProgress size={spinnerSize.lg} />
    </Stack>,
  )
}
export default index
