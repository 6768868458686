// Fetch company
export const FETCH_COMPANY_REQUESTED = 'FETCH_COMPANY_REQUESTED'
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_FAILED = 'FETCH_COMPANY_FAILED'

// Update company
export const UPDATE_COMPANY_REQUESTED = 'UPDATE_COMPANY_REQUESTED'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED'

// Fetch company service-offered
export const FETCH_COMPANY_SERVICE_OFFERED_REQUESTED =
  'FETCH_COMPANY_SERVICE_OFFERED_REQUESTED'
export const FETCH_COMPANY_SERVICE_OFFERED_SUCCESS =
  'FETCH_COMPANY_SERVICE_OFFERED_SUCCESS'
export const FETCH_COMPANY_SERVICE_OFFERED_FAILED =
  'FETCH_COMPANY_SERVICE_OFFERED_FAILED'

// Fetch company service-state-location
export const FETCH_COMPANY_SERVICE_STATE_LOCATION_REQUESTED =
  'FETCH_COMPANY_SERVICE_STATE_LOCATION_REQUESTED'
export const FETCH_COMPANY_SERVICE_STATE_LOCATION_SUCCESS =
  'FETCH_COMPANY_SERVICE_STATE_LOCATION_SUCCESS'
export const FETCH_COMPANY_SERVICE_STATE_LOCATION_FAILED =
  'FETCH_COMPANY_SERVICE_STATE_LOCATION_FAILED'

// Fetch company service-us-territories
export const FETCH_COMPANY_SERVICE_US_TERRITORIES_REQUESTED =
  'FETCH_COMPANY_SERVICE_US_TERRITORIES_REQUESTED'
export const FETCH_COMPANY_SERVICE_US_TERRITORIES_SUCCESS =
  'FETCH_COMPANY_SERVICE_US_TERRITORIES_SUCCESS'
export const FETCH_COMPANY_SERVICE_US_TERRITORIES_FAILED =
  'FETCH_COMPANY_SERVICE_STATE_LOCATION_FAILED'

// Fetch company key personnel
export const FETCH_COMPANY_KEY_PERSONNEL_REQUESTED =
  'FETCH_COMPANY_KEY_PERSONNEL_REQUESTED'
export const FETCH_COMPANY_KEY_PERSONNEL_SUCCESS =
  'FETCH_COMPANY_KEY_PERSONNEL_SUCCESS'
export const FETCH_COMPANY_KEY_PERSONNEL_FAILED =
  'FETCH_COMPANY_KEY_PERSONNEL_FAILED'

// Add Update company
export const UPDATE_KEY_PERSONNEL_REQUESTED = 'UPDATE_KEY_PERSONNEL_REQUESTED'
export const UPDATE_KEY_PERSONNEL_SUCCESS = 'UPDATE_KEY_PERSONNEL_SUCCESS'
export const UPDATE_KEY_PERSONNEL_FAILED = 'UPDATE_KEY_PERSONNEL_FAILED'
