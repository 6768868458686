import { InputAdornment } from '@mui/material'
import classNames from 'classnames'
import React, { memo, useCallback } from 'react'
import {
  g702SplitColumns,
  g702SplitContracts,
  g703Options,
} from '../../../Description/trackers.description'
import DKTCurrencyTextField from '../../../Shared/DKTCurrencyTextField'
import DKTForm from '../../../Shared/DKTForm'
import DKTInput from '../../../Shared/DKTInput'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import { useTableBodyStyle } from '../../../Styles/g702Split.style'
import { DIVIDE, MINUS, MULTIPLY, PLUS } from '../../../Utils/constant'
import { checkIncludes, equal, ternary } from '../../../Utils/javascript'
import { checkIfValidDate } from '../../../Utils/regex'

const CustomBody = memo(
  ({
    columnName,
    value,
    readOnly,
    rowIndex,
    handleChange,
    isLimitLess = false,
    errors,
  }) => {
    const classes = useTableBodyStyle()
    if (equal(columnName, 'contracts')) {
      return g702SplitContracts[rowIndex]?.label
    }

    switch (rowIndex) {
      case 0:
      case 1:
      case 2:
      case 14:
        return (
          <DKTInput
            type={ternary(equal(rowIndex, 2), 'date', 'text')}
            value={value}
            disabled={readOnly}
            onChange={(e) =>
              handleChange(
                rowIndex,
                columnName,
                e.target.value,
                ternary(equal(rowIndex, 2), 'date', 'text'),
              )
            }
            className={`${classes.inputBorder} ${
              equal(rowIndex, 2) && classes.dateError
            }`}
            startAdornment={
              equal(rowIndex, 14) ? (
                <InputAdornment position="start">%</InputAdornment>
              ) : null
            }
            error={
              errors[rowIndex]?.[columnName] && errors[rowIndex]?.[columnName]
            }
          />
        )
      default:
        return (
          <DKTCurrencyTextField
            name={columnName}
            value={value}
            disabled={readOnly}
            currencySymbol="$"
            className={classes.inputBorder}
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(event, value) =>
              handleChange(rowIndex, columnName, value)
            }
            isLimitLess={isLimitLess}
          />
        )
    }
  },
)

const G702SplitTackerTable = ({
  data,
  setData,
  setIsUnSavedChanges,
  errors,
  setErrors,
  hasOnlyViewPermission,
}) => {
  const classes = useTableBodyStyle()

  const calculateValues = (first, second, action) => {
    first = Number(first) || 0
    second = Number(second) || 0

    switch (action) {
      case PLUS:
        return parseFloat(first + second)?.toFixed(2)
      case MINUS:
        return parseFloat(first - second)?.toFixed(2)
      case MULTIPLY:
        return parseFloat(first * second)?.toFixed(2)
      case DIVIDE:
        return !equal(second, '0') &&
          !equal(second, 0) &&
          !equal(second, null) &&
          !equal(second, undefined)
          ? parseFloat((first / second) * 100)?.toFixed(2)
          : 0
      default:
        throw new Error('Invalid action')
    }
  }

  const handleChange = useCallback(
    (row, columnName, value, type = 'text') => {
      setIsUnSavedChanges(true)
      setData((prevData) => {
        const clonedData = [...prevData]

        if (clonedData[row]) {
          clonedData[row][columnName] = value
          switch (row) {
            case 3:
            case 4:
              clonedData[5][columnName] = calculateValues(
                clonedData[3][columnName],
                clonedData[4][columnName],
                PLUS,
              )
              clonedData[13][columnName] = calculateValues(
                clonedData[5][columnName],
                clonedData[8][columnName],
                MINUS,
              )
              break
            case 6:
            case 7:
              clonedData[8][columnName] = calculateValues(
                clonedData[6][columnName],
                clonedData[7][columnName],
                MINUS,
              )
              clonedData[14][columnName] = calculateValues(
                clonedData[6][columnName],
                clonedData[5][columnName],
                DIVIDE,
              )
              clonedData[14].total = calculateValues(
                clonedData[6].total,
                clonedData[5].total,
                DIVIDE,
              )
              clonedData[13][columnName] = calculateValues(
                clonedData[5][columnName],
                clonedData[8][columnName],
                MINUS,
              )
              break
            case 8:
            case 9:
              clonedData[10][columnName] = calculateValues(
                clonedData[8][columnName],
                clonedData[9][columnName],
                MINUS,
              )

              break
            case 10:
            case 11:
              clonedData[12][columnName] = calculateValues(
                clonedData[10][columnName],
                clonedData[11][columnName],
                PLUS,
              )
              break
            case 5:
              clonedData[14][columnName] = calculateValues(
                clonedData[6][columnName],
                clonedData[5][columnName],
                DIVIDE,
              )
              clonedData[14].total = calculateValues(
                clonedData[6].total,
                clonedData[5].total,
                DIVIDE,
              )
              break
            default:
              break
          }
        }
        clonedData?.forEach((item, index) => {
          if (index > 2 && index + 1 !== clonedData?.length)
            clonedData[index] = {
              ...item,
              total: Object.entries(item)
                .filter(([key]) => key !== 'name' && key !== 'total')
                .reduce(
                  // eslint-disable-next-line no-unused-vars
                  (acc, [key, value]) => calculateValues(acc, value, PLUS),
                  0,
                ),
            }
        })
        if (equal(type, 'date')) {
          if (value && !checkIfValidDate(value)) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [row]: {
                ...prevErrors[row],
                ...{ [columnName]: 'Invalid date' },
              },
            }))
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [row]: {
                ...prevErrors[row],
                ...{ [columnName]: '' },
              },
            }))
          }
        }

        return clonedData
      })
    },
    [setData],
  )

  const customBodyRender = (columnName, value, tableData) => {
    const { rowIndex } = tableData
    const indexToDisabled = [5, 8, 10, 12, 13, 14]
    const isRowDisabled =
      checkIncludes(rowIndex, indexToDisabled) ||
      equal(columnName, 'total') ||
      hasOnlyViewPermission

    return (
      <DKTForm autoComplete="off">
        <CustomBody
          readOnly={isRowDisabled}
          {...{
            columnName,
            value,
            rowIndex,
            handleChange,
            errors,
          }}
        />
      </DKTForm>
    )
  }

  const columns = g702SplitColumns.map((column) => ({
    ...column,
    options: {
      ...column.options,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (tableMeta.rowIndex < 3 && column.name === 'total') {
          return null
        }
        return customBodyRender(column.name, value, tableMeta, updateValue)
      },
    },
  }))

  const options = {
    ...g703Options,
    rowsPerPage: 100,
    responsive: 'scrollMaxHeight',
    rowsPerPageOptions: [100, 200, 500],
    fixeHeader: true,
    selectableRows: 'none',
    pagination: false,
  }
  return (
    <DKTMuiDataTable
      columns={columns}
      options={options}
      data={data}
      className={ternary(
        data?.length,
        classes.g702TableData,
        classNames(classes.g702TableData, classes.g702TableNoData),
      )}
    />
  )
}

export default memo(G702SplitTackerTable)
