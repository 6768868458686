import { Stack } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import {
  fetchProjectListSuccess,
  getProjectList,
} from '../Redux/actions/projects'
import DKTCircularProgress from '../Shared/DKTCircularProgress'
import { clientPortal, spinnerSize } from '../Utils/constant'

import { equal, ternary } from '../Utils/javascript'

const RestrictUnauthorizedProject = ({
  children,
  sideBarLinkId,
  requiredRoles,
}) => {
  const { slug: projectId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentProject } = useSelector(({ projects }) => projects)
  const { portalType } = useSelector(({ auth }) => auth)
  useEffect(() => {
    if (
      equal(portalType, clientPortal) &&
      !requiredRoles?.includes(clientPortal)
    ) {
      navigate('/dashboard')
    }
  }, [requiredRoles, portalType, clientPortal])

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(projectId)) {
      dispatch(getProjectList(projectId))
    }
    return () => {
      dispatch(fetchProjectListSuccess([]))
    }
  }, [dispatch, projectId])
  const isAccess =
    equal(projectId, 'new-project') ||
    currentProject?.disabledFields?.includes(sideBarLinkId)

  if (!currentProject)
    return (
      <Stack height="100vh" alignItems="center" justifyContent="center">
        <DKTCircularProgress size={spinnerSize.lg} />
      </Stack>
    )

  return ternary(isAccess, <Navigate to="/projects" replace />, children)
}

export default RestrictUnauthorizedProject
