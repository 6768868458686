import { Box, Grid, InputLabel, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { formFields } from '../../Description/constructionSchedule.description'
import useForm from '../../Hooks/useForm'
import { InnerLayout } from '../../Layout/project'
import DKTButton from '../../Shared/DKTButton'
import DKTInput from '../../Shared/DKTInput'
import DKTSelect from '../../Shared/DKTSelect'
import { entries, equal, gt, isArray, ternary } from '../../Utils/javascript'
import { getProjectList } from '../../Redux/actions/projects'
import {
  createAndUpdateConstructionSchedule,
  getConstructionSchedule,
  getOwnerContractorMapping,
  addConstructionSchedule,
  deleteConstructionSchedule,
} from '../../Redux/actions/constructionSchedule'
import { renderFormFields as renderFormFieldsControl } from '../../Utils/renderFormFields'
import DKTTextArea from '../../Shared/DKTTextArea'
import { SA, spinnerSize, swapWarningOC } from '../../Utils/constant'
import { showToast } from '../../Utils/toastService'
import DKTButtonSelect from '../../Shared/DKTButtonSelect'
import DKTDialog from '../../Shared/DKTDialog'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import DKTForm from '../../Shared/DKTForm'
import { updateOnSaveStatus } from '../../Redux/actions/confirmation'
import { CLEAR_CONSTRUCTION_SCHEDULE } from '../../Redux/constants/constructionSchedule'

const useStyles = makeStyles(() => ({
  addBtn: {
    marginLeft: 230,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  inlineLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 'bold',
      color: '#2D2D2D',
      fontSize: 14,
      marginBottom: 8,
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))

const ConstructionSchedule = () => {
  const dispatch = useDispatch()
  const { slug } = useParams()
  const classes = useStyles()

  const [projectOptionChoicesData, setProjectOptionChoicesData] = useState({})
  const [constructionScheduleId, setConstructionScheduleId] = useState(null)
  const [deleteNotificationOpen, setOpenDeleteNotification] =
    React.useState(false)
  const [isSaveWarningOpen, setIsSaveWarningOpen] = useState(false)
  const [tempScData, setTempScData] = useState({ id: null, add: false })
  const [isHandleChange, setHandleChange] = useState(false)
  const { projectOptionChoices, currentProject } = useSelector(
    ({ projects }) => projects,
  )
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)
  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+slug]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission = isGlobalViewer || isProjectViewer

  const {
    constructionScheduleList,
    isCreateAndUpdateConstructionScheduleLoading,
    ownerContractorMapping,
    activeConstruction,
    constructionScheduleError,
    isDeletingConstructionSchedule,
    isConstructionScheduleListLoading,
  } = useSelector(({ constructionSchedule }) => constructionSchedule)

  const {
    values,
    errors,
    handleChange,
    setValues,
    handleSubmit,
    resetForm,
    setErrors,
    setFieldValue,
    // isDirty,
    setIsDirty,
    setDummyData,
  } = useForm(
    {
      ...formFields.generalConstructionScheduleFields,
      ...formFields.lenderConstructionScheduleFields,
      ...formFields.ownerContractorAgreementMapping,
    },
    CLEAR_CONSTRUCTION_SCHEDULE,
  )

  useEffect(() => {
    dispatch(getProjectList(slug))
    setValues({ ...values, project: slug })
    setDummyData({ ...values, project: slug })
  }, [dispatch, setValues, slug])

  useEffect(() => {
    dispatch(getConstructionSchedule(slug))
  }, [dispatch, slug])

  useEffect(() => {
    if (constructionScheduleError) {
      const errorData = { ...errors }
      Object.entries(constructionScheduleError).forEach(
        ([errorName, [errorDesc]]) => {
          errorData[errorName] = errorDesc
        },
      )
      setErrors(errorData)
    }
    return () => {
      setErrors({})
    }
  }, [constructionScheduleError, isDeletingConstructionSchedule])

  useEffect(() => {
    if (!constructionScheduleList?.length) {
      handleAddNewConstructionSchedule()
    }
  }, [constructionScheduleList, isDeletingConstructionSchedule])

  useEffect(() => {
    if (activeConstruction) {
      setConstructionScheduleId(activeConstruction)
    } else {
      setConstructionScheduleId(constructionScheduleList[0]?.id)
    }
  }, [
    activeConstruction,
    constructionScheduleList[0]?.id,
    isDeletingConstructionSchedule,
  ])

  useEffect(() => {
    let dynamicOption = {}
    if (projectOptionChoices) {
      dynamicOption = projectOptionChoices
    }
    const dynamicOptionForMapping = (ary) => {
      const res = ary?.map((sts) => ({
        value: `${sts?.id}`,
        label: sts?.uniqueCode,
      }))
      return res
    }
    const mapping = dynamicOptionForMapping(ownerContractorMapping)
    setProjectOptionChoicesData({
      ...dynamicOption,
      mapping,
    })
  }, [projectOptionChoices, ownerContractorMapping])

  useEffect(() => {
    if (constructionScheduleList?.length && slug) {
      const populateValue = constructionScheduleList?.find((project) =>
        equal(project?.id, +constructionScheduleId),
      )
      const mapping = populateValue?.mapping?.map((e) => `${e}`)
      const populateFinal = {
        ...populateValue,
        mapping,
      }
      setValues(populateFinal)
      setDummyData(populateFinal)
    }
  }, [
    constructionScheduleList,
    setValues,
    constructionScheduleId,
    isDeletingConstructionSchedule,
    projectOptionChoicesData,
  ])

  useEffect(() => {
    if (
      equal(projectOptionChoicesData?.mapping?.length, 1) &&
      !isConstructionScheduleListLoading
    ) {
      setFieldValue(
        'mapping',
        [`${projectOptionChoicesData?.mapping[0]?.value}`],
        'constructionSchedule',
      )
    }
  }, [projectOptionChoicesData?.mapping])

  useEffect(() => {
    const currentConstruction = constructionScheduleList?.find((construction) =>
      equal(construction?.id, +constructionScheduleId),
    )
    if (currentConstruction) {
      if (
        currentConstruction?.uniqueCode ||
        currentConstruction?.isNewConstructionSchedule
      ) {
        dispatch(
          getOwnerContractorMapping(slug, currentConstruction?.uniqueCode),
        )
      }
    }
  }, [
    dispatch,
    constructionScheduleId,
    constructionScheduleList,
    isDeletingConstructionSchedule,
  ])

  useEffect(() => {
    if (tempScData?.id) {
      if (isHandleChange) {
        setIsSaveWarningOpen(true)
      } else {
        resetForm()
        setTimeout(() => {
          setConstructionScheduleId(tempScData?.id)
        }, 500)
        setErrors({})
      }
    } else if (tempScData?.add) {
      if (isHandleChange) {
        setIsSaveWarningOpen(true)
      } else {
        handleAddNewConstructionSchedule()
        resetForm()
      }
    }
  }, [tempScData])

  useEffect(() => {
    setHandleChange(!!constructionScheduleError)
  }, [constructionScheduleError, constructionScheduleList])

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            defaultValue,
            options,
            readOnly,
            ...formField
          },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <Grid key={index} item xs={formField.xs ?? 12}>
            <FormControl
              {...formField}
              isRequired={isRequired}
              readOnly={readOnly}
              options={projectOptionChoicesData[name] || options || []}
              id={name}
              name={name}
              value={(values && values[name]) || defaultValue || ''}
              onChange={(e) => {
                handleFieldChange(e)
              }}
              error={errors[name]}
              disabled={hasOnlyViewPermission || formField.disabled}
            />
          </Grid>
        )
      },
    )
  const renderFormFields = useCallback(
    (formFields, dynamicFormFields = false) => (
      <Grid container spacing={2}>
        {isArray(formFields)
          ? dynamicFormFields
            ? formFields?.map((formField) =>
                renderFormFieldsControl({
                  formFields: {
                    test: { type: 'checkbox', ...formField },
                  },
                }),
              )
            : formFields?.map((formField) =>
                mapFormFields({
                  formFields: formField?.data,
                  makeFieldNameUnique: true,
                  id: formField?.id,
                }),
              )
          : mapFormFields({ formFields })}
      </Grid>
    ),
    [projectOptionChoicesData, values, errors],
  )
  const isValidField = Object?.values(errors)?.every((error) => !error)

  const handleAddNewConstructionSchedule = () => {
    if (hasOnlyViewPermission) return
    if (constructionScheduleList?.length && slug) {
      const populateValue = constructionScheduleList?.find((project) =>
        equal(project?.id, +constructionScheduleId),
      )
      if (!populateValue?.isOcAvailable) {
        showToast(swapWarningOC)
        return
      }
    }
    const id = new Date().getTime()
    dispatch(addConstructionSchedule(id))
    setConstructionScheduleId(id)
    resetForm()
  }

  const handleSave = () => {
    const isFormValid = handleSubmit()
    const filteredObj = { ...values }
    Object.keys(filteredObj).forEach((key) => {
      if (equal(filteredObj[key], undefined) || equal(filteredObj[key], '')) {
        delete filteredObj[key]
        filteredObj[key] = null
      }
    })
    if (
      equal(filteredObj?.mapping?.length, 0) ||
      equal(filteredObj?.mapping, null)
    ) {
      delete filteredObj.mapping
      filteredObj.isDeleted = true
    }
    const body = {
      ...filteredObj,
      project: slug,
    }
    if (isFormValid) {
      dispatch(
        createAndUpdateConstructionSchedule(body, constructionScheduleId),
      )
    } else {
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }

  const handleClose = () => {
    setOpenDeleteNotification(false)
  }

  const handleDeleteContractor = () => {
    const body = {
      constructionSchedule: [constructionScheduleId],
    }
    if (gt(constructionScheduleList?.length, 1)) {
      dispatch(deleteConstructionSchedule(body, handleClose))
    } else {
      showToast("You can't delete this record.", 'error')
      handleClose()
    }
  }
  const actionList = [
    { label: 'Delete', onClick: () => setOpenDeleteNotification(true) },
  ]
  const actions = () => (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Stack direction="row" gap={2} className={classes.rightButton}>
        {ternary(
          !hasOnlyViewPermission,
          <DKTButtonSelect
            options={actionList}
            disabled={isConstructionScheduleListLoading || true}
          />,
          null,
        )}
        <DKTButton
          className={classes.rightButton}
          onClick={handleSave}
          disabled={
            hasOnlyViewPermission ||
            isCreateAndUpdateConstructionScheduleLoading ||
            isConstructionScheduleListLoading ||
            !isValidField
          }
        >
          {ternary(
            isCreateAndUpdateConstructionScheduleLoading,
            'Saving...',
            'Save',
          )}
        </DKTButton>
      </Stack>
    </Box>
  )

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteContractor}>
        {ternary(
          isDeletingConstructionSchedule,
          <DKTCircularProgress size={spinnerSize?.sm} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const activeConstructionSchedule = constructionScheduleList?.find(
    (constructionSchedule) =>
      equal(constructionSchedule?.id, constructionScheduleId),
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setTempScData({ id: null, add: false })
  }
  const confirmSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setConstructionScheduleId(tempScData?.id)
    setIsDirty(false)
    setHandleChange(false)
    setErrors({})
    if (tempScData?.add) {
      handleAddNewConstructionSchedule()
      resetForm()
    }
    setTempScData({ id: null, add: false })
  }

  const handleFieldChange = (...rest) => {
    handleChange(...rest)
    setHandleChange(true)
  }

  return (
    <InnerLayout
      contentTitle="CONSTRUCTION SCHEDULE"
      actions={actions}
      maxWidth="md"
      title={currentProject?.projectName || 'New Project'}
      isShowMenu
    >
      {equal(isConstructionScheduleListLoading, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isConstructionScheduleListLoading, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Construction Schedule data.
            Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <Grid container>
          <Grid item xs={12} xl={8}>
            <Grid
              container
              columnSpacing={10}
              columnGap={{ sm: 0, xl: 11 }}
              rowSpacing={3}
            >
              <Grid item lg={5} sm={6}>
                <InputLabel
                  sx={{
                    fontWeight: 'medium',
                    color: 'gray.extraDark',
                    borderBottom: '1px solid #E5E5E5',
                    fontSize: '14px',
                    paddingBottom: '2px',
                    marginBottom: '10px',
                  }}
                >
                  General Contractor Schedule
                </InputLabel>
                <DKTForm autoComplete="off">
                  {renderFormFields(
                    formFields.generalConstructionScheduleFields,
                  )}
                </DKTForm>
              </Grid>
              <Grid item lg={5} sm={6}>
                <InputLabel
                  sx={{
                    fontWeight: 'medium',
                    color: 'gray.extraDark',
                    borderBottom: '1px solid #E5E5E5',
                    fontSize: '14px',
                    paddingBottom: '2px',
                    marginBottom: '10px',
                  }}
                >
                  Investor/Lender Underwritten Schedule (optional)
                </InputLabel>
                <DKTForm autoComplete="off">
                  {renderFormFields(
                    formFields.lenderConstructionScheduleFields,
                  )}
                </DKTForm>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Comments
                </Typography>
                <DKTTextArea
                  rows={6}
                  name="comments"
                  placeholder="Placeholder Text"
                  value={values?.comments || ''}
                  onChange={handleFieldChange}
                  disabled={hasOnlyViewPermission}
                />
              </Grid>
              <DKTDialog
                open={deleteNotificationOpen}
                handleClose={handleClose}
                title="&nbsp;"
                actions={deleteNotificationAction}
                maxWidth="xs"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box className={classes.deleteNotificationContent}>
                      <Typography variant="h5">
                        {`Are you sure you want to delete ${activeConstructionSchedule?.uniqueCode}?`}
                      </Typography>
                      <Typography variant="body1">
                        You can&apos;t undo this action
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </DKTDialog>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isSaveWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isHandleChange} onSave={handleSave} />
    </InnerLayout>
  )
}

export default ConstructionSchedule
