// Fetch user profile
export const FETCH_USER_PROFILE_REQUESTED = 'FETCH_USER_PROFILE_REQUESTED'
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS'
export const FETCH_USER_PROFILE_FAILED = 'FETCH_USER_PROFILE_FAILED'
// Update user profile
export const UPDATE_USER_PROFILE_REQUESTED = 'UPDATE_USER_PROFILE_REQUESTED'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED'
// Update user password
export const UPDATE_USER_PASSWORD_REQUESTED = 'UPDATE_USER_PASSWORD_REQUESTED'
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS'
export const UPDATE_USER_PASSWORD_FAILED = 'UPDATE_USER_PASSWORD_FAILED'
