import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { equal, ternary } from '../Utils/javascript'
import { SA, publish } from '../Utils/constant'

const usePermission = () => {
  const [hasOnlyViewPermission, setHasOnlyViewPermission] = useState(false)
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { slug: projectId } = useParams()

  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+projectId]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)

  useEffect(() => {
    const onlyViewPermission =
      isGlobalViewer ||
      isProjectViewer ||
      ternary(
        reportData?.isSecondLastReport,
        equal(reportData?.lastReportStatus, publish),
        !reportData?.isLastReport,
      )
    setHasOnlyViewPermission(onlyViewPermission)

    return () => setHasOnlyViewPermission(false)
  }, [isGlobalViewer, isProjectViewer, reportData])

  return {
    hasOnlyViewPermission,
    isGlobalViewer,
    isProjectViewer,
  }
}

export default usePermission
