import { Stack, Box } from '@mui/material'
import React from 'react'
import Navbar from './admin/Navbar'

const MainLayout = ({ children }) => (
  <Stack direction="column" minHeight="100vh">
    <Box position="fixed" right="0" left="0" zIndex="999">
      <Navbar />
    </Box>
    {children}
  </Stack>
)

export default MainLayout
