import { RemoveCircleOutline } from '@mui/icons-material'
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import { CircledPlusIcon, DragIcon } from '../../../Assets/SVGs'
import {
  milestoneTrackerColumnKeys,
  milestoneTrackerColumns,
} from '../../../Description/trackers.description'
import {
  deleteMilestoneTracker,
  saveMilestoneTracker,
} from '../../../Redux/actions/constructionScheduleReport'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTInput from '../../../Shared/DKTInput'
import { useStyles } from '../../../Styles/constructionScheduleReport.style'
import { milestoneDeleteMessage } from '../../../Utils/constant'
import {
  equal,
  getDifferenceInDays,
  keys,
  length,
  ternary,
} from '../../../Utils/javascript'
import { showToast } from '../../../Utils/toastService'
import { checkIfValidDate } from '../../../Utils/regex'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTForm from '../../../Shared/DKTForm'
import usePermission from '../../../Hooks/usePermission'

const MileStoneTracker = ({ open, onClose, ocId }) => {
  const {
    milestoneTracker,
    isSavingMilestoneTracker,
    isDeletingMilestoneTrackers,
    isFetchingMilestoneTracker,
  } = useSelector(
    ({ constructionScheduleReport }) => constructionScheduleReport,
  )
  const [data, setData] = useState(milestoneTracker || [])
  const [errors, setErrors] = useState({})
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)

  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { slug: projectId, reportId } = useParams()
  const { hasOnlyViewPermission } = usePermission()

  const milestoneToDelete = useRef(null)
  const columns = ternary(
    hasOnlyViewPermission,
    milestoneTrackerColumns.slice(1),
    milestoneTrackerColumns,
  )

  useEffect(() => {
    setData(milestoneTracker || [])
  }, [milestoneTracker])

  const openDeleteNotification = () => setIsDeleteNotificationOpen(true)
  const closeDeleteNotification = () => setIsDeleteNotificationOpen(false)

  useEffect(() => {
    const res = data?.map(
      (value, i) =>
        JSON.stringify(value) !== JSON.stringify(milestoneTracker[i]),
    )
    if (res.length) {
      setIsUnSavedChanges(
        ternary(
          res.some((val) => val === true),
          true,
          false,
        ),
      )
    }
  }, [data])

  const handleChange = (rowId, type) => (e) => {
    setData((prevData) => {
      const clonedData = [...prevData]
      const { name, value } = e.target
      const rowIndex = clonedData.findIndex(
        ({ id, uniqueId }) => equal(id, rowId) || equal(uniqueId, rowId),
      )
      clonedData.splice(rowIndex, 1, {
        ...clonedData[rowIndex],
        [name]: value,
      })
      if (equal(type, 'date') && value) {
        if (!checkIfValidDate(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [rowId]: {
              ...prevErrors[rowId],
              ...{ [name]: 'Invalid date' },
            },
          }))
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [rowId]: {
              ...prevErrors[rowId],
              ...{ [name]: '' },
            },
          }))
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [rowId]: {
            ...prevErrors[rowId],
            ...{ [name]: '' },
          },
        }))
      }
      if (equal(name, 'completion') || equal(name, 'start')) {
        if (
          clonedData[rowIndex]?.start &&
          clonedData[rowIndex]?.completion &&
          checkIfValidDate(clonedData[rowIndex]?.start) &&
          checkIfValidDate(clonedData[rowIndex]?.completion)
        ) {
          const differenceInDays = getDifferenceInDays(
            clonedData[rowIndex]?.start,
            clonedData[rowIndex]?.completion,
          )
          if (differenceInDays > 0) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [rowId]: {
                ...prevErrors[rowId],
                ...{
                  completion: 'Less than start date',
                },
              },
            }))
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [rowId]: {
                ...prevErrors[rowId],
                ...{ completion: '' },
              },
            }))
          }
        }
      }
      return clonedData
    })
  }

  const removeRow = () => {
    const milestone = milestoneToDelete.current
    // If milestone is not local
    if (milestone?.id) {
      dispatch(
        deleteMilestoneTracker({
          milestoneTrackerToDelete: [milestone?.id],
          reportId,
          ocId,
          projectId,
          milestones: data,
        }),
      ).then(() => {
        closeDeleteNotification()
        setErrors((prev) => {
          const clonePrev = { ...prev }
          delete clonePrev[milestone?.id]
          return clonePrev
        })
      })
      return
    }
    // If milestone is local
    setData((prevData) => {
      const clonedData = [...prevData]
      const rowIndex = clonedData.findIndex(({ uniqueId }) =>
        equal(uniqueId, milestone?.uniqueId),
      )
      if (~rowIndex) clonedData.splice(rowIndex, 1)
      return clonedData
    })
    closeDeleteNotification()
    showToast(milestoneDeleteMessage, 'success')
    setErrors((prev) => {
      const clonePrev = { ...prev }
      delete clonePrev[milestone?.uniqueId]
      return clonePrev
    })
  }

  const handleDelete = (row) => {
    openDeleteNotification()
    milestoneToDelete.current = row
  }

  const customRowCellsRender = (row) => (
    <>
      {!hasOnlyViewPermission && (
        <TableCell key={row?.id} className={classes.dragIcon}>
          <Box>
            <DragIcon />
          </Box>
        </TableCell>
      )}
      {milestoneTrackerColumnKeys.map((columnName, index) => (
        <TableCell
          key={index}
          sx={{ py: 0 }}
          className={
            equal(columnName, 'start') || equal(columnName, 'completion')
              ? classes.modalDateCell
              : equal(columnName, 'milestoneName')
              ? classes.name
              : ''
          }
        >
          <DKTForm autoComplete="off">
            <DKTInput
              type={ternary(
                equal(columnName, 'start') || equal(columnName, 'completion'),
                'date',
                'text',
              )}
              name={columnName}
              className={
                equal(columnName, 'start') || equal(columnName, 'completion')
                  ? `${classes.modalDate} ${classes.dateError}`
                  : classes.input
              }
              value={row[columnName]}
              onChange={handleChange(
                row.id ?? row.uniqueId,
                ternary(
                  equal(columnName, 'start') || equal(columnName, 'completion'),
                  'date',
                  'text',
                ),
              )}
              disabled={hasOnlyViewPermission}
              dateSx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none !important',
                },
                marginTop: '-18px !important',
              }}
              error={
                equal(columnName, 'start') || equal(columnName, 'completion')
                  ? errors[row.id ?? row.uniqueId]?.[columnName]
                  : ''
              }
            />
          </DKTForm>
        </TableCell>
      ))}
      {!hasOnlyViewPermission && (
        <TableCell sx={{ borderBottom: 'none', p: 0, pl: 1 }}>
          <IconButton
            onClick={() => handleDelete(row)}
            disabled={hasOnlyViewPermission}
          >
            <RemoveCircleOutline color="primary" />
          </IconButton>
        </TableCell>
      )}
    </>
  )

  const addRow = () =>
    setData((prevData) => [
      ...prevData,
      {
        uniqueId: new Date().valueOf(),
        name: '',
        start: null,
        completion: null,
        description: '',
        report: reportId,
        ownerContractor: ocId,
      },
    ])

  const onDragEnd = (result) => {
    if (result.destination && result.source) {
      setIsUnSavedChanges(true)
      setData((prevData) => {
        const clonedData = [...prevData]
        clonedData.splice(result.source.index, 1)
        clonedData.splice(
          result.destination.index,
          0,
          prevData[result.source.index],
        )

        return clonedData
      })
    }
  }

  const handleSave = () => {
    setIsUnSavedChanges(false)
    const payload = data.map((item) =>
      keys(item).reduce((result, key) => {
        result[key] = item[key] === '' ? null : item[key]
        return result
      }, {}),
    )
    dispatch(
      saveMilestoneTracker({
        payload,
        reportId,
        ocId,
        projectId,
        milestones: data,
      }),
    )
  }

  const milestoneTrackerActions = (
    <DKTButton
      sx={{ ml: 'auto' }}
      onClick={handleSave}
      disabled={
        hasOnlyViewPermission ||
        isSavingMilestoneTracker ||
        Object.values(errors)
          ?.map((el) => Object.values(el)?.filter(Boolean))
          ?.flat()?.length
      }
    >
      {ternary(
        isSavingMilestoneTracker,
        <DKTCircularProgress color={theme.palette.gray.dark} />,
        'Save',
      )}
    </DKTButton>
  )

  const deleteNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteNotification}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={removeRow}
        disabled={isDeletingMilestoneTrackers}
      >
        {ternary(
          isDeletingMilestoneTrackers,
          <DKTCircularProgress color={theme.palette.gray.dark} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }
  const handleCloseChangeOrder = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }

  return (
    <DKTDialog
      title="Milestone Tracker"
      open={open}
      handleClose={handleCloseChangeOrder}
      actions={
        isFetchingMilestoneTracker === 'FAILED' ? null : milestoneTrackerActions
      }
      maxWidth="lg"
      sx={{
        '& .MuiPaper-root': {
          width: '88% !important',
        },
      }}
    >
      {isFetchingMilestoneTracker === 'FAILED' ? (
        <Typography variant="body2" color="gray.extraDark">
          There might be some issue with fetching milestone tracker data. Please
          try contacting the admin or refreshing this page.
        </Typography>
      ) : (
        <>
          <Box
            className={classes.contactsTable}
            sx={{ maxHeight: '323px', overflow: 'auto' }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Table>
                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 999 }}>
                  <TableRow>
                    {columns?.map((column, index) => (
                      <TableCell key={index}>{column}</TableCell>
                    ))}
                    <TableCell sx={{ border: 0, width: 20 }}></TableCell>
                  </TableRow>
                </TableHead>

                <Droppable droppableId="mileStoneTracker">
                  {(provided) => (
                    <TableBody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {data && equal(length(data), 0) ? (
                        <TableRow>
                          <TableCell
                            colSpan={ternary(
                              milestoneTrackerColumns,
                              length(milestoneTrackerColumns),
                              1,
                            )}
                            sx={{ textAlign: 'center' }}
                          >
                            Sorry, no matching results found
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {data?.map((row, index) => (
                            <Draggable
                              key={row.id || row.uniqueId}
                              draggableId={(row.id || row.uniqueId).toString()}
                              index={index}
                              isDragDisabled={hasOnlyViewPermission}
                            >
                              {(provided) => (
                                <TableRow
                                  sx={{
                                    backgroundColor: '#fff',
                                    height: '50px',
                                  }}
                                  id={row.id}
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {customRowCellsRender instanceof Function &&
                                    customRowCellsRender(row)}
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </>
                      )}
                    </TableBody>
                  )}
                </Droppable>
              </Table>
            </DragDropContext>
          </Box>
          <DKTButton
            variant="text"
            color="primary"
            sx={{ textTransform: 'initial', px: 1, ml: '10px', mt: 2 }}
            startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
            onClick={addRow}
            disabled={hasOnlyViewPermission}
          >
            <Typography>Milestone Tracker Row</Typography>
          </DKTButton>
        </>
      )}

      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={closeDeleteNotification}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Box className={classes.deleteNotificationContent}>
          <Typography variant="h5">Are you sure you want to delete?</Typography>
          <Typography variant="body1">
            You can&apos;t undo this action
          </Typography>
        </Box>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}

      {!hasOnlyViewPermission && (
        <>
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={handleSave}
          />
          <DKTReactRouterPrompt
            isDirty={isUnSavedChanges}
            onSave={handleSave}
          />
        </>
      )}
    </DKTDialog>
  )
}

export default MileStoneTracker
