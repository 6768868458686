import { Typography } from '@mui/material'

export const emptyTableCellPlaceholder = '---'

export const options = {
  responsive: 'standard',
  filter: false,
  print: false,
  download: false,
  viewColumns: false,
  selectableRows: 'none',
  jumpToPage: false,
  pagination: false,
  tableBodyMaxHeight: '587px',
}

export const columns = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'projectName',
    label: 'Project Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'city',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'state',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'constructionType',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'completionDate',
    label: 'Completion Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'isFavorite',
    label: ' ',
    options: {
      filter: true,
      sort: true,
    },
  },
]

export const tilesArray = [
  {
    title: 'Total Projects',
    name: 'totalProjects',
  },
  {
    title: 'Completed Projects',
    name: 'completed',
    projectStatus: 'Completed',
  },
  {
    title: 'Active Projects',
    name: 'active',
    projectStatus: 'Active',
  },
  {
    title: 'New Projects',
    name: 'new',
    projectStatus: 'New',
  },
  {
    title: 'UnPublished Reports',
    name: 'unpublishedReports',
    projectStatus: 'UP',
  },
]

export const filterTilesArray = [
  {
    title: (
      <>
        100% Complete
        <Typography>Last 30 days</Typography>
      </>
    ),
    name: 'hundredPercentReportIssued',
    tiles: 'hundredPercentIssued',
  },
  {
    title: <Typography>UnPublished Reports</Typography>,
    name: 'unpublishedReports',
    tiles: 'UP',
  },
  {
    title: (
      <>
        Reports Issued
        <Typography>Last 7 days</Typography>
      </>
    ),
    name: 'sevenReportIssued',
    tiles: 'sevenReportIssued',
  },
  {
    title: (
      <>
        Reports Issued
        <Typography>Last 30 days</Typography>
      </>
    ),
    name: 'thirtyReportIssued',
    tiles: 'thirtyReportIssued',
  },
  {
    title: (
      <>
        Outstanding Reports
        <Typography color="#FF0000"> &gt; 60 days</Typography>
      </>
    ),
    name: 'outstandingReport',
    tiles: 'outstandingReport',
  },
]

export const formFields = {
  searchProject: {
    search: {
      type: 'text',
      name: 'searchProject',
    },
  },
  selectSortFilter: {
    sortByIssue: {
      type: 'select',
      isDynamicOptions: true,
      placeholder: 'Sort By Issue',
      displayEmpty: true,
      sx: { color: 'red' },
      placeholderColor: '#FF0000',
    },
  },
  filters: {
    projectType: {
      type: 'select',
      label: 'Project Type',
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      xs: 3.3,
    },
    projectStatus: {
      type: 'select',
      label: 'Project Status',
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      xs: 3.3,
    },
    constructionType: {
      type: 'select',
      label: 'Construction Type',
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      xs: 3.3,
    },
  },
}

export const sortByIssueOptions = [
  {
    label: 'Cost Overrun (High to Low)',
    value: 'costOverrun(HL)',
  },
  {
    label: 'Cost Overrun (Low to High)',
    value: 'costOverrun(LH)',
  },
  {
    label: 'Schedule Delay (High to Low)',
    value: 'scheduleDelay(HL)',
  },
  {
    label: 'Schedule Delay (Low to High)',
    value: 'scheduleDelay(LH)',
  },
  {
    label: 'Report Outstanding (High to Low)',
    value: 'reportOutstanding(HL)',
  },
  {
    label: 'Report Outstanding (Low to High)',
    value: 'reportOutstanding(LH)',
  },
]
