import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  CREATE_THIRD_PARTY_REPORTS_FAILED,
  CREATE_THIRD_PARTY_REPORTS_REQUESTED,
  CREATE_THIRD_PARTY_REPORTS_SUCCESS,
  CREATE_THIRD_PARTY_REPORTS_SECTION_FAILED,
  CREATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED,
  CREATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS,
  DELETE_THIRD_PARTY_REPORTS_FAILED,
  DELETE_THIRD_PARTY_REPORTS_REQUESTED,
  DELETE_THIRD_PARTY_REPORTS_SUCCESS,
  FETCH_THIRD_PARTY_REPORTS_LIST_FAILED,
  FETCH_THIRD_PARTY_REPORTS_LIST_REQUESTED,
  FETCH_THIRD_PARTY_REPORTS_LIST_SUCCESS,
  FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_FAILED,
  FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_REQUESTED,
  FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_SUCCESS,
  IMPORT_THIRD_PARTY_REPORT_FAILED,
  IMPORT_THIRD_PARTY_REPORT_REQUESTED,
  IMPORT_THIRD_PARTY_REPORT_SUCCESS,
  UPDATE_THIRD_PARTY_REPORTS_FAILED,
  UPDATE_THIRD_PARTY_REPORTS_REQUESTED,
  UPDATE_THIRD_PARTY_REPORTS_SUCCESS,
  UPDATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED,
  UPDATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS,
  UPDATE_THIRD_PARTY_REPORTS_SECTION_FAILED,
} from '../constants/thirdPartyReport'
import { updateSaveStatusAction } from './confirmation'
import { getReportList } from './reportMonitoring'

// Fetch third party report list
const fetchThirdPartyReportListRequested = () => ({
  type: FETCH_THIRD_PARTY_REPORTS_LIST_REQUESTED,
})
export const fetchThirdPartyReportListSuccess = (payload) => ({
  type: FETCH_THIRD_PARTY_REPORTS_LIST_SUCCESS,
  payload,
})
const fetchThirdPartyReportListFailed = (payload) => ({
  type: FETCH_THIRD_PARTY_REPORTS_LIST_FAILED,
  payload,
})

export const getThirdPartyReportList =
  (reportId, ocId, projectId) => async (dispatch) => {
    dispatch(fetchThirdPartyReportListRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.thirdPartyReport}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
      showToastMessage: false,
      toastMessage: 'Third party reports fetch successfully!',
    })
    if (data) {
      dispatch(fetchThirdPartyReportListSuccess(data))
      return
    }
    if (error) {
      dispatch(fetchThirdPartyReportListFailed(error.response))
    }
  }

// Create third party report
const createThirdPartyReportRequested = () => ({
  type: CREATE_THIRD_PARTY_REPORTS_REQUESTED,
})

const createThirdPartyReportSuccess = (payload) => ({
  type: CREATE_THIRD_PARTY_REPORTS_SUCCESS,
  payload,
})
const createThirdPartyReportFailed = (payload) => ({
  type: CREATE_THIRD_PARTY_REPORTS_FAILED,
  payload,
})

export const createThirdPartyReport =
  (payload, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(createThirdPartyReportRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.thirdPartyReport}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Third party report created successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(createThirdPartyReportSuccess(data))
      dispatch(getReportList(projectId, ocId, reportId))
      return
    }
    if (error) {
      dispatch(createThirdPartyReportFailed(error.response.data))
    }
  }

// Update third party report
const updateThirdPartyReportRequested = () => ({
  type: UPDATE_THIRD_PARTY_REPORTS_REQUESTED,
})
const updateThirdPartyReportSuccess = (payload) => ({
  type: UPDATE_THIRD_PARTY_REPORTS_SUCCESS,
  payload,
})
const updateThirdPartyReportFailed = (payload) => ({
  type: UPDATE_THIRD_PARTY_REPORTS_FAILED,
  payload,
})

export const updateThirdPartyReport =
  (payload, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(updateThirdPartyReportRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.thirdPartyReport}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.patch,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Third party report updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(updateThirdPartyReportSuccess(data))
      return
    }
    if (error) {
      dispatch(updateThirdPartyReportFailed(error.response.data))
    }
  }

// Delete third party report
const deleteThirdPartyReportRequested = () => ({
  type: DELETE_THIRD_PARTY_REPORTS_REQUESTED,
})
export const deleteThirdPartyReportSuccess =
  (payload, previousThirdPartyReports) => (dispatch) => {
    const thirdPartyReportList = [...previousThirdPartyReports]
    const index = thirdPartyReportList?.findIndex(({ id }) =>
      equal(id, payload.thirdPartyReport[0]),
    )
    if (~index) {
      thirdPartyReportList.splice(index, 1)
    }
    dispatch({
      type: DELETE_THIRD_PARTY_REPORTS_SUCCESS,
      payload: thirdPartyReportList,
    })
  }
const deleteThirdPartyReportFailed = (payload) => ({
  type: DELETE_THIRD_PARTY_REPORTS_FAILED,
  payload,
})
export const deleteThirdPartyReport =
  (payload, reportId, ocId, projectId, thirdPartyReports, onSuccess) =>
  async (dispatch) => {
    dispatch(deleteThirdPartyReportRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.thirdPartyReport}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Deleted successfully!',
    })
    if (status) {
      dispatch(deleteThirdPartyReportSuccess(payload, thirdPartyReports))
      dispatch(getReportList(projectId, ocId, reportId))
      onSuccess()
      return
    }
    if (error) {
      dispatch(deleteThirdPartyReportFailed(error.response.data))
    }
  }

/** *******************************
  Third party report section
******************************** */
// Fetch third party report section list
const fetchThirdPartyReportSectionListRequested = () => ({
  type: FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_REQUESTED,
})
export const fetchThirdPartyReportSectionListSuccess = (payload) => ({
  type: FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_SUCCESS,
  payload,
})
const fetchThirdPartyReportSectionListFailed = (payload) => ({
  type: FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_FAILED,
  payload,
})

export const getThirdPartyReportSectionList =
  (reportId, ocId, projectId) => async (dispatch) => {
    dispatch(fetchThirdPartyReportSectionListRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.thirdPartyReportSection}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
      showToastMessage: false,
      toastMessage: 'Third party reports fetch successfully!',
    })
    if (data) {
      dispatch(fetchThirdPartyReportSectionListSuccess(data))
      return
    }
    if (error) {
      dispatch(fetchThirdPartyReportSectionListFailed(error.response))
    }
  }

// Create third party report section
const createThirdPartyReportSectionRequested = () => ({
  type: CREATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED,
})

const createThirdPartyReportSectionSuccess = (payload) => ({
  type: CREATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS,
  payload,
})
const createThirdPartyReportSectionFailed = (payload) => ({
  type: CREATE_THIRD_PARTY_REPORTS_SECTION_FAILED,
  payload,
})

export const createThirdPartyReportSection =
  ({ name, reportId, ocId, projectId, onSuccess }) =>
  async (dispatch) => {
    dispatch(createThirdPartyReportSectionRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.thirdPartyReportSection}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.post,
      data: { name },
      showToastMessage: true,
      toastMessage: 'Third party report section created successfully!',
    })
    if (data) {
      dispatch(createThirdPartyReportSectionSuccess(data))
      onSuccess(data)
      return
    }
    if (error) {
      showToast(error?.response?.data?.name?.[0])
      dispatch(createThirdPartyReportSectionFailed(error.response.data))
    }
  }

// Import from last report
const importThirdPartyReportRequested = (query) => ({
  type: IMPORT_THIRD_PARTY_REPORT_REQUESTED,
  payload: query,
})
const importThirdPartyReportSuccess = (payload) => ({
  type: IMPORT_THIRD_PARTY_REPORT_SUCCESS,
  payload,
})
const importThirdPartyReportFailed = (payload) => ({
  type: IMPORT_THIRD_PARTY_REPORT_FAILED,
  payload,
})
export const importThirdPartyReport =
  ({
    reportId,
    ocId,
    projectId,
    thirdPartyReportSections,
    onSuccess = () => null,
  }) =>
  async (dispatch) => {
    dispatch(importThirdPartyReportRequested(thirdPartyReportSections))

    const { data, error } = await api({
      endPoint: `${apiEndPoints.thirdPartyReport}import/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}&thirdPartyReportSections=${thirdPartyReportSections}`,
      method: method.get,
    })

    if (data) {
      dispatch(
        importThirdPartyReportSuccess({
          data,
          query: thirdPartyReportSections,
        }),
      )
      onSuccess(data[thirdPartyReportSections])
    }
    if (error) {
      showToast('Failed to import third party report')
      dispatch(
        importThirdPartyReportFailed({
          data: error.response.data,
          query: thirdPartyReportSections,
        }),
      )
    }
  }

// Create third party report section
const updateThirdPartyReportSectionRequested = () => ({
  type: UPDATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED,
})

const updateThirdPartyReportSectionSuccess = (payload) => ({
  type: UPDATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS,
  payload,
})
const updateThirdPartyReportSectionFailed = (payload) => ({
  type: UPDATE_THIRD_PARTY_REPORTS_SECTION_FAILED,
  payload,
})

export const updateThirdPartyReportSection =
  ({ payload, reportId, ocId, projectId, sectionId, onSuccess }) =>
  async (dispatch, getState) => {
    const { thirdPartyReportSectionList } = getState().thirdPartyReport
    dispatch(updateThirdPartyReportSectionRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.thirdPartyReportSection}${sectionId}/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.patch,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Third party report section updated successfully!',
    })
    if (data) {
      const cloneData = [...thirdPartyReportSectionList]
      const indexOfPayload = cloneData?.findIndex(({ id }) =>
        equal(id, sectionId),
      )
      cloneData?.splice(indexOfPayload, 1, data)
      dispatch(updateThirdPartyReportSectionSuccess(cloneData))
      onSuccess(data)
      return
    }
    if (error) {
      dispatch(updateThirdPartyReportSectionFailed(error.response.data))
    }
  }
