import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { downloadItem, ternary } from '../../Utils/javascript'
import {
  FETCH_PHOTOS_REQUESTED,
  FETCH_PHOTOS_SUCCESS,
  FETCH_PHOTOS_FAILED,
  DOWNLOAD_PROJECT_IMAGES_REQUESTED,
  DOWNLOAD_PROJECT_IMAGES_SUCCESS,
  DOWNLOAD_PROJECT_IMAGES_FAILED,
} from '../constants/projectPhotos'

const fetchPhotosRequested = () => ({
  type: FETCH_PHOTOS_REQUESTED,
})
const fetchPhotosSuccess = (payload) => ({
  type: FETCH_PHOTOS_SUCCESS,
  payload,
})
const fetchPhotosFailed = (payload) => ({
  type: FETCH_PHOTOS_FAILED,
  payload,
})

export const getProjectPhotos = (projectId) => async (dispatch) => {
  dispatch(fetchPhotosRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.project}${projectId}/photographs/`,
  })

  if (data) {
    dispatch(fetchPhotosSuccess(data?.reverse()))
  }
  if (error) {
    dispatch(fetchPhotosFailed(error.response))
  }
}

const downloadProjectImagesRequested = () => ({
  type: DOWNLOAD_PROJECT_IMAGES_REQUESTED,
})
const downloadProjectImagesSuccess = (payload) => ({
  type: DOWNLOAD_PROJECT_IMAGES_SUCCESS,
  payload,
})
const downloadProjectImagesFailed = (payload) => ({
  type: DOWNLOAD_PROJECT_IMAGES_FAILED,
  payload,
})

export const downloadProjectImages =
  (projectId, projectName) => async (dispatch) => {
    dispatch(downloadProjectImagesRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.project}${projectId}/download-photographs/`,
      method: method.get,
      responseType: 'arraybuffer',
    })

    if (data) {
      downloadItem(
        data,
        'application/zip',
        ternary(projectName, `${projectName}.zip`, 'images.zip'),
      )
      dispatch(downloadProjectImagesSuccess())
    }
    if (error) {
      dispatch(downloadProjectImagesFailed(error.response))
    }
  }
