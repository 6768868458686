export const FETCH_CLIENT_COMPANY_LIST_REQUESTED =
  'FETCH_CLIENT_COMPANY_LIST_REQUESTED'
export const FETCH_CLIENT_COMPANY_LIST_SUCCESS =
  'FETCH_CLIENT_COMPANY_LIST_SUCCESS'
export const FETCH_CLIENT_COMPANY_LIST_FAILED =
  'FETCH_CLIENT_COMPANY_LIST_FAILED'

export const FETCH_CLIENT_ACCESS_PROJECT_LIST_REQUESTED =
  'FETCH_CLIENT_ACCESS_PROJECT_LIST_REQUESTED'
export const FETCH_CLIENT_ACCESS_PROJECT_LIST_SUCCESS =
  'FETCH_CLIENT_ACCESS_PROJECT_LIST_SUCCESS'
export const FETCH_CLIENT_ACCESS_PROJECT_LIST_FAILED =
  'FETCH_CLIENT_ACCESS_PROJECT_LIST_FAILED'

export const FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_REQUESTED =
  'FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_REQUESTED'
export const FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_SUCCESS =
  'FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_SUCCESS'
export const FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_FAILED =
  'FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_FAILED'

export const ADD_EDIT_PROJECT_ACCESS_REQUESTED =
  'ADD_EDIT_PROJECT_ACCESS_REQUESTED'
export const ADD_EDIT_PROJECT_ACCESS_SUCCESS = 'ADD_EDIT_PROJECT_ACCESS_SUCCESS'
export const ADD_EDIT_PROJECT_ACCESS_FAILED = 'ADD_EDIT_PROJECT_ACCESS_FAILED'

export const DELETE_CLIENT_ACCESS_PROJECT_REQUESTED =
  'DELETE_CLIENT_ACCESS_PROJECT_REQUESTED'
export const DELETE_CLIENT_ACCESS_PROJECT_SUCCESS =
  'DELETE_CLIENT_ACCESS_PROJECT_SUCCESS'
export const DELETE_CLIENT_ACCESS_PROJECT_FAILED =
  'DELETE_CLIENT_ACCESS_PROJECT_FAILED'

export const CLEAR_CLIENT_ACCESS_DATA = 'CLEAR_CLIENT_ACCESS_DATA'
