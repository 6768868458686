import {
  CREATE_PERSONNEL_FAILED,
  CREATE_PERSONNEL_REQUESTED,
  CREATE_PERSONNEL_SUCCESS,
  FETCH_COMPANY_PERSONNEL_FAILED,
  FETCH_COMPANY_PERSONNEL_REQUESTED,
  FETCH_COMPANY_PERSONNEL_SUCCESS,
  GENERATE_PASSWORD_FAILED,
  GENERATE_PASSWORD_REQUESTED,
  GENERATE_PASSWORD_SUCCESS,
  RESET_PERSONNEL_PASSWORD_FAILED,
  RESET_PERSONNEL_PASSWORD_REQUESTED,
  RESET_PERSONNEL_PASSWORD_SUCCESS,
  UPDATE_COMPANY_PERSONNEL_FAILED,
  UPDATE_COMPANY_PERSONNEL_REQUESTED,
  UPDATE_COMPANY_PERSONNEL_SUCCESS,
} from '../constants/companyPersonnel'

const initialState = {
  companyPersonnelList: [],
  isFetchingCompanyPersonnel: false,
  isCreatingPersonnel: false,
  isNotifying: false,
  isResettingPassword: false,
  isGeneratingPassword: false,
  isUpdatingPersonnel: false,
  fetchCompanyPersonnelError: null,
  createPersonnelError: null,
  updatePersonnelError: null,
  resetPasswordError: null,
  generatePasswordError: null,
}

export const companyPersonnel = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_COMPANY_PERSONNEL_REQUESTED:
      return {
        ...state,
        isFetchingCompanyPersonnel: true,
      }
    case FETCH_COMPANY_PERSONNEL_SUCCESS:
      return {
        ...state,
        isFetchingCompanyPersonnel: false,
        fetchCompanyPersonnelError: null,
        companyPersonnelList: action.payload,
      }
    case FETCH_COMPANY_PERSONNEL_FAILED:
      return {
        ...state,
        isFetchingCompanyPersonnel: false,
        fetchCompanyPersonnelError: action.payload,
      }
    case CREATE_PERSONNEL_REQUESTED:
      return {
        ...state,
        isCreatingPersonnel: true,
        isNotifying: action.payload?.isNotifying,
      }
    case CREATE_PERSONNEL_SUCCESS:
      return {
        ...state,
        isCreatingPersonnel: false,
        createPersonnelError: null,
        isNotifying: false,
        companyPersonnelList: action.payload,
      }
    case CREATE_PERSONNEL_FAILED:
      return {
        ...state,
        isCreatingPersonnel: false,
        isNotifying: false,
        createPersonnelError: action.payload,
      }

    case UPDATE_COMPANY_PERSONNEL_REQUESTED:
      return {
        ...state,
        isUpdatingPersonnel: true,
        isNotifying: action.payload?.isNotifying,
      }
    case UPDATE_COMPANY_PERSONNEL_SUCCESS:
      return {
        ...state,
        isUpdatingPersonnel: false,
        updatePersonnelError: null,
        isNotifying: false,
        companyPersonnelList: action.payload,
      }
    case UPDATE_COMPANY_PERSONNEL_FAILED:
      return {
        ...state,
        isUpdatingPersonnel: false,
        isNotifying: false,
        updatePersonnelError: action.payload,
      }
    case RESET_PERSONNEL_PASSWORD_REQUESTED:
      return {
        ...state,
        isResettingPassword: true,
      }
    case RESET_PERSONNEL_PASSWORD_SUCCESS:
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: null,
      }
    case RESET_PERSONNEL_PASSWORD_FAILED:
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: action.payload,
      }
    case GENERATE_PASSWORD_REQUESTED:
      return {
        ...state,
        isGeneratingPassword: true,
      }
    case GENERATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isGeneratingPassword: false,
        generatePasswordError: null,
      }
    case GENERATE_PASSWORD_FAILED:
      return {
        ...state,
        isGeneratingPassword: false,
        generatePasswordError: action.payload,
      }
    default:
      return state
  }
}
