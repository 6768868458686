import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, lt, ternary } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  ADD_OWNER_ARCHITECT,
  CREATE_AND_UPDATE_OWNER_ARCHITECT_FAILED,
  CREATE_AND_UPDATE_OWNER_ARCHITECT_REQUESTED,
  CREATE_AND_UPDATE_OWNER_ARCHITECT_SUCCESS,
  FETCH_OWNER_CONTRACTOR_MAPPING_FAILED,
  FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED,
  FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS,
  FETCH_OWNER_ARCHITECT_REQUESTED,
  FETCH_OWNER_ARCHITECT_SUCCESS,
  FETCH_OWNER_ARCHITECT_FAILED,
  SEARCH_ARCHITECT_REQUESTED,
  SEARCH_ARCHITECT_SUCCESS,
  SEARCH_ARCHITECT_FAILED,
  DELETE_ARCHITECT_SUCCESS,
  DELETE_ARCHITECT_FAILED,
  DELETE_ARCHITECT_REQUESTED,
} from '../constants/ownerArchitect'
import { updateSaveStatusAction } from './confirmation'

let timer = null
const UNIQUE_CODE_PREFIX = 'OA'
const getUniqueCode = (code) =>
  `${UNIQUE_CODE_PREFIX}-${ternary(lt(code, 10), `0${code}`, code)}`

// Fetch owner architect list
const fetchOwnerArchitectRequested = () => ({
  type: FETCH_OWNER_ARCHITECT_REQUESTED,
})
export const fetchOwnerArchitectSuccess = (payload) => ({
  type: FETCH_OWNER_ARCHITECT_SUCCESS,
  payload,
})
const fetchOwnerArchitectFailed = (payload) => ({
  type: FETCH_OWNER_ARCHITECT_FAILED,
  payload,
})

export const getOwnerArchitect = (projectId) => async (dispatch) => {
  dispatch(fetchOwnerArchitectRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.ownerArchitect}?projectId=${projectId}`,
    method: method.get,
    showToastMessage: false,
    toastMessage: 'Owner architect Fetch successfully!',
  })
  if (data) {
    dispatch(fetchOwnerArchitectSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchOwnerArchitectFailed(error.response))
  }
}
// Create and update owner architect
const createAndUpdateOwnerArchitectRequested = () => ({
  type: CREATE_AND_UPDATE_OWNER_ARCHITECT_REQUESTED,
})
const createAndUpdateOwnerArchitectSuccess =
  (payload, isNewArchitect, newId) => (dispatch, getState) => {
    const state = getState()
    const ownerArchitectList = [...state.ownerArchitect.ownerArchitectList]
    const index = ownerArchitectList?.findIndex(({ id }) =>
      equal(id, payload.id),
    )
    const updatedOwnerArchitectList = [...ownerArchitectList]
    if (~index) {
      updatedOwnerArchitectList?.splice(index, 1, payload)
    }
    if (isNewArchitect) {
      const index = ownerArchitectList?.findIndex(({ id }) => equal(id, newId))
      updatedOwnerArchitectList?.splice(index, 1)
    }
    dispatch({
      type: CREATE_AND_UPDATE_OWNER_ARCHITECT_SUCCESS,
      payload: ternary(!isNewArchitect, updatedOwnerArchitectList, [
        ...updatedOwnerArchitectList,
        payload,
      ]),
      activeArchitect: payload?.id,
    })
  }
const createAndUpdateOwnerArchitectFailed = (payload) => ({
  type: CREATE_AND_UPDATE_OWNER_ARCHITECT_FAILED,
  payload,
})

export const createAndUpdateOwnerArchitect =
  (payload, id) => async (dispatch) => {
    dispatch(createAndUpdateOwnerArchitectRequested())
    const updatedPayload = { ...payload }
    delete updatedPayload.isNewArchitect
    const { data, error } = await api({
      endPoint: ternary(
        payload?.isNewArchitect,
        `${apiEndPoints.ownerArchitect}?projectId=${payload.project}`,
        `${apiEndPoints.ownerArchitect}${id}/?projectId=${payload.project}`,
      ),
      method: ternary(payload?.isNewArchitect, method.post, method.patch),
      data: updatedPayload,
      showToastMessage: true,
      toastMessage: ternary(
        payload?.isNewArchitect,
        'Owner architect created successfully!',
        'Owner architect updated successfully!',
      ),
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        createAndUpdateOwnerArchitectSuccess(data, payload?.isNewArchitect, id),
      )
      return
    }
    if (error) {
      dispatch(createAndUpdateOwnerArchitectFailed(error.response.data))
    }
  }

// Fetch owner contractor mapping
const fetchOwnerContractorRequested = () => ({
  type: FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED,
})
export const fetchOwnerContractorSuccess = (payload) => ({
  type: FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS,
  payload,
})
const fetchOwnerContractorFailed = (payload) => ({
  type: FETCH_OWNER_CONTRACTOR_MAPPING_FAILED,
  payload,
})

export const getOwnerContractorMapping =
  (id, uniqueCode) => async (dispatch) => {
    dispatch(fetchOwnerContractorRequested())
    const { data, error } = await api({
      endPoint: ternary(
        uniqueCode,
        `${apiEndPoints.ownerArchitect}mapping/${id}/?uniqueCode=${uniqueCode}`,
        `${apiEndPoints.ownerArchitect}mapping/${id}/`,
      ),
      method: method.get,
      showToastMessage: false,
      toastMessage: 'Owner Contractor mapping Fetch successfully!',
    })
    if (data) {
      const dataWithUpdatedKeys = data?.sort((a, b) =>
        a?.uniqueCode > b?.uniqueCode ? 1 : -1,
      )
      dispatch(fetchOwnerContractorSuccess(dataWithUpdatedKeys))
      const toast = () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          if (!dataWithUpdatedKeys?.length) {
            showToast(
              'Complete ‘Owner-Contractor’ prior to entering data on ‘Owner-Architect’ page. ',
              'error',
              'top-right',
            )
          }
        }, 2000)
      }
      toast()
      return
    }
    if (error) {
      dispatch(fetchOwnerContractorFailed(error.response.data))
    }
  }
// Add new owner architect
export const addOwnerArchitectSuccess = (payload) => ({
  type: ADD_OWNER_ARCHITECT,
  payload,
})

export const addOwnerArchitect = (id) => (dispatch, getState) => {
  const state = getState()
  const ownerArchitectList = [...state.ownerArchitect.ownerArchitectList]
  const sortedArchitectList = ownerArchitectList?.sort((a, b) =>
    a?.uniqueCode > b?.uniqueCode ? 1 : -1,
  )
  const lastIndexArchitect = sortedArchitectList
    ?.slice(-1)[0]
    ?.uniqueCode?.split('-')[1]
  dispatch(
    addOwnerArchitectSuccess([
      ...ownerArchitectList,
      {
        id,
        isNewArchitect: true,
        uniqueCode: getUniqueCode(
          ternary(lastIndexArchitect, +lastIndexArchitect + 1, '1'),
        ),
      },
    ]),
  )
}

// Search architect
const searchArchitectRequested = () => ({
  type: SEARCH_ARCHITECT_REQUESTED,
})
const searchArchitectSuccess = (payload) => ({
  type: SEARCH_ARCHITECT_SUCCESS,
  payload,
})
const searchArchitectFailed = (payload) => ({
  type: SEARCH_ARCHITECT_FAILED,
  payload,
})

export const searchArchitect =
  (searchQuery, isPopulate) => async (dispatch) => {
    if (isPopulate) {
      dispatch(searchArchitectRequested())
    }
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.projectContactSearch}?fieldName=architect${
        searchQuery ? `&query=${searchQuery}` : ''
      }`,
    })
    if (data) {
      const dataWithLabelValue = data.map((company) => ({
        label: company?.companyName,
        value: `${company?.id}`,
        city: company?.city,
        state: company?.state,
      }))
      dispatch(searchArchitectSuccess(dataWithLabelValue))
    }
    if (error) {
      dispatch(searchArchitectFailed(error.response))
    }
  }

// Delete architect
const deleteOwnerArchitectRequested = () => ({
  type: DELETE_ARCHITECT_REQUESTED,
})
export const deleteOwnerArchitectSuccess =
  (payload) => (dispatch, getState) => {
    const state = getState()
    const ownerArchitectList = [...state.ownerArchitect.ownerArchitectList]
    const index = ownerArchitectList?.findIndex(({ id }) =>
      equal(id, payload?.ownerArchitect[0]),
    )
    if (~index) {
      ownerArchitectList.splice(index, 1)
    }
    dispatch({
      type: DELETE_ARCHITECT_SUCCESS,
      payload: ownerArchitectList,
      activeContractor: ownerArchitectList[0]?.id,
    })
  }
const deleteOwnerArchitectFailed = (payload) => ({
  type: DELETE_ARCHITECT_FAILED,
  payload,
})

export const deleteOwnerArchitect =
  (payload, handleClose) => async (dispatch) => {
    dispatch(deleteOwnerArchitectRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.deleteOwnerArchitect}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Owner Architect deleted successfully!',
    })
    if (data) {
      handleClose()
      dispatch(deleteOwnerArchitectSuccess(payload))
      return
    }
    if (error) {
      dispatch(deleteOwnerArchitectFailed(error.response.data))
    }
  }
