import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  addBtn: {
    marginLeft: 230,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  inlineLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 'bold',
      color: '#2D2D2D',
      fontSize: 14,
      marginBottom: 8,
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  projectDesc: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 305,
    height: 305,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '210px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '210px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },
  esAutoGenComment: {
    minHeight: 305,
    maxHeight: 'calc(100% - 40px)',
    height: '100%',
    '& .mention': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
    '& .ck-editor': {
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
  },
}))
