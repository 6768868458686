export const optionsFields = ['ownerContractor']

export const emptyTableCellPlaceholder = '---'

export const options = {
  responsive: 'standard',
  filter: false,
  selectableRows: 'single',
  print: false,
  downloadOptions: {
    filename: 'Reports.csv',
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
}

export const columns = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'overrideReportCode',
    label: 'Report #',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'drawNumber',
    label: 'Draw #',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'payApps',
    label: 'Pay App #',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'percentComplete',
    label: '% Complete',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'periodTo',
    label: 'Period To',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'changeOrderCurrent',
    label: 'Change Order',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'remainingHCC',
    label: 'Remaining HCC',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'completionDate',
    label: 'Completion Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'publishedAt',
    label: 'Published Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: false,
      viewColumns: false,
      sort: true,
    },
  },
  {
    name: ' ',
    label: ' ',
    options: {
      filter: false,
      viewColumns: false,
      sort: true,
    },
  },
  {
    name: 'isLastReport',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'isSecondLastReport',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
]

export const projectMenu = [
  {
    menu: 'Dashboard',
    url: 'projectDashboard',
    isNewProject: true,
    id: 'projectDashboard',
  },
  {
    menu: 'Project Info',
    url: 'summary/',
  },
  {
    menu: 'Reports',
    url: 'project-reports/',
    id: [
      '',
      'cover-page-and-letter',
      'executive-summary',
      'issues-log',
      'budget-summary',
      'executive-summary',
      'budget-summary',
      'g702-tracker',
      'contingency',
      'construction-summary',
      'construction-schedule',
      'permits-entitlements',
      'third-party-reports',
      'photographs',
      'appendix',
      'report-attachment',
    ],
    isNewProject: true,
  },
  {
    menu: 'Photos',
    url: 'project-photos',
    isNewProject: true,
    id: 'project-photos',
  },
  {
    menu: 'Settings',
    url: 'project-setting/',
    isNewProject: true,
    id: 'project-setting',
  },
]
