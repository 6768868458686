import { Stack, Typography } from '@mui/material'
import React from 'react'

const PageTitleBar = ({ title }) => (
  <Stack
    height="50px"
    backgroundColor="gray.dark"
    px={3}
    justifyContent="center"
  >
    <Typography variant="h5" component="h2" fontWeight={700}>
      {title}
    </Typography>
  </Stack>
)

export default PageTitleBar
