import {
  FETCH_USER_PROFILE_FAILED,
  FETCH_USER_PROFILE_REQUESTED,
  FETCH_USER_PROFILE_SUCCESS,
  UPDATE_USER_PASSWORD_FAILED,
  UPDATE_USER_PASSWORD_REQUESTED,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_REQUESTED,
  UPDATE_USER_PROFILE_SUCCESS,
} from '../constants/userProfile'

const initialState = {
  isUserProfileLoading: false,
  userProfileData: null,
  isUpdateUserProfileLoading: false,
  updateUserProfileData: null,
  isUpdateUserPasswordLoading: false,
  updateUserPasswordData: null,
  isPasswordUpdate: false,
  passwordUpdateError: {},
}

const userProfileReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch user profile
    case FETCH_USER_PROFILE_REQUESTED:
      return {
        ...state,
        isUserProfileLoading: true,
      }
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfileData: action.payload,
        isUserProfileLoading: false,
      }
    case FETCH_USER_PROFILE_FAILED:
      return {
        ...state,
        isUserProfileLoading: false,
      }

    // Update user profile
    case UPDATE_USER_PROFILE_REQUESTED:
      return {
        ...state,
        isUpdateUserProfileLoading: true,
      }
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updateUserProfileData: action.payload,
        isUpdateUserProfileLoading: false,
      }
    case UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        isUpdateUserProfileLoading: false,
      }

    // Update user password
    case UPDATE_USER_PASSWORD_REQUESTED:
      return {
        ...state,
        isUpdateUserPasswordLoading: true,
      }
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        updateUserPasswordData: action.payload,
        isUpdateUserPasswordLoading: false,
        isPasswordUpdate: true,
      }
    case UPDATE_USER_PASSWORD_FAILED:
      return {
        ...state,
        isUpdateUserPasswordLoading: false,
        isPasswordUpdate: false,
        passwordUpdateError: action.payload,
      }
    default:
      return state
  }
}
export default userProfileReducer
