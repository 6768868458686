import React from 'react'
import { Outlet } from 'react-router-dom'

import { OuterLayout } from '../../Layout/admin'

const Admin = () => (
  <OuterLayout title="Administrator Settings">
    <Outlet />
  </OuterLayout>
)

export default Admin
