import { Box, Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import { optionsFields } from '../../../Description/reportMonitoring.description'
import { g702SplitContracts } from '../../../Description/trackers.description'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import { getReportChoices } from '../../../Redux/actions/reportChoices'
import {
  clearG702SplitTracker,
  deleteG702SplitTrackers,
  getG702SplitTracker,
  getReportG702SplitTracker,
  saveG702SplitTracker,
} from '../../../Redux/actions/reportTrackers'
import DKTButton from '../../../Shared/DKTButton'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTDeleteNotificationModal from '../../../Shared/DKTDeleteNotificationModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import usePermission from '../../../Hooks/usePermission'
import { equal, isEmptyString, ternary } from '../../../Utils/javascript'
import G702SplitTackerTable from '../g702Tracker/G702SplitTackerTable'
import { noDataMessage } from '../../../Utils/constant'

const columnId = ['01', '02', '03']

const G702Split = () => {
  const [data, setData] = useState([])
  const [ocId, setOcId] = useState(null)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [g702TrackerAry, setG702TrackerAry] = useState([])
  const [g702TrackerId, setG702TrackerId] = useState(null)
  const [deleteNotificationOpen, setOpenDeleteNotification] = useState(false)
  const [tempG702AryData, setTempG702AryData] = useState({})
  const [newTrackerCreated, setNewTrackerCreated] = useState(true)

  const dispatch = useDispatch()
  const theme = useTheme()
  const { slug: projectId, reportId } = useParams()
  const {
    isSavingG702SplitTracker,
    isFetchingG702SplitTracker,
    g702SplitTracker,
    isDeletingG702SplitTracker,
    isFetchingG702SplitTrackerUniqueCodes,
    g702SplitTrackerUniqueCodes,
  } = useSelector(({ reportTrackers }) => reportTrackers)

  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)

  const tableValues = g702SplitContracts?.map(({ name }, index) =>
    columnId.reduce(
      (acc, id) => {
        acc[id] = index < 3 ? '' : 0
        return acc
      },
      { name, ...(index > 2 && { total: 0 }) },
    ),
  )

  const { hasOnlyViewPermission } = usePermission()

  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionsFields?.join(','),
        projectId,
      }),
    )
  }, [dispatch, projectId])

  useEffect(() => {
    if (choices?.ownerContractor[0]?.id)
      setOcId(choices?.ownerContractor[0]?.id)
  }, [choices])

  useEffect(() => {
    if (reportId && ocId && projectId && !g702SplitTrackerUniqueCodes?.length) {
      dispatch(
        getReportG702SplitTracker({
          reportId,
          ocId,
          projectId,
        }),
      )
    }
  }, [reportId, ocId, projectId])

  useEffect(() => {
    if (reportId && ocId && projectId && g702TrackerId) {
      dispatch(
        getG702SplitTracker({
          reportId,
          ocId,
          projectId,
          g702TrackerId,
        }),
      )
    }
  }, [g702TrackerId])

  useEffect(() => {
    if (!isFetchingG702SplitTrackerUniqueCodes) {
      const uniqCodesAry = g702SplitTrackerUniqueCodes?.uniqueCodes?.length
        ? g702SplitTrackerUniqueCodes?.uniqueCodes?.map((id) => ({
            id: `${id}`,
            name: `No. ${id}`,
          }))
        : []
      setG702TrackerAry(uniqCodesAry)
      setG702TrackerId(uniqCodesAry?.[0]?.id || null)
      setNewTrackerCreated(!!g702SplitTrackerUniqueCodes?.uniqueCodes?.length)
    }
  }, [g702SplitTrackerUniqueCodes])

  useEffect(() => {
    if (g702SplitTracker && !!g702SplitTracker?.G702Trackers?.length) {
      const newData = g702SplitContracts?.map((item, index) => {
        const obj = columnId?.reduce(
          (acc, id, i) => ({
            ...acc,
            name: item?.name,
            [id]: g702SplitTracker?.G702Trackers?.[i]?.[item?.name],
            ...(index > 2 && { total: g702SplitTracker?.total?.[item?.name] }),
          }),
          {},
        )
        return obj
      })
      setData(newData)
      setNewTrackerCreated(false)
    }
    if (!g702SplitTracker) {
      setData(tableValues)
      setNewTrackerCreated(true)
    }
  }, [g702SplitTracker])

  useEffect(() => {
    if (tempG702AryData?.id) {
      if (isUnSavedChanges) {
        setIsUnSavedWarningOpen(true)
      } else {
        setErrors({})
      }
    }
  }, [tempG702AryData])

  useEffect(
    () => () => {
      dispatch(clearG702SplitTracker())
      setData(tableValues)
    },
    [],
  )

  const handleSave = () => {
    const payload = columnId?.map((val, index) => {
      let obj = {
        report: reportId,
        ownerContractor: ocId,
        uniqueCode: g702TrackerId,
        ...((g702SplitTracker?.G702Trackers?.[index]?.id ||
          g702SplitTracker?.[index]?.id) && {
          id:
            g702SplitTracker?.G702Trackers?.[index]?.id ||
            g702SplitTracker?.[index]?.id,
        }),
      }
      data?.forEach((item) => {
        obj = {
          ...obj,
          [item?.name]:
            equal(item.name, 'periodTo') && equal(item?.[val], '')
              ? null
              : item?.[val],
        }
      })

      return obj
    })
    dispatch(saveG702SplitTracker(payload, reportId, ocId, projectId)).then(
      () => {
        setIsUnSavedChanges(false)
        if (equal(g702TrackerId, g702TrackerAry?.at(-1)?.id)) {
          setNewTrackerCreated(false)
        }
      },
    )
  }

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    if (!isEmptyString(tempG702AryData?.g702TrackerId)) {
      setG702TrackerId(tempG702AryData?.g702TrackerId)
    }
    if (tempG702AryData?.tableData) {
      setG702TrackerAry(tempG702AryData?.tableData)
    }
    if (tempG702AryData?.newTrackerCreated) {
      setNewTrackerCreated(tempG702AryData?.newTrackerCreated)
    }
  }

  const handleOpenCloseDeleteNotification = (flag) => {
    setOpenDeleteNotification(flag)
  }

  const actionList = [
    { label: 'Delete', onClick: () => handleOpenCloseDeleteNotification(true) },
  ]

  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" gap={2}>
          {ternary(
            true,
            <>
              <DKTButtonSelect
                options={actionList}
                disabled={
                  hasOnlyViewPermission ||
                  isFetchingG702SplitTracker ||
                  isSavingG702SplitTracker ||
                  !g702TrackerAry?.length ||
                  !equal(g702TrackerId, g702TrackerAry?.at(-1)?.id)
                }
              />
              <DKTButton
                onClick={handleSave}
                disabled={
                  isSavingG702SplitTracker ||
                  isFetchingG702SplitTracker ||
                  hasOnlyViewPermission ||
                  !g702TrackerAry?.length ||
                  Object.values(errors)
                    ?.map((el) => Object.values(el)?.filter(Boolean))
                    ?.flat()?.length
                }
              >
                {ternary(isSavingG702SplitTracker, 'Saving...', 'Save')}
              </DKTButton>
            </>,
            null,
          )}
        </Stack>
      </Box>
    </>
  )

  const handleDeleteTracker = () => {
    const deleteDataIds = columnId?.map(
      (_, index) =>
        g702SplitTracker?.G702Trackers?.[index]?.id ||
        g702SplitTracker?.[index]?.id,
    )
    dispatch(
      deleteG702SplitTrackers(reportId, ocId, projectId, deleteDataIds),
    )?.then(() => {
      handleOpenCloseDeleteNotification(false)
      setData(tableValues)
      setIsUnSavedChanges(false)
      setG702TrackerAry((prev) => {
        const cloneAry = [...prev]
        cloneAry.pop()
        return cloneAry
      })
      setG702TrackerId(g702TrackerAry?.at(-2)?.id || null)
      setNewTrackerCreated(!equal(g702TrackerAry?.length, 1))
    })
  }

  const handleAddG702Tracker = () => {
    const g702Number = `${
      (g702TrackerAry.length + 1 < 10 && '0') + (g702TrackerAry.length + 1)
    }`
    const cloneG702TrackerAry = [
      ...g702TrackerAry,
      {
        id: g702Number,
        name: `No. ${g702Number}`,
      },
    ]
    const jsonObject = cloneG702TrackerAry.map(JSON.stringify)
    const uniqueSet = new Set(jsonObject)
    const uniqueArray = Array.from(uniqueSet).map(JSON.parse)
    if (isUnSavedChanges) {
      setTempG702AryData({
        tableData: uniqueArray,
        g702TrackerId: g702Number,
        newTrackerCreated: true,
      })
      setIsUnSavedWarningOpen(true)
    } else {
      setG702TrackerAry(uniqueArray)
      setG702TrackerId(g702Number)
      setData(tableValues)
      setNewTrackerCreated(true)
      setErrors({})
    }
  }

  const handlePayAppId = (id) => {
    if (equal(g702TrackerId, id)) return
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      setTempG702AryData({ ...tempG702AryData, g702TrackerId: id })
    } else {
      setG702TrackerId(id)
      setData(tableValues)
      setErrors({})
    }
  }

  return (
    <InnerLayout
      contentTitle="G702 Split tracker"
      actions={actions}
      maxWidth="md"
      isShowMenu
      headingSx={{
        marginTop: 5,
      }}
    >
      <>
        {!(
          equal(isFetchingG702SplitTracker, 'FAILED') ||
          equal(isFetchingG702SplitTrackerUniqueCodes, 'FAILED')
        ) && (
          <>
            <Box sx={{ position: 'relative' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-85px',
                      left: { lg: '-40px', sm: '-20px' },
                    }}
                  >
                    {g702TrackerAry
                      ?.sort((a, b) => (a?.id > b?.id ? 1 : -1))
                      ?.map((app) => (
                        <DKTButton
                          key={app?.id}
                          variant="text"
                          onClick={() => handlePayAppId(app?.id)}
                          sx={{
                            backgroundColor: 'rgba(229, 229, 229, 0.46)',
                            marginRight: '3px',
                            padding: '0px 12px',
                            height: '28px',
                            color: ternary(
                              equal(g702TrackerId, app?.id),
                              theme.typography.primary,
                              'inherit',
                            ),
                          }}
                          disabled={isSavingG702SplitTracker}
                        >
                          {app?.name}
                        </DKTButton>
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid xs={12}>
              <Stack sx={{ position: 'relative', py: 1 }} mb={3}>
                <Stack direction="row" alignItems="center">
                  <DKTButton
                    variant="text"
                    color="primary"
                    sx={{ textTransform: 'initial', px: 1, flex: '0 0 auto' }}
                    startIcon={
                      <CircledPlusIcon
                        disabled={
                          hasOnlyViewPermission ||
                          isFetchingG702SplitTracker ||
                          isSavingG702SplitTracker ||
                          newTrackerCreated
                        }
                      />
                    }
                    onClick={handleAddG702Tracker}
                    disabled={
                      hasOnlyViewPermission ||
                      isFetchingG702SplitTracker ||
                      isSavingG702SplitTracker ||
                      newTrackerCreated
                    }
                  >
                    <Typography>G702 Split Tracker</Typography>
                  </DKTButton>
                </Stack>
              </Stack>
            </Grid>
          </>
        )}
        {equal(isFetchingG702SplitTracker, true) ||
        equal(isFetchingG702SplitTrackerUniqueCodes, true) ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <DKTCircularProgress />
          </Stack>
        ) : equal(isFetchingG702SplitTracker, 'FAILED') ||
          equal(isFetchingG702SplitTrackerUniqueCodes, 'FAILED') ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching G702 Split Tracker data.
              Please try contacting the admin or refreshing this page.
            </Typography>
          </Stack>
        ) : (
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12}>
              {g702TrackerAry?.length ? (
                <G702SplitTackerTable
                  {...{
                    data,
                    setData,
                    setIsUnSavedChanges,
                    errors,
                    setErrors,
                    hasOnlyViewPermission,
                  }}
                />
              ) : (
                <Box my={5}>
                  <Typography textAlign="center">{noDataMessage}</Typography>
                </Box>
              )}
            </Grid>
            {/* show modal when tries to navigate without save data */}
            {!hasOnlyViewPermission && (
              <>
                <DKTConfirmNavigateShowModal
                  isActive={isUnSavedWarningOpen}
                  onConfirm={confirmSaveWarningModal}
                  onCancel={handleCloseSaveWarningModal}
                  onSave={handleSave}
                />
                <DKTReactRouterPrompt
                  isDirty={isUnSavedChanges}
                  onSave={handleSave}
                />
              </>
            )}
            <DKTDeleteNotificationModal
              open={deleteNotificationOpen}
              handleClose={() => handleOpenCloseDeleteNotification(false)}
              handleDelete={handleDeleteTracker}
              isLoadingDelete={isDeletingG702SplitTracker}
            />
          </Grid>
        )}
      </>
    </InnerLayout>
  )
}

export default G702Split
