import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  issueTable: {
    '& .MuiToolbar-root': {
      maxWidth: '50%',
      marginLeft: 'auto',
      '& div': {
        '&:last-child': {
          flex: 'none',
        },
      },
      '& .MuiFormControl-root': {
        flex: 1,
      },
    },
    '& .MuiTableCell-head': {
      padding: '11.5px 16px',
    },
  },
  issueFilter: {
    position: 'absolute',
    left: 40,
    zIndex: 9,
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      left: 20,
    },
  },
  issuesDescriptionTextArea: {
    '& textarea': {
      resize: 'vertical',
      padding: [[10, 18]],
      fontStyle: 'italic',
      fontFamily: 'Roboto',
      fontWeight: 500,
      borderRadius: 0,
      borderColor: '#C4C4C4',
      color: '#646464',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  issuesTAble: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 250,
    height: 253,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '150px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '150px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },
}))
