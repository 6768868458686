export const formFields = {
  generalConstructionScheduleFields: {
    gcConstructionStart: {
      type: 'date',
      clearable: true,
      label: 'Construction Start',
      isRequired: true,
    },
    gcScheduleCompletion: {
      type: 'date',
      clearable: true,
      label: 'Schedule Completion',
      isRequired: true,
      xs: 6,
    },
    gcDurations: {
      type: 'text',
      label: 'Duration (days)',
      xs: 6,
      disabled: true,
    },
  },
  lenderConstructionScheduleFields: {
    lenderConstructionStart: {
      type: 'date',
      clearable: true,
      label: 'Construction Start',
    },
    lendersUwCompletion: {
      type: 'date',
      clearable: true,
      label: 'Lender’s UW Completion',
      xs: 6,
    },
    lenderDurations: {
      type: 'text',
      label: 'Duration (days)',
      xs: 6,
      disabled: true,
    },
  },
}
