import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { ternary } from '../Utils/javascript'

const RestrictPostLogin = ({ children }) => {
  const { auth } = useSelector((state) => state)

  return ternary(
    auth?.accessToken,
    <Navigate to="/dashboard" replace />,
    children,
  )
}

export default RestrictPostLogin
