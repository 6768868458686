/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { OuterLayout } from '../../../Layout/project'
import { getProjectList } from '../../../Redux/actions/projects'
import DKTButton from '../../../Shared/DKTButton'
import { equal, handleDownloadImage, ternary } from '../../../Utils/javascript'
import {
  downloadProjectImages,
  getProjectPhotos,
} from '../../../Redux/actions/projectPhotos'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { spinnerSize } from '../../../Utils/constant'
import { useStyles } from '../../../Styles/photos.style'

const Photos = () => {
  const [currentImage, setCurrentImage] = useState(null)
  const [currentImgIndex, setCurrentImgIndex] = useState(null)
  const { currentProject } = useSelector(({ projects }) => projects)
  const {
    isFetchPhotosLoading,
    projectPhotos,
    isDownloadProjectImagesLoading,
  } = useSelector(({ projectPhotos }) => projectPhotos)
  const { slug: projectId } = useParams()
  const dispatch = useDispatch()
  const classes = useStyles()

  const images = projectPhotos?.flatMap((item) => item?.photographs)

  useEffect(() => {
    dispatch(getProjectList(projectId))
    dispatch(getProjectPhotos(projectId))
  }, [dispatch, projectId])

  const showHideGallery = (images = null, index = null) => {
    setCurrentImgIndex(index)
    setCurrentImage(images)
  }

  const handlePrevNextImage = (direction) => {
    if (equal(direction, 'prev') && currentImgIndex > 0) {
      setCurrentImgIndex(currentImgIndex - 1)
    } else if (
      equal(direction, 'next') &&
      currentImgIndex < currentImage.length - 1
    ) {
      setCurrentImgIndex(currentImgIndex + 1)
    }
  }

  return (
    <OuterLayout
      title={currentProject?.projectName}
      contentTitle="PHOTOGRAPHS"
      maxWidth="md"
      isShowMenu
    >
      {equal(isFetchPhotosLoading, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isFetchPhotosLoading, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Photographs data. Please try
            contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Grid mt="175px">
            <Typography
              component="h6"
              variant="body2"
              sx={{ textAlign: 'center', fontWeight: 'bold', py: 4 }}
            >
              PHOTOGRAPHS
            </Typography>
          </Grid>
          {!!images?.length && (
            <DKTButton
              type="button"
              variant="outlined"
              sx={{ width: 'fit-content', px: 2, marginLeft: 'auto', mr: 3 }}
              onClick={() =>
                dispatch(
                  downloadProjectImages(projectId, currentProject?.projectName),
                )
              }
              disabled={isDownloadProjectImagesLoading}
              startIcon={
                isDownloadProjectImagesLoading && (
                  <DKTCircularProgress size={spinnerSize?.sm} color="grey" />
                )
              }
            >
              Download All
            </DKTButton>
          )}
          {projectPhotos?.length ? (
            projectPhotos?.map((item, index) => (
              <Box px={3} mb={1.5} key={index}>
                <Typography
                  component="h6"
                  variant="body2"
                  fontWeight="bold"
                  fontSize={18}
                  sx={{ my: 2 }}
                >
                  {`Report #${item?.overrideReportCode} ${ternary(
                    item?.date,
                    `/ Site Visit Date: ${item.date}`,
                    '',
                  )}`}
                </Typography>
                <Grid container spacing={2}>
                  {item.photographs?.length ? (
                    item.photographs?.map((photo, index) => (
                      <Grid item key={index} xs={12} sm={6} md={3}>
                        <Stack
                          className={`${classes.imgWrapper} ${
                            photo?.textBox && classes.imageHover
                          }`}
                        >
                          <IconButton
                            onClick={() =>
                              handleDownloadImage(
                                photo?.photo,
                                `Report ${item?.overrideReportCode} Image ${
                                  index + 1
                                }`,
                              )
                            }
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                          <img
                            src={photo.photo}
                            alt={photo.textBox}
                            style={{ width: '100%' }}
                            onClick={() =>
                              showHideGallery(item?.photographs, index)
                            }
                          />
                          <Typography>{photo?.textBox || ''}</Typography>
                        </Stack>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Typography>
                        Sorry, there are no photographs to display in{' '}
                        {`Report #${item?.overrideReportCode}`}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {currentImgIndex !== null && (
                  <>
                    <Stack className={classes.bigImgWrapper}>
                      <DKTButton
                        onClick={showHideGallery}
                        className={`${classes.btn} ${classes.icons} close`}
                      >
                        &times;
                      </DKTButton>
                      <img
                        src={currentImage?.[currentImgIndex].photo}
                        alt={currentImage?.[currentImgIndex]?.alt}
                      />

                      {!equal(currentImgIndex, 0) && (
                        <DKTButton
                          onClick={() => handlePrevNextImage('prev')}
                          style={{
                            left: 20,
                          }}
                          className={`${classes.btn} ${classes.icons}`}
                        >
                          &larr;
                        </DKTButton>
                      )}
                      {!equal(currentImgIndex + 1, currentImage.length) && (
                        <DKTButton
                          onClick={() => handlePrevNextImage('next')}
                          style={{
                            right: 20,
                          }}
                          className={`${classes.btn} ${classes.icons}`}
                        >
                          &rarr;
                        </DKTButton>
                      )}
                    </Stack>
                    <Typography
                      color="#fff"
                      fontSize={18}
                      className={classes.imgText}
                    >
                      {currentImage?.[currentImgIndex]?.textBox || ''}
                    </Typography>
                  </>
                )}
              </Box>
            ))
          ) : (
            <Typography sx={{ mx: 'auto' }}>
              Sorry, there is no matching data to display
            </Typography>
          )}
        </>
      )}
    </OuterLayout>
  )
}

export default Photos
