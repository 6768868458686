import { Box, Grid, Stack, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment/moment'
import { getProjectChoices } from '../../../Redux/actions/choices'
import {
  clearEsGenModalValues,
  getAutoGenESText,
  getAutoGenESValues,
  saveAutoGenEsText,
} from '../../../Redux/actions/executiveSummary'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTEditor from '../../../Shared/DKTEditor'
import { useStyles } from '../../../Styles/executiveSummary.style'
import { spinnerSize } from '../../../Utils/constant'
import {
  equal,
  modifyNumber,
  renderHtmlContent,
  replaceMentionsWithValues,
  ternary,
} from '../../../Utils/javascript'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { isDate } from '../../../Utils/regex'

const initialState = {
  esGenerator: '',
  previewAndEdit: '',
  errors: '',
}

const AutoGenerateES = ({ isOpen, onClose, exportToExecutiveSummary }) => {
  const [values, setValues] = useState(initialState)
  const [editorConfig, setEditorConfig] = useState({})
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)

  const classes = useStyles()
  const dispatch = useDispatch()
  const { slug: projectId, reportId } = useParams()
  const { isLoadingChoices, choices } = useSelector(
    ({ projectChoices }) => projectChoices,
  )
  const {
    isLoadingAutoGenEsText,
    autoGenEsText,
    isSavingAutoGenEs,
    isLoadingAutoGenEsValues,
    autoGenEsValues,
  } = useSelector(({ executiveSummary }) => executiveSummary)

  useEffect(() => {
    if (choices?.executiveSummaryKey?.length)
      setEditorConfig({
        mention: {
          feeds: [
            {
              marker: '#',
              feed: choices?.executiveSummaryKey,
              dropdownLimit: choices?.executiveSummaryKey?.length,
            },
          ],
        },
      })
  }, [choices?.executiveSummaryKey])

  useEffect(() => {
    dispatch(getProjectChoices({ fieldName: 'executiveSummaryKey' }))
  }, [])

  useEffect(() => {
    dispatch(getAutoGenESValues({ reportId, projectId }))
    dispatch(getAutoGenESText({ reportId, projectId }))
  }, [isOpen, reportId, projectId])

  useEffect(() => {
    setValues({
      ...values,
      esGenerator: autoGenEsText,
      previewAndEdit: replaceMentionsWithValues(
        autoGenEsText,
        autoGenEsValues,
        valuesFormatter,
      ),
    })
  }, [autoGenEsText, autoGenEsValues])

  useEffect(() => {
    setIsUnSavedChanges(!equal(autoGenEsText, values?.esGenerator))
  }, [autoGenEsText, values])
  const exportEs = useCallback(() => {
    const payload = {
      text: values?.esGenerator,
      report: reportId,
      project: projectId,
    }
    const onSuccess = () => {
      exportToExecutiveSummary(
        'executiveSummary',
        values?.previewAndEdit ||
          replaceMentionsWithValues(
            values?.esGenerator,
            autoGenEsValues,
            valuesFormatter,
          ),
      )
      setValues(initialState)
      setIsUnSavedChanges(false)
      setIsUnSavedWarningOpen(false)
      onClose()
      dispatch(clearEsGenModalValues())
    }
    if (!values?.esGenerator) {
      setValues((prev) => ({ ...prev, errors: 'Required' }))
    }
    if (values?.errors) {
      dispatch(updateOnSaveStatus({ cancel: true }))
    } else {
      dispatch(saveAutoGenEsText(payload, reportId, projectId, onSuccess))
    }
  }, [
    values?.esGenerator,
    values?.previewAndEdit,
    values?.errors,
    exportToExecutiveSummary,
    onClose,
    dispatch,
    autoGenEsValues,
    reportId,
    projectId,
  ])
  const prepend = (string, prefix) => `${prefix}${string}`

  const valuesFormatter = (label, value) => {
    switch (label) {
      case '#Percent_Complete':
      case `#Consultant's_Percent_Complete`:
      case `#Remaining_HCC_(after_COs)_%`:
        return `${parseFloat(value)?.toFixed(2)}%`
      case '#Change_Order_Current':
      case '#Change_Order_Total':
      case '#Current_Payment_Due_Recommended':
      case '#Current_Payment_Due_Requested':
      case '#Potential_Change_Order_Total':
      case '#Remaining_HCC_(after_COs)_$':
        return prepend(modifyNumber(value || 0), '$')

      default:
        return ternary(isDate(value), moment(value).format('MM/DD/YYYY'), value)
    }
  }

  const modalActions = useMemo(
    () => (
      <DKTButton
        variant="contained"
        onClick={exportEs}
        disabled={
          !values?.previewAndEdit ||
          isSavingAutoGenEs ||
          isLoadingChoices ||
          isLoadingAutoGenEsValues ||
          isLoadingAutoGenEsText
        }
        startIcon={
          isSavingAutoGenEs && (
            <DKTCircularProgress size={spinnerSize?.sm} color="grey" />
          )
        }
        sx={{ marginLeft: 'auto' }}
      >
        Export
      </DKTButton>
    ),
    [exportEs, values?.previewAndEdit, isSavingAutoGenEs],
  )

  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }
  const handleCloseChangeOrder = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }

  return (
    <DKTDialog
      open={isOpen}
      handleClose={handleCloseChangeOrder}
      title="Auto-generate an Executive Summary"
      actions={modalActions}
      maxWidth="lg"
    >
      {equal(isLoadingChoices, true) ||
      equal(isLoadingAutoGenEsValues, true) ||
      equal(isLoadingAutoGenEsText, true) ? (
        <Stack alignItems="center" justifyContent="center">
          <DKTCircularProgress />
        </Stack>
      ) : equal(isLoadingChoices, 'FAILED') ||
        equal(isLoadingAutoGenEsValues, 'FAILED') ||
        equal(isLoadingAutoGenEsText, 'FAILED') ? (
        <Stack alignItems="center" justifyContent="center">
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Executive summary generator
            data. Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DKTEditor
                configData={editorConfig}
                value={values?.esGenerator || ''}
                className={classes.esAutoGenComment}
                title="Executive Summary Generator (Use # to activate list of tags)&nbsp;"
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setValues({
                    ...values,
                    esGenerator: data,
                    previewAndEdit: replaceMentionsWithValues(
                      data,
                      autoGenEsValues,
                      valuesFormatter,
                    ),
                    errors: !data && 'Required',
                  })
                }}
                isRequired
                error={values?.errors}
              />
            </Grid>
            <Grid item md={6}>
              <Box backgroundColor="#fff" height="100%">
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Preview
                </Typography>
                <Stack
                  px={1.5}
                  sx={{
                    overflowY: 'auto',
                    height: `calc(100% - ${ternary(
                      values?.errors,
                      '44px',
                      '22px',
                    )})`,
                    maxHeight: 'calc(100vh - 611px)',
                    minHeight: `calc(100% - ${ternary(
                      values?.errors,
                      '44px',
                      '22px',
                    )})`,
                    boxSizing: 'border-box',
                    border: '1px solid #c4c4c4',
                    '& p:not(.MuiTypography-root)': {
                      lineHeight: 'normal',
                      margin: '14.4px 0 20px 0',
                    },
                    '& ol': {
                      lineHeight: 'normal',
                      '& ol': {
                        listStyleType: 'lower-latin',
                        '& ol': {
                          listStyleType: 'lower-roman',
                          '& ol': {
                            listStyleType: 'upper-latin',
                            '& ol': {
                              listStyleType: 'upper-roman',
                            },
                          },
                        },
                      },
                    },
                    '& ul': {
                      listStyleType: 'disc',
                      '& ul': {
                        listStyleType: 'circle',
                        '& ul': {
                          listStyleType: 'square',
                        },
                      },
                    },
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    dangerouslySetInnerHTML={renderHtmlContent(
                      values?.previewAndEdit,
                    )}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
          {/* show modal when tries to navigate without save data */}
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={exportEs}
          />
          <DKTReactRouterPrompt isDirty={isUnSavedChanges} onSave={exportEs} />
        </>
      )}
    </DKTDialog>
  )
}

export default AutoGenerateES
