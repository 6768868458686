import { useTheme } from '@emotion/react'
import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Link as MuiLink,
} from '@mui/material'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { formFields } from '../../../Description/budgetSummary.description'
import { importFromLastReport } from '../../../Redux/actions/budgetSummary'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTCurrencyTextField from '../../../Shared/DKTCurrencyTextField'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTEditor from '../../../Shared/DKTEditor'
import DKTInput from '../../../Shared/DKTInput'
import DKTForm from '../../../Shared/DKTForm'
import DKTRadioGroup from '../../../Shared/DKTRadioGroup'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTTextArea from '../../../Shared/DKTTextArea'
import DKTTooltip from '../../../Shared/DKTTooltip'
import { useStyles } from '../../../Styles/budgetSummary.style'
import { importButtonTooltip, spinnerSize } from '../../../Utils/constant'
import {
  checkIncludes,
  entries,
  equal,
  handleTextEditor,
  isArray,
  ternary,
} from '../../../Utils/javascript'
import ChangeOrder from '../trackers/ChangeOrder'
import G703 from '../trackers/G703'
import LienWaiver from '../trackers/LienWaiver'
import PotentialPotentialChangeOrder from '../trackers/PotentialChangeOrder'
import StoredMaterial from '../trackers/StoredMaterial'

const CreateBudgetSummary = ({
  hasOnlyViewPermission,
  dynamicOptions,
  handleChange,
  values,
  errors,
  setFieldValue,
  ownerContractorId,
  payAppId,
  reportId,
  budgetSummaryData,
  budgetSummaryNumberData,
  showTrackers,
}) => {
  const [isG703TrackerOpen, setIsG703TrackerOpen] = useState(false)
  const [isLienWaiverTrackerOpen, setIsLienWaiverTrackerOpen] = useState(false)
  const [isStoredMaterialTrackerOpen, setIsStoredMaterialTrackerOpen] =
    useState(false)
  const [isImportWarningOpen, setIsImportWarningOpen] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const { slug: projectId } = useParams()
  const [isOpenChangeOrderTracker, setIsOpenChangeOrderTracker] =
    useState(false)
  const [
    isOpenPotentialChangeOrderTracker,
    setIsOpenPotentialChangeOrderTracker,
  ] = useState(false)
  const importFieldName = useRef(null)
  const { isImporting } = useSelector(({ budgetSummary }) => budgetSummary)

  const disbursementDisabledFields = [
    'disbursementTotalCompletedStored',
    'disbursementRetainage',
    'disbursementTotalEarnedLessRetainage',
    'disbursementPreviousDisbursements',
    'disbursementCurrentPaymentDue',
    'disbursementBalanceToFinish',
  ]

  const openG703TrackerModal = () => setIsG703TrackerOpen(true)
  const closeG703TrackerModal = () => setIsG703TrackerOpen(false)
  const openLienWaiverTrackerModal = () => setIsLienWaiverTrackerOpen(true)
  const closeLienWaiverTrackerModal = () => setIsLienWaiverTrackerOpen(false)
  const openStoredMaterialTrackerModal = () =>
    setIsStoredMaterialTrackerOpen(true)
  const closeStoredMaterialTrackerModal = () =>
    setIsStoredMaterialTrackerOpen(false)

  const handleClose = () => {
    setIsOpenChangeOrderTracker(false)
    setIsOpenPotentialChangeOrderTracker(false)
    closeG703TrackerModal()
    closeLienWaiverTrackerModal()
    closeStoredMaterialTrackerModal()
  }

  /* IMPORT FROM LAST REPORT */
  const showImportWarning = () => setIsImportWarningOpen(true)
  const hideImportWarning = () => setIsImportWarningOpen(false)
  const handleImport = useCallback(
    (name) => {
      importFieldName.current = null
      if (values[name]) {
        importFieldName.current = name
        showImportWarning()
        return
      }

      dispatch(
        importFromLastReport({
          query: name,
          projectId,
          ocId: ownerContractorId,
          onSuccess: (value) => setFieldValue(name, value),
          reportId,
        }),
      )
    },
    [projectId, ownerContractorId, dispatch, values],
  )
  const handleImportOverWrite = () => {
    if (importFieldName.current) {
      const onSuccess = (value) => {
        setFieldValue(importFieldName.current, value)
        hideImportWarning()
      }
      dispatch(
        importFromLastReport({
          query: importFieldName.current,
          projectId,
          ocId: ownerContractorId,
          onSuccess,
          reportId,
        }),
      )
    }
  }
  const importWarningActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={hideImportWarning}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleImportOverWrite}
        disabled={Object.values(isImporting).some((value) => value)}
      >
        {ternary(
          Object.values(isImporting).some((value) => value),
          <DKTCircularProgress
            size={spinnerSize?.sm}
            color={theme.palette.gray.dark}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const mapFormFields = useCallback(
    ({ formFields, makeFieldNameUnique, id }) =>
      entries(formFields).map(
        (
          [
            name,
            {
              isRequired,
              isDynamicOptions,
              isDynamicFields,
              defaultValue,
              options,
              isConfirmPassword,
              isSeparate,
              separateSectionTitle,
              isPopUp,
              popupText,
              dependsOn,
              ...formField
            },
          ],
          index,
        ) => {
          name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
          const FormControl = ternary(
            equal(formField.type, 'select'),
            DKTSelect,
            DKTInput,
          )
          return (
            <Grid
              key={index}
              item
              lg={formField.lg ?? 10}
              xs={formField.xs ?? 12}
            >
              {ternary(
                formField?.isCurrency ||
                  formField?.decimalPlaces ||
                  formField?.formatDigits,
                <DKTCurrencyTextField
                  label={formField.label}
                  isRequired={isRequired}
                  name={name}
                  variant="standard"
                  value={
                    ternary(
                      equal(values?.disbursementRecommendation, 'AR'),
                      ternary(
                        equal(name, 'disbursementCurrentPaymentDue'),
                        values?.currentPaymentDue,
                        values && values[name],
                      ),
                      values && values[name],
                    ) ?? ''
                  }
                  error={errors[name]}
                  currencySymbol={formField?.isCurrency ? '$' : ''}
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={(event, value) =>
                    handleChange({ target: { name, value } })
                  }
                  disabled={
                    hasOnlyViewPermission ||
                    formField.disabled ||
                    (checkIncludes(name, disbursementDisabledFields) &&
                      equal(values?.disbursementRecommendation, 'AR')) ||
                    (equal(name, 'payApplicationNumber') &&
                      (budgetSummaryData?.length ||
                        !!budgetSummaryNumberData?.payApp ||
                        !!budgetSummaryNumberData?.currentReportPayApp))
                  }
                  decimalPlaces={formField?.decimalPlaces}
                  endAdornment={formField?.endAdornment}
                  isLimitLess={formField?.isLimitLess}
                />,
                ternary(
                  equal(formField.type, 'textarea'),
                  <Box>
                    <Stack
                      direction="row"
                      alignItems="end"
                      justifyContent="space-between"
                      mb={1}
                    >
                      <Typography
                        variant="body2"
                        color="gray.extraDark"
                        sx={{ fontWeight: 'medium' }}
                      >
                        {formField.label}
                      </Typography>
                      <DKTTooltip title={importButtonTooltip}>
                        <Box>
                          <DKTButton
                            variant="outlined"
                            onClick={() => handleImport(name)}
                            sx={{
                              borderColor: '#ACACAC',
                              color: '#2D2D2D',
                              width: '91px',
                              height: '32px',
                              '&:hover': {
                                borderColor: '#000',
                              },
                            }}
                            disabled={
                              hasOnlyViewPermission || isImporting[name]
                            }
                          >
                            {ternary(
                              isImporting[name],
                              <DKTCircularProgress
                                size={spinnerSize?.sm}
                                color={theme.palette.gray.dark}
                              />,
                              'Import',
                            )}
                          </DKTButton>
                        </Box>
                      </DKTTooltip>
                    </Stack>
                    <DKTTextArea
                      {...formField}
                      id={name}
                      name={name}
                      value={(values && values[name]) || defaultValue || ''}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      error={errors[name]}
                      variant="standard"
                      label=""
                      disabled={hasOnlyViewPermission}
                    />
                  </Box>,

                  equal(formField.type, 'radioGroup') ? (
                    <DKTRadioGroup
                      row
                      name={name}
                      label={formField?.label}
                      value={values[name]}
                      onChange={handleChange}
                      fields={ternary(
                        isDynamicFields,
                        dynamicOptions[name] || [],
                        formField?.fields,
                      )}
                      defaultValue="AR"
                      disabled={hasOnlyViewPermission}
                      radioProps={{ size: 'small' }}
                      labelSX={formField.labelSX}
                      className={classes.radioLabel}
                    />
                  ) : (
                    <FormControl
                      {...formField}
                      options={ternary(
                        isDynamicOptions,
                        dynamicOptions[name] || [],
                        options,
                      )}
                      isRequired={isRequired}
                      id={name}
                      name={name}
                      value={values?.[name] ?? defaultValue ?? ''}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      error={errors[name]}
                      disabled={
                        hasOnlyViewPermission ||
                        formField.disabled ||
                        (equal(name, 'disbursementCurrentPaymentDue') &&
                          equal(values?.disbursementRecommendation, 'AR')) ||
                        (equal(name, 'payApplicationNumber') &&
                          (budgetSummaryData?.length ||
                            !!budgetSummaryNumberData?.payApp ||
                            !!budgetSummaryNumberData?.currentReportPayApp))
                      }
                      inputProps={formField?.inputProps}
                    />
                  ),
                ),
              )}
            </Grid>
          )
        },
      ),
    [
      values,
      errors,
      handleChange,
      dynamicOptions,
      hasOnlyViewPermission,
      handleImport,
      isImporting,
    ],
  )

  const renderFormFields = useCallback(
    (formFields) => (
      <Grid container spacing={3}>
        {isArray(formFields)
          ? formFields.map((formField) =>
              mapFormFields({
                formFields: formField.data,
                makeFieldNameUnique: true,
                id: formField.id,
              }),
            )
          : mapFormFields({ formFields })}
      </Grid>
    ),
    [mapFormFields],
  )

  return (
    <Grid item xs={12} md={12} lg={10}>
      <DKTForm autoComplete="off">
        <Stack>{renderFormFields(formFields.draw)}</Stack>
      </DKTForm>

      {/* Payment application & change orders */}
      <Box
        className={classes.payDesc}
        mt={4}
        style={ternary(
          hasOnlyViewPermission,
          { color: 'grey', pointerEvents: 'none', userSelect: 'none' },
          {},
        )}
        sx={!showTrackers && { width: '48%' }}
      >
        <Typography variant="h6" component="h6">
          {`Payment Application ${ternary(
            showTrackers,
            '& Change Orders',
            '',
          )}`}
        </Typography>
      </Box>
      <Grid container spacing={10}>
        <Grid item xs={6} mt={2}>
          <Box mb={3}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                variant="body2"
                color="gray.extraDark"
                sx={{ fontWeight: 'medium' }}
              >
                Payment Application
              </Typography>
              <Stack direction="row" gap={2}>
                <MuiLink
                  component={Link}
                  to={`/projects/${projectId}/project-reports/${reportId}/g702-tracker`}
                  target="_blank"
                  color="primary"
                  underline="none"
                  variant="subtitle2"
                  sx={{
                    margin: 0,
                    fontWeight: '500',
                    fontSize: '0.875rem',
                    lineHeight: 1.57,
                    letterSpacing: '0.00714em',
                  }}
                >
                  G702 Split
                </MuiLink>
                <Typography
                  variant="subtitle2"
                  component="span"
                  color="primary"
                  className={classes.cursorPointer}
                  onClick={openG703TrackerModal}
                >
                  G703 Tracker
                </Typography>
              </Stack>
            </Box>
            <Divider light sx={{ mb: '5px' }} />
          </Box>
          <Grid container columns={24} spacing={2} mt={-3.2}>
            <Grid item xs={12} mt={1.6}>
              <DKTForm autoComplete="off">
                {renderFormFields(formFields.paymentApplicationColumnLeft)}
              </DKTForm>
            </Grid>
            <Grid item xs={12}>
              <DKTForm
                autoComplete="off"
                sx={{
                  backgroundColor: '#1564FF1A',
                  padding: '12px 10px 12px 10px',
                }}
              >
                {renderFormFields(formFields.paymentApplicationColumnRight)}
              </DKTForm>
            </Grid>
            <Grid item xs={24} mt={2}>
              <Stack
                direction="row"
                alignItems="end"
                justifyContent="space-between"
                mb={1}
              >
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Comments
                </Typography>
                <DKTTooltip title={importButtonTooltip}>
                  <Box>
                    <DKTButton
                      variant="outlined"
                      onClick={() => handleImport('paymentComments')}
                      sx={{
                        borderColor: '#ACACAC',
                        color: '#2D2D2D',
                        width: '91px',
                        height: '32px',
                        '&:hover': {
                          borderColor: '#000',
                        },
                      }}
                      disabled={
                        hasOnlyViewPermission || isImporting.paymentComments
                      }
                    >
                      {ternary(
                        isImporting.paymentComments,
                        <DKTCircularProgress
                          {...ternary(
                            isImporting.paymentComments,
                            { color: theme.palette.gray.dark },
                            {},
                          )}
                        />,
                        'Import',
                      )}
                    </DKTButton>
                  </Box>
                </DKTTooltip>
              </Stack>
              <DKTEditor
                value={values?.paymentComments || ''}
                onChange={(event, editor) => {
                  handleTextEditor(editor, 'paymentComments', setFieldValue)
                }}
                disabled={hasOnlyViewPermission}
                className={classes.projectDesc}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Change order and potential change order */}
        {showTrackers && (
          <Grid item xs={6} mt={2}>
            <Box mb={3}>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Change Orders (COs)
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="span"
                  color="primary"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setIsOpenChangeOrderTracker(true)}
                >
                  CO Tracker
                </Typography>
              </Box>
              <Divider light sx={{ mb: '5px' }} />
            </Box>
            <DKTForm autoComplete="off">
              {renderFormFields(formFields.changeOrder)}
            </DKTForm>
            <Stack
              direction="row"
              alignItems="end"
              justifyContent="space-between"
              mb={1}
              mt={2}
            >
              <Typography
                variant="body2"
                color="gray.extraDark"
                sx={{ fontWeight: 'medium' }}
              >
                Comments
              </Typography>
              <DKTTooltip title={importButtonTooltip}>
                <Box>
                  <DKTButton
                    variant="outlined"
                    onClick={() => handleImport('changeComments')}
                    sx={{
                      borderColor: '#ACACAC',
                      color: '#2D2D2D',
                      width: '91px',
                      height: '32px',
                      '&:hover': {
                        borderColor: '#000',
                      },
                    }}
                    disabled={
                      hasOnlyViewPermission || isImporting.changeComments
                    }
                  >
                    {ternary(
                      isImporting.changeComments,
                      <DKTCircularProgress
                        {...ternary(
                          isImporting.changeComments,
                          { color: theme.palette.gray.dark },
                          {},
                        )}
                      />,
                      'Import',
                    )}
                  </DKTButton>
                </Box>
              </DKTTooltip>
            </Stack>
            <DKTEditor
              value={values?.changeComments || ''}
              onChange={(event, editor) => {
                handleTextEditor(editor, 'changeComments', setFieldValue)
              }}
              disabled={hasOnlyViewPermission}
              className={classNames(
                classes.projectDesc,
                classes.coCommentTextEditor,
              )}
            />
            <Box mb={3} mt={2}>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Potential/Pending Change Order (PCO)
                </Typography>
                <Typography
                  variant="subtitle2"
                  component="span"
                  color="primary"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setIsOpenPotentialChangeOrderTracker(true)}
                >
                  PCO Tracker
                </Typography>
              </Box>
              <Divider light sx={{ mb: '5px' }} />
            </Box>
            <DKTForm autoComplete="off">
              {renderFormFields(formFields.pcoChangeOrder)}
            </DKTForm>
            <Stack
              direction="row"
              alignItems="end"
              justifyContent="space-between"
              mb={1}
              mt={2}
            >
              <Typography
                variant="body2"
                color="gray.extraDark"
                sx={{ fontWeight: 'medium' }}
              >
                Comments
              </Typography>
              <DKTTooltip title={importButtonTooltip}>
                <Box>
                  <DKTButton
                    variant="outlined"
                    onClick={() => handleImport('potentialChangeOrderComments')}
                    sx={{
                      borderColor: '#ACACAC',
                      color: '#2D2D2D',
                      width: '91px',
                      height: '32px',
                      '&:hover': {
                        borderColor: '#000',
                      },
                    }}
                    disabled={
                      hasOnlyViewPermission ||
                      isImporting.potentialChangeOrderComments
                    }
                  >
                    {ternary(
                      isImporting.potentialChangeOrderComments,
                      <DKTCircularProgress
                        {...ternary(
                          isImporting.potentialChangeOrderComments,
                          { color: theme.palette.gray.dark },
                          {},
                        )}
                      />,
                      'Import',
                    )}
                  </DKTButton>
                </Box>
              </DKTTooltip>
            </Stack>
            <DKTEditor
              value={values?.potentialChangeOrderComments || ''}
              onChange={(event, editor) => {
                handleTextEditor(
                  editor,
                  'potentialChangeOrderComments',
                  setFieldValue,
                )
              }}
              disabled={hasOnlyViewPermission}
              className={classNames(
                classes.projectDesc,
                classes.pcoCommentTextEditor,
              )}
            />
          </Grid>
        )}
      </Grid>

      {/* Lien Waivers & Stored Materials */}
      {showTrackers && (
        <>
          <Box
            className={classes.payDesc}
            style={ternary(
              hasOnlyViewPermission,
              { color: 'grey', pointerEvents: 'none', userSelect: 'none' },
              {},
            )}
            sx={{ mt: 6 }}
          >
            <Typography variant="h6" component="h6">
              Lien Waivers & Stored Materials
            </Typography>
          </Box>
          <Grid container spacing={10}>
            <Grid item xs={6} mt={2}>
              <Box mb={3}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    color="gray.extraDark"
                    sx={{ fontWeight: 'medium' }}
                  >
                    Lien Waivers
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="primary"
                    sx={{ cursor: 'pointer' }}
                    onClick={openLienWaiverTrackerModal}
                  >
                    Lien Waiver Tracker
                  </Typography>
                </Box>
                <Divider light sx={{ mb: '5px' }} />
              </Box>
              <DKTForm autoComplete="off">
                {renderFormFields(formFields.lienWaivers)}
              </DKTForm>
              <Stack
                direction="row"
                alignItems="end"
                justifyContent="space-between"
                mb={1}
                mt={2}
              >
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Comments
                </Typography>
                <DKTTooltip title={importButtonTooltip}>
                  <Box>
                    <DKTButton
                      variant="outlined"
                      onClick={() => handleImport('lienWaiverComments')}
                      sx={{
                        borderColor: '#ACACAC',
                        color: '#2D2D2D',
                        width: '91px',
                        height: '32px',
                        '&:hover': {
                          borderColor: '#000',
                        },
                      }}
                      disabled={
                        hasOnlyViewPermission || isImporting.lienWaiverComments
                      }
                    >
                      {ternary(
                        isImporting.lienWaiverComments,
                        <DKTCircularProgress
                          {...ternary(
                            isImporting.lienWaiverComments,
                            { color: theme.palette.gray.dark },
                            {},
                          )}
                        />,
                        'Import',
                      )}
                    </DKTButton>
                  </Box>
                </DKTTooltip>
              </Stack>
              <DKTEditor
                value={values?.lienWaiverComments || ''}
                onChange={(event, editor) => {
                  handleTextEditor(editor, 'lienWaiverComments', setFieldValue)
                }}
                disabled={hasOnlyViewPermission}
                className={classes.projectDesc}
              />
            </Grid>
            <Grid item xs={6} mt={2}>
              <Box mb={3}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    color="gray.extraDark"
                    sx={{ fontWeight: 'medium' }}
                  >
                    Stored Materials
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="primary"
                    className={classes.cursorPointer}
                    onClick={openStoredMaterialTrackerModal}
                  >
                    Stored Material Tracker
                  </Typography>
                </Box>
                <Divider light sx={{ mb: '5px' }} />
              </Box>
              <DKTForm autoComplete="off">
                {renderFormFields(formFields.storedMaterials)}
              </DKTForm>
              <Stack
                direction="row"
                alignItems="end"
                justifyContent="space-between"
                mb={1}
                mt={2}
              >
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Comments
                </Typography>
                <DKTTooltip title={importButtonTooltip}>
                  <Box>
                    <DKTButton
                      variant="outlined"
                      onClick={() => handleImport('storedMaterialsComments')}
                      sx={{
                        borderColor: '#ACACAC',
                        color: '#2D2D2D',
                        width: '91px',
                        height: '32px',
                        '&:hover': {
                          borderColor: '#000',
                        },
                      }}
                      disabled={
                        hasOnlyViewPermission ||
                        isImporting.storedMaterialsComments
                      }
                    >
                      {ternary(
                        isImporting.storedMaterialsComments,
                        <DKTCircularProgress
                          {...ternary(
                            isImporting.storedMaterialsComments,
                            { color: theme.palette.gray.dark },
                            {},
                          )}
                        />,
                        'Import',
                      )}
                    </DKTButton>
                  </Box>
                </DKTTooltip>
              </Stack>
              <DKTEditor
                value={values?.storedMaterialsComments || ''}
                onChange={(event, editor) => {
                  handleTextEditor(
                    editor,
                    'storedMaterialsComments',
                    setFieldValue,
                  )
                }}
                disabled={hasOnlyViewPermission}
                className={classNames(
                  classes.projectDesc,
                  classes.storedCommentTextEditor,
                )}
              />
            </Grid>
          </Grid>
        </>
      )}

      {/* Trackers */}
      <G703
        open={isG703TrackerOpen}
        onClose={closeG703TrackerModal}
        dynamicOptions={dynamicOptions}
        uniqueCode={values.payApplicationNumber}
        ownerContractor={ownerContractorId}
      />
      {showTrackers && (
        <>
          <LienWaiver
            open={isLienWaiverTrackerOpen}
            onClose={closeLienWaiverTrackerModal}
            dynamicOptions={dynamicOptions}
            uniqueCode={values.payApplicationNumber}
            ownerContractor={ownerContractorId}
          />
          <StoredMaterial
            open={isStoredMaterialTrackerOpen}
            onClose={closeStoredMaterialTrackerModal}
            dynamicOptions={dynamicOptions}
            uniqueCode={values.payApplicationNumber}
            ownerContractor={ownerContractorId}
          />
          <ChangeOrder
            open={isOpenChangeOrderTracker}
            handleClose={handleClose}
            dynamicOptions={dynamicOptions}
            payAppId={payAppId}
            ownerContractorId={ownerContractorId}
            reportId={reportId}
            hasOnlyViewPermission={hasOnlyViewPermission}
          />
          <PotentialPotentialChangeOrder
            open={isOpenPotentialChangeOrderTracker}
            handleClose={handleClose}
            dynamicOptions={dynamicOptions}
            payAppId={payAppId}
            ownerContractorId={ownerContractorId}
            reportId={reportId}
            hasOnlyViewPermission={hasOnlyViewPermission}
          />
        </>
      )}

      {/* Import content over-write warning */}
      <DKTDialog
        open={isImportWarningOpen}
        handleClose={hideImportWarning}
        title="&nbsp;"
        actions={importWarningActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Existing data will be overwritten.
              </Typography>
              <Typography variant="body1">Continue?</Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
    </Grid>
  )
}

export default CreateBudgetSummary
