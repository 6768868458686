import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  floodZone,
  formFields,
  optionsFields,
} from '../../Description/environmental.description'
import useForm from '../../Hooks/useForm'
import { InnerLayout } from '../../Layout/project'
import {
  addEnvironmental,
  createEnvironmental,
  deleteEnvironmental,
  getEnvironmental,
  getEnvironmentalList,
  getEnvironmentalMapping,
  saveEnvironmental,
  setEnvironmentalId,
} from '../../Redux/actions/environmental'
import { fetchProjectFieldChoices } from '../../Redux/actions/projects'
import DKTButton from '../../Shared/DKTButton'
import DKTInput from '../../Shared/DKTInput'
import DKTSelect from '../../Shared/DKTSelect'
import DKTButtonSelect from '../../Shared/DKTButtonSelect'
import DKTDialog from '../../Shared/DKTDialog'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import DKTForm from '../../Shared/DKTForm'

import { BOOLEAN, NUMBER, SA, spinnerSize } from '../../Utils/constant'
import {
  checkIncludes,
  entries,
  equal,
  isArray,
  length,
  ternary,
} from '../../Utils/javascript'
import { renderFormFields as renderFormFieldsControl } from '../../Utils/renderFormFields'
import { updateOnSaveStatus } from '../../Redux/actions/confirmation'
import { CLEAR_ENVIRONMENTAL } from '../../Redux/constants/environmental'

const useStyles = makeStyles(() => ({
  addBtn: {
    marginLeft: 230,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  inlineLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 'bold',
      color: '#2D2D2D',
      fontSize: 14,
      marginBottom: 8,
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))

const FormSectionDivider = ({ sectionTitle, ...rest }) => (
  <Box {...rest}>
    <Typography
      variant="body2"
      color="gray.extraDark"
      sx={{ fontWeight: 'medium' }}
    >
      {sectionTitle}
    </Typography>
    <Divider sx={{ mb: 3 }} />
  </Box>
)

const allFormFields = entries(formFields).reduce(
  (result, formField) => {
    const formFieldValue = formField[1]
    return {
      ...result,
      ...formFieldValue,
    }
  },
  { floodZone },
)

const Environmental = () => {
  const dispatch = useDispatch()
  const { slug: projectId } = useParams()
  const classes = useStyles()
  const [projectOptionChoicesData, setProjectOptionChoicesData] = useState([])
  const [isDeleteNotificationOpen, setIsOpenDeleteNotification] =
    React.useState(false)
  const [isSaveWarningOpen, setIsSaveWarningOpen] = useState(false)
  const [tempEnData, setTempEnData] = useState({
    id: null,
    isNewEnvironmental: false,
    add: false,
  })
  const { projectOptionChoices } = useSelector(({ projects }) => projects)
  const {
    environmentals,
    environmental,
    environmentalId,
    isNewEnvironmental,
    mappings,
    isSavingEnvironmental,
    isLoadingEnvironmentalList,
    isCreatingEnvironmental,
    isDeletingEnvironmental,
    isLoadingEnvironmental,
  } = useSelector(({ environmental }) => environmental)

  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)

  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+projectId]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission = isGlobalViewer || isProjectViewer

  const {
    values,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    setErrors,
    isDirty,
    setIsDirty,
    setDummyData,
  } = useForm(allFormFields, CLEAR_ENVIRONMENTAL)
  useEffect(() => {
    if (projectOptionChoices) {
      setProjectOptionChoicesData(projectOptionChoices)
    }
  }, [projectOptionChoices])

  useEffect(() => {
    dispatch(fetchProjectFieldChoices(optionsFields))
    dispatch(getEnvironmentalList(projectId))
  }, [])

  useEffect(() => {
    if (!environmentalId) return
    if (!equal(environmental?.id, +environmentalId) && !isNewEnvironmental)
      dispatch(getEnvironmental(environmentalId))

    const environmentalValues = { ...values }
    if (environmental) {
      entries(environmental).forEach(([fieldName, fieldValue]) => {
        environmentalValues[fieldName] = equal(fieldName, 'mapping')
          ? isArray(fieldValue) && fieldValue?.length
            ? fieldValue?.map((mapEle) => `${mapEle}`)
            : equal(mappings?.length, 1)
            ? [mappings[0]?.value]
            : []
          : checkIncludes(typeof fieldValue, [BOOLEAN, NUMBER])
          ? fieldValue.toString()
          : fieldValue
      })
    } else {
      environmentalValues.mapping =
        isArray(environmental?.mapping) && environmental?.mapping?.length
          ? environmental?.mapping?.map((mapEle) => `${mapEle}`)
          : equal(mappings?.length, 1)
          ? [mappings[0]?.value]
          : []
    }
    setValues(environmentalValues)
    setDummyData(environmentalValues)
  }, [environmental, dispatch, environmentalId, setValues, isNewEnvironmental])

  useEffect(() => {
    if (isNewEnvironmental) resetForm()
    setErrors({})
    if (
      !environmentalId &&
      environmentals &&
      isArray(environmentals) &&
      environmentals[0]
    )
      dispatch(
        setEnvironmentalId(
          environmentals[0]?.id,
          environmentals[0]?.isNewEnvironmental,
        ),
      )
  }, [
    environmentalId,
    isNewEnvironmental,
    setValues,
    setErrors,
    environmentals,
    isDeletingEnvironmental,
  ])

  useEffect(
    () => () => {
      dispatch(setEnvironmentalId(environmentals?.[0]?.id, false))
    },
    [],
  )

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      mapping: mappings?.length
        ? isArray(environmental?.mapping) &&
          environmental?.mapping?.length &&
          !isNewEnvironmental
          ? environmental?.mapping?.map((mapEle) => `${mapEle}`)
          : equal(mappings?.length, 1)
          ? [mappings[0]?.value]
          : []
        : [],
    }))
    setDummyData((prevValues) => ({
      ...prevValues,
      mapping: mappings?.length
        ? isArray(environmental?.mapping) &&
          environmental?.mapping?.length &&
          !isNewEnvironmental
          ? environmental?.mapping?.map((mapEle) => `${mapEle}`)
          : equal(mappings?.length, 1)
          ? [mappings[0]?.value]
          : []
        : [],
    }))
  }, [
    environmental,
    mappings,
    setFieldValue,
    isNewEnvironmental,
    environmentalId,
  ])

  useEffect(() => {
    if (
      !hasOnlyViewPermission &&
      !isLoadingEnvironmentalList &&
      isArray(environmentals) &&
      equal(length(environmentals), 0)
    )
      dispatch(addEnvironmental())
  }, [
    environmentals,
    isLoadingEnvironmentalList,
    dispatch,
    isDeletingEnvironmental,
  ])

  useEffect(() => {
    dispatch(getEnvironmentalMapping(projectId))
  }, [environmental, isNewEnvironmental])

  useEffect(() => {
    if (tempEnData?.id) {
      if (isDirty) {
        setIsSaveWarningOpen(true)
      } else {
        resetForm()
        setTimeout(() => {
          dispatch(
            setEnvironmentalId(tempEnData?.id, tempEnData?.isNewEnvironmental),
          )
        }, 500)
        setErrors({})
      }
    } else if (tempEnData?.add) {
      if (isDirty) {
        setIsSaveWarningOpen(true)
      } else {
        dispatch(addEnvironmental())
        resetForm()
      }
    }
  }, [tempEnData])
  const handleDeleteEnvironmental = () => {
    const body = {
      environmental: [environmentalId],
    }
    dispatch(
      deleteEnvironmental(body, () => setIsOpenDeleteNotification(false)),
    )
  }

  const deleteNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => setIsOpenDeleteNotification(false)}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteEnvironmental}>
        {ternary(
          isDeletingEnvironmental,
          <DKTCircularProgress size={spinnerSize?.sm} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          { isRequired, isDynamicOptions, defaultValue, options, ...formField },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <Grid key={index} item xs={formField.xs ?? 12}>
            <FormControl
              {...formField}
              options={projectOptionChoicesData[name] || options || []}
              id={name}
              name={name}
              value={(values && values[name]) || defaultValue || ''}
              onChange={(e) => {
                handleChange(e)
              }}
              error={errors[name]}
              disabled={hasOnlyViewPermission || formField.disabled}
            />
          </Grid>
        )
      },
    )

  const renderFormFields = useCallback(
    (formFields, dynamicFormFields = false) => (
      <Grid container spacing={2}>
        {isArray(formFields)
          ? dynamicFormFields
            ? formFields?.map((formField) =>
                renderFormFieldsControl({
                  formFields: {
                    test: { type: 'checkbox', ...formField },
                  },
                }),
              )
            : formFields?.map((formField) =>
                mapFormFields({
                  formFields: formField?.data,
                  makeFieldNameUnique: true,
                  id: formField?.id,
                }),
              )
          : mapFormFields({ formFields })}
      </Grid>
    ),
    [projectOptionChoicesData, values, errors],
  )

  const customFormControlFields = ({ name, formControl }) => ({
    options: ternary(equal(name, 'mapping'), mappings, undefined),
    ...ternary(
      equal(name, 'floodZone'),
      { fields: projectOptionChoices.floodZone },
      {},
    ),
    disabled: hasOnlyViewPermission || formControl.disabled,
  })

  const handleSave = () => {
    const isFormValid = handleSubmit()
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
    } else {
      setIsDirty(false)
      const filteredObj = { ...values }
      Object.keys(filteredObj).forEach((key) => {
        if (equal(filteredObj[key], undefined) || equal(filteredObj[key], '')) {
          delete filteredObj[key]
          filteredObj[key] = null
        }
      })
      if (
        equal(filteredObj?.mapping?.length, 0) ||
        equal(filteredObj?.mapping, null)
      ) {
        delete filteredObj.mapping
        filteredObj.isDeleted = true
      }

      if (isNewEnvironmental) {
        dispatch(createEnvironmental(filteredObj, projectId))
        return
      }
      dispatch(saveEnvironmental(filteredObj, environmentalId, projectId))
    }
  }

  const actionList = [
    { label: 'Delete', onClick: () => setIsOpenDeleteNotification(true) },
  ]

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setTempEnData({
      id: null,
      isNewEnvironmental: null,
      add: false,
    })
  }
  const confirmSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    dispatch(setEnvironmentalId(tempEnData?.id, tempEnData?.isNewEnvironmental))
    setIsDirty(false)
    setErrors({})
    if (tempEnData?.add) {
      dispatch(addEnvironmental())
      resetForm()
    }
    setTempEnData({
      id: null,
      isNewEnvironmental: null,
      add: false,
    })
  }
  // const handleAddEnvironment = useCallback(() => {
  //   setTempEnData({ ...tempEnData, add: true })
  // }, [dispatch, resetForm])
  const actions = () => (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Stack direction="row" gap={2} className={classes.rightButton}>
        <DKTButtonSelect
          options={actionList}
          disabled={
            isLoadingEnvironmentalList || isLoadingEnvironmental || true
          }
        />
        <DKTButton
          onClick={handleSave}
          className={classes.rightButton}
          disabled={
            hasOnlyViewPermission ||
            isSavingEnvironmental ||
            isCreatingEnvironmental ||
            isLoadingEnvironmentalList ||
            isLoadingEnvironmental
          }
        >
          {ternary(
            isSavingEnvironmental || isCreatingEnvironmental,
            'Saving...',
            'Save',
          )}
        </DKTButton>
      </Stack>
    </Box>
  )

  const activeEnvironment = environmentals?.find((environmental) =>
    equal(environmental?.id, environmentalId),
  )

  const environmentalIssues = renderFormFields(formFields.environmentalIssues)
  const seismicAnalysis = renderFormFields(formFields.seismicAnalysis)
  const floodZoneForm = renderFormFields(formFields.floodZone)
  const floodZoneFormField = renderFormFieldsControl({
    formFields: { floodZone },
    values,
    errors,
    handleChange,
    customFormControlFields,
  })
  const comments = renderFormFieldsControl({
    formFields: formFields.comments,
    values,
    errors,
    handleChange,
    customFormControlFields,
  })

  return (
    <InnerLayout contentTitle="Environmental" actions={actions} maxWidth="md">
      {equal(isLoadingEnvironmentalList, true) ||
      equal(isLoadingEnvironmental, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isLoadingEnvironmentalList, 'FAILED') ||
        equal(isLoadingEnvironmental, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Environmental data. Please
            try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <Grid container>
          <Grid item xs={12} xl={8}>
            <Grid
              container
              columnSpacing={10}
              columnGap={{ sm: 0, xl: 11 }}
              rowSpacing={3}
            >
              <Grid item lg={5} sm={6}>
                <FormSectionDivider sectionTitle="Environmental Issues" />
                {environmentalIssues}
              </Grid>
              <Grid item lg={5} sm={6}>
                <FormSectionDivider sectionTitle="Seismic Analysis" />
                <DKTForm autoComplete="off">{seismicAnalysis}</DKTForm>
                <FormSectionDivider sectionTitle="Flood Zone" mt={4} />
                {floodZoneForm}
                <Grid container mb={1}>
                  {floodZoneFormField}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11} mt={2}>
              <Box mt={3}>
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Comments
                </Typography>
                {comments}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={() => setIsOpenDeleteNotification(false)}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                {`Are you sure you want to delete ${activeEnvironment?.uniqueCode}?`}
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isSaveWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
    </InnerLayout>
  )
}

export default Environmental
