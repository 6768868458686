import React from 'react'
import { Outlet } from 'react-router-dom'
import { OuterLayout } from '../../../Layout/rfp'

const RFPLayout = () => (
  <OuterLayout title="Request For Proposal (RFP)">
    <Outlet />
  </OuterLayout>
)

export default RFPLayout
