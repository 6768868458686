// Fetch CMR-Cover letter

export const FETCH_CMR_COVER_LETTER_REQUESTED =
  'FETCH_CMR_COVER_LETTER_REQUESTED'
export const FETCH_CMR_COVER_LETTER_SUCCESS = 'FETCH_CMR_COVER_LETTER_SUCCESS'
export const FETCH_CMR_COVER_LETTER_FAILED = 'FETCH_CMR_COVER_LETTER_FAILED'

export const FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_REQUESTED =
  'FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_REQUESTED'
export const FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_SUCCESS =
  'FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_SUCCESS'
export const FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_FAILED =
  'FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_FAILED'

// update CMR-Cover letter
export const UPDATE_COVER_LETTER_REQUESTED = 'UPDATE_COVER_LETTER_REQUESTED'
export const UPDATE_COVER_LETTER_SUCCESS = 'UPDATE_COVER_LETTER_SUCCESS'
export const UPDATE_COVER_LETTER_FAILED = 'UPDATE_COVER_LETTER_FAILED'
export const CLEAR_COVER_LETTER_VALUE = 'CLEAR_COVER_LETTER_VALUE'

// fetch signatures
export const FETCH_SIGNATURE_REQUESTED = 'FETCH_SIGNATURE_REQUESTED'
export const FETCH_SIGNATURE_SUCCESS = 'FETCH_SIGNATURE_SUCCESS'
export const FETCH_SIGNATURE_FAILED = 'FETCH_SIGNATURE_FAILED'

// fetch client names
export const FETCH_CLIENT_NAME_SUCCESS = 'FETCH_CLIENT_NAME_SUCCESS'
