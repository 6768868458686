import React, { useMemo, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import DKTDialog from '../../../Shared/DKTDialog'
import { equal, keys, ternary } from '../../../Utils/javascript'
import DKTButton from '../../../Shared/DKTButton'
import { formFields } from '../../../Description/admin.clientAccess.description'
import useForm from '../../../Hooks/useForm'
import { addEditProjectAccess } from '../../../Redux/actions/clientAccess'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'

const ProjectAccessModal = ({
  open,
  handleClose,
  projectAccessEdit,
  setProjectAccessEdit,
  mapFormFields,
  client,
  projectList,
}) => {
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    setValues,
    setDummyData,
    isDirty,
    setIsDirty,
    setIsNavigate,
  } = useForm(formFields?.ProjectAccessFields)
  const dispatch = useDispatch()

  const { isLoadingProjectAccessAddEdit } = useSelector(
    ({ clientAccess }) => clientAccess,
  )

  const isEditProjectAccess = !!keys(projectAccessEdit)?.length

  useEffect(() => {
    if (keys(projectAccessEdit)?.length) {
      const editData = keys?.(formFields?.ProjectAccessFields)?.reduce(
        (acc, key) => {
          if (equal(key, 'projectName')) {
            acc.projectName = projectAccessEdit?.id?.toString()
          } else {
            acc[key] = projectAccessEdit?.[key]
          }
          return acc
        },
        {},
      )
      setValues({ ...values, ...editData, client })
      setDummyData({ ...values, ...editData, client })
    } else if (client) {
      setValues({ ...values, client })
      setDummyData({ ...values, client })
    }
  }, [open, projectAccessEdit, client])

  useEffect(() => {
    const projectInternalId = projectList?.find(({ id }) =>
      equal(id, +values.projectName),
    )?.projectNumberInternal

    if (projectInternalId) {
      setFieldValue('projectNumberInternal', projectInternalId)
    }
  }, [values?.projectName])

  const handleCloseModal = useMemo(
    () => () => {
      handleClose()
      setProjectAccessEdit()
      resetForm()
    },
    [handleClose, setProjectAccessEdit, resetForm],
  )

  const onCloseModal = useMemo(
    () => () => {
      if (isDirty) {
        setIsUnSavedWarningOpen(true)
        return
      }
      handleCloseModal()
    },
    [isDirty, handleCloseModal],
  )

  const handleSave = useMemo(
    () => () => {
      const isFormValid = handleSubmit()
      if (!isFormValid) {
        dispatch(updateOnSaveStatus({ cancel: true }))
      } else {
        const payload = {
          project: values?.projectName,
          company: values?.client,
          engagementType: values?.engagementType,
        }
        dispatch(
          addEditProjectAccess(
            payload,
            projectAccessEdit?.clientAccessId,
            isEditProjectAccess,
          ),
        )
        handleCloseModal()
        setIsDirty(false)
      }
    },
    [
      values,
      handleSubmit,
      dispatch,
      isEditProjectAccess,
      handleCloseModal,
      setIsDirty,
      projectAccessEdit,
    ],
  )

  const dialogActions = (
    <DKTButton onClick={handleSave} sx={{ marginLeft: 'auto' }}>
      {ternary(isLoadingProjectAccessAddEdit, 'Saving...', 'Save')}
    </DKTButton>
  )

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }

  const confirmSaveWarningModal = () => {
    setIsDirty(false)
    setIsUnSavedWarningOpen(false)
    handleCloseModal()
    setIsNavigate(true)
  }

  return (
    <>
      <DKTDialog
        open={open}
        handleClose={onCloseModal}
        title={`${ternary(
          isEditProjectAccess,
          'Edit',
          'Grant',
        )} Project Access`}
        actions={dialogActions}
        maxWidth="xs"
      >
        <Grid container spacing={2}>
          {mapFormFields({
            values,
            errors,
            handleChange,
            formFields: formFields?.ProjectAccessFields,
          })}
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnSavedWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
    </>
  )
}

export default ProjectAccessModal
