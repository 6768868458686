import { BOOLEAN } from '../Utils/constant'
import { emailValidation } from '../Utils/regex'

export const loginFields = {
  email: {
    type: 'text',
    label: 'Email Address',
    placeholder: 'Email Address',
    isRequired: true,
    showLabel: false,
    validate: [emailValidation],
  },
  rememberUsername: {
    type: 'checkbox',
    valueType: BOOLEAN,
    fields: [
      {
        label: 'Remember Username',
        value: 'rememberUsername',
        labelledCheckbox: true,
      },
    ],
  },
  password: {
    isRequired: true,
    showLabel: false,
    type: 'password',
    label: 'Password',
    placeholder: 'Password',
    className: 'passwordInput',
    sx: {
      marginTop: 0,
    },
  },
}
