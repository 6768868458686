import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import StarRateIcon from '@mui/icons-material/StarRate'
import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ImagePlaceholder } from '../../Assets/SVGs'
import DKTGrid from '../../Shared/DKTGrid'
import DKTStack from '../../Shared/DKTStack'
import { equal, ternary } from '../../Utils/javascript'
import { clientPortal } from '../../Utils/constant'

const ClientAccessDashboardTableRow = ({
  row,
  handleRedirect,
  handleFavorite,
  handleRowClick,
  classes,
  projects,
  setProjects,
  renderTypography,
  renderStack,
}) => {
  const [imageHeight, setImageHeight] = useState(112)

  const { portalType } = useSelector(({ auth }) => auth)
  const divRef = useRef(null)

  useEffect(() => {
    if (divRef.current) {
      const divHeight = divRef.current.offsetHeight
      setImageHeight(divHeight)
    }
  }, [row])

  return (
    <DKTGrid container mb={1} onClick={() => handleRowClick(row?.id)}>
      <DKTGrid item md={4} xs={12}>
        <DKTStack
          minHeight={112}
          justifyContent="end"
          className={ternary(
            row?.projectDashboardAttachmentData?.attachment,
            classes.imageWrapper,
            classes.imagePlaceholder,
          )}
        >
          {ternary(
            row?.projectDashboardAttachmentData?.attachment,
            <img
              src={row?.projectDashboardAttachmentData?.attachment}
              alt=""
              style={{ maxHeight: imageHeight }}
              onLoad={() => {
                const divHeight = divRef.current.offsetHeight
                setImageHeight(divHeight)
              }}
            />,
            <ImagePlaceholder style={{ height: imageHeight }} />,
          )}
        </DKTStack>
      </DKTGrid>
      <DKTGrid item md={8} xs={12}>
        <div ref={divRef}>
          <DKTStack
            direction="row"
            justifyContent="space-between"
            position="relative"
            alignItems="start"
            padding="11px 13px"
            sx={{ background: '#fff' }}
            minHeight={90}
          >
            <Box>
              {renderTypography({
                text: row?.projectName,
                color: 'primary.main',
                fontWeight: '700',
                sx: { cursor: 'pointer' },
                onClick: () => handleRedirect(row?.id),
              })}
              {renderTypography({
                text: `${row?.city}, `,
                children: renderTypography({
                  text: row?.state,
                  variant: 'body',
                }),
              })}
              {renderTypography({
                text: row?.status,
                children: renderTypography({
                  text: `: ${row?.complete || 0}% complete`,
                  variant: 'body',
                  fontWeight: '700',
                  ml: 0.4,
                }),
                pb: 0.1,
                fontWeight: '700',
              })}
              {renderTypography({
                text: `${
                  ternary(
                    equal(portalType, clientPortal),
                    row?.company?.companyName,
                    row?.clientName,
                  ) || ''
                }`,
              })}
            </Box>
            <DKTStack height="100%" flex="0 0 40%" mt="auto">
              {renderStack('Cost Overrun', `${row?.costOverrun}%`)}
              {renderStack('Schedule Delay', `${row?.scheduleDelay || 0} days`)}
              {renderStack(
                'Reports Outstanding',
                `${row?.reportOutstanding || 0} days`,
              )}
              <Box
                onClick={(e) =>
                  handleFavorite(
                    e,
                    row?.id,
                    row?.isFavorite,
                    projects,
                    setProjects,
                  )
                }
                position="absolute"
                top={7}
                right={7}
                sx={{ cursor: 'pointer' }}
              >
                {row?.isFavorite ? (
                  <StarRateIcon style={{ color: '#FFEA00' }} />
                ) : (
                  <StarOutlineOutlinedIcon />
                )}
              </Box>
            </DKTStack>
          </DKTStack>
        </div>
      </DKTGrid>
    </DKTGrid>
  )
}

export default ClientAccessDashboardTableRow
