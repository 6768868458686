import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { keys, ternary } from '../../Utils/javascript'
import { saveStateFn } from '../../Utils/localStorage'
import {
  FETCH_COMPANY_FAILED,
  FETCH_COMPANY_KEY_PERSONNEL_FAILED,
  FETCH_COMPANY_KEY_PERSONNEL_REQUESTED,
  FETCH_COMPANY_KEY_PERSONNEL_SUCCESS,
  FETCH_COMPANY_REQUESTED,
  FETCH_COMPANY_SERVICE_OFFERED_FAILED,
  FETCH_COMPANY_SERVICE_OFFERED_REQUESTED,
  FETCH_COMPANY_SERVICE_OFFERED_SUCCESS,
  FETCH_COMPANY_SERVICE_STATE_LOCATION_FAILED,
  FETCH_COMPANY_SERVICE_STATE_LOCATION_REQUESTED,
  FETCH_COMPANY_SERVICE_STATE_LOCATION_SUCCESS,
  FETCH_COMPANY_SERVICE_US_TERRITORIES_FAILED,
  FETCH_COMPANY_SERVICE_US_TERRITORIES_REQUESTED,
  FETCH_COMPANY_SERVICE_US_TERRITORIES_SUCCESS,
  FETCH_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
  UPDATE_COMPANY_REQUESTED,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_KEY_PERSONNEL_FAILED,
  UPDATE_KEY_PERSONNEL_REQUESTED,
  UPDATE_KEY_PERSONNEL_SUCCESS,
} from '../constants/companyInformation'
import { updateSaveStatusAction } from './confirmation'

// Fetch company
const fetchCompanyRequested = () => ({ type: FETCH_COMPANY_REQUESTED })
const fetchCompanySuccess = (payload) => ({
  type: FETCH_COMPANY_SUCCESS,
  payload,
})
const fetchCompanyFailed = (payload) => ({
  type: FETCH_COMPANY_FAILED,
  payload,
})

export const getCompany = (id) => async (dispatch) => {
  dispatch(fetchCompanyRequested())
  const { data, error } = await api({
    endPoint: ternary(
      id,
      `${apiEndPoints.company}${id}/`,
      `${apiEndPoints.company}`,
    ),
    method: method.get,
    showToastMessage: false,
    toastMessage: 'Fetch Company successfully!',
  })
  if (data) {
    if (id) {
      dispatch(fetchCompanySuccess([data]))
    } else {
      dispatch(fetchCompanySuccess(data))
    }
    if (data[0]?.id) {
      saveStateFn('company-id', data[0]?.id)
    }
    return
  }
  if (error) {
    dispatch(fetchCompanyFailed(error.response.data))
  }
}
// Update company
const updateCompanyRequested = () => ({ type: UPDATE_COMPANY_REQUESTED })
const updateCompanySuccess = (payload) => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload,
})
const updateCompanyFailed = (payload) => ({
  type: UPDATE_COMPANY_FAILED,
  payload,
})

export const submitUpdateCompany = (payload, id) => async (dispatch) => {
  dispatch(updateCompanyRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.company}${id}/`,
    method: method.patch,
    data: payload,
    showToastMessage: true,
    toastMessage: 'Company updated successfully!',
  })
  setTimeout(() => {
    dispatch(updateSaveStatusAction(data, error))
  }, 700)
  if (data) {
    dispatch(updateCompanySuccess(data))
    return
  }
  if (error) {
    dispatch(updateCompanyFailed(error.response.data))
  }
}

// Fetch company service-offered
const fetchCompanyServiceOfferedRequested = () => ({
  type: FETCH_COMPANY_SERVICE_OFFERED_REQUESTED,
})
const fetchCompanyServiceOfferedSuccess = (payload) => ({
  type: FETCH_COMPANY_SERVICE_OFFERED_SUCCESS,
  payload,
})
const fetchCompanyServiceOfferedFailed = (payload) => ({
  type: FETCH_COMPANY_SERVICE_OFFERED_FAILED,
  payload,
})

export const getCompanyServiceOffered = (payload) => async (dispatch) => {
  dispatch(fetchCompanyServiceOfferedRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.companyServiceOffered,
    method: method.get,
    data: payload,
    showToastMessage: false,
    toastMessage: 'Fetch Company service offered successfully!',
  })
  if (data) {
    dispatch(fetchCompanyServiceOfferedSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchCompanyServiceOfferedFailed(error.response.data))
  }
}

// Fetch company service-state-location
const fetchCompanyServiceStateLocationRequested = () => ({
  type: FETCH_COMPANY_SERVICE_STATE_LOCATION_REQUESTED,
})
const fetchCompanyServiceStateLocationSuccess = (payload) => ({
  type: FETCH_COMPANY_SERVICE_STATE_LOCATION_SUCCESS,
  payload,
})
const fetchCompanyServiceStateLocationFailed = (payload) => ({
  type: FETCH_COMPANY_SERVICE_STATE_LOCATION_FAILED,
  payload,
})

export const getCompanyServiceStateLocation = (payload) => async (dispatch) => {
  dispatch(fetchCompanyServiceStateLocationRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.companyServiceStateLocation,
    method: method.get,
    data: payload,
    showToastMessage: false,
    toastMessage: 'Fetch Company service state location successfully!',
  })
  if (data) {
    dispatch(fetchCompanyServiceStateLocationSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchCompanyServiceStateLocationFailed(error.response.data))
  }
}

// Fetch company service-us-territories
const fetchCompanyServiceUsTerritoriesRequested = () => ({
  type: FETCH_COMPANY_SERVICE_US_TERRITORIES_REQUESTED,
})
const fetchCompanyServiceUsTerritoriesSuccess = (payload) => ({
  type: FETCH_COMPANY_SERVICE_US_TERRITORIES_SUCCESS,
  payload,
})
const fetchCompanyServiceUsTerritoriesFailed = (payload) => ({
  type: FETCH_COMPANY_SERVICE_US_TERRITORIES_FAILED,
  payload,
})

export const getCompanyServiceUsTerritories = (payload) => async (dispatch) => {
  dispatch(fetchCompanyServiceUsTerritoriesRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.companyServiceStateUsTerritories,
    method: method.get,
    data: payload,
    showToastMessage: false,
    toastMessage: 'Fetch Company service territories successfully!',
  })
  if (data) {
    dispatch(fetchCompanyServiceUsTerritoriesSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchCompanyServiceUsTerritoriesFailed(error.response.data))
  }
}

// Fetch company key-personnel
const fetchCompanyKeyPersonnelRequested = () => ({
  type: FETCH_COMPANY_KEY_PERSONNEL_REQUESTED,
})
const fetchCompanyKeyPersonnelSuccess = (payload) => ({
  type: FETCH_COMPANY_KEY_PERSONNEL_SUCCESS,
  payload,
})
const fetchCompanyKeyPersonnelFailed = (payload) => ({
  type: FETCH_COMPANY_KEY_PERSONNEL_FAILED,
  payload,
})

export const getCompanyKeyPersonnel = (companyId) => async (dispatch) => {
  dispatch(fetchCompanyKeyPersonnelRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.companyKeyPersonnel}?companyId=${companyId}`,
    method: method.get,
  })

  if (data) {
    dispatch(fetchCompanyKeyPersonnelSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchCompanyKeyPersonnelFailed(error.response.data))
  }
}

// Add/Update company key personnel
const addUpdateKeyPersonnelRequested = () => ({
  type: UPDATE_KEY_PERSONNEL_REQUESTED,
})
const addUpdateKeyPersonnelSuccess = (payload) => ({
  type: UPDATE_KEY_PERSONNEL_SUCCESS,
  payload,
})
const addUpdateKeyPersonnelFailed = (payload) => ({
  type: UPDATE_KEY_PERSONNEL_FAILED,
  payload,
})

export const addUpdateKeyPersonnel =
  (payload, companyId, onSuccess) => async (dispatch, getState) => {
    dispatch(addUpdateKeyPersonnelRequested())
    const { keyPersonnel } = getState().company
    const { data, error } = await api({
      endPoint: `${apiEndPoints.companyKeyPersonnel}?companyId=${companyId}`,
      method: keys(keyPersonnel)?.length ? method.patch : method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })

    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(addUpdateKeyPersonnelSuccess(data))
      onSuccess()
      return
    }
    if (error) {
      dispatch(addUpdateKeyPersonnelFailed(error.response.data))
    }
  }
