import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  formFields,
  optionFields,
} from '../../../Description/constructionScheduleReport.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import {
  getConstructionScheduleReport,
  getConstructionScheduleReportReferenceValues,
  getMilestoneTracker,
  getPhaseTracker,
  importConstructionScheduleComments,
  saveConstructionScheduleReport,
} from '../../../Redux/actions/constructionScheduleReport'
import { getReportChoices } from '../../../Redux/actions/reportChoices'
import DKTButton from '../../../Shared/DKTButton'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTTooltip from '../../../Shared/DKTTooltip'
import DKTForm from '../../../Shared/DKTForm'
import { useStyles } from '../../../Styles/constructionScheduleReport.style'
import { importButtonTooltip, spinnerSize } from '../../../Utils/constant'
import {
  entries,
  equal,
  getDifferenceInDays,
  handleTextEditor,
  isArray,
  keys,
  ternary,
} from '../../../Utils/javascript'
import { renderFormFields } from '../../../Utils/renderFormFields'
import PhaseTracker from './PhaseTracker'
import MileStoneTracker from '../trackers/MilestoneTracker'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { CLEAR_CONSTRUCTION_SCHEDULE_REPORT } from '../../../Redux/constants/constructionScheduleReport'
import usePermission from '../../../Hooks/usePermission'
import DKTEditor from '../../../Shared/DKTEditor'

const mergedFormFields = entries(formFields).reduce((result, formField) => {
  const formFieldValue = formField[1]
  return {
    ...result,
    ...formFieldValue,
  }
}, {})

const ConstructionScheduleReport = () => {
  const [ownerContractorId, setOwnerContractorId] = useState(null)
  const [ownerContractorUniqueCode, setOwnerContractorUniqueCode] =
    useState(null)
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [isMilestoneTrackerOpen, setIsMilestoneTrackerOpen] = useState(false)
  const [isImportWarningOpen, setIsImportWarningOpen] = useState(false)
  const [isHandleChange, setHandleChange] = useState(false)
  const [ckEditorChange, setCkEditorChange] = useState(false)

  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const { slug: projectId, reportId } = useParams()
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)
  const {
    constructionScheduleReport,
    constructionScheduleReportReference,
    isImportingComments,
    isSavingConstructionScheduleReport,
    isFetchingConstructionScheduleReportReferenceValues,
    isFetchingConstructionScheduleReport,
    isFetchingPhaseTracker,
    isFetchingMilestoneTracker,
  } = useSelector(
    ({ constructionScheduleReport }) => constructionScheduleReport,
  )
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    resetForm,
    setIsDirty,
    setDummyData,
    dummyData,
  } = useForm(mergedFormFields, CLEAR_CONSTRUCTION_SCHEDULE_REPORT)
  const { hasOnlyViewPermission } = usePermission()
  const disabledAction =
    isFetchingConstructionScheduleReportReferenceValues === 'FAILED' ||
    isFetchingConstructionScheduleReport === 'FAILED' ||
    isFetchingConstructionScheduleReportReferenceValues === true ||
    isFetchingConstructionScheduleReport === true

  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionFields?.join(','),
        projectId,
      }),
    )
  }, [dispatch, projectId])

  useEffect(() => {
    if (reportId && ownerContractorId)
      dispatch(getConstructionScheduleReport(reportId, ownerContractorId))

    if (projectId && reportId && ownerContractorId) {
      dispatch(
        getConstructionScheduleReportReferenceValues({
          projectId,
          reportId,
          ocId: ownerContractorId,
        }),
      )
      dispatch(
        getPhaseTracker({ reportId, ocId: ownerContractorId, projectId }),
      )
      dispatch(
        getMilestoneTracker({ reportId, ocId: ownerContractorId, projectId }),
      )
    }
  }, [dispatch, ownerContractorId])

  useEffect(() => {
    if (choices) {
      const dynamicDropdown = (ary) => {
        const res = ary?.map((element) => ({
          label: element?.uniqueCode || element?.projectName,
          value: `${element?.id}`,
        }))
        return res
      }
      const ownerContractor = dynamicDropdown(choices?.ownerContractor)
      setDynamicOptions({
        ...choices,
        ownerContractor,
      })
    }
  }, [choices])

  useEffect(() => {
    let activeContractor = null
    if (isArray(choices?.ownerContractor) && choices?.ownerContractor?.length) {
      activeContractor = choices?.ownerContractor[0]
    }
    setOwnerContractorId(activeContractor?.id)
    setOwnerContractorUniqueCode(activeContractor?.uniqueCode)
  }, [choices?.ownerContractor[0]?.id])

  useEffect(() => {
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    setFieldValue('ownerContractorDescription', activeContractorDescription)
    return () => resetForm()
  }, [ownerContractorId])

  const calculateBaCalendarDays = () => {
    setFieldValue(
      'currentContractCompletionDurationCalendarDays',
      values.currentContractCompletion && values.actualStart
        ? getDifferenceInDays(
            values.currentContractCompletion,
            values.actualStart,
          )
        : 0,
      'ConstructionScheduleReport',
    )
  }
  const calculateCaCalendarDays = () => {
    setFieldValue(
      'currentScheduleCompletionDurationCalendarDays',
      values.currentScheduleCompletion && values.currentContractCompletion
        ? getDifferenceInDays(
            values.currentScheduleCompletion,
            values.currentContractCompletion,
          )
        : 0,
      'ConstructionScheduleReport',
    )
  }
  const calculateDcCalendarDays = () => {
    setFieldValue(
      'varianceCalendarDays',
      values.consultantsEstimatedCompletion && values.currentContractCompletion
        ? getDifferenceInDays(
            values.consultantsEstimatedCompletion,
            values.currentContractCompletion,
            'ConstructionScheduleReport',
          )
        : 0,
    )
  }
  // Actual schedule (B-A and C-A)
  useEffect(() => {
    calculateBaCalendarDays()
  }, [values.actualStart /* A */])
  // Actual schedule (B-A)
  useEffect(() => {
    calculateBaCalendarDays()
  }, [values.currentContractCompletion /* B */])
  // Actual schedule (C-A)
  useEffect(() => {
    calculateCaCalendarDays()
  }, [
    values.currentContractCompletion,
    values?.currentScheduleCompletion /* C */,
  ])
  // Actual schedule (D-C)
  useEffect(() => {
    calculateDcCalendarDays()
  }, [
    values.consultantsEstimatedCompletion,
    values.currentContractCompletion /* D */,
  ])

  /* Original schedule calculation  */
  const calculateOriginalScheduleBaCalendarDays = () => {
    setFieldValue(
      'contractCompletionDurationCalendarDays',
      values.contractCompletion && values.constructionStart
        ? getDifferenceInDays(
            values.contractCompletion,
            values.constructionStart,
          )
        : 0,
      'ConstructionScheduleReport',
    )
  }
  const calculateOriginalScheduleCaCalendarDays = () => {
    setFieldValue(
      'scheduleCompletionDurationCalendarDays',
      values.scheduleCompletion && values.constructionStart
        ? getDifferenceInDays(
            values.scheduleCompletion,
            values.constructionStart,
          )
        : 0,
      'ConstructionScheduleReport',
    )
  }
  const calculateOriginalScheduleDaCalendarDays = () => {
    setFieldValue(
      'lendersUwCompletionDurationCalendarDays',
      values.lendersUwCompletion && values.constructionStart
        ? getDifferenceInDays(
            values.lendersUwCompletion,
            values.constructionStart,
          )
        : 0,
      'ConstructionScheduleReport',
    )
  }
  // Original schedule (B-A, C-A, and D-A)
  useEffect(() => {
    calculateOriginalScheduleBaCalendarDays()
    calculateOriginalScheduleCaCalendarDays()
    calculateOriginalScheduleDaCalendarDays()
  }, [values.constructionStart /* A */])
  // Actual schedule (B-A)
  useEffect(() => {
    calculateOriginalScheduleBaCalendarDays()
  }, [values.contractCompletion /* B */])
  // Actual schedule (C-A)
  useEffect(() => {
    calculateOriginalScheduleCaCalendarDays()
  }, [values.scheduleCompletion /* C */])
  // Actual schedule (D-C)
  useEffect(() => {
    calculateOriginalScheduleDaCalendarDays()
  }, [values.lendersUwCompletion /* D */])

  // Prefill construction schedule form fields
  useEffect(() => {
    if (!constructionScheduleReport) {
      setValues((prevValues) => ({
        ...prevValues,
        ...constructionScheduleReportReference,
      }))
      setDummyData((prevValues) => ({
        ...prevValues,
        ...constructionScheduleReportReference,
      }))
      return
    }
    setValues((prevValues) => ({
      ...prevValues,
      ...constructionScheduleReportReference,
      ...constructionScheduleReport,
    }))
    setDummyData((prevValues) => ({
      ...prevValues,
      ...constructionScheduleReportReference,
      ...constructionScheduleReport,
    }))
  }, [constructionScheduleReport, constructionScheduleReportReference])

  const showMilestoneTracker = () => setIsMilestoneTrackerOpen(true)
  const hideMilestoneTracker = () => setIsMilestoneTrackerOpen(false)

  const handleSave = () => {
    const isFormValid = handleSubmit()
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
      return
    }
    setIsDirty(false)
    setHandleChange(false)
    const payload = keys(values).reduce((result, key) => {
      result[key] = values[key] === '' ? null : values[key]
      return result
    }, {})
    dispatch(
      saveConstructionScheduleReport({
        payload,
        ocId: ownerContractorId,
        projectId,
        reportId,
        ownerContractorUniqueCode,
      }),
    )
  }

  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack
          direction="row"
          gap={2}
          // className={classes.rightButton}
        >
          <DKTButtonSelect
            options={actionList}
            disabled={hasOnlyViewPermission || disabledAction || true}
          />{' '}
          <DKTButton
            className={classes.rightButton}
            onClick={handleSave}
            disabled={
              hasOnlyViewPermission ||
              isSavingConstructionScheduleReport ||
              disabledAction
            }
          >
            {ternary(isSavingConstructionScheduleReport, 'Saving...', 'Save')}
          </DKTButton>
        </Stack>
      </Box>
    </>
  )
  const actionList = [{ label: 'Delete', onClick: () => null }]

  const customFormControlFields = ({ name, formControl }) => ({
    options: ternary(
      formControl.isDynamicOptions,
      dynamicOptions,
      formControl.options,
    ),
    disabled:
      hasOnlyViewPermission ||
      (equal(name, 'remainingBudgetSummaryCoHcc') &&
        equal(values.useFromBudgetSummaryCo, 'Yes')) ||
      (equal(name, 'remainingBudgetSummaryCoAndPcoHcc') &&
        equal(values.useFromBudgetSummaryCoAndPco, 'Yes')),
  })

  const handleFieldChange = (...rest) => {
    handleChange(...rest)
    setHandleChange(true)
  }

  const renderFormFieldsWithOptions = useCallback(
    (formFields) =>
      renderFormFields({
        values,
        errors,
        handleChange: handleFieldChange,
        formFields,
        customFormControlFields,
      }),
    [values, errors, handleFieldChange, formFields, customFormControlFields],
  )

  /* IMPORT FROM LAST REPORT */
  const showImportWarning = () => setIsImportWarningOpen(true)
  const hideImportWarning = () => setIsImportWarningOpen(false)
  const handleImport = useCallback(
    (name) => {
      if (values.comments) {
        showImportWarning()
        return
      }
      dispatch(
        importConstructionScheduleComments({
          projectId,
          ocId: ownerContractorId,
          onSuccess: (value) => setFieldValue(name, value),
          reportId,
        }),
      )
    },
    [projectId, ownerContractorId, dispatch, values],
  )
  const handleImportOverWrite = () => {
    const onSuccess = (value) => {
      setFieldValue('comments', value)
      hideImportWarning()
    }
    dispatch(
      importConstructionScheduleComments({
        projectId,
        ocId: ownerContractorId,
        onSuccess,
        reportId,
      }),
    )
    setHandleChange(true)
  }

  const importWarningActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={hideImportWarning}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleImportOverWrite}
        disabled={isImportingComments}
      >
        {ternary(
          isImportingComments,
          <DKTCircularProgress
            size={spinnerSize?.sm}
            {...ternary(
              isImportingComments,
              { color: theme.palette.gray.dark },
              {},
            )}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  useEffect(() => {
    ckEditorChangeFN('comments')
  }, [values?.comments])

  const ckEditorChangeFN = useCallback(
    (key) => {
      const valuesKey = keys(values)
      if (
        valuesKey?.includes(key) &&
        !equal(values?.[key], '') &&
        !equal(values?.[key], null)
      ) {
        if (values?.[key] !== dummyData?.[key] || isHandleChange) {
          setCkEditorChange(true)
        } else {
          setCkEditorChange(false)
        }
      }
    },
    [values, values?.comments],
  )
  useEffect(() => {
    if (ckEditorChange) {
      setHandleChange(true)
    }
  }, [ckEditorChange])

  const handleBlurEventOnCkEditor = (key, editor) => {
    const data = editor.getData()
    const previousValue = dummyData?.[key] || ''
    if (data !== previousValue) {
      setHandleChange(true)
    }
  }

  const actualScheduleStart = renderFormFieldsWithOptions(
    formFields.actualScheduleStart,
  )
  const actualSchedule = renderFormFieldsWithOptions(formFields.actualSchedule)
  const description = renderFormFieldsWithOptions(formFields.description)
  const originalScheduleStart = renderFormFieldsWithOptions(
    formFields.originalScheduleStart,
  )
  const originalSchedule = renderFormFieldsWithOptions(
    formFields.originalSchedule,
  )

  return (
    <div>
      <InnerLayout
        contentTitle="Construction Schedule"
        actions={actions}
        maxWidth="md"
        isShowMenu
      >
        {isFetchingConstructionScheduleReportReferenceValues === true ||
        isFetchingConstructionScheduleReport === true ||
        isFetchingPhaseTracker === true ||
        isFetchingMilestoneTracker === true ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <DKTCircularProgress />
          </Stack>
        ) : isFetchingConstructionScheduleReportReferenceValues === 'FAILED' ||
          isFetchingConstructionScheduleReport === 'FAILED' ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching construction schedule
              data. Please try contacting the admin or refreshing this page.
            </Typography>
          </Stack>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} lg={10}>
                <Grid container columnSpacing={10} rowSpacing={3}>
                  <Grid item lg={6} xs={6}>
                    <DKTForm autoComplete="off">{description}</DKTForm>
                  </Grid>
                  <Grid item lg={6} xs={6}></Grid>
                  <Grid item lg={6} xs={12}>
                    <FormSectionDivider sectionTitle="Original Schedule" />
                    <DKTForm autoComplete="off">
                      <Grid container spacing={2}>
                        {originalScheduleStart}
                        <Grid item xs={6} />
                        {originalSchedule}
                      </Grid>
                    </DKTForm>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <FormSectionDivider
                      sectionTitle="Actual Schedule"
                      action={
                        <Typography
                          variant="subtitle2"
                          component="span"
                          color="primary"
                          className={classes.addressDialog}
                          onClick={showMilestoneTracker}
                        >
                          Milestone Tracker
                        </Typography>
                      }
                    />
                    <DKTForm autoComplete="off">
                      <Grid container spacing={2}>
                        {actualScheduleStart}
                        <Grid item xs={6} />
                        {actualSchedule}
                      </Grid>
                    </DKTForm>
                  </Grid>
                  {/* Comments */}
                  <Grid item xs={12} lg={6} mt={1.5}></Grid>
                  {isFetchingConstructionScheduleReportReferenceValues ===
                    'FAILED' ||
                  isFetchingConstructionScheduleReport === 'FAILED' ? null : (
                    <Grid item xs={12} lg={6} mt={1.5}>
                      <Stack
                        direction="row"
                        alignItems="end"
                        justifyContent="space-between"
                        mb={1}
                      >
                        <Typography
                          variant="body2"
                          color="gray.extraDark"
                          sx={{ fontWeight: 'medium' }}
                        >
                          Comments
                        </Typography>

                        <DKTTooltip title={importButtonTooltip}>
                          <Box>
                            <DKTButton
                              variant="outlined"
                              onClick={() => handleImport('comments')}
                              sx={{
                                borderColor: '#ACACAC',
                                width: '91px',
                                height: '32px',
                                color: '#2D2D2D',
                                '&:hover': {
                                  borderColor: '#000',
                                },
                              }}
                              disabled={
                                hasOnlyViewPermission || isImportingComments
                              }
                            >
                              {ternary(
                                isImportingComments,
                                <DKTCircularProgress
                                  color={theme.palette.gray.dark}
                                />,
                                'Import',
                              )}
                            </DKTButton>
                          </Box>
                        </DKTTooltip>
                      </Stack>
                      <DKTEditor
                        onChange={(event, editor) => {
                          handleTextEditor(editor, 'comments', setFieldValue)
                        }}
                        onBlur={(event, editor) => {
                          handleBlurEventOnCkEditor('comments', editor)
                        }}
                        value={values?.comments || ''}
                        disabled={hasOnlyViewPermission}
                        className={classes.projectDesc}
                      />
                    </Grid>
                  )}
                  <PhaseTracker
                    ocId={ownerContractorId}
                    hasOnlyViewPermission={hasOnlyViewPermission}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Milestone tracker */}
            <MileStoneTracker
              open={isMilestoneTrackerOpen}
              onClose={hideMilestoneTracker}
              ocId={ownerContractorId}
            />
            {/* Import content over-write warning */}
            <DKTDialog
              open={isImportWarningOpen}
              handleClose={hideImportWarning}
              title="&nbsp;"
              actions={importWarningActions}
              maxWidth="xs"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.deleteNotificationContent}>
                    <Typography variant="h5">
                      Existing data will be overwritten.
                    </Typography>
                    <Typography variant="body1">Continue?</Typography>
                  </Box>
                </Grid>
              </Grid>
            </DKTDialog>
            {/* show modal when tries to navigate without save data */}
            {!hasOnlyViewPermission && (
              <DKTReactRouterPrompt
                isDirty={isHandleChange}
                onSave={handleSave}
              />
            )}
          </>
        )}
      </InnerLayout>
    </div>
  )
}

const FormSectionDivider = ({ sectionTitle, action, ...rest }) => (
  <Box {...rest}>
    <Stack direction="row" justifyContent="space-between">
      <Typography
        variant="body2"
        color="gray.extraDark"
        sx={{ fontWeight: 'medium' }}
      >
        {sectionTitle}
      </Typography>

      {action}
    </Stack>
    <Divider sx={{ mb: 3 }} />
  </Box>
)

export default ConstructionScheduleReport
