export const formFields = {
  issuesLogFilter: {
    issueLogFilter: {
      type: 'select',
      label: 'Issues Filter',
      placeholder: 'Select',
      displayEmpty: true,
      xs: 6,
      lg: 5,
      isDynamicOptions: true,
    },
  },
  addIssues: {
    title: {
      type: 'text',
      label: 'Title',
      placeholder: 'Construction Issue',
      isRequired: true,
      lg: 12,
    },
    issueDate: {
      type: 'date',
      label: 'Date ',
      isRequired: true,
      lg: 12,
    },
    resolutionPeriod: {
      type: 'select',
      label: 'Resolution Period',
      placeholder: 'Select',
      displayEmpty: true,
      isDynamicOptions: true,
      lg: 12,
    },
    status: {
      type: 'select',
      label: 'Status',
      placeholder: 'Select',
      displayEmpty: true,
      isDynamicOptions: true,
      isRequired: true,
      lg: 12,
    },
  },
  rightSide: {
    description: {
      type: 'textarea',
      label: 'Description',
      rows: 14,
      isRequired: true,
      placeholder: 'Placeholder Text',
      formTextAreaStyle: {
        marginTop: '14px',
      },
    },
  },
}

export const options = {
  responsive: 'standard',
  filter: false,
  search: true,
  print: false,
  download: false,
  viewColumns: false,
  selectableRows: 'none',
  pagination: false,
}

export const columns = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'number',
    label: 'No.',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'issueDate',
    label: 'Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'resolutionPeriodFullform',
    label: 'Resolution Period',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'statusFullform',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'title',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
]

export const optionsFields = [
  'issueStatus',
  'resolutionPeriod',
  'issueLogFilter',
]
