import { equal, ternary } from '../../Utils/javascript'
import {
  DELETE_PERMITS_ENTITLEMENTS_FAILED,
  DELETE_PERMITS_ENTITLEMENTS_REQUESTED,
  DELETE_PERMITS_ENTITLEMENTS_SUCCESS,
  DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
  DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  FETCH_PERMITS_ENTITLEMENTS_FAILED,
  FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_FAILED,
  FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_REQUESTED,
  FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_SUCCESS,
  FETCH_PERMITS_ENTITLEMENTS_REQUESTED,
  FETCH_PERMITS_ENTITLEMENTS_SUCCESS,
  FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
  FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  IMPORT_PERMITS_ENTITLEMENTS_FAILED,
  IMPORT_PERMITS_ENTITLEMENTS_REQUESTED,
  IMPORT_PERMITS_ENTITLEMENTS_SUCCESS,
  SAVE_PERMITS_ENTITLEMENTS_FAILED,
  SAVE_PERMITS_ENTITLEMENTS_REQUESTED,
  SAVE_PERMITS_ENTITLEMENTS_SUCCESS,
  SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
  SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
  FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  CLEAR_PERMITS_ENTITLEMENTS,
  FETCH_PERMITS_TRACKER_REQUESTED,
  FETCH_PERMITS_TRACKER_SUCCESS,
  FETCH_PERMITS_TRACKER_FAILED,
  SAVE_PERMITS_TRACKER_REQUESTED,
  SAVE_PERMITS_TRACKER_SUCCESS,
  SAVE_PERMITS_TRACKER_FAILED,
  DELETE_PERMIT_TRACKER_REQUESTED,
  DELETE_PERMIT_TRACKER_SUCCESS,
  DELETE_PERMIT_TRACKER_FAILED,
} from '../constants/permitsEntitlements'

const initialState = {
  isSavingPermitsEntitlements: false,
  isSavingCertificateOfOccupancy: false,
  isFetchingPermitsEntitlements: true,
  isFetchingLastReportPermitsEntitlements: true,
  permitsEntitlements: {},
  certificateOfOccupancyTracker: [],
  lastReportPermitsEntitlements: null,
  lastReportPermitsEntitlementsError: null,
  permitsEntitlementsError: null,
  certificateOfOccupancyTrackerError: null,
  isDeletingPermitsEntitlements: false,
  isDeletingCertificateOfOccupancy: false,
  // Import from last report
  isImporting: {
    permitAndViolationComments: false,
    certificateOfOccupancyComments: false,
  },
  importedPermitAndViolationComments: null,
  importedCertificateOfOccupancyComments: null,
  isFetchingCertificateOfOccupancyTracker: true,
  // permit tracker
  isFetchingPermitTracker: false,
  permitTrackerData: [],
  isSavePermitTracker: false,
  isDeletePermitTracker: false,
}

export const permitsEntitlements = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch permits entitlements reference values
    case FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_REQUESTED:
      return {
        ...state,
        isFetchingLastReportPermitsEntitlements: true,
      }
    case FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        isFetchingLastReportPermitsEntitlements: false,
        lastReportPermitsEntitlements: action?.payload,
        lastReportPermitsEntitlementsError: null,
      }
    case FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_FAILED:
      return {
        ...state,
        isFetchingLastReportPermitsEntitlements: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        lastReportPermitsEntitlementsError: action.payload?.data,
      }
    // Fetch permits entitlements
    case FETCH_PERMITS_ENTITLEMENTS_REQUESTED:
      return {
        ...state,
        isFetchingPermitsEntitlements: true,
      }
    case FETCH_PERMITS_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        isFetchingPermitsEntitlements: false,
        permitsEntitlements: action?.payload,
        permitsEntitlementsError: null,
      }
    case FETCH_PERMITS_ENTITLEMENTS_FAILED:
      return {
        ...state,
        isFetchingPermitsEntitlements: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        permitsEntitlementsError: action.payload?.data,
      }
    // Create permits entitlements
    case SAVE_PERMITS_ENTITLEMENTS_REQUESTED:
      return {
        ...state,
        isSavingPermitsEntitlements: true,
        permitsEntitlementsError: null,
      }
    case SAVE_PERMITS_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        isSavingPermitsEntitlements: false,
        permitsEntitlements: action.payload,
        permitsEntitlementsError: null,
      }
    case SAVE_PERMITS_ENTITLEMENTS_FAILED:
      return {
        ...state,
        isSavingPermitsEntitlements: false,
        permitsEntitlementsError: action.payload,
      }
    // Delete permits entitlements
    case DELETE_PERMITS_ENTITLEMENTS_REQUESTED:
      return {
        ...state,
        isDeletingPermitsEntitlements: true,
      }
    case DELETE_PERMITS_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        permitsEntitlements: action?.payload,
        isDeletingPermitsEntitlement: false,
      }
    case DELETE_PERMITS_ENTITLEMENTS_FAILED:
      return {
        ...state,
        isDeletingPermitsEntitlements: false,
      }

    // Import from last report
    case IMPORT_PERMITS_ENTITLEMENTS_REQUESTED:
      return {
        ...state,
        isImporting: {
          ...state.isImporting,
          [action.payload]: true,
        },
      }
    case IMPORT_PERMITS_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        [`imported${action.payload.pascalCaseQuery}`]: action.payload.data,
        isImporting: {
          ...state.isImporting,
          [action.payload.query]: false,
        },
      }
    case IMPORT_PERMITS_ENTITLEMENTS_FAILED:
      return {
        ...state,
        [`import${action.payload.pascalCaseQuery}Error`]: action.payload.data,
        isImporting: {
          ...state.isImporting,
          [action.payload.query]: false,
        },
      }

    /* Certificate of occupancy tracker */
    // Fetch certificate of occupancy tracker
    case FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED:
      return {
        ...state,
        isFetchingCertificateOfOccupancyTracker: true,
      }
    case FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS:
      return {
        ...state,
        isFetchingCertificateOfOccupancyTracker: false,
        certificateOfOccupancyTracker: action?.payload,
        certificateOfOccupancyTrackerError: null,
      }
    case FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED:
      return {
        ...state,
        isFetchingCertificateOfOccupancyTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        certificateOfOccupancyTrackerError: action.payload?.data,
      }
    // Fetch Last report certificate of occupancy tracker
    case FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED:
      return {
        ...state,
        isFetchingCertificateOfOccupancyTracker: true,
      }
    case FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS:
      return {
        ...state,
        isFetchingCertificateOfOccupancyTracker: false,
        certificateOfOccupancyTracker: action.payload,
        certificateOfOccupancyTrackerError: null,
      }
    case FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED:
      return {
        ...state,
        isFetchingCertificateOfOccupancyTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        certificateOfOccupancyTrackerError: action.payload?.data,
      }
    // Create certificate of occupancy tracker
    case SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED:
      return {
        ...state,
        isSavingCertificateOfOccupancy: true,
        certificateOfOccupancyTrackerError: null,
      }
    case SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS:
      return {
        ...state,
        isSavingCertificateOfOccupancy: false,
        certificateOfOccupancyTracker: action.payload,
        certificateOfOccupancyTrackerError: null,
      }
    case SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED:
      return {
        ...state,
        isSavingCertificateOfOccupancy: false,
        certificateOfOccupancyTrackerError: action.payload,
      }
    // Delete certificate of occupancy tracker
    case DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED:
      return {
        ...state,
        isDeletingCertificateOfOccupancy: true,
      }
    case DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS:
      return {
        ...state,
        certificateOfOccupancyTracker: action?.payload,
        isDeletingCertificateOfOccupancy: false,
      }
    case DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED:
      return {
        ...state,
        isDeletingCertificateOfOccupancy: false,
      }

    // Permit tracker
    case FETCH_PERMITS_TRACKER_REQUESTED:
      return {
        ...state,
        isFetchingPermitTracker: true,
      }
    case FETCH_PERMITS_TRACKER_SUCCESS:
      return {
        ...state,
        permitTrackerData: action?.payload,
        isFetchingPermitTracker: false,
      }
    case FETCH_PERMITS_TRACKER_FAILED:
      return {
        ...state,
        isFetchingPermitTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }
    case SAVE_PERMITS_TRACKER_REQUESTED:
      return {
        ...state,
        isSavePermitTracker: true,
      }
    case SAVE_PERMITS_TRACKER_SUCCESS:
      return {
        ...state,
        permitTrackerData: action.payload,
        isSavePermitTracker: false,
      }
    case SAVE_PERMITS_TRACKER_FAILED:
      return {
        ...state,
        isSavePermitTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }
    case DELETE_PERMIT_TRACKER_REQUESTED:
      return {
        ...state,
        isDeletePermitTracker: true,
      }
    case DELETE_PERMIT_TRACKER_SUCCESS:
      return {
        ...state,
        permitTrackerData: action.payload,
        isDeletePermitTracker: false,
      }
    case DELETE_PERMIT_TRACKER_FAILED:
      return {
        ...state,
        isDeletePermitTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }

    case CLEAR_PERMITS_ENTITLEMENTS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
