import { lt } from '../Utils/javascript'
import { isFullYear, zipCodeValidation } from '../Utils/regex'

export const optionsField = {
  select: 'options',
  checkbox: 'fields',
}

export const formSections = {
  siteAddress: {
    address: {
      type: 'text',
      label: 'Address',
    },
    city: {
      type: 'text',
      label: 'City',
    },
    county: {
      type: 'text',
      label: 'County',
    },
    state: {
      type: 'text',
      label: 'State',
      xs: 8,
    },
    zipCode: {
      type: 'text',
      label: 'Zip Code',
      xs: 4,
      validate: [zipCodeValidation],
    },
  },
  developmentInformation: {
    constructionType: {
      type: 'select',
      label: 'Construction Type',
      placeholder: 'Select',
      displayEmpty: true,
    },
    rehabType: {
      type: 'select',
      label: 'Rehab Type',
      placeholder: 'Select',
      displayEmpty: true,
    },
    originalYearBuilt: {
      type: 'number',
      label: 'Original Year Built',
      validate: [isFullYear],
    },
  },
  siteInformation: {
    zoningDesignation: {
      type: 'text',
      label: 'Zoning Designation',
    },
    parcelType: {
      type: 'select',
      label: 'Parcel Type',
      placeholder: 'Select',
      displayEmpty: true,
    },
    siteAcres: {
      type: 'text',
      label: 'Site Acres',
      decimalPlaces: 4,
    },
    totalParkingSpaces: {
      type: 'number',
      label: 'Total Parking Spaces',
    },
    accessibleParkingSpaces: {
      type: 'number',
      label: 'Accessible Parking Spaces',
    },
    parkingRatio: {
      type: 'text',
      label: 'Parking Spaces per Unit',
      placeholder: 'Total Parking Spaces / Total Number of Units',
      disabled: true,
      decimalPlaces: 2,
      isLimitLess: true,
      validate: [
        (value) => lt(value, 999999999999999),
        'You have exceeded the limit, the value should be less than 999,999,999,999,999.',
      ],
    },
  },
  amenities: {
    communalAmenities: {
      type: 'checkbox',
      labelledCheckbox: true,
      label: 'Communal Amenities',
    },
    unitAmenities: {
      type: 'checkbox',
      labelledCheckbox: true,
      label: 'Unit Amenities',
    },
  },
  buildingStyle: {
    buildingStyle: {
      type: 'checkbox',
      labelledCheckbox: true,
      label: 'Building Style',
      showLabel: false,
    },
    numberOfStories: {
      type: 'number',
      label: 'Number of Stories',
      xs: 6,
    },
    storiesAboveGrade: {
      type: 'number',
      label: 'Stories Above Grade',
      xs: 6,
    },
    numberOfElevators: {
      type: 'number',
      label: 'Number of Elevators',
      xs: 6,
    },
    storiesBelowGrade: {
      type: 'number',
      label: 'Stories Below Grade',
      xs: 6,
    },
  },
  buildingAndUnitInfo: {
    residentialBuildings: {
      type: 'number',
      label: 'Residential Buildings',
      xs: 6,
    },
    residentialGsf: {
      type: 'text',
      label: 'Residential Gross Square Feet',
      xs: 6,
      formatDigits: true,
      decimalPlaces: 0,
    },
    clubhouseBuildings: {
      type: 'number',
      label: 'Clubhouse Buildings',
      xs: 6,
    },
    clubhouseGsf: {
      type: 'text',
      label: 'Clubhouse Gross Square Feet',
      xs: 6,
      formatDigits: true,
      decimalPlaces: 0,
    },
    otherBuildings: {
      type: 'number',
      label: 'Other Buildings',
      xs: 6,
    },
    otherGsf: {
      type: 'text',
      label: 'Other Gross Square Feet',
      xs: 6,
      formatDigits: true,
      decimalPlaces: 0,
    },
    totalBuildings: {
      type: 'number',
      label: 'Total Number of Buildings',
      disabled: true,
      xs: 6,
    },
    totalGsf: {
      type: 'text',
      label: 'Total Gross Square Feet',
      disabled: true,
      xs: 6,
      formatDigits: true,
      decimalPlaces: 0,
      isLimitLess: true,
      validate: [
        (value) => lt(value, 999999999999999),
        'You have exceeded the limit, the value should be less than 999,999,999,999,999.',
      ],
    },
  },
  unitInformation: {
    totalNumberOfUnits: {
      type: 'number',
      label: 'Total Number of Units',
      xs: 6,
    },
    accessibleUnitsHc: {
      type: 'number',
      label: 'Accessible Units (HC)',
      xs: 6,
    },
    audioVisualUnitsAv: {
      type: 'number',
      label: 'Audio Visual Units (AV)',
      xs: 6,
    },
  },
  utilityInformation: {
    heatFuelSource: {
      type: 'select',
      label: 'Heat',
      inlineForm: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
    hotWaterFuelSource: {
      type: 'select',
      label: 'Domestic Hot Water',
      inlineForm: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
    cookingFuelSource: {
      type: 'select',
      label: 'Cooking',
      inlineForm: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
    airConditioningFuelSource: {
      type: 'select',
      label: 'Air Conditioning (AC)',
      inlineForm: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
    dryerFuelSource: {
      type: 'select',
      label: 'Dryer (In-Unit)',
      inlineForm: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
  },
  otherUtilities: {
    photovoltaic: {
      type: 'select',
      label: 'Photovoltaic Panels',
      options: [],
      inlineForm: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
    solarThermal: {
      type: 'select',
      label: 'Solar Thermal',
      options: [],
      inlineForm: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
    geothermal: {
      type: 'select',
      label: 'Geothermal',
      options: [],
      inlineForm: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
  },
  securityFeatures: {
    securityFeatures: {
      type: 'checkbox',
      labelledCheckbox: true,
      label: 'Security Features',
      showLabel: false,
    },
  },
  greenBuildingCertification: {
    greenBuildingProgram: {
      type: 'select',
      label: 'Green Building Program',
      options: [],
      xs: 7,
      placeholder: 'Select',
      displayEmpty: true,
    },
    certificationLevel: {
      type: 'select',
      label: 'Certification Level',
      options: [],
      xs: 5,
      placeholder: 'Select',
      displayEmpty: true,
    },
  },
  comments: {
    comments: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 6,
    },
  },
}

export const siteAndBuildingChoices = [
  'constructionType',
  'rehabType',
  'parcelType',
  'communalAmenities',
  'unitAmenities',
  'buildingStyle',
  'securityFeatures',
  'greenBuildingProgram',
  'certificationLevel',
  'heatFuelSource',
  'hotWaterFuelSource',
  'cookingFuelSource',
  'airConditioningFuelSource',
  'dryerFuelSource',
  'dryerFuelSource',
  'certificationLevel',
  'greenBuildingProgram',
  'photovoltaic',
  'solarThermal',
  'geothermal',
]

export const columns = [
  {
    name: 'buildingNameOrNumber',
    label: 'Bldg. Name/No.',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'numberOfStories',
    label: 'No. of Stories',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'netSf',
    label: 'Net SF',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'grossSf',
    label: 'Gross SF',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'br0',
    label: '0BR',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'br1',
    label: '1BR',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'br2',
    label: '2BR',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'br3',
    label: '3BR',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'br4',
    label: '4BR',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'br5',
    label: '5BR',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'totalUnits',
    label: 'Total # Units',
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: 'addRow',
    label: ' ',
    options: {
      filter: false,
      sort: false,
    },
  },
]

export const initialBuildingAndUnitInfo = {
  buildingNameOrNumber: '',
  numberOfStories: '',
  netSf: 0,
  grossSf: 0,
  br0: 0,
  br1: 0,
  br2: 0,
  br3: 0,
  br4: 0,
  br5: 0,
  totalUnits: 0,
}

export const options = {
  download: false,
  search: false,
  print: false,
  filter: false,
  viewColumns: false,
  responsive: 'standard',
  selectableRows: 'none',
  rowsPerPageOptions: [5, 10, 20],
}

export const formFields = {
  description: {
    type: 'text',
    label: 'Description',
  },
  address: {
    type: 'text',
    label: 'Address',
  },
  city: {
    type: 'text',
    label: 'City',
  },
  county: {
    type: 'text',
    label: 'County',
  },
  state: {
    type: 'text',
    label: 'State',
    xs: 8,
  },
  zipCode: {
    type: 'text',
    label: 'Zip Code',
    xs: 4,
  },
  constructionType: {
    type: 'select',
    label: 'Construction Type',
    options: [
      {
        label: 'New Construction',
        value: 'NC',
      },
      {
        label: 'Rehab',
        value: 'RE',
      },
      {
        label: 'Historic Rehab',
        value: 'HR',
      },
      {
        label: 'New + Rehab',
        value: 'NR',
      },
    ],
    placeholder: 'Select',
    displayEmpty: true,
  },
  rehabType: {
    type: 'select',
    label: 'Rehab Type',
    options: [
      {
        label: 'Moderate Rehab',
        value: 'MR',
      },
      {
        label: 'Substantial Rehab',
        value: 'SR',
      },
      {
        label: 'Gut Rehab',
        value: 'GR',
      },
    ],
    placeholder: 'Select',
    displayEmpty: true,
  },
  originalYearBuilt: {
    type: 'number',
    label: 'Original Year Built',
    validate: [isFullYear],
  },
  zoningDesignation: {
    type: 'text',
    label: 'Zoning Designation',
  },
  parcelType: {
    type: 'select',
    label: 'Parcel Type',
    options: [
      {
        label: 'Scattered',
        value: 'SC',
      },
      {
        label: 'Contiguous',
        value: 'CO',
      },
    ],
    placeholder: 'Select',
    displayEmpty: true,
  },
  siteAcres: {
    type: 'text', // 4 decimal percision
    label: 'Site Acres',
  },
  totalParkingSpaces: {
    type: 'number',
    label: 'Total Parking Spaces',
  },
  accessibleParkingSpaces: {
    type: 'number',
    label: 'Accessible Parking Spaces',
  },
  parkingRatio: {
    type: 'text',
    label: 'Parking Ratio',
    placeholder: 'Total Parking Spaces / Total Number of Units',
    disabled: true,
  },
  communalAmenities: {
    type: 'checkbox',
    labelledCheckbox: true,
    label: 'Communal Amenities',
    fields: [
      {
        label: 'Community Room',
        value: 'CR',
      },
      {
        label: 'Fitness',
        value: 'FI',
      },
      {
        label: 'Kitchen',
        value: 'KI',
      },
      {
        label: 'Computer',
        value: 'COM',
      },
      {
        label: 'Leasing Office',
        value: 'LO',
      },
      {
        label: 'Meeting',
        value: 'ME',
      },
      {
        label: 'Center Laundry',
        value: 'CL',
      },
      {
        label: 'Garden',
        value: 'GR',
      },
      {
        label: 'Swimming Pool',
        value: 'SP',
      },
      {
        label: 'Picnic/BBQ',
        value: 'PB',
      },
      {
        label: 'Library',
        value: 'LIB',
      },
      {
        label: 'Social Service',
        value: 'SS',
      },
    ],
  },
  unitAmenities: {
    type: 'checkbox',
    labelledCheckbox: true,
    label: 'Unit Amenities',
    fields: [
      {
        label: 'Balconies',
        value: 'BA',
      },
      {
        label: 'Ceiling Fans',
        value: 'CF',
      },
      {
        label: 'Microwave',
        value: 'MI',
      },
      {
        label: 'Air Conditioning(AC)',
        value: 'AC',
      },
      {
        label: 'Dishwasher',
        value: 'DI',
      },
      {
        label: 'Garbage Disposal',
        value: 'GB',
      },
      {
        label: 'Washer Dryer',
        value: 'WD',
      },
      {
        label: 'Extra Storage',
        value: 'ES',
      },
    ],
  },
  buildingStyle: {
    type: 'checkbox',
    labelledCheckbox: true,
    label: 'Building Style',
    fields: [
      {
        label: 'Garden Style(1-3 Stories)',
        value: 'GS',
      },
      {
        label: 'Mid Rise(4-8 Stories)',
        value: 'MR',
      },
      {
        label: 'High Rise(9+ Stories)',
        value: 'HR',
      },
      {
        label: 'Walk Up(No Elevator)',
        value: 'WU',
      },
      {
        label: 'Townhouse',
        value: 'TO',
      },
      {
        label: 'Single Family',
        value: 'SF',
      },
    ],
  },
  numberOfStories: {
    type: 'number',
    label: 'Number of Stories',
  },
  storiesAboveGrade: {
    type: 'number',
    label: 'Stories Above Grade',
  },
  numberOfElevators: {
    type: 'number',
    label: 'Number of Elevators',
  },
  storiesBelowGrade: {
    type: 'number',
    label: 'Stories Below Grade',
  },
  residentialBuildings: {
    type: 'number',
    label: 'Residential Buildings',
  },
  residentialGsf: {
    type: 'number',
    label: 'Residential Gross Square Feet',
  },
  clubhouseBuildings: {
    type: 'number',
    label: 'Clubhouse Buildings',
  },
  clubhouseGsf: {
    type: 'number',
    label: 'Clubhouse Gross Square Feet',
  },
  otherBuildings: {
    type: 'number',
    label: 'Other Buildings',
  },
  otherGsf: {
    type: 'number',
    label: 'Other Gross Square Feet',
  },
  totalBuildings: {
    type: 'number',
    label: 'Total Number of Buildings',
    disabled: true,
  },
  totalGsf: {
    type: 'number',
    label: 'Total Gross Square Feet',
    disabled: true,
  },
  totalNumberOfUnits: {
    type: 'number',
    label: 'Total Number of Units',
  },
  accessibleUnitsHc: {
    type: 'number',
    label: 'Accessible Units (HC)',
  },
  audioVisualUnitsAv: {
    type: 'number',
    label: 'Audio Visual Units (AV)',
  },
  heatFuelSource: {
    type: 'select',
    label: 'Heat',
    placeholder: 'Select',
    displayEmpty: true,
    options: [
      { label: 'Electric', value: 'Electric' },
      { label: 'Gas', value: 'Gas' },
      { label: 'Propane', value: 'Propane' },
      { label: 'Multiple', value: 'Multiple' },
      { label: 'N/A', value: 'N/A' },
    ],
  },
  hotWaterFuelSource: {
    type: 'select',
    label: 'Domestic Hot Water',
    placeholder: 'Select',
    displayEmpty: true,
    options: [
      { label: 'Electric', value: 'Electric' },
      { label: 'Gas', value: 'Gas' },
      { label: 'Propane', value: 'Propane' },
      { label: 'Multiple', value: 'Multiple' },
      { label: 'N/A', value: 'N/A' },
    ],
  },
  cookingFuelSource: {
    type: 'select',
    label: 'Cooking',
    placeholder: 'Select',
    displayEmpty: true,
    options: [
      { label: 'Electric', value: 'Electric' },
      { label: 'Gas', value: 'Gas' },
      { label: 'Multiple', value: 'Multiple' },
      { label: 'N/A', value: 'N/A' },
    ],
  },
  airConditioningFuelSource: {
    type: 'select',
    label: 'Air Conditioning (AC)',
    placeholder: 'Select',
    displayEmpty: true,
    options: [
      { label: 'Electric', value: 'Electric' },
      { label: 'N/A', value: 'N/A' },
    ],
  },
  dryer: {
    type: 'select',
    label: 'Dryer (In-Unit)',
    options: [],
    placeholder: 'Select',
    displayEmpty: true,
  },
  photovoltaic: {
    type: 'select',
    label: 'Photovoltaic Panels',
    options: [],
    placeholder: 'Select',
    displayEmpty: true,
  },
  solarThermal: {
    type: 'select',
    label: 'Solar Thermal',
    options: [],
    placeholder: 'Select',
    displayEmpty: true,
  },
  geothermal: {
    type: 'select',
    label: 'Geothermal',
    options: [],
    placeholder: 'Select',
    displayEmpty: true,
  },
  securityFeatures: {
    type: 'checkbox',
    labelledCheckbox: true,
    label: 'Security Features',
    fields: [
      {
        label: 'Exterior Cameras',
        value: 'EC',
      },
      {
        label: 'Interior Cameras',
        value: 'IC',
      },
      {
        label: 'Access Control-Building',
        value: 'ACB',
      },
      {
        label: 'Access Control-Unit',
        value: 'ACU',
      },
      {
        label: 'Intercom System',
        value: 'IS',
      },
      {
        label: 'Perimeter Fence',
        value: 'PF',
      },
      {
        label: 'Access Control-Parking',
        value: 'ACP',
      },
      {
        label: 'Security Guard',
        value: 'SG',
      },
    ],
  },
  greenBuildingProgram: {
    type: 'select',
    label: 'Green Building Program',
    options: [],
    placeholder: 'Select',
    displayEmpty: true,
  },
  certificationLevel: {
    type: 'select',
    label: 'Certification Level',
    options: [],
    placeholder: 'Select',
    displayEmpty: true,
  },
  comments: {
    type: 'text', // textarea
    label: 'Comments',
  },
}

export const prefillFields = [
  'address',
  'city',
  'state',
  'county',
  'zipCode',
  'constructionType',
]

export const valuesToUpdate = [
  'grossSf',
  'netSf',
  'br0',
  'br1',
  'br2',
  'br3',
  'br4',
  'br5',
  'totalUnits',
]
