import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { spinnerSize } from '../Utils/constant'
import { ternary } from '../Utils/javascript'

const DKTCircularProgress = ({ size = spinnerSize?.md, color }) => (
  <Box
    sx={{
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color,
    }}
  >
    <CircularProgress
      size={size}
      color={ternary(color, 'inherit', 'primary')}
    />
  </Box>
)
export default DKTCircularProgress
