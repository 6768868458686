export const rfpDetailsForm = {
  requestForProposalDetails: {
    rfpDueDate: {
      type: 'date',
      clearable: true,
      label: 'RFP Due Date',
      isRequired: true,
    },
    constructionType: {
      type: 'select',
      label: 'Construction Type',
      displayEmpty: true,
      isDynamicOptions: true,
      isRequired: true,
      placeholder: 'Select',
    },
    projectType: {
      type: 'select',
      label: 'Project Type',
      displayEmpty: true,
      isDynamicOptions: true,
      isRequired: true,
      placeholder: 'Select',
    },
    estimatedHardCosts: {
      type: 'text',
      label: 'Est. Hard Costs ($)',
      isRequired: true,
      isCurrency: true,
      xs: 6,
    },
    estimatedDurationMonths: {
      type: 'number',
      label: 'Est. Duration in Month',
      isRequired: true,
      xs: 6,
    },
    numberOfBuildings: {
      type: 'number',
      label: 'Number of Buildings',
      isRequired: true,
      xs: 6,
    },
    numberOfKeys: {
      type: 'number',
      label: 'Number of Unit/Keys',
      xs: 6,
    },
    parcelType: {
      type: 'select',
      label: 'Parcel Type',
      displayEmpty: true,
      isDynamicOptions: true,
      isRequired: true,
      placeholder: 'Select',
    },
    grossSquareFeet: {
      type: 'number',
      label: 'Gross Square Feet (approx)',
      formatDigits: true,
    },
  },
  services: {
    costAndDocreview: {
      type: 'radioGroup',
      lg: 12,
      fields: [
        {
          id: 1,
          label: 'Cost & Document Review (incl. site visit)',
          value: 'cost_and_document_review_incl_site_visit',
          type: 'radio',
          InputLabelProps: { fontWeight: 'bold' },
        },
        {
          id: 2,
          label: 'Cost & Document Review (excl. site visit)',
          value: 'cost_and_document_review_excl_site_visit',
          type: 'radio',
          InputLabelProps: { fontWeight: 'bold' },
        },
      ],
    },
    costAndDocreviewRelFee: {
      type: 'checkbox',
      labelledCheckbox: true,
      fields: [
        {
          sx: { marginBottom: 2 },
          id: 3,
          label: 'Reliance Fee for Cost & Document Review',
          value: 'cost_and_document_review_reliance_fee',
        },
      ],
    },
    cmrFields: {
      type: 'radioGroup',
      lg: 12,
      fields: [
        {
          id: 1,
          label:
            'Construction Monitoring Report (incl. site visit) - per report',
          value: 'construction_monitoring_report_incl_site_visit',
          type: 'radio',
        },
        {
          id: 2,
          label:
            'Construction Monitoring Report (excl. site visit) - per report',
          value: 'construction_monitoring_report_excl_site_visit',
          type: 'radio',
        },
      ],
    },
    cmrFieldsRelFee: {
      type: 'checkbox',
      labelledCheckbox: true,
      fields: [
        {
          id: 3,
          sx: { marginBottom: 2 },
          label: 'Reliance Fee for Construction Monitoring',
          value: 'construction_monitoring_report_reliance_fee',
          type: 'checkbox',
        },
      ],
    },
    scopeOfServices: {
      type: 'checkbox',
      labelledCheckbox: true,
      fields: [
        {
          label: 'Property Condition Report',
          showCheckBox: false,
          InputLabelProps: { fontWeight: 'bold', fontSize: '14px !important' },
          sx: { marginLeft: '-16px' },
        },
        {
          id: 6,
          label: 'ASTM E 2018-24',
          value: 'property_condition_report_astm_e_2018-24',
        },
        {
          id: 7,
          label: 'Fannie Mae 4099',
          value: 'property_condition_report_fannie_mae_4099',
        },
        {
          id: 8,
          label: 'Freddie Mac 1105',
          value: 'property_condition_report_freddie_mac_1105',
        },
        {
          id: 9,
          label: 'Seismic Risk Assessment (PML)',
          value: 'seismic_risk_assessment',
          InputLabelProps: { fontWeight: 'bold' },
          sx: {
            marginLeft: '-28px',
          },
        },
      ],
    },
  },
  commentField: {
    comments: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 6,
    },
  },
}

export const options = {
  responsive: 'standard',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  selectableRows: 'none',
  pagination: false,
}

export const columns = [
  {
    name: 'id',
    label: ' ',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'isFavorite',
    label: ' ',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'companyName',
    label: 'Available Consultants',
    options: {
      filter: true,
      sort: true,
    },
  },
]

// Consultant Bids & Final Selection

export const finalSelectionFields = {
  selectionFields: {
    finalSelectedConsultant: {
      type: 'select',
      label: 'Final Consultant Selection',
      displayEmpty: true,
      isDynamicOptions: true,
      placeholder: 'Select',
      xs: 4,
    },
    finalSelectedConsultantOtherNonAcreiq: {
      type: 'text',
      label: 'Final Consultant Selection Other (Non-AcreIQ)',
      xs: 4,
    },
  },
  commentField: {
    bidComment: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 6,
      xs: 8,
    },
  },
}

export const bidsColumns = [
  {
    name: 'name',
    label: 'Scope of Services',
    options: {
      filter: true,
      sort: false,
    },
  },
]

export const proposalDetailColumns = [
  {
    name: 'proposalDetail',
    label: 'Request for Proposal Details',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'projectSummary',
    label: ' ',
    options: {
      filter: true,
      sort: false,
      someOptionToToggle: true,
    },
  },
]

export const scopOfServicesColumns = [
  {
    name: 'Service',
    label: 'Scope of Services',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'fees',
    label: 'Fees',
    options: {
      filter: true,
      sort: false,
      someOptionToToggle: true,
    },
  },
]

export const proposalDetailArr = [
  {
    label: 'Client',
    name: 'client',
  },
  {
    label: 'RFP Due Date',
    name: 'rfpDueDate',
    formateType: 'date',
  },
  {
    label: 'Construction Type',
    name: 'constructionType',
    formateType: 'select',
  },
  {
    label: 'Project Type',
    name: 'projectType',
    formateType: 'select',
  },
  {
    label: 'Est. Hard Costs ($)',
    name: 'estimatedHardCosts',
    formateType: 'currency',
  },
  {
    label: 'Est. Duration in Months',
    name: 'estimatedDurationMonths',
    formateType: 'number',
  },
  {
    label: 'Number of Buildings',
    name: 'numberOfBuildings',
    formateType: 'number',
  },
  {
    label: 'Number of Units/Keys',
    name: 'numberOfKeys',
    formateType: 'number',
  },
  {
    label: 'Parcel Type',
    name: 'parcelType',
    formateType: 'select',
  },
  {
    label: 'Gross Square Feet',
    name: 'grossSquareFeet',
    formateType: 'number',
  },
]
