import { Box, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTGrid from '../../../Shared/DKTGrid'
import DKTTypography from '../../../Shared/DKTTypography'
import { useStyles } from '../../../Styles/contingency.style'
import DKTButton from '../../../Shared/DKTButton'
import { ternary } from '../../../Utils/javascript'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { spinnerSize } from '../../../Utils/constant'
import { importContingencyFromLastReport } from '../../../Redux/actions/contingency'

const ImportCommentModal = ({
  isImportWarningOpen,
  hideImportWarning,
  importFieldName,
  setFieldValue,
  ownerContractorId,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { isImporting } = useSelector(({ contingency }) => contingency)
  const { slug: projectId, reportId } = useParams()

  const handleImportOverWrite = () => {
    if (importFieldName.current) {
      const onSuccess = (value) => {
        setFieldValue(importFieldName.current, value)
        hideImportWarning()
      }
      dispatch(
        importContingencyFromLastReport({
          query: importFieldName.current,
          projectId,
          ocId: ownerContractorId,
          onSuccess,
          reportId,
        }),
      )
    }
  }

  const importWarningActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={hideImportWarning}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleImportOverWrite}
        disabled={
          isImporting.hccComments ||
          isImporting.sccComments ||
          isImporting?.contractorContingencyComments
        }
      >
        {ternary(
          Object.values(isImporting).some((value) => value),
          <DKTCircularProgress
            size={spinnerSize?.sm}
            {...ternary(
              isImporting.hccComments ||
                isImporting.sccComments ||
                isImporting?.contractorContingencyComments,
              { color: theme.palette.gray.dark },
              {},
            )}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  return (
    <DKTDialog
      open={isImportWarningOpen}
      handleClose={hideImportWarning}
      title="&nbsp;"
      actions={importWarningActions}
      maxWidth="xs"
    >
      <DKTGrid container>
        <DKTGrid item xs={12}>
          <Box className={classes.deleteNotificationContent}>
            <DKTTypography variant="h5">
              Existing data will be overwritten.
            </DKTTypography>
            <DKTTypography variant="body1">Continue?</DKTTypography>
          </Box>
        </DKTGrid>
      </DKTGrid>
    </DKTDialog>
  )
}

export default ImportCommentModal
