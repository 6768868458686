import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { cancelApiCallSource } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  DELETE_PDF_FAILED,
  DELETE_PDF_REQUESTED,
  DELETE_PDF_SUCCESS,
  FETCH_PDF_LIST_FAILED,
  FETCH_PDF_LIST_REQUESTED,
  FETCH_PDF_LIST_SUCCESS,
  FETCH_PROJECT_LIST_SUCCESS,
  FETCH_PROJECT_PDF_LIST_FAILED,
  FETCH_PROJECT_PDF_LIST_REQUESTED,
  SET_UPLOAD_PROGRESS,
  UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_FAILED,
  UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_REQUESTED,
  UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_SUCCESS,
  UPDATE_REPORT_ATTACHMENTS_PDF_LIST_FAILED,
  UPDATE_REPORT_ATTACHMENTS_PDF_LIST_REQUESTED,
  UPDATE_REPORT_ATTACHMENTS_PDF_LIST_SUCCESS,
  UPLOAD_PDF_FAILED,
  UPLOAD_PDF_REQUESTED,
  UPLOAD_PDF_SUCCESS,
} from '../constants/attachments'
import { updateSaveStatusAction } from './confirmation'

const fetchPdfListRequested = () => ({
  type: FETCH_PDF_LIST_REQUESTED,
})
const fetchPdfListSuccess = (payload) => ({
  type: FETCH_PDF_LIST_SUCCESS,
  payload,
})
const fetchPdfListFailed = (payload) => ({
  type: FETCH_PDF_LIST_FAILED,
  payload,
})

export const getPdfList =
  ({ reportId, ocId, projectId, isLoading = true }) =>
  async (dispatch) => {
    if (isLoading) {
      dispatch(fetchPdfListRequested())
    }
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.getPdfList}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
    })
    if (data) {
      dispatch(fetchPdfListSuccess(data))
    }
    if (error) {
      dispatch(fetchPdfListFailed(error.response))
    }
  }

const uploadPdfRequested = () => ({
  type: UPLOAD_PDF_REQUESTED,
})
const uploadPdfSuccess = (payload) => ({
  type: UPLOAD_PDF_SUCCESS,
  payload,
})
const uploadPdfFailed = (payload) => ({
  type: UPLOAD_PDF_FAILED,
  payload,
})
const setUploadProgress = (payload) => ({
  type: SET_UPLOAD_PROGRESS,
  payload,
})

export const uploadPdfInAttachments =
  ({
    payload,
    reportId,
    ocId,
    projectId,
    setCancelTokenSource,
    setUploadPdf,
  }) =>
  async (dispatch) => {
    dispatch(uploadPdfRequested())
    const source = cancelApiCallSource()
    setCancelTokenSource(source)
    const { data, error } = await api({
      endPoint: reportId
        ? `${apiEndPoints.getPdfList}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`
        : `${apiEndPoints.getProjectPdfList}?projectId=${projectId}`,
      method: method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Upload successfully!',
      cancelToken: source.token,
      onUploadProgress: (data) => {
        dispatch(setUploadProgress(data))
      },
    })
    if (data) {
      setUploadPdf([])
      dispatch(
        reportId
          ? getPdfList({
              projectId,
              ocId,
              reportId,
              isLoading: false,
            })
          : getProjectPdfList({
              projectId,
              isLoading: false,
            }),
      )
      dispatch(uploadPdfSuccess(data))
    }
    if (error) {
      dispatch(uploadPdfFailed(error.response))
      showToast(error.response?.data?.error)
    }
  }

// update report attachments pdf list
const updateReportAttachmentsPdfListRequested = () => ({
  type: UPDATE_REPORT_ATTACHMENTS_PDF_LIST_REQUESTED,
})
const updateReportAttachmentsPdfListSuccess = (payload) => ({
  type: UPDATE_REPORT_ATTACHMENTS_PDF_LIST_SUCCESS,
  payload,
})
const updateReportAttachmentsPdfListFailed = (payload) => ({
  type: UPDATE_REPORT_ATTACHMENTS_PDF_LIST_FAILED,
  payload,
})

export const updateReportAttachmentsPdfList =
  ({ payload, reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(updateReportAttachmentsPdfListRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.updateREportAttachmentsPdfList}${
        payload[payload.length - 1]?.id
      }/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.patch,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(updateReportAttachmentsPdfListSuccess(data))
    }
    if (error) {
      dispatch(updateReportAttachmentsPdfListFailed(error.response.data))
    }
  }
// delete pdf
const deletePdfRequested = () => ({
  type: DELETE_PDF_REQUESTED,
})
const deletePdfSuccess = (payload) => ({
  type: DELETE_PDF_SUCCESS,
  payload,
})
const deletePdfFailed = (payload) => ({
  type: DELETE_PDF_FAILED,
  payload,
})

export const deleteReportAttachmentPdf =
  ({ payload, reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(deletePdfRequested())
    const { data, error } = await api({
      endPoint: reportId
        ? `${apiEndPoints.deleteAttachmentPdf}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`
        : `${apiEndPoints.deleteProjectAttachmentPdf}?projectId=${projectId}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Deleted successfully!',
    })
    if (data) {
      dispatch(deletePdfSuccess(data))
    }
    if (error) {
      dispatch(deletePdfFailed(error.response.data))
    }
  }

// project attachments section

const fetchProjectPdfListRequested = () => ({
  type: FETCH_PROJECT_PDF_LIST_REQUESTED,
})
const fetchProjectPdfListSuccess = (payload) => ({
  type: FETCH_PROJECT_LIST_SUCCESS,
  payload,
})
const fetchProjectPdfListFailed = (payload) => ({
  type: FETCH_PROJECT_PDF_LIST_FAILED,
  payload,
})

export const getProjectPdfList =
  ({ projectId, isLoading = false }) =>
  async (dispatch) => {
    if (isLoading) {
      dispatch(fetchProjectPdfListRequested())
    }
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.getProjectPdfList}?projectId=${projectId}`,
    })
    if (data) {
      dispatch(fetchProjectPdfListSuccess(data))
    }
    if (error) {
      dispatch(fetchProjectPdfListFailed(error.response))
    }
  }

// update project attachments pdf list
const updateProjectAttachmentsPdfListRequested = () => ({
  type: UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_REQUESTED,
})
const updateProjectAttachmentsPdfListSuccess = (payload) => ({
  type: UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_SUCCESS,
  payload,
})
const updateProjectAttachmentsPdfListFailed = (payload) => ({
  type: UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_FAILED,
  payload,
})

export const updateProjectAttachmentsPdfList =
  ({ payload, projectId }) =>
  async (dispatch) => {
    dispatch(updateProjectAttachmentsPdfListRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.updateProjectAttachmentsPdfList}${
        payload[payload.length - 1]?.id
      }/?projectId=${projectId}`,
      method: method.patch,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(updateProjectAttachmentsPdfListSuccess(data))
    }
    if (error) {
      dispatch(updateProjectAttachmentsPdfListFailed(error.response.data))
    }
  }
