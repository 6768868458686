import { validatePassword } from '../Utils/regex'

export const formFields = {
  newPassword: {
    isRequired: true,
    validate: [
      validatePassword,
      'Password must be at least 8 characters long and contain a mix of uppercase letters, lowercase letters, digits, and special characters.',
    ],
    showLabel: false,
    type: 'password',
    label: 'New Password',
    placeholder: 'New Password',
    dependsOn: 'confirmPassword',
  },
  confirmPassword: {
    showLabel: false,
    type: 'password',
    label: 'Confirm Password',
    placeholder: 'Confirm Password',
    isConfirmPassword: true,
    dependsOn: 'newPassword',
  },
}
