import Admin from '../Presentation/admin'
import CompanyPersonnel from '../Presentation/admin/companyPersonnel/CompanyPersonnel'
import ProjectAssignments from '../Presentation/admin/ProjectAssignments'
import ConstructionMonitoringReport from '../Presentation/admin/reportSettings/ConstructionMonitoringReport'
import ForgotPassword from '../Presentation/auth/ForgotPassword'
import Login from '../Presentation/auth/Login'
import Profile from '../Presentation/profile'
import EditProfile from '../Presentation/profile/EditProfile'
import Projects from '../Presentation/projects'
import Environmental from '../Presentation/projects/Environmental'
import ProjectSummary from '../Presentation/projects/projectSummary/ProjectSummary'
import ResetPassword from '../Presentation/auth/ResetPassword'
import UserActivation from '../Presentation/auth/UserActivation'
import SiteAndBuilding from '../Presentation/projects/siteAndBuilding/SiteAndBuilding'
import OwnerContractor from '../Presentation/projects/OwnerContractor'
import ProjectDirectory from '../Presentation/projects/projectDirectory/ProjectDirectory'
import OwnerArchitect from '../Presentation/projects/OwnerArchitect'
import ConstructionSchedule from '../Presentation/projects/ConstructionSchedule'
import ProjectSetting from '../Presentation/projects/projectSetting/ProjectSetting'
import BudgetContingency from '../Presentation/projects/BudgetContingency'
import ReportMonitoring from '../Presentation/reportMonitoring/reportDashboard/index'
import ExecutiveSummary from '../Presentation/reportMonitoring/executiveSummary/ExecutiveSummary'
import BudgetSummary from '../Presentation/reportMonitoring/budgetSummary/BudgetSummary'
import Contingency from '../Presentation/reportMonitoring/contingency/Contingency'
import ConstructionSummary from '../Presentation/reportMonitoring/constructionSummary/ConstructionSummary'
import PermitsEntitlements from '../Presentation/reportMonitoring/permitsAndEntitlement/PermitsEntitlements'
import ThirdPartyReports from '../Presentation/reportMonitoring/thirdPartyReport/ThirdPartyReports'
import Photographs from '../Presentation/reportMonitoring/Photographs'
import Appendix from '../Presentation/reportMonitoring/appendix/Appendix'
import ConstructionScheduleReport from '../Presentation/reportMonitoring/constructionScheduleReport/ConstructionScheduleReport'
import Dashboard from '../Presentation/dashboard/Dashboard'
import TwoFactorAuthentication from '../Presentation/auth/TwoFactorAuthentication'
import ReportAttachments from '../Presentation/reportMonitoring/attachments/ReportAttachments'
import ProjectAttachments from '../Presentation/projects/ProjectAttachments'
import ProjectDashboard from '../Presentation/projects/ProjectDashboard'
import CoverLetter from '../Presentation/reportMonitoring/CoverLetter'
import ClientAccess from '../Presentation/admin/clientAccess/ClientAccess'
import {
  roles,
  adminAccessRoles,
  clientCompanyAccessRoles,
  adminOnlyRole,
} from '../Utils/constant'
import Photos from '../Presentation/reportMonitoring/photos/Index'
import G702Split from '../Presentation/reportMonitoring/trackers/G702Split'
import IssuesLog from '../Presentation/reportMonitoring/issuesLogs/IssuesLog'
import CompanyInformationWrapper from '../Presentation/admin/companyInformation/CompanyInformationWrapper'
import RFPDashboard from '../Presentation/rfp/clientAccess/RFPDashboard'
import RFPLayout from '../Presentation/rfp/clientAccess/RFPLayout'
import RFPProjectOverview from '../Presentation/rfp/clientAccess/RFPProjectOverview'
import Consultants from '../Presentation/rfp/clientAccess/Consultants'

export const routes = [
  {
    path: '/',
    subRoutes: [
      { index: true, redirectRoleWise: true },
      {
        path: 'admin',
        element: <Admin />,
        isProtected: true,
        requiredRoles: adminAccessRoles,
        subRoutes: [
          {
            path: 'company-information',
            element: <CompanyInformationWrapper />,
            isProtected: true,
            requiredRoles: adminAccessRoles,
            isAdminOnly: true,
          },
          {
            path: 'company-personnel',
            element: <CompanyPersonnel />,
            isProtected: true,
            requiredRoles: adminAccessRoles,
            isAdminOnly: true,
          },
          {
            path: 'project-assignments',
            element: <ProjectAssignments />,
            isProtected: true,
            requiredRoles: adminAccessRoles,
            isAdminOnly: true,
          },
          {
            path: 'construction-monitoring-report',
            element: <ConstructionMonitoringReport />,
            isProtected: true,
            requiredRoles: adminOnlyRole,
            isAdminOnly: true,
          },
          {
            path: 'client-access',
            element: <ClientAccess />,
            isProtected: true,
            requiredRoles: adminOnlyRole,
            isAdminOnly: true,
          },
        ],
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
        isProtected: true,
        requiredRoles: clientCompanyAccessRoles,
        subRoutes: [],
      },
      {
        path: 'admin/profile',
        element: <Profile />,
        subRoutes: [{ index: true, element: <EditProfile /> }],
        isProtected: true,
        requiredRoles: clientCompanyAccessRoles,
      },
      {
        path: 'projects',
        subRoutes: [
          {
            index: true,
            element: <Projects />,
            isProtected: true,
            requiredRoles: clientCompanyAccessRoles,
          },
          {
            path: 'new-project',
            subRoutes: [
              {
                index: true,
                element: <ProjectSummary />,
                isProtected: true,
                requiredRoles: roles,
              },
              {
                path: 'summary',
                element: <ProjectSummary />,
                isProtected: true,
                requiredRoles: roles,
              },
            ],
          },
          {
            path: ':slug',
            subRoutes: [
              {
                path: 'summary',
                element: <ProjectSummary />,
                isProtected: true,
                requiredRoles: roles,
              },
              {
                path: 'site-building',
                element: <SiteAndBuilding />,
                isProtected: true,
                requiredRoles: roles,
                isRestrictUnauthorizedProject: true,
                sideBarLinkId: 'Site & Building',
              },
              {
                path: 'environmental',
                element: <Environmental />,
                isProtected: true,
                requiredRoles: roles,
                isRestrictUnauthorizedProject: true,
                sideBarLinkId: 'Environmental',
              },
              {
                path: 'owner-contractor',
                element: <OwnerContractor />,
                isProtected: true,
                requiredRoles: roles,
                isRestrictUnauthorizedProject: true,
                sideBarLinkId: 'Owner Contractor',
              },
              {
                path: 'owner-architect',
                element: <OwnerArchitect />,
                isProtected: true,
                requiredRoles: roles,
                isRestrictUnauthorizedProject: true,
                sideBarLinkId: 'Owner Architect',
              },
              {
                path: 'budget-contingency',
                element: <BudgetContingency />,
                isProtected: true,
                requiredRoles: roles,
                isRestrictUnauthorizedProject: true,
                sideBarLinkId: 'Budget & Contingency',
              },
              {
                path: 'construction-schedule',
                element: <ConstructionSchedule />,
                isProtected: true,
                requiredRoles: roles,
                isRestrictUnauthorizedProject: true,
                sideBarLinkId: 'Construction Schedule',
              },
              {
                path: 'project-directory',
                element: <ProjectDirectory />,
                isProtected: true,
                requiredRoles: roles,
                isRestrictUnauthorizedProject: true,
                sideBarLinkId: 'Project Directory',
              },
              {
                path: 'project-attachment',
                element: <ProjectAttachments />,
                isProtected: true,
                requiredRoles: roles,
                isRestrictUnauthorizedProject: true,
                sideBarLinkId: 'ProjectAttachments',
              },
              {
                path: 'project-setting',
                element: <ProjectSetting />,
                isProtected: true,
                requiredRoles: clientCompanyAccessRoles,
                isRestrictUnauthorizedProject: true,
              },
              {
                path: 'project-photos',
                isRestrictUnauthorizedProject: true,
                element: <Photos />,
                isProtected: true,
                requiredRoles: clientCompanyAccessRoles,
              },
              {
                path: 'projectDashboard',
                element: <ProjectDashboard />,
                isProtected: true,
                requiredRoles: clientCompanyAccessRoles,
                isRestrictUnauthorizedProject: true,
              },
              {
                path: 'project-reports',
                subRoutes: [
                  {
                    index: true,
                    element: <ReportMonitoring />,
                    isProtected: true,
                    requiredRoles: clientCompanyAccessRoles,
                    isRestrictUnauthorizedProject: true,
                  },
                  {
                    path: ':reportId',
                    subRoutes: [
                      {
                        path: 'cover-page-and-letter',
                        element: <CoverLetter />,
                        isProtected: true,
                        requiredRoles: roles,
                      },
                      {
                        path: 'executive-summary',
                        element: <ExecutiveSummary />,
                        isProtected: true,
                        requiredRoles: roles,
                      },
                      {
                        path: 'issues-log',
                        element: <IssuesLog />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'Issues Log',
                      },
                      {
                        path: 'budget-summary',
                        element: <BudgetSummary />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'Budget Summary',
                      },
                      {
                        path: 'g702-tracker',
                        element: <G702Split />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'G702 Summary',
                      },
                      {
                        path: 'contingency',
                        element: <Contingency />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'Contingency',
                      },
                      {
                        path: 'construction-summary',
                        element: <ConstructionSummary />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'Construction Summary',
                      },
                      {
                        path: 'construction-schedule',
                        element: <ConstructionScheduleReport />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'Construction Schedule',
                      },
                      {
                        path: 'permits-entitlements',
                        element: <PermitsEntitlements />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'Permits & Entitlements',
                      },
                      {
                        path: 'third-party-reports',
                        element: <ThirdPartyReports />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'Third Party Reports',
                      },
                      {
                        path: 'photographs',
                        element: <Photographs />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'Photographs',
                      },
                      {
                        path: 'appendix',
                        element: <Appendix />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'Appendix',
                      },
                      {
                        path: 'report-attachment',
                        element: <ReportAttachments />,
                        isProtected: true,
                        requiredRoles: roles,
                        isRestrictUnauthorizedReport: true,
                        sideBarLinkId: 'ReportAttachments',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'login',
        element: <Login />,
        postLoginAccess: false,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
        postLoginAccess: false,
      },
      {
        path: 'accounts/reset-password/:token',
        element: <ResetPassword />,
        postLoginAccess: false,
      },
      {
        path: 'activation/:token',
        element: <UserActivation />,
        postLoginAccess: false,
      },
      {
        path: '/two-factor-auth',
        element: <TwoFactorAuthentication />,
        postLoginAccess: false,
      },
      {
        path: 'rfp',
        element: <RFPLayout />,
        isProtected: true,
        requiredRoles: adminAccessRoles,
        subRoutes: [
          {
            path: 'rfps',
            element: <RFPDashboard />,
            isProtected: true,
            requiredRoles: adminAccessRoles,
            isAdminOnly: true,
          },
          {
            path: ':rfpId',
            subRoutes: [
              {
                path: 'rfp-project',
                element: <RFPProjectOverview />,
                isProtected: true,
                requiredRoles: adminAccessRoles,
                isAdminOnly: true,
              },
            ],
          },
          {
            path: 'consultants',
            element: <Consultants />,
            isProtected: true,
            requiredRoles: adminAccessRoles,
            isAdminOnly: true,
          },
        ],
      },
    ],
  },
]
