export const navbarLinks = [
  {
    text: 'Dashboard',
    url: '/dashboard',
  },
  {
    text: 'Projects',
    url: '/projects',
  },
  // {
  //   text: 'Reports',
  //   url: '/',
  //   isInProgress: true,
  // },
  // {
  //   text: 'Library',
  //   url: '/',
  //   isInProgress: true,
  // },
  // {
  //   text: 'Contacts',
  //   url: '/',
  //   isInProgress: true,
  // },
  // {
  //   text: 'Invoices',
  //   url: '/',
  //   isInProgress: true,
  // },
  // {
  //   text: 'Datahub',
  //   url: '/',
  //   isInProgress: true,
  // },
  {
    text: 'RFP',
    url: '/rfp/rfps',
    isAdminOnly: true,
  },
  {
    text: 'Admin',
    url: '/admin/company-information',
    isAdminOnly: true,
  },
]
