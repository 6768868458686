import {
  DELETE_PROJECT_ASSIGNMENTS_FAILED,
  DELETE_PROJECT_ASSIGNMENTS_REQUESTED,
  DELETE_PROJECT_ASSIGNMENTS_SUCCESS,
  FETCH_PROJECT_ASSIGNMENTS_FAILED,
  FETCH_PROJECT_ASSIGNMENTS_REQUESTED,
  FETCH_PROJECT_ASSIGNMENTS_SUCCESS,
  SAVE_PROJECT_ASSIGNMENTS_FAILED,
  SAVE_PROJECT_ASSIGNMENTS_REQUESTED,
  SAVE_PROJECT_ASSIGNMENTS_SUCCESS,
  UPDATE_PROJECT_PERMISSIONS,
} from '../constants/projectAssignments'

const initialState = {
  projectAssignments: [],
  isFetchingProjectAssignments: false,
  isDeletingProjectAssignments: false,
  isSavingProjectAssignments: false,
  fetchProjectAssignmentsError: null,
  saveProjectAssignmentsError: null,
  deleteProjectAssignmentsError: null,
  hasUnsavedChanges: false,
}

export const projectAssignments = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PROJECT_ASSIGNMENTS_REQUESTED:
      return {
        ...state,
        isFetchingProjectAssignments: true,
      }
    case FETCH_PROJECT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        isFetchingProjectAssignments: false,
        fetchProjectAssignmentsError: null,
        projectAssignments: action.payload,
        hasUnsavedChanges: false,
      }
    case FETCH_PROJECT_ASSIGNMENTS_FAILED:
      return {
        ...state,
        isFetchingProjectAssignments: false,
        fetchProjectAssignmentsError: action.payload,
      }
    case SAVE_PROJECT_ASSIGNMENTS_REQUESTED:
      return {
        ...state,
        isSavingProjectAssignments: true,
      }
    case SAVE_PROJECT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        isSavingProjectAssignments: false,
        saveProjectAssignmentsError: null,
        hasUnsavedChanges: false,
      }
    case SAVE_PROJECT_ASSIGNMENTS_FAILED:
      return {
        ...state,
        isSavingProjectAssignments: false,
        saveProjectAssignmentsError: action.payload,
      }
    case UPDATE_PROJECT_PERMISSIONS:
      return {
        ...state,
        projectAssignments: action.payload.data,
        hasUnsavedChanges: action.payload.hasUnsavedChanges,
      }
    case DELETE_PROJECT_ASSIGNMENTS_REQUESTED:
      return {
        ...state,
        isDeletingProjectAssignments: true,
      }
    case DELETE_PROJECT_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        isDeletingProjectAssignments: false,
        deleteProjectAssignmentsError: null,
        projectAssignments: action.payload,
      }
    case DELETE_PROJECT_ASSIGNMENTS_FAILED:
      return {
        ...state,
        isDeletingProjectAssignments: false,
        deleteProjectAssignmentsError: action.payload,
      }

    default:
      return state
  }
}
