export const CREATE_ENVIRONMENTAL_REQUESTED = 'CREATE_ENVIRONMENTAL_REQUESTED'
export const CREATE_ENVIRONMENTAL_SUCCESS = 'CREATE_ENVIRONMENTAL_SUCCESS'
export const CREATE_ENVIRONMENTAL_FAILED = 'CREATE_ENVIRONMENTAL_FAILED'

export const FETCH_ENVIRONMENTAL_REQUESTED = 'FETCH_ENVIRONMENTAL_REQUESTED'
export const FETCH_ENVIRONMENTAL_SUCCESS = 'FETCH_ENVIRONMENTAL_SUCCESS'
export const FETCH_ENVIRONMENTAL_FAILED = 'FETCH_ENVIRONMENTAL_FAILED'

export const FETCH_ENVIRONMENTAL_LIST_REQUESTED =
  'FETCH_ENVIRONMENTAL_LIST_REQUESTED'
export const FETCH_ENVIRONMENTAL_LIST_SUCCESS =
  'FETCH_ENVIRONMENTAL_LIST_SUCCESS'
export const FETCH_ENVIRONMENTAL_LIST_FAILED = 'FETCH_ENVIRONMENTAL_LIST_FAILED'

export const SAVE_ENVIRONMENTAL_REQUESTED = 'SAVE_ENVIRONMENTAL_REQUESTED'
export const SAVE_ENVIRONMENTAL_SUCCESS = 'SAVE_ENVIRONMENTAL_SUCCESS'
export const SAVE_ENVIRONMENTAL_FAILED = 'SAVE_ENVIRONMENTAL_FAILED'

export const FETCH_ENVIRONMENTAL_MAPPING_REQUESTED =
  'FETCH_ENVIRONMENTAL_MAPPING_REQUESTED'
export const FETCH_ENVIRONMENTAL_MAPPING_SUCCESS =
  'FETCH_ENVIRONMENTAL_MAPPING_SUCCESS'
export const FETCH_ENVIRONMENTAL_MAPPING_FAILED =
  'FETCH_ENVIRONMENTAL_MAPPING_FAILED'

export const ADD_ENVIRONMENTAL_SUCCESS = 'ADD_ENVIRONMENTAL_SUCCESS'

export const SET_ENVIRONMENTAL_ID = 'SET_ENVIRONMENTAL_ID'

export const DELETE_ENVIRONMENTAL_REQUESTED = 'DELETE_ENVIRONMENTAL_REQUESTED'
export const DELETE_ENVIRONMENTAL_SUCCESS = 'DELETE_ENVIRONMENTAL_SUCCESS'
export const DELETE_ENVIRONMENTAL_FAILED = 'DELETE_ENVIRONMENTAL_FAILED'

// clear environmental
export const CLEAR_ENVIRONMENTAL = 'CLEAR_ENVIRONMENTAL'
