import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  autoCreateCheckBox: {
    '&.MuiFormControl-root': {
      alignItems: 'center',
      flexDirection: 'row',
    },
    '& .MuiCheckbox-root': {
      paddingLeft: 0,
    },
    '& .MuiInputLabel-root': {
      color: '#3E3E3E',
      position: 'inherit',
      fontSize: 13,
      transform: 'translate(0)',
    },
  },
  rfcSearchbar: {
    width: 200,
    display: 'inline-block',
    '& .MuiInputBase-root': {
      marginTop: -4,
      textAlign: 'start',
      paddingRight: 0,
    },
    '& fieldset': {
      display: 'none',
    },
    '& .MuiIconButton-root': {
      verticalAlign: 'bottom',
    },
  },
  rfcTableWrapper: {
    margin: '0',
    '& .MuiToolbar-root': {
      padding: 0,
      minHeight: 'unset',
      marginBottom: 10,
    },
    '& .MuiTableCell-root': {
      padding: '7px 16px',
      '& a': {
        textDecoration: 'none',
        color: '#1564FF',
      },
    },
  },
  labelledCheckbox: {
    '&.MuiFormControl-root': {
      alignItems: 'center',
      flexDirection: 'row',
    },
    '& .MuiInputLabel-root': {
      color: '#3E3E3E',
      position: 'inherit',
      fontSize: 14,
      transform: 'translate(0)',
      whiteSpace: 'normal',
      width: 'max-content',
    },
  },
  selectBox: {
    marginLeft: 'auto',
    '& .MuiSelect-select': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiInputBase-root': {
      marginTop: 0,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      backgroundColor: 'transparent',
      borderColor: '#1564FF',
      height: 'unset',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '& .MuiFormHelperText-root': {
      marginTop: 1,
    },
  },
  cstmTable: {
    '& .MuiTypography-root': {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  emptyImageWrapper: {
    '&.MuiStack-root': {
      height: 114,
      width: '100%',
      maxWidth: 378,
      backgroundColor: '#D9D9D9',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 11,
      '& img': {
        fontWeight: 700,
      },
    },
  },
  sosTable: {
    '& tbody': {
      '& tr': {
        '& td:nth-child(2)': {
          padding: 0,
          position: 'relative',
          minWidth: 178,
          '& .MuiFormControl-root': {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            '& .MuiInputBase-root': {
              height: '100%',
              backgroundColor: '#1564FF20',
              border: 0,
            },
          },
          '& .MuiFormHelperText-root': {
            marginTop: -10,
            position: 'relative',
          },
        },
      },
    },
  },
  rfcTableClient: {
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          padding: '4px 16px',
          height: 48,
        },
      },
    },
    '& .MuiTableCell-body': {
      padding: '8px 16px',
    },
  },
  hidden: {
    display: 'none',
  },
  rfpDetailsTable: {
    '& .MuiToolbar-root': {
      marginBottom: 5.5,
      height: 32,
    },
    '& td': {
      '&:first-child': {
        padding: '7px 16px',
        width: 36,
      },
      '&:nth-child(2)': {
        width: 22,
        padding: '7px',
      },
    },
  },
  radioLabel: {
    '& .MuiButtonBase-root': {
      paddingRight: '4px',
    },
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: 700,
    },
  },
  companyList: {
    '& table': {
      '& thead': {
        '& tr': {
          '& th': {
            backgroundColor: '#C4C4C4 !important',
          },
        },
      },
    },
  },
}))
