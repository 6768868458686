import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, length } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  CREATE_BUDGET_SUMMARY_FAILED,
  CREATE_BUDGET_SUMMARY_REQUESTED,
  CREATE_BUDGET_SUMMARY_SUCCESS,
  DELETE_BUDGET_SUMMARY_FAILED,
  DELETE_BUDGET_SUMMARY_REQUESTED,
  DELETE_BUDGET_SUMMARY_SUCCESS,
  FETCH_BUDGET_SUMMARY_FAILED,
  FETCH_BUDGET_SUMMARY_NUMBERING_FAILED,
  FETCH_BUDGET_SUMMARY_NUMBERING_REQUEST,
  FETCH_BUDGET_SUMMARY_NUMBERING_SUCCESS,
  FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_FAILED,
  FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_REQUESTED,
  FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_SUCCESS,
  FETCH_BUDGET_SUMMARY_REQUEST,
  FETCH_BUDGET_SUMMARY_SUCCESS,
  IMPORT_FROM_LAST_REPORT_FAILED,
  IMPORT_FROM_LAST_REPORT_REQUESTED,
  IMPORT_FROM_LAST_REPORT_SUCCESS,
  UPDATE_BUDGET_SUMMARY_FAILED,
  UPDATE_BUDGET_SUMMARY_REQUESTED,
  UPDATE_BUDGET_SUMMARY_SUCCESS,
} from '../constants/budgetSummary'
import { updateSaveStatusAction } from './confirmation'

// Fetch budget summary reference values
const fetchBudgetSummaryReferenceValuesRequested = () => ({
  type: FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_REQUESTED,
})
const fetchBudgetSummaryReferenceValuesSuccess = (payload) => ({
  type: FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_SUCCESS,
  payload,
})
const fetchBudgetSummaryReferenceValuesFailed = (payload) => ({
  type: FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_FAILED,
  payload,
})

export const getBudgetSummaryReferenceValues =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchBudgetSummaryReferenceValuesRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.budgetSummary}reference-values/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
    })
    if (data) {
      dispatch(fetchBudgetSummaryReferenceValuesSuccess(data))
      return data
    }
    if (error) {
      dispatch(fetchBudgetSummaryReferenceValuesFailed(error.response))
    }
    return false
  }

// Fetch budget summary
const fetchBudgetSummaryRequested = () => ({
  type: FETCH_BUDGET_SUMMARY_REQUEST,
})
export const fetchBudgetSummarySuccess = (payload) => ({
  type: FETCH_BUDGET_SUMMARY_SUCCESS,
  payload,
})
const fetchBudgetSummaryFailed = (payload) => ({
  type: FETCH_BUDGET_SUMMARY_FAILED,
  payload,
})

export const getBudgetSummary =
  (reportId, ocId, projectId, handleRedirect) => async (dispatch) => {
    dispatch(fetchBudgetSummaryRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.budgetSummary}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
      showToastMessage: false,
      toastMessage: 'Budget summary Fetch successfully!',
    })
    if (data) {
      dispatch(fetchBudgetSummarySuccess(data))
      return
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        handleRedirect()
      }
      dispatch(fetchBudgetSummaryFailed(error.response))
    }
  }
// Create budget summary
const createBudgetSummaryRequested = () => ({
  type: CREATE_BUDGET_SUMMARY_REQUESTED,
})

const createBudgetSummarySuccess = (payload) => ({
  type: CREATE_BUDGET_SUMMARY_SUCCESS,
  payload,
})
const createBudgetSummaryFailed = (payload) => ({
  type: CREATE_BUDGET_SUMMARY_FAILED,
  payload,
})

export const createBudgetSummary =
  (payload, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(createBudgetSummaryRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.budgetSummary}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Budget summary created successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(createBudgetSummarySuccess([data]))
      return
    }
    if (error) {
      dispatch(createBudgetSummaryFailed(error.response.data))
    }
  }
// Update budget summary
const updateBudgetSummaryRequested = () => ({
  type: UPDATE_BUDGET_SUMMARY_REQUESTED,
})

const updateBudgetSummarySuccess = (payload) => (dispatch, getState) => {
  const state = getState()
  const budgetSummaryData = [...state.budgetSummary.budgetSummaryData]
  const index = budgetSummaryData?.findIndex(({ id }) => equal(id, payload?.id))
  if (~index) {
    budgetSummaryData.splice(index, 1, payload)
  }
  dispatch({
    type: UPDATE_BUDGET_SUMMARY_SUCCESS,
    payload: budgetSummaryData,
  })
}
const updateBudgetSummaryFailed = (payload) => ({
  type: UPDATE_BUDGET_SUMMARY_FAILED,
  payload,
})

export const updateBudgetSummary =
  (payload, budgetSummaryId, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(updateBudgetSummaryRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.budgetSummary}${budgetSummaryId}/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.patch,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Budget summary updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(updateBudgetSummarySuccess(data))
      return
    }
    if (error) {
      dispatch(updateBudgetSummaryFailed(error.response.data))
    }
  }

// Delete budget summary
const deleteBudgetSummaryRequested = () => ({
  type: DELETE_BUDGET_SUMMARY_REQUESTED,
})
export const deleteBudgetSummarySuccess = (payload) => (dispatch, getState) => {
  const state = getState()
  const budgetSummaryData = [...state.budgetSummary.budgetSummaryData]
  const index = budgetSummaryData?.findIndex(({ id }) =>
    equal(id, payload?.budgetSummary[0]),
  )
  if (~index) {
    budgetSummaryData.splice(index, 1)
  }
  dispatch({
    type: DELETE_BUDGET_SUMMARY_SUCCESS,
    payload: budgetSummaryData,
  })
}
const deleteBudgetSummaryFailed = (payload) => ({
  type: DELETE_BUDGET_SUMMARY_FAILED,
  payload,
})
export const deleteBudgetSummary =
  (payload, reportId, ocId, handleClose) => async (dispatch) => {
    dispatch(deleteBudgetSummaryRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.budgetSummary}delete/?reportId=${reportId}&ocId=${ocId}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Pay App deleted successfully!',
    })
    if (status) {
      handleClose()
      dispatch(deleteBudgetSummarySuccess(payload))
      return
    }
    if (error) {
      dispatch(deleteBudgetSummaryFailed(error.response.data))
    }
  }

// Import from last report
const importFormLastReportRequested = (query) => ({
  type: IMPORT_FROM_LAST_REPORT_REQUESTED,
  payload: query,
})

const importFormLastReportSuccess = (payload) => ({
  type: IMPORT_FROM_LAST_REPORT_SUCCESS,
  payload,
})
const importFormLastReportFailed = (payload) => ({
  type: IMPORT_FROM_LAST_REPORT_FAILED,
  payload,
})
export const importFromLastReport =
  ({ query, projectId, ocId, onSuccess = () => null, reportId }) =>
  async (dispatch) => {
    const [firstLetter] = query
    const restWord = query.substr(1, length(query) - 1)
    const pascalCaseQuery = `${firstLetter.toUpperCase()}${restWord}`
    dispatch(importFormLastReportRequested(query))

    const { data, error } = await api({
      endPoint: `${apiEndPoints.importLastReport}?query=${query}&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
      method: method.get,
    })

    if (data) {
      dispatch(
        importFormLastReportSuccess({
          pascalCaseQuery,
          query,
          data,
        }),
      )
      onSuccess(data[query])
    }
    if (error) {
      showToast('Failed to import comments')
      dispatch(
        importFormLastReportFailed({
          pascalCaseQuery,
          query,
          data: error.response.data,
        }),
      )
    }
  }

// Fetch budget summary numbering
const fetchBudgetSummaryNumberRequested = () => ({
  type: FETCH_BUDGET_SUMMARY_NUMBERING_REQUEST,
})
const fetchBudgetSummaryNumberSuccess = (payload) => ({
  type: FETCH_BUDGET_SUMMARY_NUMBERING_SUCCESS,
  payload,
})
const fetchBudgetSummaryNumberFailed = (payload) => ({
  type: FETCH_BUDGET_SUMMARY_NUMBERING_FAILED,
  payload,
})

export const getBudgetSummaryNumber =
  ({ reportId, ocId, projectId, budgetSummaryId, isLoading = true }) =>
  async (dispatch) => {
    if (isLoading) {
      dispatch(fetchBudgetSummaryNumberRequested())
    }
    const { data, error } = await api({
      method: method.get,
      endPoint: budgetSummaryId
        ? `${apiEndPoints.budgetSummary}numbering/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}&budgetSummaryId=${budgetSummaryId}`
        : `${apiEndPoints.budgetSummary}numbering/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
    })
    if (data) {
      dispatch(fetchBudgetSummaryNumberSuccess(data))
      return data
    }
    if (error) {
      dispatch(fetchBudgetSummaryNumberFailed(error.response))
    }
    return false
  }
