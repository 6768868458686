import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  companyPersonalTitle: {
    '&.MuiTypography-root': {
      padding: '25px 0',
      marginBottom: 0,
      display: 'block',
    },
  },
  companyPersonnelTable: {
    marginTop: 64,
    '& .MuiPaper-elevation': {
      boxShadow: 'none',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& th': {
            background: theme.palette.gray.main,
            '& .MuiButton-root': {
              padding: 0,
              width: '100%',
              justifyContent: 'space-between',
              '&:hover': {
                background: 'trasparent',
              },
              '& > .tss-1h9t3sl-MUIDataTableHeadCell-sortAction': {
                width: '100%',
                justifyContent: 'space-between',
              },
            },
          },
        },
      },
    },
    '& .MuiButton-root': {
      marginLeft: 12,
      '& .MuiButton-startIcon': {
        marginLeft: 0,
      },
    },
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          padding: 16,
          paddingLeft: 42,
          backgroundColor: '#E5E5E5',
        },
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '& td': {
          border: 0,
          padding: 0,
        },
      },
    },
  },
  workDescription: {
    display: 'flex',
    alignItems: 'end',
    '& .MuiButtonBase-root': {
      color: '#1564FF',
    },
  },
  workDescriptionTitle: {
    display: 'flex',
    padding: '16px 16px 16px 16px',
    maxWidth: 350,
    width: '100%',
    color: '#1564FF',
    '& p': {
      fontSize: 14,
    },
    borderBottom: '1px solid #E5E5E5',
    '& svg': {
      width: 40,
      height: 12,
      marginRight: 10,
      cursor: 'move',
    },
  },
  iconWrapper: {
    padding: '16.5px 9px',
    borderBottom: '1px solid #E5E5E5',
  },
  workCheckBox: {
    borderBottom: '1px solid #E5E5E5',
    '& .MuiButtonBase-root': {
      '&.MuiCheckbox-root': {
        padding: '14.5px 9px',
      },
    },
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))
