// Fetch owner architect
export const FETCH_OWNER_ARCHITECT_REQUESTED = 'FETCH_OWNER_ARCHITECT_REQUESTED'
export const FETCH_OWNER_ARCHITECT_SUCCESS = 'FETCH_OWNER_ARCHITECT_SUCCESS'
export const FETCH_OWNER_ARCHITECT_FAILED = 'FETCH_OWNER_ARCHITECT_FAILED'

// Create and update owner architect
export const CREATE_AND_UPDATE_OWNER_ARCHITECT_REQUESTED =
  'CREATE_AND_UPDATE_OWNER_ARCHITECT_REQUESTED'
export const CREATE_AND_UPDATE_OWNER_ARCHITECT_SUCCESS =
  'CREATE_AND_UPDATE_OWNER_ARCHITECT_SUCCESS'
export const CREATE_AND_UPDATE_OWNER_ARCHITECT_FAILED =
  'CREATE_AND_UPDATE_OWNER_ARCHITECT_FAILED'

// Owner contractor mapping
export const FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED =
  'FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED'
export const FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS =
  'FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS'
export const FETCH_OWNER_CONTRACTOR_MAPPING_FAILED =
  'FETCH_OWNER_CONTRACTOR_MAPPING_FAILED'

// Add new owner architect
export const ADD_OWNER_ARCHITECT = 'ADD_OWNER_ARCHITECT'

// Search architect
export const SEARCH_ARCHITECT_REQUESTED = 'SEARCH_ARCHITECT_REQUESTED'
export const SEARCH_ARCHITECT_SUCCESS = 'SEARCH_ARCHITECT_SUCCESS'
export const SEARCH_ARCHITECT_FAILED = 'SEARCH_ARCHITECT_FAILED'

// Delete architect
export const DELETE_ARCHITECT_REQUESTED = 'DELETE_ARCHITECT_REQUESTED'
export const DELETE_ARCHITECT_SUCCESS = 'DELETE_ARCHITECT_SUCCESS'
export const DELETE_ARCHITECT_FAILED = 'DELETE_ARCHITECT_FAILED'

// clear owner Architect
export const CLEAR_OWNER_ARCHITECT = 'CLEAR_OWNER_ARCHITECT'
