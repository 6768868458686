export const FETCH_BUDGET_CONTINGENCY_REQUESTED =
  'FETCH_BUDGET_CONTINGENCY_REQUESTED'
export const FETCH_BUDGET_CONTINGENCY_SUCCESS =
  'FETCH_BUDGET_CONTINGENCY_SUCCESS'
export const FETCH_BUDGET_CONTINGENCY_FAILED = 'FETCH_BUDGET_CONTINGENCY_FAILED'

export const FETCH_BUDGET_CONTINGENCY_LIST_REQUESTED =
  'FETCH_BUDGET_CONTINGENCY_LIST_REQUESTED'
export const FETCH_BUDGET_CONTINGENCY_LIST_SUCCESS =
  'FETCH_BUDGET_CONTINGENCY_LIST_SUCCESS'
export const FETCH_BUDGET_CONTINGENCY_LIST_FAILED =
  'FETCH_BUDGET_CONTINGENCY_LIST_FAILED'

export const SAVE_BUDGET_CONTINGENCY_REQUESTED =
  'SAVE_BUDGET_CONTINGENCY_REQUESTED'
export const SAVE_BUDGET_CONTINGENCY_SUCCESS = 'SAVE_BUDGET_CONTINGENCY_SUCCESS'
export const SAVE_BUDGET_CONTINGENCY_FAILED = 'SAVE_BUDGET_CONTINGENCY_FAILED'

export const ADD_BUDGET_CONTINGENCY_SUCCESS = 'ADD_BUDGET_CONTINGENCY_SUCCESS'

export const SET_BUDGET_CONTINGENCY_ID = 'SET_BUDGET_CONTINGENCY_ID'

export const FETCH_BUDGET_CONTINGENCY_MAPPING_REQUESTED =
  'FETCH_BUDGET_CONTINGENCY_MAPPING_REQUESTED'
export const FETCH_BUDGET_CONTINGENCY_MAPPING_SUCCESS =
  'FETCH_BUDGET_CONTINGENCY_MAPPING_SUCCESS'
export const FETCH_BUDGET_CONTINGENCY_MAPPING_FAILED =
  'FETCH_BUDGET_CONTINGENCY_MAPPING_FAILED'

// Delete budget contingency
export const DELETE_BUDGET_CONTINGENCY_REQUESTED =
  'DELETE_BUDGET_CONTINGENCY_REQUESTED'
export const DELETE_BUDGET_CONTINGENCY_SUCCESS =
  'DELETE_BUDGET_CONTINGENCY_SUCCESS'
export const DELETE_BUDGET_CONTINGENCY_FAILED =
  'DELETE_BUDGET_CONTINGENCY_FAILED'

// reset all values
export const RESET_BUDGET_CONTINGENCY = 'RESET_BUDGET_CONTINGENCY'
