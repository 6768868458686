import { combineReducers } from 'redux'

import { equal } from '../../Utils/javascript'
import { authReducer } from './auth'
import { projectChoices } from './choices'
import { companyReducer } from './companyInformation'
import { companyPersonnel } from './companyPersonnel'
import { constructionMonitoringReport } from './constructionMonitoringReport'
import { projectAssignments } from './projectAssignments'
import { statesReducer } from './states'
import userProfile from './userProfile'
import { projectReducer } from './projects'
import { siteAndBuilding } from './siteAndBuilding'
import { siteAddressReducer } from './siteAddress'
import { environmental } from './environmental'
import { ownerContractorReducer } from './ownerContractor'
import { projectDirectory } from './projectDirectory'
import { constructionScheduleReducer } from './constructionSchedule'
import { ownerArchitectReducer } from './ownerArchitect'
import { projectSettingReducer } from './projectSetting'
import { budgetContingency } from './budgetContingency'
import { reportMonitoringReducer } from './reportMonitoring'
import { reportChoicesReducer } from './reportChoices'
import { reportTypeReducer } from './reportType'
import { executiveSummary } from './executiveSummary'
import { budgetSummaryReducer } from './budgetSummary'
import { reportTrackers } from './reportTrackers'
import { contingency } from './contingency'
import { constructionSummaryReducer } from './constructionSummary'
import { constructionScheduleReport } from './constructionScheduleReport'
import { permitsEntitlements } from './permitsEntitlements'
import { thirdPartyReportReducer } from './thirdPartyReport'
import { photographsReducer } from './photographs'
import { appendix } from './appendix'
import { confirmationReducer } from './confirmation'
import globalDashboard from './globalDashboard'
import { attachments } from './attachments'
import { projectDashboard } from './projectDashboard'
import { supportReducer } from './support'
import { coverLetterAndPhoto } from './coverLetterAndPhoto'
import { clientAccess } from './clientAccess'
import clientAccessDashboard from './clientAccessDashboard'
import { projectLimitation } from './projectSettingLimitation'
import { projectPhotos } from './projectPhotos'
import { issuesLog } from './issuesLog'
import { rfpDataReducer } from './rfp'

const appReducer = combineReducers({
  auth: authReducer,
  profile: userProfile,
  company: companyReducer,
  states: statesReducer,
  projects: projectReducer,
  companyPersonnel,
  projectAssignments,
  projectChoices,
  siteAndBuilding,
  constructionMonitoringReport,
  siteAddress: siteAddressReducer,
  environmental,
  ownerContractor: ownerContractorReducer,
  projectDirectory,
  ownerArchitect: ownerArchitectReducer,
  constructionSchedule: constructionScheduleReducer,
  budgetContingency,
  projectSetting: projectSettingReducer,
  reportMonitoring: reportMonitoringReducer,
  reportChoices: reportChoicesReducer,
  reportType: reportTypeReducer,
  executiveSummary,
  budgetSummary: budgetSummaryReducer,
  reportTrackers,
  contingency,
  constructionSummary: constructionSummaryReducer,
  constructionScheduleReport,
  permitsEntitlements,
  thirdPartyReport: thirdPartyReportReducer,
  photographs: photographsReducer,
  appendix,
  confirmation: confirmationReducer,
  globalDashboard,
  attachments,
  projectDashboard,
  reportAnIssue: supportReducer,
  coverLetterAndPhoto,
  clientAccess,
  clientAccessDashboard,
  projectLimitation,
  projectPhotos,
  issuesLog,
  rfpData: rfpDataReducer,
})

const rootReducer = (state, action) => {
  if (equal(action.type, 'USER_LOGGED_OUT')) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
