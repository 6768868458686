import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import { alpha } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useState, useCallback, useMemo } from 'react'
import { Delete } from '@mui/icons-material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import { DragIcon } from '../../../Assets/SVGs'
import { equal, length, ternary } from '../../../Utils/javascript'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { spinnerSize } from '../../../Utils/constant'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTButton from '../../../Shared/DKTButton'

export const useStyles = makeStyles(() => ({
  tableAttachment: {
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root': {
        padding: '8px 16px',
      },
    },
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        '&:first-child': {
          width: 20,
        },
        '&:last-child': {
          width: 20,
        },
        '&:nth-child(2)': {
          padding: 0,
        },
      },
      '&.Mui-selected': {
        backgroundColor: '#00000014',
        '&:hover': {
          backgroundColor: 'rgba(21, 100, 255, 0.12)',
        },
      },
    },
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 17,
    gap: 8,
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    hasOnlyViewPermission,
  } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead style={{ backgroundColor: '#E5E5E5' }}>
      <TableRow>
        <TableCell></TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
            disabled={hasOnlyViewPermission}
          />
        </TableCell>
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span">
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    handleDownloadPdf,
    selected,
    handleOpenCloseDeleteNotification,
    downloadId,
    loading,
  } = props
  const classes = useStyles()
  return (
    !!numSelected && (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity,
              ),
          }),
          minHeight: { xs: '56px' },
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : null}

        {numSelected > 0 && (
          <div className={classes.btnGroup}>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => handleOpenCloseDeleteNotification(true)}
              >
                <Delete color="error" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download Pdf">
              <IconButton onClick={() => handleDownloadPdf(selected)}>
                {loading && Array.isArray(downloadId) ? (
                  <DKTCircularProgress size={spinnerSize?.sm} />
                ) : (
                  <CloudDownloadIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Toolbar>
    )
  )
}

const AttachmentsTable = ({
  columns,
  data,
  setEdit,
  handleDownloadPdf,
  isFetchPdfListLoading,
  handleDeletePdf,
  downloadId,
  loading,
  hasOnlyViewPermission,
}) => {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(null)
  const [selected, setSelected] = useState([])
  const [deleteNotificationOpen, setDeleteNotificationOpen] = useState(false)

  const classes = useStyles()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }
  const handleClick = (event, id) => {
    const clickedElement = event.target
    const isCheckboxClicked =
      clickedElement.tagName === 'INPUT' && clickedElement.type === 'checkbox'
    if (isCheckboxClicked) {
      const selectedIndex = selected.indexOf(id)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }
      setSelected(newSelected)
    } else if (
      clickedElement.tagName !== 'path' &&
      clickedElement.tagName !== 'INPUT'
    ) {
      setEdit({})
    }
  }

  const handleOpenCloseDeleteNotification = useCallback(
    (status) => setDeleteNotificationOpen(status),
    [],
  )

  const isSelected = (id) => selected.indexOf(id) !== -1

  const visibleRows = React.useMemo(
    () => stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, data],
  )

  const handleDeleteAttachmentData = () => {
    handleDeletePdf(selected, setSelected)
    handleOpenCloseDeleteNotification(false)
  }

  const deleteNotificationAction = useMemo(
    () => (
      <>
        <DKTButton
          variant="contained"
          disableElevation
          onClick={() => handleOpenCloseDeleteNotification(false)}
        >
          No
        </DKTButton>
        <DKTButton variant="outlined" onClick={handleDeleteAttachmentData}>
          Yes
        </DKTButton>
      </>
    ),
    [handleDeletePdf, selected],
  )

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '30px' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            {...{
              handleDownloadPdf,
              handleOpenCloseDeleteNotification,
              selected,
              setSelected,
              downloadId,
              loading,
            }}
          />
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
            className={classes.tableAttachment}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
              columns={columns}
              hasOnlyViewPermission={hasOnlyViewPermission}
            />
            <Droppable droppableId="attachmentsList">
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {data && equal(length(data), 0) ? (
                    <TableCell
                      colSpan={6}
                      height="40px"
                      sx={{ textAlign: 'center', justifyContent: 'center' }}
                    >
                      {ternary(
                        isFetchPdfListLoading,
                        <DKTCircularProgress />,
                        ' Sorry, no matching results found',
                      )}
                    </TableCell>
                  ) : (
                    visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id)
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <Draggable
                          key={row.id}
                          draggableId={row.id.toString()}
                          index={index}
                          isDragDisabled={!row?.isDraggable}
                        >
                          {(provided) => (
                            <TableRow
                              hover
                              sx={{ backgroundColor: '#fff' }}
                              id={row.id}
                              onClick={(event) => handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <>
                                <TableCell>
                                  <Box
                                    style={{
                                      cursor: 'move',
                                      maxWidth: 'max-content',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <DragIcon />
                                  </Box>
                                </TableCell>
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                    disabled={hasOnlyViewPermission}
                                  />
                                </TableCell>
                                {columns?.map(({ id, renderCell }, index) => (
                                  <TableCell key={index}>
                                    {renderCell ? renderCell(row, id) : row[id]}
                                  </TableCell>
                                ))}
                              </>
                            </TableRow>
                          )}
                        </Draggable>
                      )
                    })
                  )}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </Paper>
      </Box>
      <DKTDialog
        open={deleteNotificationOpen}
        handleClose={() => handleOpenCloseDeleteNotification(false)}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
    </>
  )
}
export default AttachmentsTable
