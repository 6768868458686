import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Outlet, useNavigate } from 'react-router-dom'
import { Delete } from '@mui/icons-material'
import { InnerLayout } from '../../../Layout/rfp'
import DKTButton from '../../../Shared/DKTButton'
import { useStyles } from '../../../Styles/rfc.style'
import {
  clientRFPColumn,
  consultantRFPColumn,
  rfpSortFields,
} from '../../../Description/rfp.description'
import {
  deleteRfpProject,
  getRfpCompanyList,
  getRfpListData,
} from '../../../Redux/actions/rfp'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import { equal, isEmptyString, keys, ternary } from '../../../Utils/javascript'
import { emptyTableCellPlaceholder } from '../../../Description/reportMonitoring.description'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDeleteNotificationModal from '../../../Shared/DKTDeleteNotificationModal'
import CreateNewRFP from './CreateNewRFP'
import useForm from '../../../Hooks/useForm'
import { renderFormFields } from '../../../Utils/renderFormFields'
import { clientPortal } from '../../../Utils/constant'
import { getProjectChoices } from '../../../Redux/actions/choices'

const CustomToolbar = ({ values, handleChange, errors, formFields }) => (
  <>
    {renderFormFields({
      values,
      handleChange,
      errors,
      formFields,
    })}
  </>
)

const RFPDashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const setSelectedRows = useRef(() => {})
  const rfpToRemove = useRef(null)
  const [rfpList, setRfpList] = useState([])
  const [deleteNotificationOpen, setOpenDeleteNotification] = useState(false)
  const [isCreateNewRFPOpen, setIsCreateNewRFPOpen] = useState(false)

  const { rfpListData, isLoadingRfp, rfpCompanyList } = useSelector(
    ({ rfpData }) => rfpData,
  )

  const { choices } = useSelector(({ projectChoices }) => projectChoices)
  const { portalType } = useSelector(({ auth }) => auth)
  const { values, handleChange, errors } = useForm(rfpSortFields)
  const isClientPortal = equal(portalType, clientPortal)

  useEffect(() => {
    dispatch(
      getProjectChoices({
        fieldName: ternary(
          isClientPortal,
          'ClientSortByOptions',
          'ConsultantSortByOptions',
        ),
      }),
    )
    dispatch(getRfpCompanyList())
  }, [])

  useEffect(() => {
    dispatch(getRfpListData(values.sortByOptions))
  }, [dispatch, values.sortByOptions])

  useEffect(() => {
    setRfpList(rfpListData || '')
  }, [rfpListData])

  const actions = () => (
    <Stack justifyContent="flex-end" direction="row" pr={2}>
      <DKTButton onClick={() => setIsCreateNewRFPOpen(true)} disabled={false}>
        Create New RFP
      </DKTButton>
    </Stack>
  )

  const handleRedirect = (id) => {
    navigate(`/rfp/${id}/rfp-project`)
  }

  const handleClose = () => {
    setOpenDeleteNotification(false)
    setIsCreateNewRFPOpen(false)
  }

  const getAddressLink = (value) => {
    const foundAddress = rfpList?.find(
      (data) => data?.fullAddress === value,
    )?.link
    return foundAddress?.toString()
  }

  const customBodyRender = useCallback(
    (value, tableMeta) => {
      switch (tableMeta?.columnData?.name) {
        case 'projectName':
          return (
            <DKTButton
              variant="text"
              onClick={() => handleRedirect(tableMeta.rowData[0])}
              sx={{
                textAlign: 'left',
                paddingLeft: '0px !important',
                marginLeft: '0px',
              }}
            >
              {value}
            </DKTButton>
          )

        case 'fullAddress':
          return (
            <Typography
              variant="body2"
              color="primary"
              component="a"
              href={getAddressLink(value)}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              {value}
            </Typography>
          )
        case 'finalSelectedConsultant':
          return (
            tableMeta.rowData.at(-1) ||
            rfpCompanyList?.find(({ id }) => equal(id, value))?.companyName ||
            emptyTableCellPlaceholder
          )

        case 'rfpNotificationDate':
        case 'rfpDueDate':
        case 'rfpReturnedDate':
          return ternary(
            value,
            moment(value).format('MM/DD/YY'),
            emptyTableCellPlaceholder,
          )
        case 'daysUntilDue':
          // eslint-disable-next-line no-case-declarations
          const daysUntilDueValue = ternary(
            isEmptyString(value),
            emptyTableCellPlaceholder,
            ternary(value < 0, 'Closed', value),
          )
          return (
            <Typography
              sx={{
                color: ternary(value < 7, '#ff0000', 'inherit'),
              }}
            >
              {daysUntilDueValue}
            </Typography>
          )

        default:
          return emptyTableCellPlaceholder
      }
    },
    [getAddressLink, rfpCompanyList],
  )

  const customToolbarSelect = useCallback(
    (selectedRows, displayData, setSelectedTableRows) => {
      const rfpListDataIndex = keys(selectedRows.lookup)
      const selectedRfpIds = rfpListDataIndex.map(
        (rowToDelete) => rfpListData[rowToDelete]?.id,
      )
      setSelectedRows.current = setSelectedTableRows
      const setRfpToRemove = () => {
        rfpToRemove.current = selectedRfpIds
      }

      return (
        <Box>
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => {
              setRfpToRemove()
              setOpenDeleteNotification(true)
            }}
          >
            <Delete color="error" />
          </IconButton>
        </Box>
      )
    },
    [rfpListData],
  )

  const tableColumns = (
    isClientPortal ? clientRFPColumn : consultantRFPColumn
  )?.map((rfp) =>
    ternary(
      equal(rfp.name, 'projectName') ||
        equal(rfp.name, 'fullAddress') ||
        equal(rfp.name, 'rfpNotificationDate') ||
        equal(rfp.name, 'rfpDueDate') ||
        equal(rfp.name, 'daysUntilDue') ||
        equal(rfp.name, 'rfpReturnedDate') ||
        equal(rfp.name, 'finalSelectedConsultant'),
      { ...rfp, options: { ...rfp.options, customBodyRender } },
      rfp,
    ),
  )

  const renderCustomSort = () => (
    <Box className={classes.rfcSearchbar}>
      <CustomToolbar
        values={values}
        handleChange={handleChange}
        errors={errors}
        formFields={{
          sortByOptions: {
            ...rfpSortFields.sortByOptions,
            options:
              choices[
                ternary(
                  isClientPortal,
                  'ClientSortByOptions',
                  'ConsultantSortByOptions',
                )
              ],
          },
        }}
      />
    </Box>
  )

  const tableOptions = {
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    customToolbar: renderCustomSort,
    textLabels: {
      body: {
        noMatch: isLoadingRfp ? (
          <DKTCircularProgress />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
    selectableRows: isClientPortal,
    customToolbarSelect,
  }

  const handleDeleteTableRow = () => {
    const payload = {
      rfps: [...rfpToRemove.current],
    }
    dispatch(deleteRfpProject(payload))
    setOpenDeleteNotification(false)
    setSelectedRows.current([])
  }
  return (
    <InnerLayout actions={isClientPortal ? actions : undefined} maxWidth="100%">
      {equal(isLoadingRfp, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching RFPs List. Please try
            contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Box className={classes.rfcTableWrapper}>
            <DKTMuiDataTable
              data={rfpList}
              options={tableOptions}
              columns={tableColumns}
              className={`${!isClientPortal && classes.rfcTableClient}`}
            />
          </Box>
          <CreateNewRFP open={isCreateNewRFPOpen} handleClose={handleClose} />
          <DKTDeleteNotificationModal
            open={deleteNotificationOpen}
            handleClose={handleClose}
            handleDelete={handleDeleteTableRow}
          />
          <Outlet />
        </>
      )}
    </InnerLayout>
  )
}

export default RFPDashboard
