export const optionFields = ['ownerContractor']

export const formFields = {
  originalScheduleStart: {
    constructionStart: {
      label: 'Construction Start (A)',
      type: 'date',
      disabled: true,
      xs: 6,
      tooltip:
        'Reference Project → Project Info → Construction Schedule → Construction Start',
    },
  },
  originalSchedule: {
    contractCompletion: {
      label: 'Contract Completion (B)',
      type: 'date',
      disabled: true,
      xs: 6,
      tooltip:
        'Reference Project → Project Info → Owner Contractor → Contract Completion',
    },
    contractCompletionDurationCalendarDays: {
      label: 'Duration Calendar Days (B-A)',
      type: 'text',
      xs: 6,
      disabled: true,
    },
    scheduleCompletion: {
      label: 'Schedule Completion (C)',
      type: 'date',
      disabled: true,
      xs: 6,
      tooltip:
        'Reference Project → Project Info → Construction Schedule → Schedule Completion',
    },
    scheduleCompletionDurationCalendarDays: {
      label: 'Duration Calendar Days (C-A)',
      type: 'text',
      xs: 6,
      disabled: true,
    },
    lendersUwCompletion: {
      label: 'Lender’s UW Completion (D)',
      type: 'date',
      disabled: true,
      xs: 6,
      tooltip:
        'Reference Project → Project Info → Construction Schedule → Lender’s UW Completion',
    },
    lendersUwCompletionDurationCalendarDays: {
      label: 'Duration Calendar Days (D-A)',
      type: 'text',
      xs: 6,
      disabled: true,
    },
  },
  actualScheduleStart: {
    actualStart: {
      type: 'date',
      label: 'Actual Start NTP (A)',
      // isRequired: true,
      xs: 6,
    },
  },
  actualSchedule: {
    currentContractCompletion: {
      type: 'date',
      label: 'Current Contract Completion (B)',
      // isRequired: true,
      xs: 6,
    },
    currentContractCompletionDurationCalendarDays: {
      label: 'Duration Calendar Days (B-A)',
      type: 'text',
      xs: 6,
    },
    currentScheduleCompletion: {
      label: 'Current Schedule Completion (C)',
      // isRequired: true,
      type: 'date',
      xs: 6,
    },
    currentScheduleCompletionDurationCalendarDays: {
      label: 'Days Behind/(Ahead) (C-B)',
      type: 'text',
      xs: 6,
    },
    consultantsEstimatedCompletion: {
      label: 'Consultant’s Est. Completion (D)',
      // isRequired: true,
      type: 'date',
      xs: 6,
    },
    varianceCalendarDays: {
      label: 'Days Behind/(Ahead) (D-B)',
      type: 'text',
      xs: 6,
    },
  },
  comments: {
    comments: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 5,
      xs: 12,
    },
  },
}

// Phase tracker
export const phaseTrackerColumns = [
  {
    name: 'phaseDescription',
    label: 'Phase',
  },
  {
    name: 'buildings',
    label: '# Bldgs.',
  },
  {
    name: 'units',
    label: '# Units',
  },
  {
    name: 'originalStart',
    label: 'Original Start',
  },
  {
    name: 'originalFinish',
    label: 'Original Finish (A)',
  },
  {
    name: 'actualStart',
    label: 'Actual Start',
  },
  {
    name: 'actualFinish',
    label: 'Actual Finish (B)',
  },
  {
    name: 'delayDays',
    label: 'Delay Days (B-A)',
  },
]
export const phaseTrackerData = [
  {
    phaseDescription: 'Phase 1',
    buildings: '12',
    units: '34',
    originalStart: '2022-05-06',
    originalFinish: '2022-05-07',
    actualStart: '2022-05-08',
    actualFinish: '2022-05-09',
    delayDays: '05',
  },
  {
    phaseDescription: 'Phase 2',
    buildings: '12',
    units: '34',
    originalStart: '2022-05-06',
    originalFinish: '2022-05-07',
    actualStart: '2022-05-08',
    actualFinish: '2022-05-09',
    delayDays: '05',
  },
  {
    phaseDescription: 'Phase 3',
    buildings: '12',
    units: '34',
    originalStart: '2022-05-06',
    originalFinish: '2022-05-07',
    actualStart: '2022-05-08',
    actualFinish: '2022-05-09',
    delayDays: '05',
  },
]
export const phaseTrackerOptions = {
  sort: false,
  filter: false,
  print: false,
  download: false,
  viewColumns: false,
  search: false,
  selectableRows: 'none',
}
export const phaseTrackerFormFields = {
  phaseDescription: {
    type: 'text',
    label: 'Phase Description',
    isRequired: true,
  },
  buildings: {
    type: 'number',
    label: '# of Buildings',
    xs: 6,
  },
  units: {
    type: 'number',
    label: '# of Units',
    xs: 6,
  },
  originalStart: {
    type: 'date',
    label: 'Original Start',
    xs: 6,
  },
  originalFinish: {
    type: 'date',
    label: 'Original Finish (A) ',
    xs: 6,
  },
  actualStart: {
    type: 'date',
    label: 'Actual Start',
    xs: 6,
  },
  actualFinish: {
    type: 'date',
    label: 'Actual Finish (B)',
    xs: 6,
  },
}
