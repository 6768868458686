// Fetch contingency
export const FETCH_CONTINGENCY_REQUESTED = 'FETCH_CONTINGENCY_REQUESTED'
export const FETCH_CONTINGENCY_SUCCESS = 'FETCH_CONTINGENCY_SUCCESS'
export const FETCH_CONTINGENCY_FAILED = 'FETCH_CONTINGENCY_FAILED'

// Fetch contingency reference values
export const FETCH_CONTINGENCY_REFERENCE_VALUES_REQUESTED =
  'FETCH_CONTINGENCY_REFERENCE_VALUES_REQUESTED'
export const FETCH_CONTINGENCY_REFERENCE_VALUES_SUCCESS =
  'FETCH_CONTINGENCY_REFERENCE_VALUES_SUCCESS'
export const FETCH_CONTINGENCY_REFERENCE_VALUES_FAILED =
  'FETCH_CONTINGENCY_REFERENCE_VALUES_FAILED'

// Save contingency
export const SAVE_CONTINGENCY_REQUESTED = 'SAVE_CONTINGENCY_REQUESTED'
export const SAVE_CONTINGENCY_SUCCESS = 'SAVE_CONTINGENCY_SUCCESS'
export const SAVE_CONTINGENCY_FAILED = 'SAVE_CONTINGENCY_FAILED'

// Delete contingency
export const DELETE_CONTINGENCY_REQUESTED = 'DELETE_CONTINGENCY_REQUESTED'
export const DELETE_CONTINGENCY_SUCCESS = 'DELETE_CONTINGENCY_SUCCESS'
export const DELETE_CONTINGENCY_FAILED = 'DELETE_CONTINGENCY_FAILED'

// Import from last report
export const IMPORT_CONTINGENCY_FROM_LAST_REPORT_REQUESTED =
  'IMPORT_CONTINGENCY_FROM_LAST_REPORT_REQUESTED'
export const IMPORT_CONTINGENCY_FROM_LAST_REPORT_SUCCESS =
  'IMPORT_CONTINGENCY_FROM_LAST_REPORT_SUCCESS'
export const IMPORT_CONTINGENCY_FROM_LAST_REPORT_FAILED =
  'IMPORT_CONTINGENCY_FROM_LAST_REPORT_FAILED'
export const CLEAR_CONTINGENCY_REDUCER = 'CLEAR_CONTINGENCY_REDUCER'
