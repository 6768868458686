import {
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  styled,
  TextField,
} from '@mui/material'
import React, { memo } from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import DKTTooltip from './DKTTooltip'

import { equal, isArray, ternary } from '../Utils/javascript'
import DKTInfoBox from './DKTInfoBox'

const StyledInput = styled(InputBase)(({ theme }) => ({
  height: '32px',
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '&': {
    borderRadius: 0,
    position: 'relative',
    border: '1px solid #C4C4C4',
    fontSize: 16,
    width: '100%',
    paddingInline: '10px',
    backgroundColor: '#fff',
    transition: theme.transitions.create(['border-color']),
  },
  '&:focus': {
    borderColor: theme.palette.primary.main,
  },
}))
const StyledDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    height: '32px',
    borderRadius: 0,
    border: '1px solid #C4C4C4 !important',
    fontSize: 16,
    top: '-1px',
    transition: theme.transitions.create(['border-color']),
  },
  '& .MuiInputLabel-root': {
    top: '-18px !important',
  },
  '& .MuiInputBase-input': {
    padding: '6.5px 14px',
  },
  '&:focus': {
    borderColor: theme.palette.primary.main,
  },
}))
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    height: '32px',
    borderRadius: 0,
    border: '1px solid #C4C4C4 !important',
    fontSize: 16,
    top: '-1px',
    transition: theme.transitions.create(['border-color']),
  },
  '& .MuiInputLabel-root': {
    top: '-18px !important',
  },
  '& .MuiInputBase-input': {
    padding: '6.5px 14px',
  },
  '&:focus': {
    borderColor: theme.palette.primary.main,
  },
}))

const DKTInput = ({
  label,
  id = label?.toLowerCase(),
  name,
  type,
  error,
  className,
  showLabel = true,
  formControlProps,
  isRequired = false,
  tooltip,
  onChange,
  dateSx = {},
  ...rest
}) => {
  const { sx, ...restFormControlProps } = formControlProps || {}
  const renderLabel = () => (
    <InputLabel
      shrink
      htmlFor={id}
      required={isRequired}
      disabled={rest.disabled}
      sx={{
        fontWeight: 'medium',
        color: 'gray.extraDark',
        ...ternary(
          equal(type, 'date'),
          {
            top: '-18px',
          },
          {},
        ),
      }}
    >
      {label}
      {rest?.infoIcon && (
        <DKTInfoBox
          title={`${rest?.infoIcon}`}
          slotProps={{
            popper: {
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: '#062340',
                },
                '& .MuiTooltip-arrow:before': {
                  backgroundColor: '#062340',
                },
              },
            },
          }}
          sx={{
            ml: 1,
            cursor: 'pointer',
            height: 15,
            width: 15,
            float: 'right',
            marginTop: '5px',
          }}
        />
      )}
    </InputLabel>
  )

  return (
    <FormControl
      required={isRequired}
      className={className}
      variant="standard"
      sx={{
        width: '100%',
        ...(sx || {}),
        ...ternary(
          equal(type, 'date'),
          {
            marginTop: '20px !important',
          },
          {},
        ),
      }}
      {...restFormControlProps}
    >
      {showLabel &&
        ternary(
          tooltip,
          <DKTTooltip title={tooltip}>{renderLabel()}</DKTTooltip>,
          renderLabel(),
        )}
      {ternary(
        equal(type, 'date'),
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StyledDatePicker
            views={['year', 'month', 'day']}
            id={id}
            inputFormat="MM/DD/YYYY"
            renderInput={(params) => {
              const inputProps = {
                value: rest.value ?? '',
                disabled: rest.disabled,
                placeholder: 'MM/DD/YYYY',
                type: 'tel',
                onChange: (e) => {
                  const valueData = e.target.value || ''
                  const maskDigit = valueData
                    .replace(/\D/g, '')
                    .match(/(\d{0,2})(\d{0,2})(\d{0,4})/)
                  const maskInputValue = !maskDigit[2]
                    ? maskDigit[1]
                    : `${maskDigit[1]}/${maskDigit[2]}${
                        maskDigit[3] ? `/${maskDigit[3]}` : ''
                      }`
                  onChange({
                    target: { name, value: maskInputValue },
                  })
                },
              }
              return (
                <StyledTextField
                  inputProps={inputProps}
                  className={className}
                  disabled={rest.disabled}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={params.InputProps}
                  inputRef={params.inputRef}
                  sx={dateSx}
                />
              )
            }}
            onChange={(value) => {
              onChange({
                target: { name, value: moment(value).format('MM/DD/YYYY') },
              })
            }}
            sx={dateSx}
            {...rest}
          />
        </LocalizationProvider>,
        <StyledInput
          type={type}
          id={id}
          name={name}
          onChange={onChange}
          {...rest}
          onWheel={(e) => e.target.blur()}
        />,
      )}
      {isArray(error) ? (
        error.map((errorMessage, index) => (
          <FormHelperText error={!!error} key={index}>
            {errorMessage}
          </FormHelperText>
        ))
      ) : (
        <FormHelperText error={!!error}>{error}</FormHelperText>
      )}
    </FormControl>
  )
}

export default memo(DKTInput)
