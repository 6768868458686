// Fetch appendix
export const FETCH_APPENDIX_REQUESTED = 'FETCH_APPENDIX_REQUESTED'
export const FETCH_APPENDIX_SUCCESS = 'FETCH_APPENDIX_SUCCESS'
export const FETCH_APPENDIX_FAILED = 'FETCH_APPENDIX_FAILED'

// Save appendix
export const SAVE_APPENDIX_REQUESTED = 'SAVE_APPENDIX_REQUESTED'
export const SAVE_APPENDIX_SUCCESS = 'SAVE_APPENDIX_SUCCESS'
export const SAVE_APPENDIX_FAILED = 'SAVE_APPENDIX_FAILED'

// Import last report comments

export const IMPORT_APPENDIX_COMMENTS_COMMENTS_REQUESTED =
  'IMPORT_APPENDIX_COMMENTS_COMMENTS_REQUESTED'
export const IMPORT_APPENDIX_REPORT_COMMENTS_SUCCESS =
  'IMPORT_APPENDIX_REPORT_COMMENTS_SUCCESS'
export const IMPORT_APPENDIX_REPORT_COMMENTS_FAILED =
  'IMPORT_APPENDIX_REPORT_COMMENTS_FAILED'

// clear appendix
export const CLEAR_APPENDIX = 'CLEAR_APPENDIX'
