import { equal, ternary } from '../../Utils/javascript'
import {
  CREATE_THIRD_PARTY_REPORTS_FAILED,
  CREATE_THIRD_PARTY_REPORTS_REQUESTED,
  CREATE_THIRD_PARTY_REPORTS_SUCCESS,
  CREATE_THIRD_PARTY_REPORTS_SECTION_FAILED,
  CREATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED,
  CREATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS,
  DELETE_THIRD_PARTY_REPORTS_FAILED,
  DELETE_THIRD_PARTY_REPORTS_REQUESTED,
  DELETE_THIRD_PARTY_REPORTS_SUCCESS,
  FETCH_THIRD_PARTY_REPORTS_LIST_FAILED,
  FETCH_THIRD_PARTY_REPORTS_LIST_REQUESTED,
  FETCH_THIRD_PARTY_REPORTS_LIST_SUCCESS,
  FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_FAILED,
  FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_REQUESTED,
  FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_SUCCESS,
  IMPORT_THIRD_PARTY_REPORT_FAILED,
  IMPORT_THIRD_PARTY_REPORT_REQUESTED,
  IMPORT_THIRD_PARTY_REPORT_SUCCESS,
  UPDATE_THIRD_PARTY_REPORTS_FAILED,
  UPDATE_THIRD_PARTY_REPORTS_REQUESTED,
  UPDATE_THIRD_PARTY_REPORTS_SUCCESS,
  UPDATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED,
  UPDATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS,
  UPDATE_THIRD_PARTY_REPORTS_SECTION_FAILED,
  CLEAR_THIRD_PARTY_REPORT_LIST,
} from '../constants/thirdPartyReport'

const initialState = {
  isCreateAndUpdateThirdPartyReportLoading: false,
  isThirdPartyReportLoading: true,
  thirdPartyReportList: [],
  thirdPartyReportError: null,
  isThirdPartyReportDeleting: false,
  // Third party report section
  isCreateThirdPartyReportSectionLoading: false,
  isThirdPartyReportSectionLoading: true,
  thirdPartyReportSectionList: [],
  thirdPartyReportSectionError: null,
  // Import from last report
  isImporting: {},
}

export const thirdPartyReportReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch third party reports
    case FETCH_THIRD_PARTY_REPORTS_LIST_REQUESTED:
      return {
        ...state,
        isThirdPartyReportLoading: true,
      }
    case FETCH_THIRD_PARTY_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        isThirdPartyReportLoading: false,
        thirdPartyReportList: action?.payload,
        thirdPartyReportError: null,
      }
    case FETCH_THIRD_PARTY_REPORTS_LIST_FAILED:
      return {
        ...state,
        isThirdPartyReportLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        thirdPartyReportList: [],
      }
    // Create third party report
    case CREATE_THIRD_PARTY_REPORTS_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateThirdPartyReportLoading: true,
      }
    case CREATE_THIRD_PARTY_REPORTS_SUCCESS:
      return {
        ...state,
        isCreateAndUpdateThirdPartyReportLoading: false,
        thirdPartyReportList: action.payload,
        thirdPartyReportError: null,
      }
    case CREATE_THIRD_PARTY_REPORTS_FAILED:
      return {
        ...state,
        isCreateAndUpdateThirdPartyReportLoading: false,
        thirdPartyReportError: action.payload,
      }
    // Update third party report
    case UPDATE_THIRD_PARTY_REPORTS_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateThirdPartyReportLoading: true,
      }
    case UPDATE_THIRD_PARTY_REPORTS_SUCCESS:
      return {
        ...state,
        isCreateAndUpdateThirdPartyReportLoading: false,
        thirdPartyReportList: action?.payload,
        thirdPartyReportError: null,
      }
    case UPDATE_THIRD_PARTY_REPORTS_FAILED:
      return {
        ...state,
        isCreateAndUpdateThirdPartyReportLoading: false,
        thirdPartyReportError: action.payload,
      }
    // Delete third party report
    case DELETE_THIRD_PARTY_REPORTS_REQUESTED:
      return {
        ...state,
        isThirdPartyReportDeleting: true,
      }
    case DELETE_THIRD_PARTY_REPORTS_SUCCESS:
      return {
        ...state,
        thirdPartyReportList: action?.payload,
        isThirdPartyReportDeleting: false,
      }
    case DELETE_THIRD_PARTY_REPORTS_FAILED:
      return {
        ...state,
        isThirdPartyReportDeleting: false,
      }

    /** *******************************
      Third party report section
    ******************************** */
    // Fetch third party report section list
    case FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_REQUESTED:
      return {
        ...state,
        isThirdPartyReportSectionLoading: true,
      }
    case FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_SUCCESS:
      return {
        ...state,
        isThirdPartyReportSectionLoading: false,
        thirdPartyReportSectionList: action?.payload,
        thirdPartyReportSectionError: null,
      }
    case FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_FAILED:
      return {
        ...state,
        isThirdPartyReportSectionLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        thirdPartyReportSectionList: [],
      }
    // Create third party report
    case CREATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED:
      return {
        ...state,
        isCreateThirdPartyReportSectionLoading: true,
      }
    case CREATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS:
      return {
        ...state,
        isCreateThirdPartyReportSectionLoading: false,
        thirdPartyReportSectionList: [
          ...state.thirdPartyReportSectionList,
          action.payload,
        ],
        thirdPartyReportSectionError: null,
      }
    case CREATE_THIRD_PARTY_REPORTS_SECTION_FAILED:
      return {
        ...state,
        isCreateThirdPartyReportSectionLoading: false,
        thirdPartyReportSectionError: action.payload,
      }

    /** *******************************
      Import from last report
    ******************************** */
    // Import from last report
    case IMPORT_THIRD_PARTY_REPORT_REQUESTED:
      return {
        ...state,
        isImporting: {
          ...state.isImporting,
          [action.payload]: true,
        },
      }
    case IMPORT_THIRD_PARTY_REPORT_SUCCESS:
      return {
        ...state,
        imported: {
          ...state.imported,
          [action.payload.query]: action.payload.data,
        },
        isImporting: {
          ...state.isImporting,
          [action.payload.query]: false,
        },
      }
    case IMPORT_THIRD_PARTY_REPORT_FAILED:
      return {
        ...state,
        importError: action.payload.data,
        isImporting: {
          ...state.isImporting,
          [action.payload.query]: false,
        },
      }
    // update third party report
    case UPDATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED:
      return {
        ...state,
        isCreateThirdPartyReportSectionLoading: true,
      }
    case UPDATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS:
      return {
        ...state,
        isCreateThirdPartyReportSectionLoading: false,
        thirdPartyReportSectionList: action.payload,
        thirdPartyReportSectionError: null,
      }
    case UPDATE_THIRD_PARTY_REPORTS_SECTION_FAILED:
      return {
        ...state,
        isCreateThirdPartyReportSectionLoading: false,
        thirdPartyReportSectionError: action.payload,
      }
    case CLEAR_THIRD_PARTY_REPORT_LIST:
      return {
        ...state,
        thirdPartyReportList: [],
      }
    default:
      return state
  }
}
