import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material'
import React, { memo } from 'react'
import { isArray } from '../Utils/javascript'

const DKTCheckbox = ({
  checked,
  handleChange,
  label,
  error,
  className,
  showCheckBox = true,
  sx,
  ...rest
}) => (
  <FormControl className={className} sx={sx}>
    {showCheckBox && (
      <Checkbox checked={checked} onChange={handleChange} {...rest}></Checkbox>
    )}
    <InputLabel shrink sx={rest?.InputLabelProps}>
      {label}
    </InputLabel>
    {isArray(error)
      ? error.map((errorMessage, index) => (
          <FormHelperText error={!!error} key={index}>
            {errorMessage}
          </FormHelperText>
        ))
      : error && <FormHelperText error={!!error}>{error}</FormHelperText>}
  </FormControl>
)

export default memo(DKTCheckbox)
