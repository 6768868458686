import {
  emailValidation,
  isUrl,
  mobileNumber,
  zipCodeValidation,
} from '../Utils/regex'

export const formFields = {
  companyInfo: {
    companyName: {
      type: 'text',
      label: 'Company Name',
      isRequired: true,
    },
    address: {
      type: 'text',
      label: 'Address',
      isRequired: true,
    },
    city: {
      type: 'text',
      label: 'City',
      isRequired: true,
    },
    state: {
      type: 'select',
      label: 'State',
      isRequired: true,
      isDynamicOptions: true,
      xs: 6,
      placeholder: 'Select',
      displayEmpty: true,
    },
    zipCode: {
      type: 'text',
      xs: 6,
      label: 'Zip Code',
      isRequired: true,
      validate: [zipCodeValidation],
    },
    country: {
      type: 'text',
      label: 'Country',
      isRequired: true,
      placeholder: 'Country',
    },
    websiteEmail: {
      type: 'text',
      label: 'Company Email Address',
      isRequired: true,
      validate: [emailValidation],
    },
    websiteUrl: {
      type: 'text',
      label: 'Website URL',
      isRequired: true,
      validate: [isUrl],
    },
    workPhoneNumber: {
      type: 'text',
      label: 'Work Phone Number',
      isRequired: true,
      validate: [mobileNumber],
    },
  },
  servicesAndLocations: {
    serviceOffered: {
      type: 'select',
      label: 'Services Offered',
      multiple: true,
      isRequired: true,
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      isAdminAccess: true,
    },
    serviceStateLocation: {
      type: 'select',
      label: 'Services State Locations',
      multiple: true,
      isRequired: true,
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      isAdminAccess: true,
    },
    serviceUsTerritories: {
      type: 'select',
      label: 'Services US Territories',
      multiple: true,
      isRequired: false,
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      isAdminAccess: true,
    },
  },
}

export const initialLocationField = {
  otherOfficeCity: {
    type: 'text',
    label: 'Enter City',
    isRequired: true,
    xs: 6,
  },
  otherOfficeState: {
    type: 'select',
    label: 'State',
    isRequired: true,
    isDynamicOptions: true,
    xs: 4,
    placeholder: 'Select',
    displayEmpty: true,
  },
}

export const accordionData = {
  companyInformation: {
    accordionTitle: 'Company Information',
    idTag: 'compInfo',
  },
  keyPersonnel: {
    accordionTitle: 'Key Personnel',
    idTag: 'keyPersonal',
  },
}

export const keyPersonnelFormFields = {
  primaryContact: {
    privacyContactName: {
      type: 'text',
      label: 'Name',
    },
    privacyContactTitle: {
      type: 'text',
      label: 'Title',
    },
    workPhoneNumber: {
      type: 'text',
      label: 'Work Phone (W)',
      validate: [mobileNumber],
    },
    cellPhone: {
      type: 'text',
      label: 'Cell Phone (C)',
      validate: [mobileNumber],
    },
    email: {
      type: 'text',
      label: 'Email Address',
      validate: [emailValidation, 'Invalid email address'],
    },
  },
  contactForRFPs: {
    personnelName: {
      type: 'select',
      label: 'Personnel Name',
      isRequired: true,
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      xs: 10,
    },
  },
}
