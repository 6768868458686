import { Box, Grid, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  columns,
  formFields,
  options,
  optionsFields,
} from '../../../Description/issuesLog.description'
import { emptyTableCellPlaceholder } from '../../../Description/reportMonitoring.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import { getProjectChoices } from '../../../Redux/actions/choices'
import { getIssuesLogData } from '../../../Redux/actions/issuesLog'
import { CLEAR_ISSUES_LOG } from '../../../Redux/constants/issuesLog'
import DKTButton from '../../../Shared/DKTButton'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTInput from '../../../Shared/DKTInput'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTTooltip from '../../../Shared/DKTTooltip'
import { useStyles } from '../../../Styles/issuesLog.style'
import { SA, actionDisabled, publish } from '../../../Utils/constant'
import { entries, equal, htmlToText, ternary } from '../../../Utils/javascript'
import AddIssue from './AddIssue'

const IssuesLog = () => {
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editIssueData, setEditIssueData] = useState({})
  const { values, setFieldValue, handleChange } = useForm(
    formFields.issuesLogFilter,
    CLEAR_ISSUES_LOG,
  )

  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { isFetchingIssuesLogLoading, issuesLogData, canAddIssueLog } =
    useSelector(({ issuesLog }) => issuesLog)
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)
  const { choices: dynamicChoices } = useSelector(
    ({ projectChoices }) => projectChoices,
  )
  const classes = useStyles()
  const { slug: projectId, reportId } = useParams()
  const dispatch = useDispatch()

  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+projectId]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission =
    isGlobalViewer ||
    isProjectViewer ||
    ternary(
      reportData?.isSecondLastReport,
      equal(reportData?.lastReportStatus, publish),
      !reportData?.isLastReport,
    )

  useEffect(() => {
    dispatch(
      getProjectChoices({
        fieldName: optionsFields?.join(','),
      }),
    )
  }, [])

  useEffect(() => {
    if (values.issueLogFilter)
      dispatch(
        getIssuesLogData({
          reportId,
          ocId: choices?.ownerContractor[0]?.id,
          projectId,
          issueFilter: values.issueLogFilter,
        }),
      )
  }, [values?.issueLogFilter])

  useEffect(() => {
    setDynamicOptions({
      ...dynamicChoices,
      status: dynamicChoices?.issueStatus,
    })
    setFieldValue('issueLogFilter', 'AI')
  }, [dynamicChoices])

  const actionList = [{ label: 'Delete', onClick: () => {} }]

  const handleModalOpen = (status) => setIsModalOpen(status)

  const handleEditIssue = (id) => {
    handleModalOpen(true)
    setEditIssueData(() => issuesLogData.find((issue) => equal(issue?.id, id)))
  }
  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" gap={2}>
          <>
            <DKTButtonSelect
              options={actionList}
              disabled={hasOnlyViewPermission || actionDisabled}
            />
            <DKTButton disabled={hasOnlyViewPermission || actionDisabled}>
              Save
            </DKTButton>
          </>
        </Stack>
      </Box>
    </>
  )
  const mapFormFields = ({ formFields, values, errors, handleChange }) =>
    entries(formFields).map(
      (
        [
          name,
          { isRequired, isDynamicOptions, defaultValue, options, ...formField },
        ],
        index,
      ) => {
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <Grid
            key={index}
            item
            xs={formField.xs ?? 12}
            lg={formField.lg ?? 12}
          >
            <FormControl
              {...formField}
              options={ternary(
                isDynamicOptions,
                dynamicOptions[name] || [],
                options,
              )}
              isRequired={isRequired}
              id={name}
              name={name}
              value={(values && values[name]) || defaultValue || ''}
              onChange={handleChange}
              error={errors && errors[name]}
              disabled={ternary(
                equal(name, 'issueLogFilter'),
                equal(isFetchingIssuesLogLoading, 'FAILED'),
                hasOnlyViewPermission || formField.disabled,
              )}
            />
          </Grid>
        )
      },
    )

  const renderFormFields = ({
    formFields,
    values,
    errors,
    handleChange,
    className,
  }) => (
    <Grid container width="100%" spacing={2} className={className && className}>
      {mapFormFields({ formFields, values, errors, handleChange })}
    </Grid>
  )

  const customBodyRender = useCallback(
    (value, tableMeta) => {
      const [title] = tableMeta.rowData.slice(-1)
      const statusColor = ternary(
        equal(value, 'Open-Critical') || equal(value, 'Open'),
        '#FF0000',
        'inherit',
      )
      switch (tableMeta?.columnData?.name) {
        case 'description':
          return (
            <>
              <Typography
                px={2}
                component="div"
                onClick={() =>
                  !hasOnlyViewPermission &&
                  handleEditIssue(tableMeta?.rowData[0])
                }
                style={{ cursor: 'pointer' }}
                color={!hasOnlyViewPermission && 'primary'}
              >
                {title}
              </Typography>
              <DKTTooltip title={htmlToText(value)} placement="bottom-start">
                <Typography
                  px={2}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    display: '-webkit-box',
                    fontSize: '14px',
                    color: '#161616',
                    maxWidth: '775px',
                  }}
                >
                  {htmlToText(value)}
                </Typography>
              </DKTTooltip>
            </>
          )
        case 'resolutionPeriodFullform':
          return (
            <Typography fontSize={14}>
              {value || emptyTableCellPlaceholder}
            </Typography>
          )
        case 'statusFullform':
          return (
            <Typography color={statusColor} fontSize={14}>
              {value}
            </Typography>
          )

        default:
          return emptyTableCellPlaceholder
      }
    },
    [handleEditIssue],
  )

  const tableOptions = {
    ...options,
    textLabels: {
      body: {
        noMatch: isFetchingIssuesLogLoading ? (
          <DKTCircularProgress />
        ) : (
          <Typography>Sorry, there is no matching data to display</Typography>
        ),
      },
    },
    customSearch: (searchQuery, currentRow) => {
      const lowercaseSearchQuery = searchQuery.toLowerCase()
      let isFound = false
      currentRow.forEach((col) => {
        if (
          col &&
          col.toString().toLowerCase().indexOf(lowercaseSearchQuery) >= 0
        ) {
          isFound = true
        }
      })
      return isFound
    },
  }

  const tableColumns = useMemo(
    () =>
      columns?.map((project) =>
        ternary(
          equal(project.name, 'statusFullform') ||
            equal(project.name, 'resolutionPeriodFullform') ||
            equal(project.name, 'description'),
          { ...project, options: { ...project.options, customBodyRender } },
          project,
        ),
      ),
    [columns, customBodyRender],
  )

  return (
    <InnerLayout
      contentTitle="Issues Log"
      actions={actions}
      maxWidth="md"
      isShowMenu
      headingSx={{
        marginTop: 5,
      }}
      boxSx={{ position: 'relative' }}
    >
      {renderFormFields({
        formFields: formFields.issuesLogFilter,
        values,
        handleChange,
        className: classes.issueFilter,
      })}
      {equal(isFetchingIssuesLogLoading, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isFetchingIssuesLogLoading, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Issues Log data. Please try
            contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Grid>
            <DKTMuiDataTable
              data={issuesLogData || []}
              options={tableOptions}
              columns={tableColumns}
              className={classes.issueTable}
            />
            <DKTTooltip
              title={`${ternary(
                !canAddIssueLog,
                'Add Issue button disabled if subsequent report includes new issues.',
                '',
              )}`}
              placement="top-start"
            >
              <Typography component="span" sx={{ cursor: 'pointer' }}>
                <DKTButton
                  variant="text"
                  color="primary"
                  sx={{
                    '&.MuiButtonBase-root': {
                      textTransform: 'initial',
                      gap: 1,
                      padding: '8px 12px',
                      height: 'unset',
                      lineHeight: '1',
                      marginTop: 1.5,
                      '& .MuiButton-startIcon': { margin: 0 },
                    },
                  }}
                  startIcon={
                    <CircledPlusIcon
                      disabled={
                        hasOnlyViewPermission ||
                        isFetchingIssuesLogLoading ||
                        !canAddIssueLog
                      }
                    />
                  }
                  onClick={() => handleModalOpen(true)}
                  disabled={
                    hasOnlyViewPermission ||
                    isFetchingIssuesLogLoading ||
                    !canAddIssueLog
                  }
                >
                  Add Issue
                </DKTButton>
              </Typography>
            </DKTTooltip>
          </Grid>
          <AddIssue
            open={isModalOpen}
            handleClose={() => handleModalOpen(false)}
            {...{
              renderFormFields,
              editIssueData,
              setEditIssueData,
              ownerContractor: choices?.ownerContractor[0]?.id,
              hasOnlyViewPermission,
              issueFilter: values?.issueLogFilter,
            }}
          />
        </>
      )}
    </InnerLayout>
  )
}

export default IssuesLog
