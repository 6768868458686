import { BOOLEAN } from '../Utils/constant'

export const formFields = {
  constructionSummaryHeaderFields: {
    summaryType: {
      type: 'select',
      label: 'Summary Type',
      placeholder: 'Select',
      displayEmpty: true,
      xs: 4,
      lg: 3,
      options: [
        {
          label: 'Narrative-based',
          value: '1',
        },
        {
          label: 'Phase-based',
          value: '2',
        },
        {
          label: 'Freeform',
          value: '3',
        },
      ],
    },
    useForReport: {
      type: 'checkbox',
      valueType: BOOLEAN,
      fields: [
        {
          label: 'Use for report',
          value: 'useForReport',
          labelledCheckbox: true,
        },
      ],
    },
  },
}

export const narrativeBasedHeader = ['Description of Work', 'Status', 'Summary']

export const options = [
  { label: 'Not Started', value: '0' },
  { label: 'In Progress ', value: '1' },
  { label: 'Complete', value: '2' },
  { label: 'N/A', value: '3' },
]

export const dynamicColor = {
  0: 'white',
  1: '#FFE816',
  2: '#1564FF',
  3: '#E5E5E5',
}

export const manpowerOptions = {
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}

export const manpowerColumns = [
  {
    name: 'nameOfTrade',
    label: 'Name Of Trade',
    options: {
      sort: false,
    },
  },
  {
    name: 'workersOnSite',
    label: '# Workers On Site',
    options: {
      sort: false,
    },
  },
]
