import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_SITE_ADDRESS,
  CREATE_SITE_ADDRESS_FAILED,
  CREATE_SITE_ADDRESS_REQUESTED,
  CREATE_SITE_ADDRESS_SUCCESS,
  DELETE_SITE_ADDRESS_FAILED,
  DELETE_SITE_ADDRESS_REQUESTED,
  DELETE_SITE_ADDRESS_SUCCESS,
  FETCH_SITE_ADDRESS_LIST_FAILED,
  FETCH_SITE_ADDRESS_LIST_REQUESTED,
  FETCH_SITE_ADDRESS_LIST_SUCCESS,
} from '../constants/siteAddress'

const initialState = {
  siteAddressList: [],
  isSiteAddressListLoading: false,
  siteAddressListError: '',
  isCreateSiteAddressLoading: false,
  createSiteAddressError: '',
  isDeleteSiteAddressLoading: false,
  deleteSiteAddressError: '',
}

export const siteAddressReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Site address list
    case FETCH_SITE_ADDRESS_LIST_REQUESTED:
      return {
        ...state,
        isSiteAddressListLoading: true,
      }
    case FETCH_SITE_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        siteAddressList: action.payload,
        isSiteAddressListLoading: false,
      }
    case FETCH_SITE_ADDRESS_LIST_FAILED:
      return {
        ...state,
        isSiteAddressListLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        siteAddressListError: action.payload?.data,
      }
    // Create site address list
    case CREATE_SITE_ADDRESS_REQUESTED:
      return {
        ...state,
        isCreateSiteAddressLoading: true,
      }
    case CREATE_SITE_ADDRESS_SUCCESS:
      return {
        ...state,
        siteAddressList: action.payload,
        isCreateSiteAddressLoading: false,
      }
    case CREATE_SITE_ADDRESS_FAILED:
      return {
        ...state,
        isCreateSiteAddressLoading: false,
        createSiteAddressError: action.payload,
      }
    // Delete site address list
    case DELETE_SITE_ADDRESS_REQUESTED:
      return {
        ...state,
        isDeleteSiteAddressLoading: true,
      }
    case DELETE_SITE_ADDRESS_SUCCESS:
      return {
        ...state,
        siteAddressList: action.payload,
        isDeleteSiteAddressLoading: false,
      }
    case DELETE_SITE_ADDRESS_FAILED:
      return {
        ...state,
        isDeleteSiteAddressLoading: false,
        deleteSiteAddressError: action.payload,
      }
    case CLEAR_SITE_ADDRESS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
