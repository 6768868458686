import { Delete } from '@mui/icons-material'
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  siteAddressFields,
  siteAddressHeader,
} from '../../../Description/siteAddress.description'
import useForm from '../../../Hooks/useForm'
import {
  createAndUpdateSiteAddressList,
  deleteSiteAddress,
} from '../../../Redux/actions/siteAddress'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTInput from '../../../Shared/DKTInput'
import DKTSelect from '../../../Shared/DKTSelect'
import { useStyles } from '../../../Styles/projects.style'
import { entries, equal, keys, ternary } from '../../../Utils/javascript'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import DKTForm from '../../../Shared/DKTForm'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { CLEAR_SITE_ADDRESS } from '../../../Redux/constants/siteAddress'

const SiteAddress = ({
  handleClose,
  open,
  dynamicOptions,
  hasOnlyViewPermission,
}) => {
  const classes = useStyles()
  const { slug } = useParams()
  const dispatch = useDispatch()
  const [siteAddress, setSiteAddress] = useState([])
  const [deleteNotificationOpen, setOpenDeleteNotification] = React.useState({
    popupOpen: false,
  })
  const [selectedSiteAddress, setSelectedSiteAddress] = useState(null)
  const [dummyData, setDummyData] = useState(null)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)

  const {
    siteAddressList,
    isCreateSiteAddressLoading,
    isSiteAddressListLoading,
  } = useSelector(({ siteAddress }) => siteAddress)

  const { values, errors, handleChange, setValues } = useForm(
    siteAddressFields,
    CLEAR_SITE_ADDRESS,
  )

  useEffect(() => {
    let siteAddressData = []
    let addressObj = {}
    siteAddressList?.forEach((address, index) => {
      const { id } = address
      const updatedLocation = handleAddAddress(index, id, siteAddressData)
      siteAddressData = updatedLocation
      addressObj = {
        ...addressObj,
        [`description.${id}`]: address?.description,
        [`address.${id}`]: address?.address,
        [`city.${id}`]: address?.city,
        [`state.${id}`]: address?.state,
        [`zipCode.${id}`]: address?.zipCode,
      }
    })
    setSiteAddress(siteAddressData)
    setValues((prevValues) => ({
      ...prevValues,
      ...addressObj,
    }))
    setDummyData((prevValues) => ({
      ...prevValues,
      ...addressObj,
    }))
  }, [siteAddressList, setValues])

  useEffect(() => {
    const cloneValues = { ...values }
    delete cloneValues.city
    delete cloneValues.state
    setIsUnSavedChanges(
      JSON.stringify(dummyData) !== JSON.stringify(cloneValues),
    )
  }, [siteAddressList, values])

  const handleAddAddress = (index, addressId, siteAddressData = false) => {
    const address = siteAddressData || [...siteAddress]
    address[index] = {
      data: siteAddressFields,
      id: ternary(siteAddressData, `${addressId}`, `${new Date().valueOf()}`),
    }
    if (!siteAddressData) setSiteAddress([...address])
    return [...address]
  }

  const removeLocationField = (id) => {
    setOpenDeleteNotification({ popupOpen: true, id })
  }
  const handleDelete = (id) => {
    const cloneValues = { ...values }
    setSelectedSiteAddress({ value: cloneValues, id })
    const body = {
      siteAddress: [id],
    }
    dispatch(deleteSiteAddress(body, slug))
    delete cloneValues[`description.${id}`]
    delete cloneValues[`address.${id}`]
    delete cloneValues[`city.${id}`]
    delete cloneValues[`state.${id}`]
    delete cloneValues[`zipCode.${id}`]
    setValues(cloneValues)
    setDummyData(cloneValues)
  }

  const afterDelete = useCallback((id) => {
    setSiteAddress((siteAddress) =>
      siteAddress.filter((address) => !equal(address.id, id)),
    )
    handleClosePopup()
  }, [])

  useEffect(() => {
    if (selectedSiteAddress) {
      afterDelete(selectedSiteAddress?.id)
    }
  }, [afterDelete, selectedSiteAddress])

  const handleClosePopup = () => {
    setOpenDeleteNotification(false)
  }

  const handleSaveAddress = () => {
    if (!keys(errors)?.length) {
      const arr2obj = (arr) => {
        const obj = {}
        arr.forEach((v) => {
          const key = v[0]?.split('.')[0]
          const value = v[1]
          obj[key] = value
          obj.id = v[0]?.split('.')[1]
        })
        return obj
      }
      const cloneValues = entries(values)
      const addressKeyAry = keys(values)
      const addressIdAry = addressKeyAry?.map((id) => id?.split('.')[1])
      const uniqueIdAry = [...new Set(addressIdAry)]?.filter(Boolean)
      const body = uniqueIdAry
        ?.map((ele) =>
          cloneValues?.filter((flt) => equal(flt[0]?.split('.')[1], ele)),
        )
        ?.map((add) => arr2obj(add))
      setIsUnSavedChanges(false)
      dispatch(createAndUpdateSiteAddressList(body, slug))
    } else {
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }

  const deleteNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={handleClosePopup}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={() => handleDelete(deleteNotificationOpen?.id)}
      >
        Yes
      </DKTButton>
    </>
  )
  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          { isRequired, isDynamicOptions, defaultValue, options, ...formField },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        const className = { className: '' }
        if (formField.className) {
          className.className = classes[formField.className]
        }
        return (
          <TableCell key={index}>
            <DKTForm autoComplete="off">
              <FormControl
                {...formField}
                disabled={hasOnlyViewPermission}
                options={ternary(
                  isDynamicOptions,
                  dynamicOptions?.state || [],
                  options,
                )}
                renderFormFields={options}
                isRequired={isRequired}
                id={name}
                name={name}
                value={(values && values[name]) || ''}
                onChange={(e) => {
                  handleChange(e)
                }}
                error={errors[name]}
                {...className}
              />
            </DKTForm>
          </TableCell>
        )
      },
    )

  const renderFormFields = (formFields) => (
    <TableBody>
      {formFields?.map((formField) => (
        <TableRow
          key={formField?.id}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}
        >
          {mapFormFields({
            formFields: formField?.data,
            makeFieldNameUnique: true,
            id: formField?.id,
          })}
          {!hasOnlyViewPermission && (
            <TableCell>
              <IconButton
                color="error"
                onClick={() => removeLocationField(formField?.id)}
              >
                <Delete />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    handleClose()
  }
  const handleCloseAddBuilding = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    handleClose()
  }
  return (
    <>
      <DKTDialog
        open={open}
        handleClose={handleCloseAddBuilding}
        title="Site Addresses"
        maxWidth="md"
      >
        {equal(isSiteAddressListLoading, true) ? (
          <Stack alignItems="center" justifyContent="center">
            <DKTCircularProgress />
          </Stack>
        ) : equal(isSiteAddressListLoading, 'FAILED') ? (
          <Stack alignItems="center" justifyContent="center">
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching Site Addresses data.
              Please try contacting the admin or refreshing this page.
            </Typography>
          </Stack>
        ) : (
          <TableContainer>
            <div className={classes.overflowTable}>
              <Table
                className={`${classes.commonTable} ${classes.siteAddressColumn}`}
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead sx={{ position: 'sticky', zIndex: ' 999' }}>
                  <TableRow>
                    {siteAddressHeader?.map((heading, index) => (
                      <TableCell key={index}>{heading}</TableCell>
                    ))}
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {renderFormFields(siteAddress)}
              </Table>
              <div className={classes.stickyButton}>
                <DKTButton
                  variant="text"
                  color="primary"
                  sx={{ textTransform: 'initial', px: 1 }}
                  startIcon={
                    <CircledPlusIcon disabled={hasOnlyViewPermission} />
                  }
                  onClick={() => handleAddAddress(siteAddress.length)}
                  disabled={hasOnlyViewPermission}
                >
                  <Typography>Add Address</Typography>
                </DKTButton>
                <Stack direction="row" justifyContent="flex-end">
                  {ternary(
                    siteAddress?.length,
                    <DKTButton
                      disabled={hasOnlyViewPermission}
                      onClick={handleSaveAddress}
                    >
                      {ternary(isCreateSiteAddressLoading, 'Saving...', 'Save')}
                    </DKTButton>,
                    null,
                  )}
                </Stack>
              </div>
            </div>
          </TableContainer>
        )}
      </DKTDialog>
      <DKTDialog
        open={deleteNotificationOpen?.popupOpen || false}
        handleClose={handleClosePopup}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can’t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnSavedWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSaveAddress}
      />
      <DKTReactRouterPrompt isDirty={isUnSavedChanges} />
    </>
  )
}

export default SiteAddress
