import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { Delete } from '@mui/icons-material'
import moment from 'moment/moment'
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import StarRateIcon from '@mui/icons-material/StarRate'
import {
  options,
  columns,
  actionTitles,
} from '../../Description/projects.description'
import DKTButton from '../../Shared/DKTButton'
import DKTMuiDataTable from '../../Shared/DKTMuiDataTable'
import DKTButtonSelect from '../../Shared/DKTButtonSelect'
import { deleteProject, getProjectList } from '../../Redux/actions/projects'
import { checkUndefined, equal, keys, ternary } from '../../Utils/javascript'
import DKTDialog from '../../Shared/DKTDialog'
import { useStyles } from '../../Styles/projects.style'
import { OuterLayout } from '../../Layout/project'
import { SA, consultantPortal } from '../../Utils/constant'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import { usStateCitySuccess } from '../../Redux/actions/states'
import { emptyTableCellPlaceholder } from '../../Description/reportMonitoring.description'
import { addAndRemoveFavorite } from '../../Redux/actions/globalDashboard'
import { CLEAR_PROJECTS } from '../../Redux/constants/projects'

const Projects = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()

  const [projects, setProjects] = useState([])
  const [deleteNotificationOpen, setOpenDeleteNotification] = useState(false)

  const { projectList, isProjectListLoading } = useSelector(
    ({ projects }) => projects,
  )
  const { systemAuthorization, portalType } = useSelector(({ auth }) => auth)
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const projectToRemove = useRef(null)
  const setSelectedRows = useRef(() => {})

  useEffect(() => {
    dispatch(getProjectList())
    return () => dispatch({ type: CLEAR_PROJECTS })
  }, [dispatch])

  useEffect(() => {
    setProjects(projectList || [])
  }, [projectList])

  const handleRedirect = (id) => {
    navigate(`/projects/${id}/projectDashboard`)
  }

  const handleCreateProject = () => {
    navigate('/projects/new-project/summary/')
    dispatch(usStateCitySuccess(null))
  }

  const handleFavorite = (e, projectId, status) => {
    const data = {
      projectId,
      action: status ? 'remove' : 'add',
    }
    dispatch(addAndRemoveFavorite(data))

    const indexToUpdate = projects?.findIndex((project) =>
      equal(project.id, projectId),
    )

    if (indexToUpdate !== -1) {
      setProjects((prevProjects) => [
        ...prevProjects.slice(0, indexToUpdate),
        { ...prevProjects[indexToUpdate], isFavorite: !status },
        ...prevProjects.slice(indexToUpdate + 1),
      ])
    }

    e.stopPropagation()
  }

  const customBodyRender = useCallback(
    (value, tableMeta) => {
      switch (tableMeta?.columnData?.name) {
        case 'isFavorite':
          return (
            <Box
              onClick={(e) => handleFavorite(e, tableMeta?.rowData?.[0], value)}
              sx={{ cursor: 'pointer', display: 'flex' }}
            >
              {value ? (
                <StarRateIcon style={{ color: '#FFEA00' }} />
              ) : (
                <StarOutlineOutlinedIcon />
              )}
            </Box>
          )
        case 'projectName':
          return (
            <DKTButton
              variant="text"
              onClick={() => handleRedirect(tableMeta.rowData[0])}
              sx={{
                textAlign: 'left',
                paddingLeft: '0px !important',
                marginLeft: '0px',
              }}
            >
              {value}
            </DKTButton>
          )
        case 'complete':
          return ternary(value, `${value} %`, emptyTableCellPlaceholder)
        case 'reportCompletionDate':
          return ternary(
            value,
            moment(value).format('MM/DD/YY'),
            emptyTableCellPlaceholder,
          )
        case 'scheduleDelay':
          return (
            <Typography
              sx={{
                color: ternary(value > 0, '#ff0000', 'inherit'),
              }}
            >
              {ternary(
                checkUndefined(value) || equal(value, null),
                emptyTableCellPlaceholder,
                value,
              )}
            </Typography>
          )
        default:
          return emptyTableCellPlaceholder
      }
    },
    [handleRedirect, handleFavorite],
  )

  const actionList = [
    { label: 'Download .xls', onClick: () => null },
    { label: 'Print', onClick: () => null },
  ]
  const actions = () => (
    <Stack direction="row" gap={2}>
      <DKTButtonSelect options={actionList} />
      {equal(portalType, consultantPortal) &&
        (equal(systemAuthorization, SA.administrator) ||
          equal(systemAuthorization, SA.globalEditor) ||
          equal(systemAuthorization, SA.projectLevel)) && (
          <DKTButton onClick={handleCreateProject} disabled={isGlobalViewer}>
            Create New
          </DKTButton>
        )}
    </Stack>
  )
  const handleClose = () => {
    setOpenDeleteNotification(false)
  }

  const handleDeleteTableRow = () => {
    const body = {
      projects: projectToRemove?.current,
    }
    dispatch(deleteProject(body))
    setOpenDeleteNotification(false)
    setSelectedRows.current([])
  }
  const customToolbarSelect = useCallback(
    (selectedRows, displayData, setSelectedTableRows) => {
      const selectedProject = keys(selectedRows.lookup)
      const selectedProjectIds = selectedProject.map(
        (rowToDelete) => projectList[rowToDelete]?.id,
      )
      setSelectedRows.current = setSelectedTableRows
      const setProjectToRemove = () => {
        projectToRemove.current = selectedProjectIds
      }
      return (
        <Box>
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => {
              setProjectToRemove()
              setOpenDeleteNotification(true)
            }}
          >
            <Delete color="error" />
          </IconButton>
        </Box>
      )
    },
    [projectList],
  )
  const tableOptions = {
    ...options,
    textLabels: {
      body: {
        noMatch: isProjectListLoading ? (
          <DKTCircularProgress />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
    customToolbarSelect,
    selectableRows: ternary(
      equal(systemAuthorization, SA.administrator) &&
        equal(portalType, consultantPortal),
      options.selectableRows || 'multiple',
      'none',
    ),
  }
  const tableColumns = columns?.map((project) =>
    ternary(
      equal(project.name, 'isFavorite') ||
        equal(project.name, 'projectName') ||
        equal(project.name, 'complete') ||
        equal(project.name, 'reportCompletionDate') ||
        equal(project.name, 'scheduleDelay'),
      { ...project, options: { ...project.options, customBodyRender } },
      project,
    ),
  )
  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteTableRow}>
        Yes
      </DKTButton>
    </>
  )

  return (
    <OuterLayout
      title="Projects"
      actions={actions}
      actionTitle={actionTitles.projects}
      isShowMenu={false}
      actionTitleTextTransform="none"
    >
      <Box px={5} marginTop="164px">
        <DKTMuiDataTable
          data={projects}
          options={tableOptions}
          columns={tableColumns}
          className={`${classes.projectListTable} ${
            (!equal(systemAuthorization, SA.administrator) ||
              !equal(portalType, consultantPortal)) &&
            classes.projectListTableClient
          }`}
        />
        <DKTDialog
          open={deleteNotificationOpen}
          handleClose={handleClose}
          title="&nbsp;"
          actions={deleteNotificationAction}
          maxWidth="xs"
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.deleteNotificationContent}>
                <Typography variant="h5">
                  Are you sure you want to delete?
                </Typography>
                <Typography variant="body1">
                  You can&apos;t undo this action
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DKTDialog>
      </Box>
      <Outlet />
    </OuterLayout>
  )
}

export default Projects
