import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { checkIncludes, equal, length, ternary } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  DELETE_PERMITS_ENTITLEMENTS_FAILED,
  DELETE_PERMITS_ENTITLEMENTS_REQUESTED,
  DELETE_PERMITS_ENTITLEMENTS_SUCCESS,
  DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
  DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  FETCH_PERMITS_ENTITLEMENTS_FAILED,
  FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_FAILED,
  FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_REQUESTED,
  FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_SUCCESS,
  FETCH_PERMITS_ENTITLEMENTS_REQUESTED,
  FETCH_PERMITS_ENTITLEMENTS_SUCCESS,
  FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
  FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  IMPORT_PERMITS_ENTITLEMENTS_FAILED,
  IMPORT_PERMITS_ENTITLEMENTS_REQUESTED,
  IMPORT_PERMITS_ENTITLEMENTS_SUCCESS,
  SAVE_PERMITS_ENTITLEMENTS_FAILED,
  SAVE_PERMITS_ENTITLEMENTS_REQUESTED,
  SAVE_PERMITS_ENTITLEMENTS_SUCCESS,
  SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
  SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
  FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  FETCH_PERMITS_TRACKER_REQUESTED,
  FETCH_PERMITS_TRACKER_SUCCESS,
  FETCH_PERMITS_TRACKER_FAILED,
  SAVE_PERMITS_TRACKER_REQUESTED,
  SAVE_PERMITS_TRACKER_SUCCESS,
  SAVE_PERMITS_TRACKER_FAILED,
  DELETE_PERMIT_TRACKER_REQUESTED,
  DELETE_PERMIT_TRACKER_SUCCESS,
  DELETE_PERMIT_TRACKER_FAILED,
} from '../constants/permitsEntitlements'
import { updateSaveStatusAction } from './confirmation'
import { getReportList } from './reportMonitoring'

// Fetch last report permits entitlements
const fetchLastReportPermitsEntitlementsRequested = () => ({
  type: FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_REQUESTED,
})
const fetchLastReportPermitsEntitlementsSuccess = (payload) => ({
  type: FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_SUCCESS,
  payload,
})
const fetchLastReportPermitsEntitlementsFailed = (payload) => ({
  type: FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_FAILED,
  payload,
})

export const getLastReportPermitsEntitlements =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchLastReportPermitsEntitlementsRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.permitsEntitlements}last-report/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
    })
    if (data) {
      dispatch(fetchLastReportPermitsEntitlementsSuccess(data))
      return data
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchLastReportPermitsEntitlementsSuccess(null))
      }
      dispatch(fetchLastReportPermitsEntitlementsFailed(error.response))
    }
    return false
  }

// Fetch permits entitlements
const fetchPermitsEntitlementsRequested = () => ({
  type: FETCH_PERMITS_ENTITLEMENTS_REQUESTED,
})
const fetchPermitsEntitlementsSuccess = (payload) => ({
  type: FETCH_PERMITS_ENTITLEMENTS_SUCCESS,
  payload,
})
const fetchPermitsEntitlementsFailed = (payload) => ({
  type: FETCH_PERMITS_ENTITLEMENTS_FAILED,
  payload,
})

export const getPermitsEntitlements = (reportId, ocId) => async (dispatch) => {
  dispatch(fetchPermitsEntitlementsRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.permitsEntitlements}?reportId=${reportId}&ocId=${ocId}`,
  })
  if (data) {
    // const permitsEntitlements = data?.find(({ ownerContractor }) =>
    //   equal(ownerContractor, ocId),
    // )
    dispatch(fetchPermitsEntitlementsSuccess(data))
  }
  if (error) {
    if (equal(error?.response?.status, 404)) {
      dispatch(savePermitsEntitlementsSuccess(null))
    }
    dispatch(fetchPermitsEntitlementsFailed(error.response))
  }
}

// Save permits entitlements
const savePermitsEntitlementsRequested = () => ({
  type: SAVE_PERMITS_ENTITLEMENTS_REQUESTED,
})
export const savePermitsEntitlementsSuccess = (payload) => ({
  type: SAVE_PERMITS_ENTITLEMENTS_SUCCESS,
  payload,
})
const savePermitsEntitlementsFailed = (payload) => ({
  type: SAVE_PERMITS_ENTITLEMENTS_FAILED,
  payload,
})

export const savePermitsEntitlements =
  ({ payload, reportId, ocId, projectId, ownerContractorUniqueCode }) =>
  async (dispatch, getState) => {
    dispatch(savePermitsEntitlementsRequested())
    const { reportData } = getState().reportMonitoring
    const { permitsEntitlements } = getState().permitsEntitlements
    const isAlreadyCreated =
      reportData?.formCreatedList[ownerContractorUniqueCode]
        ?.permitsAndEntitlements
    const { data, error } = await api({
      method: ternary(isAlreadyCreated, method.patch, method.post),
      endPoint: ternary(
        isAlreadyCreated,
        `${apiEndPoints.permitsEntitlements}${permitsEntitlements?.id}/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
        `${apiEndPoints.permitsEntitlements}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      ),
      data: { ...payload, ownerContractor: ocId, report: reportId },
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    let cloneData = { ...data }
    cloneData = {
      ...cloneData,
      certificateOfOccupancyIssuedDate: equal(
        cloneData?.certificateOfOccupancyReceived,
        'NO',
      )
        ? 'N/A'
        : cloneData.certificateOfOccupancyIssuedDate,
      tcoDate: equal(cloneData?.temporaryCertificateOfOccupancyReceived, 'NO')
        ? 'N/A'
        : cloneData.tcoDate,
      tcoExpiration: equal(
        cloneData?.temporaryCertificateOfOccupancyReceived,
        'NO',
      )
        ? 'N/A'
        : cloneData.tcoExpiration,
    }
    if (data) {
      if (!isAlreadyCreated) dispatch(getReportList(projectId, ocId, reportId))

      dispatch(savePermitsEntitlementsSuccess(cloneData))
    }
    if (error) {
      dispatch(savePermitsEntitlementsFailed(error.response.data))
    }
  }

// Delete permits entitlements
const deletePermitsEntitlementsRequested = () => ({
  type: DELETE_PERMITS_ENTITLEMENTS_REQUESTED,
})
export const deletePermitsEntitlementsSuccess = (payload) => ({
  type: DELETE_PERMITS_ENTITLEMENTS_SUCCESS,
  payload,
})
const deletePermitsEntitlementsFailed = (payload) => ({
  type: DELETE_PERMITS_ENTITLEMENTS_FAILED,
  payload,
})

export const deletePermitsEntitlements =
  (payload, handleClose) => async (dispatch) => {
    dispatch(deletePermitsEntitlementsRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.deletePermitsEntitlements}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Deleted successfully!',
    })
    if (data) {
      handleClose()
      dispatch(deletePermitsEntitlementsSuccess(payload))
      return
    }
    if (error) {
      dispatch(deletePermitsEntitlementsFailed(error.response.data))
    }
  }
// Import from last report
const importFormLastReportRequested = (query) => ({
  type: IMPORT_PERMITS_ENTITLEMENTS_REQUESTED,
  payload: query,
})

const importFormLastReportSuccess = (payload) => ({
  type: IMPORT_PERMITS_ENTITLEMENTS_SUCCESS,
  payload,
})
const importFormLastReportFailed = (payload) => ({
  type: IMPORT_PERMITS_ENTITLEMENTS_FAILED,
  payload,
})
export const importFromLastReport =
  ({ query, projectId, ocId, onSuccess = () => null, reportId }) =>
  async (dispatch) => {
    const [firstLetter] = query
    const restWord = query.substr(1, length(query) - 1)
    const pascalCaseQuery = `${firstLetter.toUpperCase()}${restWord}`
    dispatch(importFormLastReportRequested(query))

    const { data, error } = await api({
      endPoint: `${apiEndPoints.importLastReport}?query=${query}&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
      method: method.get,
    })

    if (data) {
      dispatch(
        importFormLastReportSuccess({
          pascalCaseQuery,
          query,
          data,
        }),
      )
      onSuccess(data[query])
    }
    if (error) {
      showToast('Failed to import comments')
      dispatch(
        importFormLastReportFailed({
          pascalCaseQuery,
          query,
          data: error.response.data,
        }),
      )
    }
  }

/** *****************
    CERTIFICATE OF OCCUPANCY TRACKER
 ***************** */
// Fetch certificate of occupancy tracker
const fetchCertificateOfOccupancyRequested = () => ({
  type: FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
})
const fetchCertificateOfOccupancySuccess = (payload) => ({
  type: FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  payload,
})
const fetchCertificateOfOccupancyFailed = (payload) => ({
  type: FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  payload,
})

export const getCertificateOfOccupancy =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchCertificateOfOccupancyRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.certificateOfOccupancy}?reportId=${reportId}&projectId=${projectId}&ocId=${ocId}`,
    })
    if (data) {
      dispatch(fetchCertificateOfOccupancySuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(saveCertificateOfOccupancySuccess([]))
        // for last report data show in occupancy tracker so Currently its disabled
        // dispatch(getLastReportCertificateOfOccupancy({ reportId, ocId, projectId }))
      }
      dispatch(fetchCertificateOfOccupancyFailed(error.response))
    }
  }
// Fetch last report certificate of occupancy tracker
const fetchLastReportCertificateOfOccupancyRequested = () => ({
  type: FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
})
const fetchLastReportCertificateOfOccupancySuccess = (payload) => ({
  type: FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  payload,
})
const fetchLastReportCertificateOfOccupancyFailed = (payload) => ({
  type: FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  payload,
})

export const getLastReportCertificateOfOccupancy =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchLastReportCertificateOfOccupancyRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.certificateOfOccupancy}last-report/?reportId=${reportId}&projectId=${projectId}&ocId=${ocId}`,
    })
    if (data) {
      dispatch(fetchLastReportCertificateOfOccupancySuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(saveCertificateOfOccupancySuccess([]))
      }
      dispatch(fetchLastReportCertificateOfOccupancyFailed(error.response))
    }
  }

// Save certificate of occupancy tracker
const saveCertificateOfOccupancyRequested = () => ({
  type: SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
})
export const saveCertificateOfOccupancySuccess = (payload) => ({
  type: SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  payload,
})
const saveCertificateOfOccupancyFailed = (payload) => ({
  type: SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  payload,
})

export const saveCertificateOfOccupancy =
  ({
    payload,
    reportId,
    ocId,
    projectId,
    certificateOfOccupancyToEdit,
    onSuccess = () => null,
  }) =>
  async (dispatch, getState) => {
    dispatch(saveCertificateOfOccupancyRequested())
    const { data, error } = await api({
      method: ternary(certificateOfOccupancyToEdit, method.patch, method.post),
      endPoint: ternary(
        certificateOfOccupancyToEdit,
        `${apiEndPoints.certificateOfOccupancy}${certificateOfOccupancyToEdit}/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
        `${apiEndPoints.certificateOfOccupancy}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      ),
      data: ternary(
        payload?.ownerContractor && payload?.report,
        { ...payload },
        { ...payload, ownerContractor: ocId, report: reportId },
      ),
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      const { certificateOfOccupancyTracker } = getState().permitsEntitlements
      let savedData
      if (certificateOfOccupancyToEdit) {
        const buildingToUpdate = certificateOfOccupancyTracker.findIndex(
          ({ id }) => equal(id, certificateOfOccupancyToEdit),
        )
        if (~buildingToUpdate) {
          const clonedCertificateOfOccupancyTracker = [
            ...certificateOfOccupancyTracker,
          ]
          clonedCertificateOfOccupancyTracker.splice(buildingToUpdate, 1, data)
          savedData = clonedCertificateOfOccupancyTracker
        }
      } else {
        savedData = [...certificateOfOccupancyTracker, data]
      }
      dispatch(saveCertificateOfOccupancySuccess(savedData))
      onSuccess()
    }
    if (error) {
      dispatch(saveCertificateOfOccupancyFailed(error.response.data))
    }
  }

// Delete certificate of occupancy tracker
const deleteCertificateOfOccupancyRequested = () => ({
  type: DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED,
})
export const deleteCertificateOfOccupancySuccess = (payload) => ({
  type: DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS,
  payload,
})
const deleteCertificateOfOccupancyFailed = (payload) => ({
  type: DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED,
  payload,
})

export const deleteCertificateOfOccupancy =
  ({ buildingToDelete, reportId, ocId, projectId, onSuccess }) =>
  async (dispatch, getState) => {
    dispatch(deleteCertificateOfOccupancyRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.certificateOfOccupancy}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: { building: [buildingToDelete] },
      showToastMessage: true,
      toastMessage: 'Deleted successfully!',
    })
    if (status) {
      const { certificateOfOccupancyTracker } = getState().permitsEntitlements
      const clonedCertificateOfOccupancyTracker = [
        ...certificateOfOccupancyTracker,
      ]
      const buildingId = clonedCertificateOfOccupancyTracker.findIndex(
        ({ id }) => equal(id, buildingToDelete),
      )
      if (~buildingId) clonedCertificateOfOccupancyTracker.splice(buildingId, 1)
      onSuccess()
      dispatch(
        deleteCertificateOfOccupancySuccess(
          clonedCertificateOfOccupancyTracker,
        ),
      )
    }
    if (error) {
      dispatch(deleteCertificateOfOccupancyFailed(error.response.data))
    }
  }

// Fetch permits tracker
const fetchPermitsTrackerRequested = () => ({
  type: FETCH_PERMITS_TRACKER_REQUESTED,
})
const fetchPermitsTrackerSuccess = (payload) => ({
  type: FETCH_PERMITS_TRACKER_SUCCESS,
  payload,
})
const fetchPermitsTrackerFailed = (payload) => ({
  type: FETCH_PERMITS_TRACKER_FAILED,
  payload,
})

export const getPermitsTracker =
  (reportId, ocId, projectId) => async (dispatch) => {
    dispatch(fetchPermitsTrackerRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.permitTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
    })
    if (data) {
      dispatch(fetchPermitsTrackerSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchPermitsTrackerSuccess(null))
      }
      dispatch(fetchPermitsTrackerFailed(error.response))
    }
  }

// Save permit tracker
const savePermitTrackerRequested = () => ({
  type: SAVE_PERMITS_TRACKER_REQUESTED,
})
const savePermitTrackerSuccess = (payload) => ({
  type: SAVE_PERMITS_TRACKER_SUCCESS,
  payload,
})
const savePermitTrackerFailed = (payload) => ({
  type: SAVE_PERMITS_TRACKER_FAILED,
  payload,
})

export const savePermitTracker =
  (payload, reportId, ocId, projectId) => async (dispatch, getState) => {
    dispatch(savePermitTrackerRequested())
    const { permitTrackerData } = getState().permitsEntitlements
    const { data, error } = await api({
      endPoint: `${apiEndPoints.permitTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: permitTrackerData?.length ? method.patch : method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Permit tracker saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)

    if (data) {
      dispatch(savePermitTrackerSuccess(data))
      return
    }
    if (error) {
      dispatch(savePermitTrackerFailed(error.response))
    }
  }

// Delete permit tracker
const deleteSavePermitTrackerRequested = () => ({
  type: DELETE_PERMIT_TRACKER_REQUESTED,
})
export const deleteSavePermitTrackerSuccess = (payload) => ({
  type: DELETE_PERMIT_TRACKER_SUCCESS,
  payload,
})
const deleteSavePermitTrackerFailed = (payload) => ({
  type: DELETE_PERMIT_TRACKER_FAILED,
  payload,
})

export const deleteSavePermitTracker =
  ({ permitTrackerToDelete, reportId, ocId, projectId, setData }) =>
  async (dispatch, getState) => {
    dispatch(deleteSavePermitTrackerRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.permitTracker}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: { permitTracker: permitTrackerToDelete },
      showToastMessage: true,
      toastMessage: 'Permit tracker deleted successfully!',
    })
    if (status) {
      const { permitTrackerData } = getState().permitsEntitlements
      const permitTrackerDataAfterDeletion = permitTrackerData.filter(
        ({ id }) => !checkIncludes(id, permitTrackerToDelete),
      )
      setData(permitTrackerDataAfterDeletion || [])
      dispatch(deleteSavePermitTrackerSuccess(permitTrackerDataAfterDeletion))
    }
    if (error) {
      dispatch(deleteSavePermitTrackerFailed(error.response))
    }
  }
