import { BOOLEAN } from '../Utils/constant'
import { emailValidation, mobileNumber, validatePassword } from '../Utils/regex'

export const companyFieldList = {
  firstName: {
    type: 'text',
    label: 'First Name',
    isRequired: true,
  },
  lastName: {
    type: 'text',
    label: 'Last Name',
    isRequired: true,
  },
  email: {
    type: 'text',
    label: 'Email Address',
    isRequired: true,
    validate: [emailValidation, 'Invalid email address'],
  },
  autoCreatePassword: {
    type: 'checkbox',
    valueType: BOOLEAN,
    fields: [
      {
        label: 'Automatically create a password',
        value: 'autoCreatePassword',
        labelledCheckbox: true,
      },
    ],
  },
  password: {
    type: 'text',
    label: 'Temporary Password',
    isRequired: true,
    validate: [
      validatePassword,
      'Password must be at least 8 characters long and contain a mix of uppercase letters, lowercase letters, digits, and special characters.',
    ],
  },
  workPhoneNumber: {
    type: 'text',
    label: 'Work Phone (W)',
    xs: 6,
    validate: [mobileNumber],
    isPhoneNumber: true,
  },
  extension: {
    type: 'text',
    label: 'Extension',
    xs: 6,
  },
  cellPhone: {
    type: 'text',
    label: 'Cell Phone (C)',
    validate: [mobileNumber],
    isPhoneNumber: true,
  },
}
