import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { getProjectList } from '../../Redux/actions/projects'
import { equal } from '../../Utils/javascript'

import Navbar from '../admin/Navbar'
import MainLayout from '../main'
import ActionsBar from '../ActionsBar'
import Sidebar from './Sidebar'
import PageTitleBar from './PageTitleBar'
import { getReportList } from '../../Redux/actions/reportMonitoring'

export const OuterLayout = ({
  children,
  actions: Actions = () => null,
  actionTitle,
  isShowMenu,
}) => {
  const dispatch = useDispatch()
  const { slug: projectId } = useParams()
  const { projectList } = useSelector(({ projects }) => projects)
  const currentProject = projectList?.find((project) =>
    equal(project?.id, +projectId),
  )
  useEffect(() => {
    dispatch(getProjectList(projectId))
  }, [dispatch, projectId])

  return (
    <MainLayout>
      <PageTitleBar
        title={currentProject?.projectName}
        isShowMenu={isShowMenu}
      />
      <ActionsBar actionTitle={actionTitle}>
        <Actions />
      </ActionsBar>
      {children}
    </MainLayout>
  )
}
export const InnerLayout = ({
  actionTitle,
  contentTitle,
  actions: Actions = () => null,
  children,
  className,
  title,
  boxSx = {},
}) => {
  const dispatch = useDispatch()
  const { slug: projectId, reportId } = useParams()
  const params = useSearchParams()[0]
  const { currentProject } = useSelector(({ projects }) => projects)
  useEffect(() => {
    dispatch(getProjectList(projectId))
  }, [dispatch, projectId])
  useEffect(() => {
    if (reportId)
      dispatch(getReportList(projectId, params.get('ocId'), reportId))
  }, [dispatch, reportId])

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1010,
        }}
      >
        <Navbar />
        <PageTitleBar title={currentProject?.projectName || title} />
        <ActionsBar actionTitle={actionTitle}>
          <Actions />
        </ActionsBar>
      </Box>
      <Stack
        direction="row"
        flex={1}
        sx={{ maxHeight: '100%', overflow: 'hidden' }}
      >
        <Sidebar />
        <Box
          py={4}
          width="100%"
          sx={{
            overflowY: 'auto',
            marginLeft: '250px',
            marginTop: '164px',
            paddingLeft: { sm: '20px', lg: '40px' },
            paddingRight: { sm: '20px', lg: '40px' },
            ...boxSx,
          }}
        >
          <Typography
            component="h6"
            fontSize={14}
            fontWeight={700}
            textAlign="center"
            textTransform="uppercase"
            mb={4}
            className={className}
          >
            {contentTitle}
          </Typography>
          {children}
        </Box>
      </Stack>
    </>
  )
}
