import { Tooltip } from '@mui/material'
import React from 'react'

const DKTTooltip = ({ children, title = '', placement = 'top', ...rest }) => (
  <Tooltip title={title} arrow placement={placement} {...rest}>
    {children}
  </Tooltip>
)

export default DKTTooltip
