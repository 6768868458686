import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { checkIncludes, equal, ternary } from '../../Utils/javascript'
import {
  CREATE_PERSONNEL_FAILED,
  CREATE_PERSONNEL_REQUESTED,
  CREATE_PERSONNEL_SUCCESS,
  FETCH_COMPANY_PERSONNEL_FAILED,
  FETCH_COMPANY_PERSONNEL_REQUESTED,
  FETCH_COMPANY_PERSONNEL_SUCCESS,
  GENERATE_PASSWORD_FAILED,
  GENERATE_PASSWORD_REQUESTED,
  GENERATE_PASSWORD_SUCCESS,
  RESET_PERSONNEL_PASSWORD_FAILED,
  RESET_PERSONNEL_PASSWORD_REQUESTED,
  RESET_PERSONNEL_PASSWORD_SUCCESS,
  UPDATE_COMPANY_PERSONNEL_FAILED,
  UPDATE_COMPANY_PERSONNEL_REQUESTED,
  UPDATE_COMPANY_PERSONNEL_SUCCESS,
} from '../constants/companyPersonnel'
import { updateSaveStatusAction } from './confirmation'

const getSuffix = (extension) => ternary(extension, `(${extension})`, '')

const getWorkPhoneWithExtension = (extension, phoneNumber) =>
  `${phoneNumber} ${getSuffix(extension)}`

const fetchCompanyPersonnelRequested = () => ({
  type: FETCH_COMPANY_PERSONNEL_REQUESTED,
})
const fetchCompanyPersonnelSuccess = (payload) => ({
  type: FETCH_COMPANY_PERSONNEL_SUCCESS,
  payload,
})
const fetchCompanyPersonnelFailed = (payload) => ({
  type: FETCH_COMPANY_PERSONNEL_FAILED,
  payload,
})
export const fetchCompanyPersonnel = (payload) => async (dispatch) => {
  dispatch(fetchCompanyPersonnelRequested())
  const { data, error } = await api({
    data: payload,
    method: method.get,
    endPoint: apiEndPoints.companyPersonnel,
  })
  if (data) {
    const personnelData = data.map((personnel) => {
      const workPhoneWithExtension = getWorkPhoneWithExtension(
        personnel.extension,
        personnel.workPhoneNumber,
      )
      return { ...personnel, workPhoneWithExtension }
    })
    dispatch(fetchCompanyPersonnelSuccess(personnelData))
    return
  }
  if (error) {
    dispatch(fetchCompanyPersonnelFailed(error.response.data))
  }
}

const createPersonnelRequested = (payload) => ({
  type: CREATE_PERSONNEL_REQUESTED,
  payload,
})
const createPersonnelSuccess = (payload) => ({
  type: CREATE_PERSONNEL_SUCCESS,
  payload,
})
const createPersonnelFailed = (payload) => ({
  type: CREATE_PERSONNEL_FAILED,
  payload,
})
export const createPersonnel =
  (payload, notify, { onSuccess = () => {} }) =>
  async (dispatch, getState) => {
    dispatch(createPersonnelRequested({ isNotifying: notify }))
    const { data, error } = await api({
      data: payload,
      method: method.post,
      endPoint: ternary(
        notify,
        `${apiEndPoints.createCompanyPersonnel}?notify=${notify}`,
        apiEndPoints.createCompanyPersonnel,
      ),
      showToastMessage: true,
      showErrorMessage: false,
      toastMessage: 'Company personnel created successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      const { companyPersonnel } = getState()
      const companyPersonnelList = [
        ...companyPersonnel.companyPersonnelList,
        {
          ...data,
          workPhoneWithExtension: getWorkPhoneWithExtension(
            data.extension,
            data.workPhoneNumber,
          ),
        },
      ]
      dispatch(createPersonnelSuccess(companyPersonnelList))
      onSuccess()
      return
    }
    if (error) {
      dispatch(createPersonnelFailed(error.response.data))
    }
  }

const deleteCompanyPersonnelRequested = () => ({
  type: CREATE_PERSONNEL_REQUESTED,
})
const deleteCompanyPersonnelSuccess = (payload) => ({
  type: CREATE_PERSONNEL_SUCCESS,
  payload,
})
const deleteCompanyPersonnelFailed = (payload) => ({
  type: CREATE_PERSONNEL_FAILED,
  payload,
})
export const deleteCompanyPersonnel =
  (personnelIds) => async (dispatch, getState) => {
    const { companyPersonnelList } = getState().companyPersonnel
    const updatedPersonnelList = companyPersonnelList.filter(
      (personnel) => !checkIncludes(personnel.id, personnelIds),
    )
    dispatch(deleteCompanyPersonnelRequested())
    const { data, error } = await api({
      method: method.delete,
      endPoint: apiEndPoints.deleteCompanyPersonnel,
      data: { users: personnelIds },
      showToastMessage: true,
    })
    if (data) {
      dispatch(deleteCompanyPersonnelSuccess(updatedPersonnelList))
      return
    }
    if (error) {
      dispatch(deleteCompanyPersonnelFailed(error.response.data))
    }
  }

const resetPersonnelPasswordRequested = () => ({
  type: RESET_PERSONNEL_PASSWORD_REQUESTED,
})
const resetPersonnelPasswordSuccess = (payload) => ({
  type: RESET_PERSONNEL_PASSWORD_SUCCESS,
  payload,
})
const resetPersonnelPasswordFailed = (payload) => ({
  type: RESET_PERSONNEL_PASSWORD_FAILED,
  payload,
})
export const resetPersonnelPassword =
  (payload, { onSuccess = () => {} } = {}) =>
  async (dispatch) => {
    dispatch(resetPersonnelPasswordRequested())
    const { data, error } = await api({
      method: method.post,
      endPoint: apiEndPoints.resetPersonnelPassword,
      data: payload,
      showToastMessage: true,
      showErrorMessage: false,
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      onSuccess()
      dispatch(resetPersonnelPasswordSuccess())
      return
    }
    if (error) {
      dispatch(resetPersonnelPasswordFailed(error.response.data))
    }
  }

const updateCompanyPersonnelRequested = (payload) => ({
  type: UPDATE_COMPANY_PERSONNEL_REQUESTED,
  payload,
})
const updateCompanyPersonnelSuccess = (payload) => ({
  type: UPDATE_COMPANY_PERSONNEL_SUCCESS,
  payload,
})
const updateCompanyPersonnelFailed = (payload) => ({
  type: UPDATE_COMPANY_PERSONNEL_FAILED,
  payload,
})
export const updateCompanyPersonnel =
  (payload, notify, { onSuccess = () => {} }) =>
  async (dispatch, getState) => {
    dispatch(updateCompanyPersonnelRequested({ isNotifying: notify }))
    const { companyPersonnelList } = getState().companyPersonnel
    const updatedPersonnelList = companyPersonnelList.map((personnel) =>
      ternary(
        equal(personnel.id, payload.id),
        {
          ...payload,
          workPhoneWithExtension: getWorkPhoneWithExtension(
            payload.extension,
            payload.workPhoneNumber,
          ),
        },
        personnel,
      ),
    )

    const { data, error } = await api({
      method: method.patch,
      endPoint: ternary(
        notify,
        `${apiEndPoints.updateProfile}${payload.id}/?notify=true`,
        `${apiEndPoints.updateProfile}${payload.id}/`,
      ),
      data: payload,
      showToastMessage: true,
      showErrorMessage: false,
      toastMessage: 'Personnel information updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(updateCompanyPersonnelSuccess(updatedPersonnelList))
      onSuccess()
      return
    }
    if (error) {
      dispatch(updateCompanyPersonnelFailed(error.response.data))
    }
  }

const generatePasswordRequested = () => ({
  type: GENERATE_PASSWORD_REQUESTED,
})
const generatePasswordSuccess = (payload) => ({
  type: GENERATE_PASSWORD_SUCCESS,
  payload,
})
const generatePasswordFailed = (payload) => ({
  type: GENERATE_PASSWORD_FAILED,
  payload,
})
export const generatePassword =
  ({ onSuccess = () => {}, onError = () => {} }) =>
  async (dispatch) => {
    dispatch(generatePasswordRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: apiEndPoints.randomPassword,
    })
    if (data) {
      dispatch(generatePasswordSuccess())
      onSuccess(data.tempPassword)
      return
    }
    if (error) {
      dispatch(generatePasswordFailed(error.response.data))
      onError(error)
    }
  }
