import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#ffffff',
  },
  wrapper: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '57px',
  },
  companyLogo: {
    width: '400px',
  },
  form: {
    minWidth: 350,
  },
  forgotPasswordLink: {
    textDecoration: 'none',
    marginBottom: 15,
    marginRight: 10,
  },
  loginContainer: {
    '& > .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& img': {
      width: '100%',
      height: '100vh',
      objectFit: 'cover',
    },
  },
  loginForm: {
    '& form': {
      margin: '210px 0 auto',
      width: '100%',
      maxWidth: '350px',
      '& img': {
        height: 75,
        width: 'auto',
        marginBottom: 100,
      },
    },
  },
}))
