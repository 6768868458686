// Fetch photographs
export const FETCH_PHOTOGRAPHS_REQUEST = 'FETCH_PHOTOGRAPHS_REQUEST'
export const FETCH_PHOTOGRAPHS_SUCCESS = 'FETCH_PHOTOGRAPHS_SUCCESS'
export const FETCH_PHOTOGRAPHS_FAILED = 'FETCH_PHOTOGRAPHS_FAILED'

// Create photographs
export const CREATE_PHOTOGRAPHS_REQUESTED = 'CREATE_PHOTOGRAPHS_REQUESTED'
export const CREATE_PHOTOGRAPHS_SUCCESS = 'CREATE_PHOTOGRAPHS_SUCCESS'
export const CREATE_PHOTOGRAPHS_FAILED = 'CREATE_PHOTOGRAPHS_FAILED'

// Update photographs
export const UPDATE_PHOTOGRAPHS_REQUESTED = 'UPDATE_PHOTOGRAPHS_REQUESTED'
export const UPDATE_PHOTOGRAPHS_SUCCESS = 'UPDATE_PHOTOGRAPHS_SUCCESS'
export const UPDATE_PHOTOGRAPHS_FAILED = 'UPDATE_PHOTOGRAPHS_FAILED'

// Delete photographs
export const DELETE_PHOTOGRAPHS_REQUESTED = 'DELETE_PHOTOGRAPHS_REQUESTED'
export const DELETE_PHOTOGRAPHS_SUCCESS = 'DELETE_PHOTOGRAPHS_SUCCESS'
export const DELETE_PHOTOGRAPHS_FAILED = 'DELETE_PHOTOGRAPHS_FAILED'

// clear photographs
export const CLEAR_PHOTOGRAPHS = 'CLEAR_PHOTOGRAPHS'
