import { zipCodeValidation } from '../Utils/regex'

export const formFields = {
  projectSummary: {
    projectName: {
      type: 'text',
      label: 'Project Name',
      isRequired: true,
    },
    projectNumberInternal: {
      type: 'text',
      label: 'Project Number- Internal',
      sx: {
        mb: 1.5,
      },
    },
    address: {
      type: 'text',
      label: 'Address',
      isRequired: true,
      isSeparate: true,
      separateSectionTitle: 'Project Address',
      isPopUp: true,
      popupText: 'Site Addresses',
    },
    city: {
      type: 'text',
      label: 'City',
      isRequired: true,
    },
    county: {
      type: 'text',
      label: 'County',
      isRequired: true,
    },
    state: {
      type: 'select',
      label: 'State',
      displayEmpty: true,
      lg: 5,
      xs: 6,
      isDynamicOptions: true,
      isRequired: true,
      placeholder: 'Select',
    },
    zipCode: {
      type: 'text',
      label: 'Zip Code',
      isRequired: true,
      lg: 5,
      xs: 6,
      validate: [zipCodeValidation],
      formControlProps: {
        sx: {
          mb: 3,
        },
      },
    },
    projectType: {
      type: 'select',
      label: 'Project Type',
      displayEmpty: true,
      isDynamicOptions: true,
      isRequired: true,
      isSeparate: true,
      separateSectionTitle: 'Development Information',
      placeholder: 'Select',
    },
    constructionType: {
      type: 'select',
      label: 'Construction Type',
      displayEmpty: true,
      isDynamicOptions: true,
      isRequired: true,
      placeholder: 'Select',
      formControlProps: {
        sx: {
          mb: 3,
        },
      },
    },
  },
  singleAgreement: {
    contractType: {
      type: 'select',
      label: 'Contract Type',
      isRequired: true,
      placeholder: 'Select',
      displayEmpty: true,
      isDynamicOptions: true,
      isSeparate: true,
      separateSectionTitle: 'Owner Contractor Agreement',
    },
    contractSum: {
      type: 'text',
      label: 'Contract Sum',
      isRequired: true,
      isCurrency: true,
      decimalPlaces: 2,
      mt: 2,
    },
    contractCompletion: {
      type: 'date',
      clearable: true,
      label: 'Contract Completion',
      isRequired: true,
      formControlProps: {
        sx: {
          mb: 3,
        },
      },
    },
    hardCostContingency: {
      type: 'text',
      label: 'Original Hard Cost Contingency (HCC)',
      isSeparate: true,
      isCurrency: true,
      separateSectionTitle: 'Contingency',
      decimalPlaces: 2,
    },
    softCostContingency: {
      type: 'text',
      label: 'Soft Cost Contingency (SCC)',
      isCurrency: true,
      decimalPlaces: 2,
      sx: {
        mb: 3,
      },
    },
    gcConstructionStart: {
      type: 'date',
      clearable: true,
      label: 'Construction Start',
      isRequired: true,
      isSeparate: true,
      separateSectionTitle: 'Construction Schedule',
    },
    gcScheduleCompletion: {
      type: 'date',
      clearable: true,
      label: 'Schedule Completion',
      isRequired: true,
    },
    lendersUwCompletion: {
      type: 'date',
      clearable: true,
      label: 'Lender’s UW Completion',
    },
  },
}
