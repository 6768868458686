import {
  Box,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import React, { useEffect, useState, memo } from 'react'
import { Delete } from '@mui/icons-material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  narrativeBasedHeader,
  dynamicColor,
  options,
} from '../../../Description/constructionSummary.description'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTInput from '../../../Shared/DKTInput'
import DKTTextArea from '../../../Shared/DKTTextArea'
import { useStyles } from '../../../Styles/constructionSummary.style'
import { equal, keys, ternary } from '../../../Utils/javascript'
import DKTCheckbox from '../../../Shared/DKTCheckbox'
import DKTTooltip from '../../../Shared/DKTTooltip'
import DKTButton from '../../../Shared/DKTButton'
import { CircledPlusIcon, DragIcon } from '../../../Assets/SVGs'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'

const EnhancedTableToolbar = (props) => {
  const { numSelected, handleOpenDeleteNotifications } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        minHeight: { xs: '56px' },
      }}
    >
      {numSelected > 0 && (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      )}

      {numSelected > 0 && (
        <DKTTooltip title="Delete">
          <IconButton onClick={handleOpenDeleteNotifications}>
            <Delete color="error" />
          </IconButton>
        </DKTTooltip>
      )}
    </Toolbar>
  )
}

const NarrativeBased = ({
  handleCollectDataFromChild,
  constructionSummaryData,
  constructionSummaryFormat,
  dataForUpdate,
  hasOnlyViewPermission,
  setNarrativeBasedFormIsDirty,
  setDataForUpdate,
  constructionSummaryFeatures,
  setIsDataDeleted,
}) => {
  const classes = useStyles()
  const [values, setValues] = useState({})
  const [tableData, setTableData] = useState([])
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(true)
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  useEffect(() => {
    const updateNarrativeBased = (sourceArray) => {
      const tabledata = Object.entries(sourceArray)?.map(
        ([key, value], index) => ({
          id: key,
          descriptionOfWork: value?.workDescription ?? '',
          status: value?.status ?? '0',
          summary: value?.summary ?? '',
          sortIndex: value?.sortIndex ?? index,
        }),
      )

      setTableData(tabledata.sort((a, b) => a.sortIndex - b.sortIndex))
      setDataForUpdate((prev) => ({ ...prev, narrativeBased: sourceArray }))
    }

    if (keys(constructionSummaryData?.narrativeBased)?.length) {
      const { narrativeBased = {} } = constructionSummaryData
      updateNarrativeBased(narrativeBased)
      setValues({ ...narrativeBased })
    } else {
      const data = constructionSummaryFormat?.length
        ? constructionSummaryFormat
        : constructionSummaryFeatures

      const newnarrativeBasedData = {}

      const newnarrativeBasedTableData = data?.map((values, index) => {
        const data = {
          descriptionOfWork: values?.workDescription ?? '',
          sortIndex: values?.sortIndex ?? index,
          status: values?.status ?? '0',
          summary: values?.summary ?? '',
        }
        newnarrativeBasedData[values.id] = data
        return {
          id: values.id,
          ...data,
        }
      })
      setValues(newnarrativeBasedData)

      setTimeout(() => {
        handleCollectDataFromChild({
          narrativeBased: { ...newnarrativeBasedData },
        })
      }, 10)
      setTableData(newnarrativeBasedTableData)
    }
    if (loading) {
      setTimeout(() => {
        setLoading(false)
      }, 500)
    }
  }, [
    constructionSummaryData,
    constructionSummaryFormat,
    constructionSummaryFeatures,
  ])

  useEffect(() => {
    const res = keys(constructionSummaryData?.narrativeBased)?.map((val) =>
      equal(
        JSON.stringify(constructionSummaryData?.narrativeBased[val]),
        JSON.stringify(values[val]),
      ),
    )
    if (
      !keys(constructionSummaryData?.narrativeBased)?.length &&
      keys(dataForUpdate?.narrativeBased)?.length
    ) {
      if (
        constructionSummaryFormat?.length ||
        constructionSummaryFeatures?.length
      ) {
        const targetArray = constructionSummaryFormat?.length
          ? constructionSummaryFormat
          : constructionSummaryFeatures
        setNarrativeBasedFormIsDirty(
          targetArray?.length !== keys(dataForUpdate?.narrativeBased)?.length,
        )
      } else {
        setNarrativeBasedFormIsDirty(
          !!keys(dataForUpdate?.narrativeBased)?.length,
        )
      }
    } else {
      setNarrativeBasedFormIsDirty(false)
    }
    if (res?.length) {
      setNarrativeBasedFormIsDirty(res?.some((val) => equal(val, false)))
    }
  }, [dataForUpdate, values])

  const closeDeleteNotification = () => setIsDeleteNotificationOpen(false)
  const openDeleteNotification = () => setIsDeleteNotificationOpen(true)

  const handleChange = (event, id, index) => {
    const { name, value } = event.target
    const cloneValues = { ...values }

    const updatedValue = {
      [id]: {
        ...cloneValues?.[id],
        [name]: value,
        sortIndex: index,
      },
    }
    setValues({ ...cloneValues, ...updatedValue })
    handleCollectDataFromChild({
      narrativeBased: { ...dataForUpdate?.narrativeBased, ...updatedValue },
    })
  }

  const handleAddDescription = () => {
    const id = new Date().valueOf()
    const sortIndex = keys(dataForUpdate.narrativeBased)?.length

    const newData = {
      descriptionOfWork: '',
      status: '0',
      sortIndex,
      summary: '',
    }
    setTableData((prev) => {
      const cloneValues = [...(prev || [])]
      cloneValues.push({
        id,
        ...newData,
      })
      return cloneValues
    })
    setValues({
      ...(dataForUpdate.narrativeBased || []),
      [id]: newData,
    })

    handleCollectDataFromChild({
      narrativeBased: {
        ...(dataForUpdate.narrativeBased || []),
        [id]: newData,
      },
    })
  }
  const handleDelete = () => {
    if (selected.length > 0) {
      let cloneValues = { ...values }
      const cloneTableData = tableData.filter(
        ({ id }) => !selected.includes(id),
      )

      selected.forEach((key) => {
        delete cloneValues[key]
      })

      cloneTableData.forEach(({ id }, index) => {
        cloneValues = {
          ...cloneValues,
          [id]: {
            ...cloneValues[id],
            sortIndex: index,
          },
        }
      })
      setIsDataDeleted(true)
      setTableData(cloneTableData)

      setDataForUpdate({ ...dataForUpdate, narrativeBased: cloneValues })
      setValues({ ...cloneValues })
    }
    setSelected([])
    closeDeleteNotification()
  }

  const deleteNotificationActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteNotification}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDelete}>
        Yes
      </DKTButton>
    </>
  )

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableData?.map((n) => n?.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleRowSelect = (event, id) => {
    const clickedElement = event.target
    const isCheckboxClicked =
      clickedElement.tagName === 'INPUT' && clickedElement.type === 'checkbox'
    if (isCheckboxClicked) {
      const selectedIndex = selected.indexOf(id)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }
      setSelected(newSelected)
    }
  }

  const onDragEnd = (result) => {
    if (result.destination && result.source) {
      const clonedData = [...tableData]
      clonedData.splice(result.source.index, 1)
      clonedData.splice(
        result.destination.index,
        0,
        tableData[result.source.index],
      )
      let cloneNarrativeData = { ...values }
      clonedData.forEach(({ id }, index) => {
        cloneNarrativeData = {
          ...cloneNarrativeData,
          [id]: {
            ...cloneNarrativeData[id],
            sortIndex: index,
          },
        }
      })
      setTableData((prevData) => {
        const clonedData = [...prevData]
        clonedData.splice(result.source.index, 1)
        clonedData.splice(
          result.destination.index,
          0,
          prevData[result.source.index],
        )

        return clonedData
      })

      handleCollectDataFromChild({
        narrativeBased: cloneNarrativeData,
      })
      setValues(cloneNarrativeData)
    }
  }

  return (
    <>
      {!!selected.length && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleOpenDeleteNotifications={openDeleteNotification}
        />
      )}
      {loading ? (
        <Stack height="50vh" alignItems="center" justifyContent="center">
          <DKTCircularProgress />
        </Stack>
      ) : (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <TableContainer sx={{ overflowX: 'clip !important' }}>
              <div className={classes.overflowTable}>
                <Table
                  className={classes.commonTable}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          border: 'none',
                        }}
                      ></TableCell>
                      <TableCell>
                        <Box px={1}>
                          <DKTCheckbox
                            color="primary"
                            sx={{ padding: 0 }}
                            checked={
                              tableData.length > 0 &&
                              equal(selected.length, tableData.length)
                            }
                            onChange={handleSelectAllClick}
                            inputProps={{
                              'aria-label': 'select all desserts',
                            }}
                            disabled={hasOnlyViewPermission}
                          />
                        </Box>
                      </TableCell>
                      {narrativeBasedHeader?.map((heading, index) => (
                        <TableCell
                          key={index}
                          sx={{ padding: ' 0 0 0 20px', height: '40px' }}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <Droppable droppableId="narrativeBased">
                    {(provided) => (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {tableData?.map((cell, index) => {
                          const isItemSelected =
                            selected.indexOf(cell.id) !== -1
                          const labelId = `enhanced-table-checkbox-${index}`
                          return (
                            <Draggable
                              key={cell.id}
                              draggableId={cell.id.toString()}
                              index={index}
                              isDragDisabled={false}
                            >
                              {(provided) => (
                                <TableRow
                                  className={classes.tableSize}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TableCell sx={{ verticalAlign: 'initial' }}>
                                    <Box className={classes.dragIcon}>
                                      {!hasOnlyViewPermission && <DragIcon />}
                                    </Box>
                                  </TableCell>
                                  <TableCell sx={{ verticalAlign: 'initial' }}>
                                    <Box px={1}>
                                      <DKTCheckbox
                                        color="primary"
                                        checked={isItemSelected}
                                        onChange={(e) =>
                                          handleRowSelect(e, cell.id)
                                        }
                                        sx={{ padding: 0 }}
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                        disabled={hasOnlyViewPermission}
                                      />
                                    </Box>
                                  </TableCell>

                                  {Object?.entries(cell)?.map((data) => {
                                    const cellId = cell?.id
                                    if (
                                      !equal(data[0], 'id') &&
                                      !equal(data[0], 'sortIndex')
                                    ) {
                                      return ternary(
                                        equal(data[0], 'status'),
                                        <TableCell
                                          sx={{
                                            paddingBlock: 0.5,
                                            verticalAlign: 'top',
                                          }}
                                        >
                                          <DKTSelect
                                            displayEmpty
                                            name={data[0]}
                                            id={cellId}
                                            value={
                                              values[cellId]
                                                ? values[cellId][data[0]]
                                                : '0'
                                            }
                                            placeholder="Select"
                                            showLabel={false}
                                            className={classes.stateDropdown}
                                            options={options}
                                            onChange={(event) =>
                                              handleChange(event, cellId, index)
                                            }
                                            selectSx={
                                              values[cellId]
                                                ? {
                                                    backgroundColor: `${
                                                      dynamicColor[
                                                        values[cellId][data[0]]
                                                      ]
                                                    }`,
                                                    color: ternary(
                                                      equal(
                                                        dynamicColor[
                                                          values[cellId][
                                                            data[0]
                                                          ]
                                                        ],
                                                        '#1564FF',
                                                      ),
                                                      'white',
                                                      '#333333',
                                                    ),
                                                  }
                                                : {}
                                            }
                                            formControlProps={{
                                              sx: { height: '45px' },
                                            }}
                                            expandIconColor={ternary(
                                              values[cellId] &&
                                                equal(
                                                  dynamicColor[
                                                    values[cellId][data[0]]
                                                  ],
                                                  dynamicColor['2'],
                                                ),
                                              'white',
                                              '#333333',
                                            )}
                                            disabled={hasOnlyViewPermission}
                                          />
                                        </TableCell>,
                                        <TableCell
                                          sx={{
                                            paddingBlock: 0.5,
                                            verticalAlign: 'top',
                                          }}
                                        >
                                          {ternary(
                                            equal(data[0], 'summary'),
                                            <DKTTextArea
                                              placeholder={ternary(
                                                hasOnlyViewPermission,
                                                '',
                                                'Type Text Here',
                                              )}
                                              sx={{ minHeight: '45px' }}
                                              rows={1.1}
                                              name={data[0]}
                                              id={cellId}
                                              value={
                                                values[cellId]
                                                  ? values[cellId][data[0]]
                                                  : ''
                                              }
                                              className={
                                                classes.summaryTypeInput
                                              }
                                              onChange={(event) =>
                                                handleChange(
                                                  event,
                                                  cellId,
                                                  index,
                                                )
                                              }
                                              disabled={hasOnlyViewPermission}
                                            />,
                                            <DKTInput
                                              type="text"
                                              name="descriptionOfWork"
                                              className={classes.input}
                                              value={
                                                (values[cellId]
                                                  ?.descriptionOfWork ??
                                                  data[1]) ||
                                                ''
                                              }
                                              onChange={(event) =>
                                                handleChange(
                                                  event,
                                                  cellId,
                                                  index,
                                                )
                                              }
                                              disabled={hasOnlyViewPermission}
                                              dateSx={{
                                                '& .MuiOutlinedInput-notchedOutline':
                                                  {
                                                    border: 'none !important',
                                                  },
                                                marginTop: '-18px !important',
                                              }}
                                            />,
                                          )}
                                        </TableCell>,
                                      )
                                    }
                                    return null
                                  })}
                                </TableRow>
                              )}
                            </Draggable>
                          )
                        })}
                        {ternary(
                          !tableData?.length,
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              sx={{
                                fontSize: '1rem',
                                textAlign: 'center',
                                height: '56.5px',
                              }}
                            >
                              Sorry, there is no matching data to display
                            </TableCell>
                          </TableRow>,
                          null,
                        )}
                      </TableBody>
                    )}
                  </Droppable>
                </Table>
              </div>
            </TableContainer>
          </DragDropContext>
          <DKTButton
            variant="text"
            color="primary"
            className={classes.addDesc}
            startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
            onClick={handleAddDescription}
            disabled={hasOnlyViewPermission}
          >
            Add Description
          </DKTButton>
          {/* show warning modal when Delete a row */}
          <DKTDialog
            open={isDeleteNotificationOpen}
            handleClose={closeDeleteNotification}
            title="&nbsp;"
            actions={deleteNotificationActions}
            maxWidth="xs"
          >
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.deleteNotificationContent}>
                  <Typography variant="h5">
                    Are you sure you want to delete?
                  </Typography>
                  <Typography variant="body1">
                    You can&apos;t undo this action
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DKTDialog>
        </>
      )}
    </>
  )
}

export default memo(NarrativeBased)
