import { Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formFields } from '../../Description/support.description'
import useForm from '../../Hooks/useForm'
import { getProjectChoices } from '../../Redux/actions/choices'
import { updateOnSaveStatus } from '../../Redux/actions/confirmation'
import { saveReportIssue } from '../../Redux/actions/support'
import DKTButton from '../../Shared/DKTButton'
import DKTConfirmNavigateShowModal from '../../Shared/DKTConfirmNavigateShowModal'
import DKTDialog from '../../Shared/DKTDialog'
import DKTFileInput from '../../Shared/DKTFileInput'
import DKTInput from '../../Shared/DKTInput'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import DKTSelect from '../../Shared/DKTSelect'
import DKTForm from '../../Shared/DKTForm'
import { useStyles } from '../../Styles/projects.style'
import { submit } from '../../Utils/constant'
import {
  entries,
  equal,
  handleTextEditor,
  keys,
  ternary,
} from '../../Utils/javascript'
import DKTEditor from '../../Shared/DKTEditor'

const SupportFormModal = ({ open, handleOpenCloseModal }) => {
  const [photographs, setPhotographs] = useState([])
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)

  const {
    values,
    errors,
    setErrors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useForm(formFields)

  const dispatch = useDispatch()
  const { isReportIssueLoading } = useSelector(
    ({ reportAnIssue }) => reportAnIssue,
  )
  const { choices } = useSelector(({ projectChoices }) => projectChoices)

  useEffect(() => {
    if (!choices?.issueType) {
      dispatch(getProjectChoices({ fieldName: 'issueType' }))
    }
  }, [dispatch, choices?.issueType?.length])

  useEffect(() => {
    if (open) {
      setIsUnSavedChanges(
        !!Object.values(values)?.filter(Boolean).length ||
          !!photographs?.length,
      )
    }
  }, [open, values, photographs])

  const onMultipleFileUpload = (base64Files) => {
    setPhotographs([...photographs, ...base64Files])
  }

  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    setPhotographs([])
    handleOpenCloseModal(false)
    resetForm()
  }

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }

  const handleCloseModal = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    setErrors({})
    resetForm()
    handleOpenCloseModal(false)
  }

  const handleDelete = () => {
    setPhotographs([])
  }

  const handleSave = () => {
    const isFormValid = handleSubmit()
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
      return
    }
    const formData = new FormData()
    keys(values)?.forEach((key) => {
      formData.append(key, values?.[key])
    })

    photographs?.forEach((photo) => {
      formData.append('attachments', photo)
    })
    dispatch(saveReportIssue(formData, confirmSaveWarningModal))
  }
  const classes = useStyles()
  const mapFormFields = ({ formFields }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            options,
            isConfirmPassword,
            isSeparate,
            separateSectionTitle,
            isPopUp,
            popupText,
            dependsOn,
            defaultValue,
            ...formField
          },
        ],
        index,
      ) => {
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <Grid
            key={index}
            item
            lg={formField.lg ?? 12}
            xs={formField.xs ?? 12}
          >
            <FormControl
              {...formField}
              options={ternary(
                isDynamicOptions,
                choices?.[name] || [],
                options,
              )}
              isRequired={isRequired}
              disabled={formField?.disabled}
              id={name}
              name={name}
              value={(values && values[name]) || defaultValue || ''}
              onChange={handleChange}
              error={errors[name]}
            />
          </Grid>
        )
      },
    )

  return (
    <>
      <DKTDialog
        open={open}
        handleClose={handleCloseModal}
        title="Report an Issue"
        maxWidth="sm"
      >
        <DKTForm autoComplete="off">
          <Grid container spacing={2} mb={1.4}>
            {mapFormFields({ formFields })}
          </Grid>
        </DKTForm>
        <Box component="form" enctype="multipart/form-data">
          <DKTFileInput
            onMultipleFileUpload={onMultipleFileUpload}
            multiple
            height={50}
            label="Attachment"
            accept="image/*, video/*, .pdf, .csv"
            isMultiFileUpload
            name="multiFile"
          />
          {!!photographs?.length && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box mx={2}>
                {ternary(
                  equal(photographs?.length, 1),
                  `${photographs?.length} file uploaded`,
                  `${photographs?.length} files uploaded`,
                )}
              </Box>
              <IconButton color="error" onClick={handleDelete}>
                <Delete />
              </IconButton>
            </Stack>
          )}
        </Box>

        <DKTEditor
          title="Description"
          value={values?.description || ''}
          onChange={(event, editor) => {
            handleTextEditor(editor, 'description', setFieldValue)
          }}
          className={`${classes.projectDesc} description`}
        />
        <Stack direction="row" justifyContent="flex-end" mt={1.5}>
          <DKTButton disabled={isReportIssueLoading} onClick={handleSave}>
            {submit}
          </DKTButton>
        </Stack>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnSavedWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isUnSavedChanges} onSave={handleSave} />
    </>
  )
}

export default memo(SupportFormModal)
