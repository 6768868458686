export const options = {
  responsive: 'standard',
  filter: false,
  print: false,
  download: false,
  viewColumns: false,
  selectableRows: 'none',
  pagination: false,
}

export const columns = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'projectName',
    label: 'Project Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'city',
    label: 'City',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'state',
    label: 'State',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'projectNumberInternal',
    label: 'Project Number',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'engagementType',
    label: 'Engagement',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'associatedClients',
    label: 'Associated Clients',
    options: {
      filter: true,
      sort: true,
    },
  },
]
export const associatedClientColumnsDesc = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'projectName',
    label: 'Project Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'clientCompany',
    label: 'Client Company',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'city',
    label: 'City',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'state',
    label: 'State',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'engagementType',
    label: 'Engagement Type',
    options: {
      filter: true,
      sort: true,
    },
  },
]

export const formFields = {
  clientName: {
    client: {
      type: 'select',
      label: 'Client Company',
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      lg: 3,
      xs: 3,
    },
  },
  ProjectAccessFields: {
    client: {
      type: 'select',
      label: 'Client',
      disabled: true,
      isRequired: true,
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
    projectName: {
      type: 'select',
      label: 'Project Name',
      isRequired: true,
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
    projectNumberInternal: {
      type: 'text',
      label: 'Project Number - Internal',
      disabled: true,
    },
    engagementType: {
      type: 'select',
      label: 'Engagement Type',
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
  },
}
