import { Box, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTButton from '../../../Shared/DKTButton'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import { formFields } from '../../../Description/constructionSummary.description'
import { entries, equal, isArray, ternary } from '../../../Utils/javascript'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTInput from '../../../Shared/DKTInput'
import useForm from '../../../Hooks/useForm'
import { getReportChoices } from '../../../Redux/actions/reportChoices'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { useStyles } from '../../../Styles/budgetSummary.style'
import NarrativeBased from './NarrativeBased'
import FreeForm from './FreeForm'
import PhaseBased from './PhaseBased'
import {
  clearConstructionSummary,
  createConstructionSummary,
  fetchConstructionSummaryDataRequested,
  fetchConstructionSummaryDataSuccess,
  getConstructionSummary,
  updateConstructionSummary,
} from '../../../Redux/actions/constructionSummary'
import {
  fetchConstructionSummaryFormat,
  fetchConstructionSummaryFormatRequested,
} from '../../../Redux/actions/constructionMonitoringReport'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTCheckbox from '../../../Shared/DKTCheckbox'
import { constructionSummaryFormateFeatures } from '../../../Description/constructionSummaryFormat.description'
import ManpowerTracker from '../trackers/ManpowerTracker'
import usePermission from '../../../Hooks/usePermission'

const optionsFields = ['ownerContractor']

export const ManpowerTrackerButton = ({ showHideManpowerTracker, sx }) => (
  <Typography
    variant="subtitle2"
    component="span"
    color="primary"
    sx={{
      cursor: 'pointer',
      width: 'fit-content',
      marginLeft: 'auto',
      display: 'inherit',
      ...sx,
    }}
    onClick={() => showHideManpowerTracker(true)}
  >
    Labor Tracker
  </Typography>
)

const ConstructionSummary = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { slug, reportId } = useParams()
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [ownerContractorId, setOwnerContractorId] = useState(null)
  const [dataForUpdate, setDataForUpdate] = useState(null)
  const [constructionSummaryId, setConstructionSummaryId] = useState(null)
  const [memorizedPhaseBaseTable, setMemorizedPhaseBaseTable] = useState(null)
  const [narrativeBasedFormIsDirty, setNarrativeBasedFormIsDirty] =
    useState(false)
  const [phaseBasedFormIsDirty, setPhaseBasedFormIsDirty] = useState(false)
  const [deleteUnSaved, setDeleteUnSaved] = useState(false)
  const [freeFormIsDirty, setFreeFormIsDirty] = useState(false)
  const [isFormIncludeReport, setIsFormIncludeReport] = useState(false)
  const [ckEditorChange, setCkEditorChange] = useState(false)
  const [constructionSummaryFeatures, setConstructionSummaryFeatures] =
    useState([])
  const [isDataDeleted, setIsDataDeleted] = useState(false)
  const [isManPowerTrackerOpen, setIsManPowerTrackerOpen] = useState(false)

  const { reportData, isReportListLoading } = useSelector(
    ({ reportMonitoring }) => reportMonitoring,
  )
  const { choices } = useSelector(({ reportChoices }) => reportChoices)
  const {
    constructionSummaryData,
    isConstructionSummaryLoading,
    isConstructionSummaryUpdateLoading,
    isImportingPhaseBasedComments,
  } = useSelector(({ constructionSummary }) => constructionSummary)

  const { hasOnlyViewPermission } = usePermission()

  const { constructionSummaryFormat, isFetchingConstructionSummaryFormat } =
    useSelector(
      ({ constructionMonitoringReport }) => constructionMonitoringReport,
    )

  const { values, errors, handleChange, setFieldValue } = useForm({
    ...formFields.description,
  })
  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionsFields?.join(','),
        projectId: slug,
      }),
    )
    setConstructionSummaryFeatures(
      constructionSummaryFormateFeatures.map((item) => ({
        id: Math.floor(new Date().valueOf() * Math.random()),
        workDescription: item,
      })),
    )
  }, [dispatch, slug])

  useEffect(() => {
    dispatch(fetchConstructionSummaryFormat())
    return () => {
      setDataForUpdate(null)
      setConstructionSummaryId(null)
      setMemorizedPhaseBaseTable(null)
      // Reset construction summary redux state
      dispatch(fetchConstructionSummaryDataSuccess(null))
      dispatch(fetchConstructionSummaryDataRequested())
      dispatch(fetchConstructionSummaryFormatRequested())
    }
  }, [dispatch, ownerContractorId])

  useEffect(() => {
    if (choices) {
      const dynamicDropdown = (ary) => {
        const res = ary?.map((element) => ({
          label: element?.uniqueCode || element?.projectName,
          value: `${element?.id}`,
        }))
        return res
      }
      const ownerContractor = dynamicDropdown(choices?.ownerContractor)
      setDynamicOptions({
        ...choices,
        ownerContractor,
      })
    }
  }, [choices])

  useEffect(() => {
    let activeContractor = null
    if (isArray(choices?.ownerContractor) && choices?.ownerContractor?.length) {
      activeContractor = choices?.ownerContractor[0]?.id
    }
    setOwnerContractorId(activeContractor)
  }, [choices?.ownerContractor[0]?.id])

  useEffect(() => {
    if (reportId && ownerContractorId && slug) {
      dispatch(getConstructionSummary(reportId, ownerContractorId, slug))
    }
  }, [dispatch, reportId, ownerContractorId, slug])

  useEffect(() => {
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    setFieldValue('ownerContractorDescription', activeContractorDescription)
    setFieldValue('summaryType', '1')
  }, [ownerContractorId])

  useEffect(() => {
    if (constructionSummaryData) {
      const {
        narrativeBased,
        phaseBased,
        freeForm,
        id,
        comments,
        narrativeIncludeReport,
        phaseIncludeReport,
        freeFormIncludeReport,
      } = constructionSummaryData
      setConstructionSummaryId(id)
      setDataForUpdate({
        id,
        narrativeBased,
        phaseBased,
        freeForm,
        comments,
        report: reportId,
        ownerContractor: ownerContractorId,
        narrativeIncludeReport,
        phaseIncludeReport,
        freeFormIncludeReport,
      })
    }
  }, [constructionSummaryData, reportId, ownerContractorId])

  useEffect(() => {
    if (constructionSummaryData) {
      const {
        freeFormIncludeReport,
        narrativeIncludeReport,
        phaseIncludeReport,
      } = constructionSummaryData
      const isChanged =
        dataForUpdate?.freeFormIncludeReport !== freeFormIncludeReport ||
        dataForUpdate?.narrativeIncludeReport !== narrativeIncludeReport ||
        dataForUpdate?.phaseIncludeReport !== phaseIncludeReport
      setIsFormIncludeReport(isChanged)
    }
  }, [
    dataForUpdate?.freeFormIncludeReport,
    dataForUpdate?.narrativeIncludeReport,
    dataForUpdate?.phaseIncludeReport,
    constructionSummaryData,
  ])

  // clear construction summary
  useEffect(
    () => () => {
      dispatch(clearConstructionSummary())
    },
    [],
  )

  const showHideManpowerTracker = (status) => setIsManPowerTrackerOpen(status)

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            defaultValue,
            options,
            isConfirmPassword,
            dependsOn,
            ...formField
          },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        if (equal(formField.type, 'checkbox')) {
          const checkBoxName =
            values.summaryType === '1'
              ? 'narrativeIncludeReport'
              : values.summaryType === '2'
              ? 'phaseIncludeReport'
              : 'freeFormIncludeReport'
          return formField?.fields?.map((field, index) => (
            <React.Fragment key={index} item xs={formField.xs ?? 12}>
              <DKTCheckbox
                name={checkBoxName}
                checked={equal(dataForUpdate?.[checkBoxName], true)}
                value={dataForUpdate?.[checkBoxName] || ''}
                onChange={(e) =>
                  setDataForUpdate({
                    ...dataForUpdate,
                    [e.target.name]: e.target.checked,
                  })
                }
                label={field?.label}
                className={classes.labelledCheckbox}
                disabled={hasOnlyViewPermission}
                {...formField}
              />
            </React.Fragment>
          ))
        }
        return (
          <Grid
            key={index}
            item
            xs={formField.xs ?? 12}
            lg={formField.lg ?? 12}
          >
            <FormControl
              {...formField}
              options={ternary(
                isDynamicOptions,
                dynamicOptions[name] || [],
                options,
              )}
              isRequired={isRequired}
              id={name}
              name={name}
              value={(values && values[name]) || defaultValue || ''}
              onChange={handleChange}
              error={errors[name]}
              disabled={formField.disabled}
            />
          </Grid>
        )
      },
    )
  const renderFormFields = (formFields) => (
    <Grid container width="100%" spacing={2}>
      {isArray(formFields)
        ? formFields.map((formField) =>
            mapFormFields({
              formFields: formField.data,
              makeFieldNameUnique: true,
              id: formField.id,
            }),
          )
        : mapFormFields({ formFields })}
    </Grid>
  )
  // For save construction summary
  const handleSave = () => {
    const body = { ...dataForUpdate }
    setNarrativeBasedFormIsDirty(false)
    setPhaseBasedFormIsDirty(false)
    setFreeFormIsDirty(false)
    setDeleteUnSaved(false)
    setCkEditorChange(false)
    setIsDataDeleted(false)
    if (constructionSummaryId) {
      dispatch(
        updateConstructionSummary(
          body,
          constructionSummaryId,
          reportId,
          ownerContractorId,
          slug,
        ),
      )
    } else {
      dispatch(
        createConstructionSummary(body, reportId, ownerContractorId, slug),
      )
    }
  }

  const handleCollectDataFromChild = (args) => {
    setDataForUpdate((prev) => ({
      ...prev,
      ...args,
      report: reportId,
      ownerContractor: ownerContractorId,
    }))
  }

  const handleCollectTableStructure = (args) => {
    setMemorizedPhaseBaseTable({
      ...args,
    })
  }

  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" gap={2}>
          <DKTButtonSelect
            options={actionList}
            disabled={hasOnlyViewPermission || true}
          />
          <DKTButton
            className={classes.rightButton}
            onClick={handleSave}
            disabled={
              hasOnlyViewPermission ||
              isConstructionSummaryLoading ||
              isConstructionSummaryUpdateLoading
            }
          >
            {ternary(isConstructionSummaryUpdateLoading, 'Saving...', 'Save')}
          </DKTButton>
        </Stack>
      </Box>
    </>
  )

  const actionList = [{ label: 'Delete', onClick: () => null }]
  return (
    <InnerLayout
      contentTitle="Construction summary"
      actions={actions}
      maxWidth="md"
      isShowMenu
    >
      {equal(isConstructionSummaryLoading, true) ||
      equal(isFetchingConstructionSummaryFormat, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isConstructionSummaryLoading, 'FAILED') ||
        equal(isFetchingConstructionSummaryFormat, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Construction summary data.
            Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={10}>
              {renderFormFields(formFields.constructionSummaryHeaderFields)}
            </Grid>
          </Grid>
          {ternary(
            !isConstructionSummaryLoading ||
              !isFetchingConstructionSummaryFormat ||
              !reportData,
            <Grid container>
              {ternary(
                equal(values?.summaryType, '1'),
                <Grid item xs={12} lg={10} mt={4}>
                  <ManpowerTrackerButton
                    showHideManpowerTracker={showHideManpowerTracker}
                    sx={{ marginBottom: 1.475 }}
                  />
                  <NarrativeBased
                    handleCollectDataFromChild={handleCollectDataFromChild}
                    constructionSummaryData={constructionSummaryData}
                    constructionSummaryFormat={constructionSummaryFormat}
                    dataForUpdate={dataForUpdate}
                    hasOnlyViewPermission={hasOnlyViewPermission}
                    setNarrativeBasedFormIsDirty={setNarrativeBasedFormIsDirty}
                    setDataForUpdate={setDataForUpdate}
                    isConstructionSummaryLoading={isConstructionSummaryLoading}
                    constructionSummaryFeatures={constructionSummaryFeatures}
                    {...{ isDataDeleted, setIsDataDeleted }}
                  />
                </Grid>,
                null,
              )}
              {ternary(
                equal(values?.summaryType, '2'),
                <Grid item xs={12} lg={12} mt={4}>
                  <PhaseBased
                    handleCollectDataFromChild={handleCollectDataFromChild}
                    constructionSummaryData={constructionSummaryData}
                    constructionSummaryFormat={constructionSummaryFormat}
                    dataForUpdate={dataForUpdate}
                    handleCollectTableStructure={handleCollectTableStructure}
                    memorizedPhaseBaseTable={memorizedPhaseBaseTable}
                    isConstructionSummaryLoading={isConstructionSummaryLoading}
                    isFetchingConstructionSummaryFormat={
                      isFetchingConstructionSummaryFormat
                    }
                    isReportListLoading={isReportListLoading}
                    setPhaseBasedFormIsDirty={setPhaseBasedFormIsDirty}
                    deleteUnSaved={deleteUnSaved}
                    setDeleteUnSaved={setDeleteUnSaved}
                    isImportingPhaseBasedComments={
                      isImportingPhaseBasedComments
                    }
                    ownerContractorId={ownerContractorId}
                    reportId={reportId}
                    setCkEditorChange={setCkEditorChange}
                    setDataForUpdate={setDataForUpdate}
                    constructionSummaryFeatures={constructionSummaryFeatures}
                    showHideManpowerTracker={showHideManpowerTracker}
                  />
                </Grid>,
                null,
              )}
              {ternary(
                equal(values?.summaryType, '3'),
                <Grid item xs={12} lg={8} mt={4}>
                  <Box className={classes.payDesc}>
                    <FreeForm
                      handleCollectDataFromChild={handleCollectDataFromChild}
                      constructionSummaryData={constructionSummaryData}
                      dataForUpdate={dataForUpdate}
                      ownerContractorId={ownerContractorId}
                      hasOnlyViewPermission={hasOnlyViewPermission}
                      setFreeFormIsDirty={setFreeFormIsDirty}
                      showHideManpowerTracker={showHideManpowerTracker}
                    />
                  </Box>
                </Grid>,
                null,
              )}
            </Grid>,
            <Stack height="50vh" alignItems="center" justifyContent="center">
              <DKTCircularProgress />
            </Stack>,
          )}
        </>
      )}

      {isManPowerTrackerOpen && (
        <ManpowerTracker
          open={isManPowerTrackerOpen}
          onClose={() => showHideManpowerTracker(false)}
          projectId={slug}
          {...{ ownerContractorId, reportId, hasOnlyViewPermission }}
        />
      )}
      {/* show modal when tries to navigate without save data */}
      {!hasOnlyViewPermission && (
        <DKTReactRouterPrompt
          isDirty={
            narrativeBasedFormIsDirty ||
            phaseBasedFormIsDirty ||
            freeFormIsDirty ||
            deleteUnSaved ||
            isFormIncludeReport ||
            ckEditorChange
          }
          onSave={handleSave}
        />
      )}
    </InnerLayout>
  )
}

export default ConstructionSummary
