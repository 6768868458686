import { Grid, Typography } from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  phaseTrackerColumns,
  phaseTrackerOptions,
} from '../../../Description/constructionScheduleReport.description'

import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import { useStyles } from '../../../Styles/constructionScheduleReport.style'
import { checkIncludes, equal, ternary } from '../../../Utils/javascript'
import AddPhase from './AddPhase'

const clonedColumns = [...phaseTrackerColumns]

const PhaseTracker = ({ ocId, hasOnlyViewPermission }) => {
  const [phaseToEdit, setPhaseToEdit] = useState(null)
  const [isAddPhaseModalOpen, setIsAddPhaseModalOpen] = useState(false)

  const classes = useStyles()
  const { phaseTracker, isFetchingPhaseTracker } = useSelector(
    ({ constructionScheduleReport }) => constructionScheduleReport,
  )

  const openAddPhaseModal = () => setIsAddPhaseModalOpen(true)
  const closeAddPhaseModal = () => setIsAddPhaseModalOpen(false)

  const handleEditPhase = (row) => {
    if (hasOnlyViewPermission) return
    setPhaseToEdit(row)
    openAddPhaseModal()
  }

  const handleAddPhase = () => {
    if (hasOnlyViewPermission) return
    setPhaseToEdit(null)
    openAddPhaseModal()
  }

  const customBodyRender = (value, tableMeta) =>
    ternary(
      equal(tableMeta.columnData.name, 'phaseDescription'),
      <Typography
        variant="subtitle2"
        component="span"
        color={ternary(hasOnlyViewPermission, 'gray', 'primary')}
        fontWeight={400}
        className={classNames({
          [classes.addressDialog]: !hasOnlyViewPermission,
        })}
        onClick={() => handleEditPhase(phaseTracker[tableMeta.rowIndex])}
      >
        {value}
      </Typography>,
      checkIncludes(tableMeta.columnData.name, [
        'originalStart',
        'originalFinish',
        'actualStart',
        'actualFinish',
      ])
        ? value
          ? moment(value).format('MM/DD/YY')
          : '-'
        : value,
    )

  const tableColumns = clonedColumns.map((column) => ({
    ...column,
    options: {
      ...column.options,
      customBodyRender,
    },
  }))

  const tableOptions = {
    ...phaseTrackerOptions,
    textLabels: {
      body: {
        noMatch: ternary(
          isFetchingPhaseTracker,
          <DKTCircularProgress />,
          'Sorry, there is no matching data to display',
        ),
      },
    },
  }

  return (
    <Grid item xs={12} lg={12} mt={1.5}>
      <Typography
        variant="body2"
        color="gray.extraDark"
        sx={{ fontWeight: 'medium', mb: 1.5 }}
      >
        Phase Tracker (Multi-Phase Project)
      </Typography>
      {isFetchingPhaseTracker === 'FAILED' ? (
        <Typography variant="body2" color="gray.extraDark">
          There might be some issue with fetching phase tracker data. Please try
          contacting the admin or refreshing this page.
        </Typography>
      ) : (
        <>
          <DKTMuiDataTable
            columns={tableColumns}
            data={phaseTracker}
            options={tableOptions}
          />
          <DKTButton
            variant="text"
            color="primary"
            sx={{ textTransform: 'initial', px: 1, ml: '10px' }}
            startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
            onClick={handleAddPhase}
            disabled={hasOnlyViewPermission}
          >
            <Typography>Add Phase</Typography>
          </DKTButton>
        </>
      )}

      {/* Add phase modal */}
      {isAddPhaseModalOpen && (
        <AddPhase
          open={isAddPhaseModalOpen}
          onClose={closeAddPhaseModal}
          phaseToEdit={phaseToEdit}
          ocId={ocId}
          hasOnlyViewPermission={hasOnlyViewPermission}
        />
      )}
    </Grid>
  )
}

export default PhaseTracker
