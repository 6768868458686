import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Delete } from '@mui/icons-material'
import { useTheme } from '@mui/styles'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTButton from '../../../Shared/DKTButton'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import {
  columns,
  formFields,
  options,
} from '../../../Description/budgetSummary.description'
import {
  checkIncludes,
  equal,
  handleBlurAll,
  isArray,
  isEmptyString,
  keys,
  lt,
  modifyNumber,
  ternary,
} from '../../../Utils/javascript'
import useForm from '../../../Hooks/useForm'
import { getReportChoices } from '../../../Redux/actions/reportChoices'
import { SA, publish, spinnerSize } from '../../../Utils/constant'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import CreateBudgetSummary from './CreateBudgetSummary'
import { useStyles } from '../../../Styles/budgetSummary.style'
import { getProjectChoices } from '../../../Redux/actions/choices'
import {
  createBudgetSummary,
  deleteBudgetSummary,
  fetchBudgetSummarySuccess,
  getBudgetSummary,
  getBudgetSummaryNumber,
  getBudgetSummaryReferenceValues,
  updateBudgetSummary,
} from '../../../Redux/actions/budgetSummary'
import { emptyTableCellPlaceholder } from '../../../Description/reportMonitoring.description'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { CLEAR_BUDGET_SUMMARY } from '../../../Redux/constants/budgetSummary'
import { CLEAR_REPORT_TRACKER } from '../../../Redux/constants/reportTrackers'
import { showToast } from '../../../Utils/toastService'
import usePermission from '../../../Hooks/usePermission'

const optionsFields = ['ownerContractor']
const reportOptionsQuery = [
  'drawNumber',
  'changeOrderNumber',
  'issuerType',
  'storedMaterialsOnSite',
  'storedMaterialsOffSite',
  'documentationReceived',
  'payApplicationNumber',
  'disbursementRecommendation',
  'depositDocumentationReceived',
  'override',
]

const BudgetSummary = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const query = useSearchParams()[0]
  const isCreateSummary = query.get('create-summary')
  const { slug, reportId } = useParams()
  const theme = useTheme()
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [usedDrawNumbers, setUsedDrawNumbers] = useState([])
  const [ownerContractorId, setOwnerContractorId] = useState(null)
  const [budgetSummaryId, setBudgetSummaryId] = useState(null)
  const [budgetSummaryTableData, setBudgetSummaryTableData] = useState([])
  const [payAppAry, setPayAppAry] = useState([])
  const [isLocal, setIsLocal] = useState(true)
  const [localData, setLocalData] = useState(null)
  const [payAppId, setPayAppId] = useState(payAppAry[0]?.id || null)
  const [deletePermitValue, setDeletePermitValue] = useState(null)
  const [deleteNotificationOpen, setOpenDeleteNotification] =
    React.useState(false)
  const [budgetSummaryNotificationOpen, setBudgetSummaryNotificationOpen] =
    React.useState(false)
  const [isShowFormBudgetSummary, setIsShowFormBudgetSummary] =
    React.useState(false)
  const [isSaveWarningOpen, setIsSaveWarningOpen] = useState(false)
  const [isCreateBS, setIsCreateBS] = useState(false)
  const [tempOcData, setTempOcData] = useState({})
  const [showTrackers, setShowTrackers] = useState(true)

  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)

  const {
    budgetSummaryData,
    budgetSummaryError,
    isBudgetSummaryLoading,
    isBudgetSummaryDeleting,
    isCreateAndUpdateBudgetSummaryLoading,
    budgetSummaryReference,
    budgetSummaryNumberData,
    isBudgetSummaryNumberLoading,
    isFetchingBudgetSummaryReferenceValues,
  } = useSelector(({ budgetSummary }) => budgetSummary)
  const { choices: dynamicChoices } = useSelector(
    ({ projectChoices }) => projectChoices,
  )

  const { systemAuthorization } = useSelector(({ auth }) => auth)

  const { hasOnlyViewPermission, isGlobalViewer, isProjectViewer } =
    usePermission()

  const reportToRemove = useRef(null)
  const setSelectedRows = useRef(() => {})

  const {
    values,
    errors,
    handleChange,
    setErrors,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    setDummyData,
    isDirty,
    setIsDirty,
    setIsNavigate,
  } = useForm(
    {
      ...formFields.description,
      ...formFields?.draw,
      ...formFields?.changeOrder,
      ...formFields?.lienWaivers,
      ...formFields?.paymentApplicationColumnLeft,
      ...formFields?.paymentApplicationColumnRight,
      ...formFields?.pcoChangeOrder,
      ...formFields?.storedMaterials,
      ...formFields?.paymentApplicationComments,
    },
    CLEAR_BUDGET_SUMMARY,
  )
  const cloneData = budgetSummaryData?.find(({ payApplicationNumber }) =>
    equal(payApplicationNumber, payAppId),
  )
  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionsFields?.join(','),
        projectId: slug,
      }),
    )
  }, [dispatch, slug])

  useEffect(() => {
    if (slug && reportId && ownerContractorId) {
      dispatch(
        getBudgetSummaryNumber({
          projectId: slug,
          reportId,
          ocId: ownerContractorId,
          budgetSummaryId: cloneData ? cloneData?.id : null,
          isLoading: false,
        }),
      )
    }
  }, [ownerContractorId, payAppId])

  useEffect(() => {
    if (slug && reportId && ownerContractorId) {
      dispatch(
        getBudgetSummaryReferenceValues({
          projectId: slug,
          reportId,
          ocId: ownerContractorId,
        }),
      )
    }
  }, [slug, reportId, ownerContractorId])

  useEffect(() => {
    dispatch(getProjectChoices({ fieldName: reportOptionsQuery.join(',') }))
  }, [dispatch, slug])

  useEffect(() => {
    if (reportId && ownerContractorId) {
      const handleRedirect = () => {
        if (isCreateSummary) {
          navigate(
            `/projects/${slug}/project-reports/${reportId}/budget-summary/`,
          )
        }
      }
      dispatch(
        getBudgetSummary(reportId, ownerContractorId, slug, handleRedirect),
      )
    }
    // for always get updated list without flicker
    return () => {
      dispatch(fetchBudgetSummarySuccess([]))
    }
  }, [dispatch, slug, ownerContractorId, reportId])

  // Check if the current payApp is the Change app
  useEffect(() => {
    const shouldDisableAndRemoveTracker =
      !budgetSummaryData?.length ||
      equal(+budgetSummaryData[0].payApplicationNumber, +payAppId)

    setShowTrackers(shouldDisableAndRemoveTracker)
  }, [budgetSummaryData, payAppId])

  // To confirm that the data is local or not
  useEffect(() => {
    const data = budgetSummaryData?.find((summary) =>
      equal(summary?.payApplicationNumber, payAppId),
    )
    if (data) {
      setIsLocal(false)
    } else {
      setIsLocal(true)
    }
  }, [payAppId])

  useEffect(() => {
    const data = budgetSummaryData?.find((summary) =>
      equal(summary?.payApplicationNumber, payAppId),
    )
    if (!data && isLocal) {
      setLocalData({
        ...values,
        ...budgetSummaryReference,
        contractSum: budgetSummaryReference?.contractSum ?? '',
        amendedDaysTotal: budgetSummaryReference?.amendedDaysTotal ?? '',
        changeOrderTotal: budgetSummaryReference?.changeOrderTotal ?? '',
        changeOrderPrevious: '',
        amendedDaysPrevious: '',
      })
    } else {
      setLocalData(null)
    }
  }, [payAppId, values, budgetSummaryData])

  // For set delete permit value of budget summary landing page
  useEffect(() => {
    const tableData = budgetSummaryData
      ?.sort((a, b) =>
        a?.payApplicationNumber > b?.payApplicationNumber ? 1 : -1,
      )
      ?.map((data) => {
        if (equal(+data.payApplicationNumber, deletePermitValue)) {
          return { ...data, isPermitToDelete: true }
        }
        return data
      })
    setBudgetSummaryTableData(tableData)
  }, [budgetSummaryData, payAppId, isBudgetSummaryDeleting, deletePermitValue])

  const handleDynamicError = (err) => {
    setTimeout(() => {
      setErrors((prev) => ({ ...prev, ...err }))
    }, 500)
  }

  // For set dynamic error
  useEffect(() => {
    if (budgetSummaryError) {
      const errorData = { ...errors }
      Object.entries(budgetSummaryError)?.forEach(
        ([errorName, [errorDesc]]) => {
          errorData[errorName] = errorDesc
        },
      )
      handleDynamicError(errorData)
    }
  }, [budgetSummaryError, isCreateAndUpdateBudgetSummaryLoading])

  // For get dynamic options
  useEffect(() => {
    if (choices || dynamicChoices) {
      const dynamicDropdown = (ary) => {
        const res = ary?.map((element) => ({
          label: element?.uniqueCode || element?.projectName,
          value: `${element?.id}`,
        }))
        return res
      }
      const ownerContractor = dynamicDropdown(choices?.ownerContractor)

      const mapOptions = (options, disabledArray = []) =>
        options?.map(({ value }) => ({
          label: value,
          value,
          disabled: checkIncludes(value, disabledArray),
        }))

      setDynamicOptions({
        ...choices,
        ownerContractor,
        ...dynamicChoices,
        drawNumber: mapOptions(dynamicChoices?.drawNumber, usedDrawNumbers),
        override: mapOptions(
          dynamicChoices?.override,
          budgetSummaryNumberData?.override,
        ),
      })
    }
  }, [
    choices,
    dynamicChoices,
    usedDrawNumbers,
    budgetSummaryNumberData?.override,
  ])

  useEffect(() => {
    if (budgetSummaryNumberData?.drawNumber?.length) {
      setUsedDrawNumbers(budgetSummaryNumberData?.drawNumber)
    } else {
      setUsedDrawNumbers([])
    }
  }, [budgetSummaryNumberData?.drawNumber])

  // For get dynamic OC list
  useEffect(() => {
    let activeContractor = null
    if (isArray(choices?.ownerContractor) && choices?.ownerContractor?.length) {
      activeContractor = choices?.ownerContractor[0]?.id
    }
    setOwnerContractorId(activeContractor)
  }, [choices?.ownerContractor[0]?.id])

  // For set current owner-contractor description
  useEffect(() => {
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    setFieldValue('ownerContractorDescription', activeContractorDescription)
  }, [ownerContractorId])

  // For pre-populate payApp
  useEffect(() => {
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    if (payAppId) {
      const data = budgetSummaryData?.find((summary) =>
        equal(summary?.payApplicationNumber, payAppId),
      )
      const cloneValues = { ...values }
      const { drawNumber, drawReceivedDate } = cloneValues
      if (data) {
        setIsShowFormBudgetSummary(true)
        setValues({
          drawNumber,
          drawReceivedDate,
          payApplicationNumber: data?.payApplicationNumber,
          disbursementRecommendation: 'AR',
          changeOrderCurrent: '',
          netChangeByChangeOrders: '0',
          contractSumToDate: '0',
          amendedDaysCurrent: '',
          retainage: '0',
          totalCompletedStored: '0',
          previousDisbursements: '0',
          currentPaymentDue: '0',
          ...budgetSummaryReference,
          ownerContractorDescription: activeContractorDescription,
          contractSum: budgetSummaryReference?.contractSum ?? '',
          amendedDaysTotal: budgetSummaryReference?.amendedDaysTotal ?? '',
          changeOrderTotal: budgetSummaryReference?.changeOrderTotal ?? '',
          ...data,
        })
        setDummyData({
          drawNumber,
          drawReceivedDate,
          payApplicationNumber: data?.payApplicationNumber,
          disbursementRecommendation: 'AR',
          changeOrderCurrent: '',
          netChangeByChangeOrders: '0',
          contractSumToDate: '0',
          amendedDaysCurrent: '',
          retainage: '0',
          totalCompletedStored: '0',
          previousDisbursements: '0',
          currentPaymentDue: '0',
          ...budgetSummaryReference,
          ownerContractorDescription: activeContractorDescription,
          contractSum: budgetSummaryReference?.contractSum || '',
          amendedDaysTotal: budgetSummaryReference?.amendedDaysTotal || '',
          changeOrderTotal: budgetSummaryReference?.changeOrderTotal || '',
          ...data,
        })
      } else {
        setErrors({ ...errors })
        setValues({
          ...localData,
          payApplicationNumber: payAppId,
          disbursementRecommendation: 'AR',
          ownerContractorDescription: activeContractorDescription,
          amendedDaysPrevious: '',
          changeOrderPrevious: '',
          contractSum: budgetSummaryReference?.contractSum ?? '',
        })
        setDummyData({
          ...localData,
          payApplicationNumber: payAppId,
          disbursementRecommendation: 'AR',
          ownerContractorDescription: activeContractorDescription,
          amendedDaysPrevious: '',
          changeOrderPrevious: '',
          contractSum: budgetSummaryReference?.contractSum ?? '',
        })
      }
      setBudgetSummaryId(data?.id)
    } else {
      setValues({
        ...values,
        drawNumber: values.drawNumber,
        ownerContractorDescription: activeContractorDescription,
        contractSum: budgetSummaryReference?.contractSum ?? '',
      })
      setDummyData({
        ...values,
        drawNumber: values.drawNumber,
        ownerContractorDescription: activeContractorDescription,
        contractSum: budgetSummaryReference?.contractSum || '',
      })
    }
  }, [
    payAppId,
    budgetSummaryData,
    ownerContractorId,
    isBudgetSummaryDeleting,
    budgetSummaryReference,
  ])

  useEffect(() => {
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    setFieldValue(
      'ownerContractorDescription',
      activeContractorDescription,
      'BudgetSummary',
    )
    if (!budgetSummaryData?.length && isCreateSummary) {
      resetForm()
      if (budgetSummaryNumberData?.payApp && isCreateSummary) {
        setFieldValue(
          'payApplicationNumber',
          budgetSummaryNumberData?.payApp,
          'BudgetSummary',
        )
        setPayAppId(budgetSummaryNumberData?.payApp)
        setPayAppAry([
          {
            id: `${budgetSummaryNumberData?.payApp}`,
            name: `Pay App ${budgetSummaryNumberData?.payApp}`,
          },
        ])
      } else {
        setPayAppId(null)
        setPayAppAry([
          {
            id: `01`,
            name: `Pay App ##`,
          },
        ])
      }
    }
    // return () => setPayAppAry([])
  }, [ownerContractorId, budgetSummaryData, budgetSummaryNumberData?.payApp])

  // Pay app will be delete if it's number is largest one
  useEffect(() => {
    const payAppPermitIdForDelete = Math.max(...payAppAry.map((app) => app?.id))
    setDeletePermitValue(payAppPermitIdForDelete)
  }, [payAppAry, payAppId])

  // For generate dynamic payApp button array
  useEffect(() => {
    if (budgetSummaryData?.length) {
      const dynamicPayAppAry = budgetSummaryData
        ?.sort((a, b) =>
          a?.payApplicationNumber > b?.payApplicationNumber ? 1 : -1,
        )
        ?.map((app) => ({
          id: `${app?.payApplicationNumber}`,
          name: `${app?.payApp}`,
        }))
      setPayAppAry(dynamicPayAppAry)
      if (isCreateSummary && !payAppId) {
        setPayAppId(dynamicPayAppAry[0]?.id)
      }
    }
  }, [
    budgetSummaryData,
    isCreateSummary,
    ownerContractorId,
    isBudgetSummaryDeleting,
  ])

  // After delete budget summary set active payApp
  useEffect(() => {
    if (equal(budgetSummaryData?.length, 1) && !isBudgetSummaryDeleting) {
      setPayAppId(`${budgetSummaryData[0]?.payApplicationNumber}`)
    } else {
      setPayAppId(`${budgetSummaryData?.slice(-1)[0]?.payApplicationNumber}`)
    }
  }, [isBudgetSummaryDeleting])

  // For set payApp name as per payApplicationNumber dropdown
  useEffect(() => {
    if (lt(payAppAry?.length, 2) && values?.payApplicationNumber) {
      setPayAppId(`${values?.payApplicationNumber}`)
      setPayAppAry([
        {
          id: `${values?.payApplicationNumber}`,
          name: `Pay App ${values?.payApplicationNumber}`,
        },
      ])
    }
  }, [values?.payApplicationNumber, ownerContractorId, isBudgetSummaryDeleting])

  // For Budget summary table page
  useEffect(() => {
    if (!isCreateSummary) {
      setIsShowFormBudgetSummary(false)
      setPayAppId(null)
      setBudgetSummaryId(null)
      const activeContractorDescription = choices?.ownerContractor?.find(
        (list) => equal(list?.id, ownerContractorId),
      )?.description
      setFieldValue(
        'ownerContractorDescription',
        activeContractorDescription,
        'BudgetSummary',
      )
    }
  }, [isCreateSummary, isBudgetSummaryDeleting])

  // For last budget summary record delete then set payApp id As ##
  useEffect(() => {
    if (
      isCreateSummary &&
      !isBudgetSummaryLoading &&
      !budgetSummaryData?.length
    ) {
      if (
        !budgetSummaryNumberData?.payApp &&
        !budgetSummaryNumberData?.currentReportPayApp
      ) {
        setPayAppId(null)
        setBudgetSummaryId(null)
        setPayAppAry([
          {
            id: `01`,
            name: `Pay App ##`,
          },
        ])
        setFieldValue('disbursementRecommendation', 'AR', 'BudgetSummary')
        setFieldValue(
          'contractSum',
          budgetSummaryReference?.contractSum || '',
          'BudgetSummary',
        )
      }
      if (
        budgetSummaryNumberData?.payApp ||
        budgetSummaryNumberData?.currentReportPayApp
      ) {
        setPayAppId(
          budgetSummaryNumberData?.payApp ||
            budgetSummaryNumberData?.currentReportPayApp,
        )
        setFieldValue(
          'payApplicationNumber',
          budgetSummaryNumberData?.payApp ||
            budgetSummaryNumberData?.currentReportPayApp,
          'BudgetSummary',
        )
        setFieldValue('disbursementRecommendation', 'AR', 'BudgetSummary')
      }
    }
  }, [
    budgetSummaryData,
    isCreateSummary,
    budgetSummaryNumberData,
    isCreateAndUpdateBudgetSummaryLoading,
  ])

  /// /* Calculation *////

  // Calculate total Earned Less Retainage
  useEffect(() => {
    setFieldValue(
      'totalEarnedLessRetainage',
      parseFloat(
        +(values.totalCompletedStored || 0) - +(values.retainage || 0),
      )?.toFixed(2),
      'BudgetSummary',
    )
  }, [values?.totalCompletedStored, values?.retainage])

  // Calculate Change Order Total
  useEffect(() => {
    const orderCurrentValue = values.changeOrderCurrent
      ? Number(values.changeOrderCurrent || 0)
      : ''
    const orderPreviousValue = values.changeOrderPrevious
      ? Number(values.changeOrderPrevious || 0)
      : ''
    setValues((previous) => ({
      ...previous,
      changeOrderTotal: parseFloat(
        orderCurrentValue + orderPreviousValue || 0,
      )?.toFixed(2),
    }))
    setDummyData((previous) => ({
      ...previous,
      changeOrderTotal: parseFloat(
        orderCurrentValue + orderPreviousValue || 0,
      )?.toFixed(2),
    }))
  }, [values?.changeOrderCurrent, values?.changeOrderPrevious])

  // Calculate Amended Days Previous
  useEffect(() => {
    const amendedCurrentValue = values.amendedDaysCurrent
      ? Number(values.amendedDaysCurrent || 0)
      : ''
    const amendedPreviousValue = values.amendedDaysPrevious
      ? Number(values.amendedDaysPrevious || 0)
      : ''
    setValues((previous) => ({
      ...previous,
      amendedDaysTotal: amendedCurrentValue + amendedPreviousValue || 0,
    }))
    setDummyData((previous) => ({
      ...previous,
      amendedDaysTotal: amendedCurrentValue + amendedPreviousValue || 0,
    }))
  }, [values?.amendedDaysCurrent, values?.amendedDaysPrevious])

  // Calculate Balance to Finish (incl. Retainage)
  useEffect(() => {
    setFieldValue(
      'balanceToFinish',
      parseFloat(
        +(values.contractSumToDate || 0) -
          +(values?.totalEarnedLessRetainage || 0),
      )?.toFixed(2),
      'BudgetSummary',
    )
  }, [values?.contractSumToDate, values?.totalEarnedLessRetainage])

  // Set dynamic values as per disbursement Recommendation
  useEffect(() => {
    if (equal(values?.disbursementRecommendation, 'AR')) {
      const {
        totalCompletedStored,
        retainage,
        totalEarnedLessRetainage,
        previousDisbursements,
        currentPaymentDue,
        balanceToFinish,
      } = values

      const disbursementData = {
        disbursementTotalCompletedStored: totalCompletedStored,
        disbursementRetainage: retainage,
        disbursementTotalEarnedLessRetainage: totalEarnedLessRetainage,
        disbursementPreviousDisbursements: previousDisbursements,
        disbursementCurrentPaymentDue: currentPaymentDue,
        disbursementBalanceToFinish: balanceToFinish,
      }

      keys(disbursementData)?.forEach((item) => {
        setErrors((prev) => ({
          ...prev,
          ...(disbursementData[item] && { [item]: '' }),
        }))
      })
      setValues((prev) => ({ ...prev, ...disbursementData }))
      setDummyData({ ...values, ...disbursementData })
    }
  }, [
    values?.disbursementRecommendation,
    values?.totalCompletedStored,
    values?.retainage,
    values?.totalEarnedLessRetainage,
    values?.previousDisbursements,
    values?.currentPaymentDue,
    values?.balanceToFinish,
  ])

  // Calculate Disbursement Recommendation TotalEarnedLessRetainage
  useEffect(() => {
    if (!equal(values?.disbursementRecommendation, 'AR')) {
      setFieldValue(
        'disbursementTotalEarnedLessRetainage',
        parseFloat(
          +(values?.disbursementTotalCompletedStored || 0) -
            +(values?.disbursementRetainage || 0) || '0',
        )?.toFixed(2),
        'BudgetSummary',
      )
    }
  }, [
    values?.disbursementRecommendation,
    values?.disbursementTotalCompletedStored,
    values?.disbursementRetainage,
  ])

  // Calculate Disbursement Recommendation TotalEarnedLessRetainage
  useEffect(() => {
    if (!equal(values?.disbursementRecommendation, 'AR')) {
      setFieldValue(
        'disbursementBalanceToFinish',
        parseFloat(
          +(values?.contractSumToDate || 0) -
            +(values?.disbursementTotalEarnedLessRetainage || 0) || '0',
        )?.toFixed(2),
        'BudgetSummary',
      )
    }
  }, [
    values?.disbursementRecommendation,
    values?.contractSumToDate,
    values?.disbursementTotalEarnedLessRetainage,
  ])

  // Calculate Percent Complete
  useEffect(() => {
    setFieldValue(
      'retainageCalculation',
      (+(values.retainage || 0) / +(values?.totalCompletedStored || 0)) * 100,
      'BudgetSummary',
    )
  }, [values?.contractSumToDate, values?.totalCompletedStored])

  // Calculate Percent Complete
  useEffect(() => {
    setFieldValue(
      'percentComplete',
      (+(values.totalCompletedStored || 0) /
        +(values?.contractSumToDate || 0)) *
        100,
      'BudgetSummary',
    )
  }, [values?.contractSumToDate, values?.totalCompletedStored])

  // // Calculate Current Payment Due
  // useEffect(() => {
  //   setFieldValue(
  //     'currentPaymentDue',
  //     +(values.totalEarnedLessRetainage || 0) -
  //       +(values?.previousDisbursements || 0) || '0',
  //   )
  // }, [values?.totalEarnedLessRetainage, values?.previousDisbursements])

  // set oc based on form is unSavedData
  useEffect(() => {
    if (tempOcData?.id) {
      if (isDirty) {
        setIsSaveWarningOpen(true)
      } else {
        resetForm()
        setTimeout(() => {
          setOwnerContractorId(tempOcData?.id)
          setIsNavigate(true)
        }, 500)
        setErrors({})
      }
    }
  }, [tempOcData])

  useEffect(
    () => () => {
      dispatch({ type: CLEAR_REPORT_TRACKER })
    },
    [],
  )

  // For delete budget summary row from table
  const handleDeleteTableRow = () => {
    const data = budgetSummaryData?.find((summary) =>
      equal(summary?.payApplicationNumber, payAppId),
    )
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description

    if (!data && isCreateSummary) {
      const findPayAppIndex = payAppAry?.findIndex(({ id }) =>
        equal(id, payAppId),
      )
      const cloneOldPayApp =
        findPayAppIndex !== 0 && payAppAry?.[findPayAppIndex - 1]?.id

      resetForm()
      setValues({
        ownerContractorDescription: activeContractorDescription,
      })
      setPayAppId(cloneOldPayApp)
      setDummyData({
        ownerContractorDescription: activeContractorDescription,
      })
      setPayAppAry((prevState) => prevState.slice(0, prevState.length - 1))
      if (!budgetSummaryData?.length) {
        navigate(
          `/projects/${slug}/project-reports/${reportId}/budget-summary/`,
        )
      }
      setOpenDeleteNotification(false)
      setSelectedRows.current([])
      return
    }
    const body = {
      budgetSummary: ternary(payAppId, [data?.id], reportToRemove?.current),
    }
    dispatch(
      deleteBudgetSummary(body, reportId, ownerContractorId, handleClose),
    )?.then(() => {
      setOpenDeleteNotification(false)
      if (budgetSummaryData?.length === 1) {
        setPayAppAry([])
        setPayAppId(null)
        setValues(() => ({
          ownerContractorDescription: activeContractorDescription,
        }))
        setDummyData(() => ({
          ownerContractorDescription: activeContractorDescription,
        }))
        navigate(
          `/projects/${slug}/project-reports/${reportId}/budget-summary/`,
        )
      } else {
        setValues({
          ownerContractorDescription: activeContractorDescription,
        })
        setDummyData({
          ownerContractorDescription: activeContractorDescription,
        })
      }
      setSelectedRows.current([])
    })
  }
  const data = budgetSummaryData?.find((summary) =>
    equal(summary?.id, reportToRemove?.current?.[0]),
  )
  const deletePayAppNumber = payAppId || data?.payApplicationNumber
  const handleClose = () => {
    setOpenDeleteNotification(false)
    setBudgetSummaryNotificationOpen(false)
  }

  // For create new or add more payApp
  const handleCreateBudgetSummary = () => {
    setIsShowFormBudgetSummary(true)
    resetForm()
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    setFieldValue(
      'ownerContractorDescription',
      activeContractorDescription,
      'BudgetSummary',
    )
    if (isCreateSummary && budgetSummaryData?.length) {
      if (budgetSummaryNumberData?.currentReportPayApp) {
        const data = budgetSummaryData?.find((summary) =>
          equal(
            summary?.payApplicationNumber,
            budgetSummaryNumberData?.currentReportPayApp,
          ),
        )
        if (!data) {
          if (budgetSummaryNumberData?.currentReportPayApp) {
            setPayAppId(
              budgetSummaryNumberData?.currentReportPayApp ||
                budgetSummaryNumberData?.payApp,
            )
            const clonePayAppAry = [
              ...payAppAry,
              ...[
                {
                  id: `${
                    budgetSummaryNumberData?.currentReportPayApp ||
                    budgetSummaryNumberData?.payApp
                  }`,
                  name: `Pay App ${
                    budgetSummaryNumberData?.currentReportPayApp ||
                    budgetSummaryNumberData?.payApp
                  }`,
                },
              ],
            ]
            setPayAppAry(clonePayAppAry)
          }
        }
      }
      setValues({
        ownerContractorDescription: activeContractorDescription,
        drawReceivedDate: values.drawReceivedDate,
        disbursementRecommendation: 'AR',
        payApplicationNumber:
          budgetSummaryNumberData?.currentReportPayApp ||
          budgetSummaryNumberData?.payApp,
        changeOrderCurrent: '',
        netChangeByChangeOrders: '0',
        contractSumToDate: '0',
        amendedDaysCurrent: '',
        retainage: '0',
        totalCompletedStored: '0',
        previousDisbursements: '0',
        currentPaymentDue: '0',
        potentialChangeOrderTotal: '',
        conditionalLienWaiver: '',
        unconditionalLienWaiver: '',
        materialsStoredToDate: '',
        percentComplete: '',
        ...budgetSummaryReference,
        contractSum: budgetSummaryReference?.contractSum ?? '',
        amendedDaysTotal: budgetSummaryReference?.amendedDaysTotal ?? '',
        changeOrderTotal: budgetSummaryReference?.changeOrderTotal ?? '',
        changeOrderPrevious: '',
        amendedDaysPrevious: '',
      })
      setDummyData({
        ownerContractorDescription: activeContractorDescription,
        drawReceivedDate: values.drawReceivedDate,
        disbursementRecommendation: 'AR',
        payApplicationNumber:
          budgetSummaryNumberData?.currentReportPayApp ||
          budgetSummaryNumberData?.payApp,
        changeOrderCurrent: '',
        netChangeByChangeOrders: '0',
        contractSumToDate: '0',
        amendedDaysCurrent: '',
        retainage: '0',
        totalCompletedStored: '0',
        previousDisbursements: '0',
        currentPaymentDue: '0',
        potentialChangeOrderTotal: '',
        conditionalLienWaiver: '',
        unconditionalLienWaiver: '',
        materialsStoredToDate: '',
        percentComplete: '',
        ...budgetSummaryReference,
        contractSum: budgetSummaryReference?.contractSum || '',
        amendedDaysTotal: budgetSummaryReference?.amendedDaysTotal || '',
        changeOrderTotal: budgetSummaryReference?.changeOrderTotal || '',
        changeOrderPrevious: '',
        amendedDaysPrevious: '',
      })
    } else if (!budgetSummaryData?.length && budgetSummaryNumberData?.payApp) {
      if (
        budgetSummaryNumberData?.currentReportPayApp ||
        budgetSummaryNumberData?.payApp
      ) {
        setPayAppId(
          budgetSummaryNumberData?.currentReportPayApp ||
            budgetSummaryNumberData?.payApp,
        )
        const clonePayAppAry = [
          ...payAppAry,
          ...[
            {
              id: `${
                budgetSummaryNumberData?.currentReportPayApp ||
                budgetSummaryNumberData?.payApp
              }`,
              name: `Pay App ${
                budgetSummaryNumberData?.currentReportPayApp ||
                budgetSummaryNumberData?.payApp
              }`,
            },
          ],
        ]

        // Get unique array elements
        const jsonObject = clonePayAppAry.map(JSON.stringify)
        const uniqueSet = new Set(jsonObject)
        const uniqueArray = Array.from(uniqueSet).map(JSON.parse)
        setPayAppAry(uniqueArray)
      }
      setValues((prevValue) => ({
        ...prevValue,
        ...budgetSummaryReference,
        payApplicationNumber:
          budgetSummaryNumberData?.currentReportPayApp ||
          budgetSummaryNumberData?.payApp,
        contractSum: budgetSummaryReference?.contractSum ?? '',
        amendedDaysTotal: budgetSummaryReference?.amendedDaysTotal ?? '',
        changeOrderTotal: budgetSummaryReference?.changeOrderTotal ?? '',
        disbursementRecommendation: 'AR',
        ...localData,
      }))
      setDummyData({
        ...budgetSummaryReference,
        payApplicationNumber:
          budgetSummaryNumberData?.currentReportPayApp ||
          budgetSummaryNumberData?.payApp,
        contractSum: budgetSummaryReference?.contractSum || '',
        amendedDaysTotal: budgetSummaryReference?.amendedDaysTotal || '',
        changeOrderTotal: budgetSummaryReference?.changeOrderTotal || '',
        disbursementRecommendation: 'AR',
        ...localData,
      })
    }
    navigate(
      `/projects/${slug}/project-reports/${reportId}/budget-summary/?create-summary=true`,
    )
    handleClose()
  }

  // For redirect table view to edit budget summary section
  const handleNavigateToSummary = (id) => {
    setPayAppId(id)
    navigate(
      `/projects/${slug}/project-reports/${reportId}/budget-summary/?create-summary=true`,
    )
  }

  // For open confirmation OC for new budget summary
  const handleAddNewBudgetSummary = () => {
    if (choices?.ownerContractor?.length > 1) {
      setBudgetSummaryNotificationOpen(true)
    } else {
      handleCreateBudgetSummary()
    }
  }
  const handleAddNewBS = () => {
    if (isDirty) {
      setIsCreateBS(true)
      setIsSaveWarningOpen(true)
    } else {
      handleAddNewBudgetSummary()
    }
  }
  // For save current payApp
  const handleSave = () => {
    const isValid = handleSubmit({
      comp: 'Budget Summary',
      flag: {
        ...formFields.description,
        ...formFields?.draw,
        ...formFields?.paymentApplicationColumnLeft,
        ...formFields?.paymentApplicationColumnRight,
        ...(showTrackers && {
          ...formFields?.changeOrder,
          ...formFields?.lienWaivers,
          ...formFields?.pcoChangeOrder,
          ...formFields?.storedMaterials,
        }),
      },
    })
    if (isValid) {
      setIsDirty(false)
      const body = {
        ...values,
        report: reportId,
        ownerContractor: ownerContractorId,
        conditionalDateReceived: ternary(
          isEmptyString(values?.conditionalDateReceived),
          null,
          values?.conditionalDateReceived,
        ),
        unconditionalDateReceived: ternary(
          isEmptyString(values?.unconditionalDateReceived),
          null,
          values?.unconditionalDateReceived,
        ),
      }
      delete body.ownerContractorDescription
      if (budgetSummaryId) {
        dispatch(
          updateBudgetSummary(
            body,
            budgetSummaryId,
            reportId,
            ownerContractorId,
            slug,
          ),
        ).then(() => {
          dispatch(
            getBudgetSummaryNumber({
              projectId: slug,
              reportId,
              ocId: ownerContractorId,
              budgetSummaryId: cloneData ? cloneData?.id : null,
              isLoading: false,
            }),
          )
        })
      } else {
        dispatch(
          createBudgetSummary(body, reportId, ownerContractorId, slug),
        ).then(() => {
          dispatch(
            getBudgetSummaryNumber({
              projectId: slug,
              reportId,
              ocId: ownerContractorId,
              budgetSummaryId: cloneData ? cloneData?.id : null,
              isLoading: false,
            }),
          )
        })
      }
    } else {
      showToast(
        `Complete required fields ( * ) in Payment Applications ${ternary(
          showTrackers,
          'and Change Order',
          '',
        )} to save content on this page.`,
      )
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }

  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" gap={2}>
          {ternary(
            isCreateSummary,
            <>
              <DKTButtonSelect
                options={actionList}
                disabled={
                  (isGlobalViewer ||
                    isProjectViewer ||
                    !reportData?.isLastReport ||
                    !equal(deletePermitValue, +payAppId) ||
                    isBudgetSummaryLoading ||
                    isBudgetSummaryNumberLoading,
                  isFetchingBudgetSummaryReferenceValues)
                }
              />
              <DKTButton
                className={classes.rightButton}
                onClick={handleSave}
                onMouseOver={handleBlurAll}
                disabled={
                  (hasOnlyViewPermission ||
                    isBudgetSummaryLoading ||
                    isBudgetSummaryNumberLoading,
                  isFetchingBudgetSummaryReferenceValues)
                }
              >
                {ternary(
                  isCreateAndUpdateBudgetSummaryLoading,
                  'Saving...',
                  'Save',
                )}
              </DKTButton>
            </>,
            null,
          )}
        </Stack>
      </Box>
    </>
  )

  const customToolbarSelect = useCallback(
    (selectedRows, displayData, setSelectedTableRows) => {
      const selectedReport = keys(selectedRows.lookup)
      const selectedBudgetSummaryIds = selectedReport.map(
        (rowToDelete) => budgetSummaryData[rowToDelete]?.id,
      )
      setSelectedRows.current = setSelectedTableRows
      const setReportToRemove = () => {
        reportToRemove.current = selectedBudgetSummaryIds
      }
      return (
        <Box>
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => {
              setReportToRemove()
              setOpenDeleteNotification(true)
            }}
          >
            <Delete color="error" />
          </IconButton>
        </Box>
      )
    },
    [budgetSummaryData],
  )
  const tableOptions = {
    ...options,
    textLabels: {
      body: {
        noMatch: isBudgetSummaryLoading ? (
          <DKTCircularProgress />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
    customToolbarSelect,
    selectableRows: ternary(
      !equal(systemAuthorization, SA.administrator) ||
        !reportData?.isLastReport,
      'none',
      options.selectableRows || 'multiple',
    ),
    isRowSelectable: (dataIndex) =>
      budgetSummaryTableData[dataIndex]?.isPermitToDelete,
    rowsPerPageOptions: [5, 10, 20],
  }
  const customBodyRender = useCallback(
    (value, tableMeta) => {
      const overrideValue = tableMeta?.rowData.at(-1)
      switch (tableMeta?.columnData?.name) {
        case 'payApplicationNumber':
          return (
            <DKTButton
              variant="text"
              color="primary"
              style={{ backgroundColor: 'transparent', paddingLeft: '0' }}
              onClick={() => handleNavigateToSummary(tableMeta.rowData[2])}
            >
              {overrideValue || value}
            </DKTButton>
          )
        case 'currentPaymentDue':
        case 'recommended':
          return `$ ${modifyNumber(value)}`

        default:
          return emptyTableCellPlaceholder
      }
    },
    [handleNavigateToSummary, hasOnlyViewPermission],
  )
  const tableColumns = columns?.map((column) =>
    ternary(
      equal(column.name, 'payApplicationNumber') ||
        equal(column.name, 'currentPaymentDue') ||
        equal(column.name, 'recommended'),
      { ...column, options: { ...column.options, customBodyRender } },
      column,
    ),
  )

  const actionList = [
    { label: 'Delete', onClick: () => setOpenDeleteNotification(true) },
  ]
  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteTableRow}>
        {ternary(
          isBudgetSummaryDeleting,
          <DKTCircularProgress
            size={spinnerSize?.sm}
            color={theme.palette.gray.dark}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  const budgetSummaryNotificationAction = (
    <>
      <DKTButton
        variant="outlined"
        color="error"
        disableElevation
        onClick={handleClose}
      >
        No
      </DKTButton>
      <DKTButton variant="contained" onClick={handleCreateBudgetSummary}>
        Yes
      </DKTButton>
    </>
  )

  const handlePayAppId = (id) => {
    if (isDirty) {
      setIsSaveWarningOpen(true)
      setTempOcData({ ...tempOcData, payAppId: id })
    } else {
      setPayAppId(id)
      setIsNavigate(true)
      setErrors({})
    }
  }

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsNavigate(true)
    setIsDirty(false)
    setIsSaveWarningOpen(false)
    if (isCreateBS) {
      handleAddNewBudgetSummary()
      setIsCreateBS(false)
    }
    if (!isEmptyString(tempOcData?.id)) {
      setOwnerContractorId(tempOcData?.id)
    }
    if (!isEmptyString(tempOcData?.payAppId)) {
      setPayAppId(tempOcData?.payAppId)
    }
    setErrors({})
    setTempOcData({})
  }
  return (
    <InnerLayout
      contentTitle="Budget summary"
      actions={actions}
      maxWidth="md"
      isShowMenu
    >
      {equal(isBudgetSummaryLoading, true) ||
      equal(isFetchingBudgetSummaryReferenceValues, true) ||
      equal(isBudgetSummaryNumberLoading, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isBudgetSummaryLoading, 'FAILED') ||
        equal(isFetchingBudgetSummaryReferenceValues, 'FAILED') ||
        equal(isBudgetSummaryNumberLoading, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Budget summary data. Please
            try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Box sx={{ position: 'relative' }}>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '-85px',
                    left: { lg: '-40px', sm: '-20px' },
                  }}
                >
                  {ternary(
                    isShowFormBudgetSummary,
                    payAppAry
                      ?.sort((a, b) => (a?.id > b?.id ? 1 : -1))
                      ?.map((app) => (
                        <DKTButton
                          key={app?.id}
                          variant="text"
                          onClick={() => handlePayAppId(app?.id)}
                          sx={{
                            backgroundColor: 'rgba(229, 229, 229, 0.46)',
                            marginRight: '3px',
                            padding: 0,
                            width: '105px',
                            height: '28px',
                            color: ternary(
                              equal(payAppId, app?.id),
                              theme.typography.primary,
                              'inherit',
                            ),
                          }}
                          disabled={
                            isBudgetSummaryLoading ||
                            isBudgetSummaryDeleting ||
                            isCreateAndUpdateBudgetSummaryLoading
                          }
                        >
                          {app?.name}
                        </DKTButton>
                      )),
                    null,
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={10}>
                <Stack sx={{ position: 'relative' }} mb={3}>
                  <Stack direction="row" alignItems="center">
                    <DKTButton
                      variant="text"
                      color="primary"
                      sx={{ textTransform: 'initial', px: 1, flex: '0 0 auto' }}
                      startIcon={
                        <CircledPlusIcon
                          disabled={
                            isGlobalViewer ||
                            isProjectViewer ||
                            ternary(
                              reportData?.isSecondLastReport,
                              equal(reportData?.lastReportStatus, publish),
                              !reportData?.isLastReport,
                            ) ||
                            (!budgetSummaryData?.length && isCreateSummary) ||
                            isBudgetSummaryLoading ||
                            (!budgetSummaryId &&
                              isCreateSummary &&
                              budgetSummaryData) ||
                            (isCreateSummary &&
                              payAppAry?.length !==
                                budgetSummaryData?.length) ||
                            (isCreateSummary &&
                              budgetSummaryNumberData?.payApp &&
                              !budgetSummaryData?.length)
                          }
                        />
                      }
                      onClick={handleAddNewBS}
                      disabled={
                        hasOnlyViewPermission ||
                        (!budgetSummaryData?.length && isCreateSummary) ||
                        isBudgetSummaryLoading ||
                        (!budgetSummaryId &&
                          isCreateSummary &&
                          budgetSummaryData) ||
                        (isCreateSummary &&
                          payAppAry?.length !== budgetSummaryData?.length) ||
                        (isCreateSummary &&
                          budgetSummaryNumberData?.payApp &&
                          !budgetSummaryData?.length)
                      }
                    >
                      <Typography>Budget Summary</Typography>
                    </DKTButton>
                    <Typography
                      sx={{
                        color: '#C6C6C6',
                        fontStyle: 'italic',
                        fontWeight: '500',
                      }}
                      variant="body2"
                    >
                      includes Pay Apps,COs, PCOs, Lien Waivers, & Stored
                      Materials
                    </Typography>
                  </Stack>
                  {ternary(
                    isCreateSummary,
                    <Typography
                      color="#ff0000"
                      variant="caption"
                      sx={{
                        fontStyle: 'italic',
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      * Shows required fields{' '}
                    </Typography>,
                    null,
                  )}
                </Stack>
              </Grid>
              {ternary(
                isShowFormBudgetSummary,
                <CreateBudgetSummary
                  hasOnlyViewPermission={hasOnlyViewPermission}
                  dynamicOptions={dynamicOptions}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  ownerContractorId={ownerContractorId}
                  payAppAry={payAppAry}
                  payAppId={payAppId}
                  reportId={reportId}
                  budgetSummaryData={budgetSummaryData}
                  setValues={setValues}
                  setDummyData={setDummyData}
                  budgetSummaryNumberData={budgetSummaryNumberData}
                  showTrackers={showTrackers}
                />,
                null,
              )}
            </Grid>
            {ternary(
              !isShowFormBudgetSummary && !isCreateSummary,
              <Grid container>
                <Grid item xs={12} md={12} lg={10}>
                  <DKTMuiDataTable
                    data={budgetSummaryTableData}
                    options={tableOptions}
                    columns={tableColumns}
                    className={`${classes.budgetSummaryTable} ${
                      !hasOnlyViewPermission && 'isCheckBox'
                    } `}
                  />
                </Grid>
              </Grid>,
              ternary(
                !isShowFormBudgetSummary && isCreateSummary,
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  height="50vh"
                >
                  <DKTCircularProgress />
                </Stack>,
                null,
              ),
            )}
            <DKTDialog
              open={deleteNotificationOpen}
              handleClose={handleClose}
              title="&nbsp;"
              actions={deleteNotificationAction}
              maxWidth="xs"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.deleteNotificationContent}>
                    <Typography variant="h5">
                      Are you sure you want to delete Pay App
                      {deletePayAppNumber}?
                    </Typography>
                    <Typography variant="body1">
                      You can&apos;t undo this action
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </DKTDialog>
            <DKTDialog
              open={budgetSummaryNotificationOpen}
              handleClose={handleClose}
              title="Confirm"
              actions={budgetSummaryNotificationAction}
              maxWidth="xs"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.budgetSummaryNotificationContent}>
                    <Typography variant="h5" color="grey">
                      {`Will this new Budget Summary be associated with ${ternary(
                        values?.ownerContractorDescription,
                        values?.ownerContractorDescription,
                        '',
                      )}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </DKTDialog>
          </Box>
          {/* show modal when tries to navigate without save data */}
          {!hasOnlyViewPermission && (
            <>
              <DKTConfirmNavigateShowModal
                isActive={isSaveWarningOpen}
                onConfirm={confirmSaveWarningModal}
                onCancel={handleCloseSaveWarningModal}
                onSave={handleSave}
              />
              <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
            </>
          )}
        </>
      )}
    </InnerLayout>
  )
}

export default BudgetSummary
