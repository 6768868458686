import React, { memo, useEffect, useState } from 'react'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { InnerLayout } from '../../../Layout/project'
import useForm from '../../../Hooks/useForm'
import { formFields } from '../../../Description/projectSummary.description'
import {
  entries,
  equal,
  handleBlurAll,
  isArray,
  isEmptyString,
  keys,
  ternary,
} from '../../../Utils/javascript'
import DKTInput from '../../../Shared/DKTInput'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTForm from '../../../Shared/DKTForm'
import DKTButton from '../../../Shared/DKTButton'
import { getStateList } from '../../../Redux/actions/states'
import { getProjectChoices } from '../../../Redux/actions/choices'
import { useStyles } from '../../../Styles/projects.style'
import {
  createAndUpdateProject,
  fetchProjectFieldChoices,
  fetchProjectListSuccess,
  getProjectList,
} from '../../../Redux/actions/projects'
import { getSiteAddressList } from '../../../Redux/actions/siteAddress'
import SiteAddress from './SiteAddress'
import DKTDialog from '../../../Shared/DKTDialog'
import { SA } from '../../../Utils/constant'
import DKTCurrencyTextField from '../../../Shared/DKTCurrencyTextField'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTEditor from '../../../Shared/DKTEditor'

const optionsFields = ['contractType']

const ProjectSummary = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { slug } = useParams()
  const navigate = useNavigate()
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    setErrors,
    isDirty,
    setIsDirty,
    setDummyData,
  } = useForm({ ...formFields.singleAgreement, ...formFields.projectSummary })
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [descriptionData, setDescriptionData] = useState('')
  const [projectId, setProjectId] = useState(null)
  const [isShowSingleAgreementFields, setShowSingleAgreementFields] =
    useState(false)
  const [isShowAgreementNotification, setShowAgreementNotification] =
    useState(false)
  const [tempDescriptionData, seTempDescriptionData] = useState('')
  const [isDescriptionDirty, setIsDescriptionDirty] = useState(false)

  const { state } = useSelector(({ states }) => states)

  const { choices } = useSelector(({ projectChoices }) => projectChoices)
  const {
    projectList,
    projectOptionChoices,
    currentProject,
    projectError,
    isCreateAndUpdateProjectLoading,
    isProjectListLoading,
  } = useSelector(({ projects }) => projects)
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+slug]?.viewer
  const hasOnlyViewPermission = isGlobalViewer || isProjectViewer

  const [open, setOpen] = React.useState(false)

  const handleAddressOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setShowAgreementNotification(false)
    setShowSingleAgreementFields(true)
  }
  useEffect(() => {
    if (projectError) {
      const errorData = { ...errors }
      Object.entries(projectError).forEach(([errorName, [errorDesc]]) => {
        errorData[errorName] = errorDesc
      })
      setErrors(errorData)
    }
    return () => {
      setErrors({})
    }
  }, [projectError])

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(slug)) {
      setProjectId(slug)
      dispatch(getProjectList(slug))
    }
    return () => {
      dispatch(fetchProjectListSuccess([]))
      resetForm()
    }
  }, [dispatch, slug])

  useEffect(() => {
    if (!state?.length) {
      dispatch(getStateList())
    }
  }, [dispatch, state?.length])

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(slug)) {
      dispatch(getSiteAddressList(slug))
    }
  }, [dispatch, slug])

  useEffect(() => {
    if (!choices.constructionType && !choices.projectType) {
      dispatch(getProjectChoices({ fieldName: 'constructionType,projectType' }))
    }
  }, [dispatch, choices.constructionType?.length, choices.projectType?.length])

  useEffect(() => {
    if (!projectOptionChoices?.contractType?.length) {
      dispatch(fetchProjectFieldChoices(optionsFields))
    }
  }, [dispatch, projectOptionChoices?.contractType?.length])

  useEffect(() => {
    const dynamicOpt = {}
    const dynamicOptionForState = (states) => {
      const res = states?.map((sts) => ({
        value: sts?.value,
        label: sts?.value,
      }))
      return res
    }
    const stateList = dynamicOptionForState(state)
    dynamicOpt.state = stateList
    const { projectType, constructionType } = choices
    dynamicOpt.projectType = projectType
    dynamicOpt.constructionType = constructionType
    dynamicOpt.contractType = projectOptionChoices?.contractType

    setDynamicOptions({
      ...dynamicOptions,
      ...dynamicOpt,
    })
  }, [choices, state, projectOptionChoices])

  useEffect(() => {
    if (projectId && currentProject && !isProjectListLoading) {
      const populateValue = { ...currentProject }
      populateValue.numberOfOwnerContractorAgreements = 'SI'
      delete populateValue?.isSingle
      if (equal(populateValue?.numberOfOwnerContractorAgreements, 'SI')) {
        setShowSingleAgreementFields(true)
      }
      setDescriptionData(populateValue?.projectDescription)
      seTempDescriptionData(populateValue?.projectDescription)
      delete populateValue?.projectDescription
      setValues(populateValue)
      setDummyData(populateValue)
    } else {
      setValues((prev) => ({
        ...prev,
        numberOfOwnerContractorAgreements: 'SI',
      }))
      setDummyData((prev) => ({
        ...prev,
        numberOfOwnerContractorAgreements: 'SI',
      }))
      setShowSingleAgreementFields(true)
    }
    return () => {
      setShowSingleAgreementFields(false)
      setShowAgreementNotification(false)
    }
  }, [projectList, currentProject, setValues])

  useEffect(
    () => () => {
      resetForm()
    },
    [],
  )
  useEffect(() => {
    if (tempDescriptionData !== descriptionData) {
      setIsDescriptionDirty(true)
    } else {
      setIsDescriptionDirty(false)
    }
  }, [descriptionData])
  const handleAgreementNotification = () => {
    setShowSingleAgreementFields(false)
    handleClose()
    setShowSingleAgreementFields(false)
    setValues({ ...values, numberOfOwnerContractorAgreements: 'MUL' })
    setDummyData({ ...values, numberOfOwnerContractorAgreements: 'MUL' })
  }

  const handleSingleAgreement = (e) => {
    const { name, value } = e.target
    if (!equal(name, 'numberOfOwnerContractorAgreements')) return
    if (equal(value, 'MUL')) {
      setShowAgreementNotification(true)
      setValues({ ...values, numberOfOwnerContractorAgreements: 'SI' })
      setDummyData({ ...values, numberOfOwnerContractorAgreements: 'SI' })
    } else {
      setShowSingleAgreementFields(true)
    }
  }

  const handleCreateProject = () => {
    const isFormValid = handleSubmit(
      ternary(
        isShowSingleAgreementFields,
        {
          flag: { ...formFields.projectSummary, ...formFields.singleAgreement },
        },
        { flag: formFields.projectSummary },
      ),
    )
    if (isFormValid) {
      setIsDirty(false)
      setIsDescriptionDirty(false)
      const cloneValues = entries(values)
      const singleAgreementKeysAry = keys(formFields.singleAgreement)
      const agreementFilterFieldsValues = cloneValues?.filter(
        (data) => !singleAgreementKeysAry.includes(data[0]),
      )
      const obj = {}
      agreementFilterFieldsValues.forEach((field) => {
        const key = field[0]
        const value = field[1]

        obj[key] = value
      })

      const updatedFieldsValues = ternary(
        isShowSingleAgreementFields,
        { ...values },
        { ...obj },
      )

      const body = {
        ...updatedFieldsValues,
        projectDescription: descriptionData,
        lendersUwCompletion: ternary(
          isEmptyString(updatedFieldsValues?.lendersUwCompletion),
          null,
          updatedFieldsValues?.lendersUwCompletion,
        ),
      }
      dispatch(createAndUpdateProject(body, projectId, navigate))
    } else {
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }

  const mapFormFields = ({ formFields }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            options,
            isConfirmPassword,
            isSeparate,
            separateSectionTitle,
            isPopUp,
            popupText,
            dependsOn,
            defaultValue,
            ...formField
          },
        ],
        index,
      ) => {
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <Grid
            key={index}
            item
            lg={formField.lg ?? 10}
            xs={formField.xs ?? 12}
          >
            {ternary(
              isSeparate,
              <Box mb={1} mt={formField?.mt}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    color="gray.extraDark"
                    sx={{ fontWeight: 'medium' }}
                  >
                    {separateSectionTitle}
                  </Typography>
                  {ternary(
                    isPopUp,
                    <Typography
                      variant="subtitle2"
                      component="span"
                      color="primary"
                      className={classes.addressDialog}
                      onClick={handleAddressOpen}
                    >
                      {popupText}
                    </Typography>,
                    null,
                  )}
                </Box>
                <Divider light sx={{ mb: '5px' }} />
              </Box>,
              null,
            )}
            {ternary(
              formField.isCurrency,
              <DKTCurrencyTextField
                label={formField.label}
                isRequired={isRequired}
                variant="standard"
                value={(values && values[name]) ?? ''}
                error={errors[name]}
                currencySymbol="$"
                minimumValue="0"
                outputFormat="string"
                decimalCharacter="."
                digitGroupSeparator=","
                decimalPlaces={formField?.decimalPlaces || 2}
                onChange={(event, value) =>
                  handleChange({ target: { name, value } })
                }
                disabled={hasOnlyViewPermission || formField?.disabled}
                sx={formField?.sx}
              />,
              <FormControl
                {...formField}
                options={ternary(
                  isDynamicOptions,
                  dynamicOptions[name] || [],
                  options,
                )}
                isRequired={isRequired}
                // disabled={ternary(
                //   hasOnlyViewPermission ||
                //     (values?.isDisabled &&
                //       equal(formField.type, 'select') &&
                //       equal(name, 'numberOfOwnerContractorAgreements')),
                //   true,
                //   false,
                // )}
                disabled={hasOnlyViewPermission || formField?.disabled}
                id={name}
                name={name}
                value={(values && values[name]) || defaultValue || ''}
                onChange={(e) => {
                  handleChange(e)
                  handleSingleAgreement(e)
                }}
                error={errors[name]}
              />,
            )}
          </Grid>
        )
      },
    )

  const renderFormFields = (formFields) => (
    <Grid container spacing={2}>
      {isArray(formFields)
        ? formFields.map((formField) =>
            mapFormFields({
              formFields: formField.data,
              makeFieldNameUnique: true,
              id: formField.id,
            }),
          )
        : mapFormFields({ formFields })}
    </Grid>
  )

  const actionList = [
    { label: 'Download .xls', onClick: () => null },
    { label: 'Print', onClick: () => null },
  ]
  const actions = () => (
    <Stack direction="row" gap={2}>
      {ternary(
        !hasOnlyViewPermission,
        <DKTButtonSelect
          options={actionList}
          disabled={isProjectListLoading || true}
        />,
        null,
      )}
      <DKTButton
        onClick={handleCreateProject}
        onMouseOver={handleBlurAll}
        disabled={
          hasOnlyViewPermission ||
          isGlobalViewer ||
          isCreateAndUpdateProjectLoading ||
          isProjectListLoading
        }
      >
        {ternary(isCreateAndUpdateProjectLoading, 'Saving...', 'Save')}
      </DKTButton>
    </Stack>
  )

  const notificationAction = (
    <Box textAlign="right" width="100%">
      <DKTButton
        variant="contained"
        disableElevation
        onClick={handleAgreementNotification}
      >
        Continue
      </DKTButton>
    </Box>
  )
  return (
    <InnerLayout
      contentTitle="Project Summary"
      actions={actions}
      maxWidth="md"
      title={ternary(
        equal(isProjectListLoading, 'FAILED'),
        null,
        ternary(
          projectList?.some((project) => equal(project?.id, +projectId)) &&
            projectId,
          projectList?.find((project) => equal(project?.id, +projectId))
            ?.projectName,
          'New Project',
        ),
      )}
      isShowMenu
    >
      {equal(isProjectListLoading, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isProjectListLoading, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Project Summary data. Please
            try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DKTForm autoComplete="off">
              {renderFormFields(formFields.projectSummary)}
              {ternary(
                isShowSingleAgreementFields,
                renderFormFields(formFields.singleAgreement),
                null,
              )}
            </DKTForm>
          </Grid>
          <Grid
            item
            xs={8}
            sx={ternary(
              hasOnlyViewPermission,
              { pointerEvents: 'none', userSelect: 'none' },
              {},
            )}
          >
            <Stack
              direction="row"
              alignItems="end"
              justifyContent="space-between"
              mb={1}
            >
              <Typography variant="h6" component="h6" sx={{ fontSize: '14px' }}>
                PROJECT DESCRIPTION
              </Typography>
            </Stack>
            <DKTEditor
              value={descriptionData || ''}
              onChange={(event, editor) => {
                const data = editor.getData()
                setDescriptionData(data)
              }}
              disabled={hasOnlyViewPermission}
              className={classes.projectDesc}
            />
          </Grid>
        </Grid>
      )}
      <SiteAddress
        open={open}
        handleClose={handleClose}
        dynamicOptions={dynamicOptions}
        hasOnlyViewPermission={hasOnlyViewPermission}
      />
      <DKTDialog
        open={isShowAgreementNotification}
        handleClose={handleClose}
        title="&nbsp;"
        actions={notificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.notificationContent}>
              <Typography variant="h5">Notification</Typography>
              <Typography variant="body1">
                Multiple instances should be created in the Owner-Contractor
                Agreement section
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      <DKTReactRouterPrompt
        isDirty={isDirty || isDescriptionDirty}
        onSave={handleCreateProject}
      />
    </InnerLayout>
  )
}

export default memo(ProjectSummary)
