import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getProjectList } from '../../Redux/actions/projects'
import { ternary } from '../../Utils/javascript'

import Navbar from '../admin/Navbar'
import MainLayout from '../main'
import ActionsBar from '../ActionsBar'
import PageTitleBar from './PageTitleBar'
import Sidebar from './Sidebar'

export const OuterLayout = ({
  children,
  actions: Actions = () => null,
  actionTitle,
  title,
  isShowMenu,
  actionTitleTextTransform,
}) => (
  <MainLayout>
    <PageTitleBar title={title} isShowMenu={isShowMenu} />
    <ActionsBar
      actionTitle={actionTitle}
      textTransform={actionTitleTextTransform}
    >
      <Actions />
    </ActionsBar>
    {children}
  </MainLayout>
)

export const InnerLayout = memo(
  ({
    actionTitle,
    contentTitle,
    actions: Actions = () => null,
    children,
    className,
    title,
  }) => {
    const dispatch = useDispatch()
    const { slug: projectId } = useParams()
    const { currentProject } = useSelector(({ projects }) => projects)

    useEffect(() => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(projectId)) {
        dispatch(getProjectList(projectId))
      }
    }, [dispatch, projectId])

    return (
      <>
        <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 999 }}>
          <Navbar />
          <PageTitleBar
            title={ternary(
              projectId && currentProject?.projectName,
              currentProject?.projectName,
              title,
            )}
          />
          <ActionsBar actionTitle={actionTitle}>
            <Actions />
          </ActionsBar>
        </Box>
        <Stack
          direction="row"
          flex={1}
          sx={{ maxHeight: '100%', overflow: 'hidden' }}
        >
          <Sidebar currentProject={currentProject} />
          <Box
            py={4}
            width="100%"
            sx={{
              overflowY: 'auto',
              marginLeft: '250px',
              marginTop: '164px',
              paddingLeft: { sm: '20px', lg: '40px' },
              paddingRight: { sm: '20px', lg: '40px' },
            }}
          >
            <Typography
              component="h6"
              fontSize={14}
              fontWeight={700}
              textAlign="center"
              textTransform="uppercase"
              mb={4}
              className={className}
            >
              {contentTitle}
            </Typography>
            {children}
          </Box>
        </Stack>
      </>
    )
  },
)
