export const formFields = {
  reportTier: {
    tier: {
      type: 'select',
      label: 'Report Tier',
      xs: 6,
      displayEmpty: true,
      defaultValue: 'T1',
      placeholder: 'Select',
      isRequired: true,
      isDynamicOptions: true,
    },
  },
  addTeamMember: {
    companyPersonnel: {
      type: 'select',
      label: 'Company Personnel',
      isDynamicOptions: true,
      isRequired: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
    assignedRole: {
      type: 'select',
      label: 'Assigned Role',
      isDynamicOptions: true,
      isRequired: true,
      placeholder: 'Select',
      displayEmpty: true,
    },
  },
}

export const accordionData = {
  projectTeam: {
    accordionTitle: 'Project Team',
    idTag: 'panel1a',
  },
  limitations: {
    accordionTitle: 'Limitations',
    idTag: 'limitations',
  },
  reportDistributionList: {
    accordionTitle: 'Report Distribution List',
    idTag: 'panel2a',
  },
}

export const columns = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'firstName',
    label: 'First Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'lastName',
    label: 'Last Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'assignedRole',
    label: 'Assigned Role',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'Email Address',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'workPhoneNumber',
    label: 'Work Phone',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'cellPhone',
    label: 'Cell Phone',
    options: {
      filter: true,
      sort: true,
    },
  },
]
