export const sidebarLinks = [
  {
    text: 'Cover Page & Letter',
    url: 'cover-page-and-letter',
    sideBarLinkId: 'Cover Letter & Photo',
  },
  {
    text: 'Executive Summary',
    url: 'executive-summary',
    sideBarLinkId: 'Executive Summary',
  },
  {
    text: 'Issues Log',
    url: 'issues-log',
    sideBarLinkId: 'Issues Log',
    paddingLeft: '21px',
  },
  {
    text: 'Budget Summary',
    url: 'budget-summary',
    sideBarLinkId: 'Budget Summary',
  },
  {
    text: 'G702 Split Tracker',
    url: 'g702-tracker',
    sideBarLinkId: 'G702 Summary',
    paddingLeft: '21px',
  },
  {
    text: 'Contingency',
    url: 'contingency',
    sideBarLinkId: 'Contingency',
  },
  {
    text: 'Construction Summary',
    url: 'construction-summary',
    sideBarLinkId: 'Construction Summary',
  },
  {
    text: 'Construction Schedule',
    url: 'construction-schedule',
    sideBarLinkId: 'Construction Schedule',
  },
  {
    text: 'Permits & Entitlements',
    url: 'permits-entitlements',
    sideBarLinkId: 'Permits & Entitlements',
  },
  {
    text: 'Third Party Reports',
    url: 'third-party-reports',
    sideBarLinkId: 'Third Party Reports',
  },
  {
    text: 'Photographs',
    url: 'photographs',
    sideBarLinkId: 'Photographs',
  },
  {
    text: 'Appendix',
    url: 'appendix',
    sideBarLinkId: 'Appendix',
  },
  {
    text: 'Attachments',
    url: 'report-attachment',
    sideBarLinkId: 'ReportAttachments',
  },
]
