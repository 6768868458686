import InfoIcon from '@mui/icons-material/Info'
import DKTTooltip from './DKTTooltip'

const DKTInfoBox = ({ title, sx, color = 'info', ...rest }) => (
  <DKTTooltip title={title} {...rest}>
    <InfoIcon sx={sx} color={color} />
  </DKTTooltip>
)

export default DKTInfoBox
