import { Divider } from '@mui/material'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InnerLayout } from '../../../Layout/rfp'
import { getRfpCompanyList } from '../../../Redux/actions/rfp'
import DKTGrid from '../../../Shared/DKTGrid'
import DKTStack from '../../../Shared/DKTStack'
import DKTTypography from '../../../Shared/DKTTypography'
import ConsultantTable from './ConsultantTable'
import ConsultantDetail from './ConsultantDetail'
import { equal } from '../../../Utils/javascript'
import { getStateList } from '../../../Redux/actions/states'

const FormSectionDivider = memo(
  ({ sectionTitle, rightSection, mt = 3, ...rest }) => (
    <>
      <DKTStack
        {...rest}
        direction="row"
        alignItems="end"
        justifyContent="space-between"
        width="100%"
        mt={mt}
        sx={{ padding: '5.5px 0' }}
      >
        <DKTTypography
          variant="body2"
          color="gray.extraDark"
          sx={{
            fontWeight: 'medium',
            paddingBottom: 0.5,
            whiteSpace: 'nowrap',
          }}
        >
          {sectionTitle}
        </DKTTypography>
        {rightSection}
      </DKTStack>
      <Divider sx={{ mb: '25.6px', background: '#D9C666' }} />
    </>
  ),
)

const Consultants = () => {
  const [selectedComp, setSelectedComp] = useState({})
  const { rfpCompanyList } = useSelector(({ rfpData }) => rfpData)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getRfpCompanyList())
    dispatch(getStateList())
  }, [])

  const handleShowHideDetail = (id = '') => {
    setSelectedComp(rfpCompanyList?.find((item) => equal(item.id, id)))
  }

  return (
    <InnerLayout
      maxWidth="100%"
      showActionBar={false}
      sx={{ marginTop: '114px', background: '#E5E5E5' }}
    >
      <DKTGrid container spacing={3}>
        <DKTGrid item xs={7}>
          <ConsultantDetail
            selectedComp={selectedComp}
            FormSectionDivider={FormSectionDivider}
            handleShowHideDetail={handleShowHideDetail}
          />
        </DKTGrid>
        <DKTGrid item xs={5}>
          <ConsultantTable
            FormSectionDivider={FormSectionDivider}
            handleShowHideDetail={handleShowHideDetail}
          />
        </DKTGrid>
      </DKTGrid>
    </InnerLayout>
  )
}

export default Consultants
