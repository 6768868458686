import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { keys } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  CLEAR_COVER_LETTER_VALUE,
  FETCH_CLIENT_NAME_SUCCESS,
  FETCH_CMR_COVER_LETTER_FAILED,
  FETCH_CMR_COVER_LETTER_REQUESTED,
  FETCH_CMR_COVER_LETTER_SUCCESS,
  FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_FAILED,
  FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_REQUESTED,
  FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_SUCCESS,
  FETCH_SIGNATURE_FAILED,
  FETCH_SIGNATURE_REQUESTED,
  FETCH_SIGNATURE_SUCCESS,
  UPDATE_COVER_LETTER_FAILED,
  UPDATE_COVER_LETTER_REQUESTED,
  UPDATE_COVER_LETTER_SUCCESS,
} from '../constants/coverLetterAndPhoto'
import { updateSaveStatusAction } from './confirmation'
import { fetchReportSuccess } from './reportMonitoring'

// Fetch CMR-Cover letter
const fetchCMRCoverLetterRequested = () => ({
  type: FETCH_CMR_COVER_LETTER_REQUESTED,
})
export const fetchCMRCoverLetterSuccess = (payload) => ({
  type: FETCH_CMR_COVER_LETTER_SUCCESS,
  payload,
})
const fetchCMRCoverLetterFailed = (payload) => ({
  type: FETCH_CMR_COVER_LETTER_FAILED,
  payload,
})

export const fetchCMRCoverLetter =
  (projectId, reportId, ocId) => async (dispatch) => {
    dispatch(fetchCMRCoverLetterRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.CMRCoverLetter}${reportId}/?projectId=${projectId}&ocId=${ocId}`,
      method: method.get,
      showToastMessage: false,
      toastMessage: 'CMR cover letter fetch successfully!',
    })
    if (data) {
      if (!data?.id)
        dispatch(fetchDefaultValueOfCMRCoverLetter(projectId, reportId, ocId))

      dispatch(fetchCMRCoverLetterSuccess(data))
      return
    }
    if (error) {
      setTimeout(() => {
        dispatch(fetchCMRCoverLetterFailed(error.response))
      }, 500)
      if (error.response.status === 404) {
        dispatch(fetchDefaultValueOfCMRCoverLetter(projectId, reportId, ocId))
      }
    }
  }

// default value of CMR Cover letter
const fetchDefaultValueOfCMRCoverLetterRequested = () => ({
  type: FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_REQUESTED,
})
export const fetchDefaultValueOfCMRCoverLetterSuccess = (payload) => ({
  type: FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_SUCCESS,
  payload,
})
const fetchDefaultValueOfCMRCoverLetterFailed = (payload) => ({
  type: FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_FAILED,
  payload,
})

export const fetchDefaultValueOfCMRCoverLetter =
  (projectId, reportId, ocId) => async (dispatch) => {
    dispatch(fetchDefaultValueOfCMRCoverLetterRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.defaultValueOfCMRCoverLetter}?reportId=${reportId}&projectId=${projectId}&ocId=${ocId}`,
      method: method.get,
      showToastMessage: false,
      toastMessage: 'CMR cover letter fetch successfully!',
    })
    if (data) {
      dispatch(fetchDefaultValueOfCMRCoverLetterSuccess(data))
      return
    }
    if (error) {
      dispatch(fetchDefaultValueOfCMRCoverLetterFailed(error.response))
    }
  }

// default value of CMR Cover letter
const updateCoverLetterRequest = () => ({
  type: UPDATE_COVER_LETTER_REQUESTED,
})
export const updateCoverLetterSuccess = (payload) => ({
  type: UPDATE_COVER_LETTER_SUCCESS,
  payload,
})
const updateCoverLetterFailed = (payload) => ({
  type: UPDATE_COVER_LETTER_FAILED,
  payload,
})

export const updateCoverLetter =
  (id, projectId, ocId, payload) => async (dispatch, getState) => {
    dispatch(updateCoverLetterRequest())
    const { CMRCoverLetterData } = getState().coverLetterAndPhoto
    const { data, error } = await api({
      endPoint: CMRCoverLetterData?.id
        ? `${apiEndPoints.addUpdateCoverLetter}${id}/?projectId=${projectId}&ocId=${ocId}`
        : `${apiEndPoints.addUpdateCoverLetter}?projectId=${projectId}&ocId=${ocId}`,
      data: payload,
      method: CMRCoverLetterData?.id ? method.patch : method.post,

      showToastMessage: false,
      toastMessage: 'Update successfully',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(updateCoverLetterSuccess(data))
      dispatch(fetchReportSuccess(data?.report))
      showToast(
        keys(CMRCoverLetterData)?.length
          ? 'Cover letter updated successfully'
          : 'Cover letter added successfully',
        'success',
      )
      return
    }
    if (error) {
      dispatch(updateCoverLetterFailed(error.response.data))
      showToast('Something went wrong', 'error')
    }
  }
export const clearCoverLetterValues = () => ({
  type: CLEAR_COVER_LETTER_VALUE,
})

// Fetch CMR-Cover letter
const fetchSignatureRequested = () => ({
  type: FETCH_SIGNATURE_REQUESTED,
})
export const fetchSignatureSuccess = (payload) => ({
  type: FETCH_SIGNATURE_SUCCESS,
  payload,
})
const fetchSignatureFailed = (payload) => ({
  type: FETCH_SIGNATURE_FAILED,
  payload,
})

export const fetchSignature = () => async (dispatch) => {
  dispatch(fetchSignatureRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.companyPersonnel}`,
    method: method.get,
    showToastMessage: false,
  })

  if (data) {
    dispatch(fetchSignatureSuccess(data))
  }
  if (error) {
    dispatch(fetchSignatureFailed(error.response))
  }
}

// Fetch clients names
export const fetchClientNamesSuccess = (payload) => ({
  type: FETCH_CLIENT_NAME_SUCCESS,
  payload,
})
export const fetchClientNames = (projectId) => async (dispatch) => {
  const { data } = await api({
    endPoint: `${apiEndPoints.clientsName}?projectId=${projectId}`,
    method: method.get,
    showToastMessage: false,
  })

  if (data) {
    dispatch(fetchClientNamesSuccess(data))
  }
}
