import { Box, Grid, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { formFields } from '../../Description/resetPassword.description'
import useForm from '../../Hooks/useForm'
import {
  checkLinkExpire,
  resendLink,
  resetPassword,
} from '../../Redux/actions/auth'
import DKTButton from '../../Shared/DKTButton'
import { useStyles } from '../../Styles/resetPassword.style'
import { equal, ternary } from '../../Utils/javascript'
import { renderFormFields } from '../../Utils/renderFormFields'
import companyLogo from '../../Assets/company-logo.jpg'
import loginImage from '../../Assets/login.jpg'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import DKTTypography from '../../Shared/DKTTypography'

const ResetPassword = () => {
  const { token } = useParams()
  const {
    isResettingPassword,
    resetPasswordError,
    checkLinkExpireLoading,
    isExpired,
    isLoadingResendEmail,
    backToLogin,
  } = useSelector((state) => state.auth)
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { values, handleChange, handleSubmit, errors, setErrors } =
    useForm(formFields)

  useEffect(() => {
    if (token) dispatch(checkLinkExpire(token))
  }, [token])

  useEffect(() => {
    if (resetPasswordError) {
      setErrors({ ...errors, ...resetPasswordError })
    }
  }, [resetPasswordError])

  const handleResetPassword = (e) => {
    e.preventDefault()
    const isFormValid = handleSubmit()
    if (!isFormValid) return
    dispatch(
      resetPassword({
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
        token,
        navigate,
      }),
    )
  }

  const resendEmail = (e) => {
    e.preventDefault()
    dispatch(resendLink(token))
  }

  const resetPassFields = renderFormFields({
    errors,
    formFields,
    values,
    handleChange,
  })

  return (
    <Grid container className={classes.loginContainer}>
      <Grid item md={5}>
        <img src={loginImage} alt="company-logo" loading="lazy" />
      </Grid>
      <Grid item md={7} px={10} className={classes.loginForm}>
        {equal(checkLinkExpireLoading, true) ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - -4px)' }}
          >
            <DKTCircularProgress />
          </Stack>
        ) : (
          <Box
            component="form"
            onSubmit={isExpired ? resendEmail : handleResetPassword}
          >
            <img src={companyLogo} alt="company-logo" loading="lazy" />
            <Grid container rowGap={2}>
              {isExpired ? (
                <DKTTypography>
                  The password reset link is no longer valid. Click the button
                  below to receive a new link via email.
                </DKTTypography>
              ) : (
                resetPassFields
              )}
            </Grid>
            <Stack direction="row" alignItems="center" mt={2}>
              <DKTButton
                type="submit"
                disabled={
                  isResettingPassword || isLoadingResendEmail || backToLogin
                }
              >
                {isExpired
                  ? 'Resend Email'
                  : ternary(
                      isResettingPassword,
                      'Resetting Password...',
                      'Reset Password',
                    )}
              </DKTButton>
              {isExpired && backToLogin && (
                <Link to="/login" className={classes.resendEmailWrapper}>
                  Back to login?
                </Link>
              )}
            </Stack>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default ResetPassword
