import {
  CHECK_LINK_EXPIRE_FAILED,
  CHECK_LINK_EXPIRE_REQUESTED,
  CHECK_LINK_EXPIRE_SUCCESS,
  FETCH_SYSTEM_FAILED,
  FETCH_SYSTEM_REQUESTED,
  FETCH_SYSTEM_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCESS,
  GET_OTP_SUCCESS,
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  RESEND_EMAIL_FAILED,
  RESEND_EMAIL_REQUESTED,
  RESEND_EMAIL_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_SUCCESS,
  USER_ACTIVATION_FAILED,
  USER_ACTIVATION_REQUESTED,
  USER_ACTIVATION_SUCCESS,
} from '../constants/auth'

const initialState = {
  accessToken: null,
  refreshToken: null,
  isLoadingLogin: false,
  isResettingPassword: false,
  error: null,
  isSendingMail: false,
  loginError: null,
  forgotPasswordError: null,
  resetPasswordError: null,
  isActivationUserLoading: false,
  activateUserError: null,
  systemAuthorization: null,
  projects: null,
  isSystemAuthorizationLoading: false,
  checkLinkExpireLoading: true,
  isExpired: false,
  isLoadingResendEmail: false,
  backToLogin: false,
}

export const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
      return {
        ...state,
        isLoadingLogin: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...action.payload,
        isLoadingLogin: false,
        loginError: null,
      }
    case LOGIN_FAILED:
      return {
        ...state,
        isLoadingLogin: false,
        loginError: action.payload,
      }
    case GET_OTP_SUCCESS:
      return {
        ...state,
        isLoadingLogin: false,
      }
    case FORGOT_PASSWORD_REQUESTED:
      return {
        ...state,
        isSendingMail: true,
      }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isSendingMail: false,
        forgotPasswordError: null,
      }
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isSendingMail: false,
        forgotPasswordError: action.payload,
      }
    case RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        isResettingPassword: true,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: null,
      }
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        isResettingPassword: false,
        resetPasswordError: action.payload,
      }
    // Activate user
    case USER_ACTIVATION_REQUESTED:
      return {
        ...state,
        isActivationUserLoading: true,
      }
    case USER_ACTIVATION_SUCCESS:
      return {
        ...state,
        isActivationUserLoading: false,
      }
    case USER_ACTIVATION_FAILED:
      return {
        ...state,
        isActivationUserLoading: false,
        activateUserError: action.payload,
      }
    // System authorization
    case FETCH_SYSTEM_REQUESTED:
      return {
        ...state,
        isSystemAuthorizationLoading: true,
      }
    case FETCH_SYSTEM_SUCCESS:
      return {
        ...state,
        isSystemAuthorizationLoading: false,
        systemAuthorization: action?.payload?.systemAuthorization,
        projects: action.payload?.projects,
        portalType: action.payload?.portalType,
      }
    case FETCH_SYSTEM_FAILED:
      return {
        ...state,
        isSystemAuthorizationLoading: false,
      }
    // Check reset link expire
    case CHECK_LINK_EXPIRE_REQUESTED:
      return {
        ...state,
        checkLinkExpireLoading: true,
      }
    case CHECK_LINK_EXPIRE_SUCCESS:
      return {
        ...state,
        checkLinkExpireLoading: false,
        isExpired: action.payload.isExpired,
      }
    case CHECK_LINK_EXPIRE_FAILED:
      return {
        ...state,
        checkLinkExpireLoading: false,
      }
    // resend email
    case RESEND_EMAIL_REQUESTED:
      return {
        ...state,
        isLoadingResendEmail: true,
      }
    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        isLoadingResendEmail: false,
        backToLogin: true,
      }
    case RESEND_EMAIL_FAILED:
      return {
        ...state,
        isLoadingResendEmail: false,
      }
    default:
      return state
  }
}
