import { Box, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { formFields } from '../../Description/userActivation.description'
import useForm from '../../Hooks/useForm'
import { activateUser } from '../../Redux/actions/auth'
import DKTButton from '../../Shared/DKTButton'
import { useStyles } from '../../Styles/resetPassword.style'
import { ternary } from '../../Utils/javascript'
import { renderFormFields } from '../../Utils/renderFormFields'
import companyLogo from '../../Assets/company-logo.jpg'
import loginImage from '../../Assets/login.jpg'

const UserActivation = () => {
  const { token } = useParams()
  const { isActivationUserLoading, activateUserError } = useSelector(
    ({ auth }) => auth,
  )
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { values, handleChange, handleSubmit, errors, setErrors } =
    useForm(formFields)

  useEffect(() => {
    if (activateUserError) {
      setErrors({ ...errors, ...activateUserError })
    }
  }, [activateUserError])

  const handleActivateUser = (e) => {
    e.preventDefault()
    const isFormValid = handleSubmit()
    if (!isFormValid) return
    dispatch(
      activateUser({
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
        token,
        navigate,
      }),
    )
  }

  return (
    <Grid container className={classes.loginContainer}>
      <Grid item md={5}>
        <img src={loginImage} alt="company-logo" loading="lazy" />
      </Grid>
      <Grid item md={7} px={10} className={classes.loginForm}>
        <Box component="form" onSubmit={handleActivateUser}>
          <img src={companyLogo} alt="company-logo" loading="lazy" />
          <Grid container rowGap={2}>
            {renderFormFields({ errors, formFields, values, handleChange })}
          </Grid>
          <DKTButton
            type="submit"
            sx={{ mt: 2 }}
            disabled={isActivationUserLoading}
          >
            {ternary(
              isActivationUserLoading,
              'Activating User...',
              'Active User',
            )}
          </DKTButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default UserActivation
