import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  addBtn: {
    marginLeft: 230,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  inlineLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 'bold',
      color: '#2D2D2D',
      fontSize: 14,
      marginBottom: 8,
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))
