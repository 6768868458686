import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, length, lt, ternary } from '../../Utils/javascript'
import {
  ADD_SITE_BUILDING,
  CREATE_SITE_AND_BUILDING_FAILED,
  CREATE_SITE_AND_BUILDING_REQUESTED,
  CREATE_SITE_AND_BUILDING_SUCCESS,
  DELETE_SITE_AND_BUILDING_FAILED,
  DELETE_SITE_AND_BUILDING_REQUESTED,
  DELETE_SITE_AND_BUILDING_SUCCESS,
  FETCH_BUILDING_UNIT_INFO_FAILED,
  FETCH_BUILDING_UNIT_INFO_REQUESTED,
  FETCH_BUILDING_UNIT_INFO_SUCCESS,
  FETCH_SITE_AND_BUILDING_FAILED,
  FETCH_SITE_AND_BUILDING_REQUESTED,
  FETCH_SITE_AND_BUILDING_SUCCESS,
  REMOVE_BUILDING_UNIT_INFO_FAILED,
  REMOVE_BUILDING_UNIT_INFO_REQUESTED,
  REMOVE_BUILDING_UNIT_INFO_SUCCESS,
  SAVE_BUILDING_UNIT_INFO_FAILED,
  SAVE_BUILDING_UNIT_INFO_REQUESTED,
  SAVE_BUILDING_UNIT_INFO_SUCCESS,
  SAVE_SITE_AND_BUILDING_FAILED,
  SAVE_SITE_AND_BUILDING_REQUESTED,
  SAVE_SITE_AND_BUILDING_SUCCESS,
  SET_SITE_ID,
} from '../constants/siteAndBuilding'
import { updateSaveStatusAction } from './confirmation'

const UNIQUE_CODE_PREFIX = 'SB'
const getUniqueCode = (code) =>
  `${UNIQUE_CODE_PREFIX}-${ternary(lt(code, 10), `0${code}`, code)}`

const fetchSiteAndBuildingRequested = () => ({
  type: FETCH_SITE_AND_BUILDING_REQUESTED,
})
const fetchSiteAndBuildingSuccess = (payload) => ({
  type: FETCH_SITE_AND_BUILDING_SUCCESS,
  payload,
})
const fetchSiteAndBuildingFailed = (payload) => ({
  type: FETCH_SITE_AND_BUILDING_FAILED,
  payload,
})
export const fetchSiteAndBuilding = (projectId) => async (dispatch) => {
  dispatch(fetchSiteAndBuildingRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.siteAndBuilding}/?projectId=${projectId}`,
  })
  if (data) {
    const dataWithUpdatedBooleans = data?.map((site) => ({
      ...site,
      photovoltaic: site.photovoltaic?.toString(),
      solarThermal: site.solarThermal?.toString(),
      geothermal: site.geothermal?.toString(),
    }))
    dispatch(fetchSiteAndBuildingSuccess(dataWithUpdatedBooleans))
  }
  if (error) {
    dispatch(fetchSiteAndBuildingFailed(error.response))
  }
}

const createSiteAndBuildingRequested = () => ({
  type: CREATE_SITE_AND_BUILDING_REQUESTED,
})
const createSiteAndBuildingSuccess = (payload) => ({
  type: CREATE_SITE_AND_BUILDING_SUCCESS,
  payload,
})
const createSiteAndBuildingFailed = (payload) => ({
  type: CREATE_SITE_AND_BUILDING_FAILED,
  payload,
})
export const createSiteAndBuilding =
  (payload, project, uniqueCode) => async (dispatch, getState) => {
    const STUDIO_SRO_UNITS = 5
    const { siteAndBuildings, buildingAndUnitsInfo } =
      getState().siteAndBuilding
    dispatch(createSiteAndBuildingRequested())
    const { data, error } = await api({
      method: method.post,
      endPoint: `${apiEndPoints.siteAndBuilding}/?projectId=${project}`,
      data: {
        ...payload,
        project,
        studioSroUnits: STUDIO_SRO_UNITS,
        uniqueCode:
          uniqueCode ||
          getUniqueCode(
            ternary(siteAndBuildings, length(siteAndBuildings) + 1, '01'),
          ),
        isBuildingAndUnitInformation: !!buildingAndUnitsInfo?.buildingAndUnits,
        buildingAndUnitInformation:
          buildingAndUnitsInfo?.buildingAndUnits?.map(({ id }) => id) || [],
      },
      showToastMessage: true,
      toastMessage: 'Site & Building created successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      const newSiteAndBuildings = siteAndBuildings.map((site) =>
        ternary(equal(uniqueCode, site.uniqueCode), data, site),
      )
      dispatch(createSiteAndBuildingSuccess(newSiteAndBuildings))
      dispatch(setSiteId(data.id))
    }
    if (error) {
      dispatch(createSiteAndBuildingFailed(error.response.data))
    }
  }

const saveSiteAndBuildingRequested = () => ({
  type: SAVE_SITE_AND_BUILDING_REQUESTED,
})
const saveSiteAndBuildingSuccess = (payload) => ({
  type: SAVE_SITE_AND_BUILDING_SUCCESS,
  payload,
})
const saveSiteAndBuildingFailed = (payload) => ({
  type: SAVE_SITE_AND_BUILDING_FAILED,
  payload,
})
export const saveSiteAndBuilding = (payload) => async (dispatch, getState) => {
  const { siteAndBuildings, buildingAndUnitsInfo } = getState().siteAndBuilding
  const { isNewSite, uniqueCode } =
    siteAndBuildings.find((site) => equal(site.id, +payload.id)) || {}
  if (isNewSite) {
    dispatch(createSiteAndBuilding(payload.data, payload.projectId, uniqueCode))
    return
  }
  dispatch(saveSiteAndBuildingRequested())
  const { data, error } = await api({
    method: method.patch,
    endPoint: `${apiEndPoints.siteAndBuilding}/${payload.id}/?projectId=${payload.projectId}`,
    data: {
      ...payload.data,
      isBuildingAndUnitInformation: !!buildingAndUnitsInfo?.buildingAndUnits,
      buildingAndUnitInformation:
        buildingAndUnitsInfo?.buildingAndUnits?.map(({ id }) => id) || [],
    },
    showToastMessage: true,
    toastMessage: 'Site & Building updated successfully!',
  })
  setTimeout(() => {
    dispatch(updateSaveStatusAction(data, error))
  }, 700)
  if (data) {
    const updateSiteAndBuildings = siteAndBuildings.map((site) =>
      ternary(
        equal(site.id, +payload.id),
        {
          ...data,
          photovoltaic: data.photovoltaic?.toString(),
          solarThermal: data.solarThermal?.toString(),
          geothermal: data.geothermal?.toString(),
        },
        site,
      ),
    )
    dispatch(saveSiteAndBuildingSuccess(updateSiteAndBuildings))
  }
  if (error) {
    dispatch(saveSiteAndBuildingFailed(error.response.data))
  }
}

const fetchBuildingUnitInformationRequested = () => ({
  type: FETCH_BUILDING_UNIT_INFO_REQUESTED,
})
const fetchBuildingUnitInformationSuccess = (payload) => ({
  type: FETCH_BUILDING_UNIT_INFO_SUCCESS,
  payload,
})
const fetchBuildingUnitInformationFailed = (payload) => ({
  type: FETCH_BUILDING_UNIT_INFO_FAILED,
  payload,
})
export const fetchBuildingUnitInformation =
  ({ siteId, projectId }) =>
  async (dispatch, getState) => {
    const { siteAndBuildings } = getState().siteAndBuilding
    const { uniqueCode } =
      siteAndBuildings?.find(({ id }) => equal(id, +siteId)) || {}
    if (!uniqueCode) return
    dispatch(fetchBuildingUnitInformationRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.buildingAndUnitInfo}${uniqueCode}/${ternary(
        projectId,
        `?projectId=${projectId}`,
        '',
      )}`,
    })
    if (data) {
      dispatch(fetchBuildingUnitInformationSuccess(data))
    }
    if (error) {
      dispatch(fetchBuildingUnitInformationFailed(error.response))
    }
  }

const saveBuildingUnitInformationRequested = () => ({
  type: SAVE_BUILDING_UNIT_INFO_REQUESTED,
})
const saveBuildingUnitInformationSuccess = (payload) => ({
  type: SAVE_BUILDING_UNIT_INFO_SUCCESS,
  payload,
})
const saveBuildingUnitInformationFailed = (payload) => ({
  type: SAVE_BUILDING_UNIT_INFO_FAILED,
  payload,
})
export const saveBuildingUnitInformation =
  (payload, siteId, siteAndBuildingTotal, projectId) =>
  async (dispatch, getState) => {
    const { buildingAndUnitsInfo, siteAndBuildings } =
      getState().siteAndBuilding
    const { uniqueCode } =
      siteAndBuildings?.find(({ id }) => equal(id, +siteId)) || {}
    const shouldUpdate = buildingAndUnitsInfo?.buildingAndUnits
    const payloadData = payload.map((site) => ({
      ...site,
      siteAndBuildings:
        uniqueCode ||
        getUniqueCode(
          ternary(siteAndBuildings, length(siteAndBuildings) + 1, 1),
        ),
    }))
    dispatch(saveBuildingUnitInformationRequested())
    const { data, error } = await api({
      method: ternary(shouldUpdate, method.patch, method.post),
      endPoint: ternary(
        shouldUpdate,
        `${apiEndPoints.buildingAndUnitInfo}update/${uniqueCode}/?projectId=${projectId}`,
        `${apiEndPoints.buildingAndUnitInfo}?projectId=${projectId}`,
      ),
      data: payloadData,
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        saveBuildingUnitInformationSuccess({
          buildingAndUnits: data,
          total: siteAndBuildingTotal,
        }),
      )
    }
    if (error) {
      dispatch(saveBuildingUnitInformationFailed(error.response.data))
    }
  }

const removeBuildingUnitInformationRequested = () => ({
  type: REMOVE_BUILDING_UNIT_INFO_REQUESTED,
})
const removeBuildingUnitInformationSuccess = (payload) => ({
  type: REMOVE_BUILDING_UNIT_INFO_SUCCESS,
  payload,
})
const removeBuildingUnitInformationFailed = (payload) => ({
  type: REMOVE_BUILDING_UNIT_INFO_FAILED,
  payload,
})
export const removeBuildingUnitInformation =
  (buildingUnitInfoId, projectId) => async (dispatch) => {
    dispatch(removeBuildingUnitInformationRequested())
    const { status, error } = await api({
      method: method.delete,
      endPoint: `${apiEndPoints.buildingAndUnitInfo}delete/?projectId=${projectId}`,
      data: { buildingAndUnitInformation: [buildingUnitInfoId] },
      showToastMessage: true,
      toastMessage: 'Deleted successfully!',
    })
    if (status) {
      dispatch(removeBuildingUnitInformationSuccess())
    }
    if (error) {
      dispatch(removeBuildingUnitInformationFailed(error.response.data))
    }
  }

export const addSiteBuildingSuccess = (payload) => ({
  type: ADD_SITE_BUILDING,
  payload,
})

export const addSiteAndBuilding = () => (dispatch, getState) => {
  const { siteAndBuildings } = getState().siteAndBuilding
  const id = new Date().getTime()
  const sortedSiteAndBuildingList = siteAndBuildings?.sort((a, b) =>
    a?.uniqueCode > b?.uniqueCode ? 1 : -1,
  )
  const lastIndexSiteAndBuilding = sortedSiteAndBuildingList
    ?.slice(-1)[0]
    ?.uniqueCode?.split('-')[1]
  dispatch(
    addSiteBuildingSuccess([
      ...siteAndBuildings,
      {
        id,
        isNewSite: true,
        uniqueCode: getUniqueCode(
          ternary(lastIndexSiteAndBuilding, +lastIndexSiteAndBuilding + 1, '1'),
        ),
      },
    ]),
  )
  dispatch(setSiteId(id))
}

export const setSiteId = (siteId) => ({ type: SET_SITE_ID, payload: siteId })

// Delete site and building
const deleteSiteAndBuildingRequested = () => ({
  type: DELETE_SITE_AND_BUILDING_REQUESTED,
})
export const deleteSiteAndBuildingSuccess =
  (payload) => (dispatch, getState) => {
    const { siteAndBuildings } = getState().siteAndBuilding
    const index = siteAndBuildings?.findIndex(({ id }) =>
      equal(id, payload?.siteAndBuilding[0]),
    )
    if (~index) {
      siteAndBuildings.splice(index, 1)
    }
    dispatch({
      type: DELETE_SITE_AND_BUILDING_SUCCESS,
      payload: siteAndBuildings,
      activeSiteBuilding: siteAndBuildings[0]?.id,
    })
  }
const deleteSiteAndBuildingFailed = (payload) => ({
  type: DELETE_SITE_AND_BUILDING_FAILED,
  payload,
})

export const deleteSiteAndBuilding =
  (payload, handleClose) => async (dispatch) => {
    dispatch(deleteSiteAndBuildingRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.deleteSiteAndBuilding}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Site & building deleted successfully!',
    })
    if (data) {
      handleClose()
      dispatch(deleteSiteAndBuildingSuccess(payload))
      return
    }
    if (error) {
      if (equal(error?.response?.status, 400)) {
        handleClose()
      }
      dispatch(deleteSiteAndBuildingFailed(error.response.data))
    }
  }
