import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  loginContainer: {
    '& > .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& img': {
      width: '100%',
      height: '100vh',
      objectFit: 'cover',
    },
  },
  loginForm: {
    '& form': {
      margin: '200px 0 auto',
      width: '100%',
      maxWidth: '350px',
      '& img': {
        height: 75,
        width: 'auto',
        marginBottom: 100,
      },
    },
  },
  resendEmailWrapper: {
    display: 'inline-block',
    maxWidth: 'max-content',
    textDecoration: 'none',
    fontSize: '13px',
    margin: 'auto 20px',
    fontFamily: 'Roboto',
    color: theme.palette.primary.main,
  },
}))
