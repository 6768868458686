import { jwtDecode } from 'jwt-decode'
import { apiEndPoints, method } from '../../Utils/constant'
import {
  loadStateFn,
  removeStateFn,
  saveStateFn,
} from '../../Utils/localStorage'
import api from '../../Utils/api'
import { replaceLocation } from '../../Utils/window'

export const getRefreshAccessToken = async (accessToken) => {
  const { exp } = await jwtDecode(accessToken)

  const currentDate = new Date()
  const tokenDate = new Date(exp * 1000)

  if (currentDate.getTime() <= tokenDate.getTime()) return

  const refreshToken = JSON.parse(loadStateFn('auth'))?.refreshToken

  const { data } = await api({
    method: method.post,
    endPoint: apiEndPoints?.refreshAccessToken,
    refreshAccessToken: false,
    data: {
      refresh: refreshToken,
    },
  })

  if (data?.access) {
    const localAuthPayload = {
      accessToken: data.access,
      refreshToken,
    }
    saveStateFn('auth', JSON.stringify(localAuthPayload))
  } else {
    removeStateFn('auth')
    removeStateFn('company-id')
    replaceLocation('/login')
  }
}
