import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import React from 'react'
import { matchPath, NavLink, useLocation } from 'react-router-dom'
import { ternary } from '../../Utils/javascript'
import { rfpMenuList } from '../../Description/rfp.description'

const useStyles = makeStyles(() => ({
  projectMenu: {
    display: 'flex',
    '& .MuiListItem-root': {
      whiteSpace: 'nowrap',
      '& .MuiTypography-root': {
        fontWeight: '700',
      },
    },
  },
}))

const ProjectMenuLink = ({ data }) => {
  const theme = useTheme()
  const location = useLocation()
  const match = matchPath(`/rfp/${data?.id}`, location.pathname)
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={NavLink}
        to={data?.url}
        style={ternary(match, { color: theme.palette.primary.main }, {})}
      >
        <ListItemText primary={data.menu} />
      </ListItemButton>
    </ListItem>
  )
}

const PageTitleBar = ({ title, isShowMenu = true }) => {
  const classes = useStyles()

  return (
    <Stack
      height="50px"
      backgroundColor="gray.dark"
      px={3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      top={64}
      left={0}
      right={0}
      zIndex={999}
    >
      <Typography variant="h5" component="h2" fontWeight={700}>
        {ternary(title, title, '')}
      </Typography>
      {ternary(
        isShowMenu,
        <List className={classes.projectMenu} disablePadding>
          {rfpMenuList?.map((data, i) => (
            <ProjectMenuLink key={i} data={data} />
          ))}
        </List>,
        null,
      )}
    </Stack>
  )
}

export default PageTitleBar
