import { fahrenheit } from '../Utils/constant'
import { timeValidation } from '../Utils/regex'

export const formFields = {
  leftSide: {
    reportDate: {
      type: 'date',
      clearable: true,
      label: 'Report Date',
      isRequired: true,
    },
    virtualMeeting: {
      type: 'select',
      label: 'Virtual Meeting',
      options: [],
      placeholder: 'Select',
      xs: 5,
      displayEmpty: true,
    },
    virtualMeetingDate: {
      type: 'date',
      clearable: true,
      label: 'Date',
      xs: 7,
    },
    onSiteVisit: {
      type: 'select',
      label: 'On-site Visit',
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
    siteVisitDate: {
      type: 'date',
      clearable: true,
      label: 'Site Visit Date',
    },
    timeOfVisit: {
      type: 'text',
      label: 'Time of Visit',
      placeholder: '00:00',
      isTime: true,
      xs: 8,
      validate: [timeValidation],
    },
    timeOfVisitMeridiem: {
      type: 'select',
      label: 'AM/PM',
      options: [],
      xs: 4,
      sx: {
        '& .MuiSelect-select': {
          paddingRight: '0px !important',
          paddingLeft: '8px',
        },
      },
    },
    temperature: {
      type: 'select',
      label: 'Temperature',
      placeholder: 'Select',
      displayEmpty: true,
      renderValue: (value) => `${value}${fahrenheit}`,
    },
    siteConditions: {
      type: 'select',
      label: 'Site Conditions',
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
  },
  rightSide: {
    executiveSummary: {
      type: 'textarea',
      label: 'Executive Summary',
      rows: 14,
      placeholder: 'Placeholder Text',
      formTextAreaStyle: {
        marginTop: '14px',
      },
    },
    openIssues: {
      type: 'textarea',
      label: 'Open Issues',
      rows: 6,
      placeholder: 'Placeholder Text',
    },
  },
  comments: {
    comments: {
      type: 'textarea',
      label: 'Comments',
      rows: 6,
      placeholder: 'Placeholder Text',
    },
  },
}

export const criticalIssues = [
  'Payment Application approved as requested',
  'Consultant agrees with Contractor’s percent complete',
  'Adequate hard costs funds to complete the project ',
  'Adequate hard cost contingency  to complete the project',
  "Change Order amount is lower than the lender's/investor's consent limits",
  'Potential Change Order amount is acceptable relative to contingency',
  'Progress is consistent with the original construction schedule',
  'Observed quality of work is in accordance with the Contract Documents',
  'Amount for off-site stored materials/material deposits is high this period',
  'Other significant issues impacting the project (see comments)',
]

export const choiceFields = [
  'criticalIssues',
  'temperature',
  'siteConditions',
  'timeOfVisitMeridiem',
  'onSiteVisit',
  'virtualMeeting',
]
