import {
  SAVE_SUPPORT_FORM_FAILED,
  SAVE_SUPPORT_FORM_REQUESTED,
  SAVE_SUPPORT_FORM_SUCCESS,
} from '../constants/support'
import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { updateSaveStatusAction } from './confirmation'
import { showToast } from '../../Utils/toastService'

// Save support
const saveReportIssueRequested = () => ({
  type: SAVE_SUPPORT_FORM_REQUESTED,
})
export const saveReportIssueSuccess = (payload) => ({
  type: SAVE_SUPPORT_FORM_SUCCESS,
  payload,
})
const saveReportIssueFailed = (payload) => ({
  type: SAVE_SUPPORT_FORM_FAILED,
  payload,
})

export const saveReportIssue =
  (payload, confirmSaveWarningModal) => async (dispatch) => {
    dispatch(saveReportIssueRequested())

    const { data, error } = await api({
      method: method.post,
      endPoint: `${apiEndPoints.reportIssue}`,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Message submitted successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(saveReportIssueSuccess(data))
      confirmSaveWarningModal()
    }
    if (error) {
      showToast(error?.response?.data?.attachment?.[0])
      dispatch(saveReportIssueFailed(error.response.data))
    }
  }
