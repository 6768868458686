import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, ternary } from '../../Utils/javascript'
import {
  CREATE_SITE_ADDRESS_FAILED,
  CREATE_SITE_ADDRESS_REQUESTED,
  CREATE_SITE_ADDRESS_SUCCESS,
  DELETE_SITE_ADDRESS_FAILED,
  DELETE_SITE_ADDRESS_REQUESTED,
  DELETE_SITE_ADDRESS_SUCCESS,
  FETCH_SITE_ADDRESS_LIST_FAILED,
  FETCH_SITE_ADDRESS_LIST_REQUESTED,
  FETCH_SITE_ADDRESS_LIST_SUCCESS,
} from '../constants/siteAddress'
import { updateSaveStatusAction } from './confirmation'

// Fetch site address list
const fetchSiteAddressListRequested = () => ({
  type: FETCH_SITE_ADDRESS_LIST_REQUESTED,
})
const fetchSiteAddressListSuccess = (payload) => ({
  type: FETCH_SITE_ADDRESS_LIST_SUCCESS,
  payload,
})
const fetchSiteAddressListFailed = (payload) => ({
  type: FETCH_SITE_ADDRESS_LIST_FAILED,
  payload,
})

export const getSiteAddressList = (projectId) => async (dispatch) => {
  dispatch(fetchSiteAddressListRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.siteAddressList}?projectId=${projectId || ''}`,
    method: method.get,
    showToastMessage: false,
    toastMessage: 'Fetch site address list successfully!',
  })
  if (data) {
    dispatch(fetchSiteAddressListSuccess(data))
    return
  }
  if (error) {
    if (equal(error?.response?.status, 404)) {
      dispatch(fetchSiteAddressListSuccess([]))
    }
    dispatch(fetchSiteAddressListFailed(error.response))
  }
}

// Create site address
const createAndUpdateSiteAddressRequested = () => ({
  type: CREATE_SITE_ADDRESS_REQUESTED,
})
const createAndUpdateSiteAddressSuccess = (payload) => ({
  type: CREATE_SITE_ADDRESS_SUCCESS,
  payload,
})
const createAndUpdateSiteAddressFailed = (payload) => ({
  type: CREATE_SITE_ADDRESS_FAILED,
  payload,
})

export const createAndUpdateSiteAddressList =
  (payload, projectId) => async (dispatch, getState) => {
    const state = getState()
    const siteAddressList = [...state.siteAddress.siteAddressList]
    const oldAddressId = siteAddressList?.map((address) => `${address?.id}`)
    const newAddress = payload?.filter(
      (flt) => !oldAddressId?.includes(flt?.id),
    )
    dispatch(createAndUpdateSiteAddressRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.siteAddressList}?projectId=${projectId || ''}`,
      method: ternary(newAddress?.length, method.post, method.patch),
      data: ternary(newAddress?.length, newAddress, payload),
      showToastMessage: true,
      toastMessage: `site address ${ternary(
        newAddress?.length,
        'Created',
        'Updated',
      )} successfully!`,
    })

    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(createAndUpdateSiteAddressSuccess(payload))
      return
    }
    if (error) {
      dispatch(createAndUpdateSiteAddressFailed(error.response.data))
    }
  }

// delete site address
const deleteSiteAddressRequested = () => ({
  type: DELETE_SITE_ADDRESS_REQUESTED,
})
const deleteSiteAddressSuccess = (payload) => (dispatch, getState) => {
  const state = getState()
  const siteAddressList = [...state.siteAddress.siteAddressList]
  const index = siteAddressList?.findIndex(({ id }) =>
    equal(`${id}`, payload?.siteAddress[0]),
  )
  if (~index) {
    siteAddressList.splice(index, 1)
  }

  dispatch({
    type: DELETE_SITE_ADDRESS_SUCCESS,
    payload: siteAddressList,
  })
}
const deleteSiteAddressFailed = (payload) => ({
  type: DELETE_SITE_ADDRESS_FAILED,
  payload,
})

export const deleteSiteAddress = (payload, slug) => async (dispatch) => {
  dispatch(deleteSiteAddressRequested())
  const { status, error } = await api({
    endPoint: `${apiEndPoints.siteAddressDelete}/?projectId=${slug}`,
    method: method.delete,
    data: payload,
    showToastMessage: false,
    toastMessage: 'site address Deleted successfully!',
  })
  if (status) {
    dispatch(deleteSiteAddressSuccess(payload))
    return
  }
  if (error) {
    dispatch(deleteSiteAddressFailed(error.response.data))
  }
}
