export const FETCH_COMPANY_PERSONNEL_REQUESTED =
  'FETCH_COMPANY_PERSONNEL_REQUESTED'
export const FETCH_COMPANY_PERSONNEL_SUCCESS = 'FETCH_COMPANY_PERSONNEL_SUCCESS'
export const FETCH_COMPANY_PERSONNEL_FAILED = 'FETCH_COMPANY_PERSONNEL_FAILED'

export const CREATE_PERSONNEL_REQUESTED = 'CREATE_PERSONNEL_REQUESTED'
export const CREATE_PERSONNEL_SUCCESS = 'CREATE_PERSONNEL_SUCCESS'
export const CREATE_PERSONNEL_FAILED = 'CREATE_PERSONNEL_FAILED'

export const UPDATE_COMPANY_PERSONNEL_REQUESTED =
  'UPDATE_COMPANY_PERSONNEL_REQUESTED'
export const UPDATE_COMPANY_PERSONNEL_SUCCESS =
  'UPDATE_COMPANY_PERSONNEL_SUCCESS'
export const UPDATE_COMPANY_PERSONNEL_FAILED = 'UPDATE_COMPANY_PERSONNEL_FAILED'

export const DELETE_COMPANY_PERSONNEL_REQUESTED =
  'DELETE_COMPANY_PERSONNEL_REQUESTED'
export const DELETE_COMPANY_PERSONNEL_SUCCESS =
  'DELETE_COMPANY_PERSONNEL_SUCCESS'
export const DELETE_COMPANY_PERSONNEL_FAILED = 'DELETE_COMPANY_PERSONNEL_FAILED'

export const RESET_PERSONNEL_PASSWORD_REQUESTED =
  'RESET_PERSONNEL_PASSWORD_REQUESTED'
export const RESET_PERSONNEL_PASSWORD_SUCCESS =
  'RESET_PERSONNEL_PASSWORD_SUCCESS'
export const RESET_PERSONNEL_PASSWORD_FAILED = 'RESET_PERSONNEL_PASSWORD_FAILED'

export const GENERATE_PASSWORD_REQUESTED = 'GENERATE_PASSWORD_REQUESTED'
export const GENERATE_PASSWORD_SUCCESS = 'GENERATE_PASSWORD_SUCCESS'
export const GENERATE_PASSWORD_FAILED = 'GENERATE_PASSWORD_FAILED'
