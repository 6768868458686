import {
  FETCH_TOTAL_COUNTS_REQUESTED,
  FETCH_TOTAL_COUNTS_SUCCESS,
  FETCH_TOTAL_COUNTS_FAILED,
  FETCH_PROJECTS_LIST_REQUESTED,
  FETCH_PROJECTS_LIST_SUCCESS,
  FETCH_PROJECTS_LIST_FAILED,
  FETCH_FILTER_PROJECTS_REQUESTED,
  FETCH_FILTER_PROJECTS_SUCCESS,
  FETCH_FILTER_PROJECTS_FAILED,
  FETCH_SEARCH_PROJECT_REQUESTED,
  FETCH_SEARCH_PROJECT_SUCCESS,
  FETCH_SEARCH_PROJECT_FAILED,
  CLEAR_PROJECT_DATA,
  SET_PROJECT_DATA,
  CLEAR_MARKER_HOVER,
  SET_MARKER_HOVER,
  ADD_REMOVE_FAVORITE_REQUEST,
  ADD_REMOVE_FAVORITE_FAILED,
  ADD_REMOVE_FAVORITE_SUCCESS,
} from '../constants/globalDashboard'

const initialState = {
  // total Counts Of Titles
  isFetchingTotalCounts: false,
  totalCountsOfTitles: null,
  totalCountsOfTitlesError: null,
  // projects list
  isFetchingProjectList: false,
  projectList: [],
  getProjectsListError: null,
  // search project
  isFetchingSearchProject: false,
  searchProjectLists: [],
  searchProjectError: null,
  // onClickRowData
  projectId: null,
  activeMarker: null,
  // onClick favoriteMark
  isFavoritesLoading: false,
  favoriteError: null,
}

const globalDashboard = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch total Counts Of Titles
    case FETCH_TOTAL_COUNTS_REQUESTED:
      return {
        ...state,
        isFetchingTotalCounts: true,
        projectList: [],
      }
    case FETCH_TOTAL_COUNTS_SUCCESS:
      return {
        ...state,
        isFetchingTotalCounts: false,
        projectList: action.payload?.projectLists,
        totalCountsOfTitles: action?.payload?.totalCounts,
        totalCountsOfTitlesError: null,
      }
    case FETCH_TOTAL_COUNTS_FAILED:
      return {
        ...state,
        isFetchingTotalCounts: false,
        totalCountsOfTitlesError: action.payload,
      }
    // Fetch projects List
    case FETCH_PROJECTS_LIST_REQUESTED:
      return {
        ...state,
        isFetchingProjectList: true,
        projectList: [],
      }
    case FETCH_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        isFetchingProjectList: false,
        projectList: action.payload.projects?.projectList,
        getProjectsListError: null,
      }
    case FETCH_PROJECTS_LIST_FAILED:
      return {
        ...state,
        isFetchingProjectList: false,
        getFilterProjects: action.payload,
      }
    // filter based on dropDown fields
    case FETCH_FILTER_PROJECTS_REQUESTED:
      return {
        ...state,
        isFetchingFilterProjects: true,
      }
    case FETCH_FILTER_PROJECTS_SUCCESS:
      return {
        ...state,
        isFetchingFilterProjects: false,
        searchResult: action?.payload?.projects?.projectList,
        getFilterProjectsError: null,
      }
    case FETCH_FILTER_PROJECTS_FAILED:
      return {
        ...state,
        isFetchingFilterProjects: false,
        getFilterProjectsError: action.payload,
      }

    // search projects
    case FETCH_SEARCH_PROJECT_REQUESTED:
      return {
        ...state,
        isFetchingSearchProject: true,
      }
    case FETCH_SEARCH_PROJECT_SUCCESS:
      return {
        ...state,
        isFetchingSearchProject: false,
        searchProjectLists: action?.payload?.projects?.projectList,
        searchProjectError: null,
      }
    case FETCH_SEARCH_PROJECT_FAILED:
      return {
        ...state,
        isFetchingSearchProject: false,
        searchProjectLists: [],
        searchProjectError: action.payload,
      }
    case SET_PROJECT_DATA:
      return {
        ...state,
        projectId: action.payload,
      }
    case CLEAR_PROJECT_DATA:
      return {
        ...state,
        projectId: null,
        activeMarker: null,
      }
    case SET_MARKER_HOVER:
      return {
        ...state,
        activeMarker: action.payload,
        projectId: null,
      }
    case CLEAR_MARKER_HOVER:
      return {
        ...state,
        activeMarker: null,
        projectId: null,
      }
    case ADD_REMOVE_FAVORITE_REQUEST:
      return {
        ...state,
        isFavoritesLoading: true,
      }
    case ADD_REMOVE_FAVORITE_SUCCESS:
      return {
        ...state,
        isFavoritesLoading: false,
      }
    case ADD_REMOVE_FAVORITE_FAILED:
      return {
        ...state,
        isFavoritesLoading: false,
        favoriteError: action.payload,
      }
    default:
      return state
  }
}

export default globalDashboard
