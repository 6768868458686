import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import StarRateIcon from '@mui/icons-material/StarRate'
import { Box } from '@mui/material'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  columns,
  formFields,
  options,
} from '../../../Description/rfp.description'
import {
  addAndRemoveFavoriteCompany,
  getRfpCompanyList,
} from '../../../Redux/actions/rfp'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTGrid from '../../../Shared/DKTGrid'
import DKTInput from '../../../Shared/DKTInput'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTStack from '../../../Shared/DKTStack'
import DKTTypography from '../../../Shared/DKTTypography'
import { useStyles } from '../../../Styles/rfc.style'
import {
  debounceFunction,
  entries,
  equal,
  keys,
  ternary,
} from '../../../Utils/javascript'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import { emptyTableCellPlaceholder } from '../../../Description/reportMonitoring.description'

const ConsultantTable = ({ FormSectionDivider, handleShowHideDetail }) => {
  const [values, setValues] = useState({
    isSearchBoxOpen: false,
    search: '',
    state: '',
  })
  const [delay, setDelay] = useState(false)

  const { rfpCompanyList, isLoadingCompanyList } = useSelector(
    ({ rfpData }) => rfpData,
  )
  const { state } = useSelector(({ states }) => states)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    const queryParams = {
      search: values?.search || '',
      state: ternary(
        equal(values?.state, 'all') || !values?.state,
        '',
        values?.state,
      ),
    }

    if (keys(queryParams).length > 0) {
      if (queryParams?.search && delay) {
        debounceFunction(() => dispatch(getRfpCompanyList(queryParams)), 500)()
        return
      }
      dispatch(getRfpCompanyList(queryParams))
    }
  }, [values?.search, values?.state])

  const handleOpenCloseSearchBox = ({ ...rest }) =>
    setValues({
      ...values,
      ...rest,
    })

  const handleCloseSearchBox = () => {
    handleOpenCloseSearchBox({
      isSearchBoxOpen: false,
      ...(values.search && { search: '' }),
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const cloneValues = { ...values }
    setValues({ ...cloneValues, [name]: value })
    setDelay(true)
    if (equal(name, 'search')) {
      debounceFunction(() => setDelay(false), 500)()
    }
  }

  const handleFavorite = (e, companyId, status) => {
    const data = {
      company_id: companyId,
      action: status ? 'remove' : 'add',
    }
    dispatch(addAndRemoveFavoriteCompany(data))
    e.stopPropagation()
  }

  const mapFormFields = useCallback(
    (formFields, extOptions = {}) =>
      entries(formFields).map(
        (
          [
            name,
            {
              isRequired,
              isDynamicOptions,
              defaultValue,
              options,
              ...formField
            },
          ],
          index,
        ) => {
          const FormControl = ternary(
            equal(formField.type, 'select'),
            DKTSelect,
            DKTInput,
          )
          return (
            <DKTGrid key={index} item xs={formField.xs ?? 12}>
              <FormControl
                {...formField}
                options={ternary(
                  isDynamicOptions,
                  [{ label: 'All States', value: 'all' }, ...state],
                  options,
                )}
                id={name}
                name={name}
                value={(values && values[name]) || ''}
                onChange={(e) => handleChange(e)}
                {...(extOptions?.autoFocus && index === 0
                  ? { autoFocus: true }
                  : {})}
                className={classes.selectType}
              />
            </DKTGrid>
          )
        },
      ),
    [formFields, state, values, handleChange, classes.selectType],
  )

  const consultantList = useMemo(
    () => (
      <DKTStack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <DKTStack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.selectBox}
        >
          {values?.isSearchBoxOpen ? (
            <DKTStack
              direction="row"
              alignItems="center"
              minWidth="220px"
              sx={{
                '& .MuiInputBase-input': { padding: 0 },
                '& .MuiFormControl-root': {
                  height: 24,
                },
              }}
            >
              {mapFormFields(formFields?.searchProject, { autoFocus: true })}
              <CloseIcon
                fontSize="small"
                color="#000"
                onClick={handleCloseSearchBox}
                cursor="pointer"
              />
            </DKTStack>
          ) : (
            <Box>
              <SearchIcon
                fontSize="small"
                color="#000"
                onClick={() =>
                  handleOpenCloseSearchBox({ isSearchBoxOpen: true })
                }
                cursor="pointer"
              />
            </Box>
          )}
          {mapFormFields(formFields?.selectSortFilter)}
        </DKTStack>
      </DKTStack>
    ),
    [values, state, formFields],
  )

  const customBodyRender = useCallback(
    (value, tableMeta) => {
      switch (tableMeta?.columnData?.name) {
        case 'isFavorite':
          return (
            <Box
              onClick={(e) => handleFavorite(e, tableMeta?.rowData?.[0], value)}
              sx={{ cursor: 'pointer', display: 'flex' }}
            >
              {value ? (
                <StarRateIcon style={{ color: '#FFEA00' }} />
              ) : (
                <StarOutlineOutlinedIcon />
              )}
            </Box>
          )
        case 'companyName':
          return (
            <DKTTypography
              color="primary.main"
              fontWeight="700"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleShowHideDetail(tableMeta?.rowData?.[0])}
            >
              {value}
            </DKTTypography>
          )
        default:
          return emptyTableCellPlaceholder
      }
    },
    [handleFavorite, handleShowHideDetail],
  )

  const tableOptions = {
    ...options,
    textLabels: {
      body: {
        noMatch: isLoadingCompanyList ? (
          <DKTCircularProgress />
        ) : (
          'Sorry, there is no matching values to display'
        ),
      },
    },
  }

  const descriptionTableColumns = columns?.map((report) => ({
    ...report,
    ...((equal(report.name, 'isFavorite') ||
      equal(report.name, 'companyName')) && {
      options: {
        ...report.options,
        customBodyRender,
      },
    }),
  }))

  return (
    <>
      <FormSectionDivider
        sectionTitle="CONSULTANT LIST"
        rightSection={consultantList}
        mt={0}
      />
      <Box
        sx={{
          maxHeight: '656px',
          minHeight: '656px',
          overflowY: 'auto',
          backgroundColor: '#ffffff',
        }}
      >
        <DKTMuiDataTable
          data={rfpCompanyList || [{}]}
          options={tableOptions}
          columns={descriptionTableColumns}
          className={`${classes.companyList}`}
        />
      </Box>
    </>
  )
}

export default memo(ConsultantTable)
