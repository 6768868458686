import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_PHOTOGRAPHS,
  CREATE_PHOTOGRAPHS_FAILED,
  CREATE_PHOTOGRAPHS_REQUESTED,
  CREATE_PHOTOGRAPHS_SUCCESS,
  DELETE_PHOTOGRAPHS_FAILED,
  DELETE_PHOTOGRAPHS_REQUESTED,
  DELETE_PHOTOGRAPHS_SUCCESS,
  FETCH_PHOTOGRAPHS_FAILED,
  FETCH_PHOTOGRAPHS_REQUEST,
  FETCH_PHOTOGRAPHS_SUCCESS,
  UPDATE_PHOTOGRAPHS_FAILED,
  UPDATE_PHOTOGRAPHS_REQUESTED,
  UPDATE_PHOTOGRAPHS_SUCCESS,
} from '../constants/photographs'

const initialState = {
  isPhotographListCreateOrUpdating: false,
  isPhotographListLoading: true,
  photographsList: [],
  photographsUploadError: null,
  isPhotographDeleting: false,
}

export const photographsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch photographs
    case FETCH_PHOTOGRAPHS_REQUEST:
      return {
        ...state,
        isPhotographListLoading: true,
      }
    case FETCH_PHOTOGRAPHS_SUCCESS:
      return {
        ...state,
        isPhotographListLoading: false,
        photographsList: action?.payload,
        photographsUploadError: null,
      }
    case FETCH_PHOTOGRAPHS_FAILED:
      return {
        ...state,
        isPhotographListLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        photographsList: [],
      }
    // Create photographs
    case CREATE_PHOTOGRAPHS_REQUESTED:
      return {
        ...state,
        isPhotographListCreateOrUpdating: true,
      }
    case CREATE_PHOTOGRAPHS_SUCCESS:
      return {
        ...state,
        isPhotographListCreateOrUpdating: false,
        photographsList: [...state.photographsList, ...action.payload],
        photographsUploadError: null,
      }
    case CREATE_PHOTOGRAPHS_FAILED:
      return {
        ...state,
        isPhotographListCreateOrUpdating: false,
        photographsUploadError: action.payload,
      }
    // Update photographs
    case UPDATE_PHOTOGRAPHS_REQUESTED:
      return {
        ...state,
        isPhotographListCreateOrUpdating: true,
      }
    case UPDATE_PHOTOGRAPHS_SUCCESS:
      return {
        ...state,
        isPhotographListCreateOrUpdating: false,
        photographsUploadError: null,
      }
    case UPDATE_PHOTOGRAPHS_FAILED:
      return {
        ...state,
        isPhotographListCreateOrUpdating: false,
        photographsUploadError: action.payload,
      }
    // Delete photographs
    case DELETE_PHOTOGRAPHS_REQUESTED:
      return {
        ...state,
        isPhotographDeleting: true,
      }
    case DELETE_PHOTOGRAPHS_SUCCESS:
      return {
        ...state,
        photographsList: action?.payload,
        isPhotographDeleting: false,
      }
    case DELETE_PHOTOGRAPHS_FAILED:
      return {
        ...state,
        isPhotographDeleting: false,
      }
    case CLEAR_PHOTOGRAPHS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
