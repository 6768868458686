import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'

import { useTableViewColStyles } from '../Styles/dktTableViewCol.style'
import { equal } from '../Utils/javascript'

const DKTTableViewCol = ({
  columns,
  options,
  components = {},
  onColumnUpdate,
  classes = useTableViewColStyles(),
}) => {
  const textLabels = options.textLabels.viewColumns
  const CheckboxComponent = components.Checkbox || Checkbox

  const handleColChange = (index) => {
    onColumnUpdate(index)
  }

  return (
    <FormControl
      component="fieldset"
      className={classes.root}
      aria-label={textLabels.titleAria}
    >
      <Typography variant="caption" className={classes.title}>
        {textLabels.title}
      </Typography>
      <FormGroup className={classes.formGroup}>
        {columns.map(
          (column, index) =>
            !equal(column.display, 'excluded') &&
            !equal(column.viewColumns, false) && (
              <FormControlLabel
                key={index}
                classes={{
                  root: classes.formControl,
                  label: classes.label,
                }}
                control={
                  <CheckboxComponent
                    color="primary"
                    className={classes.checkbox}
                    classes={{
                      root: classes.checkboxRoot,
                      checked: classes.checked,
                    }}
                    onChange={() => handleColChange(index)}
                    checked={equal(column.display, 'true')}
                    value={column.name}
                  />
                }
                label={column.label}
              />
            ),
        )}
      </FormGroup>
    </FormControl>
  )
}

export default DKTTableViewCol
