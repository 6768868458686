import {
  SAVE_SUPPORT_FORM_FAILED,
  SAVE_SUPPORT_FORM_REQUESTED,
  SAVE_SUPPORT_FORM_SUCCESS,
} from '../constants/support'

const initialState = {
  isReportIssueLoading: false,
  ReportIssueSaveError: null,
}

export const supportReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SAVE_SUPPORT_FORM_REQUESTED:
      return {
        ...state,
        isReportIssueLoading: true,
      }
    case SAVE_SUPPORT_FORM_SUCCESS:
      return {
        ...state,
        isReportIssueLoading: false,
      }
    case SAVE_SUPPORT_FORM_FAILED:
      return {
        ...state,
        isReportIssueLoading: false,
        ReportIssueSaveError: action.payload,
      }
    default:
      return state
  }
}
