import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getProjectChoices } from '../../Redux/actions/choices'
import { equal } from '../../Utils/javascript'
import ClientAccessDashboard from './ClientAccessDashboard'
import ConsultantDashboard from './ConsultantDashboard'
import { sortByIssueOptions } from '../../Description/dashboard.description'
import { clientPortal } from '../../Utils/constant'
import { addAndRemoveFavorite } from '../../Redux/actions/globalDashboard'

const Dashboard = () => {
  const { portalType } = useSelector(({ auth }) => auth)
  const [dynamicOptions, setDynamicOptions] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { choices } = useSelector(({ projectChoices }) => projectChoices)

  useEffect(() => {
    if (
      !choices.constructionType &&
      !choices.projectType &&
      !choices.projectStatus
    ) {
      dispatch(
        getProjectChoices({
          fieldName: 'constructionType,projectType,projectStatus',
        }),
      )
    }
  }, [
    choices.constructionType?.length,
    choices.projectType?.length,
    choices.projectStatus?.length,
  ])

  useEffect(() => {
    const { projectType, constructionType, projectStatus } = choices
    const optionForAll = { label: 'All', value: 'all' }

    setDynamicOptions({
      ...dynamicOptions,
      projectType: [optionForAll, ...(projectType || [])],
      constructionType: [optionForAll, ...(constructionType || [])],
      projectStatus: [optionForAll, ...(projectStatus || [])],
      sortByIssue: sortByIssueOptions,
    })
  }, [choices])

  const handleRedirect = (id) => {
    navigate(`/projects/${id}/projectDashboard`)
  }

  const handleFavorite = (e, projectId, status, projects, setProjects) => {
    const data = {
      projectId,
      action: status ? 'remove' : 'add',
    }
    dispatch(addAndRemoveFavorite(data))

    const updatedProjects = [...projects]

    const indexToUpdate = updatedProjects.findIndex((project) =>
      equal(project.id, projectId),
    )

    if (!equal(indexToUpdate, -1)) {
      updatedProjects[indexToUpdate] = {
        ...updatedProjects[indexToUpdate],
        isFavorite: !status,
      }
      setProjects(updatedProjects)
    }

    e.stopPropagation()
  }

  return equal(portalType, clientPortal) ? (
    <ClientAccessDashboard
      dynamicOptions={dynamicOptions}
      setDynamicOptions={setDynamicOptions}
      handleRedirect={handleRedirect}
      handleFavorite={handleFavorite}
    />
  ) : (
    <ConsultantDashboard
      dynamicOptions={dynamicOptions}
      handleRedirect={handleRedirect}
      handleFavorite={handleFavorite}
    />
  )
}

export default Dashboard
