import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_ATTACHMENTS,
  FETCH_PDF_LIST_FAILED,
  FETCH_PDF_LIST_REQUESTED,
  FETCH_PDF_LIST_SUCCESS,
  FETCH_PROJECT_LIST_SUCCESS,
  FETCH_PROJECT_PDF_LIST_FAILED,
  FETCH_PROJECT_PDF_LIST_REQUESTED,
  SET_UPLOAD_PROGRESS,
  UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_FAILED,
  UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_REQUESTED,
  UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_SUCCESS,
  UPDATE_REPORT_ATTACHMENTS_PDF_LIST_FAILED,
  UPDATE_REPORT_ATTACHMENTS_PDF_LIST_REQUESTED,
  UPDATE_REPORT_ATTACHMENTS_PDF_LIST_SUCCESS,
  UPLOAD_PDF_FAILED,
  UPLOAD_PDF_REQUESTED,
  UPLOAD_PDF_SUCCESS,
} from '../constants/attachments'

const initialState = {
  isFetchPdfListLoading: true,
  reportPdfList: [],
  reportPdfListError: {},
  isUpdatePdfListLoading: false,
  isPdfUploadLoading: false,
  // project
  projectPdfList: [],
  projectPdfListError: {},
  uploadProgress: 0,
}

export const attachments = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PDF_LIST_REQUESTED:
      return {
        ...state,
        isFetchPdfListLoading: true,
      }
    case FETCH_PDF_LIST_SUCCESS:
      return {
        ...state,
        isFetchPdfListLoading: false,
        reportPdfList: action.payload,
      }
    case FETCH_PDF_LIST_FAILED:
      return {
        ...state,
        isFetchPdfListLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        reportPdfListError: action.payload?.data,
      }
    // upload
    case UPLOAD_PDF_REQUESTED:
      return {
        ...state,
        isPdfUploadLoading: true,
        uploadProgress: 0,
      }
    case UPLOAD_PDF_SUCCESS:
      return {
        ...state,
        isPdfUploadLoading: false,
        uploadProgress: 0,
      }
    case UPLOAD_PDF_FAILED:
      return {
        ...state,
        isPdfUploadLoading: false,
        uploadProgress: 0,
        reportPdfListError: action.payload,
      }
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: (action.payload.loaded / action.payload.total) * 100,
      }
    // update
    case UPDATE_REPORT_ATTACHMENTS_PDF_LIST_REQUESTED:
      return {
        ...state,
        isUpdatePdfListLoading: true,
      }
    case UPDATE_REPORT_ATTACHMENTS_PDF_LIST_SUCCESS:
      return {
        ...state,
        isUpdatePdfListLoading: false,
        reportPdfList: action.payload,
      }
    case UPDATE_REPORT_ATTACHMENTS_PDF_LIST_FAILED:
      return {
        ...state,
        isUpdatePdfListLoading: false,
        reportPdfListError: action.payload,
      }
    // project attachments section
    case FETCH_PROJECT_PDF_LIST_REQUESTED:
      return {
        ...state,
        isFetchPdfListLoading: true,
      }
    case FETCH_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        isFetchPdfListLoading: false,
        projectPdfList: action.payload,
      }
    case FETCH_PROJECT_PDF_LIST_FAILED:
      return {
        ...state,
        isFetchPdfListLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        projectPdfListError: action.payload?.data,
      }
    // update
    case UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_REQUESTED:
      return {
        ...state,
        isUpdatePdfListLoading: true,
      }
    case UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_SUCCESS:
      return {
        ...state,
        isUpdatePdfListLoading: false,
        projectPdfList: action.payload,
      }
    case UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_FAILED:
      return {
        ...state,
        isUpdatePdfListLoading: false,
        projectPdfListError: action.payload,
      }
    case CLEAR_ATTACHMENTS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
