import { Box, Grid, IconButton, Typography, Stack } from '@mui/material'
import React, { memo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Delete } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { useParams } from 'react-router-dom'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  columns,
  initialBuildingAndUnitInfo,
  options,
  valuesToUpdate,
} from '../../../Description/siteAndBuilding.description'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTInput from '../../../Shared/DKTInput'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import {
  checkIncludes,
  equal,
  isArray,
  length,
  ternary,
} from '../../../Utils/javascript'
import { useStyles } from '../../../Styles/siteAndBuilding.style'
import { removeBuildingUnitInformation } from '../../../Redux/actions/siteAndBuilding'
import DKTCurrencyTextField from '../../../Shared/DKTCurrencyTextField'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTForm from '../../../Shared/DKTForm'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'

const add = (...numbers) => {
  if (!isArray(numbers)) return 0
  return numbers.reduce((total, num) => +num + +total, 0)
}
const getTotalNumberOfUnits = (unitInfo) => {
  const bedrooms = [0, 1, 2, 3, 4, 5]
  return add(...bedrooms.map((bedroom) => unitInfo[`br${bedroom}`] || 0))
}
const dialogStyle = makeStyles(() => ({
  buildingDialog: {
    '& .MuiPaper-root': {
      width: 'calc(100% - 128px)',
      '& .MuiPaper-root': {
        padding: 0,
        width: '100%',
        '& div .MuiTable-root': {
          minWidth: 1370,
        },
      },
    },
  },
  buildingInformationTable: {
    '& td': {
      padding: '8px 10px',
    },
    '& .MuiFormControl-root': {
      '& .MuiInputBase-root, .MuiFormHelperText-root': {
        marginTop: 0,
      },
    },
  },
}))

const CustomBody = memo(
  ({
    isLastRow,
    isRowRequired,
    columnName,
    value,
    rowIndex,
    removeRow,
    handleBuildingAndUnitInfoChange,
    hasOnlyViewPermission,
  }) => {
    const classes = useStyles()
    const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
      useState(false)
    const handleRemoveRow = () => {
      removeRow(rowIndex)()
      setIsDeleteNotificationOpen(false)
    }

    const deleteNotificationAction = (
      <>
        <DKTButton
          variant="contained"
          disableElevation
          onClick={() => setIsDeleteNotificationOpen(false)}
        >
          No
        </DKTButton>
        <DKTButton variant="outlined" onClick={handleRemoveRow}>
          Yes
        </DKTButton>
      </>
    )
    return ternary(
      isLastRow,
      isRowRequired &&
        ternary(
          equal(columnName, 'netSf') || equal(columnName, 'grossSf'),
          <DKTCurrencyTextField
            name={columnName}
            value={value}
            disabled={hasOnlyViewPermission || isLastRow}
            currencySymbol=""
            minimumValue="0"
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(event, value) =>
              handleBuildingAndUnitInfoChange(rowIndex)({
                target: { name: columnName, value },
              })
            }
            decimalPlaces="0"
            isLimitLess
          />,
          <DKTInput
            name={columnName}
            value={value}
            onChange={handleBuildingAndUnitInfoChange(rowIndex)}
            placeholder="Total"
            disabled={hasOnlyViewPermission || isLastRow}
          />,
        ),
      ternary(
        !hasOnlyViewPermission && equal(columnName, 'addRow'),
        <>
          <IconButton
            color="error"
            onClick={() => setIsDeleteNotificationOpen(true)}
          >
            <Delete />
          </IconButton>
          <DKTDialog
            open={isDeleteNotificationOpen}
            handleClose={() => setIsDeleteNotificationOpen(false)}
            title="&nbsp;"
            actions={deleteNotificationAction}
            maxWidth="xs"
          >
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.deleteNotificationContent}>
                  <Typography variant="h5">
                    Are you sure you want to delete?
                  </Typography>
                  <Typography variant="body1">
                    You can’t undo this action
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DKTDialog>
        </>,
        ternary(
          equal(columnName, 'netSf') || equal(columnName, 'grossSf'),
          <DKTCurrencyTextField
            name={columnName}
            value={value}
            disabled={hasOnlyViewPermission || equal(columnName, 'totalUnits')}
            currencySymbol=""
            minimumValue="0"
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(event, value) =>
              handleBuildingAndUnitInfoChange(rowIndex)({
                target: { name: columnName, value },
              })
            }
            decimalPlaces="0"
            isLimitLess
          />,
          ternary(
            equal(columnName, 'buildingNameOrNumber'),
            <DKTInput
              name={columnName}
              value={value}
              onChange={handleBuildingAndUnitInfoChange(rowIndex)}
              disabled={
                hasOnlyViewPermission || equal(columnName, 'totalUnits')
              }
            />,
            <DKTCurrencyTextField
              name={columnName}
              value={value}
              disabled={
                hasOnlyViewPermission || equal(columnName, 'totalUnits')
              }
              minimumValue="0"
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value) =>
                handleBuildingAndUnitInfoChange(rowIndex)({
                  target: { name: columnName, value },
                })
              }
              decimalPlaces="0"
              isLimitLess
            />,
          ),
        ),
      ),
    )
  },
)

const BuildingInformation = ({
  addBuildingAndUnitInfoRow,
  saveBuildingAndUnitInfo,
  isOpen,
  onClose,
  buildingAndUnitInfo,
  hasOnlyViewPermission,
  isSavingBuildingAndUnits,
  buildingInfoTotal,
  setBuildingInfoTotal,
  setBuildingAndUnitInfo,
}) => {
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const dispatch = useDispatch()
  const { slug: projectId } = useParams()
  const classes = dialogStyle()
  const { isFetchingBuildingUnitInfo } = useSelector(
    ({ siteAndBuilding }) => siteAndBuilding,
  )

  const handleBuildingAndUnitInfoChange = useCallback(
    (rowIndex) => (e) => {
      setIsUnSavedChanges(true)
      const changeBuildingInfoTotal = (unitInfo) => {
        const updatedTotal = unitInfo.reduce(
          (total, unit) => ({
            ...total,
            ...Object.fromEntries(
              valuesToUpdate?.map((value) => [
                value,
                add(+total[value] || 0, +unit[value] || 0),
              ]),
            ),
          }),
          {},
        )
        setBuildingInfoTotal(updatedTotal)
      }
      const { name, value } = e.target
      setBuildingAndUnitInfo((buildingAndUnitInfo) => {
        const clonedBuildingAndUnitInfo = [...buildingAndUnitInfo]
        clonedBuildingAndUnitInfo[rowIndex][name] = ternary(
          equal(name, 'buildingNameOrNumber') || equal(name, 'numberOfStories'),
          value,
          value || 0,
        )
        clonedBuildingAndUnitInfo[rowIndex].totalUnits = getTotalNumberOfUnits(
          clonedBuildingAndUnitInfo[rowIndex],
        )
        changeBuildingInfoTotal(clonedBuildingAndUnitInfo)
        return clonedBuildingAndUnitInfo
      })
    },
    [],
  )

  const removeRow = useCallback(
    (rowIndex) => () => {
      setBuildingAndUnitInfo((buildingAndUnitInfo) => {
        dispatch(
          removeBuildingUnitInformation(
            buildingAndUnitInfo[rowIndex]?.id,
            projectId,
          ),
        )
        const result = buildingAndUnitInfo.filter(
          (building, index) => !equal(index, rowIndex),
        )
        const changeBuildingInfoTotal = (unitInfo) => {
          const updatedTotal = unitInfo.reduce(
            (total, unit) => ({
              ...total,
              ...Object.fromEntries(
                valuesToUpdate?.map((value) => [
                  value,
                  add(+total[value] || 0, +unit[value] || 0),
                ]),
              ),
            }),
            {},
          )
          setBuildingInfoTotal(
            ternary(unitInfo?.length, updatedTotal, initialBuildingAndUnitInfo),
          )
        }
        changeBuildingInfoTotal(result)
        return result
      })
    },
    [],
  )

  const customBodyRender = (value, tableMeta) => {
    const { rowIndex, columnData, tableData } = tableMeta
    const isLastRow = equal(rowIndex, length(tableData) - 1)
    const isRowRequired = !checkIncludes(columnData.name, [
      'buildingNameOrNumber',
      'numberOfStories',
      'addRow',
    ])
    return (
      <DKTForm autoComplete="off">
        <CustomBody
          {...{
            isLastRow,
            isRowRequired,
            columnName: columnData.name,
            value,
            rowIndex,
            removeRow,
            handleBuildingAndUnitInfoChange,
            hasOnlyViewPermission,
          }}
        />
      </DKTForm>
    )
  }

  const columnsWithCustomBodyRender = columns.map((column) => ({
    ...column,
    options: {
      ...column.options,
      customBodyRender,
      ...ternary(
        column.name === 'addRow' && hasOnlyViewPermission,
        { display: 'excluded' },
        { display: column.options?.display || true },
      ),
    },
  }))

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }
  const handleCloseAddBuilding = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }
  return (
    <DKTDialog
      showCloseButton
      handleClose={handleCloseAddBuilding}
      open={isOpen}
      title="Building &amp; Unit Information"
      maxWidth="xl"
      className={classes.buildingDialog}
    >
      {equal(isFetchingBuildingUnitInfo, true) ? (
        <Stack alignItems="center" justifyContent="center">
          <DKTCircularProgress />
        </Stack>
      ) : equal(isFetchingBuildingUnitInfo, 'FAILED') ? (
        <Stack alignItems="center" justifyContent="center">
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Building &amp; Unit
            Information data. Please try contacting the admin or refreshing this
            page.
          </Typography>
        </Stack>
      ) : (
        <>
          <DKTMuiDataTable
            columns={columnsWithCustomBodyRender}
            data={[...buildingAndUnitInfo, buildingInfoTotal]}
            options={options}
            className={classes.buildingInformationTable}
          />
          <DKTButton
            variant="text"
            startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
            onClick={addBuildingAndUnitInfoRow}
            disabled={hasOnlyViewPermission}
          >
            Add row
          </DKTButton>
          <Box display="flex" justifyContent="flex-end">
            <DKTButton
              onClick={() => {
                saveBuildingAndUnitInfo()
                setIsUnSavedChanges(false)
              }}
              disabled={hasOnlyViewPermission || isSavingBuildingAndUnits}
            >
              {ternary(isSavingBuildingAndUnits, 'Saving...', 'Save')}
            </DKTButton>
          </Box>
        </>
      )}
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnSavedWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={saveBuildingAndUnitInfo}
      />
      <DKTReactRouterPrompt isDirty={isUnSavedChanges} />
    </DKTDialog>
  )
}

export default memo(BuildingInformation)
