// Fetch construction schedule
export const FETCH_CONSTRUCTION_SCHEDULE_REQUESTED =
  'FETCH_CONSTRUCTION_SCHEDULE_REQUESTED'
export const FETCH_CONSTRUCTION_SCHEDULE_SUCCESS =
  'FETCH_CONSTRUCTION_SCHEDULE_SUCCESS'
export const FETCH_CONSTRUCTION_SCHEDULE_FAILED =
  'FETCH_CONSTRUCTION_SCHEDULE_FAILED'

// Create and update construction schedule
export const CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_REQUESTED =
  'CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_REQUESTED'
export const CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_SUCCESS =
  'CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_SUCCESS'
export const CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_FAILED =
  'CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_FAILED'

// Owner contractor mapping
export const FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED =
  'FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED'
export const FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS =
  'FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS'
export const FETCH_OWNER_CONTRACTOR_MAPPING_FAILED =
  'FETCH_OWNER_CONTRACTOR_MAPPING_FAILED'

// Add new construction schedule
export const ADD_CONSTRUCTION_SCHEDULE = 'ADD_CONSTRUCTION_SCHEDULE'

// Delete schedule
export const DELETE_CONSTRUCTION_SCHEDULE_REQUESTED =
  'DELETE_CONSTRUCTION_SCHEDULE_REQUESTED'
export const DELETE_CONSTRUCTION_SCHEDULE_SUCCESS =
  'DELETE_CONSTRUCTION_SCHEDULE_SUCCESS'
export const DELETE_CONSTRUCTION_SCHEDULE_FAILED =
  'DELETE_CONSTRUCTION_SCHEDULE_FAILED'

// clear construction schedule
export const CLEAR_CONSTRUCTION_SCHEDULE = 'CLEAR_CONSTRUCTION_SCHEDULE'
