import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  autoCreateCheckBox: {
    '&.MuiFormControl-root': {
      alignItems: 'center',
      flexDirection: 'row',
    },
    '& .MuiCheckbox-root': {
      paddingLeft: 0,
    },
    '& .MuiInputLabel-root': {
      color: '#3E3E3E',
      position: 'inherit',
      fontSize: 13,
      transform: 'translate(0)',
    },
  },
}))
