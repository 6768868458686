import { Stack, Typography } from '@mui/material'
import React, { memo } from 'react'

const ActionsBar = ({
  actionTitle,
  children,
  textTransform = 'capitalize',
}) => (
  <Stack
    direction="row"
    height="50px"
    width="100%"
    backgroundColor="gray.main"
    px={3}
    boxSizing="border-box"
    alignItems="center"
    justifyContent="space-between"
    position="fixed"
    top={114}
    left={0}
    right={0}
    zIndex={999}
  >
    <Typography
      variant="subtitle2"
      color="gray.medium"
      fontWeight={400}
      textTransform={textTransform}
    >
      {actionTitle}
    </Typography>
    {children}
  </Stack>
)

export default memo(ActionsBar)
