export const FETCH_PROJECT_ASSIGNMENTS_REQUESTED =
  'FETCH_PROJECT_ASSIGNMENTS_REQUESTED'
export const FETCH_PROJECT_ASSIGNMENTS_SUCCESS =
  'FETCH_PROJECT_ASSIGNMENTS_SUCCESS'
export const FETCH_PROJECT_ASSIGNMENTS_FAILED =
  'FETCH_PROJECT_ASSIGNMENTS_FAILED'

export const SAVE_PROJECT_ASSIGNMENTS_REQUESTED =
  'SAVE_PROJECT_ASSIGNMENTS_REQUESTED'
export const SAVE_PROJECT_ASSIGNMENTS_SUCCESS =
  'SAVE_PROJECT_ASSIGNMENTS_SUCCESS'
export const SAVE_PROJECT_ASSIGNMENTS_FAILED = 'SAVE_PROJECT_ASSIGNMENTS_FAILED'

export const UPDATE_PROJECT_PERMISSIONS = 'UPDATE_PROJECT_PERMISSIONS'

export const DELETE_PROJECT_ASSIGNMENTS_REQUESTED =
  'DELETE_PROJECT_ASSIGNMENTS_REQUESTED'
export const DELETE_PROJECT_ASSIGNMENTS_SUCCESS =
  'DELETE_PROJECT_ASSIGNMENTS_SUCCESS'
export const DELETE_PROJECT_ASSIGNMENTS_FAILED =
  'DELETE_PROJECT_ASSIGNMENTS_FAILED'
