import React, { useState } from 'react'
import OTPInput from 'react-otp-input'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Typography, Container, Stack } from '@mui/material'
import { login, resendOtp } from '../../Redux/actions/auth'
import DKTButton from '../../Shared/DKTButton'
import { loadStateFn } from '../../Utils/localStorage'
import { equal } from '../../Utils/javascript'
import { useStyles } from '../../Styles/twoFactorAuthentication.style'

const TwoFactorAuthentication = () => {
  const [otp, setOtp] = useState('')
  const dispatch = useDispatch()
  const classes = useStyles()
  const { isLoadingLogin } = useSelector((state) => state.auth)

  const values = JSON.parse(loadStateFn('loginInfo'))
  const { email, password, rememberUsername } = values

  const handleChange = (otp) => {
    setOtp(otp)
  }
  const handleVerify = () => {
    dispatch(login({ email, password, rememberUsername, otp }))
  }
  const handleResendOtp = (event) => {
    event.preventDefault()
    setOtp('')
    dispatch(resendOtp({ email }))
  }

  return (
    <Container>
      <Stack
        flexDirection="column"
        justifyContent="center"
        className={classes.otpBox}
      >
        <Box className={classes.otpBoxWrapper}>
          <Typography variant="h4">Verify Account</Typography>
          <Typography variant="body1">
            An OTP has been sent to your email
          </Typography>
          <Typography variant="h5">Enter your Code here</Typography>
          <OTPInput
            containerStyle={classes.otpContainer}
            onChange={handleChange}
            value={otp}
            inputStyle="inputStyle"
            numInputs={6}
            separator={<span></span>}
            renderInput={(props) => <input {...props} />}
          />

          <Typography
            variant="body1"
            component="span"
            className={classes.resendLink}
          >
            Didn&rsquo;t receive the code?{' '}
            <Link to="/" onClick={handleResendOtp}>
              Resend
            </Link>
          </Typography>
          <DKTButton
            onClick={handleVerify}
            disabled={!equal(otp?.length, 6) || isLoadingLogin}
          >
            Verify
          </DKTButton>
        </Box>
      </Stack>
    </Container>
  )
}

export default TwoFactorAuthentication
