import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  tableSize: {
    '& td': {
      '&:last-child': {
        width: '50%',
      },
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  notificationContent: {
    textAlign: 'center',
    position: 'relative',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
      textAlign: 'left',
      position: 'absolute',
      top: '-46px',
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  addressDialog: {
    cursor: 'pointer',
  },
  projectDesc: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 305,
    height: 305,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '210px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '210px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },
  overflowTable: {
    overflow: 'auto !important',
    height: 'calc(100vh - 478px) !important',
    '& thead': {
      position: 'sticky',
      top: '-2px',
      zIndex: 888,
      '& tr': {
        '& th': {
          '& .MuiButtonBase-root': {
            padding: 0,
          },
        },
      },
    },
    '& tbody': {
      '& td': {
        '&:nth-child(2)': {
          padding: '0 16px !important',
          '& .MuiButtonBase-root': {
            padding: 0,
          },
        },
      },
    },
  },
  commonTable: {
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          '&:nth-child(3)': {
            position: 'sticky',
            left: 0,
            zIndex: 2,
            minWidth: '267px',
          },
          backgroundColor: '#E5E5E5',
        },
      },
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      '&:nth-child(3)': {
        position: 'sticky',
        left: 0,
        zIndex: 2,
        background: '#fff',
      },
      borderBottom: '1px solid #E5E5E5 !important',
      padding: 0,
      verticalAlign: 'middle',
    },
  },
  stateDropdown: {
    marginTop: '0 !important',
    height: '45px',
    '& .MuiSelect-select': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  summaryTypeInput: {
    '& .MuiInputBase-root': {
      marginTop: '0 !important',
      paddingLeft: '10px !important',
      border: 'none',
    },
  },
  freeForm: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 250,
    height: 350,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '150px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '150px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },
  input: {
    '& .MuiInputBase-root': {
      color: '#1564FF',
      marginTop: '5px !important',
      border: 'none',
      '& .MuiInputBase-input': {
        paddingLeft: '0 !important',
      },
    },
  },
  addDesc: {
    textTransform: 'initial',
    paddingLeft: '49px',
    marginTop: '16px',
  },
  dragIcon: {
    cursor: 'move',
    maxWidth: 'max-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 auto',
  },
  modalManPower: {
    '& .MuiPaper-root': {
      boxSizing: 'border-box',
      '& .MuiDialogContent-root': {
        '& .MuiPaper-root': {
          padding: 0,
        },
        '& tbody': {
          '& tr': {
            '& td': {
              padding: 0,
              '& .MuiInputBase-root': {
                padding: 8,
                height: 'auto',
              },
              '& .MuiFormHelperText-root': {
                marginTop: 0,
              },
            },
          },
        },
      },
    },
  },
}))
