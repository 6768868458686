export const actionTitles = {
  projects: 'Create and Manage New Projects',
}

export const options = {
  responsive: 'standard',
  filter: false,
  downloadOptions: {
    filename: 'Projects.csv',
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
}

export const columns = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'isFavorite',
    label: ' ',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'projectName',
    label: 'Project Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'city',
    label: 'City',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'state',
    label: 'State',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'projectType',
    label: 'Project Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'constructionType',
    label: 'Construction Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'complete',
    label: '% Complete',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'reportCompletionDate',
    label: 'Completion Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'scheduleDelay',
    label: 'Days Behind/(Ahead)',
    options: {
      filter: true,
      sort: true,
    },
  },
]

export const projectMenu = [
  {
    menu: 'Dashboard',
    url: 'projectDashboard',
    isNewProject: true,
    id: 'projectDashboard',
  },
  {
    menu: 'Project Info',
    url: 'summary',
    id: [
      'summary',
      'site-building',
      'environmental',
      'owner-contractor',
      'owner-architect',
      'budget-contingency',
      'construction-schedule',
      'project-directory',
      'project-attachment',
    ],
  },
  {
    menu: 'Reports',
    url: 'project-reports/',
    id: 'project-reports',
    isNewProject: true,
  },
  {
    menu: 'Photos',
    url: 'project-photos',
    isNewProject: true,
    id: 'project-photos',
  },
  {
    menu: 'Settings',
    url: 'project-setting/',
    isNewProject: true,
    id: 'project-setting',
  },
]
