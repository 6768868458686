import { equal, ternary } from '../../Utils/javascript'
import {
  FETCH_ENVIRONMENTAL_LIST_FAILED,
  FETCH_ENVIRONMENTAL_MAPPING_FAILED,
  FETCH_ENVIRONMENTAL_MAPPING_REQUESTED,
  FETCH_ENVIRONMENTAL_MAPPING_SUCCESS,
  FETCH_ENVIRONMENTAL_LIST_REQUESTED,
  FETCH_ENVIRONMENTAL_LIST_SUCCESS,
  SAVE_ENVIRONMENTAL_FAILED,
  SAVE_ENVIRONMENTAL_REQUESTED,
  SAVE_ENVIRONMENTAL_SUCCESS,
  FETCH_ENVIRONMENTAL_REQUESTED,
  FETCH_ENVIRONMENTAL_SUCCESS,
  FETCH_ENVIRONMENTAL_FAILED,
  CREATE_ENVIRONMENTAL_REQUESTED,
  CREATE_ENVIRONMENTAL_SUCCESS,
  CREATE_ENVIRONMENTAL_FAILED,
  ADD_ENVIRONMENTAL_SUCCESS,
  SET_ENVIRONMENTAL_ID,
  DELETE_ENVIRONMENTAL_REQUESTED,
  DELETE_ENVIRONMENTAL_SUCCESS,
  DELETE_ENVIRONMENTAL_FAILED,
  CLEAR_ENVIRONMENTAL,
} from '../constants/environmental'

const initialState = {
  isLoadingMapping: false,
  isLoadingEnvironmentalList: true,
  isLoadingEnvironmental: false,
  isSavingEnvironmental: false,
  isCreatingEnvironmental: false,
  fetchMappingError: null,
  fetchEnvironmentalListError: null,
  fetchEnvironmentalError: null,
  createEnvironmentalError: null,
  saveEnvironmentalError: null,
  mappings: null,
  environmentals: null,
  environmental: null,
  environmentalId: null,
  isNewEnvironmental: false,
  isDeletingEnvironmental: false,
}

export const environmental = (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_ENVIRONMENTAL_REQUESTED:
      return {
        ...state,
        isCreatingEnvironmental: true,
      }
    case CREATE_ENVIRONMENTAL_SUCCESS:
      return {
        ...state,
        isCreatingEnvironmental: false,
        environmentals: action.payload.environmentals,
        environmental: action.payload.environmental,
      }
    case CREATE_ENVIRONMENTAL_FAILED:
      return {
        ...state,
        isCreatingEnvironmental: false,
        createEnvironmentalError: action.payload,
      }
    case FETCH_ENVIRONMENTAL_REQUESTED:
      return {
        ...state,
        isLoadingEnvironmental: true,
      }
    case FETCH_ENVIRONMENTAL_SUCCESS:
      return {
        ...state,
        isLoadingEnvironmental: false,
        environmental: action.payload,
      }
    case FETCH_ENVIRONMENTAL_FAILED:
      return {
        ...state,
        isLoadingEnvironmental: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchEnvironmentalError: action.payload?.data,
      }
    case FETCH_ENVIRONMENTAL_LIST_REQUESTED:
      return {
        ...state,
        isLoadingEnvironmentalList: true,
      }
    case FETCH_ENVIRONMENTAL_LIST_SUCCESS:
      return {
        ...state,
        isLoadingEnvironmentalList: false,
        environmentals: action.payload,
        environmentalId: action?.payload[0]?.id,
      }
    case FETCH_ENVIRONMENTAL_LIST_FAILED:
      return {
        ...state,
        isLoadingEnvironmentalList: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchEnvironmentalListError: action.payload?.data,
      }
    case SAVE_ENVIRONMENTAL_REQUESTED:
      return {
        ...state,
        isSavingEnvironmental: true,
      }
    case SAVE_ENVIRONMENTAL_SUCCESS:
      return {
        ...state,
        isSavingEnvironmental: false,
        environmental: action.payload,
      }
    case SAVE_ENVIRONMENTAL_FAILED:
      return {
        ...state,
        isSavingEnvironmental: false,
        saveEnvironmentalError: action.payload,
      }
    case FETCH_ENVIRONMENTAL_MAPPING_REQUESTED:
      return {
        ...state,
        isLoadingMapping: true,
      }
    case FETCH_ENVIRONMENTAL_MAPPING_SUCCESS:
      return {
        ...state,
        isLoadingMapping: false,
        mappings: action.payload,
      }
    case FETCH_ENVIRONMENTAL_MAPPING_FAILED:
      return {
        ...state,
        isLoadingMapping: false,
        fetchMappingError: action.payload,
      }
    case ADD_ENVIRONMENTAL_SUCCESS:
      return {
        ...state,
        environmentals: action.payload,
      }
    case SET_ENVIRONMENTAL_ID:
      return {
        ...state,
        environmentalId: action.payload.environmentalId,
        isNewEnvironmental: action.payload.isNewEnvironmental,
      }

    // Delete contractor
    case DELETE_ENVIRONMENTAL_REQUESTED:
      return {
        ...state,
        isDeletingEnvironmental: true,
      }
    case DELETE_ENVIRONMENTAL_SUCCESS:
      return {
        ...state,
        isDeletingEnvironmental: false,
        environmentals: action.payload,
        environmentalId: action?.activeEnvironmental,
        isNewEnvironmental:
          action.isNewEnvironmental || state.isNewEnvironmental,
      }
    case DELETE_ENVIRONMENTAL_FAILED:
      return {
        ...state,
        isDeletingEnvironmental: false,
      }
    case CLEAR_ENVIRONMENTAL:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
