import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'

import ActionsBar from './ActionsBar'
import Navbar from './Navbar'
import PageTitleBar from './PageTitleBar'
import Sidebar from './Sidebar'

export const OuterLayout = ({ children, title }) => (
  <Stack direction="column" minHeight="100vh">
    <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 999 }}>
      <Navbar />
      <PageTitleBar title={title} />
    </Box>
    {children}
  </Stack>
)

export const InnerLayout = ({
  actionTitle,
  contentTitle,
  actions: Actions = () => null,
  maxWidth = '100%',
  children,
  className,
}) => (
  <>
    <Box sx={{ position: 'fixed', top: 114, left: 0, right: 0, zIndex: 999 }}>
      <ActionsBar actionTitle={actionTitle}>
        <Actions />
      </ActionsBar>
    </Box>
    <Stack
      direction="row"
      flex={1}
      sx={{ maxHeight: '100%', overflow: 'hidden' }}
    >
      <Sidebar />
      <Box
        {...(maxWidth && { px: 5, py: 4 })}
        width="100%"
        sx={{
          overflowY: 'auto',
          marginLeft: '250px',
          marginTop: '179px',
        }}
      >
        <Container
          width="100%"
          maxWidth={maxWidth}
          sx={{ m: 0, p: 0, position: 'relative' }}
        >
          <Typography
            component="h6"
            fontSize={14}
            fontWeight={700}
            textAlign="center"
            textTransform="uppercase"
            mb={4}
            className={className}
          >
            {contentTitle}
          </Typography>
          {children}
        </Container>
      </Box>
    </Stack>
  </>
)
