// Fetch report list
export const FETCH_REPORT_LIST_REQUESTED = 'FETCH_REPORT_LIST_REQUESTED'
export const FETCH_REPORT_LIST_SUCCESS = 'FETCH_REPORT_LIST_SUCCESS'
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS'
export const FETCH_REPORT_LIST_FAILED = 'FETCH_REPORT_LIST_FAILED'

// Create and update report
export const CREATE_AND_UPDATE_REPORT_REQUESTED =
  'CREATE_AND_UPDATE_REPORT_REQUESTED'
export const CREATE_AND_UPDATE_REPORT_SUCCESS =
  'CREATE_AND_UPDATE_REPORT_SUCCESS'
export const CREATE_AND_UPDATE_REPORT_FAILED = 'CREATE_AND_UPDATE_REPORT_FAILED'

// Delete reports
export const DELETE_REPORT_REQUESTED = 'DELETE_REPORT_REQUESTED'
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS'
export const DELETE_REPORT_FAILED = 'DELETE_REPORT_FAILED'

// Publish reports
export const PUBLIC_REPORT_REQUESTED = 'PUBLIC_REPORT_REQUESTED'
export const PUBLIC_REPORT_SUCCESS = 'PUBLIC_REPORT_SUCCESS'
export const PUBLIC_REPORT_FAILED = 'PUBLIC_REPORT_FAILED'

// Fetch performanceChart data
export const FETCH_PERFORMANCE_CHART_DATA_REQUESTED =
  'FETCH_PERFORMANCE_CHART_DATA_REQUESTED'
export const FETCH_PERFORMANCE_CHART_DATA_SUCCESS =
  'FETCH_PERFORMANCE_CHART_DATA_SUCCESS'
export const FETCH_PERFORMANCE_CHART_DATA_FAILED =
  'FETCH_PERFORMANCE_CHART_DATA_FAILED'

// Create and update ScheduleDuration
export const CREATE_AND_UPDATE_SCHEDULE_DURATION_REQUESTED =
  'CREATE_AND_UPDATE_SCHEDULE_DURATION_REQUESTED'
export const CREATE_AND_UPDATE_SCHEDULE_DURATION_SUCCESS =
  'CREATE_AND_UPDATE_SCHEDULE_DURATION_SUCCESS'
export const CREATE_AND_UPDATE_SCHEDULE_DURATION_FAILED =
  'CREATE_AND_UPDATE_SCHEDULE_DURATION_FAILED'

// Fetch draw progress data
export const FETCH_DRAW_PROGRESS_CHART_DATA_REQUESTED =
  'FETCH_DRAW_PROGRESS_CHART_DATA_REQUESTED'
export const FETCH_DRAW_PROGRESS_CHART_DATA_SUCCESS =
  'FETCH_DRAW_PROGRESS_CHART_DATA_SUCCESS'
export const FETCH_DRAW_PROGRESS_CHART_DATA_FAILED =
  'FETCH_DRAW_PROGRESS_CHART_DATA_FAILED'

// Fetch EconomicCompletion data
export const FETCH_ECONOMIC_DATA_REQUESTED = 'FETCH_ECONOMIC_DATA_REQUESTED'
export const FETCH_ECONOMIC_DATA_SUCCESS = 'FETCH_ECONOMIC_DATA_SUCCESS'
export const FETCH_ECONOMIC_DATA_FAILED = 'FETCH_ECONOMIC_DATA_FAILED'

// Create and update EconomicCompletion
export const CREATE_AND_UPDATE_ECONOMIC_COMPLETION_REQUESTED =
  'CREATE_AND_UPDATE_ECONOMIC_COMPLETION_REQUESTED'
export const CREATE_AND_UPDATE_ECONOMIC_COMPLETION_SUCCESS =
  'CREATE_AND_UPDATE_ECONOMIC_COMPLETION_SUCCESS'
export const CREATE_AND_UPDATE_ECONOMIC_COMPLETION_FAILED =
  'CREATE_AND_UPDATE_ECONOMIC_COMPLETION_FAILED'

// Clear chart values when component is unmount
export const CLEAR_PERFORMANCE_CHART_DATA = 'CLEAR_PERFORMANCE_CHART_DATA'
