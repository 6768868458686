import { equal, ternary } from '../../Utils/javascript'
import {
  ADD_OWNER_ARCHITECT,
  CLEAR_OWNER_ARCHITECT,
  CREATE_AND_UPDATE_OWNER_ARCHITECT_FAILED,
  CREATE_AND_UPDATE_OWNER_ARCHITECT_REQUESTED,
  CREATE_AND_UPDATE_OWNER_ARCHITECT_SUCCESS,
  DELETE_ARCHITECT_FAILED,
  DELETE_ARCHITECT_REQUESTED,
  DELETE_ARCHITECT_SUCCESS,
  FETCH_OWNER_ARCHITECT_FAILED,
  FETCH_OWNER_ARCHITECT_REQUESTED,
  FETCH_OWNER_ARCHITECT_SUCCESS,
  FETCH_OWNER_CONTRACTOR_MAPPING_FAILED,
  FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED,
  FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS,
  SEARCH_ARCHITECT_FAILED,
  SEARCH_ARCHITECT_REQUESTED,
  SEARCH_ARCHITECT_SUCCESS,
} from '../constants/ownerArchitect'

const initialState = {
  isCreateAndUpdateOwnerArchitectLoading: false,
  isOwnerArchitectListLoading: true,
  ownerArchitectList: [],
  ownerContractorMapping: [],
  isOwnerContractorMapping: false,
  activeArchitect: null,
  ownerArchitectError: null,
  isSearchingArchitect: true,
  searchResults: [],
  searchArchitectError: '',
  isDeletingArchitect: false,
}

export const ownerArchitectReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch owner architect
    case FETCH_OWNER_ARCHITECT_REQUESTED:
      return {
        ...state,
        isOwnerArchitectListLoading: true,
      }
    case FETCH_OWNER_ARCHITECT_SUCCESS:
      return {
        ...state,
        isOwnerArchitectListLoading: false,
        ownerArchitectList: action?.payload,
        ownerArchitectError: null,
      }
    case FETCH_OWNER_ARCHITECT_FAILED:
      return {
        ...state,
        isOwnerArchitectListLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        ownerArchitectList: [],
      }
    // Create and update owner architect
    case CREATE_AND_UPDATE_OWNER_ARCHITECT_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateOwnerArchitectLoading: true,
        activeArchitect: null,
      }
    case CREATE_AND_UPDATE_OWNER_ARCHITECT_SUCCESS:
      return {
        ...state,
        isCreateAndUpdateOwnerArchitectLoading: false,
        ownerArchitectList: action?.payload,
        activeArchitect: action?.activeArchitect,
        ownerArchitectError: null,
      }
    case CREATE_AND_UPDATE_OWNER_ARCHITECT_FAILED:
      return {
        ...state,
        isCreateAndUpdateOwnerArchitectLoading: false,
        ownerArchitectError: action.payload,
      }
    // Fetch owner architect
    case FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED:
      return {
        ...state,
        isOwnerContractorMapping: true,
      }
    case FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS:
      return {
        ...state,
        isOwnerContractorMapping: false,
        ownerContractorMapping: action?.payload,
      }
    case FETCH_OWNER_CONTRACTOR_MAPPING_FAILED:
      return {
        ...state,
        isOwnerContractorMapping: false,
      }
    // Add owner architect
    case ADD_OWNER_ARCHITECT:
      return {
        ...state,
        ownerArchitectList: action.payload,
      }
    // Search architect
    case SEARCH_ARCHITECT_REQUESTED:
      return {
        ...state,
        isSearchingArchitect: true,
      }
    case SEARCH_ARCHITECT_SUCCESS:
      return {
        ...state,
        isSearchingArchitect: false,
        searchResults: action.payload,
      }
    case SEARCH_ARCHITECT_FAILED:
      return {
        ...state,
        isSearchingArchitect: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        searchArchitectError: action.payload?.data,
      }
    // Delete architect
    case DELETE_ARCHITECT_REQUESTED:
      return {
        ...state,
        isDeletingArchitect: true,
      }
    case DELETE_ARCHITECT_SUCCESS:
      return {
        ...state,
        isDeletingArchitect: false,
        ownerArchitectList: action.payload,
        activeArchitect: action?.activeArchitect,
      }
    case DELETE_ARCHITECT_FAILED:
      return {
        ...state,
        isDeletingArchitect: false,
      }
    case CLEAR_OWNER_ARCHITECT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
