import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  updateNameConfirmation: {
    '& .MuiCheckbox-root ~ .MuiInputLabel-root': {
      color: 'red',
      maxWidth: '412px',
    },
    '& .MuiButtonBase-root.MuiButton-root': {
      marginTop: 15,
    },
    '& .MuiFormControl-root': {
      alignItems: 'start',
      marginTop: 10,
      '& .MuiCheckbox-root': {
        padding: '0 10px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    '& .MuiPaper-root ': {
      '& .MuiDialogContent-root': {
        paddingBottom: '0 !important',
      },
    },
  },
}))
