import { Delete } from '@mui/icons-material'
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
  Stack,
} from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  g703Columns,
  g703InitialTotal,
  g703Options,
  initialG703Tracker,
} from '../../../Description/trackers.description'
import {
  deleteG703Trackers,
  getG703Tracker,
  saveG703Tracker,
} from '../../../Redux/actions/reportTrackers'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTCurrencyTextField from '../../../Shared/DKTCurrencyTextField'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTInput from '../../../Shared/DKTInput'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { useStyles } from '../../../Styles/budgetSummary.style'
import { SA, publish } from '../../../Utils/constant'
import { equal, keys, length, lt, ternary } from '../../../Utils/javascript'
import DKTForm from '../../../Shared/DKTForm'

const CustomBody = memo(
  ({
    columnName,
    value,
    readOnly,
    rowIndex,
    handleChange,
    isLimitLess = false,
  }) => {
    const classes = useStyles()
    return equal(columnName, 'rowNumber') ? (
      value
    ) : equal(columnName, 'descriptionOfWork') || equal(columnName, 'da') ? (
      <DKTInput
        value={value}
        disabled={readOnly}
        onChange={(e) => handleChange(rowIndex, columnName, e.target.value)}
        className={classes.input}
        endAdornment={ternary(
          equal(columnName, 'da'),
          <InputAdornment position="end">%</InputAdornment>,
          null,
        )}
      />
    ) : (
      <DKTCurrencyTextField
        name={columnName}
        value={value}
        disabled={readOnly}
        currencySymbol="$"
        className={classes.input}
        outputFormat="string"
        decimalCharacter="."
        digitGroupSeparator=","
        onChange={(event, value) => handleChange(rowIndex, columnName, value)}
        isLimitLess={isLimitLess}
      />
    )
  },
)

const formatNumber = (number = 1) =>
  ternary(lt(number, 10), `0${number}`, number)

const getColumnTotal = (array, columnName) =>
  array.reduce((total, rowData) => total + +rowData[columnName] || 0, 0)

const G703 = ({ open, onClose, uniqueCode, ownerContractor }) => {
  const {
    g703Tracker,
    isSavingG703Tracker,
    isDeletingG703Trackers,
    isFetchingG703Tracker,
  } = useSelector(({ reportTrackers }) => reportTrackers)
  const { slug: projectId, reportId } = useParams()

  const [data, setData] = useState(
    g703Tracker?.G703Trackers || [
      {
        ...initialG703Tracker,
        uniqueId: new Date().valueOf(),
        report: reportId,
        ownerContractor,
        uniqueCode,
      },
    ],
  )
  const [dataTotal, setDataTotal] = useState(
    {
      ...g703Tracker?.total,
      da: g703Tracker?.total?.da ? +g703Tracker.total.da.toFixed(2) : 0,
    } || {
      ...g703InitialTotal,
    },
  )
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const recordsToRemove = useRef([])
  const setSelectedRows = useRef(() => {})
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)

  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+projectId]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission =
    isGlobalViewer ||
    isProjectViewer ||
    ternary(
      reportData?.isSecondLastReport,
      equal(reportData?.lastReportStatus, publish),
      !reportData?.isLastReport,
    )

  useEffect(() => {
    dispatch(
      getG703Tracker({
        reportId,
        projectId,
        ocId: ownerContractor,
      }),
    )
  }, [dispatch, uniqueCode, ownerContractor])
  useEffect(() => {
    setData(
      g703Tracker?.G703Trackers || [
        {
          ...initialG703Tracker,
          uniqueId: new Date().valueOf(),
          report: reportId,
          ownerContractor,
          uniqueCode,
        },
      ],
    )
    setDataTotal(
      {
        ...g703Tracker?.total,
        da: g703Tracker?.total?.da ? +g703Tracker.total.da.toFixed(2) : 0,
      } || {
        ...g703InitialTotal,
      },
    )
  }, [g703Tracker])

  const closeDeleteNotification = () => setIsDeleteNotificationOpen(false)
  const openDeleteNotification = () => setIsDeleteNotificationOpen(true)

  const handleChange = useCallback(
    (row, columnName, value) => {
      setIsUnSavedChanges(true)
      setData((prevData) => {
        const clonedData = [...prevData]
        let calculatedTotal = {}

        if (clonedData[row]) {
          clonedData[row][columnName] = value
          calculatedTotal = {
            ...calculatedTotal,
            [columnName]: getColumnTotal(clonedData, columnName) || 0,
          }
          // Calculate variance
          if (
            equal(columnName, 'thisPeriod') ||
            equal(columnName, 'recommended')
          ) {
            clonedData[row].variance =
              clonedData[row].thisPeriod - clonedData[row].recommended
            calculatedTotal = {
              ...calculatedTotal,
              variance: getColumnTotal(clonedData, 'variance'),
            }
          } else if (
            // Calculate D/A
            equal(columnName, 'completedStored') ||
            equal(columnName, 'current')
          ) {
            clonedData[row].da = (
              (+(clonedData[row].completedStored || 0) /
                (+clonedData[row].current || 0)) *
              100
            )?.toFixed(2)
          }
        }
        const daTotal = { ...dataTotal, ...calculatedTotal }
        setDataTotal((prevDataTotal) => ({
          ...prevDataTotal,
          ...calculatedTotal,
          // eslint-disable-next-line no-restricted-globals
          da: !isNaN(
            (
              ((+daTotal.completedStored || 0) / (+daTotal.current || 0)) *
              100
            )?.toFixed(2),
          )
            ? +(
                ((+daTotal.completedStored || 0) / (+daTotal.current || 0)) *
                100
              ).toFixed(2)
            : 0,
        }))

        return clonedData
      })
    },
    [dataTotal],
  )

  const addRow = () => {
    setData((prevData) => [
      ...prevData,
      {
        rowNumber: formatNumber(
          +(prevData[length(prevData) - 1] || { rowNumber: 0 }).rowNumber + 1,
        ),
        descriptionOfWork: '',
        original: 0,
        current: 0,
        thisPeriod: 0,
        recommended: 0,
        variance: 0,
        completedStored: 0,
        da: 0,
        report: reportId,
        ownerContractor,
        uniqueCode,
        uniqueId: new Date().valueOf(), // To delete local row
      },
    ])
  }

  const customBodyRender = (columnName, readOnly, value, tableData) => {
    const { rowIndex } = tableData

    return (
      <DKTForm autoComplete="off">
        <CustomBody
          columnName={columnName}
          value={value}
          readOnly={readOnly || hasOnlyViewPermission}
          rowIndex={rowIndex}
          handleChange={handleChange}
        />
      </DKTForm>
    )
  }
  const columns = g703Columns.map((column) => ({
    ...column,
    options: {
      ...column.options,
      customBodyRender: (...props) =>
        customBodyRender(
          column.name,
          equal(column.name, 'rowNumber') ||
            equal(column.name, 'variance') ||
            equal(column.name, 'da'),
          ...props,
        ),
    },
  }))

  const customTableBodyFooterRender = () =>
    ternary(
      data?.length,
      <TableFooter>
        <TableRow>
          {hasOnlyViewPermission ||
            (!equal(g703Options.selectableRows, 'none') && (
              <TableCell></TableCell>
            ))}
          {columns
            .filter(({ options: { display = true } }) => display)
            .map((column) => (
              <TableCell>
                {ternary(
                  equal(column.name, 'rowNumber') ||
                    equal(column.name, 'descriptionOfWork'),
                  null,
                  <CustomBody
                    columnName={column.name}
                    value={dataTotal[column.name]}
                    readOnly
                    handleChange={() => null}
                    isLimitLess
                  />,
                )}
              </TableCell>
            ))}
        </TableRow>
      </TableFooter>,
      null,
    )

  const customToolbarSelect = useCallback(
    (selectedRows, displayData, setSelectedTableRows) => {
      const selectedRecords = keys(selectedRows.lookup)
      const selectedRecordIds = selectedRecords.map(
        (rowToDelete) => data[rowToDelete]?.id ?? data[rowToDelete]?.uniqueId,
      )
      setSelectedRows.current = setSelectedTableRows
      const setRecordsToRemove = () => {
        recordsToRemove.current = selectedRecordIds
      }

      return (
        <Box>
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => {
              setRecordsToRemove()
              openDeleteNotification()
            }}
          >
            <Delete color="error" />
          </IconButton>
        </Box>
      )
    },
    // [companyPersonnelList],
  )

  const options = {
    ...g703Options,
    customTableBodyFooterRender,
    customToolbarSelect,
    rowsPerPage: 100,
    responsive: 'scrollMaxHeight',
    rowsPerPageOptions: [100, 200, 500],
    fixeHeader: true,
    selectableRows: ternary(
      hasOnlyViewPermission,
      'none',
      g703Options.selectableRows || 'multiple',
    ),
  }

  const handleSave = () => {
    setIsUnSavedChanges(false)
    dispatch(saveG703Tracker(data, reportId, ownerContractor, projectId))
  }

  const handleDelete = () => {
    dispatch(
      deleteG703Trackers({
        g703TrackersToDelete: recordsToRemove.current,
        reportId,
        ocId: ownerContractor,
        g703: data,
        uniqueCode,
        projectId,
      }),
    ).then(() => {
      setSelectedRows.current([])
      closeDeleteNotification()
    })
  }

  const deleteNotificationActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteNotification}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleDelete}
        disabled={isDeletingG703Trackers}
      >
        {ternary(
          isDeletingG703Trackers,
          <DKTCircularProgress color={theme.palette.gray.dark} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }

  const handleCloseG703 = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }

  return (
    <DKTDialog
      title="G703 Tracker"
      open={open}
      showCloseButton
      handleClose={handleCloseG703}
      maxWidth="xl"
      className={classes.budgetSummaryTrackerModal}
    >
      {equal(isFetchingG703Tracker, true) ? (
        <Stack alignItems="center" justifyContent="center">
          <DKTCircularProgress />
        </Stack>
      ) : equal(isFetchingG703Tracker, 'FAILED') ? (
        <Stack alignItems="center" justifyContent="center">
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching G703 Tracker data. Please
            try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Typography sx={{ marginBottom: '26px' }}>
            Enter Consultant’s opinion in Recommended column
          </Typography>
          <DKTMuiDataTable
            columns={columns}
            options={options}
            data={data || [{}]}
            className={classes.g703Table}
          />
          <DKTButton
            variant="text"
            startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
            onClick={addRow}
            disabled={hasOnlyViewPermission}
          >
            Add row
          </DKTButton>
          <Box textAlign="right">
            <DKTButton
              onClick={handleSave}
              disabled={hasOnlyViewPermission || isSavingG703Tracker}
            >
              {ternary(
                isSavingG703Tracker,
                <DKTCircularProgress color={theme.palette.gray.dark} />,
                'Save',
              )}
            </DKTButton>
          </Box>
        </>
      )}
      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={closeDeleteNotification}
        title="&nbsp;"
        actions={deleteNotificationActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      {!hasOnlyViewPermission && (
        <>
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={handleSave}
          />
          <DKTReactRouterPrompt
            isDirty={isUnSavedChanges}
            onSave={handleSave}
          />
        </>
      )}
    </DKTDialog>
  )
}

export default memo(G703)
