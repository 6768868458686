import { ListItem, ListItemButton, ListItemText } from '@mui/material'
import { useTheme } from '@mui/styles'
import React from 'react'
import { NavLink, matchPath, useLocation, useParams } from 'react-router-dom'
import { checkUndefined, isArray, ternary } from '../Utils/javascript'
import DKTTooltip from '../Shared/DKTTooltip'

const ProjectMenuLink = ({ data, getMatchingPath }) => {
  const theme = useTheme()
  const { slug } = useParams()
  const matchMultiplePaths = (ids) =>
    ids.map((id) => matchPath(getMatchingPath(id), location.pathname))
  const location = useLocation()
  const match = isArray(data?.id)
    ? matchMultiplePaths(data?.id).some((path) => !!path)
    : matchPath(getMatchingPath(data?.id), location.pathname)

  return (
    <ListItem disablePadding>
      {data?.isInProgress ? (
        <DKTTooltip title="In Progress" placement="bottom">
          <ListItemButton
            component={NavLink}
            onClick={(event) =>
              data.isInProgress ? event.preventDefault() : {}
            }
            disabled={
              // eslint-disable-next-line no-restricted-globals
              (isNaN(slug) && data?.isNewProject) ||
              checkUndefined(slug) ||
              data.isInProgress
            }
            to={`/projects/${slug}/${data?.url}`}
            style={
              data.isInProgress
                ? {
                    display: 'block',
                    width: '100%',
                    paddingRight: '16px',
                    textDecoration: 'none',
                    color: 'grey',
                    cursor: 'pointer',
                    pointerEvents: 'unset',
                  }
                : ternary(
                    match,
                    { color: theme.palette.primary.main },
                    ternary(
                      // eslint-disable-next-line no-restricted-globals
                      isNaN(slug) && data?.isNewProject,
                      { pointerEvents: 'none', color: 'gray' },
                      {},
                    ),
                  )
            }
          >
            <ListItemText primary={data.menu} />
          </ListItemButton>
        </DKTTooltip>
      ) : (
        <ListItemButton
          component={NavLink}
          // eslint-disable-next-line no-restricted-globals
          disabled={(isNaN(slug) && data?.isNewProject) || checkUndefined(slug)}
          to={`/projects/${slug}/${data?.url}`}
          style={ternary(
            match,
            { color: theme.palette.primary.main },
            ternary(
              // eslint-disable-next-line no-restricted-globals
              isNaN(slug) && data?.isNewProject,
              { pointerEvents: 'none', color: 'gray' },
              {},
            ),
          )}
        >
          <ListItemText primary={data.menu} />
        </ListItemButton>
      )}
    </ListItem>
  )
}

export default ProjectMenuLink
