import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal } from '../../Utils/javascript'
import {
  CREATE_PHOTOGRAPHS_FAILED,
  CREATE_PHOTOGRAPHS_REQUESTED,
  CREATE_PHOTOGRAPHS_SUCCESS,
  DELETE_PHOTOGRAPHS_FAILED,
  DELETE_PHOTOGRAPHS_REQUESTED,
  DELETE_PHOTOGRAPHS_SUCCESS,
  FETCH_PHOTOGRAPHS_FAILED,
  FETCH_PHOTOGRAPHS_REQUEST,
  FETCH_PHOTOGRAPHS_SUCCESS,
  UPDATE_PHOTOGRAPHS_FAILED,
  UPDATE_PHOTOGRAPHS_REQUESTED,
  UPDATE_PHOTOGRAPHS_SUCCESS,
} from '../constants/photographs'
import { updateSaveStatusAction } from './confirmation'

// Fetch photographs
const fetchPhotographsRequested = () => ({
  type: FETCH_PHOTOGRAPHS_REQUEST,
})
export const fetchPhotographsSuccess = (payload) => ({
  type: FETCH_PHOTOGRAPHS_SUCCESS,
  payload,
})
const fetchPhotographsFailed = (payload) => ({
  type: FETCH_PHOTOGRAPHS_FAILED,
  payload,
})

export const getPhotographs =
  (reportId, ocId, projectId) => async (dispatch) => {
    dispatch(fetchPhotographsRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.photographs}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
      showToastMessage: false,
      toastMessage: 'Photographs Fetch successfully!',
    })
    if (data) {
      dispatch(fetchPhotographsSuccess(data))
      return
    }
    if (error) {
      dispatch(fetchPhotographsFailed(error.response))
    }
  }
// Create photographs
const createPhotographsRequested = () => ({
  type: CREATE_PHOTOGRAPHS_REQUESTED,
})

const createPhotographsSuccess = (payload) => ({
  type: CREATE_PHOTOGRAPHS_SUCCESS,
  payload,
})
const createPhotographsFailed = (payload) => ({
  type: CREATE_PHOTOGRAPHS_FAILED,
  payload,
})

export const createPhotographs =
  (payload, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(createPhotographsRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.photographs}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Photographs upload successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(createPhotographsSuccess(data))
      return
    }
    if (error) {
      dispatch(createPhotographsFailed(error.response.data))
    }
  }
// Update photographs
const updatePhotographsRequested = () => ({
  type: UPDATE_PHOTOGRAPHS_REQUESTED,
})
const updatePhotographsSuccess = () => ({
  type: UPDATE_PHOTOGRAPHS_SUCCESS,
})
const updatePhotographsFailed = (payload) => ({
  type: UPDATE_PHOTOGRAPHS_FAILED,
  payload,
})

export const updatePhotographs =
  (payload, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(updatePhotographsRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.photographsUpdate}${
        payload[payload.length - 1]?.id
      }/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.patch,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Photographs updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(updatePhotographsSuccess(data))
      return
    }
    if (error) {
      dispatch(updatePhotographsFailed(error.response.data))
    }
  }

// Delete photographs
const deletePhotographsRequested = () => ({
  type: DELETE_PHOTOGRAPHS_REQUESTED,
})
export const deletePhotographsSuccess = (payload) => (dispatch, getState) => {
  const state = getState()
  let photographsList = [...state.photographs.photographsList]
  if (payload?.photograph?.length > 1) {
    photographsList = []
  } else {
    const index = photographsList?.findIndex(({ id }) =>
      equal(id, payload?.photograph[0]),
    )
    if (~index) {
      photographsList.splice(index, 1)
    }
  }
  dispatch({
    type: DELETE_PHOTOGRAPHS_SUCCESS,
    payload: photographsList,
  })
}
const deletePhotographsFailed = (payload) => ({
  type: DELETE_PHOTOGRAPHS_FAILED,
  payload,
})
export const deletePhotographs =
  (payload, reportId, ocId, projectId, handleClose) => async (dispatch) => {
    dispatch(deletePhotographsRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.photographs}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Photograph deleted successfully!',
    })
    if (status) {
      handleClose()
      dispatch(deletePhotographsSuccess(payload))
      return
    }
    if (error) {
      dispatch(deletePhotographsFailed(error.response.data))
    }
  }
