import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_CONSTRUCTION_SUMMARY,
  CREATE_CONSTRUCTION_SUMMARY_FAILED,
  CREATE_CONSTRUCTION_SUMMARY_REQUESTED,
  CREATE_CONSTRUCTION_SUMMARY_SUCCESS,
  FETCH_CONSTRUCTION_SUMMARY_FAILED,
  FETCH_CONSTRUCTION_SUMMARY_REQUESTED,
  FETCH_CONSTRUCTION_SUMMARY_SUCCESS,
  FETCH_MANPOWER_TRACKER_FAILED,
  FETCH_MANPOWER_TRACKER_REQUESTED,
  FETCH_MANPOWER_TRACKER_SUCCESS,
  IMPORT_FROM_LAST_REPORT_FAILED,
  IMPORT_FROM_LAST_REPORT_REQUESTED,
  IMPORT_FROM_LAST_REPORT_SUCCESS,
  IMPORT_PHASE_BASED_COMMENTS_FAILED,
  IMPORT_PHASE_BASED_COMMENTS_REQUESTED,
  IMPORT_PHASE_BASED_COMMENTS_SUCCESS,
  UPDATE_CONSTRUCTION_SUMMARY_FAILED,
  UPDATE_CONSTRUCTION_SUMMARY_REQUESTED,
  UPDATE_CONSTRUCTION_SUMMARY_SUCCESS,
  CREATE_UPDATE_MANPOWER_TRACKER_FAILED,
  CREATE_UPDATE_MANPOWER_TRACKER_REQUESTED,
  CREATE_UPDATE_MANPOWER_TRACKER_SUCCESS,
  DELETE_MANPOWER_TRACKER_REQUESTED,
  DELETE_MANPOWER_TRACKER_SUCCESS,
  DELETE_MANPOWER_TRACKER_FAILED,
} from '../constants/constructionSummary'

const initialState = {
  constructionSummaryData: null,
  isConstructionSummaryLoading: true,
  isConstructionSummaryUpdateLoading: false,
  isConstructionSummaryCreateLoading: false,
  constructionSummaryError: null,
  constructionSummaryUpdateError: null,
  // Import from last report
  isImporting: {
    freeForm: false,
  },
  importedFreeForm: null,
  isImportingPhaseBasedComments: false,
  importedComments: null,
  // Labor tracker
  isLoadingManpowerTracker: false,
  manpowerTrackerData: [],
  isCreateAndUpdateManpowerTracker: false,
  isDeleteManpowerTracker: false,
}

export const constructionSummaryReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    // fetch construction summary
    case FETCH_CONSTRUCTION_SUMMARY_REQUESTED:
      return {
        ...state,
        isConstructionSummaryLoading: true,
      }
    case FETCH_CONSTRUCTION_SUMMARY_SUCCESS:
      return {
        ...state,
        isConstructionSummaryLoading: false,
        constructionSummaryData: action.payload,
      }
    case FETCH_CONSTRUCTION_SUMMARY_FAILED:
      return {
        ...state,
        isConstructionSummaryLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        constructionSummaryError: action.payload?.data,
      }
    // Create construction summary
    case CREATE_CONSTRUCTION_SUMMARY_REQUESTED:
      return {
        ...state,
        isConstructionSummaryCreateLoading: true,
      }
    case CREATE_CONSTRUCTION_SUMMARY_SUCCESS:
      return {
        ...state,
        isConstructionSummaryCreateLoading: false,
        constructionSummaryData: action.payload,
      }
    case CREATE_CONSTRUCTION_SUMMARY_FAILED:
      return {
        ...state,
        isConstructionSummaryCreateLoading: false,
        constructionSummaryCreateError: action.payload,
      }
    // Update construction summary
    case UPDATE_CONSTRUCTION_SUMMARY_REQUESTED:
      return {
        ...state,
        isConstructionSummaryUpdateLoading: true,
      }
    case UPDATE_CONSTRUCTION_SUMMARY_SUCCESS:
      return {
        ...state,
        isConstructionSummaryUpdateLoading: false,
        constructionSummaryData: action.payload,
      }
    case UPDATE_CONSTRUCTION_SUMMARY_FAILED:
      return {
        ...state,
        isConstructionSummaryUpdateLoading: false,
        constructionSummaryUpdateError: action.payload,
      }
    // Import from last report
    case IMPORT_FROM_LAST_REPORT_REQUESTED:
      return {
        ...state,
        isImporting: {
          ...state.isImporting,
          [action.payload]: true,
        },
      }
    case IMPORT_FROM_LAST_REPORT_SUCCESS:
      return {
        ...state,
        [`imported${action.payload.pascalCaseQuery}`]: action.payload.data,
        isImporting: {
          ...state.isImporting,
          [action.payload.query]: false,
        },
      }
    case IMPORT_FROM_LAST_REPORT_FAILED:
      return {
        ...state,
        [`import${action.payload.pascalCaseQuery}Error`]: action.payload.data,
        isImporting: {
          ...state.isImporting,
          [action.payload.query]: false,
        },
      }
    // Import phase based comments from last report
    case IMPORT_PHASE_BASED_COMMENTS_REQUESTED:
      return {
        ...state,
        isImportingPhaseBasedComments: true,
      }
    case IMPORT_PHASE_BASED_COMMENTS_SUCCESS:
      return {
        ...state,
        importedComments: action.payload?.constructionSummaryComments,
        isImportingPhaseBasedComments: false,
      }
    case IMPORT_PHASE_BASED_COMMENTS_FAILED:
      return {
        ...state,
        isImportingPhaseBasedComments: false,
      }
    // Labor tracker
    case FETCH_MANPOWER_TRACKER_REQUESTED:
      return {
        ...state,
        isLoadingManpowerTracker: true,
      }
    case FETCH_MANPOWER_TRACKER_SUCCESS:
      return {
        ...state,
        manpowerTrackerData: action.payload,
        isLoadingManpowerTracker: false,
      }
    case FETCH_MANPOWER_TRACKER_FAILED:
      return {
        ...state,
        isLoadingManpowerTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }
    case CREATE_UPDATE_MANPOWER_TRACKER_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateManpowerTracker: true,
      }
    case CREATE_UPDATE_MANPOWER_TRACKER_SUCCESS:
      return {
        ...state,
        manpowerTrackerData: action.payload,
        isCreateAndUpdateManpowerTracker: false,
      }
    case CREATE_UPDATE_MANPOWER_TRACKER_FAILED:
      return {
        ...state,
        isCreateAndUpdateManpowerTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }
    case DELETE_MANPOWER_TRACKER_REQUESTED:
      return {
        ...state,
        isDeleteManpowerTracker: true,
      }
    case DELETE_MANPOWER_TRACKER_SUCCESS:
      return {
        ...state,
        manpowerTrackerData: action.payload,
        isDeleteManpowerTracker: false,
      }
    case DELETE_MANPOWER_TRACKER_FAILED:
      return {
        ...state,
        isDeleteManpowerTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }
    case CLEAR_CONSTRUCTION_SUMMARY:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
