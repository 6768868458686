import { Box, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { InnerLayout } from '../../../Layout/admin'
import {
  fetchLimitations,
  saveLimitations,
} from '../../../Redux/actions/constructionMonitoringReport'
import DKTButton from '../../../Shared/DKTButton'
import {
  entries,
  equal,
  handleTextEditor,
  isArray,
  ternary,
} from '../../../Utils/javascript'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import DKTSelect from '../../../Shared/DKTSelect'
import { formFields } from '../../../Description/constructionMonitoringReport.description'
import DKTInput from '../../../Shared/DKTInput'
import { fetchSignature } from '../../../Redux/actions/coverLetterAndPhoto'
import DKTStack from '../../../Shared/DKTStack'
import { ImagePlaceholder } from '../../../Assets/SVGs'
import useForm from '../../../Hooks/useForm'
import DKTForm from '../../../Shared/DKTForm'
import DKTEditor from '../../../Shared/DKTEditor'

const useStyles = makeStyles(() => ({
  limitationTextarea: {
    '& textarea': {
      resize: 'vertical',
      padding: [[10, 18]],
      fontStyle: 'italic',
      fontFamily: 'Roboto',
      fontWeight: 500,
      borderRadius: 0,
      borderColor: '#C4C4C4',
      color: '#646464',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  limitation: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 250,
    height: 350,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '150px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '150px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },
}))
const Limitations = ({ renderSelect, items }) => {
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [selectedSignatures, setSelectedSignatures] = useState(null)

  const {
    isUnsavedData,
    setIsUnsavedData,
    discardUnsavedChanges,
    isUnsavedChangesModalOpen,
    closeUnsavedChangesModal,
  } = items

  const { values, setValues, handleChange, setFieldValue } = useForm(formFields)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { limitations, isSavingLimitations } = useSelector(
    ({ constructionMonitoringReport }) => constructionMonitoringReport,
  )
  const { signatures } = useSelector(
    ({ coverLetterAndPhoto }) => coverLetterAndPhoto,
  )

  useEffect(() => {
    dispatch(fetchSignature())
  }, [dispatch])

  useEffect(() => {
    setValues(limitations)
  }, [dispatch, limitations])

  useEffect(() => {
    dispatch(fetchLimitations())
  }, [dispatch])

  useEffect(() => {
    if (limitations) {
      const flag =
        equal(values?.limitation, limitations?.limitation) &&
        equal(
          `${values?.nameAndSignature}`,
          `${limitations?.nameAndSignature}`,
        ) &&
        equal(values?.title, limitations?.title)
      setIsUnsavedData(!flag)
    }
  }, [limitations, values])

  useEffect(() => {
    const dynamicOptionArray = (arr) => {
      const res = arr?.map((sts) => ({
        value: `${sts?.id}`,
        label: `${sts?.firstName} ${sts?.lastName}`,
      }))
      return res
    }
    const nameAndSignature = dynamicOptionArray(signatures)

    setDynamicOptions({
      nameAndSignature,
    })
  }, [signatures])

  useEffect(() => {
    if (signatures && values?.nameAndSignature) {
      const selectedSignature = signatures.find(({ id }) =>
        equal(id?.toString(), values.nameAndSignature?.toString()),
      )

      setSelectedSignatures(selectedSignature?.signature || '')
    }
  }, [signatures, values?.nameAndSignature])

  const handleSave = () => {
    if (limitations) {
      setIsUnsavedData(false)
      dispatch(saveLimitations({ limitation: limitations, ...values }))
    } else {
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }

  const actions = () => (
    <DKTButton onClick={handleSave} disabled={isSavingLimitations}>
      {ternary(isSavingLimitations, 'Saving...', 'Save')}
    </DKTButton>
  )
  const mapFormFields = ({ formFields }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            defaultValue,
            options,
            isConfirmPassword,
            dependsOn,
            ...formField
          },
        ],
        index,
      ) => {
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )

        return (
          <Grid
            key={index}
            item
            lg={formField.lg ?? 12}
            xs={formField.xs ?? 12}
          >
            <FormControl
              {...formField}
              options={dynamicOptions[name] || []}
              isRequired={isRequired}
              disabled={formField?.disabled}
              id={name}
              name={name}
              value={(values && values[name]?.toString()) || defaultValue || ''}
              onChange={handleChange}
            />
          </Grid>
        )
      },
    )

  const renderFormFields = (formFields) => (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {isArray(formFields)
        ? formFields.map((formField) =>
            mapFormFields({
              formFields: formField.data,
              id: formField.id,
            }),
          )
        : mapFormFields({ formFields })}
    </Grid>
  )

  return (
    <InnerLayout
      actionTitle="Manage Construction Monitoring Report Settings"
      actions={actions}
      contentTitle="Construction Monitoring Report (CMR)"
    >
      {renderSelect()}
      <Box mt={5}>
        <Grid container>
          <Grid item xs={9}>
            <div className={classes.limitationTextarea}>
              <Grid item xs={12}>
                <DKTEditor
                  value={values?.limitation || ''}
                  onChange={(event, editor) => {
                    handleTextEditor(editor, 'limitation', setFieldValue)
                  }}
                  className={classes.limitation}
                />
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Grid container mt={2}>
        <Grid item lg={3} md={6}>
          <DKTForm autoComplete="off">
            {renderFormFields(formFields.limitations)}
          </DKTForm>
        </Grid>
        <Grid item lg={6} md={6}>
          {values?.nameAndSignature && (
            <DKTStack
              ml={3}
              height="100%"
              alignItems="center"
              justifyContent="center"
              sx={{ maxWidth: 280, maxHeight: 132 }}
              overflow="hidden"
            >
              {ternary(
                selectedSignatures,
                <img src={selectedSignatures} alt="" />,
                <ImagePlaceholder style={{ height: '200px' }} />,
              )}
            </DKTStack>
          )}
        </Grid>
      </Grid>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnsavedChangesModalOpen}
        onConfirm={discardUnsavedChanges}
        onCancel={closeUnsavedChangesModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isUnsavedData} onSave={handleSave} />
    </InnerLayout>
  )
}

export default Limitations
