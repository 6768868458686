export const FETCH_SITE_AND_BUILDING_REQUESTED =
  'FETCH_SITE_AND_BUILDING_REQUESTED'
export const FETCH_SITE_AND_BUILDING_FAILED = 'FETCH_SITE_AND_BUILDING_FAILED'
export const FETCH_SITE_AND_BUILDING_SUCCESS = 'FETCH_SITE_AND_BUILDING_SUCCESS'

export const CREATE_SITE_AND_BUILDING_REQUESTED =
  'CREATE_SITE_AND_BUILDING_REQUESTED'
export const CREATE_SITE_AND_BUILDING_FAILED = 'CREATE_SITE_AND_BUILDING_FAILED'
export const CREATE_SITE_AND_BUILDING_SUCCESS =
  'CREATE_SITE_AND_BUILDING_SUCCESS'

export const SAVE_SITE_AND_BUILDING_REQUESTED =
  'SAVE_SITE_AND_BUILDING_REQUESTED'
export const SAVE_SITE_AND_BUILDING_FAILED = 'SAVE_SITE_AND_BUILDING_FAILED'
export const SAVE_SITE_AND_BUILDING_SUCCESS = 'SAVE_SITE_AND_BUILDING_SUCCESS'

export const FETCH_BUILDING_UNIT_INFO_REQUESTED =
  'FETCH_BUILDING_UNIT_INFO_REQUESTED'
export const FETCH_BUILDING_UNIT_INFO_FAILED = 'FETCH_BUILDING_UNIT_INFO_FAILED'
export const FETCH_BUILDING_UNIT_INFO_SUCCESS =
  'FETCH_BUILDING_UNIT_INFO_SUCCESS'

export const SAVE_BUILDING_UNIT_INFO_REQUESTED =
  'SAVE_BUILDING_UNIT_INFO_REQUESTED'
export const SAVE_BUILDING_UNIT_INFO_FAILED = 'SAVE_BUILDING_UNIT_INFO_FAILED'
export const SAVE_BUILDING_UNIT_INFO_SUCCESS = 'SAVE_BUILDING_UNIT_INFO_SUCCESS'

export const REMOVE_BUILDING_UNIT_INFO_REQUESTED =
  'REMOVE_BUILDING_UNIT_INFO_REQUESTED'
export const REMOVE_BUILDING_UNIT_INFO_FAILED =
  'REMOVE_BUILDING_UNIT_INFO_FAILED'
export const REMOVE_BUILDING_UNIT_INFO_SUCCESS =
  'REMOVE_BUILDING_UNIT_INFO_SUCCESS'

export const ADD_SITE_BUILDING = 'ADD_SITE_BUILDING'
export const SET_SITE_ID = 'SET_SITE_ID'

// Delete site and building
export const DELETE_SITE_AND_BUILDING_REQUESTED =
  'DELETE_SITE_AND_BUILDING_REQUESTED'
export const DELETE_SITE_AND_BUILDING_SUCCESS =
  'DELETE_SITE_AND_BUILDING_SUCCESS'
export const DELETE_SITE_AND_BUILDING_FAILED = 'DELETE_SITE_AND_BUILDING_FAILED'

// clear site and building
export const CLEAR_SITE_AND_BUILDING = 'CLEAR_SITE_AND_BUILDING'
