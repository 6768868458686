import { Box, Grid, Typography } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { loginFields } from '../../Description/login.description'
import useForm from '../../Hooks/useForm'
import { login } from '../../Redux/actions/auth'
import DKTButton from '../../Shared/DKTButton'
import { useStyles } from '../../Styles/login.style'
import { ternary } from '../../Utils/javascript'
import { loadStateFn } from '../../Utils/localStorage'
import { renderFormFields } from '../../Utils/renderFormFields'
import loginImage from '../../Assets/login.jpg'
import companyLogo from '../../Assets/company-logo.jpg'
import {
  reCaptchaId,
  recaptchaLoginAction,
  sitekey,
} from '../../Utils/constant'
import { showToast } from '../../Utils/toastService'

const Login = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isLoadingLogin } = useSelector((state) => state.auth)

  const { values, handleChange, handleSubmit, errors, setFieldValue } =
    useForm(loginFields)

  useEffect(() => {
    renderCaptcha()
  }, [])

  const renderCaptcha = () => {
    try {
      if (document.getElementById(reCaptchaId).innerHTML) {
        document.getElementById(reCaptchaId).remove()
        const temp = document.getElementById('outer_html_element')
        const newDiv = document.createElement('div')
        newDiv.id = reCaptchaId
        temp.appendChild(newDiv)
      }

      window?.grecaptcha?.enterprise?.ready(() => {
        try {
          window?.grecaptcha?.enterprise?.render(
            reCaptchaId,
            {
              sitekey,
              callback: verifyReCaptchaCallback,
            },
            recaptchaLoginAction,
          )
        } catch (error) {
          showToast(
            'There is something wrong with the google recaptcha. Please try again later!',
          )
        }
      })
    } catch (error) {
      showToast(
        'There is something wrong with the google recaptcha. Please try again later!',
      )
    }
  }

  useEffect(() => {
    const rememberedUsername = loadStateFn('rememberUsername')
    if (rememberedUsername) setFieldValue('email', rememberedUsername)
  }, [setFieldValue])

  const handleLogin = async (e) => {
    e.preventDefault()
    const isFormValid = handleSubmit()
    if (!isFormValid) return
    const { email, password, token, rememberUsername } = values
    dispatch(
      login({
        email,
        password,
        token,
        recaptcha_action: recaptchaLoginAction,
        rememberUsername,
        renderCaptcha,
        navigate,
      }),
    )
  }

  const verifyReCaptchaCallback = useCallback((token) => {
    setFieldValue('token', token)
  }, [])
  return (
    <Grid container className={classes.loginContainer}>
      <Grid item md={5}>
        <img src={loginImage} alt="company-logo" loading="lazy" />
      </Grid>
      <Grid item md={7} px={10} className={classes.loginForm}>
        <Box
          component="form"
          className={classes.loginFormMain}
          onSubmit={handleLogin}
        >
          <img src={companyLogo} alt="company-logo" loading="lazy" />
          {renderFormFields({
            formFields: loginFields,
            errors,
            values,
            handleChange,
          })}
          <Link to="/forgot-password" className={classes.forgotPasswordLink}>
            Forgot password?
          </Link>
          <div id="outer_html_element">
            <div id={reCaptchaId}></div>
          </div>
          <DKTButton type="submit" disabled={isLoadingLogin}>
            {ternary(isLoadingLogin, 'Logging in...', 'Login')}
          </DKTButton>
        </Box>
        <Typography className={classes.copyrightText}>
          <Typography variant="span">©</Typography> 2023 AcreIQ. All rights
          reserved.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Login
