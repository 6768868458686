import { FormHelperText, InputLabel } from '@mui/material'
import React from 'react'
import Select from 'react-select'
import { isArray } from '../Utils/javascript'

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    paddingLeft: '0px',
    paddingRight: '0px',
    zIndex: '10',
  }),
}

const DKTSearchableSelect = ({
  isRequired,
  showLabel = true,
  id,
  label,
  defaultValue,
  disabled,
  error,
  innerRef,
  ...rest
}) => (
  <>
    {showLabel && (
      <InputLabel
        shrink
        htmlFor={id}
        required={isRequired}
        disabled={disabled}
        sx={{ fontWeight: 'medium', color: 'gray.extraDark' }}
      >
        {label}
      </InputLabel>
    )}
    <Select
      ref={innerRef}
      isSearchable
      isDisabled={disabled}
      {...rest}
      defaultValue={defaultValue}
      styles={customStyles}
    />
    {isArray(error) ? (
      error.map((errorMessage, index) => (
        <FormHelperText error={!!error} key={index}>
          {errorMessage}
        </FormHelperText>
      ))
    ) : (
      <FormHelperText error={!!error}>{error}</FormHelperText>
    )}
  </>
)
export default DKTSearchableSelect
