import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_BUDGET_SUMMARY,
  CREATE_BUDGET_SUMMARY_FAILED,
  CREATE_BUDGET_SUMMARY_REQUESTED,
  CREATE_BUDGET_SUMMARY_SUCCESS,
  DELETE_BUDGET_SUMMARY_FAILED,
  DELETE_BUDGET_SUMMARY_REQUESTED,
  DELETE_BUDGET_SUMMARY_SUCCESS,
  FETCH_BUDGET_SUMMARY_FAILED,
  FETCH_BUDGET_SUMMARY_NUMBERING_FAILED,
  FETCH_BUDGET_SUMMARY_NUMBERING_REQUEST,
  FETCH_BUDGET_SUMMARY_NUMBERING_SUCCESS,
  FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_FAILED,
  FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_REQUESTED,
  FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_SUCCESS,
  FETCH_BUDGET_SUMMARY_REQUEST,
  FETCH_BUDGET_SUMMARY_SUCCESS,
  IMPORT_FROM_LAST_REPORT_FAILED,
  IMPORT_FROM_LAST_REPORT_REQUESTED,
  IMPORT_FROM_LAST_REPORT_SUCCESS,
  UPDATE_BUDGET_SUMMARY_FAILED,
  UPDATE_BUDGET_SUMMARY_REQUESTED,
  UPDATE_BUDGET_SUMMARY_SUCCESS,
} from '../constants/budgetSummary'

const initialState = {
  isCreateAndUpdateBudgetSummaryLoading: false,
  isBudgetSummaryLoading: true,
  budgetSummaryData: [],
  budgetSummaryError: null,
  isBudgetSummaryDeleting: false,
  // Import from last report
  isImporting: {
    payApplicationComments: false,
    changeOrderComments: false,
    potentialChangeOrderComments: false,
    lienWaiverComments: false,
    storedMaterialComments: false,
  },
  importedPayApplicationComments: null,
  importedChangeOrderComments: null,
  importedPotentialChangeOrderComments: null,
  importedLienWaiverComments: null,
  importedStoredMaterialComments: null,
  importChangeOrderCommentsError: null,
  importPotentialChangeOrderCommentsError: null,
  importLienWaiverCommentsError: null,
  importStoredMaterialCommentsError: null,
  isFetchingBudgetSummaryReferenceValues: true,
  budgetSummaryReference: null,
  budgetSummaryReferenceError: null,
  isBudgetSummaryNumberLoading: true,
  budgetSummaryNumberData: null,
}

export const budgetSummaryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch budget summary reference values
    case FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_REQUESTED:
      return {
        ...state,
        isFetchingBudgetSummaryReferenceValues: true,
      }
    case FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_SUCCESS:
      return {
        ...state,
        isFetchingBudgetSummaryReferenceValues: false,
        budgetSummaryReference: action?.payload,
        budgetSummaryReferenceError: null,
      }
    case FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_FAILED:
      return {
        ...state,
        isFetchingBudgetSummaryReferenceValues: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        budgetSummaryReferenceError: action.payload?.data,
      }

    // Fetch budget summary numbering
    case FETCH_BUDGET_SUMMARY_NUMBERING_REQUEST:
      return {
        ...state,
        isBudgetSummaryNumberLoading: true,
      }
    case FETCH_BUDGET_SUMMARY_NUMBERING_SUCCESS:
      return {
        ...state,
        isBudgetSummaryNumberLoading: false,
        budgetSummaryNumberData: action?.payload,
      }
    case FETCH_BUDGET_SUMMARY_NUMBERING_FAILED:
      return {
        ...state,
        isBudgetSummaryNumberLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }
    // Fetch budget summary
    case FETCH_BUDGET_SUMMARY_REQUEST:
      return {
        ...state,
        isBudgetSummaryLoading: true,
      }
    case FETCH_BUDGET_SUMMARY_SUCCESS:
      return {
        ...state,
        isBudgetSummaryLoading: false,
        budgetSummaryData: action?.payload,
        budgetSummaryError: null,
      }
    case FETCH_BUDGET_SUMMARY_FAILED:
      return {
        ...state,
        isBudgetSummaryLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        budgetSummaryData: [],
      }
    // Create budget summary
    case CREATE_BUDGET_SUMMARY_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateBudgetSummaryLoading: true,
        budgetSummaryError: null,
      }
    case CREATE_BUDGET_SUMMARY_SUCCESS:
      return {
        ...state,
        isCreateAndUpdateBudgetSummaryLoading: false,
        budgetSummaryData: [...state.budgetSummaryData, ...action.payload],
        budgetSummaryError: null,
      }
    case CREATE_BUDGET_SUMMARY_FAILED:
      return {
        ...state,
        isCreateAndUpdateBudgetSummaryLoading: false,
        budgetSummaryError: action.payload,
      }
    // Update budget summary
    case UPDATE_BUDGET_SUMMARY_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateBudgetSummaryLoading: true,
        budgetSummaryError: null,
      }
    case UPDATE_BUDGET_SUMMARY_SUCCESS:
      return {
        ...state,
        isCreateAndUpdateBudgetSummaryLoading: false,
        budgetSummaryData: action?.payload,
        budgetSummaryError: null,
      }
    case UPDATE_BUDGET_SUMMARY_FAILED:
      return {
        ...state,
        isCreateAndUpdateBudgetSummaryLoading: false,
        budgetSummaryError: action.payload,
      }
    // Delete budget summary
    case DELETE_BUDGET_SUMMARY_REQUESTED:
      return {
        ...state,
        isBudgetSummaryDeleting: true,
      }
    case DELETE_BUDGET_SUMMARY_SUCCESS:
      return {
        ...state,
        budgetSummaryData: action?.payload,
        isBudgetSummaryDeleting: false,
      }
    case DELETE_BUDGET_SUMMARY_FAILED:
      return {
        ...state,
        isBudgetSummaryDeleting: false,
      }

    // Import from last report
    case IMPORT_FROM_LAST_REPORT_REQUESTED:
      return {
        ...state,
        isImporting: {
          ...state.isImporting,
          [action.payload]: true,
        },
      }
    case IMPORT_FROM_LAST_REPORT_SUCCESS:
      return {
        ...state,
        [`imported${action.payload.pascalCaseQuery}`]: action.payload.data,
        isImporting: {
          ...state.isImporting,
          [action.payload.query]: false,
        },
      }
    case IMPORT_FROM_LAST_REPORT_FAILED:
      return {
        ...state,
        [`import${action.payload.pascalCaseQuery}Error`]: action.payload.data,
        isImporting: {
          ...state.isImporting,
          [action.payload.query]: false,
        },
      }
    case CLEAR_BUDGET_SUMMARY:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
