import { equal, ternary } from '../../Utils/javascript'
import {
  ADD_UPDATE_ISSUE_FAILED,
  ADD_UPDATE_ISSUE_REQUESTED,
  ADD_UPDATE_ISSUE_SUCCESS,
  CLEAR_ISSUES_LOG,
  DELETE_ISSUE_FAILED,
  DELETE_ISSUE_REQUESTED,
  DELETE_ISSUE_SUCCESS,
  FETCH_ISSUES_LOG_FAILED,
  FETCH_ISSUES_LOG_REQUESTED,
  FETCH_ISSUES_LOG_SUCCESS,
} from '../constants/issuesLog'

const initialState = {
  isFetchingIssuesLogLoading: true,
  issuesLogData: [],
  isAddOrUpdateIssueLoading: false,
  isIssueDeleteLoading: false,
  issuesLogError: {},
  canAddIssueLog: true,
}

export const issuesLog = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ISSUES_LOG_REQUESTED:
      return {
        ...state,
        isFetchingIssuesLogLoading: true,
      }
    case FETCH_ISSUES_LOG_SUCCESS:
      return {
        ...state,
        issuesLogData: action.payload?.results,
        canAddIssueLog: action.payload?.canAddIssueLog,
        isFetchingIssuesLogLoading: false,
      }
    case FETCH_ISSUES_LOG_FAILED:
      return {
        ...state,
        isFetchingIssuesLogLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        issuesLogData: [],
      }
    case ADD_UPDATE_ISSUE_REQUESTED:
      return {
        ...state,
        isAddOrUpdateIssueLoading: true,
      }
    case ADD_UPDATE_ISSUE_SUCCESS:
      return {
        ...state,
        isAddOrUpdateIssueLoading: false,
      }
    case ADD_UPDATE_ISSUE_FAILED:
      return {
        ...state,
        isAddOrUpdateIssueLoading: false,
        issuesLogError: action.payload?.data,
      }
    case DELETE_ISSUE_REQUESTED:
      return {
        ...state,
        isIssueDeleteLoading: true,
      }
    case DELETE_ISSUE_SUCCESS:
      return {
        ...state,
        issuesLogData: action.payload,
        isIssueDeleteLoading: false,
      }
    case DELETE_ISSUE_FAILED:
      return {
        ...state,
        isIssueDeleteLoading: false,
        issuesLogData: [],
      }
    case CLEAR_ISSUES_LOG:
      return initialState
    default:
      return state
  }
}
