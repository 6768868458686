import { equal, ternary } from '../../Utils/javascript'
import {
  ADD_OWNER_CONTRACTOR,
  CLEAR_OWNER_CONTRACTOR,
  CREATE_AND_UPDATE_OWNER_CONTRACTOR_FAILED,
  CREATE_AND_UPDATE_OWNER_CONTRACTOR_REQUESTED,
  CREATE_AND_UPDATE_OWNER_CONTRACTOR_SUCCESS,
  DELETE_CONTRACTOR_FAILED,
  DELETE_CONTRACTOR_REQUESTED,
  DELETE_CONTRACTOR_SUCCESS,
  FETCH_OWNER_CONTRACTOR_FAILED,
  FETCH_OWNER_CONTRACTOR_REQUESTED,
  FETCH_OWNER_CONTRACTOR_SUCCESS,
  FETCH_SITE_BUILDING_MAPPING_FAILED,
  FETCH_SITE_BUILDING_MAPPING_REQUESTED,
  FETCH_SITE_BUILDING_MAPPING_SUCCESS,
  SEARCH_CONTRACTOR_FAILED,
  SEARCH_CONTRACTOR_REQUESTED,
  SEARCH_CONTRACTOR_SUCCESS,
} from '../constants/ownerContractor'

const initialState = {
  isCreateAndUpdateOwnerContractorLoading: false,
  isOwnerContractorListLoading: true,
  ownerContractorList: [],
  siteBuildingMapping: [],
  activeContractor: null,
  ownerContractorError: null,
  isSearchingContractor: true,
  searchResults: [],
  searchContractorError: '',
  isDeletingContractor: false,
}

export const ownerContractorReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch owner contractor
    case FETCH_OWNER_CONTRACTOR_REQUESTED:
      return {
        ...state,
        isOwnerContractorListLoading: true,
      }
    case FETCH_OWNER_CONTRACTOR_SUCCESS:
      return {
        ...state,
        isOwnerContractorListLoading: false,
        ownerContractorList: action?.payload,
        ownerContractorError: null,
      }
    case FETCH_OWNER_CONTRACTOR_FAILED:
      return {
        ...state,
        isOwnerContractorListLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        ownerContractorList: [],
      }
    // Create and update owner contractor
    case CREATE_AND_UPDATE_OWNER_CONTRACTOR_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateOwnerContractorLoading: true,
        activeContractor: null,
      }
    case CREATE_AND_UPDATE_OWNER_CONTRACTOR_SUCCESS:
      return {
        ...state,
        isCreateAndUpdateOwnerContractorLoading: false,
        ownerContractorList: action?.payload,
        activeContractor: action?.activeContractor,
        ownerContractorError: null,
      }
    case CREATE_AND_UPDATE_OWNER_CONTRACTOR_FAILED:
      return {
        ...state,
        isCreateAndUpdateOwnerContractorLoading: false,
        ownerContractorError: action.payload,
      }
    // Fetch owner contractor
    case FETCH_SITE_BUILDING_MAPPING_REQUESTED:
      return {
        ...state,
        isSiteBuildingMapping: true,
      }
    case FETCH_SITE_BUILDING_MAPPING_SUCCESS:
      return {
        ...state,
        isSiteBuildingMapping: false,
        siteBuildingMapping: action?.payload,
      }
    case FETCH_SITE_BUILDING_MAPPING_FAILED:
      return {
        ...state,
        isSiteBuildingMapping: false,
      }
    // Add owner contractor
    case ADD_OWNER_CONTRACTOR:
      return {
        ...state,
        ownerContractorList: action.payload,
      }
    // Search contractor
    case SEARCH_CONTRACTOR_REQUESTED:
      return {
        ...state,
        isSearchingContractor: true,
      }
    case SEARCH_CONTRACTOR_SUCCESS:
      return {
        ...state,
        isSearchingContractor: false,
        searchResults: action.payload,
      }
    case SEARCH_CONTRACTOR_FAILED:
      return {
        ...state,
        isSearchingContractor: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        searchContractorError: action.payload?.data,
      }
    // Delete contractor
    case DELETE_CONTRACTOR_REQUESTED:
      return {
        ...state,
        isDeletingContractor: true,
      }
    case DELETE_CONTRACTOR_SUCCESS:
      return {
        ...state,
        isDeletingContractor: false,
        ownerContractorList: action.payload,
        activeContractor: action?.activeContractor,
      }
    case DELETE_CONTRACTOR_FAILED:
      return {
        ...state,
        isDeletingContractor: false,
      }
    case CLEAR_OWNER_CONTRACTOR:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
