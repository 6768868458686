import { Box } from '@mui/material'
import React from 'react'

const DKTForm = ({ children, ...rest }) => (
  <Box component="form" {...rest} onSubmit={(e) => e.preventDefault()}>
    {children}
  </Box>
)

export default DKTForm
