import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material'
import React from 'react'

const DKTAccordion = ({
  children,
  accordionTitle,
  expanded,
  idTag,
  onChange,
  defaultExpanded = false,
  ...rest
}) => (
  <Accordion
    elevation={0}
    expanded={expanded}
    onChange={onChange}
    defaultExpanded={defaultExpanded}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`${idTag}-content`}
      id={`${idTag}-header`}
      {...rest}
    >
      <Typography>{accordionTitle}</Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
)

export default DKTAccordion
