// Fetch permits entitlements
export const FETCH_PERMITS_ENTITLEMENTS_REQUESTED =
  'FETCH_PERMITS_ENTITLEMENTS_REQUESTED'
export const FETCH_PERMITS_ENTITLEMENTS_SUCCESS =
  'FETCH_PERMITS_ENTITLEMENTS_SUCCESS'
export const FETCH_PERMITS_ENTITLEMENTS_FAILED =
  'FETCH_PERMITS_ENTITLEMENTS_FAILED'

// Fetch last report permits entitlements
export const FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_REQUESTED =
  'FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_REQUESTED'
export const FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_SUCCESS =
  'FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_SUCCESS'
export const FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_FAILED =
  'FETCH_LAST_REPORT_PERMITS_ENTITLEMENTS_FAILED'

// Save permits entitlements
export const SAVE_PERMITS_ENTITLEMENTS_REQUESTED =
  'SAVE_PERMITS_ENTITLEMENTS_REQUESTED'
export const SAVE_PERMITS_ENTITLEMENTS_SUCCESS =
  'SAVE_PERMITS_ENTITLEMENTS_SUCCESS'
export const SAVE_PERMITS_ENTITLEMENTS_FAILED =
  'SAVE_PERMITS_ENTITLEMENTS_FAILED'

// Delete permits entitlements
export const DELETE_PERMITS_ENTITLEMENTS_REQUESTED =
  'DELETE_PERMITS_ENTITLEMENTS_REQUESTED'
export const DELETE_PERMITS_ENTITLEMENTS_SUCCESS =
  'DELETE_PERMITS_ENTITLEMENTS_SUCCESS'
export const DELETE_PERMITS_ENTITLEMENTS_FAILED =
  'DELETE_PERMITS_ENTITLEMENTS_FAILED'

// Import from last report
export const IMPORT_PERMITS_ENTITLEMENTS_REQUESTED =
  'IMPORT_PERMITS_ENTITLEMENTS_REQUESTED'
export const IMPORT_PERMITS_ENTITLEMENTS_SUCCESS =
  'IMPORT_PERMITS_ENTITLEMENTS_SUCCESS'
export const IMPORT_PERMITS_ENTITLEMENTS_FAILED =
  'IMPORT_PERMITS_ENTITLEMENTS_FAILED'

/* Certificate of Occupancy tracker */
// Fetch Certificate of Occupancy tracker
export const FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED =
  'FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED'
export const FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS =
  'FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS'
export const FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED =
  'FETCH_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED'

// Fetch last report Certificate of Occupancy tracker
export const FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED =
  'FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED'
export const FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS =
  'FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS'
export const FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED =
  'FETCH_LAST_REPORT_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED'

// Add/Update Certificate of Occupancy tracker
export const SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED =
  'SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED'
export const SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS =
  'SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS'
export const SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED =
  'SAVE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED'

// Delete Certificate of Occupancy tracker
export const DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED =
  'DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_REQUESTED'
export const DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS =
  'DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_SUCCESS'
export const DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED =
  'DELETE_CERTIFICATE_OF_OCCUPANCY_TRACKER_FAILED'
export const CLEAR_PERMITS_ENTITLEMENTS = 'CLEAR_PERMITS_ENTITLEMENTS'

// Fetch permits tracker
export const FETCH_PERMITS_TRACKER_REQUESTED = 'FETCH_PERMITS_TRACKER_REQUESTED'
export const FETCH_PERMITS_TRACKER_SUCCESS = 'FETCH_PERMITS_TRACKER_SUCCESS'
export const FETCH_PERMITS_TRACKER_FAILED = 'FETCH_PERMITS_TRACKER_FAILED'

// Save permits tracker
export const SAVE_PERMITS_TRACKER_REQUESTED = 'SAVE_PERMITS_TRACKER_REQUESTED'
export const SAVE_PERMITS_TRACKER_SUCCESS = 'SAVE_PERMITS_TRACKER_SUCCESS'
export const SAVE_PERMITS_TRACKER_FAILED = 'SAVE_PERMITS_TRACKER_FAILED'

// Delete permits Tracker
export const DELETE_PERMIT_TRACKER_REQUESTED = 'DELETE_PERMIT_TRACKER_REQUESTED'
export const DELETE_PERMIT_TRACKER_SUCCESS = 'DELETE_PERMIT_TRACKER_SUCCESS'
export const DELETE_PERMIT_TRACKER_FAILED = 'DELETE_PERMIT_TRACKER_FAILED'
