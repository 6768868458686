import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  addBtn: {
    marginLeft: 0,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  image: {
    display: 'none',
  },
  imageBox: {
    '& div[data-react-beautiful-dnd-droppable]': {
      flexWrap: 'wrap',
      margin: '0 -10px',
      display: 'flex',
      '& div[data-react-beautiful-dnd-drag-handle]': {
        width: '50%',
        padding: '0 10px',
        boxSizing: 'border-box',
        marginBottom: 20,
      },
    },
  },
}))

export const useStylesIcon = makeStyles(() => ({
  iconWrapper: {
    '&.MuiIconButton-root': {
      backgroundColor: '#fff',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
  },
}))
