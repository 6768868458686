import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { showToast } from '../../Utils/toastService'
import {
  CLEAR_PROJECT_DASHBOARD_DATA,
  FETCH_PROJECT_DASHBOARD_FAILED,
  FETCH_PROJECT_DASHBOARD_REQUESTED,
  FETCH_PROJECT_DASHBOARD_SUCCESS,
  UPLOAD_IMAGE_FAILED,
  UPLOAD_IMAGE_REQUESTED,
  UPLOAD_IMAGE_SUCCESS,
} from '../constants/projectDashboard'

const fetchProjectDashboardDataRequested = () => ({
  type: FETCH_PROJECT_DASHBOARD_REQUESTED,
})
export const fetchProjectDashboardDataSuccess = (payload) => ({
  type: FETCH_PROJECT_DASHBOARD_SUCCESS,
  payload,
})
const fetchProjectDashboardDataFailed = (payload) => ({
  type: FETCH_PROJECT_DASHBOARD_FAILED,
  payload,
})

export const getProjectDashboard = (projectId) => async (dispatch) => {
  dispatch(fetchProjectDashboardDataRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.projectDashboard}?projectId=${projectId}`,
    method: method.get,
  })
  if (data) {
    dispatch(fetchProjectDashboardDataSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchProjectDashboardDataFailed(error?.response?.data))
  }
}

// Update photographs
const uploadPhotographRequested = () => ({
  type: UPLOAD_IMAGE_REQUESTED,
})
const uploadPhotographSuccess = (payload) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload,
})
const uploadPhotographFailed = (payload) => ({
  type: UPLOAD_IMAGE_FAILED,
  payload,
})

export const uploadPhotograph = (payload, projectId) => async (dispatch) => {
  dispatch(uploadPhotographRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.projectDashboardAttachments}?projectId=${projectId}`,
    method: method.post,
    data: payload,
    showToastMessage: true,
    toastMessage: 'Image updated successfully!',
  })

  if (data) {
    dispatch(uploadPhotographSuccess(data))
    return
  }
  if (error) {
    dispatch(uploadPhotographFailed(error.response.data))
  }
}

export const deletePhotograph = (payload, projectId) => async (dispatch) => {
  try {
    await api({
      endPoint: `${apiEndPoints.projectDashboardAttachments}delete/?projectId=${projectId}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Image deleted successfully!',
    })

    dispatch(getProjectDashboard(projectId))
  } catch (error) {
    showToast(error?.message || 'Something went wrong')
  }
}

export const clearProjectDashboardData = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROJECT_DASHBOARD_DATA })
}
