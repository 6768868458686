export const optionFields = ['ownerContractor']

export const formFields = {
  comments: {
    type: 'textarea',
    placeholder: 'Placeholder Text',
    rows: 6,
    xs: 12,
  },
}

export const addSectionFormFields = {
  name: {
    type: 'select',
    label: 'Select Section',
    placeholder: 'Select',
    displayEmpty: true,
    options: [
      { label: 'Section 1', value: 'Section 1' },
      { label: 'Section 2', value: 'Section 2' },
      { label: 'Section 3', value: 'Section 3' },
    ],
    xs: 11.6,
  },
}
export const addNewSectionFormFields = {
  name: {
    type: 'text',
    label: 'Section Name',
    placeholder: 'Enter Section Name',
  },
}
