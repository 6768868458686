import {
  ADD_EDIT_PROJECT_ACCESS_FAILED,
  ADD_EDIT_PROJECT_ACCESS_REQUESTED,
  ADD_EDIT_PROJECT_ACCESS_SUCCESS,
  CLEAR_CLIENT_ACCESS_DATA,
  DELETE_CLIENT_ACCESS_PROJECT_SUCCESS,
  FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_FAILED,
  FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_REQUESTED,
  FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_SUCCESS,
  FETCH_CLIENT_ACCESS_PROJECT_LIST_FAILED,
  FETCH_CLIENT_ACCESS_PROJECT_LIST_REQUESTED,
  FETCH_CLIENT_ACCESS_PROJECT_LIST_SUCCESS,
  FETCH_CLIENT_COMPANY_LIST_FAILED,
  FETCH_CLIENT_COMPANY_LIST_REQUESTED,
  FETCH_CLIENT_COMPANY_LIST_SUCCESS,
} from '../constants/clientAccess'

const initialState = {
  isLoadingClientCompanies: false,
  clientCompanies: [],
  clientCompaniesError: null,
  projectAccessList: [],
  isLoadingProjectAccessList: false,
  projectAccessListError: null,
  associatedClientsWithProjects: [],
  isLoadingAssociatedClientsWithProjects: false,
  associatedClientsWithProjectsError: null,
  isLoadingProjectAccessAddEdit: false,
  projectAccessAddEditError: null,
}

export const clientAccess = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_CLIENT_COMPANY_LIST_REQUESTED:
      return {
        ...state,
        isLoadingClientCompanies: true,
      }
    case FETCH_CLIENT_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        clientCompanies: action.payload,
        isLoadingClientCompanies: false,
      }
    case FETCH_CLIENT_COMPANY_LIST_FAILED:
      return {
        ...state,
        isLoadingClientCompanies: false,
        clientCompaniesError: action.payload,
      }
    case FETCH_CLIENT_ACCESS_PROJECT_LIST_REQUESTED:
      return {
        ...state,
        isLoadingProjectAccessList: true,
      }
    case FETCH_CLIENT_ACCESS_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        projectAccessList: action.payload,
        isLoadingProjectAccessList: false,
        associatedClientsWithProjects: [],
      }
    case FETCH_CLIENT_ACCESS_PROJECT_LIST_FAILED:
      return {
        ...state,
        isLoadingProjectAccessList: false,
        projectAccessListError: action.payload,
      }
    case FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_REQUESTED:
      return {
        ...state,
        isLoadingAssociatedClientsWithProjects: true,
        associatedClientsWithProjects: [],
      }
    case FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_SUCCESS:
      return {
        ...state,
        associatedClientsWithProjects: action.payload,
        isLoadingAssociatedClientsWithProjects: false,
      }
    case FETCH_ASSOCIATED_CLIENT_WITH_PROJECT_FAILED:
      return {
        ...state,
        isLoadingAssociatedClientsWithProjects: false,
        associatedClientsWithProjectsError: action.payload,
      }
    case ADD_EDIT_PROJECT_ACCESS_REQUESTED:
      return {
        ...state,
        isLoadingProjectAccessAddEdit: true,
      }
    case ADD_EDIT_PROJECT_ACCESS_SUCCESS:
      return {
        ...state,
        isLoadingProjectAccessAddEdit: false,
        associatedClientsWithProjects: [],
      }
    case ADD_EDIT_PROJECT_ACCESS_FAILED:
      return {
        ...state,
        isLoadingProjectAccessAddEdit: false,
        projectAccessAddEditError: action.payload,
      }
    case DELETE_CLIENT_ACCESS_PROJECT_SUCCESS:
      return {
        ...state,
        projectAccessList: action.payload,
        associatedClientsWithProjects: [],
      }
    case CLEAR_CLIENT_ACCESS_DATA:
      return initialState
    default:
      return state
  }
}
