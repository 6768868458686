import { Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography, Stack } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  initialLienWaiversTracker,
  lienWaiverChoices,
  lienWaiverColumns,
  lienWaiverOptions,
} from '../../../Description/trackers.description'
import { getProjectChoices } from '../../../Redux/actions/choices'
import {
  deleteLienWaiversTrackers,
  getLienWaiversTracker,
  saveLienWaiversTracker,
} from '../../../Redux/actions/reportTrackers'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTCurrencyTextField from '../../../Shared/DKTCurrencyTextField'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTInput from '../../../Shared/DKTInput'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTSelect from '../../../Shared/DKTSelect'
import { useStyles } from '../../../Styles/budgetSummary.style'
import { SA, publish } from '../../../Utils/constant'
import { equal, isEmptyString, keys, ternary } from '../../../Utils/javascript'
import { checkIfValidDate } from '../../../Utils/regex'
import DKTForm from '../../../Shared/DKTForm'

export const useTableBodyStyle = makeStyles(() => ({
  stateDropdown: {
    marginTop: '0 !important',
    height: '45px',
    '& .MuiSelect-select': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  inputBorder: {
    marginTop: '0 !important',
    '& .MuiInputBase-root': {
      marginTop: '0 !important',
      paddingLeft: '16px !important',
      border: 'none',
    },
  },
  dateError: {
    position: 'relative',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      left: 30,
      bottom: -3,
    },
  },
}))

const CustomBody = memo(
  ({ columnName, value, readOnly, rowIndex, handleChange, errors }) => {
    const classes = useTableBodyStyle()
    const { choices } = useSelector(({ projectChoices }) => projectChoices)
    switch (columnName) {
      case 'issuerType':
        return (
          <DKTSelect
            className={classes.stateDropdown}
            value={value}
            disabled={readOnly}
            onChange={(e) => handleChange(rowIndex, columnName, e.target.value)}
            options={choices?.issuerType || []}
            placeholder="Select"
            displayEmpty
            showLabel={false}
            sx={{ margin: 0 }}
          />
        )
      case 'conditionalAmount':
      case 'unconditionalAmount':
        return (
          <DKTCurrencyTextField
            className={classes.inputBorder}
            name={columnName}
            value={value}
            disabled={readOnly}
            currencySymbol="$"
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(event, value) =>
              handleChange(rowIndex, columnName, value)
            }
            showLabel={false}
          />
        )
      default:
        return (
          <DKTInput
            className={`${classes.inputBorder} ${classes.dateError}`}
            type={ternary(equal(columnName, 'issuerName'), 'text', 'date')}
            value={value}
            disabled={readOnly}
            onChange={(e) =>
              handleChange(
                rowIndex,
                columnName,
                e.target.value,
                ternary(equal(columnName, 'issuerName'), 'text', 'date'),
              )
            }
            placeholder="type text"
            showLabel={false}
            dateSx={{
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
              },
            }}
            error={
              equal(columnName, 'conditionalAmountDateReceived') ||
              equal(columnName, 'unconditionalAmountDateReceived')
                ? errors[rowIndex]?.[columnName]
                : ''
            }
          />
        )
    }
  },
)

const LienWaiver = ({ open, onClose, ownerContractor, uniqueCode }) => {
  const {
    lienWaiversTracker,
    isSavingLienWaiversTracker,
    isDeletingLienWaiversTrackers,
    isFetchingLienWaiversTracker,
  } = useSelector(({ reportTrackers }) => reportTrackers)
  const { reportId } = useParams()

  const [data, setData] = useState(
    lienWaiversTracker || [
      {
        ...initialLienWaiversTracker,
        report: reportId,
        ownerContractor,
        uniqueCode,
      },
    ],
  )
  const [errors, setErrors] = useState({})
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const recordsToRemove = useRef([])
  const setSelectedRows = useRef(() => {})
  const { slug: projectId } = useParams()
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)

  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+projectId]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission =
    isGlobalViewer ||
    isProjectViewer ||
    ternary(
      reportData?.isSecondLastReport,
      equal(reportData?.lastReportStatus, publish),
      !reportData?.isLastReport,
    )
  useEffect(() => {
    dispatch(getProjectChoices({ fieldName: lienWaiverChoices.join(',') }))
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getLienWaiversTracker({
        reportId,
        ocId: ownerContractor,
        projectId,
      }),
    )
  }, [dispatch, ownerContractor, uniqueCode])

  useEffect(() => {
    setData(
      lienWaiversTracker || [
        {
          ...initialLienWaiversTracker,
          report: reportId,
          ownerContractor,
          uniqueCode,
          uniqueId: new Date().valueOf(), // To delete local row
        },
      ],
    )
  }, [lienWaiversTracker])

  const closeDeleteNotification = () => setIsDeleteNotificationOpen(false)
  const openDeleteNotification = () => setIsDeleteNotificationOpen(true)

  const handleChange = useCallback((row, columnName, value, type) => {
    setIsUnSavedChanges(true)
    setData((prevData) => {
      const clonedData = [...prevData]
      if (clonedData[row]) {
        clonedData[row][columnName] = value
        if (equal(type, 'date') && value) {
          if (!checkIfValidDate(value)) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [row]: {
                ...prevErrors[row],
                ...{ [columnName]: 'Invalid date' },
              },
            }))
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [row]: {
                ...prevErrors[row],
                ...{ [columnName]: '' },
              },
            }))
          }
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [row]: {
              ...prevErrors[row],
              ...{ [columnName]: '' },
            },
          }))
        }
      }
      return clonedData
    })
  }, [])

  const addRow = () => {
    setData((prevData) => [
      ...prevData,
      {
        issuerName: '',
        issuerType: '',
        conditionalAmount: 0,
        conditionalAmountDateReceived: null,
        unconditionalAmount: 0,
        unconditionalAmountDateReceived: null,
        report: reportId,
        ownerContractor,
        uniqueCode,
        uniqueId: new Date().valueOf(), // To delete local row
      },
    ])
  }

  const customBodyRender = (columnName, readOnly, value, tableData) => {
    const { rowIndex } = tableData
    return (
      <DKTForm autoComplete="off">
        <CustomBody
          columnName={columnName}
          value={value}
          readOnly={readOnly || hasOnlyViewPermission}
          rowIndex={rowIndex}
          handleChange={handleChange}
          errors={errors}
        />
      </DKTForm>
    )
  }
  const columns = lienWaiverColumns.map((column) => ({
    ...column,
    options: {
      ...column.options,
      customBodyRender: (...props) =>
        customBodyRender(column.name, false, ...props),
    },
  }))

  const customToolbarSelect = (
    selectedRows,
    displayData,
    setSelectedTableRows,
  ) => {
    const selectedRecords = keys(selectedRows.lookup)
    const selectedRecordIds = selectedRecords.map(
      (rowToDelete) => data[rowToDelete]?.id ?? data[rowToDelete]?.uniqueId,
    )
    setSelectedRows.current = setSelectedTableRows
    const setRecordsToRemove = () => {
      recordsToRemove.current = selectedRecordIds
    }
    return (
      <Box>
        <IconButton
          onClick={() => {
            setRecordsToRemove()
            openDeleteNotification()
          }}
        >
          <Delete color="error" />
        </IconButton>
      </Box>
    )
  }

  const options = {
    ...lienWaiverOptions,
    customToolbarSelect,
    rowsPerPage: 5,
    selectableRows: ternary(
      hasOnlyViewPermission,
      'none',
      lienWaiverOptions.selectableRows || 'multiple',
    ),
  }

  const handleSave = () => {
    const filterData = data?.map((value) => ({
      ...value,
      report: value?.report || reportId,
      conditionalAmountDateReceived: ternary(
        isEmptyString(value?.conditionalAmountDateReceived),
        null,
        value?.conditionalAmountDateReceived,
      ),
      unconditionalAmountDateReceived: ternary(
        isEmptyString(value?.unconditionalAmountDateReceived),
        null,
        value?.unconditionalAmountDateReceived,
      ),
    }))
    setIsUnSavedChanges(false)
    dispatch(
      saveLienWaiversTracker(filterData, projectId, reportId, ownerContractor),
    )
  }

  const handleDelete = () => {
    dispatch(
      deleteLienWaiversTrackers({
        lienWaiversTrackersToDelete: recordsToRemove.current,
        reportId,
        projectId,
        ocId: ownerContractor,
        tableData: data,
      }),
    ).then(() => {
      setSelectedRows.current([])
      closeDeleteNotification()
    })
  }

  const deleteNotificationActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteNotification}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleDelete}
        disabled={isDeletingLienWaiversTrackers}
      >
        {ternary(
          isDeletingLienWaiversTrackers,
          <DKTCircularProgress color={theme.palette.gray.dark} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }

  const handleCloseLienWaiver = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }

  return (
    <DKTDialog
      title="Lien Waiver Tracker"
      open={open}
      showCloseButton
      handleClose={handleCloseLienWaiver}
      maxWidth="xl"
      className={classes.budgetSummaryTrackerModal}
    >
      {equal(isFetchingLienWaiversTracker, true) ? (
        <Stack alignItems="center" justifyContent="center">
          <DKTCircularProgress />
        </Stack>
      ) : equal(isFetchingLienWaiversTracker, 'FAILED') ? (
        <Stack alignItems="center" justifyContent="center">
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Lien Waiver Tracker data.
            Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <DKTMuiDataTable
            columns={columns}
            options={options}
            data={data || [{}]}
            className={ternary(
              data?.length,
              classes.summaryTableData,
              classNames(classes.summaryTableData, classes.summaryTableNoData),
            )}
          />
          <DKTButton
            variant="text"
            startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
            onClick={addRow}
            disabled={hasOnlyViewPermission}
          >
            Add row
          </DKTButton>
          <Box textAlign="right">
            <DKTButton
              onClick={handleSave}
              disabled={
                hasOnlyViewPermission ||
                isSavingLienWaiversTracker ||
                Object.values(errors)
                  ?.map((el) => Object.values(el)?.filter(Boolean))
                  ?.flat()?.length
              }
            >
              {ternary(
                isSavingLienWaiversTracker,
                <DKTCircularProgress color={theme.palette.gray.dark} />,
                'Save',
              )}
            </DKTButton>
          </Box>
        </>
      )}
      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={closeDeleteNotification}
        title="&nbsp;"
        actions={deleteNotificationActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      {!hasOnlyViewPermission && (
        <>
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={handleSave}
          />
          <DKTReactRouterPrompt
            isDirty={isUnSavedChanges}
            onSave={handleSave}
          />
        </>
      )}
    </DKTDialog>
  )
}

export default memo(LienWaiver)
