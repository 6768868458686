import {
  AppBar,
  Divider,
  IconButton,
  Link,
  Stack,
  Toolbar,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'

import { DangerIcon, UserIcon } from '../../Assets/SVGs'
import darkLogo from '../../Assets/darkLogo.png'
import { navbarLinks } from '../../Description/navbar.description'
import SupportFormModal from '../../Presentation/support/Index'
import DKTTooltip from '../../Shared/DKTTooltip'
import { SA, clientPortal } from '../../Utils/constant'
import { equal, ternary } from '../../Utils/javascript'

const useStyles = makeStyles(() => ({
  activeTab: {
    borderBottom: '5px solid #00acf0',
    color: 'white',
    minHeight: '58px',
  },
}))
const Navbar = () => {
  const [open, setOpen] = useState(false)
  const { systemAuthorization } = useSelector(({ auth }) => auth)
  const classes = useStyles()
  const location = useLocation()

  const handleOpenCloseModal = (status) => setOpen(status)

  return (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: '#062340' }}>
      <Toolbar>
        <Stack
          direction="row"
          sx={{ width: '100%' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Link
              component={NavLink}
              to="/"
              color="#fff"
              textTransform="uppercase"
              underline="none"
              mr={7}
            >
              <img
                src={darkLogo}
                alt="company-logo"
                loading="lazy"
                height="30px"
              />
            </Link>
            <Divider
              orientation="vertical"
              sx={{
                minHeight: '23px',
                borderRightWidth: '1px',
                backgroundColor: '#fff',
              }}
            />
            <Stack direction="row" ml={5} gap={5}>
              {navbarLinks.map(
                ({ text, url, isInProgress, isAdminOnly = false }, index) => {
                  const isActive =
                    equal(location?.pathname, url) ||
                    location?.pathname.includes(url.split('/')[1])
                  const navbarLink = isInProgress ? (
                    <DKTTooltip title="In Progress">
                      <Link
                        key={index}
                        component={NavLink}
                        onClick={(event) =>
                          isInProgress ? event.preventDefault() : {}
                        }
                        to={url}
                        color="#C6C6C6"
                        underline="none"
                        fontWeight="bold"
                        fontSize="14px"
                        style={
                          isInProgress
                            ? {
                                display: 'block',
                                width: '100%',
                                paddingRight: '16px',
                                textDecoration: 'none',
                                color: 'grey',
                              }
                            : {}
                        }
                      >
                        {text}
                      </Link>
                    </DKTTooltip>
                  ) : (
                    <Link
                      key={index}
                      component={NavLink}
                      onClick={(event) =>
                        isInProgress ? event.preventDefault() : {}
                      }
                      to={url}
                      color="#C6C6C6"
                      className={isActive && classes.activeTab}
                      underline="none"
                      fontWeight="bold"
                      fontSize="14px"
                      display="flex"
                      alignItems="center"
                      style={
                        isInProgress
                          ? {
                              display: 'block',
                              width: '100%',
                              paddingRight: '16px',
                              textDecoration: 'none',
                              color: 'grey',
                            }
                          : {}
                      }
                    >
                      {text}
                    </Link>
                  )
                  return ternary(
                    isAdminOnly,
                    ternary(
                      equal(systemAuthorization, SA.administrator) ||
                        equal(systemAuthorization, clientPortal),
                      navbarLink,
                      null,
                    ),
                    navbarLink,
                  )
                },
              )}
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            <IconButton
              onClick={() => handleOpenCloseModal(true)}
              sx={{ color: 'white', padding: 0 }}
            >
              <DangerIcon />
            </IconButton>
            <NavLink
              to="/admin/profile"
              style={{ lineHeight: 0, height: 'fit-content' }}
            >
              <UserIcon />
            </NavLink>
          </Stack>
        </Stack>
      </Toolbar>
      <SupportFormModal
        open={open}
        handleOpenCloseModal={handleOpenCloseModal}
      />
    </AppBar>
  )
}

export default memo(Navbar)
