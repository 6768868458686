// Fetch total count of tiles
export const FETCH_TOTAL_COUNTS_REQUESTED = 'FETCH_TOTAL_COUNTS_REQUESTED'
export const FETCH_TOTAL_COUNTS_SUCCESS = 'FETCH_TOTAL_COUNTS_SUCCESS'
export const FETCH_TOTAL_COUNTS_FAILED = 'FETCH_TOTAL_COUNTS_FAILED'

// Fetch projectsLists
export const FETCH_PROJECTS_LIST_REQUESTED = 'FETCH_PROJECTS_LIST_REQUESTED'
export const FETCH_PROJECTS_LIST_SUCCESS = 'FETCH_PROJECTS_LIST_SUCCESS'
export const FETCH_PROJECTS_LIST_FAILED = 'FETCH_PROJECTS_LIST_FAILED'

// addAndRemoveFavorite
export const ADD_REMOVE_FAVORITE_SUCCESS = 'ADD_REMOVE_FAVORITE_SUCCESS'
export const ADD_REMOVE_FAVORITE_FAILED = 'ADD_REMOVE_FAVORITE_FAILED'
export const ADD_REMOVE_FAVORITE_REQUEST = 'ADD_REMOVE_FAVORITE_REQUEST'

// filter based on dropDown fields
export const FETCH_FILTER_PROJECTS_REQUESTED = 'FETCH_FILTER_PROJECTS_REQUESTED'
export const FETCH_FILTER_PROJECTS_SUCCESS = 'FETCH_FILTER_PROJECTS_SUCCESS'
export const FETCH_FILTER_PROJECTS_FAILED = 'FETCH_FILTER_PROJECTS_FAILED'

// search project
export const FETCH_SEARCH_PROJECT_REQUESTED = 'FETCH_SEARCH_PROJECT_REQUESTED'
export const FETCH_SEARCH_PROJECT_SUCCESS = 'FETCH_SEARCH_PROJECT_SUCCESS'
export const FETCH_SEARCH_PROJECT_FAILED = 'FETCH_SEARCH_PROJECT_FAILED'

// onClickRowData
export const SET_PROJECT_DATA = 'SET_PROJECT_DATA'
export const CLEAR_PROJECT_DATA = 'CLEAR_PROJECT_DATA'

// onHoverMarker
export const SET_MARKER_HOVER = 'SET_MARKER_HOVER'
export const CLEAR_MARKER_HOVER = 'CLEAR_MARKER_HOVER'
