import { ternary } from '../../Utils/javascript'
import {
  FETCH_STATE_LIST_FAILED,
  FETCH_STATE_LIST_REQUESTED,
  FETCH_STATE_LIST_SUCCESS,
  US_STATE_CITY_FAILED,
  US_STATE_CITY_REQUESTED,
  US_STATE_CITY_SUCCESS,
} from '../constants/states'

const initialState = {
  state: [],
  isStateListLoading: false,
  stateListError: '',
  isUsStateCityLoading: false,
  usStateCityData: null,
  usStateCityError: null,
}

export const statesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // State list
    case FETCH_STATE_LIST_REQUESTED:
      return {
        ...state,
        isStateListLoading: true,
      }
    case FETCH_STATE_LIST_SUCCESS:
      return {
        ...state,
        state: ternary(
          action.payload?.length,
          action.payload?.sort((a, b) => (a?.value > b?.value ? 1 : -1)),
          [],
        ),
        isStateListLoading: false,
      }
    case FETCH_STATE_LIST_FAILED:
      return {
        ...state,
        isStateListLoading: false,
        stateListError: action.payload,
      }
    // Fetch us state and cities
    case US_STATE_CITY_REQUESTED:
      return {
        ...state,
        isUsStateCityLoading: true,
        usStateCityError: null,
      }
    case US_STATE_CITY_SUCCESS:
      return {
        ...state,
        isUsStateCityLoading: false,
        usStateCityData: action.payload,
      }
    case US_STATE_CITY_FAILED:
      return {
        ...state,
        isUsStateCityLoading: false,
        usStateCityError: action.payload,
      }
    default:
      return state
  }
}
