export const rfpMenuList = [
  {
    menu: 'RFPs',
    url: 'rfps',
    id: 'rfps',
  },
  {
    menu: 'Consultants',
    url: 'consultants',
    id: 'consultants',
  },
]

export const clientRFPColumn = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'projectName',
    label: 'Project Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'fullAddress',
    label: 'Address',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'finalSelectedConsultant',
    label: 'Final Selection',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'rfpNotificationDate',
    label: 'RFP Notification Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'rfpDueDate',
    label: 'RFP Due Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'bidsReceived',
    label: 'Bids Received',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'finalSelectedConsultantOtherNonAcreiq',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
]

export const consultantRFPColumn = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'projectName',
    label: 'Project Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'fullAddress',
    label: 'Address',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'client',
    label: 'Client',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'rfpDueDate',
    label: 'RFP Due Date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'daysUntilDue',
    label: 'Days Until Due',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'rfpReturnedDate',
    label: 'RFP Returned',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'awardStatus',
    label: 'Award Status',
    options: {
      filter: true,
      sort: true,
    },
  },
]

export const rfpSortFields = {
  sortByOptions: {
    type: 'select',
    isDynamicOptions: true,
    placeholder: 'Sort By',
    displayEmpty: true,
    options: [],
  },
}

export const formFields = {
  searchProject: {
    search: {
      type: 'text',
      name: 'Search',
    },
  },
  selectSortFilter: {
    state: {
      type: 'select',
      isDynamicOptions: true,
      placeholder: 'Sort By',
      displayEmpty: true,
    },
  },
}

export const options = {
  responsive: 'standard',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
  selectableRows: 'none',
  pagination: false,
}

export const columns = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
      sort: false,
    },
  },
  {
    name: 'isFavorite',
    label: ' ',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'companyName',
    label: 'Company Name',
    options: {
      filter: true,
      sort: true,
      sortDirection: 'asc',
    },
  },
  {
    name: 'city',
    label: 'City',
    options: {
      filter: true,
      sort: true,
      sortDirection: 'asc',
    },
  },
  {
    name: 'state',
    label: 'State',
    options: {
      filter: true,
      sort: true,
      sortDirection: 'asc',
    },
  },
]
