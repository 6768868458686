import { Box, Grid, Radio, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CircledPlusIcon } from '../../Assets/SVGs'
import { actionTitles } from '../../Description/admin.description'
import { companyFieldList } from '../../Description/companyPersonnel.description'
import {
  columns,
  options,
  personnelFormFields,
} from '../../Description/projectAssignments.description'
import useForm from '../../Hooks/useForm'
import { InnerLayout } from '../../Layout/admin'
import { getProjectChoices } from '../../Redux/actions/choices'
import { fetchCompanyPersonnel } from '../../Redux/actions/companyPersonnel'
import {
  clearProjectAssignments,
  deleteProjectAssignments,
  fetchProjectAssignments,
  saveProjectAssignments,
  updateProjectPermissions,
} from '../../Redux/actions/projectAssignments'
import DKTButton from '../../Shared/DKTButton'
import DKTDialog from '../../Shared/DKTDialog'
import DKTInput from '../../Shared/DKTInput'
import DKTMuiDataTable from '../../Shared/DKTMuiDataTable'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import DKTSelect from '../../Shared/DKTSelect'
import { useStyles } from '../../Styles/projectAssignments.style'
import {
  checkIncludes,
  entries,
  equal,
  length,
  ternary,
} from '../../Utils/javascript'
import CreateCompanyPersonnel from './companyPersonnel/CreateCompanyPersonnel'
import {
  SA,
  administrator,
  clientPortal,
  globalEditor,
  globalViewer,
  projectLevel,
} from '../../Utils/constant'

const getCompanyListOptions = (companyPersonnelList) =>
  companyPersonnelList
    .filter((data) => data.isCompanyPersonnel)
    .map(({ id, firstName, lastName }) => ({
      value: id.toString(),
      label: `${lastName} ${firstName}`,
    }))

const ModalBody = ({ message }) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={classes.deleteNotificationContent}>
          <Typography variant="h5">{message}</Typography>
          <Typography variant="body1">
            You can&apos;t undo this action
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

const ProjectAssignments = () => {
  const classes = useStyles()
  const { values, errors, handleChange, setFieldValue } =
    useForm(companyFieldList)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false)

  const setSelectedRows = useRef(() => {})
  const selectedProjectAssignments = useRef([])
  const discardedPersonnelName = useRef('')

  const dispatch = useDispatch()
  const { companyPersonnelList } = useSelector(
    (state) => state.companyPersonnel,
  )
  const { choices } = useSelector(({ projectChoices }) => projectChoices)
  const { projectAssignments, hasUnsavedChanges, isSavingProjectAssignments } =
    useSelector(({ projectAssignments }) => projectAssignments)
  const { portalType } = useSelector(({ auth }) => auth)

  useEffect(() => {
    dispatch(fetchCompanyPersonnel())

    return () => dispatch(clearProjectAssignments())
  }, [dispatch])

  useEffect(() => {
    if (!choices.systemAuthorization)
      dispatch(getProjectChoices({ fieldName: 'systemAuthorization' }))
  }, [dispatch, choices.systemAuthorization])

  useEffect(() => {
    if (values.name)
      dispatch(fetchProjectAssignments({ personnel: values.name }))
  }, [dispatch, values.name])

  useEffect(() => {
    if (
      checkIncludes(values.systemAuthorization, [
        administrator,
        globalEditor,
        globalViewer,
      ])
    ) {
      dispatch(
        updateProjectPermissions({
          permission: ternary(
            equal(portalType, clientPortal) &&
              equal(values.systemAuthorization, administrator),
            'viewer',
            ternary(
              equal(values.systemAuthorization, globalViewer),
              'viewer',
              'editor',
            ),
          ),
          systemAuthorization: values.systemAuthorization,
          allowAccess: true,
          updateAll: true,
        }),
      )
      return
    }

    dispatch(
      updateProjectPermissions({
        systemAuthorization: values.systemAuthorization,
        updateAll: true,
      }),
    )
  }, [values.systemAuthorization, dispatch])

  useEffect(() => {
    setFieldValue(
      'systemAuthorization',
      projectAssignments[0]?.systemAuthorization,
    )
  }, [setFieldValue, projectAssignments, values.systemAuthorization])

  const closeDeleteModal = () => setIsDeleteModalOpen(false)

  const handleSave = () => {
    dispatch(saveProjectAssignments({ personnel: values.name }))
  }

  const actions = () => (
    <Stack direction="row" gap={2}>
      <DKTButton
        onClick={handleSave}
        disabled={!hasUnsavedChanges || isSavingProjectAssignments}
      >
        {ternary(isSavingProjectAssignments, 'Saving...', 'Save')}
      </DKTButton>
    </Stack>
  )

  const customBodyRender = (value, { columnData, rowData }) => {
    const [projectId] = rowData

    const handlePermissionChange = (value) => {
      dispatch(
        updateProjectPermissions({
          allowAccess: !value,
          permission: columnData.name,
          projectId,
        }),
      )
    }

    return (
      <Radio
        value={columnData.name}
        checked={value}
        name={projectId.toString()}
        onClick={() => handlePermissionChange(value)}
        disabled={!equal(values.systemAuthorization, projectLevel)}
      />
    )
  }

  const handleDeleteTableRow = () => {
    dispatch(
      deleteProjectAssignments({
        selectedProjectAssignments: selectedProjectAssignments.current,
        personnel: values.name,
      }),
    )
    closeDeleteModal()
    setSelectedRows.current([])
  }

  const deleteModalActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteModal}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteTableRow}>
        Yes
      </DKTButton>
    </>
  )

  const openUnsavedChangesModal = () => setIsUnsavedChangesModalOpen(true)
  const closeUnsavedChangesModal = () => setIsUnsavedChangesModalOpen(false)

  const discardUnsavedChanges = () => {
    const eventWithTarget = {
      target: { name: 'name', value: discardedPersonnelName.current },
    }
    handleChange(eventWithTarget)
    discardedPersonnelName.current = ''
    closeUnsavedChangesModal()
  }

  const unsavedChangesModalActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeUnsavedChangesModal}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={discardUnsavedChanges}>
        Yes
      </DKTButton>
    </>
  )

  const showUnsavedChangesModal = (e) => {
    openUnsavedChangesModal()
    discardedPersonnelName.current = e.target.value
  }

  const customDateRender = (value) =>
    ternary(value, moment(value).format('MM/DD/YY'), '-')

  const tableColumns = columns
    .filter(
      (column) =>
        !(equal(portalType, clientPortal) && equal(column.name, 'viewer')),
    )
    .map((column) =>
      ternary(
        equal(portalType, clientPortal) && equal(column.name, 'editor'),
        {
          ...column,
          name: 'viewer',
          label: 'Access',
          options: {
            ...column.options,
            customBodyRender,
          },
        },
        ternary(
          checkIncludes(column.name, ['editor', 'viewer', 'completionDate']),
          {
            ...column,
            options: {
              ...column.options,
              customBodyRender: ternary(
                equal(column.name, 'completionDate'),
                customDateRender,
                customBodyRender,
              ),
            },
          },
          column,
        ),
      ),
    )

  const tableOptions = {
    ...options,
  }

  const addNewPersonnel = () => setIsModalOpen(true)

  const renderFormFields = (formFields) => (
    <Grid container spacing={2}>
      {entries(formFields)?.map(
        ([name, { isRequired, ...formField }], index) => {
          const FormControl = ternary(
            equal(formField.type, 'select'),
            DKTSelect,
            DKTInput,
          )
          return (
            <Grid key={index} item xs={formField.xs ?? 12}>
              <FormControl
                {...{
                  ...formField,
                  ...ternary(
                    equal(name, 'name'),
                    { options: getCompanyListOptions(companyPersonnelList) },
                    ternary(
                      equal(name, 'systemAuthorization'),
                      {
                        options:
                          choices.systemAuthorization?.filter(({ value }) =>
                            equal(portalType, clientPortal)
                              ? [SA.administrator, SA.projectLevel].includes(
                                  value,
                                )
                              : true,
                          ) || [],
                        disabled:
                          equal(length(projectAssignments), 0) || !values?.name,
                      },
                      {},
                    ),
                  ),
                }}
                id={name}
                name={name}
                value={(values && values[name]) || ''}
                onChange={ternary(
                  equal(name, 'name') && hasUnsavedChanges && values.name,
                  showUnsavedChangesModal,
                  handleChange,
                )}
                error={errors[name]}
              />
              {equal(name, 'name') && (
                <DKTButton
                  variant="text"
                  color="primary"
                  sx={{ textTransform: 'initial' }}
                  startIcon={<CircledPlusIcon />}
                  onClick={addNewPersonnel}
                >
                  <Typography>Add New Personnel</Typography>
                </DKTButton>
              )}
            </Grid>
          )
        },
      )}
    </Grid>
  )

  const handleClose = () => setIsModalOpen(false)

  return (
    <InnerLayout
      actionTitle={actionTitles.projectAssignments}
      contentTitle="PROJECT ASSIGNMENTS"
      actions={actions}
    >
      {renderFormFields(personnelFormFields)}

      {values.name && (
        <Box className={classes.projectAssignmentTable}>
          <DKTMuiDataTable
            data={projectAssignments}
            options={tableOptions}
            columns={tableColumns}
            className={classes.projectAssignmentTable}
          />
        </Box>
      )}
      <CreateCompanyPersonnel open={isModalOpen} handleClose={handleClose} />
      <DKTDialog
        open={isDeleteModalOpen}
        handleClose={closeDeleteModal}
        title="&nbsp;"
        actions={deleteModalActions}
        maxWidth="xs"
      >
        <ModalBody message="Are you sure you want to delete?" />
      </DKTDialog>
      <DKTDialog
        open={isUnsavedChangesModalOpen}
        handleClose={closeUnsavedChangesModal}
        title="&nbsp;"
        actions={unsavedChangesModalActions}
        maxWidth="xs"
      >
        <ModalBody message="Are you sure you want to discard the changes?" />
      </DKTDialog>
      <DKTReactRouterPrompt isDirty={hasUnsavedChanges} onSave={handleSave} />
    </InnerLayout>
  )
}

export default ProjectAssignments
