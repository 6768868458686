export const formFields = {
  workDescription: {
    type: 'text',
    label: 'Description of Work',
    isRequired: true,
  },
}

export const selectedRowFormFields = {
  list: {
    type: 'checkbox',
  },
}

export const columns = ['Description of work']

export const constructionSummaryFormateFeatures = [
  'General Requirements',
  'Existing Conditions',
  'Concrete',
  'Masonry',
  'Metals',
  'Wood & Plastics',
  'Thermal & Moisture',
  'Openings',
  'Finishes',
  'Specialties',
  'Equipment',
  'Furnishings',
  'Special Construction',
  'Conveying Equipment',
  'Fire Suppression',
  'Plumbing',
  'Mechanical',
  'Electrical',
  'Earthwork',
  'Exterior Improvements',
]
