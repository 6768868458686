export const formFields = {
  summary: {
    type: 'text',
    label: 'Summary',
    isRequired: true,
  },
  pageName: {
    type: 'text',
    label: 'Page Name',
    isRequired: true,
  },
  issueType: {
    type: 'select',
    label: 'Issue Type',
    displayEmpty: true,
    isDynamicOptions: true,
    isRequired: true,
    placeholder: 'Select',
  },
}
