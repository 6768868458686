import { Box, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { cooFormFields } from '../../../Description/permitsEntitlements.description'
import useForm from '../../../Hooks/useForm'
import {
  deleteCertificateOfOccupancy,
  saveCertificateOfOccupancy,
} from '../../../Redux/actions/permitsEntitlements'
import { getProjectList } from '../../../Redux/actions/projects'
import { getStateList } from '../../../Redux/actions/states'

import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import { useStyles } from '../../../Styles/constructionScheduleReport.style'
import {
  entries,
  equal,
  isEmptyString,
  keys,
  ternary,
} from '../../../Utils/javascript'
import { renderFormFields } from '../../../Utils/renderFormFields'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import DKTForm from '../../../Shared/DKTForm'

const AddBuilding = ({
  open = false,
  buildingToEdit,
  onClose,
  ocId,
  hasOnlyViewPermission,
  onSave = () => {},
}) => {
  const classes = useStyles()
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)

  const { slug: projectId, reportId } = useParams()
  const theme = useTheme()
  const { currentProject } = useSelector(({ projects }) => projects)

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    setErrors,
    setValues,
  } = useForm(cooFormFields)
  const {
    isSavingCertificateOfOccupancy,
    isDeletingCertificateOfOccupancy,
    certificateOfOccupancyTrackerError,
  } = useSelector(({ permitsEntitlements }) => permitsEntitlements)
  const { state } = useSelector(({ states }) => states)
  const stateAbbreviations = useMemo(
    () => state?.map(({ value }) => ({ label: value, value })) || [],
    [state],
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getStateList())
    dispatch(getProjectList(projectId))
  }, [dispatch])

  useEffect(() => {
    resetForm()
    if (buildingToEdit) {
      entries(buildingToEdit).forEach(([fieldName, fieldValue]) => {
        setFieldValue(fieldName, fieldValue)
      })
    } else if (currentProject) {
      const { address, city, state, zipCode } = currentProject
      setValues((prevValues) => ({
        ...prevValues,
        address,
        city,
        state,
        zipCode,
      }))
    }
  }, [buildingToEdit, open, currentProject])

  // Set errors on save
  useEffect(() => {
    if (certificateOfOccupancyTrackerError)
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...certificateOfOccupancyTrackerError,
      }))
  }, [certificateOfOccupancyTrackerError, setErrors])

  // set isUnsavedData as true
  useEffect(() => {
    if (keys(buildingToEdit).length) {
      setIsUnSavedChanges(
        ternary(
          JSON.stringify(values) !== JSON.stringify(buildingToEdit),
          true,
          false,
        ),
      )
    } else if (currentProject) {
      const { address, city, state, zipCode } = currentProject
      setIsUnSavedChanges(
        ternary(
          JSON.stringify(values) !==
            JSON.stringify({ address, city, state, zipCode }),
          true,
          false,
        ),
      )
    }
  }, [buildingToEdit, values, currentProject])
  const handleDeleteBuilding = () => {
    const onSuccess = () => {
      setIsDeleteNotificationOpen(false)
      onClose()
    }
    dispatch(
      deleteCertificateOfOccupancy({
        buildingToDelete: buildingToEdit?.id,
        onSuccess,
        reportId,
        ocId,
        projectId,
      }),
    )
  }

  const handleSave = () => {
    const isFormValid = handleSubmit()
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
      return
    }
    setIsUnSavedChanges(false)
    const payload = {
      ...values,
      certificateOfOccupancyIssuedDate: ternary(
        isEmptyString(values?.certificateOfOccupancyIssuedDate),
        null,
        values?.certificateOfOccupancyIssuedDate,
      ),
      tcoDate: ternary(isEmptyString(values?.tcoDate), null, values?.tcoDate),
    }
    dispatch(
      saveCertificateOfOccupancy({
        payload,
        reportId,
        projectId,
        ocId,
        certificateOfOccupancyToEdit: buildingToEdit?.id,
        onSuccess: () => {
          onSave()
          onClose()
        },
      }),
    )
  }

  const actions = (
    <>
      {buildingToEdit && (
        <DKTButton
          variant="outlined"
          color="error"
          onClick={() => setIsDeleteNotificationOpen(true)}
          disabled={hasOnlyViewPermission}
        >
          Delete Cert. of Occupancy
        </DKTButton>
      )}
      <DKTButton
        onClick={handleSave}
        disabled={hasOnlyViewPermission || isSavingCertificateOfOccupancy}
        sx={ternary(!buildingToEdit, { marginLeft: 'auto' }, {})}
      >
        {ternary(
          isSavingCertificateOfOccupancy,
          <DKTCircularProgress color={theme.palette.gray.dark} />,
          'Save',
        )}
      </DKTButton>
    </>
  )
  const customFormControlFields = ({ name, formControl }) => ({
    disabled: hasOnlyViewPermission || formControl.disabled,
    ...ternary(equal(name, 'state'), { options: stateAbbreviations }, {}),
  })

  const deleteNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => setIsDeleteNotificationOpen(false)}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        disabled={isDeletingCertificateOfOccupancy}
        onClick={handleDeleteBuilding}
      >
        {ternary(
          isDeletingCertificateOfOccupancy,
          <DKTCircularProgress color={theme.palette.gray.dark} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }
  const handleCloseAddBuilding = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }

  return (
    <DKTDialog
      open={open}
      handleClose={handleCloseAddBuilding}
      title="Add or Edit Cert. of Occupancy"
      actions={actions}
    >
      <DKTForm autoComplete="off">
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
          {renderFormFields({
            values,
            errors,
            formFields: cooFormFields,
            handleChange,
            customFormControlFields,
          })}
        </Grid>
      </DKTForm>
      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={() => setIsDeleteNotificationOpen(false)}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Box className={classes.deleteNotificationContent}>
          <Typography variant="h5">Are you sure you want to delete?</Typography>
          <Typography variant="body1">
            You can&apos;t undo this action
          </Typography>
        </Box>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      {!hasOnlyViewPermission && (
        <>
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={handleSave}
          />
          <DKTReactRouterPrompt
            isDirty={isUnSavedChanges}
            onSave={handleSave}
          />
        </>
      )}
    </DKTDialog>
  )
}

export default AddBuilding
