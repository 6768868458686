import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import DKTInfoBox from './DKTInfoBox'

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': {
      width: '100%',
      padding: '32px 32px',
      '& .MuiDialog-paperWidthXs': {
        maxWidth: 414,
      },
      '& .MuiDialogTitle-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
      },
      '& .MuiDialogContent-root': {
        padding: '20px 0 27px !important',
        overflowY: 'visible',
      },
      '& .MuiDialogActions-root': {
        padding: 0,
        justifyContent: 'space-between',
        '& .MuiButton-root': {
          borderRadius: 0,
        },
      },
    },
  },
}))

const DKTDialog = ({
  open,
  handleClose,
  title,
  children,
  actions,
  maxWidth = 'xs',
  showCloseButton = true,
  className,
  infoIcon = false,
  infoText,
  titleSx = null,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll="body"
      maxWidth={maxWidth}
      className={` ${className} ${classes.modal}`}
      {...rest}
    >
      <DialogTitle sx={titleSx}>
        {title}
        {infoIcon && (
          <DKTInfoBox
            title={infoText}
            slotProps={{
              popper: {
                sx: {
                  '& .MuiTooltip-tooltip': { backgroundColor: '#062340' },
                  '& .MuiTooltip-arrow:before': { backgroundColor: '#062340' },
                },
              },
            }}
            sx={{ mr: 'auto', ml: 1, cursor: 'pointer' }}
          />
        )}
        {showCloseButton && (
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  )
}

export default DKTDialog
