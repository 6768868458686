import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Delete } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import DKTButton from '../../../Shared/DKTButton'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import {
  entries,
  equal,
  isArray,
  keys,
  ternary,
} from '../../../Utils/javascript'
import DKTDialog from '../../../Shared/DKTDialog'
import { OuterLayout } from '../../../Layout/project'
import DKTAccordion from '../../../Shared/DKTAccordion'
import {
  accordionData,
  formFields,
  columns,
} from '../../../Description/projectSetting.description'
import useForm from '../../../Hooks/useForm'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTInput from '../../../Shared/DKTInput'
import {
  createAndUpdateProjectTeam,
  deleteProjectTeam,
  fetchReportTier,
  getProjectTeam,
  setReportTier,
} from '../../../Redux/actions/projectSetting'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import { fetchCompanyPersonnel } from '../../../Redux/actions/companyPersonnel'
import { getProjectChoices } from '../../../Redux/actions/choices'
import { getProjectList } from '../../../Redux/actions/projects'
import { useStyles } from '../../../Styles/projectSetting.style'
import { SA, clientPortal } from '../../../Utils/constant'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import ReportTier from './ReportTier'
import ProjectSettingLimitation from './ProjectSettingLimitation'

const ProjectSetting = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { slug } = useParams()

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setErrors,
    setValues,
    setFieldValue,
  } = useForm({
    ...formFields.reportTier,
    ...formFields.addTeamMember,
  })

  const [
    deleteProjectTeamNotificationOpen,
    setOpenDeleteProjectTeamNotification,
  ] = React.useState(false)
  const [openAddTeamMemberPopup, setOpenAddTeamMemberPopup] =
    React.useState(false)
  const [dynamicOptions, setDynamicOptions] = React.useState({})
  const [projectTeamArray, setProjectTeamArray] = React.useState([])
  const [selectedTeamId, setSelectedTeamId] = React.useState(null)
  const [isUnsavedData, setIsUnsavedData] = React.useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = React.useState(false)
  const [isDirty, setIsDirty] = React.useState(false)

  const {
    projectTeamList,
    isCreateAndUpdateProjectTeamLoading,
    isReportTierLoading,
    activeReportTier,
    isSetReportTierLoading,
  } = useSelector(({ projectSetting }) => projectSetting)
  const { choices } = useSelector(({ projectChoices }) => projectChoices)
  const { currentProject } = useSelector(({ projects }) => projects)
  const { companyPersonnelList } = useSelector(
    (state) => state.companyPersonnel,
  )
  const { systemAuthorization, portalType, projects } = useSelector(
    ({ auth }) => auth,
  )
  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+slug]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const isClientCompany = equal(portalType, clientPortal)
  const hasOnlyViewPermission =
    isGlobalViewer || isProjectViewer || isClientCompany

  const projectTeamToRemove = useRef(null)
  const setSelectedRows = useRef(() => {})

  useEffect(() => {
    dispatch(getProjectTeam(slug))
    dispatch(getProjectList(slug))
    dispatch(fetchReportTier(slug))
  }, [dispatch, slug])

  useEffect(() => {
    if (!companyPersonnelList?.length) {
      dispatch(fetchCompanyPersonnel())
    }
  }, [dispatch, companyPersonnelList?.length])

  useEffect(() => {
    if (!choices?.assignedRole) {
      dispatch(getProjectChoices({ fieldName: 'assignedRole,reportTier' }))
    }
  }, [dispatch, choices?.assignedRole])

  useEffect(() => {
    const getCompanyListOptions = (companyPersonnelList) =>
      companyPersonnelList.map(({ id, firstName, lastName }) => ({
        value: id.toString(),
        label: `${lastName} ${firstName}`,
      }))
    const companyPersonnel = getCompanyListOptions(companyPersonnelList)
    setDynamicOptions({
      ...dynamicOptions,
      companyPersonnel,
      assignedRole: choices?.assignedRole,
      tier: choices?.reportTier,
    })
  }, [dispatch, companyPersonnelList, choices])

  useEffect(() => {
    const teamArray = []
    projectTeamList?.forEach((team) => {
      const { assignedRole, companyPersonnel, id } = team
      const { firstName, lastName, email, workPhoneNumber, cellPhone } =
        companyPersonnel
      const assignedRoleLabel = dynamicOptions.assignedRole?.find((role) =>
        equal(role?.value, assignedRole),
      )
      teamArray.push({
        id,
        firstName,
        lastName,
        email,
        workPhoneNumber,
        cellPhone,
        assignedRole: assignedRoleLabel?.label,
      })
    })
    setProjectTeamArray(teamArray)
  }, [projectTeamList, dynamicOptions, isCreateAndUpdateProjectTeamLoading])

  useEffect(() => {
    if (activeReportTier) {
      setFieldValue('tier', activeReportTier?.reportTier)
    }
  }, [activeReportTier])

  useEffect(() => {
    if (activeReportTier) {
      if (!equal(values?.tier, activeReportTier?.reportTier)) {
        setIsUnsavedData(true)
      } else {
        setIsUnsavedData(false)
      }
    }
  }, [values?.tier, activeReportTier])

  useEffect(() => {
    if (selectedTeamId) {
      const populate = projectTeamList?.find((list) =>
        equal(list?.id, selectedTeamId),
      )
      const { assignedRole, companyPersonnel } = populate
      if (
        !equal(values?.assignedRole, assignedRole) ||
        !equal(values?.companyPersonnel, `${companyPersonnel?.id}`)
      ) {
        setIsDirty(true)
      } else {
        setIsDirty(false)
      }
    } else if (values?.assignedRole || values?.companyPersonnel) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }, [values?.assignedRole, values?.companyPersonnel, selectedTeamId])

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            defaultValue,
            options,
            isConfirmPassword,
            dependsOn,
            ...formField
          },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <Grid
            key={index}
            item
            xs={formField.xs ?? 12}
            lg={formField.lg ?? 12}
          >
            <FormControl
              {...formField}
              options={ternary(
                isDynamicOptions,
                dynamicOptions[name] || [],
                options,
              )}
              isRequired={isRequired}
              id={name}
              name={name}
              value={(values && values[name]) || defaultValue || ''}
              onChange={handleChange}
              error={errors[name]}
              disabled={hasOnlyViewPermission || formField.disabled}
            />
          </Grid>
        )
      },
    )

  const renderFormFields = (formFields) => (
    <Grid container width="100%" spacing={2}>
      {isArray(formFields)
        ? formFields.map((formField) =>
            mapFormFields({
              formFields: formField.data,
              makeFieldNameUnique: true,
              id: formField.id,
            }),
          )
        : mapFormFields({ formFields })}
    </Grid>
  )

  const handleSetReportTier = () => {
    const isFormValid = handleSubmit({ flag: formFields.reportTier })
    if (isFormValid) {
      const body = {
        reportTier: values?.tier,
        project: slug,
      }
      dispatch(setReportTier(body, slug))
      setIsUnsavedData(false)
    }
  }

  const handleClose = () => {
    setOpenDeleteProjectTeamNotification(false)
    setOpenAddTeamMemberPopup(false)
    const cloneValues = { ...values }
    keys(formFields.addTeamMember).forEach((name) => {
      delete cloneValues[name]
    })
    setValues(cloneValues)
    setErrors({})
  }

  const handleDeleteTableRow = () => {
    const body = {
      projectTeamMember: projectTeamToRemove?.current,
    }
    dispatch(deleteProjectTeam(body, slug))
    setOpenDeleteProjectTeamNotification(false)
    setSelectedRows.current([])
  }

  const handleSaveAddEditProjectTeam = () => {
    const { assignedRole, companyPersonnel } = values
    if (selectedTeamId) {
      const isFormValid = handleSubmit({ flag: formFields.addTeamMember })
      const body = {
        assignedRole,
        companyPersonnel,
        project: slug,
      }
      if (isFormValid) {
        setIsDirty(false)
        handleClose()
        dispatch(createAndUpdateProjectTeam(body, selectedTeamId))
        setSelectedTeamId(null)
      } else {
        dispatch(updateOnSaveStatus({ cancel: true }))
      }
    } else {
      const body = {
        assignedRole,
        companyPersonnel,
        project: slug,
      }
      const isFormValid = handleSubmit({ flag: formFields.addTeamMember })
      if (isFormValid) {
        setIsDirty(false)
        handleClose()
        dispatch(createAndUpdateProjectTeam(body))
        setSelectedTeamId(null)
      } else {
        dispatch(updateOnSaveStatus({ cancel: true }))
      }
    }
  }

  const handleEditProjectTeam = (teamId) => {
    if (hasOnlyViewPermission) return
    setOpenAddTeamMemberPopup(true)
    setSelectedTeamId(teamId)
    const populate = projectTeamList?.find((list) => equal(list?.id, teamId))
    const { assignedRole, companyPersonnel } = populate
    setValues({
      ...values,
      ...{ companyPersonnel: `${companyPersonnel?.id}`, assignedRole },
    })
  }

  const customToolbarSelect = useCallback(
    (selectedRows, displayData, setSelectedTableRows) => {
      const selectedProjectTeam = keys(selectedRows.lookup)
      const selectedProjectTeamIds = selectedProjectTeam.map(
        (rowToDelete) => projectTeamList[rowToDelete]?.id,
      )
      setSelectedRows.current = setSelectedTableRows
      const setProjectTeamToRemove = () => {
        projectTeamToRemove.current = selectedProjectTeamIds
      }
      return (
        <Box>
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => {
              setProjectTeamToRemove()
              setOpenDeleteProjectTeamNotification(true)
            }}
          >
            <Delete color="error" />
          </IconButton>
        </Box>
      )
    },
    [projectTeamList],
  )
  const tableOptions = {
    customToolbarSelect,
    responsive: 'standard',
    filter: false,
    selectableRows: hasOnlyViewPermission ? 'none' : 'multiple',
  }

  const customBodyRender = useCallback(
    (value, tableMeta) => (
      <Typography
        className={
          hasOnlyViewPermission
            ? classes.assignedRoleDisabled
            : classes.assignedRole
        }
        onClick={() => handleEditProjectTeam(tableMeta.rowData[0])}
        sx={ternary(hasOnlyViewPermission, { color: '#000000de' }, {})}
      >
        {value}
      </Typography>
    ),
    [handleEditProjectTeam, classes],
  )

  const tableColumns = columns.map((team) =>
    ternary(
      equal(team.name, 'assignedRole'),
      {
        ...team,
        options: {
          ...team.options,
          customBodyRender,
        },
      },
      team,
    ),
  )

  const handleAddTeamMember = () => {
    setOpenAddTeamMemberPopup(true)
    setSelectedTeamId(null)
    const cloneValues = { ...values }
    keys(formFields.addTeamMember).forEach((name) => {
      delete cloneValues[name]
    })
    setValues(cloneValues)
  }

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteTableRow}>
        Yes
      </DKTButton>
    </>
  )
  const addTeamMemberPopupAction = (
    <DKTButton
      variant="contained"
      disableElevation
      onClick={handleSaveAddEditProjectTeam}
      sx={{ marginLeft: 'auto', marginRight: '16px' }}
    >
      {ternary(
        isCreateAndUpdateProjectTeamLoading,
        <DKTCircularProgress color="white" />,
        ternary(selectedTeamId, 'Save', 'Add'),
      )}
    </DKTButton>
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsDirty(false)
    setIsUnSavedWarningOpen(false)
    handleClose()
  }
  const handleCloseAddPhase = () => {
    if (isDirty) {
      setIsUnSavedWarningOpen(true)
      return
    }
    handleClose()
  }
  return (
    <OuterLayout
      title={currentProject?.projectName || 'New Project'}
      isShowMenu
    >
      <Box p={3} sx={{ marginTop: '199px' }}>
        {!equal(portalType, clientPortal) && (
          <ReportTier
            renderFormFields={renderFormFields}
            isUnsavedData={isUnsavedData}
            handleSetReportTier={handleSetReportTier}
          />
        )}
        {!equal(portalType, clientPortal) && (
          <Stack direction="row" gap={2}>
            <DKTButton
              sx={{ mb: 2 }}
              disabled={
                hasOnlyViewPermission ||
                isReportTierLoading ||
                isSetReportTierLoading
              }
              variant="outlined"
              onClick={handleSetReportTier}
            >
              {ternary(isSetReportTierLoading, 'Saving...', 'Save')}
            </DKTButton>
          </Stack>
        )}
        <div className={classes.projectSettingOuter}>
          <DKTAccordion
            className={classes.projectSettingAccordion}
            accordionTitle={accordionData.projectTeam.accordionTitle}
            idTag={accordionData.projectTeam.idTag}
          >
            <DKTMuiDataTable
              data={projectTeamArray}
              options={tableOptions}
              columns={tableColumns}
              className={classes.settingTable}
            />
            <DKTDialog
              open={deleteProjectTeamNotificationOpen}
              handleClose={handleClose}
              title="&nbsp;"
              actions={deleteNotificationAction}
              maxWidth="xs"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.notificationContent}>
                    <Typography variant="h5">
                      Are you sure you want to delete?
                    </Typography>
                    <Typography variant="body1">
                      You can&apos;t undo this action
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </DKTDialog>
            <DKTDialog
              open={openAddTeamMemberPopup}
              handleClose={handleCloseAddPhase}
              title="Add Team Member"
              actions={addTeamMemberPopupAction}
              maxWidth="xs"
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box>{renderFormFields(formFields.addTeamMember)}</Box>
                </Grid>
              </Grid>
            </DKTDialog>
            {!equal(portalType, clientPortal) && (
              <DKTButton
                variant="text"
                onClick={handleAddTeamMember}
                startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
                disabled={hasOnlyViewPermission}
              >
                Add Team Member
              </DKTButton>
            )}
          </DKTAccordion>
          <DKTAccordion
            className={classes.projectSettingAccordion}
            accordionTitle={accordionData.limitations.accordionTitle}
            idTag={accordionData.limitations.idTag}
          >
            <ProjectSettingLimitation />
          </DKTAccordion>
        </div>
      </Box>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnSavedWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSaveAddEditProjectTeam}
      />
      <DKTReactRouterPrompt
        isDirty={isDirty}
        onSave={handleSaveAddEditProjectTeam}
      />
    </OuterLayout>
  )
}

export default ProjectSetting
