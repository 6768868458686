import { Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accordionData,
  formFields,
} from '../../Description/editProfile.description'
import useForm from '../../Hooks/useForm'
import { InnerLayout } from '../../Layout/profile'
import { userLogout } from '../../Redux/actions/auth'
import { getProjectChoices } from '../../Redux/actions/choices'
import { updateOnSaveStatus } from '../../Redux/actions/confirmation'
import { getStateList } from '../../Redux/actions/states'
import {
  getUserProfile,
  updateUserPassword,
  updateUserPasswordFailed,
  updateUserProfile,
} from '../../Redux/actions/userProfile'
import DKTAccordion from '../../Shared/DKTAccordion'
import DKTButton from '../../Shared/DKTButton'
import DKTCheckbox from '../../Shared/DKTCheckbox'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import DKTFileInput from '../../Shared/DKTFileInput'
import DKTInfoBox from '../../Shared/DKTInfoBox'
import DKTInput from '../../Shared/DKTInput'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import DKTSelect from '../../Shared/DKTSelect'
import { spinnerSize, twoFAText } from '../../Utils/constant'
import { entries, equal, isArray, keys, ternary } from '../../Utils/javascript'
import { removeStateFn } from '../../Utils/localStorage'
import { replaceLocation } from '../../Utils/window'
import DKTDialog from '../../Shared/DKTDialog'
import DKTForm from '../../Shared/DKTForm'

const useStyles = makeStyles(() => ({
  editProfileTitle: {
    '&.MuiTypography-root': {
      textAlign: 'left',
      padding: '25px 0',
      marginBottom: 0,
      display: 'block',
      textTransform: 'capitalize',
      color: '#686868',
      fontWeight: 'normal',
    },
  },
  editProfile: {
    '& .MuiAccordion-root': {
      marginBottom: 5,
      '& .MuiAccordionSummary-content': {
        margin: [[20, 0]],
      },
      '&.Mui-expanded': {
        '& .MuiAccordionSummary-root': {
          backgroundColor: '#1564FF',
          color: '#fff',
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: '#fff',
          },
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: 16,
      },
    },
  },
  editProfileAccordion: {
    '&.MuiAccordionSummary-root': {
      backgroundColor: '#C6C6C6',
      color: 'black',
      fontSize: '14px',
    },
  },
  editProfileButton: {
    margin: 1,
  },
  labelledCheckbox: {
    '&.MuiFormControl-root': {
      marginLeft: -10,
      alignItems: 'center',
      flexDirection: 'row',
    },
    '& .MuiInputLabel-root': {
      color: '#3E3E3E',
      position: 'inherit',
      fontSize: 13,
      transform: 'translate(0)',
      whiteSpace: 'normal',
      width: 'max-content',
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))

const EditProfile = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    values,
    errors,
    setErrors,
    handleChange,
    setValues,
    handleSubmit,
    resetForm,
  } = useForm({
    ...formFields.editProfileInfo,
    ...formFields.changePasswordInfo,
  })
  const {
    userProfileData,
    isUserProfileLoading,
    isUpdateUserProfileLoading,
    isUpdateUserPasswordLoading,
    isPasswordUpdate,
    passwordUpdateError,
  } = useSelector(({ profile }) => profile)
  const { state } = useSelector(({ states }) => states)
  const { systemAuthorization } = useSelector(({ auth }) => auth)
  const { choices, isLoadingChoices } = useSelector(
    ({ projectChoices }) => projectChoices,
  )
  const [currentSystemAuth, setCurrentSystemAuth] = React.useState('')
  const [closeAccordion, setCloseAccordion] = React.useState(
    isPasswordUpdate || false,
  )
  const [isDirty, setIsDirty] = React.useState(false)
  const [signature, setSignature] = React.useState()
  const [deleteNotificationOpen, setOpenDeleteNotification] =
    React.useState(false)

  const handleAccordion = () => {
    setCloseAccordion(!closeAccordion)
  }
  const dynamicOptions = {
    state,
  }

  useEffect(() => {
    dispatch(getUserProfile())
    dispatch(getStateList())
  }, [dispatch])

  useEffect(() => {
    if (passwordUpdateError) {
      setErrors({ ...errors, ...passwordUpdateError })
      dispatch(updateUserPasswordFailed(null))
    }
  }, [passwordUpdateError])

  useEffect(() => {
    if (userProfileData) {
      setValues({ ...userProfileData })
      setSignature(userProfileData?.signature)
    }
  }, [userProfileData, setValues])

  useEffect(() => {
    if (isPasswordUpdate) {
      resetForm(formFields.changePasswordInfo)
      setCloseAccordion(false)
    }
  }, [isPasswordUpdate])

  useEffect(() => {
    if (!choices.systemAuthorization)
      dispatch(getProjectChoices({ fieldName: 'systemAuthorization' }))
  }, [dispatch, choices.systemAuthorization])

  useEffect(() => {
    if (choices.systemAuthorization) {
      const activeSystemAuth = choices?.systemAuthorization?.find((auth) =>
        equal(auth?.value, systemAuthorization),
      )?.label
      setCurrentSystemAuth(activeSystemAuth)
    }
  }, [choices.systemAuthorization, systemAuthorization])

  useEffect(() => {
    const res = []
    const changePassField = [
      'oldPassword',
      'newPassword',
      'confirmPassword',
      'company',
      'isCompanyPersonnel',
      'isAdmin',
      'id',
    ]
    keys(values).forEach((key) => {
      if (!changePassField.includes(key)) {
        if (equal(key, 'signature')) {
          res.push(equal(`${signature}`, `${userProfileData?.[key]}`))
        }
        res.push(equal(`${values?.[key]}`, `${userProfileData?.[key]}`))
      }
    })
    if (signature && !userProfileData?.signature) {
      res.push(true)
    }
    if (res.length) {
      setIsDirty(res.some((bol) => bol === false))
    }
  }, [values, signature])

  const handleDeleteSignature = () => {
    setOpenDeleteNotification({ popupOpen: true, id: 'companyLogo' })
  }

  const handleClose = () => setOpenDeleteNotification(false)

  const handleDelete = () => {
    setSignature(null)
    handleClose()
  }

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDelete}>
        Yes
      </DKTButton>
    </>
  )

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            options,
            isConfirmPassword,
            dependsOn,
            ...formField
          },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        if (equal(formField.type, 'checkbox')) {
          return formField?.fields?.map((field, index) => (
            <Grid key={index} item xs={formField.xs ?? 12}>
              <Box>
                <DKTCheckbox
                  name={name}
                  checked={equal(values[name], true)}
                  value={values[name] || ''}
                  onChange={handleChange}
                  label={field?.label}
                  className={classNames({
                    [classes.labelledCheckbox]: field.labelledCheckbox,
                  })}
                  {...formField}
                />
                <DKTInfoBox
                  title={twoFAText}
                  sx={{ mt: '8px', cursor: 'pointer' }}
                />
              </Box>
            </Grid>
          ))
        }
        return (
          <Grid key={index} item xs={formField.xs ?? 12}>
            <FormControl
              {...formField}
              options={ternary(
                isDynamicOptions,
                dynamicOptions[name] || [],
                options,
              )}
              isRequired={isRequired}
              id={name}
              name={name}
              value={(values && values[name]) || ''}
              onChange={handleChange}
              error={errors[name]}
            />
          </Grid>
        )
      },
    )

  const renderFormFields = (formFields) => (
    <Grid container spacing={2}>
      {isArray(formFields)
        ? formFields.map((formField) =>
            mapFormFields({
              formFields: formField.data,
              makeFieldNameUnique: true,
              id: formField.id,
            }),
          )
        : mapFormFields({ formFields })}
    </Grid>
  )
  const saveProfileInfo = () => {
    const isValid = handleSubmit({
      flag: formFields.editProfileInfo,
      comp: 'EditProfile',
    })
    if (isValid) {
      setIsDirty(false)
      const formData = new FormData()
      keys(values)?.forEach((key) => {
        if (!equal(key, 'signature')) formData.append(key, values?.[key] || '')
      })
      formData.append('is_2fa_enabled', values?.is2faEnabled)
      if (signature instanceof File) {
        formData.append('signature', signature)
      } else if (equal(signature, null)) {
        formData.append('signature', '')
      }

      return dispatch(updateUserProfile(formData))
    }
    dispatch(updateOnSaveStatus({ cancel: true }))
    return true
  }
  const handleChangePassword = () => {
    const isValid = handleSubmit({
      flag: formFields.changePasswordInfo,
      comp: 'EditProfile',
    })
    if (isValid) {
      setIsDirty(false)
      const { oldPassword, newPassword, confirmPassword } = values
      const body = {
        oldPassword,
        newPassword,
        confirmPassword,
      }
      dispatch(updateUserPassword(body))
    }
  }

  const onFileUpload = (logo) => {
    setSignature(logo)
  }

  const handleLogout = () => {
    dispatch(userLogout())
    removeStateFn('auth')
    removeStateFn('company-id')
    replaceLocation('/login')
  }
  const actions = () => (
    <Stack direction="row" gap={2}>
      <DKTButton disabled={isUserProfileLoading} onClick={handleLogout}>
        Log Out
      </DKTButton>
    </Stack>
  )
  return ternary(
    isLoadingChoices,
    <Stack height="100vh" alignItems="center" justifyContent="center">
      <DKTCircularProgress size={spinnerSize.md} />
    </Stack>,
    <InnerLayout
      contentTitle={`Your permission is set as ${currentSystemAuth}`}
      actions={actions}
      className={classes.editProfileTitle}
      actionSx={{ marginTop: '114px' }}
    >
      <div className={classes.editProfile}>
        <DKTAccordion
          className={classes.editProfileAccordion}
          accordionTitle={accordionData?.editProfile?.accordionTitle}
          idTag={accordionData?.editProfile?.idTag}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <DKTForm autoComplete="off">
                {renderFormFields(formFields.editProfileInfo)}
                <DKTButton
                  sx={{ m: 2, marginLeft: 0 }}
                  onClick={saveProfileInfo}
                  variant="outlined"
                >
                  {ternary(isUpdateUserProfileLoading, 'Saving...', 'Save')}
                </DKTButton>
              </DKTForm>
            </Grid>
            <Grid item xs={3}>
              <Box
                component="form"
                enctype="multipart/form-data"
                position="relative"
              >
                <DKTFileInput
                  label="Signatures"
                  onFileUpload={onFileUpload}
                  defaultImage={signature}
                  isCompLogo
                />
                {ternary(
                  signature,
                  <IconButton
                    color="error"
                    onClick={handleDeleteSignature}
                    sx={{ position: 'absolute', top: -10, right: 0 }}
                  >
                    <Delete />
                  </IconButton>,
                  null,
                )}
              </Box>
            </Grid>
          </Grid>
        </DKTAccordion>
        <DKTAccordion
          accordionTitle={accordionData?.changePassword?.accordionTitle}
          idTag={accordionData?.changePassword?.idTag}
          className={classes.editProfileAccordion}
          expanded={equal(closeAccordion, true)}
          onChange={handleAccordion}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <DKTForm autoComplete="off">
                {renderFormFields(formFields.changePasswordInfo)}
              </DKTForm>
              <DKTButton
                sx={{ m: 2, marginLeft: 0 }}
                onClick={handleChangePassword}
                variant="outlined"
              >
                {ternary(
                  isUpdateUserPasswordLoading,
                  'Change password...',
                  'Change password',
                )}
              </DKTButton>
            </Grid>
          </Grid>
        </DKTAccordion>
      </div>
      <DKTReactRouterPrompt isDirty={isDirty} onSave={saveProfileInfo} />
      <DKTDialog
        open={deleteNotificationOpen || false}
        handleClose={handleClose}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can’t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
    </InnerLayout>,
  )
}
export default EditProfile
