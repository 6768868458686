export const sidebarLinks = [
  {
    text: 'Project Summary',
    url: 'summary',
  },
  {
    text: 'Site & Building',
    url: 'site-building',
    end: false,
    isNewProject: true,
    sideBarLinkId: 'Site & Building',
  },
  {
    text: 'Environmental',
    isNewProject: true,
    url: 'environmental',
    sideBarLinkId: 'Environmental',
  },
  {
    text: 'Owner-Contractor',
    isNewProject: true,
    url: 'owner-contractor',
    sideBarLinkId: 'Owner Contractor',
  },
  {
    text: 'Owner-Architect',
    isNewProject: true,
    url: 'owner-architect',
    sideBarLinkId: 'Owner Architect',
  },
  {
    text: 'Budget & Contingency',
    isNewProject: true,
    url: 'budget-contingency',
    sideBarLinkId: 'Budget & Contingency',
  },
  {
    text: 'Construction Schedule',
    isNewProject: true,
    url: 'construction-schedule',
    sideBarLinkId: 'Construction Schedule',
  },
  {
    text: 'Project Directory',
    isNewProject: true,
    url: 'project-directory',
    sideBarLinkId: 'Project Directory',
  },
  {
    text: 'Attachments',
    isNewProject: true,
    url: 'project-attachment',
    sideBarLinkId: 'ProjectAttachments',
  },
]
