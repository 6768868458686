import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  dragIcon: {
    cursor: 'move',
    maxWidth: 'max-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  commonTable: {
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          backgroundColor: '#E5E5E5',
        },
      },
    },
  },
  stateDropdown: {
    marginTop: '0 !important',
    height: '40px',
    '& .MuiSelect-select': {
      paddingLeft: 10,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  summaryTypeInput: {
    '& .MuiInputBase-root': {
      marginTop: '0 !important',
      paddingLeft: '0 !important',
      border: 'none',
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  status: {
    position: 'relative',
    '&.MuiInputBase-root': {
      height: 53,
    },
  },
  tableCell: {
    '& .MuiFormControl-root': {
      '& .MuiFormHelperText-root': {
        margin: 0,
        height: 0,
      },
    },
  },
  projectDesc: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 250,
    height: 250,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '150px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '150px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },
}))
