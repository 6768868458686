import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import * as Sentry from '@sentry/react'
import App from './App'
import store from './Redux'
import reportWebVitals from './reportWebVitals'
import './index.css'

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://39cb7a4617a17d63e288b31e0e40b78e@o4506278954401792.ingest.sentry.io/4506784352043008',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    debug: true,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: [
      'https://staging.acreiq.com',
      'https://acreiq.com',
    ],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <HistoryRouter history={createBrowserHistory()}>
      <Provider store={store}>
        <App />
      </Provider>
    </HistoryRouter>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
