export const chartTiles = [
  {
    heading: 'Percent Complete',
    chartId: 'percent_complete',
    card: {
      cardOne: { title: 'Draw Number', name: 'draw_number' },
      cardTwo: {
        title: 'Current Contract Completion',
        name: 'current_contract_completion',
        type: 'date',
      },
    },
  },
  {
    heading: 'Contract Time',
    chartId: 'percentage_of_contract_time',
    card: {
      cardOne: { title: 'Period Ending', name: 'period_ending', type: 'date' },
      cardTwo: {
        title: 'Current Schedule Completion',
        name: 'current_schedule_completion',
        type: 'date',
      },
    },
  },
  {
    heading: 'Remaining HC Contingency',
    chartId: 'percentage_of_remaing_hcc_after_co',
    card: {
      cardOne: { title: 'Draw Average %', name: 'draw_average' },
      cardTwo: { title: 'Days Behind/(Ahead)', name: 'delay_days' },
    },
  },
]

export const initialEconomicsField = [
  {
    name: 'month',
    label: 'Month',
    type: 'text',
    placeholder: 'Enter month',
    isRequired: true,
  },
  {
    name: 'economicCompletion',
    label: 'Economic Completion  %',
    type: 'number',
    placeholder: 'Enter Economic Completion',
    isRequired: true,
  },
]
