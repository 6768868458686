import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  otpBox: {
    minHeight: '100vh',
  },
  otpBoxWrapper: {
    maxWidth: 540,
    margin: '0 auto',
    padding: [[80, 50]],
    background: theme.palette.gray.main,
    borderRadius: 5,
    '& h4': {
      fontWeight: 600,
    },
    '& p': {
      color: theme.palette.gray.medium,
      margin: [[15, 0, 0, 0]],
    },
    '& h5': {
      fontSize: 18,
      fontWeight: 400,
      paddingTop: 50,
    },
    '& button': {
      width: '100%',
    },
  },
  otpContainer: {
    gap: 15,
    margin: [[20, 0, 15, 0]],
    '& input': {
      width: '40px !important',
      height: 40,
      borderRadius: 5,
      border: `1px solid ${theme.palette.gray.dark}`,
      '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
        appearance: 'none',
        webkitAppearance: 'none',
      },
    },
  },
  resendLink: {
    fontSize: 14,
    color: theme.palette.gray.medium,
    paddingBottom: 50,
    display: 'block',
    '& a ': {
      color: theme.palette.primary.main,
    },
  },
}))
