import React from 'react'
import AutoNumeric from 'autonumeric'
import { InputAdornment } from '@mui/material'
import { withStyles } from '@mui/styles'
import DKTInput from './DKTInput'
import { equal, ternary } from '../Utils/javascript'

const styles = () => ({
  textField: (props) => ({
    textAlign: props.textAlign || 'right',
  }),
})

class CurrencyTextField extends React.Component {
  constructor(props) {
    super(props)
    this.getValue = this.getValue.bind(this)
    this.callEventHandler = this.callEventHandler.bind(this)
  }

  componentDidMount() {
    const { currencySymbol, isLimitLess, ...others } = this.props

    this.autonumeric = new AutoNumeric(
      this.input,
      typeof this.props.value === 'string' ||
      typeof this.props.value === 'number'
        ? // eslint-disable-next-line no-restricted-globals
          isNaN(this.props.value)
          ? ''
          : this.props.value
        : '',
      {
        ...this.props.preDefined,
        ...others,
        decimalPlaces: others.decimalPlaces ?? 2,
        modifyValueOnWheel: false,
        onChange: undefined,
        onFocus: undefined,
        onBlur: undefined,
        onKeyPress: undefined,
        onKeyUp: undefined,
        onKeyDown: undefined,
        watchExternalChanges: true,
        maximumValue: 999999999999999,
        minimumValue: -999999999999999,
        decimalPlacesRawValue: 0,
        decimalPlacesShownOnBlur: this.props.decimalPlaces ?? 2,
        allowDecimalPadding: AutoNumeric.options.allowDecimalPadding.always,
        decimalCharacterAlternative:
          AutoNumeric.options.decimalCharacterAlternative.dot,
        outputFormat: AutoNumeric.options.outputFormat.string,
        overrideMinMaxLimits: ternary(isLimitLess, 'ignore', null),
      },
    )
  }

  componentWillUnmount() {
    this.autonumeric?.remove()
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const isValueChanged =
      !equal(this.props.value, newProps.value) &&
      !equal(this.getValue(), newProps.value)

    if (isValueChanged) {
      this.autonumeric.set(newProps.value)
    }
  }

  getValue() {
    if (!this.autonumeric) return null
    const valueMapper = {
      string: (numeric) => numeric?.getNumericString(),
      number: (numeric) => numeric?.getNumber(),
    }
    return valueMapper[this.props.outputFormat](this.autonumeric)
  }

  callEventHandler(event, eventName) {
    if (!this.props[eventName]) return
    this.props[eventName](event, this.getValue())
  }

  render() {
    const { currencySymbol } = this.props

    const otherProps = {}

    const attributes = [
      'id',
      'label',
      'className',
      'autoFocus',
      'variant',
      'style',
      'error',
      'endAdornment',
      'disabled',
      'type',
      'name',
      'defaultValue',
      'tabIndex',
      'fullWidth',
      'rows',
      'select',
      'isRequired',
      'unselectable',
      'margin',
      'multiline',
      'size',
      'sx',
      'placeholder',
      'tooltip',
      'sx',
      'showLabel',
    ]

    attributes.forEach((prop) => {
      otherProps[prop] = this.props[prop]
    })

    return (
      <DKTInput
        inputRef={(ref) => {
          this.input = ref
        }}
        onChange={(e) => this.callEventHandler(e, 'onChange')}
        onFocus={(e) => this.callEventHandler(e, 'onFocus')}
        onBlur={(e) => this.callEventHandler(e, 'onBlur')}
        onKeyPress={(e) => this.callEventHandler(e, 'onKeyPress')}
        onKeyUp={(e) => this.callEventHandler(e, 'onKeyUp')}
        onKeyDown={(e) => this.callEventHandler(e, 'onKeyDown')}
        {...ternary(
          currencySymbol,
          {
            startAdornment: (
              <InputAdornment position="start">{currencySymbol}</InputAdornment>
            ),
          },
          {},
        )}
        {...otherProps}
      />
    )
  }
}

export default withStyles(styles)(CurrencyTextField)

export const predefinedOptions = AutoNumeric.getPredefinedOptions()
