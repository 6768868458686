import { Box } from '@mui/material'
import React, { useRef } from 'react'
import { Cropper } from 'react-cropper'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'
import { base64ToFile } from '../../../Utils/javascript'

const ImageCrop = ({ open, onClose, image, setUploadFile, onFileUpload }) => {
  const cropperRef = useRef(null)
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const file = base64ToFile(
        cropperRef.current?.cropper
          .getCroppedCanvas({ width: 435, height: 175 })
          .toDataURL(),
        image?.file.name,
      )

      onFileUpload(file)

      setUploadFile(
        cropperRef.current?.cropper
          .getCroppedCanvas({ width: 435, height: 175 })
          .toDataURL(),
      )
      onClose()
    }
  }
  return (
    <DKTDialog
      title="Edit Image Dimensions"
      open={open}
      showCloseButton
      handleClose={onClose}
      maxWidth="md"
    >
      <Cropper
        ref={cropperRef}
        style={{ height: 400, width: '100%' }}
        zoomTo={0.5}
        initialAspectRatio={435 / 175}
        preview=".img-preview"
        src={image?.link}
        viewMode={0}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive
        autoCropArea={1}
        checkOrientation={false}
        guides
      />
      <Box textAlign="right" mt={2}>
        <DKTButton onClick={getCropData}>Upload</DKTButton>
      </Box>
    </DKTDialog>
  )
}

export default ImageCrop
