import { zipCodeValidation } from '../Utils/regex'

export const siteAddressHeader = [
  'Description',
  'Address',
  'City',
  'State',
  'Zip Code',
]

export const siteAddressFields = {
  description: {
    type: 'text',
    showLabel: false,
  },
  address: {
    type: 'text',
    showLabel: false,
  },
  city: {
    type: 'text',
    showLabel: false,
  },
  state: {
    type: 'select',
    displayEmpty: true,
    isDynamicOptions: true,
    showLabel: false,
    className: 'stateDropdown',
    placeholder: 'Select',
  },
  zipCode: {
    type: 'text',
    helperText: 'Zip code',
    validate: [zipCodeValidation],
    showLabel: false,
  },
}
