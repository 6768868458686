import { Box, Grid, Stack } from '@mui/material'
import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { formFields } from '../../../Description/issuesLog.description'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTEditor from '../../../Shared/DKTEditor'
import DKTForm from '../../../Shared/DKTForm'
import { useStyles } from '../../../Styles/issuesLog.style'
import { handleTextEditor, keys, ternary } from '../../../Utils/javascript'
import useForm from '../../../Hooks/useForm'
import DKTDeleteNotificationModal from '../../../Shared/DKTDeleteNotificationModal'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import {
  addOrUpdateIssue,
  deleteIssueLog,
} from '../../../Redux/actions/issuesLog'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
const initialFormFields = { ...formFields.addIssues, ...formFields.rightSide }
const AddIssue = ({
  open,
  handleClose,
  editIssueData,
  setEditIssueData,
  renderFormFields,
  ownerContractor,
  hasOnlyViewPermission,
  issueFilter,
}) => {
  const [deleteNotificationOpen, setOpenDeleteNotification] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const {
    values,
    setValues,
    errors,
    setErrors,
    setFieldValue,
    handleChange,
    handleSubmit,
    isDirty,
    setIsDirty,
  } = useForm(initialFormFields)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isAddOrUpdateIssueLoading, isIssueDeleteLoading, issuesLogError } =
    useSelector(({ issuesLog }) => issuesLog)
  const { slug: projectId, reportId } = useParams()

  useEffect(() => {
    if (issuesLogError && keys(issuesLogError)?.length) {
      setErrors({ ...errors, ...issuesLogError })
    }
  }, [issuesLogError])

  useEffect(() => {
    if (editIssueData && keys(editIssueData)?.length) {
      setValues(editIssueData)
    }
    return () => {
      setErrors({})
      setValues({})
    }
  }, [editIssueData])

  const handleCloseIssueModal = () => {
    if (isDirty) {
      setIsUnSavedWarningOpen(true)
      return
    }
    handleClose()
    setEditIssueData({})
    setValues({})
    setErrors({})
    setIsDirty(false)
  }

  const handleOpenCloseDeleteNotification = (flag) => {
    setOpenDeleteNotification(flag)
  }

  const handleSave = () => {
    const isFormValid = handleSubmit()
    if (
      !isFormValid ||
      !!Object.values(errors)
        ?.map((el) => Object.values(el || '')?.filter(Boolean))
        ?.flat()?.length
    ) {
      dispatch(updateOnSaveStatus({ cancel: true }))
    } else {
      const { title, issueDate, resolutionPeriod, status, description } = values
      const adIssueSuccessfully = () => {
        handleClose()
        setEditIssueData({})
        setValues({})
        setErrors({})
        setIsDirty(false)
      }
      const payload = {
        title,
        issue_date: issueDate,
        resolutionPeriod,
        status,
        description,
        report: reportId,
        ownerContractor,
      }
      setIsDirty(false)
      dispatch(
        addOrUpdateIssue({
          reportId,
          ocId: ownerContractor,
          projectId,
          id: editIssueData?.id,
          issueFilter,
          payload,
          adIssueSuccessfully,
        }),
      )
    }
  }

  const handleDeleteIssue = () => {
    const deleteIssueSuccessfully = () => {
      handleClose()
      handleOpenCloseDeleteNotification(false)
      setEditIssueData({})
      setValues({})
      setErrors({})
      setIsDirty(false)
    }
    dispatch(
      deleteIssueLog({
        issueLogDelete: editIssueData?.id,
        reportId,
        ocId: ownerContractor,
        projectId,
        deleteIssueSuccessfully,
      }),
    )
      .then(() => {
        deleteIssueSuccessfully()
      })
      .catch(() => {
        handleOpenCloseDeleteNotification(false)
      })
  }

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsDirty(false)
    setIsUnSavedWarningOpen(false)
    handleClose()
  }

  const dialogActions = (
    <>
      {!!keys(editIssueData).length && (
        <DKTButton
          onClick={() => handleOpenCloseDeleteNotification(true)}
          disabled={hasOnlyViewPermission || isIssueDeleteLoading}
          variant="outlined"
          color="error"
          disableElevation
          sx={{
            '&.MuiButtonBase-root': {
              padding: '12px 22px',
              lineHeight: '1',
            },
          }}
        >
          {ternary(false, 'Deleting...', 'Delete')}
        </DKTButton>
      )}
      <DKTButton
        variant="contain"
        onClick={handleSave}
        disabled={hasOnlyViewPermission || isAddOrUpdateIssueLoading}
        sx={{
          '&.MuiButtonBase-root': {
            padding: '12px 34px',
            lineHeight: '1',
            margin: 0,
            marginLeft: 'auto',
          },
        }}
      >
        {ternary(
          isAddOrUpdateIssueLoading,
          ternary(keys(editIssueData)?.length, 'Updating...', 'Saving...'),
          ternary(keys(editIssueData)?.length, 'Update', 'Save'),
        )}
      </DKTButton>
    </>
  )

  return (
    <>
      <DKTDialog
        open={open}
        handleClose={handleCloseIssueModal}
        title={`${ternary(
          keys(editIssueData)?.length,
          'Update',
          'Add',
        )} an Issue`}
        actions={dialogActions}
        maxWidth="md"
      >
        <Stack direction="row" mb="22px">
          <Grid container columnSpacing="14px">
            <Grid item xs={12} md={4.5}>
              <DKTForm autoComplete="off">
                {renderFormFields({
                  values,
                  errors,
                  handleChange,
                  formFields: formFields.addIssues,
                })}
              </DKTForm>
            </Grid>
            <Grid item xs={12} md={7.5}>
              <Box className={classes.issuesDescriptionTextArea}>
                <DKTEditor
                  title="Description"
                  value={values?.description || ''}
                  onChange={(event, editor) => {
                    handleTextEditor(editor, 'description', setFieldValue)
                  }}
                  disabled={hasOnlyViewPermission}
                  error={errors?.description}
                  className={classes.issuesTAble}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </DKTDialog>
      <DKTDeleteNotificationModal
        open={deleteNotificationOpen}
        handleClose={() => handleOpenCloseDeleteNotification(false)}
        handleDelete={handleDeleteIssue}
        isLoadingDelete={isIssueDeleteLoading}
      />
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnSavedWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
    </>
  )
}

export default memo(AddIssue)
