import { InputAdornment } from '@mui/material'

const percentageAdornment = <InputAdornment position="end">%</InputAdornment>

export const optionsFields = [
  'floodZone',
  'radonZone',
  'wetlandPresentOnSite',
  'mapping',
  'seismicZone',
  'asbestosContainingMaterial',
  'leadBasedPaint',
  'moldAndMildew',
  'abovegroundStorageTank',
  'undergroundStorageTank',
  'recognizedEnvironmentalCondition',
  'controlledRecognizedEnvironmentalCondition',
  'historicalRecognizedEnvironmentalCondition',
  'specialFloodHazardArea',
]

export const formFields = {
  environmentalIssues: {
    radonZone: {
      type: 'select',
      label: 'Radon Zone',
      isDynamicOptions: true,
      defaultValue: '',
      placeholder: 'Select',
      displayEmpty: true,
    },
    wetlandPresentOnSite: {
      type: 'select',
      label: 'Wetlands Present on Site',
      isDynamicOptions: true,
      defaultValue: '',
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
    asbestosContainingMaterial: {
      type: 'select',
      label: 'Asbestos Containing Material (ACM)',
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
    leadBasedPaint: {
      type: 'select',
      label: 'Lead Based Paint (LBP)',
      isDynamicOptions: true,
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
    moldAndMildew: {
      type: 'select',
      label: 'Mold and/or Mildew',
      isDynamicOptions: true,
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
    abovegroundStorageTank: {
      type: 'select',
      label: 'Aboveground Storage Tank (AST)',
      isDynamicOptions: true,
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
    undergroundStorageTank: {
      type: 'select',
      label: 'Underground Storage Tank (UST)',
      isDynamicOptions: true,
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
    recognizedEnvironmentalCondition: {
      type: 'select',
      label: 'Recognized Environmental Condition (REC)',
      isDynamicOptions: true,
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
    controlledRecognizedEnvironmentalCondition: {
      type: 'select',
      label: 'Controlled Recognized Environmental Condition (CREC)',
      isDynamicOptions: true,
      defaultValue: true,
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
    historicalRecognizedEnvironmentalCondition: {
      type: 'select',
      label: 'Historical Recognized Environmental Condition (HREC)',
      isDynamicOptions: true,
      defaultValue: true,
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
  },
  seismicAnalysis: {
    seismicZone: {
      type: 'select',
      label: 'Seismic Zone',
      isDynamicOptions: true,
      defaultValue: '',
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
    peakGroundAcceleration: {
      type: 'text',
      label: 'Peak Ground Acceleration (PGA)',
      isDynamicOptions: true,
      endAdornment: percentageAdornment,
    },
    scenarioExpectedLoss: {
      type: 'text',
      label: 'Scenario Expected Loss (SEL-475) %',
      endAdornment: percentageAdornment,
    },
    scenarioUpperLoss: {
      type: 'text',
      label: 'Scenario Upper Loss (SuL-475) %',
      isDynamicOptions: true,
      options: [],
      endAdornment: percentageAdornment,
    },
  },
  floodZone: {
    specialFloodHazardArea: {
      type: 'select',
      label: 'Special Flood Hazard Area',
      isDynamicOptions: true,
      defaultValue: true,
      options: [],
      placeholder: 'Select',
      displayEmpty: true,
    },
  },
  comments: {
    comments: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 6,
    },
  },
}

export const floodZone = {
  type: 'checkbox',
  labelledCheckbox: true,
  fields: [],
}
