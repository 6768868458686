import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  imgWrapper: {
    height: 250,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    '& .MuiIconButton-root': {
      backgroundColor: '#fff',
      position: 'absolute',
      top: 10,
      right: 10,
      zIndex: 2,
      borderRadius: 0,
    },
    '& img': {
      height: '100%',
      width: 'auto',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    '& p': {
      opacity: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: 10,
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      transform: 'translateY(100%)',
      transition: 'all 0.3s ease-in-out',
    },
  },
  imageHover: {
    '&:hover': {
      '& p': {
        transform: 'translateY(0)',
        opacity: 1,
      },
    },
  },
  icons: {
    top: '50%',
    transform: 'translateY(-50%)',
    '&.close': {
      top: 40,
      right: 20,
      padding: 10,
    },
  },
  btn: {
    position: 'absolute',
    background: '#fff',
    color: '#000',
    fontSize: 24,
    lineHeight: '45px',
    height: 45,
    width: 45,
    padding: 10,
    textAlign: 'center',
    minWidth: 'auto',
    '&:hover': {
      background: '#00acf0',
      color: '#fff',
    },
  },
  bigImgWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    background: '#00000030',
    backdropFilter: 'blur(1px)',
    zIndex: 999,
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      maxHeight: '70vh',
      width: 'auto',
      minHeight: 450,
      maxWidth: '80%',
      objectFit: 'contain',
    },
    '& p': {
      padding: 10,
      color: '#fff',
      fontSize: 18,
    },
  },
  imgText: {
    position: 'fixed',
    top: 'calc(100% - 110px)',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 9999,
  },
}))
