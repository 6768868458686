import { makeStyles } from '@mui/styles'

export const useTableBodyStyle = makeStyles(() => ({
  inputBorder: {
    marginTop: '0 !important',
    '& .MuiInputBase-root': {
      marginTop: '0 !important',
      paddingLeft: '16px !important',
      border: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  dateError: {
    position: 'relative',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      left: 30,
      bottom: -3,
    },
  },
  g702SplitTrackerModal: {
    '& .MuiPaper-root': {
      width: 'calc(100% - 128px)',
      '& .MuiPaper-root': {
        padding: 0,
        width: '100%',
        '& div .MuiTable-root': {
          minWidth: 1370,
        },
      },
    },
  },
  g702TableData: {
    '& div:nth-child(2)': {
      maxHeight: '100% !important',
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        borderLeft: '1px solid #e0e0e0',
        '&:hover': {
          backgroundColor: '#fff',
        },
        '& .MuiTableCell-root': {
          borderRight: '1px solid #e0e0e0',
          padding: 0,
          '&:first-child': {
            width: 300,
          },
          '& .MuiFormControl-root': {
            height: '48px',
            display: 'block',
          },
          '& .MuiInputBase-root:nth-child(1)': {
            borderBottom: 0,
          },
          '& .MuiInputBase-root': {
            height: '48px',
            '& .MuiSelect-select': {
              padding: '12px 14px',
            },
          },
          '& div': {
            justifyContent: 'center',
          },
          '& form': {
            '&.MuiBox-root': {
              padding: '0 8px',
              boxSizing: 'border-box',
              '& .MuiInputBase-root': {
                padding: '0px !important',
                width: '100%',
              },
            },
          },
        },
        '&:first-child': {
          '& td': {
            '&:last-child': {
              borderBottom: 0,
              borderRight: 0,
            },
          },
        },
        '&:nth-child(2)': {
          '& td': {
            '&:last-child': {
              borderBottom: 0,
              borderRight: 0,
            },
          },
        },
        '&:nth-child(3)': {
          borderBottom: '20px solid white',
          position: 'relative',
          '&:after': {
            position: 'absolute',
            content: "''",
            top: 'calc(100% + 10px)',
            left: 0,
            right: 0,
            height: 1,
            background: '#e0e0e0',
          },
          '& td': {
            '&:last-child': {
              borderBottom: 0,
              position: 'relative',
              borderRight: 0,
              '&:after': {
                position: 'absolute',
                content: "''",
                top: 'calc(100%)',
                left: 0,
                right: 0,
                height: 1,
                background: '#fff',
                zIndex: 2,
              },
            },
          },
        },
        '&:nth-child(4)': {
          position: 'relative',
          '&:after': {
            position: 'absolute',
            content: "''",
            bottom: 'calc(100% - 10px)',
            left: 0,
            right: 0,
            height: 1,
            background: '#e0e0e0',
          },
        },
        '&:last-child': {
          position: 'relative',
          borderTop: '20px solid white',
          '&:after': {
            position: 'absolute',
            content: "''",
            bottom: 'calc(100% + 10px)',
            left: 0,
            right: 0,
            height: 1,
            background: '#e0e0e0',
          },
        },
        '&:nth-last-child(2)': {
          position: 'relative',
          '&:after': {
            position: 'absolute',
            content: "''",
            top: 'calc(100% - 10px)',
            left: 0,
            right: 0,
            height: 1,
            background: '#e0e0e0',
          },
        },
        '&:nth-last-child(4)': {
          '& td': {
            '&:first-child': {
              '& form.MuiBox-root': {
                paddingLeft: '34px !important',
              },
            },
          },
        },
      },
    },
  },
  g702TableNoData: {
    '& .MuiTableCell-root': {
      padding: '16px !important',
    },
  },
}))
