// Fetch construction schedule report
export const FETCH_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED =
  'FETCH_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED'
export const FETCH_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS =
  'FETCH_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS'
export const FETCH_CONSTRUCTION_SCHEDULE_REPORT_FAILED =
  'FETCH_CONSTRUCTION_SCHEDULE_REPORT_FAILED'

// Fetch construction schedule report reference values
export const FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_REQUESTED =
  'FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_REQUESTED'
export const FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_SUCCESS =
  'FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_SUCCESS'
export const FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_FAILED =
  'FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_FAILED'

// Save construction schedule report
export const SAVE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED =
  'SAVE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED'
export const SAVE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS =
  'SAVE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS'
export const SAVE_CONSTRUCTION_SCHEDULE_REPORT_FAILED =
  'SAVE_CONSTRUCTION_SCHEDULE_REPORT_FAILED'

// Delete construction schedule report
export const DELETE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED =
  'DELETE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED'
export const DELETE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS =
  'DELETE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS'
export const DELETE_CONSTRUCTION_SCHEDULE_REPORT_FAILED =
  'DELETE_CONSTRUCTION_SCHEDULE_REPORT_FAILED'

// Import from last report
export const IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_REQUESTED =
  'IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_REQUESTED'
export const IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_SUCCESS =
  'IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_SUCCESS'
export const IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_FAILED =
  'IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_FAILED'

/* Phase tracker */
// Fetch phase tracker
export const FETCH_PHASE_TRACKER_REQUESTED = 'FETCH_PHASE_TRACKER_REQUESTED'
export const FETCH_PHASE_TRACKER_SUCCESS = 'FETCH_PHASE_TRACKER_SUCCESS'
export const FETCH_PHASE_TRACKER_FAILED = 'FETCH_PHASE_TRACKER_FAILED'

// Add/Update phase tracker
export const SAVE_PHASE_TRACKER_REQUESTED = 'SAVE_PHASE_TRACKER_REQUESTED'
export const SAVE_PHASE_TRACKER_SUCCESS = 'SAVE_PHASE_TRACKER_SUCCESS'
export const SAVE_PHASE_TRACKER_FAILED = 'SAVE_PHASE_TRACKER_FAILED'

// Delete phase tracker
export const DELETE_PHASE_TRACKER_REQUESTED = 'DELETE_PHASE_TRACKER_REQUESTED'
export const DELETE_PHASE_TRACKER_SUCCESS = 'DELETE_PHASE_TRACKER_SUCCESS'
export const DELETE_PHASE_TRACKER_FAILED = 'DELETE_PHASE_TRACKER_FAILED'

/* Milestone tracker */
// Fetch Milestone tracker
export const FETCH_MILESTONE_TRACKER_REQUESTED =
  'FETCH_MILESTONE_TRACKER_REQUESTED'
export const FETCH_MILESTONE_TRACKER_SUCCESS = 'FETCH_MILESTONE_TRACKER_SUCCESS'
export const FETCH_MILESTONE_TRACKER_FAILED = 'FETCH_MILESTONE_TRACKER_FAILED'

// Save Milestone tracker
export const SAVE_MILESTONE_TRACKER_REQUESTED =
  'SAVE_MILESTONE_TRACKER_REQUESTED'
export const SAVE_MILESTONE_TRACKER_SUCCESS = 'SAVE_MILESTONE_TRACKER_SUCCESS'
export const SAVE_MILESTONE_TRACKER_FAILED = 'SAVE_MILESTONE_TRACKER_FAILED'

// Delete Milestone Tracker
export const DELETE_MILESTONE_TRACKER_REQUESTED =
  'DELETE_MILESTONE_TRACKER_REQUESTED'
export const DELETE_MILESTONE_TRACKER_SUCCESS =
  'DELETE_MILESTONE_TRACKER_SUCCESS'
export const DELETE_MILESTONE_TRACKER_FAILED = 'DELETE_MILESTONE_TRACKER_FAILED'

/* Milestone tracker last Report data */
// Fetch Milestone tracker
export const FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA_REQUESTED =
  'FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA_REQUESTED'
export const FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__SUCCESS =
  'FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__SUCCESS'
export const FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__FAILED =
  'FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__FAILED'

/* Phase tracker last Report data */
// Fetch Phase tracker

export const FETCH_PHASE_TRACKER_LAST_REPORT_DATA_REQUESTED =
  'FETCH_PHASE_TRACKER_LAST_REPORT_DATA_REQUESTED'
export const FETCH_PHASE_TRACKER_LAST_REPORT_DATA_SUCCESS =
  'FETCH_PHASE_TRACKER_LAST_REPORT_DATA_SUCCESS'
export const FETCH_PHASE_TRACKER_LAST_REPORT_DATA_FAILED =
  'FETCH_PHASE_TRACKER_LAST_REPORT_DATA_FAILED'
export const CLEAR_CONSTRUCTION_SCHEDULE_REPORT =
  'CLEAR_CONSTRUCTION_SCHEDULE_REPORT'
