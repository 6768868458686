export const formFields = {
  CMRReportNumber: {
    reportNumber: {
      type: 'input',
      label: 'Report Number',
      xs: 7.5,
      lg: 6,
      disabled: true,
    },
    overrideReportCode: {
      type: 'select',
      label: 'Override Report Number',
      placeholder: 'Select',
      overrideOptions: true,
      xs: 7.5,
      lg: 6,
    },
  },
  CMRCoverLetterDate: {
    date: {
      type: 'date',
      label: 'Date',
      xs: 6,
      lg: 6,
      disabled: true,
    },
  },
  CMRCoverLetter: {
    subject: {
      type: 'text',
      label: 'Subject',
      xs: 12,
      lg: 12,
    },
    salutation: {
      type: 'text',
      label: 'Salutation',
      xs: 12,
      lg: 12,
    },
  },
  signatures: {
    signature: {
      type: 'select',
      label: 'Signature',
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      lg: 6,
    },
    signatureOne: {
      type: 'select',
      label: 'Signature',
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      lg: 6,
    },
  },
  CMRTextFields: {
    clientNameAddress: {
      type: 'text',
      label: 'clientNameAddress',
    },
    body: {
      type: 'text',
      label: 'clientNameAddress',
    },
    nameAndContactInformation: {
      type: 'text',
      label: 'clientNameAddress',
    },
    nameAndContactInformationOne: {
      type: 'text',
      label: 'clientNameAddress',
    },
  },
  addClientNames: {
    clientName: {
      type: 'select',
      label: 'Client Name',
      isRequired: true,
      isDynamicClientName: true,
      placeholder: 'Select',
      displayEmpty: true,
      xs: 10,
    },
  },
}
