import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import DKTButton from './DKTButton'
import DKTDialog from './DKTDialog'
import { ternary } from '../Utils/javascript'
import DKTCircularProgress from './DKTCircularProgress'
import { spinnerSize } from '../Utils/constant'

const useStyles = makeStyles(() => ({
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
}))

const DKTDeleteNotificationModal = ({
  open,
  handleClose,
  handleDelete,
  isLoadingDelete,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handleDelete()
      }
    }

    if (open) {
      document.addEventListener('keydown', handleKeyDown)
    }
  }, [open, handleDelete])

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDelete}>
        {ternary(
          isLoadingDelete,
          <DKTCircularProgress
            size={spinnerSize?.sm}
            color={theme.palette.gray.dark}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  return (
    <DKTDialog
      open={open}
      handleClose={handleClose}
      title="&nbsp;"
      actions={deleteNotificationAction}
      maxWidth="xs"
    >
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.deleteNotificationContent}>
            <Typography variant="h5">
              Are you sure you want to delete?
            </Typography>
            <Typography variant="body1">
              You can&apos;t undo this action
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </DKTDialog>
  )
}

export default DKTDeleteNotificationModal
