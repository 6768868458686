import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  addBtn: {
    marginLeft: 0,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  tdFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      textAlign: 'left',
    },
  },
  tdInput: {
    '& .MuiInputBase-root': {
      marginTop: 0,
    },
  },
}))
