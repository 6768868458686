import { emailValidation, mobileNumber, validatePassword } from '../Utils/regex'

export const columns = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'firstName',
    label: 'First Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'lastName',
    label: 'Last Name',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'Email Address',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'workPhoneWithExtension',
    label: 'Work Phone',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'extension',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'cellPhone',
    label: 'Cell Phone',
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: 'isCompanyPersonnel',
    label: 'Role',
    options: {
      filter: true,
      sort: false,
    },
  },
]

export const options = {
  responsive: 'standard',
  filter: false,
  downloadOptions: {
    filename: 'Company personnel.csv',
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
}

export const contactTypeFilterList = [
  { label: 'All' },
  { label: 'Client' },
  { label: 'Developer' },
  { label: 'Architect' },
  { label: 'Landscape Architect' },
  { label: 'Environmental Engineer' },
  { label: 'Geotech Engineer' },
  { label: 'Geotech Engineer' },
  { label: 'Geotech Engineer' },
  { label: 'Geotech Engineer' },
  { label: 'Geotech Engineer' },
]

export const companyNameFilterList = [
  { label: 'All' },
  { label: 'Bank Name' },
  { label: 'Bank Name' },
  { label: 'Insurance Company Name' },
  { label: 'Syndicator Name' },
  { label: 'Investor Name' },
  { label: 'Investor Name ' },
  { label: 'Investor Name ' },
  { label: 'Investor Name ' },
  { label: 'Investor Name ' },
  { label: 'Investor Name ' },
]

export const stateFilterList = [
  { label: 'All' },
  { label: 'AZ' },
  { label: 'CA' },
  { label: 'CO' },
  { label: 'FL' },
  { label: 'GA' },
  { label: 'OH' },
  { label: 'OH' },
  { label: 'OH' },
  { label: 'OH' },
  { label: 'OH' },
]

export const companyFieldList = {
  firstName: {
    type: 'text',
    label: 'First Name',
    isRequired: true,
  },
  lastName: {
    type: 'text',
    label: 'Last Name',
    isRequired: true,
  },
  email: {
    type: 'text',
    label: 'Email Address',
    isRequired: true,
    validate: [emailValidation],
  },
  autoCreatePassword: {
    type: 'checkbox',
    fields: [
      {
        label: 'Automatically create a password',
        value: 'autoCreatePassword',
      },
    ],
  },
  temporaryPassword: {
    type: 'text',
    label: 'Temporary Password',
    isRequired: true,
    validate: [
      validatePassword,
      'Password must be at least 8 characters long and contain a mix of uppercase letters, lowercase letters, digits, and special characters.',
    ],
  },
  workPhone: {
    type: 'text',
    label: 'Work Phone (W)',
    xs: '6',
    isRequired: true,
    validate: [mobileNumber],
  },
  extension: {
    type: 'text',
    label: 'Extension',
    xs: '6',
    isRequired: true,
  },
  cellPhone: {
    type: 'text',
    label: 'Cell Phone (C)',
    isRequired: true,
    validate: [mobileNumber],
  },
}
