// Fetch owner contractor
export const FETCH_OWNER_CONTRACTOR_REQUESTED =
  'FETCH_OWNER_CONTRACTOR_REQUESTED'
export const FETCH_OWNER_CONTRACTOR_SUCCESS = 'FETCH_OWNER_CONTRACTOR_SUCCESS'
export const FETCH_OWNER_CONTRACTOR_FAILED = 'FETCH_OWNER_CONTRACTOR_FAILED'

// Create and update owner contractor
export const CREATE_AND_UPDATE_OWNER_CONTRACTOR_REQUESTED =
  'CREATE_AND_UPDATE_OWNER_CONTRACTOR_REQUESTED'
export const CREATE_AND_UPDATE_OWNER_CONTRACTOR_SUCCESS =
  'CREATE_AND_UPDATE_OWNER_CONTRACTOR_SUCCESS'
export const CREATE_AND_UPDATE_OWNER_CONTRACTOR_FAILED =
  'CREATE_AND_UPDATE_OWNER_CONTRACTOR_FAILED'

// Site and building mapping
export const FETCH_SITE_BUILDING_MAPPING_REQUESTED =
  'FETCH_SITE_BUILDING_MAPPING_REQUESTED'
export const FETCH_SITE_BUILDING_MAPPING_SUCCESS =
  'FETCH_SITE_BUILDING_MAPPING_SUCCESS'
export const FETCH_SITE_BUILDING_MAPPING_FAILED =
  'FETCH_SITE_BUILDING_MAPPING_FAILED'

// Add new owner contractor
export const ADD_OWNER_CONTRACTOR = 'ADD_OWNER_CONTRACTOR'

// Search contractor
export const SEARCH_CONTRACTOR_REQUESTED = 'SEARCH_CONTRACTOR_REQUESTED'
export const SEARCH_CONTRACTOR_SUCCESS = 'SEARCH_CONTRACTOR_SUCCESS'
export const SEARCH_CONTRACTOR_FAILED = 'SEARCH_CONTRACTOR_FAILED'

// Delete contractor
export const DELETE_CONTRACTOR_REQUESTED = 'DELETE_CONTRACTOR_REQUESTED'
export const DELETE_CONTRACTOR_SUCCESS = 'DELETE_CONTRACTOR_SUCCESS'
export const DELETE_CONTRACTOR_FAILED = 'DELETE_CONTRACTOR_FAILED'

// clear owner contractor
export const CLEAR_OWNER_CONTRACTOR = 'CLEAR_OWNER_CONTRACTOR'
