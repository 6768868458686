import { RemoveCircleOutline } from '@mui/icons-material'
import {
  Box,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/styles'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { DragIcon } from '../../../Assets/SVGs'
import { dynamicColor } from '../../../Description/appendix.description'
import DKTButton from '../../../Shared/DKTButton'
import DKTCheckbox from '../../../Shared/DKTCheckbox'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTInput from '../../../Shared/DKTInput'
import DKTSelect from '../../../Shared/DKTSelect'
import { useStyles } from '../../../Styles/appendix.style'
import { BOOLEAN } from '../../../Utils/constant'
import { equal, length, ternary } from '../../../Utils/javascript'
import { showToast } from '../../../Utils/toastService'
import DKTForm from '../../../Shared/DKTForm'

const Documents = ({
  data,
  setData,
  appendixColumns,
  options,
  providedPlaceholder,
  hasOnlyViewPermission,
  setIsDirty,
}) => {
  const [deleteNotificationOpen, setOpenDeleteNotification] = useState(false)

  const classes = useStyles()
  const theme = useTheme()
  const documentToDelete = useRef(null)
  const { isFetchingAppendix, appendix } = useSelector(
    ({ appendix }) => appendix,
  )

  useEffect(() => {
    setData(appendix)
  }, [appendix])

  const handleClose = () => setOpenDeleteNotification(false)

  const handleChange = (event, rowId) => {
    const { name, value, type, checked } = event.target
    const clonedData = [...data]
    if (
      clonedData[rowId][name] !==
      ternary(equal(type, 'checkbox'), checked, value)
    ) {
      setIsDirty(true)
    }

    clonedData[rowId][name] = ternary(equal(type, 'checkbox'), checked, value)
    setData((prevData) => {
      const clonedData = [...prevData]
      clonedData[rowId][name] = ternary(equal(type, 'checkbox'), checked, value)
      return clonedData
    })
  }

  const removeRow = (documentId) => {
    documentToDelete.current = documentId
    setOpenDeleteNotification(true)
  }

  const handleDeleteTableRow = () => {
    const clonedData = [...data]
    const documentIndex = clonedData.findIndex(({ id }) =>
      equal(id, documentToDelete.current),
    )
    clonedData.splice(documentIndex, 1)
    setData(clonedData)
    handleClose()
    showToast('Deleted successfully!', 'success')
  }

  const deleteNotificationActions = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteTableRow}>
        Yes
      </DKTButton>
    </>
  )
  return data && equal(length(data), 0) ? (
    <TableRow>
      <TableCell
        colSpan={ternary(appendixColumns, length(appendixColumns), 1)}
        sx={{ textAlign: 'center' }}
        height="57px"
      >
        {ternary(
          isFetchingAppendix,
          <DKTCircularProgress />,
          'Sorry, no matching results found',
        )}
      </TableCell>
    </TableRow>
  ) : (
    <>
      {data?.map((row, index) => (
        <Draggable
          key={row.id || row.uniqueId}
          draggableId={(row.id || row.uniqueId).toString()}
          index={index}
          isDragDisabled={hasOnlyViewPermission || !row?.isDraggable}
        >
          {(provided) => (
            <TableRow
              sx={{ backgroundColor: '#fff' }}
              id={row.id}
              key={index}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {Object?.entries({
                id: row.id,
                document: row.document,
                status: row.status,
                includeInAppendix: row.includeInAppendix,
              })?.map((rowData, idx) => {
                const rowId = row?.id
                if (!equal(rowData[0], 'id')) {
                  return ternary(
                    equal(rowData[0], 'status'),
                    <TableCell
                      className={classes.tableCell}
                      sx={{ paddingBlock: 0 }}
                      key={idx}
                    >
                      <DKTForm autoComplete="off">
                        {ternary(
                          row?.isStatus,
                          <DKTSelect
                            displayEmpty
                            name={rowData[0]}
                            id={rowId}
                            value={data[index] ? data[index][rowData[0]] : ''}
                            placeholder="Select"
                            showLabel={false}
                            className={`${classes.stateDropdown} ${classes.status}`}
                            options={options}
                            onChange={(event) => handleChange(event, index)}
                            disabled={hasOnlyViewPermission || row?.isDisabled}
                            selectSx={
                              data[index]
                                ? {
                                    backgroundColor: `${
                                      dynamicColor[data[index][rowData[0]]]
                                    }`,
                                    color: ternary(
                                      equal(
                                        dynamicColor[data[index][rowData[0]]],
                                        '#1564FF',
                                      ),
                                      'white',
                                      '#333333',
                                    ),
                                  }
                                : {}
                            }
                            expandIconColor={ternary(
                              data[index] &&
                                equal(
                                  dynamicColor[data[index][rowData[0]]],
                                  dynamicColor['0'],
                                ),
                              'white',
                              '#333333',
                            )}
                          />,
                          <TableCell sx={{ border: 0 }}></TableCell>,
                        )}
                      </DKTForm>
                    </TableCell>,
                    ternary(
                      equal(rowData[0], 'document') &&
                        equal(row.isEditable, true),
                      <TableCell key={idx} sx={{ py: 0 }}>
                        <DKTForm autoComplete="off">
                          <Box
                            className={classNames({
                              [classes.dragIcon]: !hasOnlyViewPermission,
                            })}
                          >
                            {!hasOnlyViewPermission && row?.isDraggable && (
                              <DragIcon />
                            )}
                            {ternary(
                              hasOnlyViewPermission,
                              rowData[1],
                              <Box sx={{ paddingLeft: '10px', width: '500px' }}>
                                <DKTInput
                                  placeholder="Type Text Here"
                                  name={rowData[0]}
                                  id={rowId}
                                  sx={{
                                    color: theme.palette.primary.main,
                                  }}
                                  value={
                                    data[index] ? data[index][rowData[0]] : ''
                                  }
                                  className={classes.summaryTypeInput}
                                  onChange={(event) =>
                                    handleChange(event, index)
                                  }
                                />
                              </Box>,
                            )}
                          </Box>
                        </DKTForm>
                      </TableCell>,
                      ternary(
                        equal(rowData[0], 'includeInAppendix'),
                        ternary(
                          row?.isIncludeInAppendix,
                          <TableCell
                            key={idx}
                            sx={{
                              paddingBlock: 0.5,
                              paddingInline: 5,
                            }}
                          >
                            <DKTCheckbox
                              name="includeInAppendix"
                              handleChange={(event) =>
                                handleChange(event, index)
                              }
                              checked={
                                data[index] ? data[index][rowData[0]] : false
                              }
                              disabled={
                                hasOnlyViewPermission || row?.isDisabled
                              }
                            />
                          </TableCell>,
                          <TableCell key={idx}></TableCell>,
                        ),
                        <TableCell
                          key={idx}
                          sx={{
                            paddingBlock: 0.5,
                            ...(!row?.isDraggable && { height: '45px' }),
                          }}
                        >
                          <Box
                            className={classNames({
                              [classes.dragIcon]: !hasOnlyViewPermission,
                            })}
                          >
                            {!hasOnlyViewPermission && row?.isDraggable && (
                              <DragIcon />
                            )}
                            <Typography
                              sx={{
                                paddingLeft:
                                  !row?.isDraggable && !hasOnlyViewPermission
                                    ? '25px'
                                    : '10px',
                              }}
                            >
                              {equal(typeof rowData[1], BOOLEAN)
                                ? ''
                                : rowData[1]}
                            </Typography>
                          </Box>
                        </TableCell>,
                      ),
                    ),
                  )
                }
                return null
              })}
              {ternary(
                equal(row.isEditable, true),
                !hasOnlyViewPermission && (
                  <TableCell sx={{ borderBottom: 'none', p: 0, pl: 1 }}>
                    <IconButton
                      onClick={() => removeRow(row?.id)}
                      disabled={hasOnlyViewPermission}
                    >
                      <RemoveCircleOutline color="primary" />
                    </IconButton>
                  </TableCell>
                ),
                null,
              )}
            </TableRow>
          )}
        </Draggable>
      ))}

      {providedPlaceholder}
      <DKTDialog
        open={deleteNotificationOpen}
        handleClose={handleClose}
        title="&nbsp;"
        actions={deleteNotificationActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
    </>
  )
}

export default Documents
