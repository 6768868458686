import React from 'react'
import { ternary } from '../../Utils/javascript'

export const SearchIcon = ({ color = '#fff' }) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 12.5L20 20" stroke={color} />
    <circle cx="7.5" cy="7.5" r="7" stroke={color} />
  </svg>
)

export const UserIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9.5" stroke="white" />
    <circle cx="10" cy="7" r="3.5" stroke="white" />
    <path
      d="M17.1962 16.5C15.8979 13.8358 13.1636 12 10.0005 12C6.83734 12 4.10298 13.8358 2.80469 16.5"
      stroke="white"
    />
  </svg>
)

export const ExpandIcon = ({ color = 'black' }) => (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L6.5 7L12 1" stroke={color} />
  </svg>
)

export const ImagePlaceholder = (style) => (
  <svg
    width="94"
    height="61"
    viewBox="0 0 94 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <rect
      x="1"
      y="1.07666"
      width="92"
      height="58.1211"
      rx="2"
      stroke="#C4C4C4"
      strokeWidth="2"
    />
    <path
      d="M26.9043 35.3034C19.4116 42.2656 7.18922 53.533 5.68164 54.5613H88.319L63.1 23.9821C61.6462 22.2192 59.0135 22.0304 57.3229 23.5678L37.7036 41.4098L30.9663 35.2828C29.8117 34.2328 28.0476 34.2411 26.9043 35.3034Z"
      fill="#C4C4C4"
    />
    <ellipse
      cx="14.978"
      cy="14.6378"
      rx="6.71429"
      ry="6.10605"
      fill="#C4C4C4"
    />
  </svg>
)

export const CircledPlusIcon = ({ disabled }) => {
  const color = ternary(disabled, 'rgba(0, 0, 0, 0.26)', '#1564FF')
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.17871" cy="8" r="7.5" fill="white" stroke={color} />
      <path
        d="M8.17871 3.5V12"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4287 7.75L3.92871 7.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SortIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.5 1V17M14.5 17L19 13.2105M14.5 17L10 13.2105" stroke="black" />
    <path
      d="M5.5 17L5.5 0.999999M5.5 0.999999L1 4.78947M5.5 0.999999L10 4.78947"
      stroke="black"
    />
  </svg>
)

export const ColumnSortedAscendingIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.5 1V17M14.5 17L19 13.2105M14.5 17L10 13.2105" stroke="black" />
    <path
      d="M5.5 17L5.5 0.999999M5.5 0.999999L1 4.78947M5.5 0.999999L10 4.78947"
      stroke="black"
    />
  </svg>
)

export const ColumnSortedDescendingIcon = () => (
  <svg
    width="11"
    height="17"
    viewBox="0 0 11 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.5 0V16M5.5 16L10 12.2105M5.5 16L1 12.2105" stroke="#1564FF" />
  </svg>
)

export const DragIcon = () => (
  <svg
    width="13"
    height="10"
    viewBox="0 0 13 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.333984" y="0.833496" width="2.5" height="2.5" fill="#646464" />
    <rect x="0.333984" y="4.16699" width="2.5" height="2.5" fill="#646464" />
    <rect x="0.333984" y="7.5" width="2.5" height="2.5" fill="#646464" />
    <rect x="10.334" y="0.833496" width="2.5" height="2.5" fill="#646464" />
    <rect x="10.334" y="4.16699" width="2.5" height="2.5" fill="#646464" />
    <rect x="10.334" y="7.5" width="2.5" height="2.5" fill="#646464" />
    <rect x="7" y="0.833496" width="2.5" height="2.5" fill="#646464" />
    <rect x="7" y="4.16699" width="2.5" height="2.5" fill="#646464" />
    <rect x="7" y="7.5" width="2.5" height="2.5" fill="#646464" />
    <rect x="3.66797" y="0.833496" width="2.5" height="2.5" fill="#646464" />
    <rect x="3.66797" y="4.16699" width="2.5" height="2.5" fill="#646464" />
    <rect x="3.66797" y="7.5" width="2.5" height="2.5" fill="#646464" />
  </svg>
)

export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-trash3"
    viewBox="0 0 16 16"
  >
    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
  </svg>
)

export const DangerIcon = () => (
  <svg
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="ReportProblemOutlinedIcon"
    height={20}
    width={20}
  >
    <path
      d="M12 5.99 19.53 19H4.47L12 5.99"
      style={{ fill: 'none', stroke: 'white', strokeWidth: 0 }}
    ></path>
    <path
      d="M12 2 1 21h22L12 2"
      style={{ fill: 'none', stroke: 'white', strokeWidth: 1 }}
    ></path>
    <rect
      x="11"
      y="17"
      width="2"
      height="1"
      style={{ fill: 'white', stroke: 'white', strokeWidth: 1 }}
    ></rect>
    <rect
      x="11"
      y="9"
      width="2"
      height="4"
      style={{ fill: 'white', stroke: 'white', strokeWidth: 1 }}
    ></rect>
  </svg>
)
export const ShareIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6495 0.799565C18.4834 -0.72981 16.0093 0.081426 16.0093 1.99313V3.91272C12.2371 3.86807 9.65665 5.16473 7.9378 6.97554C6.10034 8.9113 5.34458 11.3314 5.02788 12.9862C4.86954 13.8135 5.41223 14.4138 5.98257 14.6211C6.52743 14.8191 7.25549 14.7343 7.74136 14.1789C9.12036 12.6027 11.7995 10.4028 16.0093 10.5464V13.0069C16.0093 14.9186 18.4834 15.7298 19.6495 14.2004L23.3933 9.29034C24.2022 8.2294 24.2022 6.7706 23.3933 5.70966L19.6495 0.799565ZM7.48201 11.6095C9.28721 10.0341 11.8785 8.55568 16.0093 8.55568H17.0207C17.5792 8.55568 18.0319 9.00103 18.0319 9.55037L18.0317 13.0069L21.7754 8.09678C22.0451 7.74313 22.0451 7.25687 21.7754 6.90322L18.0317 1.99313V4.90738C18.0317 5.4567 17.579 5.90201 17.0205 5.90201H16.0093C11.4593 5.90201 9.41596 8.33314 9.41596 8.33314C8.47524 9.32418 7.86984 10.502 7.48201 11.6095Z"
      fill="currentColor"
    />
    <path
      d="M7 1.00391H4C2.34315 1.00391 1 2.34705 1 4.00391V20.0039C1 21.6608 2.34315 23.0039 4 23.0039H20C21.6569 23.0039 23 21.6608 23 20.0039V17.0039C23 16.4516 22.5523 16.0039 22 16.0039C21.4477 16.0039 21 16.4516 21 17.0039V20.0039C21 20.5562 20.5523 21.0039 20 21.0039H4C3.44772 21.0039 3 20.5562 3 20.0039V4.00391C3 3.45162 3.44772 3.00391 4 3.00391H7C7.55228 3.00391 8 2.55619 8 2.00391C8 1.45162 7.55228 1.00391 7 1.00391Z"
      fill="currentColor"
    />
  </svg>
)
