import React, { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useStyles } from '../../../Styles/createCompanyPersonnel.style'
import useForm from '../../../Hooks/useForm'
import {
  entries,
  equal,
  isArray,
  keys,
  ternary,
} from '../../../Utils/javascript'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTForm from '../../../Shared/DKTForm'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import { rfpFieldList } from '../../../Description/createNewRfp.description'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTInput from '../../../Shared/DKTInput'
import DKTButton from '../../../Shared/DKTButton'
import { createNewRfp } from '../../../Redux/actions/rfp'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { getStateList } from '../../../Redux/actions/states'

const CreateNewRFP = ({ open, handleClose, data = null }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const [dynamicOptions, setDynamicOptions] = useState({})
  const { state } = useSelector(({ states }) => states)
  const { companyData } = useSelector(({ company }) => company)
  const { isLoadingNewRfp } = useSelector(({ rfpData }) => rfpData)

  const { values, setValues, errors, handleChange, handleSubmit, resetForm } =
    useForm(rfpFieldList)

  useEffect(() => {
    dispatch(getStateList())
  }, [])

  const handleSave = useCallback(() => {
    const isFormValid = handleSubmit()
    if (isFormValid) {
      const payload = {
        company: companyData?.[0]?.id,
        project_name: values?.projectName,
        city: values?.city,
        state: values?.state,
        zip_code: values?.zipCode,
        address: values?.address,
        ...(values?.id && { id: values?.id }),
      }
      dispatch(
        createNewRfp(payload, navigate, values, {
          onSuccess: () => {
            handleClose()
            resetForm()
          },
        }),
      )
    }
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    dispatch(updateOnSaveStatus({ loader: false }))
  }, [handleClose, dispatch, handleSubmit, values])

  useEffect(() => {
    setDynamicOptions({
      ...dynamicOptions,
      state: [...state],
    })
  }, [state])

  useEffect(() => {
    if (data) {
      setValues(data)
    }
  }, [data])

  useEffect(() => {
    setIsUnSavedChanges(() => {
      if (keys(data).length) {
        const isUnSaved = keys(rfpFieldList).some(
          (name) => !equal(data[name], values[name]),
        )
        return isUnSaved
      }
      const hasValues = Object.values(values).some((value) => value)
      return hasValues
    })
  }, [values])

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            defaultValue,
            options,
            isConfirmPassword,
            dependsOn,
            overrideOptions,
            isDynamicClientName,
            ...formField
          },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )

        const optValues = isDynamicOptions
          ? dynamicOptions[name] || []
          : options
        return (
          <Grid
            key={index}
            item
            xs={formField.xs ?? 12}
            lg={formField.lg ?? 12}
          >
            <Stack direction="row" width="100%" spacing={2} alignItems="end">
              <FormControl
                {...formField}
                options={optValues}
                isRequired={isRequired}
                id={name}
                name={name}
                value={(values && values[name]) || defaultValue || ''}
                onChange={handleChange}
                error={errors[name]}
                disabled={formField.disabled}
              />
            </Stack>
          </Grid>
        )
      },
    )

  const renderFormFields = (formFields) =>
    isArray(formFields)
      ? formFields?.map((formField) =>
          mapFormFields({
            formFields: formField?.data,
            makeFieldNameUnique: true,
            id: formField?.id,
          }),
        )
      : mapFormFields({ formFields })
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    handleClose()
    resetForm()
  }
  const handleCloseAddPhase = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    handleClose()
    resetForm()
  }

  const onSave = () => {
    handleSave()
  }

  const dialogActions = (
    <DKTButton
      variant="contained"
      onClick={handleSave}
      disabled={isLoadingNewRfp}
      sx={{ ml: 'auto' }}
    >
      {ternary(isLoadingNewRfp, 'Saving...', 'Save')}
    </DKTButton>
  )

  return (
    <>
      <DKTDialog
        open={open}
        handleClose={handleCloseAddPhase}
        title="Create/Edit New RFP"
        actions={dialogActions}
        maxWidth="xs"
        className={classes.companyPersonalModal}
      >
        <DKTForm autoComplete="off">
          <Grid container spacing={2}>
            {renderFormFields(rfpFieldList)}
          </Grid>
        </DKTForm>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnSavedWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={onSave}
      />
    </>
  )
}

export default memo(CreateNewRFP)
