import { Delete } from '@mui/icons-material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import useForm from '../../../Hooks/useForm'
import { OuterLayout } from '../../../Layout/reportMonitoring'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import {
  SA,
  clientPortal,
  consultantPortal,
  spinnerSize,
} from '../../../Utils/constant'
import {
  equal,
  isArray,
  keys,
  modifyNumber,
  ternary,
} from '../../../Utils/javascript'
import { ShareIcon } from '../../../Assets/SVGs'
import {
  columns,
  emptyTableCellPlaceholder,
  options,
  optionsFields,
} from '../../../Description/reportMonitoring.description'
import {
  clearGenerateLinkFailed,
  generateLink,
} from '../../../Redux/actions/ReportDownload'
import { getProjectList } from '../../../Redux/actions/projects'
import { getReportChoices } from '../../../Redux/actions/reportChoices'
import {
  createAndUpdateReport,
  deleteReport,
  getReportList,
  publishReport,
} from '../../../Redux/actions/reportMonitoring'
import { getReportType } from '../../../Redux/actions/reportType'
import DKTTooltip from '../../../Shared/DKTTooltip'
import { showToast } from '../../../Utils/toastService'
import PerformanceChart from './PerformanceChart'

const btnStyle = makeStyles(() => ({
  addBtn: {
    marginLeft: 0,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  inlineLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 'bold',
      color: '#2D2D2D',
      fontSize: 14,
      marginBottom: 8,
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  publishedNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#646464',
      fontSize: 15,
      fontWeight: 500,
      marginBottom: 15,
      maxWidth: '38ch',
      marginInline: 'auto',
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  reportListTable: {
    '& .MuiCheckbox-root.MuiButtonBase-root': {
      padding: [[0, 9]],
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          padding: '11.5px',
        },
      },
    },
    '& .MuiTableCell-body': {
      padding: 3,
    },
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root:last-child': {
        textAlign: 'right',
        width: '6%',
      },
      '& .MuiTableCell-root:first-child': {
        backgroundColor: 'white',
      },
    },
  },
  acquireTable: {
    '& .MuiTableCell-body': {
      padding: '8px 10px',
      '&:nth-child(2)': {
        '& .MuiButtonBase-root': {
          justifyContent: 'start',
        },
      },
    },
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          padding: '16px 10px',
        },
      },
    },
    '&.acquireTableClient': {
      '& .MuiTableCell-body': {
        padding: '8px 10px',
      },
    },
  },
  emptyTitle: {
    '&.MuiTypography-root': {
      padding: [[12, 0]],
      lineHeight: '1',
    },
  },
}))

const ReportMonitoring = () => {
  const dispatch = useDispatch()
  const classes = btnStyle()
  const navigate = useNavigate()
  const { slug } = useParams()

  const { values, setValues, setFieldValue } = useForm()

  const [dynamicOptions, setDynamicOptions] = useState({})
  const [ownerContractorId, setOwnerContractorId] = useState(null)

  const [deleteNotificationOpen, setOpenDeleteNotification] = useState(false)
  const [publishedNotificationOpen, setOpenPublishedNotification] =
    useState(false)
  const [unPublishedNotificationOpen, setOpenUnPublishedNotification] =
    useState(false)
  const [reportForPublishing, setReportForPublishing] = useState(null)
  const [downloadReportId, setDownloadReportId] = useState(null)
  const [isAttachmentInclude, setIsAttachmentInclude] = useState(null)
  const [downloadNotificationOpen, setDownloadNotificationOpen] =
    useState(false)
  const [generateLinkNotificationOpen, setGenerateLinkNotificationOpen] =
    useState(false)
  const [reportPdfLink, setReportPdfLink] = useState(null)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)

  const {
    isReportListLoading,
    isCreateAndUpdateReportLoading,
    isReportDeletingLoading,
    reportList,
    isReportPublishingLoading,
    isReportDownloadingLoading,
    isGenerateLinkLoading,
    reportLink,
  } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)
  const { projectList } = useSelector(({ projects }) => projects)
  const { reportTypeList } = useSelector(({ reportType }) => reportType)

  const { systemAuthorization, portalType, projects } = useSelector(
    ({ auth }) => auth,
  )
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+slug]?.viewer
  const isClientCompany = equal(portalType, clientPortal)

  const hasOnlyViewPermission =
    isGlobalViewer || isProjectViewer || isClientCompany

  const reportToRemove = useRef(null)
  const setSelectedRows = useRef(() => {})

  const reportNo = reportList?.find((item) =>
    equal(item?.id, downloadReportId),
  )?.overrideReportCode

  useEffect(() => {
    if (ownerContractorId) {
      dispatch(getReportList(slug, ownerContractorId))
    }
  }, [
    dispatch,
    slug,
    ownerContractorId,
    isCreateAndUpdateReportLoading,
    isReportDeletingLoading,
    isReportPublishingLoading,
  ])

  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionsFields?.join(','),
        projectId: slug,
      }),
    )
  }, [dispatch, slug])

  useEffect(() => {
    dispatch(getProjectList())
    dispatch(getReportType())
  }, [dispatch])

  useEffect(() => {
    if (choices) {
      const dynamicDropdown = (ary) => {
        const res = ary?.map((element) => ({
          label: element?.uniqueCode || element?.projectName,
          value: `${element?.id}`,
        }))
        return res
      }
      const dynamicReportType = (ary) => {
        const res = ary?.map((element) => ({
          label: element?.name,
          value: `${element?.id}`,
        }))
        return res
      }
      const ownerContractor = dynamicDropdown(choices?.ownerContractor)
      const project = dynamicDropdown(projectList)
      const reportType = dynamicReportType(reportTypeList)
      setDynamicOptions({
        ...choices,
        ownerContractor,
        project,
        reportType,
      })
    }
  }, [choices, projectList, reportTypeList])

  useEffect(() => {
    const populateObj = {
      project: slug,
      reportType: '1',
    }
    if (equal(dynamicOptions?.ownerContractor?.length, 1)) {
      populateObj.ownerContractor = dynamicOptions?.ownerContractor[0]?.value
    }
    setValues(populateObj)
  }, [slug, setValues, dynamicOptions?.ownerContractor])

  useEffect(() => {
    let activeContractor = null
    if (isArray(choices?.ownerContractor) && choices?.ownerContractor?.length) {
      activeContractor = choices?.ownerContractor[0]?.id
    }
    setOwnerContractorId(activeContractor)
  }, [choices?.ownerContractor[0]?.id])

  useEffect(() => {
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    setFieldValue('ownerContractorDescription', activeContractorDescription)
  }, [ownerContractorId])

  useEffect(() => {
    if (!isReportDownloadingLoading) {
      handleCloseDownlaModal()
    }
  }, [isReportDownloadingLoading])

  useEffect(() => {
    if (generateLinkNotificationOpen) {
      if (reportLink) {
        setReportPdfLink(reportLink?.url)
      }
    }
  }, [reportLink, generateLinkNotificationOpen, reportList])

  const navigateToReport = (id, path) => {
    navigate(`/projects/${slug}/project-reports/${id}/${path}`)
  }

  const handleCreateNewReport = () => {
    if (!choices?.ownerContractor?.length) {
      showToast(
        'Complete ‘Owner-Contractor’ to create new report. ',
        'error',
        'top-right',
      )
    }
    if (equal(choices?.ownerContractor?.length, 1)) {
      handleCreateReport()
    }
  }

  const handlePublishedAndUnPublishedReport = (rptForPublish) => {
    const item = reportList?.find((report) => equal(report?.id, rptForPublish))
    const secondLastReport = reportList?.at(-2)
    const isPriorReportUp = reportList
      ?.slice(0, -2)
      ?.some(({ status }) => equal(status, 'Unpublished'))

    if (
      (!item?.isSecondLastReport &&
        secondLastReport &&
        equal(secondLastReport?.status, 'Unpublished')) ||
      isPriorReportUp
    ) {
      showToast(
        'Publish prior report before attempting to publish the current report',
        'error',
      )
    } else if (equal(item?.status, 'Published')) {
      setOpenUnPublishedNotification(true)
    } else {
      setOpenPublishedNotification(true)
    }
    setReportForPublishing(rptForPublish)
  }

  const getLatestValue = (value, format) =>
    ternary(
      value && value.length > 0,
      format(value[value.length - 1]),
      emptyTableCellPlaceholder,
    )
  const getValue = (value, format) =>
    ternary(value, format(value), emptyTableCellPlaceholder)
  const append = (string, postfix) => `${string} ${postfix}`
  const prepend = (string, prefix) => `${prefix} ${string}`
  const formatDate = (date) =>
    ternary(date, moment(date).format('MM/DD/YY'), '-')

  const handleCancelApiCall = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Request cancelled by user')
    }
  }

  const handleOpenGenerateLinkModal = (id) => {
    setDownloadReportId(id)
    setGenerateLinkNotificationOpen(true)
  }
  const handleCloseGenerateLinkModal = () => {
    setDownloadReportId(null)
    setGenerateLinkNotificationOpen(false)
    setReportPdfLink('')
    dispatch(clearGenerateLinkFailed())
    handleCancelApiCall()
  }

  const handleClickBack = () => {
    setReportPdfLink('')
    dispatch(clearGenerateLinkFailed())
  }

  const copyReportLinkToClipboard = () => {
    if (reportPdfLink) {
      navigator.clipboard
        .writeText(reportPdfLink)
        .then(() => {
          showToast('Copied to clipboard!', 'success')
        })
        .catch(() => {
          showToast('Failed Copied to clipboard!', 'success')
        })
    }
  }

  const handleOpenDownlaModal = (id) => {
    setDownloadReportId(id)
    setDownloadNotificationOpen(true)
  }
  const handleCloseDownlaModal = () => {
    setDownloadReportId(null)
    setDownloadNotificationOpen(false)
    handleCancelApiCall()
  }

  const handleDownloadAndGeneratePdf = ({
    isIncludeAttachment,
    isGeneratedReport = false,
  }) => {
    setIsAttachmentInclude(isIncludeAttachment)
    dispatch(
      generateLink({
        reportIds: downloadReportId,
        isIncludeAttachment,
        isGeneratedReport,
        setCancelTokenSource,
        projectId: slug,
      }),
    )
  }

  const customBodyRender = useCallback(
    (value, tableMeta) => {
      const [isLastReport] = tableMeta.rowData.slice(-2, -1)
      const [isSecondLastReport] = tableMeta.rowData.slice(-1)
      switch (tableMeta?.columnData?.name) {
        case 'status':
          return ternary(
            ternary(
              isSecondLastReport,
              reportList[reportList.length - 1]?.isLastPublished,
              !isLastReport,
            ),
            value,
            <DKTButton
              variant="text"
              style={{ backgroundColor: 'transparent', paddingLeft: '0' }}
              onClick={() =>
                handlePublishedAndUnPublishedReport(tableMeta.rowData[0])
              }
              disabled={hasOnlyViewPermission}
            >
              {value}
            </DKTButton>,
          )
        case ' ':
          return (
            <Stack
              direction="row"
              spacing={0.2}
              justifyContent="end"
              paddingRight="5px"
            >
              <DKTTooltip title="Download Report">
                <IconButton
                  onClick={() => handleOpenDownlaModal(tableMeta.rowData[0])}
                >
                  <CloudDownloadIcon />
                </IconButton>
              </DKTTooltip>
              <DKTTooltip title="Share Report Link">
                <IconButton
                  onClick={() =>
                    handleOpenGenerateLinkModal(tableMeta.rowData[0])
                  }
                  sx={{ marginRight: '12px !important' }}
                >
                  <ShareIcon />
                </IconButton>
              </DKTTooltip>
            </Stack>
          )

        case 'drawNumber':
          return ternary(
            value && value.length > 0,
            <DKTTooltip title={[...new Set(value)]?.join(', ')}>
              <span> {[...new Set(value)]?.join(', ')}</span>
            </DKTTooltip>,
            emptyTableCellPlaceholder,
          )
        case 'payApps':
          // eslint-disable-next-line no-case-declarations
          const displayValue = value?.payapp?.map(
            (data, index) => value?.override[index] ?? data,
          )
          return ternary(
            displayValue && displayValue.length > 0,
            <DKTTooltip title={[...new Set(displayValue)]?.join(', ')}>
              <span> {[...new Set(displayValue)]?.join(', ')}</span>
            </DKTTooltip>,
            emptyTableCellPlaceholder,
          )

        case 'percentComplete':
          return getLatestValue(value, (val) => append(val, '%'))
        case 'periodTo':
          return getLatestValue(value, formatDate)
        case 'changeOrderCurrent':
          return getLatestValue(value, (val) => prepend(modifyNumber(val), '$'))
        case 'completionDate':
          return getValue(value, formatDate)
        case 'remainingHCC':
          return getValue(value, (val) => prepend(modifyNumber(val), '$'))
        case 'publishedAt':
          return getValue(value, formatDate)

        case 'overrideReportCode':
          return (
            <DKTButton
              variant="text"
              style={{ backgroundColor: 'transparent', paddingLeft: '0' }}
              onClick={() =>
                navigateToReport(tableMeta.rowData[0], 'executive-summary')
              }
              disabled={hasOnlyViewPermission}
            >
              {value}
            </DKTButton>
          )

        default:
          return emptyTableCellPlaceholder
      }
    },
    [navigateToReport, hasOnlyViewPermission],
  )

  const actions = () => (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Stack direction="row" gap={2} className={classes.rightButton}>
        {ternary(
          !equal(portalType, clientPortal) &&
            (equal(systemAuthorization, SA.administrator) ||
              equal(systemAuthorization, SA.globalEditor) ||
              equal(systemAuthorization, SA.projectLevel)),
          <DKTButton
            onClick={handleCreateNewReport}
            disabled={isGlobalViewer || isReportListLoading}
          >
            Create New
          </DKTButton>,
          null,
        )}
      </Stack>
    </Box>
  )

  const handleClose = () => {
    setOpenDeleteNotification(false)
    setOpenPublishedNotification(false)
    setOpenUnPublishedNotification(false)
  }

  const handleDeleteTableRow = () => {
    const body = {
      report: reportToRemove?.current,
    }
    dispatch(deleteReport(body, slug, ownerContractorId))
    setOpenDeleteNotification(false)
    setSelectedRows.current([])
  }

  const handlePublishingStartReport = () => {
    const data = reportList?.find((item) => item.id === reportForPublishing)
    const isPublish = equal(data?.status, 'Unpublished')
    dispatch(
      publishReport(
        reportForPublishing,
        slug,
        ownerContractorId,
        isPublish,
        handleClose,
      ),
    )
  }
  const customToolbarSelect = useCallback(
    (selectedRows, displayData, setSelectedTableRows) => {
      const selectedReport = keys(selectedRows.lookup)
      const selectedReportIds = selectedReport.map(
        (rowToDelete) => reportList[rowToDelete]?.id,
      )
      setSelectedRows.current = setSelectedTableRows
      const setReportToRemove = () => {
        reportToRemove.current = selectedReportIds
      }
      const lastReportId = reportList?.at(-1)?.id
      const disabledDelete =
        selectedReportIds?.length === 1 && lastReportId === selectedReportIds[0]
      return (
        <Box mr={2.5}>
          {!equal(systemAuthorization, clientPortal) && (
            <DKTTooltip title="Only last report can be deleted">
              <span>
                <IconButton
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setReportToRemove()
                    setOpenDeleteNotification(true)
                  }}
                  disabled={!disabledDelete}
                >
                  <Delete color={!disabledDelete ? 'disabled' : 'error'} />
                </IconButton>
              </span>
            </DKTTooltip>
          )}
          <IconButton
            onClick={() => handleOpenDownlaModal(selectedReportIds)}
            disabled={isReportDownloadingLoading}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Box>
      )
    },
    [reportList, isReportDownloadingLoading],
  )
  const tableOptions = {
    ...options,
    textLabels: {
      body: {
        noMatch: isReportListLoading ? (
          <DKTCircularProgress />
        ) : (
          <Typography className={classes.emptyTitle}>
            Sorry, there is no matching data to display
          </Typography>
        ),
      },
    },
    customToolbarSelect,
    selectableRows: ternary(
      equal(systemAuthorization, SA.administrator) &&
        equal(portalType, consultantPortal),
      'multiple',
      'none',
    ),
    rowsPerPageOptions: [5, 10, 20],
  }
  const tableColumns = columns?.map((report) => ({
    ...report,
    options: { ...report.options, customBodyRender },
  }))

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteTableRow}>
        {ternary(
          isReportDeletingLoading,
          <DKTCircularProgress color="white" />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const publishedNotificationAction = (
    <>
      <DKTButton variant="outlined" disableElevation onClick={handleClose}>
        Cancel
      </DKTButton>
      <DKTButton
        variant="contained"
        onClick={() => handlePublishingStartReport(true)}
      >
        {ternary(
          isReportPublishingLoading,
          <DKTCircularProgress color="white" />,
          'Continue',
        )}
      </DKTButton>
    </>
  )
  const unPublishedNotificationAction = (
    <>
      <DKTButton variant="outlined" disableElevation onClick={handleClose}>
        Cancel
      </DKTButton>
      <DKTButton
        variant="contained"
        onClick={() => handlePublishingStartReport(false)}
      >
        {ternary(
          isReportPublishingLoading,
          <DKTCircularProgress color="white" />,
          'Continue',
        )}
      </DKTButton>
    </>
  )
  const downloadNotificationAction = (
    <>
      <DKTButton
        variant="outlined"
        disableElevation
        onClick={() =>
          handleDownloadAndGeneratePdf({
            isIncludeAttachment: false,
          })
        }
        disabled={isReportDownloadingLoading || isGenerateLinkLoading}
        startIcon={
          !isAttachmentInclude &&
          isReportDownloadingLoading && (
            <DKTCircularProgress size={spinnerSize?.sm} color="grey" />
          )
        }
      >
        Report Only
      </DKTButton>
      <DKTButton
        variant="contained"
        onClick={() =>
          handleDownloadAndGeneratePdf({
            isIncludeAttachment: true,
          })
        }
        disabled={isReportDownloadingLoading || isGenerateLinkLoading}
        startIcon={
          isAttachmentInclude &&
          isReportDownloadingLoading && (
            <DKTCircularProgress size={spinnerSize?.sm} color="grey" />
          )
        }
      >
        Report + Attachments
      </DKTButton>
    </>
  )

  const generateLinkModalAction = reportPdfLink ? (
    <>
      <DKTButton
        variant="outlined"
        disableElevation
        onClick={handleClickBack}
        sx={{ cursor: 'pointer' }}
      >
        Back
      </DKTButton>
      <DKTButton
        variant="contained"
        sx={{ ml: 'auto', cursor: 'pointer' }}
        onClick={copyReportLinkToClipboard}
      >
        Copy
      </DKTButton>
    </>
  ) : (
    <>
      <DKTButton
        variant="outlined"
        disableElevation
        onClick={() =>
          handleDownloadAndGeneratePdf({
            isIncludeAttachment: false,
            isGeneratedReport: true,
          })
        }
        startIcon={
          !isAttachmentInclude &&
          isGenerateLinkLoading && (
            <DKTCircularProgress size={spinnerSize?.sm} color="grey" />
          )
        }
        disabled={isGenerateLinkLoading}
      >
        Report Only
      </DKTButton>
      <DKTButton
        variant="contained"
        onClick={() =>
          handleDownloadAndGeneratePdf({
            isIncludeAttachment: true,
            isGeneratedReport: true,
          })
        }
        startIcon={
          isAttachmentInclude &&
          isGenerateLinkLoading && (
            <DKTCircularProgress size={spinnerSize?.sm} color="grey" />
          )
        }
        disabled={isGenerateLinkLoading}
      >
        Report + Attachments
      </DKTButton>
    </>
  )

  const handleCreateReport = () => {
    const { ownerContractor, project, reportType } = values
    const body = {
      ownerContractor,
      project,
      reportType,
    }
    dispatch(
      createAndUpdateReport(body, slug, ownerContractorId, handleClose),
    ).then((reportId) => {
      if (equal(choices?.ownerContractor?.length, 1) && reportId) {
        navigateToReport(
          reportId,
          // eslint-disable-next-line no-template-curly-in-string
          `executive-summary/?ocId=${ownerContractorId}`,
        )
      }
    })
  }

  return (
    <OuterLayout actions={actions} isShowMenu>
      <PerformanceChart projectId={slug} />
      <Box px={5}>
        <Grid item xs={4} mt={6}>
          <Typography variant="h6" fontWeight={700}>
            Construction Monitoring Reports (CMR)
          </Typography>
        </Grid>
        {equal(isReportListLoading, 'FAILED') ? (
          <Typography className={classes.emptyTitle}>
            There might be some issue with fetching Report data. Please try
            contacting the admin or refreshing this page.
          </Typography>
        ) : (
          <DKTMuiDataTable
            data={reportList || [{}]}
            options={tableOptions}
            columns={tableColumns}
            className={`${classes.reportListTable} ${classes.acquireTable} ${
              equal(portalType, clientPortal) && 'acquireTableClient'
            }`}
          />
        )}

        <DKTDialog
          open={deleteNotificationOpen}
          handleClose={handleClose}
          title="&nbsp;"
          actions={deleteNotificationAction}
          maxWidth="xs"
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.deleteNotificationContent}>
                <Typography variant="h5">
                  Are you sure you want to delete?
                </Typography>
                <Typography variant="body1">
                  You can&apos;t undo this action
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DKTDialog>
        <DKTDialog
          open={publishedNotificationOpen}
          handleClose={handleClose}
          title="Publish"
          actions={publishedNotificationAction}
          maxWidth="xs"
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.publishedNotificationContent}>
                <Typography variant="h5" color="grey">
                  Publishing a report suggests it is ready for Client use.
                  Continue?
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DKTDialog>
        <DKTDialog
          open={unPublishedNotificationOpen}
          handleClose={handleClose}
          title="Unpublish"
          actions={unPublishedNotificationAction}
          maxWidth="xs"
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.publishedNotificationContent}>
                <Typography variant="h5" color="grey">
                  Unpublishing a report will redact it from Client use.
                  Continue?
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DKTDialog>
        <DKTDialog
          open={downloadNotificationOpen}
          handleClose={handleCloseDownlaModal}
          title="Download"
          actions={downloadNotificationAction}
          maxWidth="xs"
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.publishedNotificationContent}>
                <Typography variant="h5" color="grey">
                  Confirm the type of download.
                </Typography>
                {(isReportDownloadingLoading || isGenerateLinkLoading) && (
                  <Typography variant="h5" color="grey">
                    Please do not close or refresh the page while the download
                    pdf is in progress.
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </DKTDialog>
        <DKTDialog
          open={generateLinkNotificationOpen}
          handleClose={handleCloseGenerateLinkModal}
          title={`Share - Report ${reportNo || ''}`}
          actions={generateLinkModalAction}
          maxWidth="xs"
        >
          <Grid container>
            <Grid item xs={12}>
              {reportPdfLink ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Stack
                    p={1}
                    sx={{
                      border: '1px solid gray',
                      flexGrow: 1,
                      height: '23px',
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="p"
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        userSelect: 'none',
                      }}
                    >
                      {reportPdfLink || ''}
                    </Typography>
                  </Stack>
                </Box>
              ) : (
                <Box className={classes.publishedNotificationContent}>
                  <Typography variant="h5" color="grey">
                    Confirm the type of PDF report link.
                  </Typography>
                  {isGenerateLinkLoading && (
                    <Typography variant="h5" color="grey">
                      Please do not close or refresh the page while the link is
                      being generated.
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        </DKTDialog>
      </Box>
      <Outlet />
    </OuterLayout>
  )
}

export default ReportMonitoring
