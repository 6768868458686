import { equal, ternary } from '../../Utils/javascript'
import {
  ADD_SITE_BUILDING,
  CLEAR_SITE_AND_BUILDING,
  CREATE_SITE_AND_BUILDING_FAILED,
  CREATE_SITE_AND_BUILDING_REQUESTED,
  CREATE_SITE_AND_BUILDING_SUCCESS,
  DELETE_SITE_AND_BUILDING_FAILED,
  DELETE_SITE_AND_BUILDING_REQUESTED,
  DELETE_SITE_AND_BUILDING_SUCCESS,
  FETCH_BUILDING_UNIT_INFO_FAILED,
  FETCH_BUILDING_UNIT_INFO_REQUESTED,
  FETCH_BUILDING_UNIT_INFO_SUCCESS,
  FETCH_SITE_AND_BUILDING_FAILED,
  FETCH_SITE_AND_BUILDING_REQUESTED,
  FETCH_SITE_AND_BUILDING_SUCCESS,
  REMOVE_BUILDING_UNIT_INFO_FAILED,
  REMOVE_BUILDING_UNIT_INFO_REQUESTED,
  REMOVE_BUILDING_UNIT_INFO_SUCCESS,
  SAVE_BUILDING_UNIT_INFO_FAILED,
  SAVE_BUILDING_UNIT_INFO_REQUESTED,
  SAVE_BUILDING_UNIT_INFO_SUCCESS,
  SAVE_SITE_AND_BUILDING_FAILED,
  SAVE_SITE_AND_BUILDING_REQUESTED,
  SAVE_SITE_AND_BUILDING_SUCCESS,
  SET_SITE_ID,
} from '../constants/siteAndBuilding'

const initialState = {
  siteAndBuildings: null,
  buildingAndUnitsInfo: null,
  isCreatingSiteAndBuilding: false,
  isFetchingSiteAndBuilding: true,
  isFetchingBuildingUnitInfo: true,
  isDeletingSiteBuilding: false,
  isSavingBuildingAndUnits: false,
  isRemovingBuildingAndUnits: false,
  createSiteAndBuildingError: null,
  fetchSiteAndBuildingError: null,
  buildingAndUnitError: null,
  saveBuildingAndUnitsError: null,
  removeBuildingAndUnitsError: null,
  siteId: null,
}

export const siteAndBuilding = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_SITE_AND_BUILDING_REQUESTED:
      return {
        ...state,
        isFetchingSiteAndBuilding: true,
      }
    case FETCH_SITE_AND_BUILDING_SUCCESS:
      return {
        ...state,
        isFetchingSiteAndBuilding: false,
        siteAndBuildings: action.payload,
      }
    case FETCH_SITE_AND_BUILDING_FAILED:
      return {
        ...state,
        isFetchingSiteAndBuilding: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchSiteAndBuildingError: action.payload?.data,
      }

    case FETCH_BUILDING_UNIT_INFO_REQUESTED:
      return {
        ...state,
        isFetchingBuildingUnitInfo: true,
      }
    case FETCH_BUILDING_UNIT_INFO_SUCCESS:
      return {
        ...state,
        isFetchingBuildingUnitInfo: false,
        buildingAndUnitsInfo: action.payload,
      }
    case FETCH_BUILDING_UNIT_INFO_FAILED:
      return {
        ...state,
        isFetchingBuildingUnitInfo: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        buildingAndUnitError: action.payload?.data,
      }
    case CREATE_SITE_AND_BUILDING_REQUESTED:
      return {
        ...state,
        isCreatingSiteAndBuilding: true,
      }
    case CREATE_SITE_AND_BUILDING_SUCCESS:
      return {
        ...state,
        isCreatingSiteAndBuilding: false,
        siteAndBuildings: action.payload,
        createSiteAndBuildingError: '',
      }
    case CREATE_SITE_AND_BUILDING_FAILED:
      return {
        ...state,
        isCreatingSiteAndBuilding: false,
        createSiteAndBuildingError: action.payload,
      }

    case SAVE_SITE_AND_BUILDING_REQUESTED:
      return {
        ...state,
        isSavingSiteAndBuilding: true,
        createSiteAndBuildingError: '',
      }
    case SAVE_SITE_AND_BUILDING_SUCCESS:
      return {
        ...state,
        isSavingSiteAndBuilding: false,
        siteAndBuildings: action.payload,
        createSiteAndBuildingError: '',
      }
    case SAVE_SITE_AND_BUILDING_FAILED:
      return {
        ...state,
        isSavingSiteAndBuilding: false,
        createSiteAndBuildingError: action.payload,
      }

    case SAVE_BUILDING_UNIT_INFO_REQUESTED:
      return {
        ...state,
        isSavingBuildingAndUnits: true,
      }
    case SAVE_BUILDING_UNIT_INFO_SUCCESS:
      return {
        ...state,
        isSavingBuildingAndUnits: false,
        buildingAndUnitsInfo: action.payload,
        saveBuildingAndUnitsError: '',
      }
    case SAVE_BUILDING_UNIT_INFO_FAILED:
      return {
        ...state,
        isSavingBuildingAndUnits: false,
        saveBuildingAndUnitsError: action.payload,
      }
    case REMOVE_BUILDING_UNIT_INFO_REQUESTED:
      return {
        ...state,
        isRemovingBuildingAndUnits: true,
      }
    case REMOVE_BUILDING_UNIT_INFO_SUCCESS:
      return {
        ...state,
        isRemovingBuildingAndUnits: false,
      }
    case REMOVE_BUILDING_UNIT_INFO_FAILED:
      return {
        ...state,
        isRemovingBuildingAndUnits: false,
        removeBuildingAndUnitsError: action.payload,
      }
    case ADD_SITE_BUILDING:
      return {
        ...state,
        siteAndBuildings: action.payload,
      }

    case SET_SITE_ID:
      return {
        ...state,
        siteId: action.payload,
      }
    // Delete site and building
    case DELETE_SITE_AND_BUILDING_REQUESTED:
      return {
        ...state,
        isDeletingSiteBuilding: true,
      }
    case DELETE_SITE_AND_BUILDING_SUCCESS:
      return {
        ...state,
        isDeletingSiteBuilding: false,
        siteAndBuildings: action.payload,
        siteId: action?.activeSiteBuilding,
      }
    case DELETE_SITE_AND_BUILDING_FAILED:
      return {
        ...state,
        isDeletingSiteBuilding: false,
      }
    case CLEAR_SITE_AND_BUILDING:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
