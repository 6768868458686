import { numberPostFix, startYear, STRING } from './constant'
import { equal, lowerCase, ternary, typeOf } from './javascript'

export const emailValidation = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(lowerCase(email))
}

export const confirmPassword = (confirmPass, pass) => equal(confirmPass, pass)

export const alphabeticStringValidation = (val) => {
  const regex = /^[a-zA-Z0-9@äëïöüÿÄËÏÖÜŸ)/(._-\s]+$/
  return regex.test(val)
}

export const alphaNumericValidation = (val) => {
  const regex = /^[A-Z0-9]{6}$/
  return regex.test(val)
}

export const number = (val) => {
  const regex = /^[0-9]+$/
  return regex.test(val)
}

export const mobileNumber = (val) => {
  const regex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
  return regex.test(val) && equal(val.toString().length, 12)
}

export const validatePassword = (val) => {
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&+=!*().]).{8,}$/
  return regex.test(val) && val.toString().length >= 8
}

export const notEmpty = (val) =>
  val
    ? Array.isArray(val)
      ? val?.length
      : !equal(typeOf(val), STRING)
      ? true
      : val.trim()
    : false
export const validateYearString = (value) =>
  alphaNumericValidation(value) ? value.includes(startYear) : false

export const isDate = (date) => {
  const regExp = [
    /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/,
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+$/,
  ]
  return regExp[0].test(date) || regExp[1].test(date)
}

export const zipCodeValidation = (value) => {
  const regExp = /^\d{5}$/
  return regExp.test(value)
}

export const isNumber = (val) => /^\d+$/.test(val)

export const checkNumberWithPostfix = (val) =>
  ternary(number(val), val.includes(numberPostFix), false)

export const isUrl = (value) => {
  const regExp =
    // eslint-disable-next-line no-useless-escape
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
  return regExp.test(value)
}

export const isFullYear = (value) => /^\d{4}$/.test(value)

export const timeValidation = (value) => {
  if (!value) return false
  const regExp = /\d{2}:\d{2}/
  const [hours, minutes] = value.split(':')
  return +hours <= 12 && +minutes < 60 && regExp.test(value)
}

export const numberRangeValidation = (value) => +value >= 0 && +value <= 100

// Regular expression to check if string is valid date
export const checkIfValidDate = (dateStr) => {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/
  if (dateStr.match(regex) === null) {
    return false
  }
  const [month, day, year] = dateStr.split('/')
  // 👇️ format Date string as `yyyy-mm-dd`
  const isoFormattedStr = `${year}-${month}-${day}`

  const date = new Date(isoFormattedStr)
  const timestamp = date.getTime()
  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false
  }
  return date.toISOString().startsWith(isoFormattedStr)
}

export const isValidDateFormat = (input) => {
  // Regular expressions for the allowed formats
  const regExp = [
    /^[0-9]*\.?[0-9]+$/,
    /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{2}$/,
    /^(0[1-9]|1[0-2])\/\d{2}$|^(0[1-9]|1[0-2])-\d{2}$|^(10|11|12)\/\d{2}$|^(10|11|12)-\d{2}$/,
    /^(0[1-9]|1[0-2])\/\d{2}$|^(0[1-9]|1[0-2])-\d{2}$|^(10|11|12)\/\d{2}$|^(10|11|12)-\d{2}$/,
  ]

  // eslint-disable-next-line no-restricted-syntax
  for (const format of regExp) {
    if (format.test(input)) {
      return true
    }
  }

  return false
}
