import { BOOLEAN } from '../Utils/constant'

export const columns = [
  '',
  'Contact Type',
  'Company Name',
  'Address',
  'Work Phone # (W)',
]
export const columnsKeys = [
  'contactType',
  'companyName',
  'address',
  'workPhone',
]
export const addressFields = ['address', 'city', 'state', 'zipCode']
export const prefillFormFields = ['description', 'mapping']

export const editCompanyNameFormFields = {
  editCompanyName: {
    type: 'text',
    label: 'Company Name',
    placeholder: 'Enter Company Name',
  },
  isCompanyNameUpdateWarning: {
    type: 'checkbox',
    valueType: BOOLEAN,
    sx: {
      color: 'red',
    },
    fields: [
      {
        label:
          'Edits here impact all projects referencing this ‘Company Name’ throughout the application. Select to update.',
        value: 'isCompanyNameUpdateWarning',
        labelledCheckbox: true,
      },
    ],
  },
}

export const selectedRowFormFields = {
  list: {
    type: 'checkbox',
  },
}
