import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { checkIncludes, equal, ternary } from '../../Utils/javascript'
import {
  DELETE_PROJECT_ASSIGNMENTS_FAILED,
  DELETE_PROJECT_ASSIGNMENTS_REQUESTED,
  DELETE_PROJECT_ASSIGNMENTS_SUCCESS,
  FETCH_PROJECT_ASSIGNMENTS_FAILED,
  FETCH_PROJECT_ASSIGNMENTS_REQUESTED,
  FETCH_PROJECT_ASSIGNMENTS_SUCCESS,
  SAVE_PROJECT_ASSIGNMENTS_FAILED,
  SAVE_PROJECT_ASSIGNMENTS_REQUESTED,
  SAVE_PROJECT_ASSIGNMENTS_SUCCESS,
  UPDATE_PROJECT_PERMISSIONS,
} from '../constants/projectAssignments'
import { updateSaveStatusAction } from './confirmation'

const fetchProjectAssignmentsRequested = () => ({
  type: FETCH_PROJECT_ASSIGNMENTS_REQUESTED,
})
const fetchProjectAssignmentsSuccess = (payload) => ({
  type: FETCH_PROJECT_ASSIGNMENTS_SUCCESS,
  payload,
})
const fetchProjectAssignmentsFailed = (payload) => ({
  type: FETCH_PROJECT_ASSIGNMENTS_FAILED,
  payload,
})

export const clearProjectAssignments = () => fetchProjectAssignmentsSuccess([])

export const fetchProjectAssignments =
  ({ personnel }) =>
  async (dispatch) => {
    dispatch(fetchProjectAssignmentsRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.projectAssignments}/${personnel}`,
    })
    if (data) {
      const projectAssignmentsList = data.map(
        ({
          id,
          editor,
          viewer,
          personnelName,
          project: { id: projectId, state, city, completionDate, projectName },
          systemAuthorization,
        }) => ({
          id,
          editor,
          viewer,
          personnelName,
          state,
          city,
          completionDate,
          projectName,
          project: projectId,
          systemAuthorization,
        }),
      )
      dispatch(fetchProjectAssignmentsSuccess(projectAssignmentsList))
      return
    }
    if (error) {
      if (equal(error?.response?.status, 404))
        dispatch(fetchProjectAssignmentsSuccess([]))
      dispatch(fetchProjectAssignmentsFailed(error.response.data))
    }
  }

const updatePermissions = (payload) => ({
  type: UPDATE_PROJECT_PERMISSIONS,
  payload,
})

export const updateProjectPermissions =
  ({
    allowAccess,
    permission,
    projectId,
    updateAll = false,
    systemAuthorization,
  }) =>
  (dispatch, getState) => {
    const { projectAssignments } = getState().projectAssignments
    let hasUnsavedChanges = false

    const updatedPermissions = projectAssignments?.map((projectAssignment) => {
      if (updateAll || equal(projectAssignment.id, projectId)) {
        if (
          !equal(projectAssignment.systemAuthorization, systemAuthorization) ||
          (!equal(
            projectAssignment.editor,
            ternary(equal(permission, 'editor') && allowAccess, true, false),
          ) &&
            permission) ||
          (!equal(
            projectAssignment.viewer,
            ternary(equal(permission, 'viewer') && allowAccess, true, false),
          ) &&
            permission)
        ) {
          hasUnsavedChanges = true
        }
      }
      return ternary(
        updateAll || equal(projectAssignment.id, projectId),
        {
          ...projectAssignment,
          systemAuthorization:
            systemAuthorization || projectAssignment.systemAuthorization,
          ...ternary(
            permission,
            {
              [permission]: allowAccess,
              ...ternary(
                allowAccess,
                {
                  [ternary(equal(permission, 'editor'), 'viewer', 'editor')]:
                    !allowAccess,
                },
                {},
              ),
            },
            {
              editor: projectAssignment.editor,
              viewer: projectAssignment.viewer,
            },
          ),
        },
        projectAssignment,
      )
    })

    dispatch(updatePermissions({ data: updatedPermissions, hasUnsavedChanges }))
  }

export const deleteProjectAssignmentsRequested = () => ({
  type: DELETE_PROJECT_ASSIGNMENTS_REQUESTED,
})
export const deleteProjectAssignmentsSuccess = (payload) => ({
  type: DELETE_PROJECT_ASSIGNMENTS_SUCCESS,
  payload,
})
export const deleteProjectAssignmentsFailed = (payload) => ({
  type: DELETE_PROJECT_ASSIGNMENTS_FAILED,
  payload,
})
export const deleteProjectAssignments =
  ({ selectedProjectAssignments, personnel }) =>
  async (dispatch, getState) => {
    dispatch(deleteProjectAssignmentsRequested())
    const { projectAssignments } = getState().projectAssignments
    const projectAssignmentsWithRemoval = projectAssignments.filter(
      (projectAssignment) =>
        !checkIncludes(projectAssignment.id, selectedProjectAssignments),
    )
    const { data, error } = await api({
      data: { project_assignments: selectedProjectAssignments },
      method: method.delete,
      endPoint: `${apiEndPoints.projectAssignments}/delete/${personnel}`,
    })
    if (data) {
      dispatch(deleteProjectAssignmentsSuccess(projectAssignmentsWithRemoval))
      return
    }
    if (error) {
      dispatch(deleteProjectAssignmentsFailed(error.response.data))
    }
  }

export const saveProjectAssignmentsRequested = () => ({
  type: SAVE_PROJECT_ASSIGNMENTS_REQUESTED,
})
export const saveProjectAssignmentsSuccess = (payload) => ({
  type: SAVE_PROJECT_ASSIGNMENTS_SUCCESS,
  payload,
})
export const saveProjectAssignmentsFailed = (payload) => ({
  type: SAVE_PROJECT_ASSIGNMENTS_FAILED,
  payload,
})
export const saveProjectAssignments =
  ({ personnel }) =>
  async (dispatch, getState) => {
    dispatch(saveProjectAssignmentsRequested())
    const { projectAssignments } = getState().projectAssignments
    const { data, error } = await api({
      data: projectAssignments,
      method: method.patch,
      endPoint: `${apiEndPoints.projectAssignments}/${personnel}/`,
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(saveProjectAssignmentsSuccess(projectAssignments))
      return
    }
    if (error) {
      dispatch(saveProjectAssignmentsFailed(error.response.data))
    }
  }
