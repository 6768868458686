import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Box, Typography } from '@mui/material'
import DKTDialog from './DKTDialog'
import { useStyles } from '../Styles/confirmNavigateShowModal.style'
import DKTButton from './DKTButton'
import { updateOnSaveStatus } from '../Redux/actions/confirmation'

const DKTConfirmNavigateShowModal = ({
  isActive,
  onConfirm,
  onSave,
  onCancel,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { navigateConfirm } = useSelector(({ confirmation }) => confirmation)
  const { save, cancel, loader } = navigateConfirm

  useEffect(() => {
    if (isActive) {
      if (save) {
        onConfirm()
        dispatch(updateOnSaveStatus({ save: false, loader: false }))
      } else if (cancel) {
        onCancel()
        dispatch(updateOnSaveStatus({ cancel: false, loader: false }))
      }
    }
  }, [save, cancel])

  const saveFn = () => {
    dispatch(updateOnSaveStatus({ loader: true }))
    onSave()
  }

  const unSaveChangesAction = (
    <>
      <DKTButton variant="outlined" onClick={onConfirm}>
        No
      </DKTButton>
      <DKTButton
        variant="contained"
        disableElevation
        disabled={loader}
        onClick={saveFn}
      >
        {loader ? 'Saving...' : 'Save'}
      </DKTButton>
    </>
  )
  return (
    <DKTDialog
      open={isActive}
      handleClose={onCancel}
      title="&nbsp;"
      actions={unSaveChangesAction}
      maxWidth="xs"
    >
      <Grid container>
        <Grid item xs={12}>
          <Box className={classes.confirmNavigationSHowModalContent}>
            <Typography variant="h5">
              You have unsaved changes. Save before continuing?
            </Typography>
            <Typography variant="body1">
              If you leave before saving, your changes will be lost.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </DKTDialog>
  )
}

export default DKTConfirmNavigateShowModal
