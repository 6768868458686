import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from 'redux'
import thunk from 'redux-thunk'

import { ternary } from '../Utils/javascript'
import { loadStateFn } from '../Utils/localStorage'
import rootReducer from './reducers'

const auth = loadStateFn('auth')
const preloadedState = ternary(
  auth,
  {
    auth: {
      accessToken: null,
      refreshToken: null,
      isLoadingLogin: false,
      isResettingPassword: false,
      error: null,
      isSendingMail: false,
      loginError: null,
      forgotPasswordError: null,
      resetPasswordError: null,
      isActivationUserLoading: false,
      activateUserError: null,
      ...JSON.parse(auth),
    },
  },
  {},
)

const middlewares = [thunk]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(
  rootReducer,
  preloadedState,
  composeEnhancers(applyMiddleware(...middlewares)),
)
