import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  contactsTable: {
    marginTop: 64,
    '& .MuiPaper-elevation': {
      boxShadow: 'none',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& th': {
            background: theme.palette.gray.main,
            '& .MuiButton-root': {
              padding: 0,
              width: '100%',
              justifyContent: 'space-between',
              '&:hover': {
                background: 'trasparent',
              },
              '& > .tss-1h9t3sl-MUIDataTableHeadCell-sortAction': {
                width: '100%',
                justifyContent: 'space-between',
              },
            },
          },
        },
      },
    },
    '& .MuiButton-root': {
      marginLeft: 30,
      '& .MuiButton-startIcon': {
        marginLeft: 0,
      },
    },
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          backgroundColor: '#E5E5E5',
        },
      },
    },
    '& .MuiTableBody-root': {
      '& td': {
        '&:first-child': {
          width: 72,
          '& .MuiButtonBase-root': {
            marginLeft: 35,
          },
        },
      },
    },
  },
  tableCellWidth: {
    textAlign: 'left',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  addressTableCell: {
    maxWidth: '320px',
  },
  workDescription: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      color: '#1564FF',
    },
  },
  contactType: {
    maxWidth: 350,
    width: '100%',
    color: '#1564FF',
    '& p': {
      fontSize: 14,
    },
  },
  dragIcon: {
    cursor: 'move',
    maxWidth: 'max-content',
    display: 'inline-flex',
    alignItems: 'center',
    '& .MuiCheckbox-root': {
      padding: 0,
    },
    '& .MuiFormControl-root': {
      marginLeft: 0,
    },
  },

  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))
