import api from '../../Utils/api'
import { apiEndPoints, clientPortal, method } from '../../Utils/constant'
import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_PERFORMANCE_CHART_DATA,
  CREATE_AND_UPDATE_ECONOMIC_COMPLETION_FAILED,
  CREATE_AND_UPDATE_ECONOMIC_COMPLETION_REQUESTED,
  CREATE_AND_UPDATE_ECONOMIC_COMPLETION_SUCCESS,
  CREATE_AND_UPDATE_REPORT_FAILED,
  CREATE_AND_UPDATE_REPORT_REQUESTED,
  CREATE_AND_UPDATE_REPORT_SUCCESS,
  CREATE_AND_UPDATE_SCHEDULE_DURATION_FAILED,
  CREATE_AND_UPDATE_SCHEDULE_DURATION_REQUESTED,
  CREATE_AND_UPDATE_SCHEDULE_DURATION_SUCCESS,
  DELETE_REPORT_FAILED,
  DELETE_REPORT_REQUESTED,
  DELETE_REPORT_SUCCESS,
  FETCH_DRAW_PROGRESS_CHART_DATA_FAILED,
  FETCH_DRAW_PROGRESS_CHART_DATA_REQUESTED,
  FETCH_DRAW_PROGRESS_CHART_DATA_SUCCESS,
  FETCH_ECONOMIC_DATA_FAILED,
  FETCH_ECONOMIC_DATA_REQUESTED,
  FETCH_ECONOMIC_DATA_SUCCESS,
  FETCH_PERFORMANCE_CHART_DATA_FAILED,
  FETCH_PERFORMANCE_CHART_DATA_REQUESTED,
  FETCH_PERFORMANCE_CHART_DATA_SUCCESS,
  FETCH_REPORT_LIST_FAILED,
  FETCH_REPORT_LIST_REQUESTED,
  FETCH_REPORT_LIST_SUCCESS,
  FETCH_REPORT_SUCCESS,
  PUBLIC_REPORT_FAILED,
  PUBLIC_REPORT_REQUESTED,
  PUBLIC_REPORT_SUCCESS,
} from '../constants/reportMonitoring'
import { updateSaveStatusAction } from './confirmation'

// Fetch report list
const fetchReportListRequested = () => ({
  type: FETCH_REPORT_LIST_REQUESTED,
})
const fetchReportListSuccess = (payload) => ({
  type: FETCH_REPORT_LIST_SUCCESS,
  payload,
})
const fetchReportListFailed = (payload) => ({
  type: FETCH_REPORT_LIST_FAILED,
  payload,
})
export const fetchReportSuccess = (payload) => ({
  type: FETCH_REPORT_SUCCESS,
  payload,
})

export const getReportList =
  (projectId, ocId, reportId) => async (dispatch, getState) => {
    dispatch(fetchReportListRequested())
    const { portalType } = getState().auth
    const isClientCompany = equal(portalType, clientPortal)
    const { data, error } = await api({
      endPoint: ternary(
        reportId,
        `${apiEndPoints.reportList}${reportId}/?projectId=${projectId}`,
        `${apiEndPoints.reportList}?projectId=${projectId}&ocId=${ocId}`,
      ),
      method: method.get,
      showToastMessage: false,
      toastMessage: 'Reports Fetch successfully!',
    })
    const cloneData = data?.length
      ? data.filter(
          ({ status }) =>
            (isClientCompany && equal(status, 'Published')) || !isClientCompany,
        )
      : []

    if (data) {
      if (reportId) {
        dispatch(fetchReportSuccess(data))
        return
      }
      dispatch(fetchReportListSuccess(cloneData))
    }
    if (error) {
      dispatch(fetchReportListFailed(error.response))
    }
  }
// Create and update Reports
const createAndUpdateReportRequested = () => ({
  type: CREATE_AND_UPDATE_REPORT_REQUESTED,
})
const createAndUpdateReportSuccess = (payload) => ({
  type: CREATE_AND_UPDATE_REPORT_SUCCESS,
  payload,
})

const createAndUpdateReportFailed = (payload) => ({
  type: CREATE_AND_UPDATE_REPORT_FAILED,
  payload,
})

export const createAndUpdateReport =
  (payload, projectId, ocId, handleClose) => async (dispatch) => {
    dispatch(createAndUpdateReportRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.reportList}?projectId=${projectId}&ocId=${ocId}`,
      method: method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Report created successfully!',
    })
    if (data) {
      dispatch(createAndUpdateReportSuccess(data))
      handleClose()
      return data?.id
    }
    if (error) {
      dispatch(createAndUpdateReportFailed(error.response.data))
      handleClose()
    }
    return false
  }

// Delete Report
const deleteReportRequested = () => ({
  type: DELETE_REPORT_REQUESTED,
})
export const deleteReportSuccess = (payload) => (dispatch, getState) => {
  const state = getState()
  const reportList = [...state.reportMonitoring.reportList]
  const index = reportList?.findIndex(({ id }) => equal(id, payload?.report[0]))
  if (~index) {
    reportList.splice(index, 1)
  }
  dispatch({
    type: DELETE_REPORT_SUCCESS,
    payload: reportList,
  })
}
const deleteReportFailed = (payload) => ({
  type: DELETE_REPORT_FAILED,
  payload,
})

export const deleteReport = (payload, projectId, ocId) => async (dispatch) => {
  dispatch(deleteReportRequested())
  const { status, error } = await api({
    endPoint: `${apiEndPoints.deleteReport}?projectId=${projectId}&ocId=${ocId}`,
    method: method.delete,
    data: payload,
    showToastMessage: true,
    toastMessage: 'Report Deleted successfully!',
  })
  if (status) {
    dispatch(deleteReportSuccess(payload))
    return
  }
  if (error) {
    dispatch(deleteReportFailed(error.response.data))
  }
}

// Publish report
const publishingReportRequested = () => ({
  type: PUBLIC_REPORT_REQUESTED,
})
export const publishingReportSuccess = (payload) => ({
  type: PUBLIC_REPORT_SUCCESS,
  payload,
})
const publishingReportFailed = (payload) => ({
  type: PUBLIC_REPORT_FAILED,
  payload,
})

export const publishReport =
  (reportId, projectId, ocId, isPublish, handleClose) => async (dispatch) => {
    dispatch(publishingReportRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.reportList}${reportId}/published/?projectId=${projectId}&ocId=${ocId}&isPublish=${isPublish}`,
      method: method.patch,
      showToastMessage: true,
      toastMessage: ternary(
        isPublish,
        'Report published successfully!',
        'Report unpublished successfully!',
      ),
    })
    if (data) {
      dispatch(publishingReportSuccess(data))
      handleClose()
      return
    }
    if (error) {
      dispatch(publishingReportFailed(error.response.data))
      handleClose()
    }
  }

// Fetch report list
const fetchPerformanceChartDataRequested = () => ({
  type: FETCH_PERFORMANCE_CHART_DATA_REQUESTED,
})
const fetchPerformanceChartDataSuccess = (payload) => ({
  type: FETCH_PERFORMANCE_CHART_DATA_SUCCESS,
  payload,
})
const fetchPerformanceChartDataFailed = (payload) => ({
  type: FETCH_PERFORMANCE_CHART_DATA_FAILED,
  payload,
})

export const getPerformanceChartData =
  (projectId, reportId) => async (dispatch) => {
    dispatch(fetchPerformanceChartDataRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.reportPerformanceChart}?projectId=${projectId}&reportId=${reportId}`,

      method: method.get,
      showToastMessage: false,
    })
    if (data) {
      dispatch(fetchPerformanceChartDataSuccess(data))
    }
    if (error) {
      dispatch(fetchPerformanceChartDataFailed(error.response))
    }
  }

// Fetch draw progress data
const fetchDrawProjectChartDataRequested = () => ({
  type: FETCH_DRAW_PROGRESS_CHART_DATA_REQUESTED,
})
const fetchDrawProjectChartDataSuccess = (payload) => ({
  type: FETCH_DRAW_PROGRESS_CHART_DATA_SUCCESS,
  payload,
})
const fetchDrawProjectChartDataFailed = (payload) => ({
  type: FETCH_DRAW_PROGRESS_CHART_DATA_FAILED,
  payload,
})

export const fetchDrawProjectChartData = (projectId) => async (dispatch) => {
  dispatch(fetchDrawProjectChartDataRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.getReportDrawProgressionChart}?projectId=${projectId}`,

    method: method.get,
    showToastMessage: false,
  })

  if (data) {
    dispatch(fetchDrawProjectChartDataSuccess(data))
  }
  if (error) {
    dispatch(fetchDrawProjectChartDataFailed(error.response))
  }
}

// Create and update Reports
const createScheduleDurationRequested = () => ({
  type: CREATE_AND_UPDATE_SCHEDULE_DURATION_REQUESTED,
})
const createScheduleDurationSuccess = (payload) => ({
  type: CREATE_AND_UPDATE_SCHEDULE_DURATION_SUCCESS,
  payload,
})

const createScheduleDurationFailed = (payload) => ({
  type: CREATE_AND_UPDATE_SCHEDULE_DURATION_FAILED,
  payload,
})

export const createScheduleDuration =
  (payload, projectId) => async (dispatch) => {
    dispatch(createScheduleDurationRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.reportDrawProcess}?projectId=${projectId}`,
      method: method.post,
      data: payload,
      showToastMessage: false,
    })
    if (data) {
      dispatch(createScheduleDurationSuccess(data))
      dispatch(fetchDrawProjectChartData(projectId))
    }
    if (error) {
      dispatch(createScheduleDurationFailed(error.response.data))
    }
  }

// Fetch Economic Completion data
const fetchEconomicDataRequested = () => ({
  type: FETCH_ECONOMIC_DATA_REQUESTED,
})
const fetchEconomicDataSuccess = (payload) => ({
  type: FETCH_ECONOMIC_DATA_SUCCESS,
  payload,
})
const fetchEconomicDataFailed = (payload) => ({
  type: FETCH_ECONOMIC_DATA_FAILED,
  payload,
})

export const getEconomicCompletionData = (projectId) => async (dispatch) => {
  dispatch(fetchEconomicDataRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.reportEconomicCompletion}?projectId=${projectId}`,

    method: method.get,
    showToastMessage: false,
  })

  if (data) {
    dispatch(fetchEconomicDataSuccess(data?.economicCompletion))
  }
  if (error) {
    dispatch(fetchEconomicDataFailed(error.response))
  }
}

// Create and update economic completion
const createEconomicCompletionRequested = () => ({
  type: CREATE_AND_UPDATE_ECONOMIC_COMPLETION_REQUESTED,
})
const createEconomicCompletionSuccess = (payload) => ({
  type: CREATE_AND_UPDATE_ECONOMIC_COMPLETION_SUCCESS,
  payload,
})

const createEconomicCompletionFailed = (payload) => ({
  type: CREATE_AND_UPDATE_ECONOMIC_COMPLETION_FAILED,
  payload,
})

export const createEconomicCompletion =
  (payload, projectId) => async (dispatch) => {
    dispatch(createEconomicCompletionRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.reportEconomicCompletion}?projectId=${projectId}`,
      method: method.post,
      data: payload,
      showToastMessage: false,
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(createEconomicCompletionSuccess(data))
      dispatch(fetchDrawProjectChartData(payload?.projectId))
      dispatch(getEconomicCompletionData(payload?.projectId))
    }
    if (error) {
      dispatch(createEconomicCompletionFailed(error.response.data))
    }
  }
export const clearOldDataForChart = () => async (dispatch) => {
  dispatch({ type: CLEAR_PERFORMANCE_CHART_DATA })
}
