import { Grid, Typography, Stack } from '@mui/material'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  addressFields,
  cooColumns,
  cooTrackerOptions,
} from '../../../Description/permitsEntitlements.description'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import { useStyles } from '../../../Styles/permitsEntitlements.style'
import { checkIncludes, equal, ternary } from '../../../Utils/javascript'
import AddBuilding from './AddBuilding'

const clonedColumns = [...cooColumns]
const getFullAddress = (contactType) =>
  addressFields
    .filter((addressField) => contactType[addressField])
    .map((addressField) => contactType[addressField])
    .join(', ')

const CertificateOfOccupancy = ({ ocId, hasOnlyViewPermission }) => {
  const [buildingToEdit, setBuildingToEdit] = useState(null)
  const [isCooModalOpen, setIsCooModalOpen] = useState(false)

  const classes = useStyles()
  const {
    certificateOfOccupancyTracker,
    isFetchingCertificateOfOccupancyTracker,
  } = useSelector(({ permitsEntitlements }) => permitsEntitlements)

  const openCooModal = () => setIsCooModalOpen(true)
  const closeCooModal = () => setIsCooModalOpen(false)

  const handleEditBuilding = (row) => {
    if (hasOnlyViewPermission) return
    setBuildingToEdit(row)
    openCooModal()
  }

  const handleAddBuilding = () => {
    if (hasOnlyViewPermission) return
    setBuildingToEdit(null)
    openCooModal()
  }

  const customBodyRender = (value, tableMeta) =>
    ternary(
      equal(tableMeta.columnData.name, 'address'),
      <Typography
        variant="subtitle2"
        component="span"
        color={ternary(hasOnlyViewPermission, 'gray', 'primary')}
        fontWeight={400}
        className={classNames({
          [classes.addressDialog]: !hasOnlyViewPermission,
        })}
        onClick={() =>
          handleEditBuilding(certificateOfOccupancyTracker[tableMeta.rowIndex])
        }
      >
        {getFullAddress(
          certificateOfOccupancyTracker[tableMeta.rowIndex] || {},
        )}
      </Typography>,
      checkIncludes(tableMeta.columnData.name, [
        'tcoDate',
        'certificateOfOccupancyIssuedDate',
      ])
        ? value
          ? moment(value).format('MM/DD/YY')
          : '-'
        : value,
    )

  const tableColumns = clonedColumns.map((column) => ({
    ...column,
    options: {
      ...column.options,
      customBodyRender,
    },
  }))

  const cooTrackerTableOptions = {
    ...cooTrackerOptions,
    textLabels: {
      body: {
        noMatch: ternary(
          isFetchingCertificateOfOccupancyTracker,
          <DKTCircularProgress />,
          'Sorry, there is no matching data to display',
        ),
      },
    },
  }

  return (
    <Grid item xs={12} lg={12} mt={1.5}>
      {equal(isFetchingCertificateOfOccupancyTracker, true) ? (
        <Stack alignItems="center" justifyContent="center">
          <DKTCircularProgress />
        </Stack>
      ) : equal(isFetchingCertificateOfOccupancyTracker, 'FAILED') ? (
        <Stack alignItems="center" justifyContent="center">
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Certificate of Occupancy
            Tracker data. Please try contacting the admin or refreshing this
            page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Typography
            variant="body2"
            color="gray.extraDark"
            sx={{ fontWeight: 'medium', mb: 1.5 }}
          >
            Certificate of Occupancy Tracker (Multiple Buildings)
          </Typography>
          <DKTMuiDataTable
            columns={tableColumns}
            data={certificateOfOccupancyTracker}
            options={cooTrackerTableOptions}
          />
          <DKTButton
            variant="text"
            color="primary"
            sx={{ textTransform: 'initial', px: 1, ml: '10px' }}
            startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
            onClick={handleAddBuilding}
            disabled={hasOnlyViewPermission}
          >
            <Typography>Add Building</Typography>
          </DKTButton>

          {/* Add building modal */}
          <AddBuilding
            open={isCooModalOpen}
            onClose={closeCooModal}
            buildingToEdit={buildingToEdit}
            ocId={ocId}
            hasOnlyViewPermission={hasOnlyViewPermission}
          />
        </>
      )}
    </Grid>
  )
}

export default CertificateOfOccupancy
