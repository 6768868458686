import { Box, Grid, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InnerLayout } from '../../../Layout/admin'
import { getProjectChoices } from '../../../Redux/actions/choices'
import {
  fetchExecutiveSummary,
  saveExecutiveSummary,
} from '../../../Redux/actions/constructionMonitoringReport'
import DKTButton from '../../../Shared/DKTButton'
import DKTEditor from '../../../Shared/DKTEditor'
import { equal, ternary } from '../../../Utils/javascript'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'

const useStyles = makeStyles((theme) => ({
  executiveSummaryTextArea: {
    '& textarea': {
      resize: 'vertical',
      padding: [[10, 18]],
      fontStyle: 'italic',
      fontFamily: 'Roboto',
      fontWeight: 500,
      borderRadius: 0,
      borderColor: '#C4C4C4',
      color: '#646464',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  executiveSummary: {
    minHeight: 250,
    height: 450,
    '& .mention': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
}))

const ExecutiveSummary = ({ renderSelect, items }) => {
  const [executiveSummary, setExecutiveSummary] = useState('')
  const [editorConfig, setEditorConfig] = useState({})

  const classes = useStyles()
  const dispatch = useDispatch()
  const { isLoadingChoices, choices } = useSelector(
    ({ projectChoices }) => projectChoices,
  )
  const { isFetchingES, executiveSummaryData, isSavingEs } = useSelector(
    ({ constructionMonitoringReport }) => constructionMonitoringReport,
  )

  const {
    isUnsavedData,
    setIsUnsavedData,
    discardUnsavedChanges,
    isUnsavedChangesModalOpen,
    closeUnsavedChangesModal,
  } = items

  useEffect(() => {
    dispatch(getProjectChoices({ fieldName: 'executiveSummaryKey' }))
    dispatch(fetchExecutiveSummary())
  }, [])

  useEffect(() => {
    setExecutiveSummary(executiveSummaryData?.executiveSummary)
  }, [executiveSummaryData?.executiveSummary])

  useEffect(() => {
    if (choices?.executiveSummaryKey?.length)
      setEditorConfig({
        mention: {
          feeds: [
            {
              marker: '#',
              feed: choices?.executiveSummaryKey,
              dropdownLimit: choices?.executiveSummaryKey?.length,
            },
          ],
        },
      })
  }, [choices?.executiveSummaryKey])

  useEffect(() => {
    setIsUnsavedData(
      !equal(executiveSummaryData?.executiveSummary, executiveSummary),
    )
  }, [executiveSummaryData?.executiveSummary, executiveSummary])

  const handleSave = () => {
    const onSuccess = () => {
      setIsUnsavedData(false)
    }
    dispatch(saveExecutiveSummary({ executiveSummary, onSuccess }))
  }

  const actions = () => (
    <DKTButton onClick={handleSave}>
      {ternary(isSavingEs, 'Saving...', 'Save')}
    </DKTButton>
  )
  return (
    <InnerLayout
      actionTitle="Manage Construction Monitoring Report Settings"
      actions={actions}
      contentTitle="Construction Monitoring Report (CMR)"
    >
      {renderSelect()}
      {equal(isLoadingChoices, true) || equal(isFetchingES, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isLoadingChoices, 'FAILED') || equal(isFetchingES, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Executive summary data.
            Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <Box mt={5}>
          <Grid container>
            <Grid item xs={9}>
              <div className={classes.executiveSummaryTextArea}>
                <Grid item xs={12}>
                  <DKTEditor
                    configData={editorConfig}
                    value={executiveSummary || ''}
                    className={classes.executiveSummary}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setExecutiveSummary(data)
                    }}
                  />
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnsavedChangesModalOpen}
        onConfirm={discardUnsavedChanges}
        onCancel={closeUnsavedChangesModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isUnsavedData} onSave={handleSave} />
    </InnerLayout>
  )
}

export default ExecutiveSummary
