import { useCallback, useEffect, useState, memo } from 'react'
import { Grid, Stack, Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import {
  entries,
  equal,
  modifyNumber,
  ternary,
} from '../../../Utils/javascript'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTInput from '../../../Shared/DKTInput'
import DKTForm from '../../../Shared/DKTForm'
import {
  bidsColumns,
  finalSelectionFields,
  options,
} from '../../../Description/rfpDetails.description'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { emptyTableCellPlaceholder } from '../../../Description/reportMonitoring.description'
import DKTTypography from '../../../Shared/DKTTypography'
import DKTTextArea from '../../../Shared/DKTTextArea'

const useStyles = makeStyles(() => ({
  bidsFinalTable: {
    '& table': {
      '& tbody': {
        '& tr': {
          '& td': {
            '&:first-child': {
              width: '45%',
            },
          },
        },
      },
    },
  },
}))

const BidsAndFinalSec = ({ formValues }) => {
  const { values, handleChange } = formValues
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [columns, setColumns] = useState([])
  const [tableData, setTableData] = useState([])
  const { scopeServiceOpts, rfpCompanyList, rfpBuidsData, isLoadingSingleRfp } =
    useSelector(({ rfpData }) => rfpData)

  const classes = useStyles()
  useEffect(() => {
    setDynamicOptions(() => ({
      finalSelectedConsultant: [
        {
          label: 'Pending...',
          value: 'pending',
        },
        ...ternary(
          values?.selectedCompanies?.length,
          values.selectedCompanies,
          [],
        )
          .map((id) => {
            const data = rfpCompanyList?.find((item) => equal(item?.id, id))
            return ternary(
              data,
              {
                label: data?.companyName || '',
                value: data?.id?.toString() || '',
              },
              null,
            )
          })
          .filter((item) => !equal(item, null)),
        {
          label: 'Other-(Non AcreIQ)',
          value: 'other',
        },
      ],
    }))
    setColumns(() => [
      ...bidsColumns.map((report) => ({
        ...report,
        options: { ...report.options, customBodyRender },
      })),
      ...[
        ...ternary(
          values?.selectedCompanies?.length,
          values.selectedCompanies,
          [],
        ),
      ]
        .map((id) => {
          const data = rfpCompanyList?.find((item) => equal(item?.id, id))
          return data
            ? {
                name: data?.companyName || '',
                label: data?.companyName || '',
                options: {
                  filter: true,
                  sort: false,
                  customBodyRender,
                },
              }
            : null
        })
        .filter((item) => !equal(item, null)),
    ])
  }, [rfpCompanyList, values?.selectedCompanies])

  useEffect(() => {
    if (scopeServiceOpts)
      setTableData(() => {
        const data = values?.scopeOfServices?.map((value) =>
          scopeServiceOpts?.filter((item) => equal(item?.value, value)),
        )
        return data?.flat()
      })
  }, [values?.scopeOfServices, scopeServiceOpts])

  const tableOptions = {
    ...options,
    textLabels: {
      body: {
        noMatch: isLoadingSingleRfp ? (
          <DKTCircularProgress />
        ) : (
          'Sorry, there is no matching values to display'
        ),
      },
    },
  }
  const renderFormFields = useCallback(
    (formFields) => (
      <Grid container spacing={2}>
        {entries(formFields).map(
          ([name, { isRequired, ...formField }], index) => {
            const FormControl = ternary(
              equal(formField.type, 'select'),
              DKTSelect,
              ternary(equal(formField.type, 'textarea'), DKTTextArea, DKTInput),
            )
            const disabledOtherNonAcreiq =
              !['finalSelectedConsultant', 'bidComment'].includes(name) &&
              !equal(values?.finalSelectedConsultant, 'other')

            return (
              <Grid key={index} item xs={formField.xs ?? 12}>
                <FormControl
                  {...formField}
                  options={dynamicOptions[name] || []}
                  id={name}
                  name={name}
                  disabled={disabledOtherNonAcreiq || false}
                  value={
                    values && values[name]
                      ? disabledOtherNonAcreiq
                        ? ''
                        : values[name]?.toString()
                      : ''
                  }
                  onChange={handleChange}
                />
              </Grid>
            )
          },
        )}
      </Grid>
    ),
    [values, dynamicOptions, handleChange],
  )

  const customBodyRender = useCallback(
    (value, tableMeta) => {
      switch (tableMeta?.columnData?.name) {
        case 'name':
          return value
        default:
          return ternary(
            rfpBuidsData?.[tableMeta.rowIndex]?.[tableMeta?.columnData?.name] >
              0,
            `$ ${modifyNumber(
              rfpBuidsData?.[tableMeta.rowIndex]?.[tableMeta?.columnData?.name],
            )}`,
            emptyTableCellPlaceholder,
          )
      }
    },
    [rfpBuidsData],
  )

  return (
    <Box>
      <Stack direction="row" spacing={4} marginTop="16px" marginBottom="34px">
        <DKTForm autoComplete="off" sx={{ width: '100%' }}>
          {renderFormFields(finalSelectionFields?.selectionFields)}
        </DKTForm>
      </Stack>
      <Box>
        <DKTMuiDataTable
          data={tableData}
          options={tableOptions}
          columns={columns}
          className={classes.bidsFinalTable}
        />
      </Box>
      <Box mt={5}>
        <DKTTypography
          variant="body2"
          color="gray.extraDark"
          sx={{ fontWeight: 'medium' }}
        >
          Internal Notes (hidden)
        </DKTTypography>
        {renderFormFields(finalSelectionFields?.commentField)}
      </Box>
    </Box>
  )
}

export default memo(BidsAndFinalSec)
