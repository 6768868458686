import { Box, Stack, Typography } from '@mui/material'
import { memo, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { emptyTableCellPlaceholder } from '../../../Description/reportMonitoring.description'
import {
  options,
  proposalDetailColumns,
  scopOfServicesColumns,
  rfpDetailsForm,
  proposalDetailArr,
} from '../../../Description/rfpDetails.description'
import { InnerLayout } from '../../../Layout/rfp'
import DKTButton from '../../../Shared/DKTButton'
import DKTGrid from '../../../Shared/DKTGrid'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTTypography from '../../../Shared/DKTTypography'
import {
  equal,
  isEmptyString,
  modifyNumber,
  ternary,
} from '../../../Utils/javascript'
import { renderFormFields } from '../../../Utils/renderFormFields'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTCurrencyTextField from '../../../Shared/DKTCurrencyTextField'
import { useStyles } from '../../../Styles/rfc.style'
import {
  sendandReturnRFPNotification,
  updateRFP,
} from '../../../Redux/actions/rfp'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { spinnerSize } from '../../../Utils/constant'
import DKTTooltip from '../../../Shared/DKTTooltip'

const RFPOverviewConsultant = () => {
  const [values, setValues] = useState([])
  const [open, setOpen] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [onSaveModal, setOnSaveModal] = useState(false)
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false)
  const [successModal, setSuccessModalModal] = useState(false)
  const classes = useStyles()
  const {
    rfpProjectData,
    rfpBuidsData,
    scopeServiceOpts,
    isLoadingSingleRfp,
    sendRFPLoading,
    updateRFPLoading,
    fetchBidsLoading,
  } = useSelector(({ rfpData }) => rfpData)
  const { choices } = useSelector(({ projectChoices }) => projectChoices)
  const dispatch = useDispatch()

  useEffect(() => () => setValues([]), [])
  useEffect(() => {
    if (rfpBuidsData?.length)
      setValues(() =>
        rfpBuidsData
          ?.map((item) => ({
            [item?.Service]: item?.fee,
          }))
          .filter((item) => !equal(item, null)),
      )
  }, [rfpBuidsData])

  const handleOpenCloseModal = (status = false) => setOpen(status)
  const handleOpenModal = () => {
    const isShowWarning = values
      ?.map((item) => Object.values(item))
      .flat()
      ?.some((val) => isEmptyString(val) || equal(val, 0))
    if (isShowWarning) {
      setShowWarningModal(true)
    } else {
      handleOpenCloseModal(true)
    }
  }

  const handleChange = (rowIndex, name, value) => {
    setIsUnsavedChangesModalOpen(true)
    setValues((prev) =>
      prev?.map((item, index) => {
        if (equal(index, rowIndex)) {
          return {
            ...item,
            [name]: value,
          }
        }
        return item
      }),
    )
  }

  const handleOpenSuccessmodal = (flag = true) => setSuccessModalModal(flag)

  const handleSave = () => {
    const payload = {
      rfp: rfpProjectData?.id,
      services: values,
    }
    const onSucces = () => {
      setIsUnsavedChangesModalOpen(false)
      setOnSaveModal(true)
    }
    dispatch(updateRFP(payload, onSucces, true))
  }

  const reurnBids = () => {
    const onSucces = () => {
      setIsUnsavedChangesModalOpen(false)
      handleOpenCloseModal()
      setOnSaveModal(false)
    }
    dispatch(
      sendandReturnRFPNotification(
        { id: rfpProjectData?.id, rfp_link: window.location.pathname },
        onSucces,
        true,
        handleOpenSuccessmodal,
      ),
    )
  }

  const saveModalAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => setOnSaveModal(false)}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={() => {
          setOnSaveModal(false)
          setOpen(true)
        }}
      >
        Yes
      </DKTButton>
    </>
  )

  const actions = () => (
    <Stack direction="row" gap={2}>
      <DKTTooltip
        title={
          isLoadingSingleRfp ||
          sendRFPLoading ||
          !rfpProjectData.isBidsReceived ||
          isUnsavedChangesModalOpen
            ? 'Please save the data first in order to enable the button.'
            : ''
        }
      >
        <Box sx={{ cursor: 'pointer' }}>
          <DKTButton
            onClick={handleOpenModal}
            variant="outlined"
            disabled={
              isLoadingSingleRfp ||
              sendRFPLoading ||
              !rfpProjectData.isBidsReceived ||
              isUnsavedChangesModalOpen
            }
            sx={{ backgroundColor: 'white' }}
          >
            Return Bid to Client
          </DKTButton>
        </Box>
      </DKTTooltip>
      <DKTButton
        onClick={handleSave}
        disabled={updateRFPLoading || isLoadingSingleRfp}
      >
        {ternary(false, 'Saving...', 'Save')}
      </DKTButton>
    </Stack>
  )

  const getLatestValue = (type, value, name = '') => {
    switch (type) {
      case 'date':
        return ternary(
          value,
          moment(value).format('MM/DD/YY'),
          emptyTableCellPlaceholder,
        )
      case 'currency':
        return `$${modifyNumber(value || 0)}`
      case 'number':
        return modifyNumber(value)
      case 'select':
        return choices?.[name]?.find((opt) => equal(value, opt?.value))?.label
      default:
        return value || emptyTableCellPlaceholder
    }
  }

  const customBodyRender = useCallback(
    (value, tableMeta) => {
      switch (tableMeta?.columnData?.name) {
        case 'proposalDetail':
          return <b>{proposalDetailArr[tableMeta?.rowIndex].label}</b>
        case 'projectSummary':
          return (
            getLatestValue(
              proposalDetailArr[tableMeta?.rowIndex]?.formateType,
              rfpProjectData[proposalDetailArr[tableMeta?.rowIndex]?.name],
              proposalDetailArr[tableMeta?.rowIndex]?.name,
            ) || emptyTableCellPlaceholder
          )
        case 'Service':
          return scopeServiceOpts?.find((opt) => equal(opt.value, value))?.name
        case 'fees':
          return (
            <DKTCurrencyTextField
              name={tableMeta.rowData[0]}
              value={
                values?.[tableMeta?.rowIndex]?.[tableMeta.rowData[0]] || '0'
              }
              currencySymbol="$"
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value) =>
                handleChange(
                  tableMeta?.rowIndex,
                  rfpBuidsData[tableMeta?.rowIndex]?.Service,
                  value,
                )
              }
              showLabel={false}
            />
          )
        default:
          return emptyTableCellPlaceholder
      }
    },
    [rfpProjectData, scopeServiceOpts, rfpBuidsData, values, choices],
  )

  const summaryTableColumns = proposalDetailColumns?.map((report) => ({
    ...report,
    options: {
      ...report.options,
      customBodyRender,
    },
  }))

  const sosColumns = scopOfServicesColumns?.map((report) => ({
    ...report,
    options: {
      ...report.options,
      customBodyRender,
    },
  }))

  const warningModalAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => setShowWarningModal()}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={() => {
          handleOpenCloseModal(true)
          setShowWarningModal(false)
        }}
      >
        Yes
      </DKTButton>
    </>
  )

  const modalActions = (
    <DKTButton
      variant="contained"
      onClick={reurnBids}
      disabled={sendRFPLoading}
      sx={{ ml: 'auto' }}
      startIcon={
        sendRFPLoading && (
          <DKTCircularProgress size={spinnerSize?.sm} color="grey" />
        )
      }
    >
      Yes
    </DKTButton>
  )

  const commentField = renderFormFields({
    values: { ...rfpProjectData },
    errors: {},
    formFields: {
      comments: {
        ...rfpDetailsForm?.commentField?.comments,
        disabled: true,
      },
    },
  })

  return (
    <InnerLayout actions={actions}>
      {equal(fetchBidsLoading, true) || equal(isLoadingSingleRfp, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(fetchBidsLoading, 'FAILED') ||
        equal(isLoadingSingleRfp, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching RFP Data. Please try
            contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Box>
            <Stack pb={2} pt={1} backgroundColor="#fff" mb={2}>
              <Box mb={2}>
                <DKTTypography variant="h6" fontWeight="bold">
                  {rfpProjectData?.projectName}
                </DKTTypography>
                <DKTTypography
                  variant="body2"
                  color="primary"
                  component="a"
                  href={rfpProjectData?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  {rfpProjectData?.fullAddress}
                </DKTTypography>
              </Box>
              <Box>
                <DKTTypography variant="body1" sx={{ fontWeight: 600 }}>
                  Client Contact
                </DKTTypography>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
                >
                  <DKTTypography variant="body1">
                    {rfpProjectData?.clientInfo?.clientName}
                  </DKTTypography>
                  <DKTTypography
                    variant="body2"
                    color="primary"
                    component="a"
                    href={`mailto:${rfpProjectData?.clientInfo?.email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    {rfpProjectData?.clientInfo?.email}
                  </DKTTypography>
                  {rfpProjectData?.clientInfo?.workPhoneNumber && (
                    <DKTTypography variant="body1">
                      {rfpProjectData?.clientInfo?.workPhoneNumber} (w)
                    </DKTTypography>
                  )}
                  {rfpProjectData?.clientInfo?.cellPhone && (
                    <DKTTypography variant="body1">
                      {rfpProjectData?.clientInfo?.cellPhone} (w)
                    </DKTTypography>
                  )}
                </Box>
              </Box>
            </Stack>
          </Box>
          <DKTGrid container columnSpacing={3} mt={3}>
            <DKTGrid item md={6}>
              <Box>
                <DKTMuiDataTable
                  data={new Array(10).fill(0)}
                  options={options}
                  columns={summaryTableColumns}
                />
              </Box>
              <Box mt={5}>
                <DKTTypography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Comments
                </DKTTypography>
                {commentField}
              </Box>
            </DKTGrid>
            <DKTGrid item md={6}>
              <Box>
                <DKTMuiDataTable
                  data={rfpBuidsData || []}
                  options={options}
                  columns={sosColumns}
                  className={classes.sosTable}
                />
              </Box>
            </DKTGrid>
          </DKTGrid>
          <DKTDialog
            open={open}
            handleClose={() => handleOpenCloseModal()}
            actions={modalActions}
            title="RFP Notification"
            maxWidth="xs"
          >
            <Typography
              variant="h6"
              color="#646464"
              textAlign="center"
              fontSize={18}
            >
              The RFP bid will be returned to {rfpProjectData?.client}.
              Continue?
            </Typography>
          </DKTDialog>
          <DKTDialog
            open={showWarningModal}
            handleClose={() => setShowWarningModal(false)}
            actions={warningModalAction}
            title="Warning"
            titleSx={{ color: 'red' }}
            maxWidth="xs"
          >
            <Typography
              variant="h6"
              color="#646464"
              textAlign="center"
              fontSize={18}
            >
              Fees are missing from Scope of Services. Continue?
            </Typography>
          </DKTDialog>
          <DKTDialog
            title="&nbsp;"
            open={successModal}
            handleClose={() => handleOpenSuccessmodal(false)}
            maxWidth="xs"
          >
            <Typography
              variant="h5"
              textAlign="center"
              fontSize={18}
              fontWeight="medium"
            >
              Your bid has been successfully returned to the client. Thanks for
              your participation in the RFP. The Award Status column on the RFP
              page will confirm if your company has been selected.
            </Typography>
          </DKTDialog>

          <DKTDialog
            open={onSaveModal}
            handleClose={() => setOnSaveModal(false)}
            actions={saveModalAction}
            title="Return Bid Notification"
            maxWidth="xs"
          >
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="body2" color="gray.extraDark" ml={2}>
                Would you like to return this bid to the Client?
              </Typography>
            </Stack>
          </DKTDialog>
          <DKTReactRouterPrompt
            isDirty={isUnsavedChangesModalOpen}
            onSave={handleSave}
          />
        </>
      )}
    </InnerLayout>
  )
}

export default memo(RFPOverviewConsultant)
