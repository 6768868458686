export const FETCH_PDF_LIST_REQUESTED = 'FETCH_PDF_LIST_REQUESTED'
export const FETCH_PDF_LIST_SUCCESS = 'FETCH_PDF_LIST_SUCCESS'
export const FETCH_PDF_LIST_FAILED = 'FETCH_PDF_LIST_FAILED'

// upload pdf in attachments

export const UPLOAD_PDF_REQUESTED = 'UPLOAD_PDF_REQUESTED'
export const UPLOAD_PDF_SUCCESS = 'UPLOAD_PDF_SUCCESS'
export const UPLOAD_PDF_FAILED = 'UPLOAD_PDF_FAILED'
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS'

// update report attachmnets pdfList in attachments

export const UPDATE_REPORT_ATTACHMENTS_PDF_LIST_REQUESTED =
  'UPDATE_REPORT_ATTACHMENTS_PDF_LIST_REQUESTED'
export const UPDATE_REPORT_ATTACHMENTS_PDF_LIST_SUCCESS =
  'UPDATE_REPORT_ATTACHMENTS_PDF_LIST_SUCCESS'
export const UPDATE_REPORT_ATTACHMENTS_PDF_LIST_FAILED =
  'UPDATE_REPORT_ATTACHMENTS_PDF_LIST_FAILED'

// delete pdf in attachments

export const DELETE_PDF_REQUESTED = 'DELETE_PDF_REQUESTED'
export const DELETE_PDF_SUCCESS = 'DELETE_PDF_SUCCESS'
export const DELETE_PDF_FAILED = 'DELETE_PDF_FAILED'

// project attachments section

export const FETCH_PROJECT_PDF_LIST_REQUESTED =
  'FETCH_PROJECT_PDF_LIST_REQUESTED'
export const FETCH_PROJECT_LIST_SUCCESS = 'FETCH_PROJECT_LIST_SUCCESS'
export const FETCH_PROJECT_PDF_LIST_FAILED = 'FETCH_PROJECT_PDF_LIST_FAILED'

// update project attachmnets pdfList in attachments

export const UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_REQUESTED =
  'UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_REQUESTED'
export const UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_SUCCESS =
  'UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_SUCCESS'
export const UPDATE_PROJECT_ATTACHMENTS_PDF_LIST_FAILED =
  'UPDATE_REPORT_ATTACHMENTS_PDF_LIST_FAILED'

// clear attachments
export const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS'
