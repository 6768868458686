import { ExpandButton } from 'mui-datatables'

export const projectInformation = 'Project Information'
export const executiveSummary = 'Executive Summary'
export const budgetSummary = 'Budget Summary'
export const contingency = 'Contingency'
export const constructionSummary = 'Construction Summary'
export const constructionSchedule = 'Construction Schedule'
export const permitsEntitlements = 'Permits & Entitlements'
export const thirdPartyReports = 'Third Party Reports'
export const photographs = 'Photographs'
export const appendix = 'Appendix'

export const initialReportTierSubSection = {
  reportSection: null,
  includeInAppendix: null,
}

export const reportSections = [
  {
    name: 'Project Information',
    subSections: [
      { name: 'Project Summary' },
      { name: 'Site & Building' },
      { name: 'Environmental' },
      { name: 'Owner Contractor' },
      { name: 'Owner Architect' },
      { name: 'Budget & Contingency' },
      { name: 'Construction Schedule' },
      { name: 'Project Directory' },
    ],
  },
  {
    name: 'Executive Summary',
  },
  {
    name: 'Budget Summary',
    subSections: [
      { name: 'G703 Tracker', includeInAppendix: false },
      { name: 'CO Tracker', includeInAppendix: false },
      { name: 'PCO Tracker', includeInAppendix: false },
      { name: 'Lien Waivers Tracker', includeInAppendix: false },
      { name: 'Stored Material Tracker', includeInAppendix: false },
    ],
  },
  {
    name: 'Contingency',
  },
  {
    name: 'Construction Summary',
  },
  {
    name: 'Construction Schedule',
    subSections: [{ name: 'Milestone Tracker' }],
  },
  {
    name: 'Permits & Entitlements',
  },
  {
    name: 'Third Party Reports',
  },
  {
    name: 'Photographs',
  },
  {
    name: 'Appendix',
  },
]

// For disabled accordion Main Panel checkbox
export const alwaysDisabledReportSections = [
  'Executive Summary',
  'Project Summary',
  'Budget Summary',
]

export const initialReportTierSettings = [
  {
    reportSection: 'Project Summary',
    firstTier: true,
    secondTier: true,
    thirdTier: true,
    subSections: [
      {
        reportSection: 'Site & Building',
        firstTier: true,
        secondTier: false,
        thirdTier: false,
      },
      {
        reportSection: 'Environmental',
        firstTier: true,
        secondTier: false,
        thirdTier: false,
      },
      {
        reportSection: 'Owner Contractor',
        firstTier: true,
        secondTier: false,
        thirdTier: false,
      },
      {
        reportSection: 'Owner Architect',
        firstTier: true,
        secondTier: false,
        thirdTier: false,
      },
      {
        reportSection: 'Budget & Contingency',
        firstTier: true,
        secondTier: false,
        thirdTier: false,
      },
      {
        reportSection: 'Construction Schedule',
        firstTier: true,
        secondTier: false,
        thirdTier: false,
      },
      {
        reportSection: 'Project Directory',
        firstTier: true,
        secondTier: false,
        thirdTier: false,
      },
    ],
  },
  {
    reportSection: 'Executive Summary',
    firstTier: true,
    secondTier: true,
    thirdTier: true,
    disabledFields: ['secondTier', 'thirdTier'],
  },
  {
    reportSection: 'Budget Summary',
    firstTier: true,
    secondTier: true,
    thirdTier: true,
    // subSections: [
    //   {
    //     reportSection: 'G703 Tracker',
    //     firstTier: undefined,
    //     secondTier: undefined,
    //     thirdTier: undefined,
    //     includeInAppendix: false,
    //   },
    //   {
    //     reportSection: 'CO Tracker',
    //     firstTier: undefined,
    //     secondTier: undefined,
    //     thirdTier: undefined,
    //     includeInAppendix: false,
    //   },
    //   {
    //     reportSection: 'PCO Tracker',
    //     firstTier: undefined,
    //     secondTier: undefined,
    //     thirdTier: undefined,
    //     includeInAppendix: false,
    //   },
    //   {
    //     reportSection: 'Lien Waivers Tracker',
    //     firstTier: undefined,
    //     secondTier: undefined,
    //     thirdTier: undefined,
    //     includeInAppendix: false,
    //   },
    //   {
    //     reportSection: 'Stored Material Tracker',
    //     firstTier: undefined,
    //     secondTier: undefined,
    //     thirdTier: undefined,
    //     includeInAppendix: false,
    //   },
    // ],
  },
  {
    reportSection: 'Contingency',
    firstTier: true,
    secondTier: false,
    thirdTier: false,
  },
  {
    reportSection: 'Construction Summary',
    firstTier: true,
    secondTier: false,
    thirdTier: false,
  },
  {
    reportSection: 'Construction Schedule',
    firstTier: true,
    secondTier: false,
    thirdTier: false,
    // subSections: [
    //   {
    //     reportSection: 'Milestone Tracker',
    //     firstTier: undefined,
    //     secondTier: undefined,
    //     thirdTier: undefined,
    //     includeInAppendix: false,
    //   },
    // ],
  },
  {
    reportSection: 'Permits & Entitlements',
    firstTier: true,
    secondTier: false,
    thirdTier: false,
  },
  {
    reportSection: 'Third Party Reports',
    firstTier: true,
    secondTier: false,
    thirdTier: false,
  },
  {
    reportSection: 'Photographs',
    firstTier: true,
    secondTier: false,
    thirdTier: false,
  },
  {
    reportSection: 'Appendix',
    firstTier: true,
    secondTier: false,
    thirdTier: false,
  },
]

export const reportSectionValues = Object.values(reportSections)

export const tiers = ['Tier 01', 'Tier 02', 'Tier 03']

export const components = {
  ExpandButton(props) {
    if (!reportSectionValues[props.dataIndex]?.subSections)
      return <div style={{ width: '24px' }} />
    return <ExpandButton {...props} />
  },
}
