import { Box, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  tilesArray,
  filterTilesArray,
  formFields,
} from '../../Description/dashboard.description'
import MainLayout from '../../Layout/main'
import DKTButton from '../../Shared/DKTButton'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import DKTSelect from '../../Shared/DKTSelect'
import GoogleMap from './GoogleMap'
import { useStyles } from '../../Styles/globalDashboard.style'
import {
  checkIncludes,
  debounceFunction,
  entries,
  equal,
  isEmptyString,
  keys,
  ternary,
} from '../../Utils/javascript'
import {
  getProjectsList,
  getTotalCountsOfTiles,
  setProjectData,
} from '../../Redux/actions/globalDashboard'
import DKTCard from '../../Shared/DKTCard'
import { dashboardFilters } from '../../Utils/constant'
import DKTTypography from '../../Shared/DKTTypography'
import DKTGrid from '../../Shared/DKTGrid'
import DKTStack from '../../Shared/DKTStack'
import DKTInput from '../../Shared/DKTInput'
import ClientAccessDashboardTableRow from './ClientAccessDashboardTableRow'

const FormSectionDivider = memo(
  ({ sectionTitle, rightSection, mt = 3, childrenComp, ...rest }) => (
    <>
      <DKTStack
        {...rest}
        direction="row"
        alignItems="end"
        justifyContent="space-between"
        width="100%"
        mt={mt}
      >
        {childrenComp}
        {renderTypography({
          text: sectionTitle,
          variant: 'body2',
          color: 'gray.extraDark',
          sx: { fontWeight: 'medium', paddingBottom: 0.5 },
        })}
        {rightSection}
      </DKTStack>
      <Divider sx={{ mb: 1, background: '#D9C666' }} />
    </>
  ),
)

const renderTypography = ({ text, children, ...rest }) => (
  <DKTTypography {...rest}>
    {text}
    {children && children}
  </DKTTypography>
)

const renderStack = (label, value) => (
  <DKTStack direction="row" justifyContent="space-between" alignItems="center">
    {renderTypography({
      text: label,
      color: 'black.main',
      fontWeight: '700',
      fontSize: '12px',
    })}
    {renderTypography({
      text: value,
      color: '#F00',
      fontWeight: '700',
      fontSize: '12px',
      flex: '0 0 35%',
    })}
  </DKTStack>
)

const ConsultantDashboard = ({
  dynamicOptions,
  handleRedirect,
  handleFavorite,
}) => {
  const dispatch = useDispatch()

  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false)
  const [values, setValues] = useState(dashboardFilters)
  const [projects, setProjects] = useState([])
  const [activeTiles, setActiveTiles] = useState({})
  const [delay, setDelay] = useState(false)

  const {
    totalCountsOfTitles,
    projectList,
    isFetchingProjectList,
    isFetchingFilterProjects,
    isFetchingTotalCounts,
  } = useSelector(({ globalDashboard }) => globalDashboard)
  const isResetBtnDisabled =
    equal(values.projectType, 'all') &&
    equal(values.projectStatus, 'all') &&
    equal(values.constructionType, 'all')

  useEffect(() => {
    dispatch(getTotalCountsOfTiles())
    setActiveTiles({ totalProjects: true })
  }, [])

  useEffect(() => {
    setProjects(projectList)
  }, [projectList])

  useEffect(() => {
    const mapValue = (key) =>
      ternary(equal(values?.[key], 'all'), '', values?.[key])

    const payload = {
      projectType: mapValue('projectType'),
      projectStatus: mapValue('projectStatus'),
      constructionType: mapValue('constructionType'),
      sort: values?.sortByIssue || '',
      search: values?.search || '',
    }

    const queryParams = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      entries(payload).filter(([_, value]) => !equal(value, '')),
    )

    const activeTileData = [...tilesArray, ...filterTilesArray]?.find(
      ({ name }) => equal(name, keys(activeTiles)[0]),
    )

    if (!keys(queryParams).length && activeTileData) {
      if (activeTileData.projectStatus) {
        queryParams.projectStatus = activeTileData.projectStatus
      } else if (activeTileData.tiles) {
        queryParams.tiles = activeTileData.tiles
      } else {
        dispatch(getProjectsList())
      }
    }

    if (keys(queryParams).length > 0) {
      if (queryParams?.search && delay) {
        debounceFunction(() => dispatch(getProjectsList(queryParams)), 500)()
        return
      }
      dispatch(getProjectsList(queryParams))
    }
  }, [values])

  const handleChange = (event) => {
    const { name, value } = event.target
    const cloneValues = { ...values }
    const updatedValue = { ...cloneValues, [name]: value }
    setValues({ ...cloneValues, ...updatedValue })
    setDelay(true)
    if (equal(name, 'search')) {
      debounceFunction(() => setDelay(false), 500)()
    }
  }

  const resetAllFilter = () => {
    setValues(dashboardFilters)
    dashboardFilters.projectStatus = 'all'
  }

  const handleRowClick = (id) => {
    dispatch(setProjectData(id))
  }

  const classes = useStyles()

  const handleClickOnTilesBox = (projectStatus, name) => {
    const projecTilesValue = ['Completed', 'Active', 'New']
    const queryParams = {}

    setValues(dashboardFilters)
    handleCloseSearchBox()
    dashboardFilters.projectStatus = 'all'
    setActiveTiles({ [name]: true })

    if (!isEmptyString(projectStatus)) {
      queryParams[
        ternary(
          checkIncludes(projectStatus, projecTilesValue),
          'projectStatus',
          'tiles',
        )
      ] = projectStatus
    }

    dispatch(getProjectsList(queryParams))
  }
  const mapFormFields = (formFields, extOptions = {}) =>
    entries(formFields).map(
      (
        [
          name,
          { isRequired, isDynamicOptions, defaultValue, options, ...formField },
        ],
        index,
      ) => {
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <DKTGrid key={index} item xs={formField.xs ?? 12}>
            <FormControl
              {...formField}
              options={ternary(
                isDynamicOptions,
                dynamicOptions?.[name],
                options,
              )}
              id={name}
              name={name}
              value={(values && values[name]) || ''}
              onChange={(e) => handleChange(e)}
              {...(extOptions?.autoFocus && index === 0
                ? { autoFocus: true }
                : {})}
              className={classes.selectType}
            />
          </DKTGrid>
        )
      },
    )

  const handleOpenCloseSearchBox = (status) => setIsSearchBoxOpen(status)

  const handleCloseSearchBox = () => {
    handleOpenCloseSearchBox(false)
    if (values?.search) setValues({ ...values, search: '' })
  }

  const consultantProjectList = useMemo(
    () => (
      <DKTStack
        direction="row"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <DKTTypography fontSize="14px">
          {renderTypography({
            text: 'PROJECT LIST ',
            variant: 'body',
            fontWeight: '500',
            mr: 1,
          })}
          <i>
            Selected {projects?.length || 0} /{' '}
            {totalCountsOfTitles?.totalProjects || 0}
          </i>
        </DKTTypography>
        <DKTStack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.selectBox}
        >
          {isSearchBoxOpen ? (
            <DKTStack direction="row" alignItems="center" minWidth="220px">
              {mapFormFields(formFields?.searchProject, { autoFocus: true })}
              <CloseIcon
                fontSize="small"
                color="#000"
                onClick={handleCloseSearchBox}
                cursor="pointer"
              />
            </DKTStack>
          ) : (
            <Box>
              <SearchIcon
                fontSize="small"
                color="#000"
                onClick={() => handleOpenCloseSearchBox(true)}
                cursor="pointer"
              />
            </Box>
          )}
          {mapFormFields(formFields?.selectSortFilter)}
        </DKTStack>
      </DKTStack>
    ),
    [
      projects,
      totalCountsOfTitles?.totalProjects,
      isSearchBoxOpen,
      classes.selectBox,
      dynamicOptions,
      values?.search,
      values?.sortByIssue,
      formFields,
    ],
  )

  return (
    <MainLayout>
      <Box
        sx={{
          backgroundColor: '#F4F4F4',
          minHeight: '100vh',
          boxSizing: 'border-box',
        }}
        pt={12}
        px={5}
      >
        <DKTGrid container spacing={3}>
          <DKTGrid item xs={6}>
            <FormSectionDivider
              sectionTitle="PROJECT FILTERS"
              mt={0}
              sx={{ padding: '5.5px 0' }}
            />
            <DKTGrid container spacing={3} mt={1}>
              <DKTGrid item xs={12}>
                <DKTGrid container spacing={3} justifyContent="center">
                  {tilesArray
                    ?.slice(0, 4)
                    ?.map(({ title, name, projectStatus }) => (
                      <DKTGrid item xs={3} sx={{ cursor: 'pointer' }}>
                        <DKTCard
                          title={title}
                          count={totalCountsOfTitles?.[name] || 0}
                          projectStatus={projectStatus}
                          onClick={() =>
                            handleClickOnTilesBox(projectStatus, name)
                          }
                          className={`${classes.projectTab} ${
                            activeTiles[name] ? classes.oneCard : ''
                          }`}
                        />
                      </DKTGrid>
                    ))}
                </DKTGrid>
              </DKTGrid>
              <DKTGrid item xs={12}>
                <DKTGrid container spacing={2} alignItems="flex-end">
                  <DKTGrid item xs={3.3}>
                    <DKTSelect
                      displayEmpty
                      name="projectType"
                      value={values?.projectType || ''}
                      placeholder="Select"
                      showLabel
                      label="Project Type"
                      options={
                        dynamicOptions?.projectType &&
                        dynamicOptions?.projectType
                      }
                      onChange={(event) => handleChange(event)}
                      className={classes.selectType}
                    />
                  </DKTGrid>
                  <DKTGrid item xs={3.3}>
                    <DKTSelect
                      displayEmpty
                      name="projectStatus"
                      value={values?.projectStatus || ''}
                      placeholder="Select"
                      showLabel
                      label="Project Status"
                      options={
                        dynamicOptions?.projectStatus &&
                        dynamicOptions?.projectStatus
                      }
                      onChange={(event) => handleChange(event)}
                      className={classes.selectType}
                    />
                  </DKTGrid>
                  <DKTGrid item xs={3.3}>
                    <DKTSelect
                      displayEmpty
                      name="constructionType"
                      value={values?.constructionType || ''}
                      placeholder="Select"
                      showLabel
                      label="Construction Type"
                      options={
                        dynamicOptions?.constructionType &&
                        dynamicOptions?.constructionType
                      }
                      onChange={(event) => handleChange(event)}
                      className={classes.selectType}
                    />
                  </DKTGrid>
                  <DKTGrid item xs={2.1}>
                    <DKTButton
                      disabled={isResetBtnDisabled}
                      className={classes.resetButton}
                      onClick={resetAllFilter}
                    >
                      Reset All
                    </DKTButton>
                  </DKTGrid>
                </DKTGrid>
                <Box sx={{ height: '350px', width: '100%' }} mt={3}>
                  <div className="data-view-map">
                    <GoogleMap data={projects || []} height="350px" />
                  </div>
                </Box>
              </DKTGrid>
              <DKTGrid item xs={12}>
                <DKTGrid
                  container
                  columnSpacing={1}
                  justifyContent="center"
                  my={1}
                >
                  {filterTilesArray
                    ?.filter(
                      ({ name }) => !equal(name, 'hundredPercentReportIssued'),
                    )
                    ?.map(({ title, name, tiles }) => (
                      <DKTGrid item xs={3} flex={1} sx={{ cursor: 'pointer' }}>
                        <DKTCard
                          title={title}
                          count={totalCountsOfTitles?.[name] || 0}
                          xs={3}
                          countColor="#646464"
                          className={`${classes.projectTab} ${
                            activeTiles[name] ? classes.oneCard : ''
                          }`}
                          numberLineHeight="48px"
                          onClick={() => handleClickOnTilesBox(tiles, name)}
                        />
                      </DKTGrid>
                    ))}
                </DKTGrid>
              </DKTGrid>
            </DKTGrid>
          </DKTGrid>
          <DKTGrid item xs={6}>
            <FormSectionDivider childrenComp={consultantProjectList} mt={0} />
            <Box mt={3.2} maxHeight="726px" overflow="auto">
              {ternary(
                isFetchingProjectList ||
                  isFetchingFilterProjects ||
                  isFetchingTotalCounts,
                <DKTCircularProgress />,
                ternary(
                  !!projects?.length,
                  projects?.map((row) => (
                    <ClientAccessDashboardTableRow
                      key={row.id}
                      {...{
                        row,
                        classes,
                        handleRedirect,
                        handleFavorite,
                        handleRowClick,
                        projects,
                        setProjects,
                        renderTypography,
                        renderStack,
                      }}
                    />
                  )),
                  renderTypography({
                    text: 'Sorry, there is no matching values to display',
                    textAlign: 'center',
                  }),
                ),
              )}
            </Box>
          </DKTGrid>
        </DKTGrid>
      </Box>
    </MainLayout>
  )
}

export default ConsultantDashboard
