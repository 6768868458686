import { Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Typography, Stack } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  manpowerColumns,
  manpowerOptions,
} from '../../../Description/constructionSummary.description'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTForm from '../../../Shared/DKTForm'
import DKTInput from '../../../Shared/DKTInput'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import { useStyles } from '../../../Styles/constructionSummary.style'
import theme from '../../../Theme'
import { equal, keys, ternary } from '../../../Utils/javascript'
import {
  createUpdateManpowerTracker,
  deleteManpowerTracker,
  getManpowerTracker,
} from '../../../Redux/actions/constructionSummary'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'

const ManpowerTracker = ({
  open,
  onClose,
  ownerContractorId,
  reportId,
  projectId,
  hasOnlyViewPermission,
}) => {
  const [data, setData] = useState([
    {
      nameOfTrade: '',
      workersOnSite: 0,
      report: reportId,
      ownerContractor: ownerContractorId,
      uniqueId: new Date().valueOf(), // To delete local row
    },
  ])
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)

  const recordsToRemove = useRef([])
  const setSelectedRows = useRef(() => {})
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    isLoadingManpowerTracker,
    manpowerTrackerData,
    isCreateAndUpdateManpowerTracker,
    isDeleteManpowerTracker,
  } = useSelector(({ constructionSummary }) => constructionSummary)

  useEffect(() => {
    if (
      reportId &&
      ownerContractorId &&
      projectId &&
      !manpowerTrackerData?.length
    ) {
      dispatch(getManpowerTracker(reportId, ownerContractorId, projectId))
    }
  }, [dispatch, reportId, ownerContractorId, projectId])

  useEffect(() => {
    setData(
      manpowerTrackerData?.length
        ? manpowerTrackerData
        : [
            {
              nameOfTrade: '',
              workersOnSite: 0,
              report: reportId,
              ownerContractor: ownerContractorId,
              uniqueId: new Date().valueOf(),
            },
          ],
    )
  }, [manpowerTrackerData, ownerContractorId])

  const handleOpenCloseDeleteNotification = (status) =>
    setIsDeleteNotificationOpen(status)

  const handleChange = useCallback((row, columnName, value) => {
    setIsUnSavedChanges(true)
    setData((prevData) => {
      const clonedData = [...prevData]
      if (clonedData[row]) {
        clonedData[row][columnName] = value
      }
      return clonedData
    })
  }, [])

  const handleSave = () => {
    setIsUnSavedChanges(false)
    dispatch(
      createUpdateManpowerTracker(data, reportId, ownerContractorId, projectId),
    )
  }

  const handleDelete = () => {
    dispatch(
      deleteManpowerTracker({
        manpowerToDelete: recordsToRemove.current,
        reportId,
        projectId,
        ocId: ownerContractorId,
        setData,
      }),
    ).then(() => {
      setSelectedRows.current([])
      handleOpenCloseDeleteNotification()
    })
  }

  const deleteNotificationActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => handleOpenCloseDeleteNotification(false)}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleDelete}
        disabled={isDeleteManpowerTracker}
      >
        Yes
      </DKTButton>
    </>
  )

  const manPowerTrackerActions = (
    <DKTButton
      sx={{ ml: 'auto' }}
      onClick={handleSave}
      disabled={
        hasOnlyViewPermission ||
        isCreateAndUpdateManpowerTracker ||
        isLoadingManpowerTracker
      }
    >
      {ternary(
        isCreateAndUpdateManpowerTracker,
        <DKTCircularProgress color={theme.palette.gray.dark} />,
        'Save',
      )}
    </DKTButton>
  )

  const customBodyRender = (columnName, readOnly, value, tableData) => {
    const { rowIndex } = tableData
    return (
      <DKTForm autoComplete="off">
        <DKTInput
          type={ternary(equal(columnName, 'nameOfTrade'), 'text', 'number')}
          value={value}
          disabled={hasOnlyViewPermission || isLoadingManpowerTracker}
          onChange={(e) => handleChange(rowIndex, columnName, e.target.value)}
          placeholder="Enter text"
          showLabel={false}
        />
      </DKTForm>
    )
  }

  const customToolbarSelect = (
    selectedRows,
    displayData,
    setSelectedTableRows,
  ) => {
    const selectedRecords = keys(selectedRows.lookup)
    const selectedRecordIds = selectedRecords.map(
      (rowToDelete) => data[rowToDelete]?.id ?? data[rowToDelete]?.uniqueId,
    )
    setSelectedRows.current = setSelectedTableRows
    const setRecordsToRemove = () => {
      recordsToRemove.current = selectedRecordIds
    }
    return (
      <Box>
        <IconButton
          sx={{ mr: 1 }}
          onClick={() => {
            setRecordsToRemove()
            handleOpenCloseDeleteNotification(true)
          }}
        >
          <Delete color="error" />
        </IconButton>
      </Box>
    )
  }

  const options = {
    ...manpowerOptions,
    customToolbarSelect,
    rowsPerPage: 5,
    selectableRows: ternary(hasOnlyViewPermission, 'none', 'multiple'),
    textLabels: {
      body: {
        noMatch: isLoadingManpowerTracker ? (
          <DKTCircularProgress />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
  }

  const columns = manpowerColumns.map((column) => ({
    ...column,
    options: {
      ...column.options,
      customBodyRender: (...props) =>
        customBodyRender(column.name, false, ...props),
    },
  }))

  const addRow = () => {
    setData((prevData) => [
      ...prevData,
      {
        nameOfTrade: '',
        workersOnSite: 0,
        report: reportId,
        ownerContractor: ownerContractorId,
        uniqueId: new Date().valueOf(), // To delete local row
      },
    ])
  }

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }

  const handleCloseLienWaiver = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }

  return (
    <>
      <DKTDialog
        title="Labor Tracker"
        open={open}
        handleClose={handleCloseLienWaiver}
        actions={manPowerTrackerActions}
        maxWidth="lg"
        className={classes.modalManPower}
      >
        {equal(isLoadingManpowerTracker, true) ? (
          <Stack alignItems="center" justifyContent="center">
            <DKTCircularProgress />
          </Stack>
        ) : equal(isLoadingManpowerTracker, 'FAILED') ? (
          <Stack alignItems="center" justifyContent="center">
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching Man Power Tracker data.
              Please try contacting the admin or refreshing this page.
            </Typography>
          </Stack>
        ) : (
          <>
            <DKTMuiDataTable
              columns={columns}
              options={options}
              data={data || [{}]}
            />
            <DKTButton
              variant="text"
              startIcon={
                <CircledPlusIcon
                  disabled={
                    hasOnlyViewPermission || isCreateAndUpdateManpowerTracker
                  }
                />
              }
              onClick={addRow}
              disabled={
                hasOnlyViewPermission || isCreateAndUpdateManpowerTracker
              }
            >
              Add row
            </DKTButton>
          </>
        )}
      </DKTDialog>
      {/* show warning modal when Delete a row */}
      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={() => handleOpenCloseDeleteNotification(false)}
        title="&nbsp;"
        actions={deleteNotificationActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}

      {!hasOnlyViewPermission && (
        <>
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={handleSave}
          />
          <DKTReactRouterPrompt
            isDirty={isUnSavedChanges}
            onSave={handleSave}
          />
        </>
      )}
    </>
  )
}
export default ManpowerTracker
