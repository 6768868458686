import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  FETCH_APPENDIX_FAILED,
  FETCH_APPENDIX_REQUESTED,
  FETCH_APPENDIX_SUCCESS,
  SAVE_APPENDIX_FAILED,
  SAVE_APPENDIX_REQUESTED,
  SAVE_APPENDIX_SUCCESS,
  IMPORT_APPENDIX_COMMENTS_COMMENTS_REQUESTED,
  IMPORT_APPENDIX_REPORT_COMMENTS_SUCCESS,
  IMPORT_APPENDIX_REPORT_COMMENTS_FAILED,
} from '../constants/appendix'
import { updateSaveStatusAction } from './confirmation'
import { getReportList } from './reportMonitoring'

// Fetch appendix
const fetchAppendixRequested = () => ({
  type: FETCH_APPENDIX_REQUESTED,
})
const fetchAppendixSuccess = (payload) => ({
  type: FETCH_APPENDIX_SUCCESS,
  payload,
})
const fetchAppendixFailed = (payload) => ({
  type: FETCH_APPENDIX_FAILED,
  payload,
})

export const getAppendix = (reportId, ocId, projectId) => async (dispatch) => {
  dispatch(fetchAppendixRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.appendix}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
  })
  if (data) {
    const updatedData = {
      ...data,
      appendix: data?.appendix?.map((item, index) => {
        if (
          equal(index, 0) &&
          equal(item?.document, 'Tracker (if applicable)')
        ) {
          return {
            ...item,
            isDraggable: false,
            isStatus: false,
            isIncludeInAppendix: false,
          }
        }
        return {
          ...item,
          isIncludeInAppendix: true,
        }
      }),
    }
    dispatch(fetchAppendixSuccess(updatedData))
  }
  if (error) {
    if (equal(error?.response?.status, 404)) {
      dispatch(fetchAppendixSuccess({ appendix: [], id: null }))
    }
    dispatch(fetchAppendixFailed(error.response))
  }
}

// Save appendix
const saveAppendixRequested = () => ({
  type: SAVE_APPENDIX_REQUESTED,
})
export const saveAppendixSuccess = (payload) => ({
  type: SAVE_APPENDIX_SUCCESS,
  payload,
})
const saveAppendixFailed = (payload) => ({
  type: SAVE_APPENDIX_FAILED,
  payload,
})

export const saveAppendix =
  ({ payload, reportId, ocId, projectId, ownerContractorUniqueCode }) =>
  async (dispatch, getState) => {
    dispatch(saveAppendixRequested())
    const { reportData } = getState().reportMonitoring
    const { appendixId } = getState().appendix
    const isAlreadyCreated =
      reportData?.formCreatedList[ownerContractorUniqueCode]?.appendix
    const { data, error } = await api({
      method: method.patch,
      endPoint: `${apiEndPoints.appendix}${appendixId}/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      data: {
        appendix: payload?.data,
        comments: payload?.comments,
        ownerContractor: ocId,
        report: reportId,
      },
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      if (!isAlreadyCreated) dispatch(getReportList(projectId, ocId, reportId))

      dispatch(saveAppendixSuccess(data))
    }
    if (error) {
      dispatch(saveAppendixFailed(error.response.data))
    }
  }

// Import construction schedule report from last report
const importAppendixCommentsRequested = () => ({
  type: IMPORT_APPENDIX_COMMENTS_COMMENTS_REQUESTED,
})
const importAppendixCommentsSuccess = (payload) => ({
  type: IMPORT_APPENDIX_REPORT_COMMENTS_SUCCESS,
  payload,
})
const importAppendixCommentsFailed = (payload) => ({
  type: IMPORT_APPENDIX_REPORT_COMMENTS_FAILED,
  payload,
})

export const importAppendixComments =
  ({ projectId, ocId, reportId, onSuccess = () => null }) =>
  async (dispatch) => {
    dispatch(importAppendixCommentsRequested())

    const { data, error } = await api({
      endPoint: `${apiEndPoints.importLastReport}?query=appendixComments&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
      method: method.get,
    })
    if (data) {
      dispatch(importAppendixCommentsSuccess(data))
      onSuccess(data?.appendixComments)
    }
    if (error) {
      showToast('Failed to import comments')
      dispatch(importAppendixCommentsFailed(error.response.data))
    }
  }
