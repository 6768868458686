import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  companyPersonalTitle: {
    '&.MuiTypography-root': {
      padding: '25px 0',
      marginBottom: 0,
      display: 'block',
    },
  },
  companyPersonnelName: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&.MuiTypography-root': {
      fontSize: '14px',
    },
  },
  autoCreateCheckBox: {
    '&.MuiFormControl-root': {
      alignItems: 'center',
      flexDirection: 'row',
    },
    '& .MuiCheckbox-root': {
      paddingLeft: 0,
    },
    '& .MuiInputLabel-root': {
      color: '#3E3E3E',
      position: 'inherit',
      fontSize: 13,
      transform: 'translate(0)',
    },
  },
  contactFilterModal: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 793,
    },
  },
  contactFilterListWrap: {},
  listTitle: {
    padding: 16,
    background: theme.palette.gray.main,
    '& h5': {
      color: '#161616',
      fontSize: 14,
      fontWeight: 500,
    },
  },
  contactFilterList: {
    border: '1px solid #C4C4C4',
    padding: '10px 12px 10px 0',
  },
  filterCheckBox: {
    maxHeight: 255,
    minHeight: 255,
    overflowY: 'auto',
    '& .MuiFormControl-root': {
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
    },
    '& .MuiCheckbox-root': {
      padding: '6px 8px',
    },
    '& .MuiInputLabel-root': {
      color: '#3E3E3E',
      position: 'inherit',
      fontSize: 14,
      transform: 'translate(0)',
      whiteSpace: 'normal',
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  companyPersonnelTable: {
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          padding: '7px 16px',
        },
      },
    },
    '& .MuiTableCell-body': {
      padding: '7px 16px',
      boxSizing: 'border-box',
    },
    '@media print': {
      '& .tss-5jmwmz-MUIDataTableFooter-root': {
        display: 'none',
      },
      '& .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft': {
        display: 'none',
      },
    },
  },
}))
