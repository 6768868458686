import { Box, Grid, Stack, Typography, IconButton, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Delete } from '@mui/icons-material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {
  projectDirectoryColumnArr,
  projectSummaryArr,
  summaryColumnArr,
  options,
} from '../../Description/projectDashboardDashboard.description'
import { emptyTableCellPlaceholder } from '../../Description/reportMonitoring.description'
import { OuterLayout } from '../../Layout/project'
import DKTButtonSelect from '../../Shared/DKTButtonSelect'
import DKTFileInput from '../../Shared/DKTFileInput'
import DKTMuiDataTable from '../../Shared/DKTMuiDataTable'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import {
  handleDownloadImage,
  keys,
  modifyNumber,
  renderHtmlContent,
  ternary,
} from '../../Utils/javascript'
import DKTDialog from '../../Shared/DKTDialog'
import DKTButton from '../../Shared/DKTButton'
import {
  clearProjectDashboardData,
  deletePhotograph,
  getProjectDashboard,
  uploadPhotograph,
} from '../../Redux/actions/projectDashboard'
import { getProjectList } from '../../Redux/actions/projects'

const useStyles = makeStyles(() => ({
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  iconWrapper: {
    '&.MuiIconButton-root': {
      backgroundColor: '#fff',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
  },
}))

const ProjectDashboard = () => {
  const [data, setData] = useState({})
  const [image, setImage] = useState()
  const [inputFileError, setInputFileError] = useState(false)
  const [deleteNotificationOpen, setOpenDeleteNotification] = useState(false)

  const classes = useStyles()
  const { currentProject } = useSelector(({ projects }) => projects)
  const { slug: projectId } = useParams()
  const dispatch = useDispatch()
  const { isLoadingProjectDashboard, projectDashboard } = useSelector(
    ({ projectDashboard }) => projectDashboard,
  )

  const actionList = [
    { label: 'Download .xls', onClick: () => null },
    { label: 'Print', onClick: () => null },
  ]

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectList(projectId))
      dispatch(getProjectDashboard(projectId))
    }
  }, [dispatch, projectId])

  useEffect(() => {
    setData(projectDashboard)
  }, [projectDashboard])

  useEffect(() => {
    setImage(
      projectDashboard?.project_dashboard_attachment_data?.attachment || null,
    )
  }, [projectDashboard?.project_dashboard_attachment_data])

  useEffect(
    () => () => {
      dispatch(clearProjectDashboardData())
    },
    [],
  )

  const actions = () => (
    <Stack direction="row" gap={2}>
      <DKTButtonSelect options={actionList} />
    </Stack>
  )
  const prepend = (string, prefix) => `${prefix} ${string}`
  const formatDate = (date) =>
    ternary(date, moment(date).format('MM/DD/YY'), '-')

  const getLatestValue = (type, value) => {
    switch (type) {
      case 'date':
        return formatDate(value)
      case 'currency':
        return prepend(modifyNumber(value || 0), '$')
      case 'number':
        return modifyNumber(value)
      default:
        return value || emptyTableCellPlaceholder
    }
  }

  const tableOptions = {
    ...options,
    textLabels: {
      body: {
        noMatch: isLoadingProjectDashboard ? (
          <DKTCircularProgress />
        ) : (
          'Sorry, there is no matching values to display'
        ),
      },
    },
  }

  const directoryData = keys(data?.project_directory_data)?.map((item) => ({
    label: item,
    name: item,
    options: {
      filter: true,
      sort: false,
    },
  }))

  const customBodyRender = useCallback(
    (value, tableMeta) => {
      const directoryArrData = directoryData?.[tableMeta?.rowIndex]
      switch (tableMeta?.columnData?.name) {
        case 'reportCode':
          return <b>{directoryArrData?.label}</b>
        case 'projectDirectory':
          return data?.project_directory_data?.[directoryArrData?.name] || '--'
        default:
          return emptyTableCellPlaceholder
      }
    },
    [data],
  )

  const descriptionTableColumns = projectDirectoryColumnArr?.map((report) => ({
    ...report,
    options: { ...report.options, customBodyRender },
  }))

  const customBodyRenderForSummary = useCallback(
    (value, tableMeta) => {
      const summaryArrData = projectSummaryArr?.[tableMeta?.rowIndex]
      switch (tableMeta?.columnData?.name) {
        case 'reportCode':
          return <b>{summaryArrData?.label}</b>
        case 'projectSummary':
          return (
            getLatestValue(
              summaryArrData?.formateType,
              data?.[summaryArrData?.name],
            ) || emptyTableCellPlaceholder
          )
        default:
          return emptyTableCellPlaceholder
      }
    },
    [data],
  )

  const summaryTableColumns = summaryColumnArr?.map((report) => ({
    ...report,
    options: {
      ...report.options,
      customBodyRender: customBodyRenderForSummary,
    },
  }))

  const onFileUpload = (logo) => {
    setImage(logo)
    setInputFileError(false)
    const formData = new FormData()
    formData.append('attachment', logo)
    dispatch(uploadPhotograph(formData, projectId))
  }

  const handleOpenDeleteConfirmation = () => {
    setOpenDeleteNotification(true)
  }
  const handleDelete = () => {
    const payload = {
      project_dashboard_attachment: [
        projectDashboard?.project_dashboard_attachment_data?.id,
      ],
    }
    dispatch(deletePhotograph(payload, projectId))
    handleClose()
  }

  const handleClose = () => setOpenDeleteNotification(false)

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDelete}>
        Yes
      </DKTButton>
    </>
  )

  return (
    <OuterLayout
      title={currentProject?.projectName}
      actions={actions}
      isShowMenu
    >
      <Stack mt={20} px={3} py={2.5} backgroundColor="#F4F4F4">
        <Grid container spacing={3} className={classes.loginContainer}>
          <Grid item md={5}>
            <Box
              component="form"
              height="100%"
              position="relative"
              enctype="multipart/form-data"
            >
              <DKTFileInput
                onFileUpload={onFileUpload}
                defaultImage={image}
                errorText={ternary(inputFileError, 'Upload Cover Photo', null)}
                height="100%"
                uploadLimit={5}
                sx={{
                  height: '100%',
                  '& img': {
                    height: '100%',
                    maxHeight: 494,
                    width: '100%',
                    objectFit: 'cover',
                  },
                }}
              />
              <Stack
                direction="row"
                position="absolute"
                right={5}
                top={5}
                spacing={0.5}
                mt={0.5}
              >
                {ternary(
                  image,
                  <>
                    <IconButton
                      className={classes.iconWrapper}
                      onClick={() =>
                        handleDownloadImage(
                          image,
                          `${currentProject?.projectName} dashboard`,
                        )
                      }
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                    <IconButton
                      className={classes.iconWrapper}
                      color="error"
                      onClick={handleOpenDeleteConfirmation}
                    >
                      <Delete />
                    </IconButton>
                  </>,
                  null,
                )}
              </Stack>
            </Box>
          </Grid>
          <Grid item md={7}>
            <Stack p={2} backgroundColor="#fff" mb={2} minHeight={234}>
              <Box mb={2}>
                <Typography variant="h6">{data?.name}</Typography>
                <Link
                  href={data?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data?.address}
                </Link>
                <Typography variant="body1">
                  Project Status:{' '}
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    component="span"
                  >
                    {data?.status}
                  </Typography>
                </Typography>
              </Box>
              <Typography variant="body1" fontWeight="bold">
                Report By/Project Manager:
              </Typography>
              <Typography variant="body1">{data?.company_name}</Typography>
              <Typography variant="body1">{data?.full_name}</Typography>
              <Typography variant="body1"> {data?.email}</Typography>
              {data?.work_phone && (
                <Typography variant="body1">{data?.work_phone}(w)</Typography>
              )}
              {data?.cell_phone && (
                <Typography variant="body1">{data?.cell_phone}(c)</Typography>
              )}
            </Stack>
            <Box
              sx={{
                overflowY: 'auto',
                minHeight: 215,
                '& .MuiPaper-root > div:nth-child(2)': {
                  maxHeight: 215,
                  minHeight: 215,
                },
                '& .MuiTableRow-root': {
                  '&:hover': {
                    backgroundColor: 'transparent',
                    '& .MuiTableCell-root': {
                      backgroundColor: 'rgba(0,0,0,0.04)',
                    },
                  },
                  '& .datatables-noprint': {
                    border: 0,
                    '& .datatables-noprint': {
                      minHeight: 125,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    },
                  },
                },
              }}
            >
              <DKTMuiDataTable
                data={directoryData}
                options={tableOptions}
                columns={descriptionTableColumns}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={3}
          mt={3}
          className={classes.loginContainer}
        >
          <Grid item md={5}>
            <Box
              sx={{
                overflowY: 'auto',
                '& .MuiPaper-root > div': { maxHeight: 672 },
              }}
            >
              <DKTMuiDataTable
                data={new Array(13).fill(0)}
                options={tableOptions}
                columns={summaryTableColumns}
              />
            </Box>
          </Grid>
          <Grid item md={7}>
            <Box backgroundColor="#fff" height="100%">
              <Stack backgroundColor="#E5E5E5" p={2.3}>
                <Typography variant="body2" fontWeight="500">
                  PROJECT DESCRIPTION
                </Typography>
              </Stack>
              <Stack p={3} maxHeight={556} sx={{ overflowY: 'auto' }}>
                <Typography
                  variant="body1"
                  component="p"
                  dangerouslySetInnerHTML={renderHtmlContent(
                    data?.project_description,
                  )}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <DKTDialog
          open={deleteNotificationOpen || false}
          handleClose={handleClose}
          title="&nbsp;"
          actions={deleteNotificationAction}
          maxWidth="xs"
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.deleteNotificationContent}>
                <Typography variant="h5">
                  Are you sure you want to delete?
                </Typography>
                <Typography variant="body1">
                  You can’t undo this action
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DKTDialog>
      </Stack>
    </OuterLayout>
  )
}

export default ProjectDashboard
