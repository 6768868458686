import { Stack, Typography } from '@mui/material'
import React from 'react'
import { ImagePlaceholder } from '../../../Assets/SVGs'
import { showToast } from '../../../Utils/toastService'
import { MAX_PDF_SIZE_MB } from '../../../Utils/constant'

const PdfUploader = ({ uploadPdf, setUploadPdf, hasOnlyViewPermission }) => {
  const handlePdfChange = (event) => {
    const files = Array.from(event.target.files)
    handleFiles(files)
  }

  const handleDrop = (event) => {
    event.preventDefault()
    const files = Array.from(event.dataTransfer.files)
    handleFiles(files)
  }

  const handleFiles = (files) => {
    const base64StringArray = []
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      if (file.type !== 'application/pdf') {
        showToast('You can only upload PDF files')
        break
      }
      if (file.size > MAX_PDF_SIZE_MB * 1024 * 1024) {
        showToast(`File size must be less than ${MAX_PDF_SIZE_MB}MB`)
        break
      }
      base64StringArray.push(file)
    }

    setUploadPdf([...uploadPdf, ...base64StringArray])
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        backgroundColor="gray.main"
        height="168px"
        overflow="hidden"
        onDrop={hasOnlyViewPermission ? null : handleDrop}
        onDragOver={hasOnlyViewPermission ? null : handleDragOver}
      >
        <>
          <ImagePlaceholder />
          <Typography
            variant="subtitle2"
            color="#646464"
            fontWeight={400}
            mt={1}
            textTransform="capitalize"
          >
            Drag and drop or{' '}
            <label htmlFor="uploadFile" style={{ cursor: 'pointer' }}>
              <Typography
                variant="subtitle2"
                fontWeight={400}
                color="primary.main"
                sx={{ display: 'inline' }}
              >
                Upload
              </Typography>
            </label>
          </Typography>
        </>
      </Stack>
      <input
        type="file"
        name="uploadFile"
        id="uploadFile"
        style={{ marginTop: 10 }}
        onChange={handlePdfChange}
        multiple
        hidden
        accept="application/pdf"
        onClick={(event) => {
          event.currentTarget.value = null
        }}
        disabled={hasOnlyViewPermission}
      />
    </>
  )
}

export default PdfUploader
