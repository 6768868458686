import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, length, ternary } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  DELETE_CONTINGENCY_FAILED,
  DELETE_CONTINGENCY_REQUESTED,
  DELETE_CONTINGENCY_SUCCESS,
  FETCH_CONTINGENCY_FAILED,
  FETCH_CONTINGENCY_REFERENCE_VALUES_FAILED,
  FETCH_CONTINGENCY_REFERENCE_VALUES_REQUESTED,
  FETCH_CONTINGENCY_REFERENCE_VALUES_SUCCESS,
  FETCH_CONTINGENCY_REQUESTED,
  FETCH_CONTINGENCY_SUCCESS,
  IMPORT_CONTINGENCY_FROM_LAST_REPORT_FAILED,
  IMPORT_CONTINGENCY_FROM_LAST_REPORT_REQUESTED,
  IMPORT_CONTINGENCY_FROM_LAST_REPORT_SUCCESS,
  SAVE_CONTINGENCY_FAILED,
  SAVE_CONTINGENCY_REQUESTED,
  SAVE_CONTINGENCY_SUCCESS,
} from '../constants/contingency'
import { updateSaveStatusAction } from './confirmation'
import { getReportList } from './reportMonitoring'

// Fetch contingency reference values
const fetchContingencyReferenceValuesRequested = () => ({
  type: FETCH_CONTINGENCY_REFERENCE_VALUES_REQUESTED,
})
const fetchContingencyReferenceValuesSuccess = (payload) => ({
  type: FETCH_CONTINGENCY_REFERENCE_VALUES_SUCCESS,
  payload,
})
const fetchContingencyReferenceValuesFailed = (payload) => ({
  type: FETCH_CONTINGENCY_REFERENCE_VALUES_FAILED,
  payload,
})

export const getContingencyReferenceValues =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchContingencyReferenceValuesRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.contingency}reference-values/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
    })
    if (data) {
      dispatch(fetchContingencyReferenceValuesSuccess(data))
      return data
    }
    if (error) {
      dispatch(fetchContingencyReferenceValuesFailed(error.response))
      throw new Error(error?.response?.data)
    }
    return false
  }

// Fetch contingency
const fetchContingencyRequested = () => ({
  type: FETCH_CONTINGENCY_REQUESTED,
})
const fetchContingencySuccess = (payload) => ({
  type: FETCH_CONTINGENCY_SUCCESS,
  payload,
})
const fetchContingencyFailed = (payload) => ({
  type: FETCH_CONTINGENCY_FAILED,
  payload,
})

export const getContingency = (reportId, ocId) => async (dispatch) => {
  dispatch(fetchContingencyRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.contingency}?reportId=${reportId}&ocId=${ocId}`,
  })
  if (data) {
    const contingency = data?.find(({ ownerContractor }) =>
      equal(ownerContractor, ocId),
    )
    dispatch(fetchContingencySuccess(contingency))
  }
  if (error) {
    if (equal(error?.response?.status, 404)) {
      dispatch(saveContingencySuccess(null))
    }
    dispatch(fetchContingencyFailed(error.response))
  }
}

// Save contingency
const saveContingencyRequested = () => ({
  type: SAVE_CONTINGENCY_REQUESTED,
})
export const saveContingencySuccess = (payload) => ({
  type: SAVE_CONTINGENCY_SUCCESS,
  payload,
})
const saveContingencyFailed = (payload) => ({
  type: SAVE_CONTINGENCY_FAILED,
  payload,
})

export const saveContingency =
  ({ payload, reportId, ocId, projectId, ownerContractorUniqueCode }) =>
  async (dispatch, getState) => {
    dispatch(saveContingencyRequested())
    const { reportData } = getState().reportMonitoring
    const { contingency } = getState().contingency
    const isAlreadyCreated =
      reportData?.formCreatedList[ownerContractorUniqueCode]?.contingency
    const { data, error } = await api({
      method: ternary(isAlreadyCreated, method.patch, method.post),
      endPoint: ternary(
        isAlreadyCreated,
        `${apiEndPoints.contingency}${contingency?.id}/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
        `${apiEndPoints.contingency}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      ),
      data: { ...payload, ownerContractor: ocId, report: reportId },
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      if (!isAlreadyCreated) dispatch(getReportList(projectId, ocId, reportId))

      dispatch(saveContingencySuccess(data))
    }
    if (error) {
      dispatch(saveContingencyFailed(error.response.data))
    }
  }

// Delete contingency
const deleteContingencyRequested = () => ({
  type: DELETE_CONTINGENCY_REQUESTED,
})
export const deleteContingencySuccess = (payload) => ({
  type: DELETE_CONTINGENCY_SUCCESS,
  payload,
})
const deleteContingencyFailed = (payload) => ({
  type: DELETE_CONTINGENCY_FAILED,
  payload,
})

export const deleteContingency = (payload, handleClose) => async (dispatch) => {
  dispatch(deleteContingencyRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.deleteContingency}`,
    method: method.delete,
    data: payload,
    showToastMessage: true,
    toastMessage: 'Contingency deleted successfully!',
  })
  if (data) {
    handleClose()
    dispatch(deleteContingencySuccess(payload))
    return
  }
  if (error) {
    dispatch(deleteContingencyFailed(error.response.data))
  }
}

// Import contingency from last report
const importFromLastReportRequested = (query) => ({
  type: IMPORT_CONTINGENCY_FROM_LAST_REPORT_REQUESTED,
  payload: query,
})
const importFromLastReportSuccess = (payload) => ({
  type: IMPORT_CONTINGENCY_FROM_LAST_REPORT_SUCCESS,
  payload,
})
const importFromLastReportFailed = (payload) => ({
  type: IMPORT_CONTINGENCY_FROM_LAST_REPORT_FAILED,
  payload,
})
export const importContingencyFromLastReport =
  ({ query, projectId, ocId, reportId, onSuccess = () => null }) =>
  async (dispatch) => {
    const [firstLetter] = query
    const restWord = query.substr(1, length(query) - 1)
    const pascalCaseQuery = `${firstLetter.toUpperCase()}${restWord}`
    dispatch(importFromLastReportRequested(query))

    const { data, error } = await api({
      endPoint: `${apiEndPoints.importLastReport}?query=${query}&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
      method: method.get,
    })

    if (data) {
      dispatch(
        importFromLastReportSuccess({
          pascalCaseQuery,
          query,
          data,
        }),
      )
      onSuccess(data[query])
    }
    if (error) {
      showToast('Failed to import comments')
      dispatch(
        importFromLastReportFailed({
          pascalCaseQuery,
          query,
          data: error.response.data,
        }),
      )
    }
  }
