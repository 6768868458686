import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import {
  CLEAR_REPORT_CHOICES,
  FETCH_REPORT_CHOICES_FAILED,
  FETCH_REPORT_CHOICES_REQUESTED,
  FETCH_REPORT_CHOICES_SUCCESS,
} from '../constants/reportChoices'

const fetchReportChoicesRequested = () => ({
  type: FETCH_REPORT_CHOICES_REQUESTED,
})
const fetchReportChoicesSuccess = (payload) => ({
  type: FETCH_REPORT_CHOICES_SUCCESS,
  payload,
})
const fetchReportChoicesFailed = (payload) => ({
  type: FETCH_REPORT_CHOICES_FAILED,
  payload,
})
export const getReportChoices =
  ({ formNames, projectId }) =>
  async (dispatch, getState) => {
    const { choices } = getState().projectChoices
    dispatch(fetchReportChoicesRequested())
    const { data, error } =
      formNames &&
      (await api({
        method: method.get,
        endPoint: `${apiEndPoints.reportChoices}${projectId}/?formNames=${formNames}`,
      }))
    if (data) {
      dispatch(
        fetchReportChoicesSuccess({
          ...choices,
          ...data,
        }),
      )
      return
    }
    if (error) {
      dispatch(fetchReportChoicesFailed(error?.response?.data))
    }
  }

export const clearOldReportChoiceData = () => async (dispatch) => {
  dispatch({ type: CLEAR_REPORT_CHOICES })
}
