export const formFields = {
  OwnerArchitectFields: {
    aiaFormAgreement: {
      type: 'select',
      label: 'AIA Form Agreement',
      placeholder: 'Select',
      displayEmpty: true,
    },
    ownerArchitectContractDate: {
      type: 'date',
      clearable: true,
      label: 'Owner Architect Contract Date',
    },
    owner: {
      type: 'text',
      label: 'Owner',
    },
    architect: {
      type: 'select',
      label: 'Architect ',
      displayEmpty: true,
      placeholder: 'Select',
    },
    architecturalFee: {
      type: 'text',
      label: 'Architectural Fee',
      isCurrency: true,
    },
    siteVisitFrequency: {
      type: 'select',
      label: 'Site Visit Frequency ',
      placeholder: 'Select',
      displayEmpty: true,
    },
  },
}

export const architecturalConsultants = {
  type: 'checkbox',
  labelledCheckbox: true,
  fields: [],
  label: 'Architect’s Consultants',
}
