import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import {
  FETCH_PROJECT_LIMITATION_FAILED,
  FETCH_PROJECT_LIMITATION_REQUESTED,
  FETCH_PROJECT_LIMITATION_SUCCESS,
  SAVE_PROJECT_LIMITATIONS_FAILED,
  SAVE_PROJECT_LIMITATIONS_REQUESTED,
  SAVE_PROJECT_LIMITATIONS_SUCCESS,
} from '../constants/projectSettingLimitation'
import { updateSaveStatusAction } from './confirmation'

const fetchProjectLimitationRequested = () => ({
  type: FETCH_PROJECT_LIMITATION_REQUESTED,
})
const fetchProjectLimitationSuccess = (payload) => ({
  type: FETCH_PROJECT_LIMITATION_SUCCESS,
  payload,
})
const fetchProjectLimitationFailed = (payload) => ({
  type: FETCH_PROJECT_LIMITATION_FAILED,
  payload,
})
export const fetchProjectLimitation =
  ({ projectId }) =>
  async (dispatch) => {
    dispatch(fetchProjectLimitationRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.projectLimitation}/${projectId}`,
    })

    if (data) {
      dispatch(fetchProjectLimitationSuccess(data?.limitation))
      return
    }
    if (error) {
      dispatch(fetchProjectLimitationFailed(error.response.data))
    }
  }

const saveProjectLimitationsRequested = () => ({
  type: SAVE_PROJECT_LIMITATIONS_REQUESTED,
})
const saveProjectLimitationsFailed = (payload) => ({
  type: SAVE_PROJECT_LIMITATIONS_FAILED,
  payload,
})
const saveProjectLimitationsSuccess = (payload) => ({
  type: SAVE_PROJECT_LIMITATIONS_SUCCESS,
  payload,
})
export const saveProjectLimitations =
  (projectId, payload) => async (dispatch) => {
    dispatch(saveProjectLimitationsRequested())
    const { data, error } = await api({
      data: {
        limitation: payload,
      },
      method: method.patch,
      endPoint: `${apiEndPoints.projectLimitation}/${projectId}/`,
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(saveProjectLimitationsSuccess(data?.limitation))
      return
    }
    if (error) {
      dispatch(saveProjectLimitationsFailed(error.response.data))
    }
  }
