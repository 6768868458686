import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, ternary } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  ADD_REMOVE_COMPANY_FAILED,
  ADD_REMOVE_COMPANY_REQUESTED,
  ADD_REMOVE_COMPANY_SUCCESS,
  BUIDS_FAILED,
  BUIDS_REQUESTED,
  BUIDS_SUCCESS,
  CREATE_NEW_RFP_FAILED,
  CREATE_NEW_RFP_REQUESTED,
  CREATE_NEW_RFP_SUCCESS,
  DELETE_RFP_FAILED,
  DELETE_RFP_REQUESTED,
  DELETE_RFP_SUCCESS,
  FETCH_RFP_LIST_FAILED,
  FETCH_RFP_LIST_REQUESTED,
  FETCH_RFP_LIST_SUCCESS,
  GET_RFP_COMPANY_LIST_FAILED,
  GET_RFP_COMPANY_LIST_REQUESTED,
  GET_RFP_COMPANY_LIST_SUCCESS,
  GET_SCOPE_SERVICE_OPTS_FAILED,
  GET_SCOPE_SERVICE_OPTS_REQUESTED,
  GET_SCOPE_SERVICE_OPTS_SUCCESS,
  GET_SINGLE_RFP_DATA_FAILED,
  GET_SINGLE_RFP_DATA_REQUESTED,
  GET_SINGLE_RFP_DATA_SUCCESS,
  SEND_RPF_FAILED,
  SEND_RPF_REQUESTED,
  SEND_RPF_SUCCESS,
  UPDATE_RFP_ADRESS,
  UPDATE_RPF_FAILED,
  UPDATE_RPF_REQUESTED,
  UPDATE_RPF_SUCCESS,
} from '../constants/rfp'
import { updateSaveStatusAction } from './confirmation'

const fetchRfpListRequested = () => ({
  type: FETCH_RFP_LIST_REQUESTED,
})
const fetchRfpListSuccess = (payload) => ({
  type: FETCH_RFP_LIST_SUCCESS,
  payload,
})
const fetchRfpListFailed = (payload) => ({
  type: FETCH_RFP_LIST_FAILED,
  payload,
})

export const getRfpListData =
  (sortValue = '') =>
  async (dispatch) => {
    dispatch(fetchRfpListRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.rfpList}${
        sortValue ? `?sort=${sortValue}` : ''
      }`,
      method: method.get,
      showToastMessage: false,
    })
    if (data) {
      dispatch(fetchRfpListSuccess(data))
      return
    }
    if (error) {
      dispatch(fetchRfpListFailed(error.response))
    }
  }

export const getRfpListSortBy = (sortValue) => async (dispatch) => {
  dispatch(fetchRfpListRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.rfpList}?sort=${sortValue}`,
    method: method.get,
    showToastMessage: false,
  })
  if (data) {
    dispatch(fetchRfpListSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchRfpListFailed(error.response.data))
  }
}

const createNewRfpRequested = () => ({
  type: CREATE_NEW_RFP_REQUESTED,
})
const createNewRfpSuccess = (payload) => ({
  type: CREATE_NEW_RFP_SUCCESS,
  payload,
})
const createNewRfpFailed = (payload) => ({
  type: CREATE_NEW_RFP_FAILED,
  payload,
})
const updateRfpAddress = (payload) => ({
  type: UPDATE_RFP_ADRESS,
  payload,
})
export const createNewRfp =
  (payload, navigate, values, { onSuccess = () => {} } = {}) =>
  async (dispatch, getState) => {
    dispatch(createNewRfpRequested())
    const { data, error } = await api({
      data: payload,
      method: payload?.id ? method.patch : method.post,
      endPoint: `${apiEndPoints.rfpList}`,
      showToastMessage: true,
      showErrorMessage: false,
      toastMessage: payload?.id
        ? 'RFP saved and updated successfully!'
        : 'New RFP created successfully!',
    })
    if (data) {
      onSuccess()
      if (payload?.id) {
        const { rfpListData } = getState().rfpData
        const updateRFPList = rfpListData?.map((item) => {
          if (equal(item.id, payload.id)) {
            return {
              ...item,
              ...values,
              projectName: data?.projectName,
              fullAddress: data?.fullAddress,
              link: data?.link,
              latitude: data?.latitude,
              longitude: data?.longitude,
            }
          }
          return item
        })

        dispatch(
          updateRfpAddress({
            rfpListData: updateRFPList,
            rfpProjectData: {
              ...values,
              projectName: data?.projectName,
              fullAddress: data?.fullAddress,
              link: data?.link,
              latitude: data?.latitude,
              longitude: data?.longitude,
            },
          }),
        )
      } else {
        dispatch(createNewRfpSuccess(data))
        navigate(`/rfp/${data?.id}/rfp-project`)
      }
      return
    }
    if (error) {
      dispatch(createNewRfpFailed(error.response.data))
    }
  }

// Delete rfp
const deleteRfpRequested = () => ({
  type: DELETE_RFP_REQUESTED,
})
const deleteRfpSuccess = (payload) => ({
  type: DELETE_RFP_SUCCESS,
  payload,
})
const deleteRfpFailed = (payload) => ({
  type: DELETE_RFP_FAILED,
  payload,
})

export const deleteRfpProject = (payload) => async (dispatch) => {
  dispatch(deleteRfpRequested())
  const { status, error } = await api({
    endPoint: apiEndPoints.rfpList,
    method: method.delete,
    data: payload,
    showToastMessage: true,
    toastMessage: 'RFP Deleted successfully!',
  })
  if (status) {
    dispatch(deleteRfpSuccess(payload.rfps))
    return
  }
  if (error) {
    dispatch(deleteRfpFailed(error.response.data))
  }
}

// Get single rfp project details
const getSingleRfpRequested = () => ({
  type: GET_SINGLE_RFP_DATA_REQUESTED,
})
const getSingleRfpSuccess = (payload) => ({
  type: GET_SINGLE_RFP_DATA_SUCCESS,
  payload,
})
const getSingleRfpFailed = (payload) => ({
  type: GET_SINGLE_RFP_DATA_FAILED,
  payload,
})
export const getSingleRfpData = (rfpId) => async (dispatch) => {
  dispatch(getSingleRfpRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.rfpList}?rfp=${rfpId}`,
    showToastMessage: false,
  })
  if (data) {
    dispatch(getSingleRfpSuccess(data))
    return
  }
  if (error) {
    dispatch(getSingleRfpFailed(error.response))
  }
}

// Get scope of services options
const getScopeOptsRequested = () => ({
  type: GET_SCOPE_SERVICE_OPTS_REQUESTED,
})
const getScopeOptsSuccess = (payload) => ({
  type: GET_SCOPE_SERVICE_OPTS_SUCCESS,
  payload,
})
const getScopeOptsFailed = (payload) => ({
  type: GET_SCOPE_SERVICE_OPTS_FAILED,
  payload,
})
export const getScopeServiceOpts = () => async (dispatch) => {
  dispatch(getScopeOptsRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: apiEndPoints.scopeServiceOpts,
    showToastMessage: false,
  })
  if (data) {
    dispatch(getScopeOptsSuccess(data))
    return
  }
  if (error) {
    dispatch(getScopeOptsFailed(error.response.data))
  }
}

// Get rfp company list
const getRfpCompanyRequested = () => ({
  type: GET_RFP_COMPANY_LIST_REQUESTED,
})
const getRfpCompanySuccess = (payload) => ({
  type: GET_RFP_COMPANY_LIST_SUCCESS,
  payload,
})
const getRfpCompanyFailed = (payload) => ({
  type: GET_RFP_COMPANY_LIST_FAILED,
  payload,
})
export const getRfpCompanyList = (queryParams) => async (dispatch) => {
  const queryString = new URLSearchParams(queryParams)
    .toString()
    .replace(/%28/g, '(')
    .replace(/%29/g, ')')
  dispatch(getRfpCompanyRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.rfpCompany}?${queryString}`,
    showToastMessage: false,
  })

  if (data) {
    dispatch(getRfpCompanySuccess(data))
    return
  }
  if (error) {
    dispatch(getRfpCompanyFailed(error?.response?.data))
  }
}

// Add or remove company from favorite
const addAndRemoveFavCompanyRequest = () => ({
  type: ADD_REMOVE_COMPANY_REQUESTED,
})
const addAndRemoveFavCompanySuccess = (payload) => ({
  type: ADD_REMOVE_COMPANY_SUCCESS,
  payload,
})
const addAndRemoveFavCompanyFailed = (payload) => ({
  type: ADD_REMOVE_COMPANY_FAILED,
  payload,
})

export const addAndRemoveFavoriteCompany =
  (payload) => async (dispatch, getState) => {
    dispatch(addAndRemoveFavCompanyRequest())
    const { error } = await api({
      method: method.post,
      endPoint: `${apiEndPoints.rfpFavoriteCompany}`,
      data: payload,
    })

    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(addAndRemoveFavCompanyFailed(error.response.data))
      }
      dispatch(addAndRemoveFavCompanyFailed(error.response.data))
    } else {
      const { rfpCompanyList } = getState().rfpData
      const updatedList = rfpCompanyList?.map((item) => {
        if (equal(+item.id, +payload.company_id)) {
          return {
            ...item,
            isFavorite: equal(payload?.action, 'add'),
          }
        }
        return item
      })
      dispatch(addAndRemoveFavCompanySuccess(updatedList))
    }
  }

// Update RPF data
const updateRFPRequested = () => ({
  type: UPDATE_RPF_REQUESTED,
})
export const updateRFPSuccess = (payload) => ({
  type: UPDATE_RPF_SUCCESS,
  payload,
})
const updateRFPFailed = (payload) => ({
  type: UPDATE_RPF_FAILED,
  payload,
})

export const updateRFP =
  (payload, onSucces, returnBid = false) =>
  async (dispatch, getState) => {
    dispatch(updateRFPRequested())
    const { data, error } = await api({
      method: method[returnBid ? 'post' : 'patch'],
      endPoint: `${apiEndPoints[ternary(returnBid, 'projectBids', 'rfpList')]}`,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      const { rfpProjectData } = getState().rfpData
      const payload = returnBid
        ? {
            rfpProjectData: {
              ...rfpProjectData,
              isBidsReceived: true,
            },
          }
        : {
            rfpProjectData: data,
          }
      dispatch(updateRFPSuccess(payload))
      onSucces()
      if (returnBid) {
        setTimeout(() => {
          showToast(
            "Select 'Return Bid to Client’ in order to complete the process.",
          )
        }, 2200)
      }
    }
    if (error) {
      dispatch(updateRFPFailed(error.response.data))
    }
  }

// Send RPF Notification
const sendRFPRequested = () => ({
  type: SEND_RPF_REQUESTED,
})
export const sendRFPSuccess = (payload) => ({
  type: SEND_RPF_SUCCESS,
  payload,
})
const sendRFPFailed = (payload) => ({
  type: SEND_RPF_FAILED,
  payload,
})

export const sendandReturnRFPNotification =
  (payload, onSucces, isReturn = false, handleOpenSuccessmodal = () => {}) =>
  async (dispatch) => {
    dispatch(sendRFPRequested())
    const { data, error } = await api({
      method: method.post,
      endPoint: `${
        apiEndPoints[ternary(isReturn, 'returnBid', 'sendRFPNotification')]
      }`,
      data: payload,
      showToastMessage: !isReturn,
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(sendRFPSuccess(data))
      onSucces()
      if (isReturn) handleOpenSuccessmodal()
    }
    if (error) {
      dispatch(sendRFPFailed(error.response.data))
    }
  }

// Get bids data
const getBidsRequested = () => ({
  type: BUIDS_REQUESTED,
})
const getBidsSuccess = (payload) => ({
  type: BUIDS_SUCCESS,
  payload,
})
const getBidsFailed = (payload) => ({
  type: BUIDS_FAILED,
  payload,
})
export const fetchBidsData =
  ({ rfpId }) =>
  async (dispatch) => {
    dispatch(getBidsRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.bids}?rfp=${rfpId}`,
      showToastMessage: false,
    })
    if (data) {
      dispatch(getBidsSuccess(data))
      return
    }
    if (error) {
      dispatch(getBidsFailed(error.response))
    }
  }
