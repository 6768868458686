import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, lt, ternary } from '../../Utils/javascript'
import {
  ADD_OWNER_CONTRACTOR,
  CREATE_AND_UPDATE_OWNER_CONTRACTOR_FAILED,
  CREATE_AND_UPDATE_OWNER_CONTRACTOR_REQUESTED,
  CREATE_AND_UPDATE_OWNER_CONTRACTOR_SUCCESS,
  DELETE_CONTRACTOR_FAILED,
  DELETE_CONTRACTOR_REQUESTED,
  DELETE_CONTRACTOR_SUCCESS,
  FETCH_OWNER_CONTRACTOR_FAILED,
  FETCH_OWNER_CONTRACTOR_REQUESTED,
  FETCH_OWNER_CONTRACTOR_SUCCESS,
  FETCH_SITE_BUILDING_MAPPING_FAILED,
  FETCH_SITE_BUILDING_MAPPING_REQUESTED,
  FETCH_SITE_BUILDING_MAPPING_SUCCESS,
  SEARCH_CONTRACTOR_FAILED,
  SEARCH_CONTRACTOR_REQUESTED,
  SEARCH_CONTRACTOR_SUCCESS,
} from '../constants/ownerContractor'
import { updateSaveStatusAction } from './confirmation'

const UNIQUE_CODE_PREFIX = 'OC'
const getUniqueCode = (code) =>
  `${UNIQUE_CODE_PREFIX}-${ternary(lt(code, 10), `0${code}`, code)}`

// Fetch owner contractor list
const fetchOwnerContractorRequested = () => ({
  type: FETCH_OWNER_CONTRACTOR_REQUESTED,
})
export const fetchOwnerContractorSuccess = (payload) => ({
  type: FETCH_OWNER_CONTRACTOR_SUCCESS,
  payload,
})
const fetchOwnerContractorFailed = (payload) => ({
  type: FETCH_OWNER_CONTRACTOR_FAILED,
  payload,
})

export const getOwnerContractor = (projectId) => async (dispatch) => {
  dispatch(fetchOwnerContractorRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.ownerContractor}?projectId=${projectId}`,
    method: method.get,
    showToastMessage: false,
    toastMessage: 'Owner Contractor Fetch successfully!',
  })
  if (data) {
    dispatch(fetchOwnerContractorSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchOwnerContractorFailed(error.response))
  }
}
// Create and update owner contractor
const createAndUpdateOwnerContractorRequested = () => ({
  type: CREATE_AND_UPDATE_OWNER_CONTRACTOR_REQUESTED,
})
const createAndUpdateOwnerContractorSuccess = (payload) => (dispatch) => {
  dispatch({
    type: CREATE_AND_UPDATE_OWNER_CONTRACTOR_SUCCESS,
    payload: [{ ...payload }],
    activeContractor: payload?.id,
  })
}
const createAndUpdateOwnerContractorFailed = (payload) => ({
  type: CREATE_AND_UPDATE_OWNER_CONTRACTOR_FAILED,
  payload,
})

export const createAndUpdateOwnerContractor =
  (payload, id) => async (dispatch) => {
    dispatch(createAndUpdateOwnerContractorRequested())
    const updatedPayload = { ...payload }
    delete updatedPayload.isNewContractor
    const { data, error, statusCode } = await api({
      endPoint: `${apiEndPoints.ownerContractor}${id}/?projectId=${payload.project}`,
      method: method.patch,
      data: updatedPayload,
      showToastMessage: true,
      toastMessage: 'Owner Contractor updated successfully!',
    })
    const cloneData = ternary(statusCode, null, data)
    setTimeout(() => {
      dispatch(updateSaveStatusAction(cloneData, error))
    }, 700)
    if (data) {
      dispatch(createAndUpdateOwnerContractorSuccess(data, id))
      return
    }
    if (error) {
      dispatch(createAndUpdateOwnerContractorFailed(error.response.data))
    }
  }

// Fetch site building mapping
const fetchSiteBuildingRequested = () => ({
  type: FETCH_SITE_BUILDING_MAPPING_REQUESTED,
})
export const fetchSiteBuildingSuccess = (payload) => ({
  type: FETCH_SITE_BUILDING_MAPPING_SUCCESS,
  payload,
})
const fetchSiteBuildingFailed = (payload) => ({
  type: FETCH_SITE_BUILDING_MAPPING_FAILED,
  payload,
})

export const getSiteBuildingMapping = (id, uniqueCode) => async (dispatch) => {
  dispatch(fetchSiteBuildingRequested())
  const { data, error } = await api({
    endPoint: ternary(
      uniqueCode,
      `${apiEndPoints.ownerContractor}mapping/${id}/?uniqueCode=${uniqueCode}`,
      `${apiEndPoints.ownerContractor}mapping/${id}/`,
    ),
    method: method.get,
    showToastMessage: false,
    toastMessage: 'Owner Contractor site building Fetch successfully!',
  })
  if (data) {
    const dataWithUpdatedKeys = data?.sort((a, b) =>
      a?.uniqueCode > b?.uniqueCode ? 1 : -1,
    )
    dispatch(fetchSiteBuildingSuccess(dataWithUpdatedKeys))
    return
  }
  if (error) {
    dispatch(fetchSiteBuildingFailed(error.response.data))
  }
}
// Add new owner contractor
export const addOwnerContractorSuccess = (payload) => ({
  type: ADD_OWNER_CONTRACTOR,
  payload,
})

export const addOwnerContractor = (id) => (dispatch, getState) => {
  const state = getState()
  const ownerContractorList = [...state.ownerContractor.ownerContractorList]
  const sortedContractorList = ownerContractorList?.sort((a, b) =>
    a?.uniqueCode > b?.uniqueCode ? 1 : -1,
  )
  const lastIndexContractor = sortedContractorList
    ?.slice(-1)[0]
    ?.uniqueCode?.split('-')[1]
  dispatch(
    addOwnerContractorSuccess([
      ...ownerContractorList,
      {
        id,
        isNewContractor: true,
        uniqueCode: getUniqueCode(
          ternary(lastIndexContractor, +lastIndexContractor + 1, '1'),
        ),
      },
    ]),
  )
}

// Search contractor
const searchContractorRequested = () => ({
  type: SEARCH_CONTRACTOR_REQUESTED,
})
const searchContractorSuccess = (payload) => ({
  type: SEARCH_CONTRACTOR_SUCCESS,
  payload,
})
const searchContractorFailed = (payload) => ({
  type: SEARCH_CONTRACTOR_FAILED,
  payload,
})

export const searchContractor =
  (searchQuery, isPopulate) => async (dispatch) => {
    if (isPopulate) {
      dispatch(searchContractorRequested())
    }
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.projectContactSearch}?fieldName=contractor${
        searchQuery ? `&query=${searchQuery}` : ''
      }`,
    })
    if (data) {
      const dataWithLabelValue = data.map((company) => ({
        label: company?.companyName,
        value: `${company?.id}`,
        city: company?.city,
        state: company?.state,
      }))
      dispatch(searchContractorSuccess(dataWithLabelValue))
    }
    if (error) {
      dispatch(searchContractorFailed(error.response))
    }
  }

// Delete contractor
const deleteOwnerContractorRequested = () => ({
  type: DELETE_CONTRACTOR_REQUESTED,
})
export const deleteOwnerContractorSuccess =
  (payload) => (dispatch, getState) => {
    const state = getState()
    const ownerContractorList = [...state.ownerContractor.ownerContractorList]
    const index = ownerContractorList?.findIndex(({ id }) =>
      equal(id, payload?.ownerContractor[0]),
    )
    if (~index) {
      ownerContractorList.splice(index, 1)
    }
    dispatch({
      type: DELETE_CONTRACTOR_SUCCESS,
      payload: ownerContractorList,
      activeContractor: ownerContractorList[0]?.id,
    })
  }
const deleteOwnerContractorFailed = (payload) => ({
  type: DELETE_CONTRACTOR_FAILED,
  payload,
})

export const deleteOwnerContractor =
  (payload, handleClose) => async (dispatch) => {
    dispatch(deleteOwnerContractorRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.deleteOwnerContractor}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Owner Contractor deleted successfully!',
    })
    if (data) {
      handleClose()
      dispatch(deleteOwnerContractorSuccess(payload))
      return
    }
    if (error) {
      if (equal(error?.response?.status, 400)) {
        handleClose()
      }
      dispatch(deleteOwnerContractorFailed(error.response.data))
    }
  }
