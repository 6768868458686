import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, length, lt, ternary } from '../../Utils/javascript'
import {
  FETCH_ENVIRONMENTAL_LIST_FAILED,
  FETCH_ENVIRONMENTAL_MAPPING_FAILED,
  FETCH_ENVIRONMENTAL_MAPPING_REQUESTED,
  FETCH_ENVIRONMENTAL_MAPPING_SUCCESS,
  FETCH_ENVIRONMENTAL_LIST_REQUESTED,
  FETCH_ENVIRONMENTAL_LIST_SUCCESS,
  SAVE_ENVIRONMENTAL_FAILED,
  SAVE_ENVIRONMENTAL_REQUESTED,
  SAVE_ENVIRONMENTAL_SUCCESS,
  FETCH_ENVIRONMENTAL_REQUESTED,
  FETCH_ENVIRONMENTAL_SUCCESS,
  FETCH_ENVIRONMENTAL_FAILED,
  CREATE_ENVIRONMENTAL_REQUESTED,
  CREATE_ENVIRONMENTAL_SUCCESS,
  CREATE_ENVIRONMENTAL_FAILED,
  ADD_ENVIRONMENTAL_SUCCESS,
  SET_ENVIRONMENTAL_ID,
  DELETE_ENVIRONMENTAL_REQUESTED,
  DELETE_ENVIRONMENTAL_SUCCESS,
  DELETE_ENVIRONMENTAL_FAILED,
} from '../constants/environmental'
import { updateSaveStatusAction } from './confirmation'

const UNIQUE_CODE_PREFIX = 'EN'
const getUniqueCode = (code) =>
  `${UNIQUE_CODE_PREFIX}-${ternary(lt(code, 10), `0${code}`, code)}`

const fetchEnvironmentalRequested = () => ({
  type: FETCH_ENVIRONMENTAL_REQUESTED,
})
const fetchEnvironmentalSuccess = (payload) => ({
  type: FETCH_ENVIRONMENTAL_SUCCESS,
  payload,
})
const fetchEnvironmentalFailed = (payload) => ({
  type: FETCH_ENVIRONMENTAL_FAILED,
  payload,
})

export const getEnvironmental = (environmentalId) => async (dispatch) => {
  dispatch(fetchEnvironmentalRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.environmental}${environmentalId}`,
  })
  if (data) {
    dispatch(fetchEnvironmentalSuccess(data))
  }
  if (error) {
    dispatch(fetchEnvironmentalFailed(error.response))
  }
}

const fetchEnvironmentalListRequested = () => ({
  type: FETCH_ENVIRONMENTAL_LIST_REQUESTED,
})
const fetchEnvironmentalListSuccess = (payload) => ({
  type: FETCH_ENVIRONMENTAL_LIST_SUCCESS,
  payload,
})
const fetchEnvironmentalListFailed = (payload) => ({
  type: FETCH_ENVIRONMENTAL_LIST_FAILED,
  payload,
})

export const getEnvironmentalList = (projectId) => async (dispatch) => {
  dispatch(fetchEnvironmentalListRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.environmental}?projectId=${projectId}`,
  })
  if (data) {
    const modifiedData = data?.map(({ id, uniqueCode }) => ({ id, uniqueCode }))
    dispatch(fetchEnvironmentalListSuccess(modifiedData))
  }
  if (error) {
    dispatch(fetchEnvironmentalListFailed(error.response))
  }
}

const createEnvironmentalRequested = () => ({
  type: CREATE_ENVIRONMENTAL_REQUESTED,
})
const createEnvironmentalSuccess = (payload) => ({
  type: CREATE_ENVIRONMENTAL_SUCCESS,
  payload,
})
const createEnvironmentalFailed = (payload) => ({
  type: CREATE_ENVIRONMENTAL_FAILED,
  payload,
})

export const createEnvironmental =
  (payload, project) => async (dispatch, getState) => {
    const { environmentals, environmentalId } = getState().environmental
    dispatch(createEnvironmentalRequested())
    const { uniqueCode } =
      environmentals?.find((environmental) =>
        equal(environmental.id, +environmentalId),
      ) || {}
    const { data, error } = await api({
      method: method.post,
      endPoint: `${apiEndPoints.environmental}?projectId=${project}`,
      data: {
        ...payload,
        project,
        uniqueCode:
          uniqueCode ||
          getUniqueCode(ternary(environmentals, length(environmentals) + 1, 1)),
      },
      showToastMessage: true,
      toastMessage: 'Environmental created successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        createEnvironmentalSuccess({
          environmentals: environmentals?.map((environmental) =>
            ternary(
              equal(environmental.uniqueCode, uniqueCode),
              data,
              environmental,
            ),
          ),
          environmental: data,
        }),
      )
      dispatch(setEnvironmentalId(data.id, false))
      dispatch(getEnvironmentalMapping(project))
    }
    if (error) {
      dispatch(createEnvironmentalFailed(error.response.data))
    }
  }

const saveEnvironmentalRequested = () => ({
  type: SAVE_ENVIRONMENTAL_REQUESTED,
})
const saveEnvironmentalSuccess = (payload) => ({
  type: SAVE_ENVIRONMENTAL_SUCCESS,
  payload,
})
const saveEnvironmentalFailed = (payload) => ({
  type: SAVE_ENVIRONMENTAL_FAILED,
  payload,
})

export const saveEnvironmental =
  (payload, environmentalId, project) => async (dispatch) => {
    dispatch(saveEnvironmentalRequested())
    const { data, error } = await api({
      method: method.patch,
      endPoint: `${apiEndPoints.environmental}${environmentalId}/?projectId=${project}`,
      data: {
        ...payload,
        project,
      },
      showToastMessage: true,
      toastMessage: 'Environmental updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(getEnvironmentalMapping(project))
      dispatch(saveEnvironmentalSuccess(data))
    }
    if (error) {
      dispatch(saveEnvironmentalFailed(error.response.data))
    }
  }

const fetchEnvironmentalMappingRequested = () => ({
  type: FETCH_ENVIRONMENTAL_MAPPING_REQUESTED,
})
const fetchEnvironmentalMappingSuccess = (payload) => ({
  type: FETCH_ENVIRONMENTAL_MAPPING_SUCCESS,
  payload,
})
const fetchEnvironmentalMappingFailed = (payload) => ({
  type: FETCH_ENVIRONMENTAL_MAPPING_FAILED,
  payload,
})

export const getEnvironmentalMapping =
  (projectId) => async (dispatch, getState) => {
    const { environmental, isNewEnvironmental } = getState().environmental
    dispatch(fetchEnvironmentalMappingRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.environmental}mapping/${projectId}/${ternary(
        environmental?.uniqueCode && !isNewEnvironmental,
        `?uniqueCode=${environmental?.uniqueCode}`,
        '',
      )}`,
    })
    if (data) {
      const dataWithUpdatedKeys = data
        ?.sort((a, b) => (a?.uniqueCode > b?.uniqueCode ? 1 : -1))
        ?.map(({ uniqueCode, id } = {}) => ({
          label: uniqueCode,
          value: id.toString(),
        }))
      dispatch(fetchEnvironmentalMappingSuccess(dataWithUpdatedKeys))
    }
    if (error) {
      dispatch(fetchEnvironmentalMappingFailed(error.response.data))
    }
  }

const addEnvironmentalSuccess = (payload) => ({
  type: ADD_ENVIRONMENTAL_SUCCESS,
  payload,
})

export const addEnvironmental = () => (dispatch, getState) => {
  const { environmentals } = getState().environmental
  const id = new Date().getTime()

  const sortedEnvironmentalList = environmentals?.sort((a, b) =>
    a?.uniqueCode > b?.uniqueCode ? 1 : -1,
  )
  const lastIndexContractor = sortedEnvironmentalList
    ?.slice(-1)[0]
    ?.uniqueCode?.split('-')[1]
  dispatch(
    addEnvironmentalSuccess([
      ...(environmentals || []),
      {
        id,
        isNewEnvironmental: true,
        uniqueCode: getUniqueCode(
          ternary(lastIndexContractor, +lastIndexContractor + 1, '1'),
        ),
      },
    ]),
  )
  dispatch(setEnvironmentalId(id, true))
}

export const setEnvironmentalId = (environmentalId, isNewEnvironmental) => ({
  type: SET_ENVIRONMENTAL_ID,
  payload: { environmentalId, isNewEnvironmental },
})

// Delete contractor
const deleteEnvironmentalRequested = () => ({
  type: DELETE_ENVIRONMENTAL_REQUESTED,
})
export const deleteEnvironmentalSuccess = (payload) => (dispatch, getState) => {
  const { environmentals } = getState().environmental
  const index = environmentals?.findIndex(({ id }) =>
    equal(id, payload?.environmental[0]),
  )
  if (~index) {
    environmentals.splice(index, 1)
  }
  dispatch({
    type: DELETE_ENVIRONMENTAL_SUCCESS,
    payload: environmentals,
    activeEnvironmental: environmentals[0]?.id,
    isNewEnvironmental: environmentals[0]?.isNewEnvironmental,
  })
}
const deleteEnvironmentalFailed = (payload) => ({
  type: DELETE_ENVIRONMENTAL_FAILED,
  payload,
})

export const deleteEnvironmental =
  (payload, handleClose) => async (dispatch) => {
    dispatch(deleteEnvironmentalRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.deleteEnvironmental}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Environmental deleted successfully!',
    })
    if (data) {
      handleClose()
      dispatch(deleteEnvironmentalSuccess(payload))
      return
    }
    if (error) {
      dispatch(deleteEnvironmentalFailed(error.response.data))
    }
  }
