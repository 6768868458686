import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DKTAccordion from '../../../Shared/DKTAccordion'
import CompanyInformation from './CompanyInformation'
import { accordionData } from '../../../Description/companyInformation.description'
import { InnerLayout } from '../../../Layout/admin'
import { actionTitles } from '../../../Description/admin.description'
import KeyPersonnel from './KeyPersonnel'

const useStyles = makeStyles(() => ({
  compInfoOuter: {
    '& .MuiAccordion-root': {
      marginBottom: 5,
      '& .MuiAccordionSummary-content': {
        margin: [[20, 0]],
      },
      '&.Mui-expanded': {
        '& .MuiAccordionSummary-root': {
          backgroundColor: '#1564FF',
          color: '#fff',
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: '#fff',
          },
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: 16,
      },
    },
  },
  compInfoAccordion: {
    '&.MuiAccordionSummary-root': {
      backgroundColor: '#C6C6C6',
      color: 'black',
      fontSize: '14px',
    },
  },
}))

const CompanyInformationWrapper = () => {
  const classes = useStyles()
  return (
    <InnerLayout
      contentTitle="Company Information"
      actionTitle={actionTitles.companyInformation}
      maxWidth={false}
    >
      <Box p={1}>
        <div className={classes.compInfoOuter}>
          <DKTAccordion
            className={classes.compInfoAccordion}
            accordionTitle={accordionData.companyInformation.accordionTitle}
            idTag={accordionData.companyInformation.idTag}
          >
            <CompanyInformation />
          </DKTAccordion>
          <DKTAccordion
            className={classes.compInfoAccordion}
            accordionTitle={accordionData.keyPersonnel.accordionTitle}
            idTag={accordionData.keyPersonnel.idTag}
          >
            <KeyPersonnel />
          </DKTAccordion>
        </div>
      </Box>
    </InnerLayout>
  )
}

export default CompanyInformationWrapper
