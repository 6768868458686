// Fetch site address list
export const FETCH_SITE_ADDRESS_LIST_REQUESTED =
  'FETCH_SITE_ADDRESS_LIST_REQUESTED'
export const FETCH_SITE_ADDRESS_LIST_SUCCESS = 'FETCH_SITE_ADDRESS_LIST_SUCCESS'
export const FETCH_SITE_ADDRESS_LIST_FAILED = 'FETCH_SITE_ADDRESS_LIST_FAILED'

// Create site address
export const CREATE_SITE_ADDRESS_REQUESTED = 'CREATE_SITE_ADDRESS_REQUESTED'
export const CREATE_SITE_ADDRESS_SUCCESS = 'CREATE_SITE_ADDRESS_SUCCESS'
export const CREATE_SITE_ADDRESS_FAILED = 'CREATE_SITE_ADDRESS_FAILED'

// Delete site address
export const DELETE_SITE_ADDRESS_REQUESTED = 'DELETE_SITE_ADDRESS_REQUESTED'
export const DELETE_SITE_ADDRESS_SUCCESS = 'DELETE_SITE_ADDRESS_SUCCESS'
export const DELETE_SITE_ADDRESS_FAILED = 'DELETE_SITE_ADDRESS_FAILED'

// clear site address data
export const CLEAR_SITE_ADDRESS = 'CLEAR_SITE_ADDRESS'
