import { Box, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { InnerLayout } from '../../Layout/project'
import {
  deleteReportAttachmentPdf,
  getProjectPdfList,
  updateProjectAttachmentsPdfList,
  uploadPdfInAttachments,
} from '../../Redux/actions/attachments'
import DKTButton from '../../Shared/DKTButton'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import { useStyles } from '../../Styles/attachments.style'
import { equal, handleBlurAll } from '../../Utils/javascript'
import Attachments from '../reportMonitoring/attachments/Attachments'

const ProjectAttachments = () => {
  const [listPdf, setListPdf] = useState([])
  const [uploadPdf, setUploadPdf] = useState([])
  const [edit, setEdit] = useState()
  const [isDirty, setIsDirty] = useState(false)
  const [dragDataDirty, setDragDataDirty] = useState(false)

  const classes = useStyles()
  const dispatch = useDispatch()
  const { slug: projectId } = useParams()
  const { projectPdfList, isUpdatePdfListLoading, isFetchPdfListLoading } =
    useSelector(({ attachments }) => attachments)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)
  useEffect(() => {
    dispatch(
      getProjectPdfList({
        projectId,
      }),
    )
  }, [])

  useEffect(() => {
    setListPdf(projectPdfList?.map((pdf) => ({ ...pdf, isDraggable: true })))
  }, [projectPdfList])

  useEffect(() => {
    const res = listPdf?.map((pdf) => {
      const oldData = projectPdfList?.find((item) => equal(item.id, pdf.id))
      return !equal(pdf.filename, oldData.filename)
    })
    if (res?.flat().length) {
      setIsDirty(res?.flat()?.some((data) => data === true))
    }
  }, [listPdf])

  const handleUploadPdf = () => {
    const formData = new FormData()

    uploadPdf.forEach((pdfFile) => {
      formData.append('attachment_file', pdfFile)
    })
    dispatch(
      uploadPdfInAttachments({
        payload: formData,
        projectId,
        setCancelTokenSource,
        setUploadPdf,
      }),
    )
  }
  const handleUpdate = () => {
    setIsDirty(false)
    setDragDataDirty(false)
    dispatch(
      updateProjectAttachmentsPdfList({
        payload: listPdf,
        projectId,
      }),
    )
  }

  const handleDeletePdf = (selected, setSelected) => {
    dispatch(
      deleteReportAttachmentPdf({
        payload: {
          project_attachment: selected,
        },
        projectId,
      }),
    ).then(() => {
      dispatch(
        getProjectPdfList({
          projectId,
          isLoading: false,
        }),
      )
      setSelected([])
    })
  }

  const actions = () => (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Stack direction="row" gap={2} className={classes.rightButton}>
        <DKTButton
          onMouseOver={handleBlurAll}
          className={classes.rightButton}
          onClick={handleUpdate}
          disabled={
            !listPdf?.length || isUpdatePdfListLoading || isFetchPdfListLoading
          }
        >
          {isUpdatePdfListLoading ? 'Saving...' : 'Save'}
        </DKTButton>
      </Stack>
    </Box>
  )

  return (
    <InnerLayout contentTitle="Attachments" actions={actions} maxWidth="md">
      <Attachments
        {...{
          listPdf,
          setListPdf,
          edit,
          setEdit,
          handleDeletePdf,
          uploadPdf,
          setUploadPdf,
          setDragDataDirty,
          handleUploadPdf,
          cancelTokenSource,
        }}
      />
      <DKTReactRouterPrompt
        isDirty={isDirty || dragDataDirty}
        onSave={handleUpdate}
      />
    </InnerLayout>
  )
}

export default ProjectAttachments
