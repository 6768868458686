import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { memo } from 'react'

import { ExpandIcon } from '../Assets/SVGs'
import { checkIncludes, equal, ternary } from '../Utils/javascript'

const StyledSelect = styled(Select)(({ theme }) => ({
  height: '32px',
  borderRadius: 0,
  width: '100%',
  marginTop: theme.spacing(3),
}))

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: ({ placeholderColor }) => placeholderColor || '#ACACAC',
  },
}))

const Placeholder = ({ children, placeholderColor }) => {
  const classes = usePlaceholderStyles({ placeholderColor })
  return <div className={`${classes.placeholder} `}>{children}</div>
}

const useStyles = makeStyles(() => ({
  inlineForm: {
    '& .MuiInputLabel-root': {
      position: 'static',
      transform: 'translate(0, 0) scale(0.75)',
    },
    '& .MuiOutlinedInput-root': {
      marginTop: 0,
    },
  },
}))

const DKTSelect = ({
  id,
  options,
  label,
  value,
  error,
  isRequired,
  disabled,
  showLabel = true,
  placeholder = '',
  formControlProps: { sx, ...formControlProps } = {},
  disableEmpty,
  selectSx = {},
  expandIconColor = '#333333',
  renderValue = (value) => value,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <FormControl
      variant="standard"
      sx={{ width: '100%', ...sx }}
      disabled={disabled}
      required={isRequired}
      {...formControlProps}
    >
      <Grid
        container
        alignItems="center"
        className={rest?.inlineForm ? classes.inlineForm : undefined}
      >
        <Grid item md={rest?.inlineForm ? 6 : 12}>
          {showLabel && (
            <InputLabel
              shrink
              htmlFor={id}
              sx={{ fontWeight: 'medium', color: 'gray.extraDark' }}
            >
              {label}
            </InputLabel>
          )}
        </Grid>
        <Grid item md={rest?.inlineForm ? 6 : 12}>
          <StyledSelect
            id={id}
            value={ternary(rest.multiple && !value, [], value)}
            variant="outlined"
            IconComponent={() => null}
            endAdornment={<ExpandIcon color={expandIconColor} />}
            renderValue={(selected) =>
              ternary(
                equal(value, 'N/A'),
                'N/A',
                ternary(
                  value?.length,
                  rest.multiple
                    ? options
                        ?.filter((e) => checkIncludes(e.value, selected))
                        ?.map((option) => option.label)
                        ?.join(', ')
                    : renderValue(
                        options?.find((e) => e.value === value)?.label,
                      ),
                  <Placeholder placeholderColor={rest.placeholderColor}>
                    {placeholder}
                  </Placeholder>,
                ),
              )
            }
            MenuProps={{
              disableScrollLock: true,
            }}
            {...{ ...rest, sx: { ...rest.sx, ...selectSx } }}
          >
            {options?.map((option, index) => (
              <MenuItem
                key={index}
                disabled={option?.disabled}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </Grid>
      </Grid>
      <FormHelperText error={!!error}>{error}</FormHelperText>
    </FormControl>
  )
}

export default memo(DKTSelect)
