import { Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  initialPermitTracker,
  permitTrackerColumns,
  permitTrackerOptions,
} from '../../../Description/permitsEntitlements.description'
import {
  deleteSavePermitTracker,
  getPermitsTracker,
  savePermitTracker,
} from '../../../Redux/actions/permitsEntitlements'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTForm from '../../../Shared/DKTForm'
import DKTInput from '../../../Shared/DKTInput'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { useStyles } from '../../../Styles/permitsEntitlements.style'
import { equal, keys, ternary } from '../../../Utils/javascript'
import { checkIfValidDate } from '../../../Utils/regex'

const PermitTracker = ({
  open,
  onClose,
  ownerContractor,
  reportId,
  projectId,
  uniqueCode,
  hasOnlyViewPermission,
}) => {
  const [data, setData] = useState([
    {
      ...initialPermitTracker,
      report: reportId,
      ownerContractor,
      uniqueCode,
    },
  ])
  const [errors, setErrors] = useState({})
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)

  const recordsToRemove = useRef([])
  const setSelectedRows = useRef(() => {})
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()

  const {
    isFetchingPermitTracker,
    permitTrackerData,
    isSavePermitTracker,
    isDeletePermitTracker,
  } = useSelector(({ permitsEntitlements }) => permitsEntitlements)

  useEffect(() => {
    if (reportId && ownerContractor && projectId) {
      dispatch(getPermitsTracker(reportId, ownerContractor, projectId))
    }
  }, [dispatch, reportId, ownerContractor, projectId])

  useEffect(() => {
    setData(
      permitTrackerData?.length
        ? permitTrackerData
        : [
            {
              ...initialPermitTracker,
              report: reportId,
              ownerContractor,
              uniqueCode,
            },
          ],
    )
  }, [permitTrackerData, ownerContractor])

  const handleOpenCloseDeleteNotification = (status) =>
    setIsDeleteNotificationOpen(status)

  const handleChange = useCallback((row, columnName, value, type) => {
    setIsUnSavedChanges(true)
    setData((prevData) => {
      const clonedData = [...prevData]
      if (clonedData[row]) {
        clonedData[row][columnName] = value
        if (equal(type, 'date') && value) {
          if (!checkIfValidDate(value)) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [row]: {
                ...prevErrors[row],
                ...{ [columnName]: 'Invalid date' },
              },
            }))
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [row]: {
                ...prevErrors[row],
                ...{ [columnName]: '' },
              },
            }))
          }
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [row]: {
              ...prevErrors[row],
              ...{ [columnName]: '' },
            },
          }))
        }
      }
      return clonedData
    })
  }, [])

  const addRow = () => {
    setData((prevData) => [
      ...prevData,
      {
        ...initialPermitTracker,
        report: reportId,
        ownerContractor,
        uniqueCode,
        uniqueId: new Date().valueOf(), // To delete local row
      },
    ])
  }

  const handleSave = () => {
    const payload = data.map((item) => ({
      ...item,
      issued: equal(item?.issued, '') ? null : item.issued,
      expiration: equal(item?.expiration, '') ? null : item.expiration,
    }))
    setIsUnSavedChanges(false)
    dispatch(savePermitTracker(payload, reportId, ownerContractor, projectId))
  }

  const handleDelete = () => {
    dispatch(
      deleteSavePermitTracker({
        permitTrackerToDelete: recordsToRemove.current,
        reportId,
        projectId,
        ocId: ownerContractor,
        setData,
      }),
    ).then(() => {
      setSelectedRows.current([])
      handleOpenCloseDeleteNotification()
    })
  }

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }

  const handleClosePermitTracker = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }

  const customBodyRender = (columnName, readOnly, value, tableData) => {
    const { rowIndex } = tableData
    return (
      <DKTForm autoComplete="off">
        <DKTInput
          className={`${classes.inputBorder} ${classes.dateError}`}
          type={ternary(
            equal(columnName, 'issued') || equal(columnName, 'expiration'),
            'date',
            'text',
          )}
          value={value}
          disabled={hasOnlyViewPermission}
          onChange={(e) =>
            handleChange(
              rowIndex,
              columnName,
              e.target.value,
              ternary(
                equal(columnName, 'issued') || equal(columnName, 'expiration'),
                'date',
                'text',
              ),
            )
          }
          placeholder="Enter text"
          showLabel={false}
          dateSx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
          }}
          error={
            equal(columnName, 'issued') || equal(columnName, 'expiration')
              ? errors[rowIndex]?.[columnName]
              : ''
          }
        />
      </DKTForm>
    )
  }

  const customToolbarSelect = (
    selectedRows,
    displayData,
    setSelectedTableRows,
  ) => {
    const selectedRecords = keys(selectedRows.lookup)
    const selectedRecordIds = selectedRecords.map(
      (rowToDelete) => data[rowToDelete]?.id ?? data[rowToDelete]?.uniqueId,
    )
    setSelectedRows.current = setSelectedTableRows
    const setRecordsToRemove = () => {
      recordsToRemove.current = selectedRecordIds
    }
    return (
      <Box>
        <IconButton
          onClick={() => {
            setRecordsToRemove()
            handleOpenCloseDeleteNotification(true)
          }}
        >
          <Delete color="error" />
        </IconButton>
      </Box>
    )
  }

  const options = {
    ...permitTrackerOptions,
    customToolbarSelect,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    selectableRows: ternary(hasOnlyViewPermission, 'none', 'multiple'),
    textLabels: {
      body: {
        noMatch: isFetchingPermitTracker ? (
          <DKTCircularProgress />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
  }

  const columns = permitTrackerColumns.map((column) => ({
    ...column,
    options: {
      ...column.options,
      customBodyRender: (...props) =>
        customBodyRender(column.name, false, ...props),
    },
  }))

  const deleteNotificationActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => handleOpenCloseDeleteNotification(false)}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleDelete}
        disabled={isDeletePermitTracker}
      >
        {ternary(
          isDeletePermitTracker,
          <DKTCircularProgress color={theme.palette.gray.dark} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  return (
    <DKTDialog
      title="Permit Tracker"
      open={open}
      showCloseButton
      handleClose={handleClosePermitTracker}
      maxWidth="xl"
      className={classes.permitTrackerModal}
    >
      {equal(isFetchingPermitTracker, true) ? (
        <Stack alignItems="center" justifyContent="center">
          <DKTCircularProgress />
        </Stack>
      ) : equal(isFetchingPermitTracker, 'FAILED') ? (
        <Stack alignItems="center" justifyContent="center">
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Permit Tracker data. Please
            try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <DKTMuiDataTable
            columns={columns}
            options={options}
            data={data || [{}]}
            className={ternary(
              data?.length,
              classes.permitTrackerTable,
              classNames(
                classes.permitTrackerTable,
                classes.permitTrackerTableNoData,
              ),
            )}
          />
          <DKTButton
            variant="text"
            startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
            onClick={addRow}
            disabled={hasOnlyViewPermission}
          >
            Add row
          </DKTButton>
          <Box textAlign="right">
            <DKTButton
              onClick={handleSave}
              disabled={
                hasOnlyViewPermission ||
                isSavePermitTracker ||
                Object.values(errors)
                  ?.map((el) => Object.values(el)?.filter(Boolean))
                  ?.flat()?.length
              }
            >
              {ternary(
                isSavePermitTracker,
                <DKTCircularProgress color={theme.palette.gray.dark} />,
                ternary(false, 'Saving...', 'Save'),
              )}
            </DKTButton>
          </Box>
        </>
      )}
      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={() => handleOpenCloseDeleteNotification(false)}
        title="&nbsp;"
        actions={deleteNotificationActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      {!hasOnlyViewPermission && (
        <>
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={handleSave}
          />
          <DKTReactRouterPrompt
            isDirty={isUnSavedChanges}
            onSave={handleSave}
          />
        </>
      )}
    </DKTDialog>
  )
}

export default PermitTracker
