import './App.css'

import { ThemeProvider } from '@mui/material/styles'
import React, { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import Routes from './Routes'
import theme from './Theme'
import { getAuthorization } from './Redux/actions/auth'

function App() {
  const dispatch = useDispatch()
  const { accessToken } = useSelector(({ auth }) => auth)
  useEffect(() => {
    if (accessToken) {
      dispatch(getAuthorization())
    }
  }, [dispatch, accessToken])
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html lang="en" />
        <title>AcreIQ</title>
      </Helmet>
      <Toaster />
      <Routes />
    </ThemeProvider>
  )
}
export default App
