import { memo, useCallback } from 'react'
import { Box, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DKTEditor from '../../../Shared/DKTEditor'
import DKTGrid from '../../../Shared/DKTGrid'
import DKTStack from '../../../Shared/DKTStack'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTTooltip from '../../../Shared/DKTTooltip'
import DKTTypography from '../../../Shared/DKTTypography'
import DKTButton from '../../../Shared/DKTButton'
import { ternary } from '../../../Utils/javascript'
import { importButtonTooltip } from '../../../Utils/constant'
import { importContingencyFromLastReport } from '../../../Redux/actions/contingency'
import { useStyles } from '../../../Styles/contingency.style'

const CommentsSection = ({
  title,
  valuesKey,
  onChangeHandler,
  disabled,
  values,
  showImportWarning,
  importFieldName,
  ownerContractorId,
  setFieldValue,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { slug: projectId, reportId } = useParams()
  const classes = useStyles()
  const { isImporting } = useSelector(({ contingency }) => contingency)

  const handleImport = useCallback(
    (name) => {
      importFieldName.current = null
      if (values[name]) {
        importFieldName.current = name
        showImportWarning()
        return
      }
      dispatch(
        importContingencyFromLastReport({
          query: name,
          projectId,
          ocId: ownerContractorId,
          onSuccess: (value) => setFieldValue(name, value),
          reportId,
        }),
      )
    },
    [projectId, ownerContractorId, dispatch, values],
  )

  return (
    <DKTGrid item xs={12}>
      <DKTStack
        direction="row"
        alignItems="end"
        justifyContent="space-between"
        mb={1}
      >
        <DKTTypography
          variant="body2"
          color="gray.extraDark"
          sx={{ fontWeight: 'medium' }}
        >
          {title}
        </DKTTypography>
        <DKTTooltip title={importButtonTooltip}>
          <Box>
            <DKTButton
              variant="outlined"
              onClick={() => handleImport(valuesKey)}
              sx={{
                borderColor: '#ACACAC',
                color: '#2D2D2D',
                width: '91px',
                height: '32px',
                '&:hover': { borderColor: '#000' },
              }}
              disabled={disabled || isImporting[valuesKey]}
            >
              {ternary(
                isImporting[valuesKey],
                <DKTCircularProgress color={theme.palette.gray.dark} />,
                'Import',
              )}
            </DKTButton>
          </Box>
        </DKTTooltip>
      </DKTStack>
      <DKTEditor
        value={values?.[valuesKey] || ''}
        onChange={(event, editor) =>
          onChangeHandler(editor, valuesKey, setFieldValue)
        }
        disabled={disabled}
        className={classes.projectDesc}
      />
    </DKTGrid>
  )
}

export default memo(CommentsSection)
