export const FETCH_PROJECT_LIMITATION_REQUESTED =
  'FETCH_PROJECT_LIMITATION_REQUESTED'
export const FETCH_PROJECT_LIMITATION_SUCCESS =
  'FETCH_PROJECT_LIMITATION_SUCCESS'
export const FETCH_PROJECT_LIMITATION_FAILED = 'FETCH_PROJECT_LIMITATION_FAILED'

export const SAVE_PROJECT_LIMITATIONS_REQUESTED =
  'SAVE_PROJECT_LIMITATIONS_REQUESTED'
export const SAVE_PROJECT_LIMITATIONS_SUCCESS =
  'SAVE_PROJECT_LIMITATIONS_SUCCESS'
export const SAVE_PROJECT_LIMITATIONS_FAILED = 'SAVE_PROJECT_LIMITATIONS_FAILED'
