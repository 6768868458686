import { zipCodeValidation } from '../Utils/regex'

export const optionFields = ['ownerContractor']
export const optionChoices = [
  'buildingPermitIsActive',
  'violationsThisPeriod',
  'stopWorkOrderThisPeriod',
  'temporaryCertificateOfOccupancyReceived',
  'certificateOfOccupancyReceived',
]
export const fieldsToInherit = [
  'buildingPermitIsActive',
  'permitDate',
  'permitExpiration',
  'permitNumber',
]

export const addressFields = ['address', 'city', 'state', 'zipCode']

export const formFields = {
  permitsAndViolationsFirstRow: {
    buildingPermitIsActive: {
      label: 'Building Permit is Active',
      type: 'select',
      placeholder: 'Select',
      displayEmpty: true,
      // isRequired: true,
      xs: 6,
      isDynamicOptions: true,
    },
    permitDate: {
      label: 'Permit Issued',
      type: 'date',
      xs: 6,
    },
    permitNumber: {
      label: 'Permit Number',
      type: 'text',
      xs: 6,
    },
    permitExpiration: {
      label: 'Permit Expiration',
      type: 'date',
      xs: 6,
    },
    violationsThisPeriod: {
      label: 'Violations This Period',
      type: 'select',
      placeholder: 'Select',
      displayEmpty: true,
      // isRequired: true,
      xs: 6,
      isDynamicOptions: true,
    },
    stopWorkOrderThisPeriod: {
      label: 'Stop Work Order This Period',
      // isRequired: true,
      type: 'select',
      placeholder: 'Select',
      displayEmpty: true,
      xs: 6,
      isDynamicOptions: true,
    },
  },
  permitsAndViolationsComments: {
    permitAndViolationComments: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 6,
      xs: 12,
    },
  },
  certificateOfOccupancy: {
    temporaryCertificateOfOccupancyReceived: {
      type: 'select',
      placeholder: 'Select',
      displayEmpty: true,
      label: 'Temporary Certificate Of Occupancy (TCO) Received',
      // isRequired: true,
      xs: 12,
      isDynamicOptions: true,
    },
    tcoDate: {
      label: 'TCO Issued',
      type: 'date',
      xs: 6,
    },
    tcoExpiration: {
      label: 'TCO Expiration',
      type: 'date',
      xs: 6,
    },
    certificateOfOccupancyReceived: {
      label: 'Certificate of Occupancy Received',
      type: 'select',
      placeholder: 'Select',
      displayEmpty: true,
      // isRequired: true,
      xs: 6,
      isDynamicOptions: true,
    },
    certificateOfOccupancyIssuedDate: {
      label: 'Issued Date',
      type: 'date',
      xs: 6,
    },
  },
  certificateOfOccupancyComments: {
    certificateOfOccupancyComments: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 6,
      xs: 12,
    },
  },
}

// CoO tracker
export const cooColumns = [
  {
    name: 'address',
    label: 'Address',
  },
  {
    name: 'buildingNameOrNumber',
    label: 'Bldg. ID',
  },
  {
    name: 'permitNumber',
    label: 'Permit No.',
  },
  {
    name: 'tcoDate',
    label: 'Temp. Cert. of Occupancy',
  },
  {
    name: 'certificateOfOccupancyIssuedDate',
    label: 'Cert. of Occupancy',
  },
]
export const cooTrackerOptions = {
  sort: false,
  filter: false,
  print: false,
  download: false,
  viewColumns: false,
  search: false,
  selectableRows: 'none',
}
export const cooFormFields = {
  address: {
    type: 'text',
    label: 'Address',
    xs: 12,
    isRequired: true,
  },
  city: {
    type: 'text',
    label: 'City',
    xs: 12,
  },
  state: {
    type: 'select',
    placeholder: 'Select',
    displayEmpty: true,
    label: 'State',
    xs: 6,
  },
  zipCode: {
    type: 'text',
    label: 'Zip Code',
    xs: 6,
    validate: [zipCodeValidation],
  },
  buildingNameOrNumber: {
    type: 'text',
    label: 'Bldg. ID (example A, B, C, 1, 2, 3)',
    xs: 12,
  },
  permitNumber: {
    type: 'text',
    label: 'Permit No.',
    xs: 12,
  },
  tcoDate: {
    type: 'date',
    label: 'Temp. Certificate Of Occupancy Date',
    xs: 12,
  },
  certificateOfOccupancyIssuedDate: {
    type: 'date',
    label: 'Certificate of Occupancy Date',
    xs: 12,
  },
}

export const permitTrackerOptions = {
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}

// Permit Tracker
export const permitTrackerColumns = [
  {
    name: 'description',
    label: 'Description',
    options: {
      sort: false,
    },
  },
  {
    name: 'permitNumber',
    label: 'Permit Number',
    options: {
      sort: false,
    },
  },
  {
    name: 'issuer',
    label: 'Issuer',
    options: {
      sort: false,
    },
  },
  {
    name: 'issued',
    label: 'Issued',
    options: {
      sort: false,
    },
  },
  {
    name: 'expiration',
    label: 'Expiration',
    options: {
      sort: false,
    },
  },
]

export const initialPermitTracker = {
  description: '',
  permitNumber: '',
  issuer: '',
  issued: null,
  expiration: null,
}
