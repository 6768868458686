import { equal, ternary } from '../../Utils/javascript'
import {
  ADD_REMOVE_COMPANY_FAILED,
  ADD_REMOVE_COMPANY_REQUESTED,
  ADD_REMOVE_COMPANY_SUCCESS,
  BUIDS_FAILED,
  BUIDS_REQUESTED,
  BUIDS_SUCCESS,
  CLEAR_RFP_DATA,
  CREATE_NEW_RFP_FAILED,
  CREATE_NEW_RFP_REQUESTED,
  CREATE_NEW_RFP_SUCCESS,
  DELETE_RFP_FAILED,
  DELETE_RFP_REQUESTED,
  DELETE_RFP_SUCCESS,
  FETCH_RFP_LIST_FAILED,
  FETCH_RFP_LIST_REQUESTED,
  FETCH_RFP_LIST_SUCCESS,
  GET_RFP_COMPANY_LIST_FAILED,
  GET_RFP_COMPANY_LIST_REQUESTED,
  GET_RFP_COMPANY_LIST_SUCCESS,
  GET_SCOPE_SERVICE_OPTS_FAILED,
  GET_SCOPE_SERVICE_OPTS_REQUESTED,
  GET_SCOPE_SERVICE_OPTS_SUCCESS,
  GET_SINGLE_RFP_DATA_FAILED,
  GET_SINGLE_RFP_DATA_REQUESTED,
  GET_SINGLE_RFP_DATA_SUCCESS,
  SEND_RPF_FAILED,
  SEND_RPF_REQUESTED,
  SEND_RPF_SUCCESS,
  UPDATE_RFP_ADRESS,
  UPDATE_RPF_FAILED,
  UPDATE_RPF_REQUESTED,
  UPDATE_RPF_SUCCESS,
} from '../constants/rfp'

const initialState = {
  rfpListData: [],
  rfpProjectData: {},
  rfpCompanyList: [],
  scopeServiceOpts: null,
  isLoadingRfp: true,
  isLoadingNewRfp: false,
  isRfpDeleteLoading: false,
  isLoadingSingleRfp: true,
  isLoadingSosOpts: false,
  isLoadingCompanyList: false,
  isFavoriteLoading: false,
  sosOptsError: null,
  rfpError: null,
  newRfpError: null,
  singleRfpError: null,
  companyListError: null,
  favoriteError: null,
  updateRFPLoading: false,
  updateRFPError: null,
  sendRFPLoading: false,
  rfpBuidsData: [],
}

export const rfpDataReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_RFP_LIST_REQUESTED:
      return {
        ...state,
        isLoadingRfp: true,
        rfpListData: [],
      }
    case FETCH_RFP_LIST_SUCCESS:
      return {
        ...state,
        rfpListData: [...action.payload],
        isLoadingRfp: false,
      }
    case FETCH_RFP_LIST_FAILED:
      return {
        ...state,
        isLoadingRfp: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        rfpError: action.payload?.data,
      }
    case CREATE_NEW_RFP_REQUESTED:
      return {
        ...state,
        isLoadingNewRfp: true,
      }
    case CREATE_NEW_RFP_SUCCESS:
      return {
        ...state,
        isLoadingNewRfp: false,
        rfpProjectData: action.payload,
        rfpListData: [...state.rfpListData, action.payload],
      }
    case CREATE_NEW_RFP_FAILED:
      return {
        ...state,
        isLoadingNewRfp: false,
        newRfpError: action.payload,
      }
    case UPDATE_RFP_ADRESS:
      return {
        ...state,
        isLoadingNewRfp: false,
        ...action.payload,
      }
    case GET_SINGLE_RFP_DATA_REQUESTED:
      return {
        ...state,
        isLoadingSingleRfp: true,
      }
    case GET_SINGLE_RFP_DATA_SUCCESS:
      return {
        ...state,
        isLoadingSingleRfp: false,
        rfpProjectData: action.payload,
      }
    case GET_SINGLE_RFP_DATA_FAILED:
      return {
        ...state,
        isLoadingSingleRfp: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        singleRfpError: action.payload?.data,
      }
    case DELETE_RFP_REQUESTED:
      return {
        ...state,
        isRfpDeleteLoading: true,
      }
    case DELETE_RFP_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const cloneRfpList = [...state.rfpListData]
      // eslint-disable-next-line no-case-declarations
      const updatedRfpList = cloneRfpList?.filter(
        (rfp) => !action?.payload?.includes(rfp?.id),
      )
      return {
        ...state,
        isRfpDeleteLoading: false,
        rfpListData: updatedRfpList,
      }
    case DELETE_RFP_FAILED:
      return {
        ...state,
        isRfpDeleteLoading: false,
      }
    case GET_SCOPE_SERVICE_OPTS_REQUESTED:
      return {
        ...state,
        isLoadingSosOpts: true,
      }
    case GET_SCOPE_SERVICE_OPTS_SUCCESS:
      return {
        ...state,
        scopeServiceOpts: action.payload,
        isLoadingSosOpts: false,
      }
    case GET_SCOPE_SERVICE_OPTS_FAILED:
      return {
        ...state,
        isLoadingSosOpts: false,
        sosOptsError: action.payload,
      }
    case GET_RFP_COMPANY_LIST_REQUESTED:
      return {
        ...state,
        isLoadingCompanyList: true,
      }
    case GET_RFP_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        rfpCompanyList: action.payload,
        isLoadingCompanyList: false,
      }
    case GET_RFP_COMPANY_LIST_FAILED:
      return {
        ...state,
        isLoadingCompanyList: false,
        companyListError: action.payload,
      }
    case ADD_REMOVE_COMPANY_REQUESTED:
      return {
        ...state,
        isFavoriteLoading: true,
      }
    case ADD_REMOVE_COMPANY_SUCCESS:
      return {
        ...state,
        isFavoriteLoading: false,
        rfpCompanyList: action.payload,
      }
    case ADD_REMOVE_COMPANY_FAILED:
      return {
        ...state,
        isFavoriteLoading: false,
        favoriteError: action.payload,
      }
    // update rfp
    case UPDATE_RPF_REQUESTED:
      return {
        ...state,
        updateRFPLoading: true,
      }
    case UPDATE_RPF_SUCCESS:
      return {
        ...state,
        updateRFPLoading: false,
        ...action.payload,
      }
    case UPDATE_RPF_FAILED:
      return {
        ...state,
        updateRFPLoading: false,
        updateRFPError: action.payload,
      }
    // Send RPF Notification
    case SEND_RPF_REQUESTED:
      return {
        ...state,
        sendRFPLoading: true,
      }
    case SEND_RPF_SUCCESS:
      return {
        ...state,
        sendRFPLoading: false,
      }
    case SEND_RPF_FAILED:
      return {
        ...state,
        sendRFPLoading: false,
      }
    // Get bids data
    case BUIDS_REQUESTED:
      return {
        ...state,
        fetchBidsLoading: true,
      }
    case BUIDS_SUCCESS:
      return {
        ...state,
        fetchBidsLoading: false,
        rfpBuidsData: action.payload?.consultantBids,
      }
    case BUIDS_FAILED:
      return {
        ...state,
        fetchBidsLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }
    case CLEAR_RFP_DATA:
      return initialState

    default:
      return state
  }
}
