// Fetch G703 tracker
export const FETCH_G703_TRACKER_REQUESTED = 'FETCH_G703_TRACKER_REQUESTED'
export const FETCH_G703_TRACKER_SUCCESS = 'FETCH_G703_TRACKER_SUCCESS'
export const FETCH_G703_TRACKER_FAILED = 'FETCH_G703_TRACKER_FAILED'

// Save G703 tracker
export const SAVE_G703_TRACKER_REQUESTED = 'SAVE_G703_TRACKER_REQUESTED'
export const SAVE_G703_TRACKER_SUCCESS = 'SAVE_G703_TRACKER_SUCCESS'
export const SAVE_G703_TRACKER_FAILED = 'SAVE_G703_TRACKER_FAILED'

// Delete G703 Tracker
export const DELETE_G703_TRACKER_REQUESTED = 'DELETE_G703_TRACKER_REQUESTED'
export const DELETE_G703_TRACKER_SUCCESS = 'DELETE_G703_TRACKER_SUCCESS'
export const DELETE_G703_TRACKER_FAILED = 'DELETE_G703_TRACKER_FAILED'

/* Change order tracker */
// Fetch CO tracker
export const FETCH_CO_TRACKER_REQUESTED = 'FETCH_CO_TRACKER_REQUESTED'
export const FETCH_CO_TRACKER_SUCCESS = 'FETCH_CO_TRACKER_SUCCESS'
export const FETCH_CO_TRACKER_FAILED = 'FETCH_CO_TRACKER_FAILED'

// Save CO tracker
export const SAVE_CO_TRACKER_REQUESTED = 'SAVE_CO_TRACKER_REQUESTED'
export const SAVE_CO_TRACKER_SUCCESS = 'SAVE_CO_TRACKER_SUCCESS'
export const SAVE_CO_TRACKER_FAILED = 'SAVE_CO_TRACKER_FAILED'

// Update CO tracker
export const UPDATE_CO_TRACKER_REQUESTED = 'UPDATE_CO_TRACKER_REQUESTED'
export const UPDATE_CO_TRACKER_SUCCESS = 'UPDATE_CO_TRACKER_SUCCESS'
export const UPDATE_CO_TRACKER_FAILED = 'UPDATE_CO_TRACKER_FAILED'

// Delete CO Tracker
export const DELETE_CO_TRACKER_REQUESTED = 'DELETE_CO_TRACKER_REQUESTED'
export const DELETE_CO_TRACKER_SUCCESS = 'DELETE_CO_TRACKER_SUCCESS'
export const DELETE_CO_TRACKER_FAILED = 'DELETE_CO_TRACKER_FAILED'

/* Potential change order tracker */
// Fetch PCO tracker
export const FETCH_PCO_TRACKER_REQUESTED = 'FETCH_PCO_TRACKER_REQUESTED'
export const FETCH_PCO_TRACKER_SUCCESS = 'FETCH_PCO_TRACKER_SUCCESS'
export const FETCH_PCO_TRACKER_FAILED = 'FETCH_PCO_TRACKER_FAILED'

// Save PCO tracker
export const SAVE_PCO_TRACKER_REQUESTED = 'SAVE_PCO_TRACKER_REQUESTED'
export const SAVE_PCO_TRACKER_SUCCESS = 'SAVE_PCO_TRACKER_SUCCESS'
export const SAVE_PCO_TRACKER_FAILED = 'SAVE_PCO_TRACKER_FAILED'

// Update PCO tracker
export const UPDATE_PCO_TRACKER_REQUESTED = 'UPDATE_PCO_TRACKER_REQUESTED'
export const UPDATE_PCO_TRACKER_SUCCESS = 'UPDATE_PCO_TRACKER_SUCCESS'
export const UPDATE_PCO_TRACKER_FAILED = 'UPDATE_PCO_TRACKER_FAILED'

// Delete PCO Tracker
export const DELETE_PCO_TRACKER_REQUESTED = 'DELETE_PCO_TRACKER_REQUESTED'
export const DELETE_PCO_TRACKER_SUCCESS = 'DELETE_PCO_TRACKER_SUCCESS'
export const DELETE_PCO_TRACKER_FAILED = 'DELETE_PCO_TRACKER_FAILED'

/* Lien waivers tracker */
// Fetch Lien waivers tracker
export const FETCH_LIEN_WAIVERS_REQUESTED = 'FETCH_LIEN_WAIVERS_REQUESTED'
export const FETCH_LIEN_WAIVERS_SUCCESS = 'FETCH_LIEN_WAIVERS_SUCCESS'
export const FETCH_LIEN_WAIVERS_FAILED = 'FETCH_LIEN_WAIVERS_FAILED'

// Save Lien waivers tracker
export const SAVE_LIEN_WAIVERS_REQUESTED = 'SAVE_LIEN_WAIVERS_REQUESTED'
export const SAVE_LIEN_WAIVERS_SUCCESS = 'SAVE_LIEN_WAIVERS_SUCCESS'
export const SAVE_LIEN_WAIVERS_FAILED = 'SAVE_LIEN_WAIVERS_FAILED'

// Delete Lien waivers Tracker
export const DELETE_LIEN_WAIVERS_REQUESTED = 'DELETE_LIEN_WAIVERS_REQUESTED'
export const DELETE_LIEN_WAIVERS_SUCCESS = 'DELETE_LIEN_WAIVERS_SUCCESS'
export const DELETE_LIEN_WAIVERS_FAILED = 'DELETE_LIEN_WAIVERS_FAILED'

/* Stored material tracker */
// Fetch stored material tracker
export const FETCH_STORED_MATERIAL_REQUESTED = 'FETCH_STORED_MATERIAL_REQUESTED'
export const FETCH_STORED_MATERIAL_SUCCESS = 'FETCH_STORED_MATERIAL_SUCCESS'
export const FETCH_STORED_MATERIAL_FAILED = 'FETCH_STORED_MATERIAL_FAILED'

// Save stored material tracker
export const SAVE_STORED_MATERIAL_REQUESTED = 'SAVE_STORED_MATERIAL_REQUESTED'
export const SAVE_STORED_MATERIAL_SUCCESS = 'SAVE_STORED_MATERIAL_SUCCESS'
export const SAVE_STORED_MATERIAL_FAILED = 'SAVE_STORED_MATERIAL_FAILED'

// Delete stored material Tracker
export const DELETE_STORED_MATERIAL_REQUESTED =
  'DELETE_STORED_MATERIAL_REQUESTED'
export const DELETE_STORED_MATERIAL_SUCCESS = 'DELETE_STORED_MATERIAL_SUCCESS'
export const DELETE_STORED_MATERIAL_FAILED = 'DELETE_STORED_MATERIAL_FAILED'

// G702 tracker
export const FETCH_G702_SPLIT_TRACKER_REQUESTED =
  'FETCH_G702_SPLIT_TRACKER_REQUESTED'
export const FETCH_G702_SPLIT_TRACKER_SUCCESS =
  'FETCH_G702_SPLIT_TRACKER_SUCCESS'
export const FETCH_G702_SPLIT_TRACKER_FAILED = 'FETCH_G702_SPLIT_TRACKER_FAILED'

// Save G702 tracker
export const SAVE_G702_SPLIT_TRACKER_REQUESTED =
  'SAVE_G702_SPLIT_TRACKER_REQUESTED'
export const SAVE_G702_SPLIT_TRACKER_SUCCESS = 'SAVE_G702_SPLIT_TRACKER_SUCCESS'
export const SAVE_G702_SPLIT_TRACKER_FAILED = 'SAVE_G702_SPLIT_TRACKER_FAILED'

// Delete G702 Split Tracker
export const DELETE_G702_SPLIT_TRACKER_REQUESTED =
  'DELETE_G702_SPLIT_TRACKER_REQUESTED'
export const DELETE_G702_SPLIT_TRACKER_SUCCESS =
  'DELETE_G702_SPLIT_TRACKER_SUCCESS'
export const DELETE_G702_SPLIT_TRACKER_FAILED =
  'DELETE_G702_SPLIT_TRACKER_FAILED'

// G702 tracker
export const FETCH_REPORT_G702_SPLIT_TRACKER_REQUESTED =
  'FETCH_REPORT_G702_SPLIT_TRACKER_REQUESTED'
export const FETCH_REPORT_G702_SPLIT_TRACKER_SUCCESS =
  'FETCH_REPORT_G702_SPLIT_TRACKER_SUCCESS'
export const FETCH_REPORT_G702_SPLIT_TRACKER_FAILED =
  'FETCH_REPORT_G702_SPLIT_TRACKER_FAILED'

// clear G702 split trackers
export const CLEAR_G702_SPLIT_TRACKER = 'CLEAR_G702_SPLIT_TRACKER'

// clear report trackers
export const CLEAR_REPORT_TRACKER = 'CLEAR_REPORT_TRACKER'
