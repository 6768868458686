import { Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useSelector } from 'react-redux'
import DKTStack from '../../../Shared/DKTStack'
import DKTTypography from '../../../Shared/DKTTypography'
import { keys } from '../../../Utils/javascript'
import { useStyles } from '../../../Styles/rfc.style'
import GoogleMap from '../../dashboard/GoogleMap'

const ConsultantDetail = ({
  FormSectionDivider,
  selectedComp,
  handleShowHideDetail,
}) => {
  const classes = useStyles()
  const { rfpCompanyList } = useSelector(({ rfpData }) => rfpData)

  return (
    <>
      {keys(selectedComp)?.length > 0 && (
        <DKTStack
          sx={{
            backgroundColor: '#ffffff',
            padding: '20px 37px 69px 43px',
            minHeight: '629px',
          }}
          className={!keys(selectedComp)?.length > 0 && classes.hidden}
        >
          <DKTStack minHeight={234}>
            <DKTStack
              direction="row"
              justifyContent="space-between"
              sx={{ position: 'relative', width: '100%', height: '100%' }}
            >
              {selectedComp?.companyLogo && (
                <DKTStack className={classes.emptyImageWrapper}>
                  <img
                    src={selectedComp?.companyLogo}
                    alt="Company logo"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </DKTStack>
              )}
              <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                <CloseIcon
                  color="#000"
                  onClick={handleShowHideDetail}
                  cursor="pointer"
                  fontSize="large"
                />
              </Box>
            </DKTStack>
            <Box mb={3}>
              <DKTTypography variant="h6" fontWeight="bold">
                {selectedComp?.companyName}
              </DKTTypography>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
              >
                <DKTTypography
                  variant="body2"
                  color="primary"
                  component="a"
                  href={selectedComp?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  {`${selectedComp?.address}, ${selectedComp?.city}, ${selectedComp?.state} ${selectedComp?.zipCode}`}
                </DKTTypography>
                <DKTTypography
                  variant="body2"
                  color="primary"
                  component="a"
                  href={selectedComp?.websiteUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  {selectedComp?.websiteUrl}
                </DKTTypography>
              </Box>
              {selectedComp?.workPhoneNumber && (
                <DKTTypography variant="body1">
                  {selectedComp?.workPhoneNumber} (w)
                </DKTTypography>
              )}
            </Box>
            <DKTTypography variant="body1" mb={3} fontWeight="bold">
              Service State Locations:
              <DKTTypography variant="body1" component="span" ml={1}>
                {selectedComp?.serviceStateLocation.join(', ')}
              </DKTTypography>
            </DKTTypography>
            <Box mb={3}>
              <DKTTypography variant="body1" fontWeight="bold">
                Office Locations:
                <DKTTypography variant="body1" component="span" ml={1}>
                  {selectedComp?.officeLocations
                    ?.map(({ otherOfficeState }) => otherOfficeState)
                    .join(', ')}
                </DKTTypography>
              </DKTTypography>
            </Box>
            <Box mb={3}>
              <DKTTypography variant="body1" fontWeight="bold">
                Primary Contact
              </DKTTypography>
              <DKTTypography variant="body1">
                {selectedComp.companyKeyPersonnel?.[0]?.privacyContactName}
              </DKTTypography>
              <DKTTypography variant="body1">
                {selectedComp.companyKeyPersonnel?.[0]?.privacyContactTitle}
              </DKTTypography>
              {selectedComp.companyKeyPersonnel?.[0]?.cellPhone && (
                <DKTTypography variant="body1">
                  {selectedComp.companyKeyPersonnel?.[0]?.cellPhone} (c)
                </DKTTypography>
              )}
              {selectedComp.companyKeyPersonnel?.[0]?.workPhoneNumber && (
                <DKTTypography variant="body1">
                  {selectedComp.companyKeyPersonnel?.[0]?.workPhoneNumber} (w)
                </DKTTypography>
              )}
              <DKTTypography
                variant="body2"
                color="primary"
                component="a"
                href={`mailto:${selectedComp.companyKeyPersonnel?.[0]?.email}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                {selectedComp.companyKeyPersonnel?.[0]?.email}
              </DKTTypography>
            </Box>
            <Box>
              <DKTTypography variant="body1" fontWeight="bold">
                Services Offered:
                <DKTTypography variant="body1" component="span" ml={1}>
                  {selectedComp?.serviceOffered.join(', ')}
                </DKTTypography>
              </DKTTypography>
            </Box>
          </DKTStack>
        </DKTStack>
      )}
      <DKTStack
        sx={{ height: '100%' }}
        className={keys(selectedComp)?.length > 0 && classes.hidden}
      >
        <FormSectionDivider sectionTitle="AcreIQ PROJECTS" mt={0} />
        <GoogleMap data={rfpCompanyList || []} height="100%" />
      </DKTStack>
    </>
  )
}

export default ConsultantDetail
