import { equal, ternary } from '../../Utils/javascript'
import {
  FETCH_PHOTOS_REQUESTED,
  FETCH_PHOTOS_SUCCESS,
  FETCH_PHOTOS_FAILED,
  DOWNLOAD_PROJECT_IMAGES_REQUESTED,
  DOWNLOAD_PROJECT_IMAGES_SUCCESS,
  DOWNLOAD_PROJECT_IMAGES_FAILED,
} from '../constants/projectPhotos'

const initialState = {
  isFetchPhotosLoading: true,
  projectPhotos: [],
  projectPhotosError: '',
  isDownloadProjectImagesLoading: false,
}

export const projectPhotos = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PHOTOS_REQUESTED:
      return {
        ...state,
        isFetchPhotosLoading: true,
        projectError: '',
      }
    case FETCH_PHOTOS_SUCCESS:
      return {
        ...state,
        isFetchPhotosLoading: false,
        projectPhotos: action?.payload,
      }
    case FETCH_PHOTOS_FAILED:
      return {
        ...state,
        isFetchPhotosLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        projectPhotos: [],
      }
    case DOWNLOAD_PROJECT_IMAGES_REQUESTED:
      return {
        ...state,
        isDownloadProjectImagesLoading: true,
      }
    case DOWNLOAD_PROJECT_IMAGES_SUCCESS:
      return {
        ...state,
        isDownloadProjectImagesLoading: false,
      }
    case DOWNLOAD_PROJECT_IMAGES_FAILED:
      return {
        ...state,
        isDownloadProjectImagesLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }
    default:
      return state
  }
}
