import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import React, { memo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { ExpandIcon } from '../../Assets/SVGs'
import { sidebarLinks } from '../../Description/sidebar.description'
import { matchRoutesLink, ternary } from '../../Utils/javascript'

const StyledAccordion = styled(Accordion)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '& .MuiAccordionSummary-root': {
    padding: 0,
    minHeight: 'auto',
    paddingRight: 8,
    '&.Mui-expanded': {
      minHeight: 'auto',
      margin: 0,
    },
  },
  '& .MuiAccordionSummary-content': {
    paddingLeft: 20,
    marginBlock: 0,
    paddingBlock: 8,
    '&.Mui-expanded': {
      minHeight: 'auto',
      margin: 0,
    },
  },
  '& .MuiAccordionDetails-root': {
    paddingRight: '8px',
    paddingLeft: 21,
    paddingBlock: 8,
  },
}))

const Sidebar = () => {
  const location = useLocation()
  const { palette } = useTheme()
  const { systemAuthorization, portalType } = useSelector(({ auth }) => auth)

  const activeTab = (dropdownItems) =>
    dropdownItems?.find((dropdownItem) =>
      matchRoutesLink({
        path: dropdownItem.url,
        location,
      }),
    )
  return (
    <Box
      backgroundColor="gray.main"
      minWidth="250px"
      sx={{ position: 'fixed', top: 164, bottom: 0 }}
    >
      <List sx={{ pt: 5 }}>
        {sidebarLinks(systemAuthorization, portalType)?.map(
          ({ text, url, dropdownItems }, index) => {
            const isActive = matchRoutesLink({
              path: url,
              location,
            })
            return (
              <ListItem
                key={index}
                sx={{
                  borderLeft: ternary(dropdownItems, 0, 4),
                  fontSize: '14px',
                  paddingRight: 0,
                  ...ternary(
                    dropdownItems,
                    { padding: 0 },
                    ternary(
                      isActive,
                      {
                        fontWeight: 500,
                        borderColor: 'primary.main',
                        color: 'primary.main',
                        backgroundColor: 'primary.light',
                      },
                      { borderColor: 'transparent', color: '#000' },
                    ),
                  ),
                }}
              >
                {ternary(
                  dropdownItems,
                  <Box width="100%">
                    <StyledAccordion defaultExpanded={activeTab(dropdownItems)}>
                      <AccordionSummary expandIcon={<ExpandIcon />}>
                        <Typography
                          variant="span"
                          fontWeight={400}
                          fontSize={14}
                          color="#000"
                        >
                          {text}
                        </Typography>
                      </AccordionSummary>
                      {dropdownItems?.map((dropdownItem, index) => {
                        const isDropdownItemActive = matchRoutesLink({
                          path: dropdownItem.url,
                          location,
                        })
                        return (
                          <AccordionDetails
                            key={index}
                            sx={{
                              fontSize: '14px',
                              borderLeft: 4,
                              ...ternary(
                                isDropdownItemActive,
                                {
                                  borderColor: 'primary.main',
                                  backgroundColor: 'primary.light',
                                  fontWeight: 500,
                                },
                                {
                                  borderColor: 'transparent',
                                },
                              ),
                            }}
                          >
                            <Link
                              to={dropdownItem.url}
                              style={{
                                display: 'block',
                                width: '100%',
                                paddingRight: '16px',
                                textDecoration: 'none',
                                color: ternary(
                                  isDropdownItemActive,
                                  palette.primary.main,
                                  '#000',
                                ),
                              }}
                            >
                              {dropdownItem.text}
                            </Link>
                          </AccordionDetails>
                        )
                      })}
                    </StyledAccordion>
                  </Box>,
                  <Link
                    to={url}
                    style={{
                      display: 'block',
                      width: '100%',
                      paddingRight: '16px',
                      textDecoration: 'none',
                      color: ternary(isActive, palette.primary.main, '#000'),
                    }}
                  >
                    {text}
                  </Link>,
                )}
              </ListItem>
            )
          },
        )}
      </List>
    </Box>
  )
}

export default memo(Sidebar)
