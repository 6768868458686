export const options = [
  { label: 'Received', value: '0' },
  { label: 'Not Received', value: '1' },
  { label: 'N/A', value: '2' },
]

// Appendix document table
export const appendixColumns = ['Document', 'Status', 'Include in Appendix']

export const dynamicColor = {
  0: '#1564FF',
  1: '#FFCCCB',
  2: '#E5E5E5',
}
