import { zipCodeValidation } from '../Utils/regex'

export const rfpFieldList = {
  projectName: {
    type: 'text',
    label: 'Project Name',
    isRequired: true,
  },
  address: {
    type: 'text',
    label: 'Address',
  },
  city: {
    type: 'text',
    label: 'City',
    isRequired: true,
  },
  state: {
    type: 'select',
    label: 'State',
    displayEmpty: true,
    lg: 6,
    xs: 6,
    isDynamicOptions: true,
    isRequired: true,
    placeholder: 'Select',
  },
  zipCode: {
    type: 'text',
    label: 'Zip Code',
    isRequired: true,
    lg: 6,
    xs: 6,
    validate: [zipCodeValidation],
    formControlProps: {
      sx: {
        mb: 3,
      },
    },
  },
}
