import api from '../../Utils/api'
import {
  apiEndPoints,
  method,
  milestoneDeleteMessage,
} from '../../Utils/constant'
import { checkIncludes, equal, length, ternary } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  DELETE_CONSTRUCTION_SCHEDULE_REPORT_FAILED,
  DELETE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED,
  DELETE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS,
  DELETE_MILESTONE_TRACKER_FAILED,
  DELETE_MILESTONE_TRACKER_REQUESTED,
  DELETE_MILESTONE_TRACKER_SUCCESS,
  DELETE_PHASE_TRACKER_FAILED,
  DELETE_PHASE_TRACKER_REQUESTED,
  DELETE_PHASE_TRACKER_SUCCESS,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_FAILED,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_FAILED,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_REQUESTED,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_SUCCESS,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED,
  FETCH_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS,
  FETCH_MILESTONE_TRACKER_FAILED,
  FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA_REQUESTED,
  FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__FAILED,
  FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__SUCCESS,
  FETCH_MILESTONE_TRACKER_REQUESTED,
  FETCH_MILESTONE_TRACKER_SUCCESS,
  FETCH_PHASE_TRACKER_FAILED,
  FETCH_PHASE_TRACKER_LAST_REPORT_DATA_FAILED,
  FETCH_PHASE_TRACKER_LAST_REPORT_DATA_REQUESTED,
  FETCH_PHASE_TRACKER_REQUESTED,
  FETCH_PHASE_TRACKER_SUCCESS,
  IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_FAILED,
  IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_REQUESTED,
  IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_SUCCESS,
  SAVE_CONSTRUCTION_SCHEDULE_REPORT_FAILED,
  SAVE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED,
  SAVE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS,
  SAVE_MILESTONE_TRACKER_FAILED,
  SAVE_MILESTONE_TRACKER_REQUESTED,
  SAVE_MILESTONE_TRACKER_SUCCESS,
  SAVE_PHASE_TRACKER_FAILED,
  SAVE_PHASE_TRACKER_REQUESTED,
  SAVE_PHASE_TRACKER_SUCCESS,
} from '../constants/constructionScheduleReport'
import { updateSaveStatusAction } from './confirmation'
import { getReportList } from './reportMonitoring'

// Fetch construction schedule report reference values
const fetchConstructionScheduleReportReferenceValuesRequested = () => ({
  type: FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_REQUESTED,
})
const fetchConstructionScheduleReportReferenceValuesSuccess = (payload) => ({
  type: FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_SUCCESS,
  payload,
})
const fetchConstructionScheduleReportReferenceValuesFailed = (payload) => ({
  type: FETCH_CONSTRUCTION_SCHEDULE_REPORT_REFERENCE_VALUES_FAILED,
  payload,
})

export const getConstructionScheduleReportReferenceValues =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchConstructionScheduleReportReferenceValuesRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.constructionScheduleReport}reference-values/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
    })
    if (data) {
      dispatch(fetchConstructionScheduleReportReferenceValuesSuccess(data))
      return data
    }
    if (error) {
      dispatch(
        fetchConstructionScheduleReportReferenceValuesFailed(
          error.response.data,
        ),
      )
      throw new Error(error?.response?.data)
    }
    return false
  }

// Fetch construction schedule report
const fetchConstructionScheduleReportRequested = () => ({
  type: FETCH_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED,
})
const fetchConstructionScheduleReportSuccess = (payload) => ({
  type: FETCH_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS,
  payload,
})
const fetchConstructionScheduleReportFailed = (payload) => ({
  type: FETCH_CONSTRUCTION_SCHEDULE_REPORT_FAILED,
  payload,
})

export const getConstructionScheduleReport =
  (reportId, ocId) => async (dispatch) => {
    dispatch(fetchConstructionScheduleReportRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.constructionScheduleReport}?reportId=${reportId}&ocId=${ocId}`,
    })
    if (data) {
      dispatch(fetchConstructionScheduleReportSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(saveConstructionScheduleReportSuccess(null))
      }
      dispatch(fetchConstructionScheduleReportFailed(error.response))
    }
  }

// Save construction schedule report
const saveConstructionScheduleReportRequested = () => ({
  type: SAVE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED,
})
export const saveConstructionScheduleReportSuccess = (payload) => ({
  type: SAVE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS,
  payload,
})
const saveConstructionScheduleReportFailed = (payload) => ({
  type: SAVE_CONSTRUCTION_SCHEDULE_REPORT_FAILED,
  payload,
})

export const saveConstructionScheduleReport =
  ({ payload, reportId, ocId, projectId, ownerContractorUniqueCode }) =>
  async (dispatch, getState) => {
    dispatch(saveConstructionScheduleReportRequested())
    const { reportData } = getState().reportMonitoring
    const { constructionScheduleReport } = getState().constructionScheduleReport
    const isAlreadyCreated =
      reportData?.formCreatedList[ownerContractorUniqueCode]
        ?.constructionSchedule
    const { data, error } = await api({
      method: ternary(isAlreadyCreated, method.patch, method.post),
      endPoint: ternary(
        isAlreadyCreated,
        `${apiEndPoints.constructionScheduleReport}${constructionScheduleReport?.id}/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
        `${apiEndPoints.constructionScheduleReport}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      ),
      data: { ...payload, ownerContractor: ocId, report: reportId },
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      if (!isAlreadyCreated) dispatch(getReportList(projectId, ocId, reportId))

      dispatch(saveConstructionScheduleReportSuccess(data))
    }
    if (error) {
      dispatch(saveConstructionScheduleReportFailed(error.response.data))
    }
  }

// Delete construction schedule report
const deleteConstructionScheduleReportRequested = () => ({
  type: DELETE_CONSTRUCTION_SCHEDULE_REPORT_REQUESTED,
})
export const deleteConstructionScheduleReportSuccess = (payload) => ({
  type: DELETE_CONSTRUCTION_SCHEDULE_REPORT_SUCCESS,
  payload,
})
const deleteConstructionScheduleReportFailed = (payload) => ({
  type: DELETE_CONSTRUCTION_SCHEDULE_REPORT_FAILED,
  payload,
})

export const deleteConstructionScheduleReport =
  (payload, handleClose) => async (dispatch) => {
    dispatch(deleteConstructionScheduleReportRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.deleteConstructionScheduleReport}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Deleted successfully!',
    })
    if (data) {
      handleClose()
      dispatch(deleteConstructionScheduleReportSuccess(payload))
      return
    }
    if (error) {
      dispatch(deleteConstructionScheduleReportFailed(error.response.data))
    }
  }

// Import construction schedule report from last report
const importConstructionScheduleCommentsRequested = () => ({
  type: IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_REQUESTED,
})
const importConstructionScheduleCommentsSuccess = (payload) => ({
  type: IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_SUCCESS,
  payload,
})
const importConstructionScheduleCommentsFailed = (payload) => ({
  type: IMPORT_CONSTRUCTION_SCHEDULE_REPORT_COMMENTS_FAILED,
  payload,
})
export const importConstructionScheduleComments =
  ({ projectId, ocId, reportId, onSuccess = () => null }) =>
  async (dispatch) => {
    dispatch(importConstructionScheduleCommentsRequested())

    const { data, error } = await api({
      endPoint: `${apiEndPoints.importLastReport}?query=constructionScheduleComments&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
      method: method.get,
    })

    if (data) {
      dispatch(importConstructionScheduleCommentsSuccess(data))
      onSuccess(data.constructionScheduleComments)
    }
    if (error) {
      showToast('Failed to import comments')
      dispatch(importConstructionScheduleCommentsFailed(error.response.data))
    }
  }

/** *****************
    PHASE TRACKER
 ***************** */
// Fetch phase tracker
const fetchPhaseTrackerRequested = () => ({
  type: FETCH_PHASE_TRACKER_REQUESTED,
})
const fetchPhaseTrackerSuccess = (payload) => ({
  type: FETCH_PHASE_TRACKER_SUCCESS,
  payload,
})
const fetchPhaseTrackerFailed = (payload) => ({
  type: FETCH_PHASE_TRACKER_FAILED,
  payload,
})

export const getPhaseTracker =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchPhaseTrackerRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.phaseTracker}?reportId=${reportId}&projectId=${projectId}&ocId=${ocId}`,
    })
    if (data) {
      dispatch(fetchPhaseTrackerSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(savePhaseSuccess([]))
        // for show last report data on phase tracker
        // dispatch(getPhaseTrackerLastReportData({ reportId, ocId, projectId }))
      }
      dispatch(fetchPhaseTrackerFailed(error.response))
    }
  }

// Fetch phase tracker
const fetchPhaseTrackerLastReportDataRequested = () => ({
  type: FETCH_PHASE_TRACKER_LAST_REPORT_DATA_REQUESTED,
})

const fetchPhaseTrackerLastReportDataFailed = (payload) => ({
  type: FETCH_PHASE_TRACKER_LAST_REPORT_DATA_FAILED,
  payload,
})

export const getPhaseTrackerLastReportData =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchPhaseTrackerLastReportDataRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.phaseTrackerLastReportData}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
    })
    if (data) {
      dispatch(fetchPhaseTrackerSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(savePhaseSuccess([]))
      }
      dispatch(fetchPhaseTrackerLastReportDataFailed(error.response.data))
    }
  }

// Save phase tracker
const savePhaseRequested = () => ({
  type: SAVE_PHASE_TRACKER_REQUESTED,
})
export const savePhaseSuccess = (payload) => ({
  type: SAVE_PHASE_TRACKER_SUCCESS,
  payload,
})
const savePhaseFailed = (payload) => ({
  type: SAVE_PHASE_TRACKER_FAILED,
  payload,
})

export const savePhase =
  ({
    payload,
    reportId,
    ocId,
    projectId,
    phaseToEdit,
    onSuccess = () => null,
  }) =>
  async (dispatch, getState) => {
    dispatch(savePhaseRequested())
    const { data, error } = await api({
      method: ternary(phaseToEdit, method.patch, method.post),
      endPoint: ternary(
        phaseToEdit,
        `${apiEndPoints.phaseTracker}${phaseToEdit}/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
        `${apiEndPoints.phaseTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      ),
      data: ternary(payload?.ownerContractor && payload?.report, payload, {
        ...payload,
        ownerContractor: ocId,
        report: reportId,
      }),
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      const { phaseTracker } = getState().constructionScheduleReport
      let savedData
      if (phaseToEdit) {
        const phaseToUpdate = phaseTracker.findIndex(({ id }) =>
          equal(id, phaseToEdit),
        )
        if (~phaseToUpdate) {
          const clonedPhaseTracker = [...phaseTracker]
          clonedPhaseTracker.splice(phaseToUpdate, 1, data)
          savedData = clonedPhaseTracker
        }
      } else {
        savedData = [...phaseTracker, data]
      }
      dispatch(savePhaseSuccess(savedData))
      onSuccess()
    }
    if (error) {
      dispatch(savePhaseFailed(error.response.data))
    }
  }

// Delete phase tracker
const deletePhaseRequested = () => ({
  type: DELETE_PHASE_TRACKER_REQUESTED,
})
export const deletePhaseSuccess = (payload) => ({
  type: DELETE_PHASE_TRACKER_SUCCESS,
  payload,
})
const deletePhaseFailed = (payload) => ({
  type: DELETE_PHASE_TRACKER_FAILED,
  payload,
})

export const deletePhase =
  ({ phaseToDelete, reportId, ocId, projectId, onSuccess }) =>
  async (dispatch, getState) => {
    dispatch(deletePhaseRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.phaseTracker}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: {
        phaseTracker: [phaseToDelete],
      },
      showToastMessage: true,
      toastMessage: 'Deleted successfully!',
    })
    if (status) {
      const { phaseTracker } = getState().constructionScheduleReport
      const clonedPhaseTracker = [...phaseTracker]
      const phaseId = clonedPhaseTracker.findIndex(({ id }) =>
        equal(id, phaseToDelete),
      )
      if (~phaseId) clonedPhaseTracker.splice(phaseId, 1)
      onSuccess()
      dispatch(deletePhaseSuccess(clonedPhaseTracker))
    }
    if (error) {
      dispatch(deletePhaseFailed(error.response.data))
    }
  }

/* * * * * * * * * * * * * * * * * *
  Milestone tracker
* * * * * * * * * * * * * * * * * */
// Fetch Milestone tracker
const fetchMilestoneTrackerRequested = () => ({
  type: FETCH_MILESTONE_TRACKER_REQUESTED,
})
const fetchMilestoneTrackerSuccess = (payload) => ({
  type: FETCH_MILESTONE_TRACKER_SUCCESS,
  payload,
})
const fetchMilestoneTrackerFailed = (payload) => ({
  type: FETCH_MILESTONE_TRACKER_FAILED,
  payload,
})

export const getMilestoneTracker =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchMilestoneTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.milestoneTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      dispatch(fetchMilestoneTrackerSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchMilestoneTrackerSuccess([]))
        // for show last report data on milestoneTracker
        // dispatch(getLastReportDataOfMilestone({ reportId, ocId, projectId }))
      }
      dispatch(fetchMilestoneTrackerFailed(error.response))
    }
  }

// get last report of Milestone
const fetchMilestoneTrackerLastReportDataRequested = () => ({
  type: FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA_REQUESTED,
})
const fetchMilestoneTrackerLastReportDataSuccess = (payload) => ({
  type: FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__SUCCESS,
  payload,
})
const fetchMilestoneTrackerLastReportDataFailed = (payload) => ({
  type: FETCH_MILESTONE_TRACKER_LAST_REPORT_DATA__FAILED,
  payload,
})

export const getLastReportDataOfMilestone =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchMilestoneTrackerLastReportDataRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.milestoneTrackerLastReportData}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      dispatch(fetchMilestoneTrackerSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchMilestoneTrackerLastReportDataSuccess([]))
      }
      dispatch(fetchMilestoneTrackerLastReportDataFailed(error.response.data))
    }
  }

// Save Milestone tracker
const saveMilestoneTrackerRequested = () => ({
  type: SAVE_MILESTONE_TRACKER_REQUESTED,
})
const saveMilestoneTrackerSuccess = (payload) => ({
  type: SAVE_MILESTONE_TRACKER_SUCCESS,
  payload,
})
const saveMilestoneTrackerFailed = (payload) => ({
  type: SAVE_MILESTONE_TRACKER_FAILED,
  payload,
})

export const saveMilestoneTracker =
  ({ payload, projectId, reportId, ocId, milestones }) =>
  async (dispatch, getState) => {
    const { milestoneTracker } = getState().constructionScheduleReport
    dispatch(saveMilestoneTrackerRequested())
    const shouldPost =
      equal(length(milestoneTracker), 0) ||
      equal(length(milestones), 0) ||
      milestones.every(({ id }) => !id)
    const { data, error } = await api({
      endPoint: !shouldPost
        ? `${apiEndPoints.milestoneTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`
        : `${apiEndPoints.milestoneTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: ternary(!shouldPost, method.patch, method.post),
      data: payload,
      showToastMessage: true,
      toastMessage: 'Milestone tracker saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(saveMilestoneTrackerSuccess(data))
      return
    }
    if (error) {
      dispatch(saveMilestoneTrackerFailed(error.response.data))
    }
  }

// Delete Milestone tracker
const deleteMilestoneTrackerRequested = () => ({
  type: DELETE_MILESTONE_TRACKER_REQUESTED,
})
export const deleteMilestoneTrackerSuccess = (payload) => ({
  type: DELETE_MILESTONE_TRACKER_SUCCESS,
  payload,
})
const deleteMilestoneTrackerFailed = (payload) => ({
  type: DELETE_MILESTONE_TRACKER_FAILED,
  payload,
})
export const deleteMilestoneTracker =
  ({ milestoneTrackerToDelete, reportId, ocId, projectId, milestones }) =>
  async (dispatch) => {
    dispatch(deleteMilestoneTrackerRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.milestoneTracker}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: {
        milestoneTracker: milestoneTrackerToDelete,
      },
      showToastMessage: true,
      toastMessage: milestoneDeleteMessage,
    })
    if (status) {
      const milestonesAfterDeletion = milestones?.filter(
        ({ id }) => !checkIncludes(id, milestoneTrackerToDelete),
      )
      dispatch(deleteMilestoneTrackerSuccess(milestonesAfterDeletion))
      return
    }
    if (error) {
      dispatch(deleteMilestoneTrackerFailed(error.response.data))
    }
  }
