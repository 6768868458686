import { Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import JSZip from 'jszip'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTInput from '../../../Shared/DKTInput'
import { useStyles } from '../../../Styles/attachments.style'
import DKTTooltip from '../../../Shared/DKTTooltip'
import { spinnerSize } from '../../../Utils/constant'
import { byteConversion, equal, gte, ternary } from '../../../Utils/javascript'
import AttachmentsTable from './AttachmentsTable'
import PdfUploader from './PdfUploader'
import DKTLabelCircularProgress from '../../../Shared/DKTLabelCircularProgress'
import { CLEAR_ATTACHMENTS } from '../../../Redux/constants/attachments'

const Attachments = ({
  listPdf,
  setListPdf,
  edit,
  setEdit,
  handleDeletePdf,
  uploadPdf,
  setUploadPdf,
  setDragDataDirty,
  handleUploadPdf,
  cancelTokenSource,
  hasOnlyViewPermission = false,
}) => {
  const [downloadId, setDownloadId] = useState(null)
  const [loading, setLoading] = useState(false)
  const { isFetchPdfListLoading, isPdfUploadLoading, uploadProgress } =
    useSelector(({ attachments }) => attachments)

  const classes = useStyles()
  const dispatch = useDispatch()

  // clear attachment data
  useEffect(
    () => () => {
      dispatch({ type: CLEAR_ATTACHMENTS })
    },
    [],
  )

  const handleDownloadPdf = async (pdfUrl) => {
    if (Array.isArray(pdfUrl) && pdfUrl.length > 1) {
      const zip = new JSZip()
      setDownloadId(pdfUrl)
      setLoading(true)
      await Promise.all(
        pdfUrl.map(async (id) => {
          const url = listPdf.find((pdf) => pdf?.id === id)
          const response = await fetch(url.attachmentFile)
          const pdfData = await response.blob()
          zip.file(`${url?.filename}.pdf`, pdfData)
        }),
      )
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(content)
        downloadLink.download = 'multiple_pdfs.zip'
        downloadLink.click()
      })
      setLoading(false)
    } else {
      setDownloadId(pdfUrl[0])
      setLoading(true)
      const url = listPdf.find((pdf) => pdf?.id === pdfUrl[0])
      const response = await fetch(url?.attachmentFile)
      const pdfBlob = await response.blob()

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(pdfBlob)
      downloadLink.download = `${url.filename}.pdf`
      downloadLink.click()

      URL.revokeObjectURL(downloadLink.href)
      setLoading(false)
    }
  }
  const handleChange = (e) => {
    setListPdf((prevData) =>
      prevData.map((item) =>
        item.id === edit.id ? { ...item, filename: e.target.value } : item,
      ),
    )
  }
  const onBlur = () => {
    setEdit({})
  }

  const handleClick = (row) => {
    window.open(row.attachmentFile, '_blank')
  }

  const handleButtonClick = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Request canceled by user')
    }
  }

  const attachmentsColumns = [
    {
      id: 'filename',
      label: 'File Name',
      renderCell: (row) =>
        edit?.id === row.id ? (
          <DKTInput
            value={row.filename}
            onChange={handleChange}
            onBlur={onBlur}
            autoFocus
            className={classes.tdInput}
            disabled={hasOnlyViewPermission}
          />
        ) : (
          <Box className={classes.tdFlex}>
            <DKTButton
              variant="text"
              style={{ backgroundColor: 'transparent', padding: '0' }}
              onClick={() => handleClick(row)}
            >
              {row.filename}
            </DKTButton>
            <EditIcon
              fontSize="small"
              color="primary"
              onClick={() => setEdit(row)}
              cursor="pointer"
              disabled={hasOnlyViewPermission}
            />
          </Box>
        ),
    },
    {
      id: 'modifiedDate',
      label: 'Last Modified',
      renderCell: ({ updatedAt }) => (
        <Box>{moment(updatedAt).format('MM/DD/YY')}</Box>
      ),
    },
    {
      id: 'fileSize',
      label: 'File Size',
      renderCell: ({ fileSize }) => <Box>{byteConversion(fileSize)}</Box>,
    },
    {
      id: 'downloadFile',
      renderCell: ({ id }) =>
        loading && !Array.isArray(downloadId) && downloadId === id ? (
          <DKTCircularProgress size={spinnerSize?.sm} />
        ) : (
          <DKTTooltip title="Download Report">
            <IconButton sx={{ mr: 1 }} onClick={() => handleDownloadPdf([id])}>
              <CloudDownloadIcon />
            </IconButton>
          </DKTTooltip>
        ),
    },
  ]

  const onDragEnd = (result) => {
    if (result?.destination?.index !== result?.source?.index) {
      setDragDataDirty(true)
    }
    if (!result.destination) {
      return
    }

    const newItems = Array.from(listPdf)

    if (
      result.source.index === listPdf.length - 1 &&
      result.destination.index === 0
    ) {
      const [movedItem] = newItems.splice(result.source.index, 1)
      newItems.unshift(movedItem)
    } else {
      const [reorderedItem] = newItems.splice(result.source.index, 1)
      newItems.splice(result.destination.index, 0, reorderedItem)
    }

    setListPdf(newItems)
  }

  const handleRemovePdf = () => {
    setUploadPdf([])
  }

  return equal(isFetchPdfListLoading, true) ? (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: 'calc(100vh - 290px)' }}
    >
      <DKTCircularProgress />
    </Stack>
  ) : equal(isFetchPdfListLoading, 'FAILED') ? (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: 'calc(100vh - 290px)' }}
    >
      <Typography variant="body2" color="gray.extraDark" ml={2}>
        There might be some issue with fetching Attachments data. Please try
        contacting the admin or refreshing this page.
      </Typography>
    </Stack>
  ) : (
    <Grid container item xs={12} lg={10}>
      <Grid container spacing={2} mt={3}>
        <Grid item xs={12} lg={12}>
          <Box component="form" enctype="multipart/form-data">
            <PdfUploader
              {...{ uploadPdf, setUploadPdf, hasOnlyViewPermission }}
            />
            {ternary(
              uploadPdf?.length,
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box mx={2}>
                  {ternary(
                    uploadPdf?.length === 1,
                    `${uploadPdf?.length} PDF uploaded`,
                    `${uploadPdf?.length} PDFs uploaded`,
                  )}
                </Box>
                <IconButton
                  color="error"
                  onClick={handleRemovePdf}
                  disabled={isPdfUploadLoading}
                >
                  <Delete />
                </IconButton>
                <Box sx={{ marginLeft: 'auto' }}>
                  {isPdfUploadLoading && (
                    <DKTButton
                      onClick={handleButtonClick}
                      variant="text"
                      color="primary"
                      disabled={gte(uploadProgress, 90)}
                      sx={{ textTransform: 'initial' }}
                    >
                      Cancel
                    </DKTButton>
                  )}
                  <DKTButton
                    variant="text"
                    color="primary"
                    sx={{ textTransform: 'initial' }}
                    startIcon={
                      isPdfUploadLoading ? (
                        !equal(uploadProgress, 100) ? (
                          <DKTLabelCircularProgress progress={uploadProgress} />
                        ) : (
                          <DKTCircularProgress size={spinnerSize?.sm} />
                        )
                      ) : (
                        <CircledPlusIcon />
                      )
                    }
                    onClick={handleUploadPdf}
                    disabled={isPdfUploadLoading}
                  >
                    {isPdfUploadLoading
                      ? equal(uploadProgress, 100)
                        ? 'Almost Done...'
                        : 'Uploading...'
                      : 'Upload Successful, Add PDF?'}
                  </DKTButton>
                </Box>
              </Stack>,
              null,
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <AttachmentsTable
              columns={attachmentsColumns}
              data={listPdf}
              handleDownloadPdf={handleDownloadPdf}
              isFetchPdfListLoading={isFetchPdfListLoading}
              handleDeletePdf={handleDeletePdf}
              setEdit={setEdit}
              {...{ downloadId, loading, hasOnlyViewPermission }}
            />
          </DragDropContext>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Attachments
