import {
  FETCH_PROJECTS_LIST_FAILED,
  FETCH_PROJECTS_LIST_REQUESTED,
  FETCH_PROJECTS_LIST_SUCCESS,
} from '../constants/clientAccessDashboard'

const initialState = {
  isFetchingClientAccessDashboardProjectsList: false,
  clientName: '',
  clientAccessDashboardProjectsList: [],
  dashboardTilesCounts: {},
  clientAccessDashboardProjectsListError: {},
}

const clientAccessDashboard = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch projects List
    case FETCH_PROJECTS_LIST_REQUESTED:
      return {
        ...state,
        isFetchingClientAccessDashboardProjectsList: true,
        clientAccessDashboardProjectsList: [],
      }
    case FETCH_PROJECTS_LIST_SUCCESS:
      return {
        ...state,
        isFetchingClientAccessDashboardProjectsList: false,
        clientAccessDashboardProjectsList: action.payload?.projectList,
        clientName: action.payload?.clientName,
        dashboardTilesCounts: action.payload.counts,
        clientAccessDashboardProjectsListError: null,
      }
    case FETCH_PROJECTS_LIST_FAILED:
      return {
        ...state,
        isFetchingClientAccessDashboardProjectsList: false,
        clientAccessDashboardProjectsListError: action.payload,
      }
    default:
      return state
  }
}

export default clientAccessDashboard
