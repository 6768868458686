import {
  Box,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'
import {
  formSections,
  initialBuildingAndUnitInfo,
  prefillFields,
  siteAndBuildingChoices,
  optionsField,
} from '../../../Description/siteAndBuilding.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/project'
import { getProjectList } from '../../../Redux/actions/projects'
import { getProjectChoices } from '../../../Redux/actions/choices'
import {
  addSiteAndBuilding,
  createSiteAndBuilding,
  deleteSiteAndBuilding,
  fetchBuildingUnitInformation,
  fetchSiteAndBuilding,
  saveBuildingUnitInformation,
  saveSiteAndBuilding,
  setSiteId,
} from '../../../Redux/actions/siteAndBuilding'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
import {
  checkIncludes,
  entries,
  equal,
  gt,
  keys,
  length,
  lte,
  ternary,
} from '../../../Utils/javascript'
import { renderFormFields } from '../../../Utils/renderFormFields'
import { SA, spinnerSize } from '../../../Utils/constant'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { showToast } from '../../../Utils/toastService'
import BuildingInformation from './BuildingInformation'
import { useStyles } from '../../../Styles/siteAndBuilding.style'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { CLEAR_SITE_AND_BUILDING } from '../../../Redux/constants/siteAndBuilding'
import DKTForm from '../../../Shared/DKTForm'

const clonedInitialBuildingAndUnitInfo = { ...initialBuildingAndUnitInfo }

const allFormFields = entries(formSections).reduce((result, formField) => {
  const formFieldValue = formField[1]
  return {
    ...result,
    ...formFieldValue,
  }
}, {})

const SiteAndBuildings = () => {
  const [isBuildingAndUnitInfoModalOpen, setIsBuildingAndUnitInfoModalOpen] =
    useState(false)
  const [buildingAndUnitInfo, setBuildingAndUnitInfo] = useState([])
  const [hasBuildingAndUnitInfo, setHasBuildingAndUnitInfo] = useState(false)
  const [isDeleteSiteNotificationOpen, setIsDeleteSiteNotificationOpen] =
    useState(false)
  const [buildingInfoTotal, setBuildingInfoTotal] = useState({
    ...clonedInitialBuildingAndUnitInfo,
  })
  const [isSaveWarningOpen, setIsSaveWarningOpen] = useState(false)
  const [buildingId, setBuildingId] = useState({ id: null, add: false })
  const [isHandleChange, setHandleChange] = useState(false)
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    resetForm,
    // isDirty,
    setIsDirty,
    setDummyData,
  } = useForm(allFormFields, CLEAR_SITE_AND_BUILDING)
  const dispatch = useDispatch()
  const { slug: projectId } = useParams()
  const { isProjectListLoading, projectList, currentProject } = useSelector(
    ({ projects }) => projects,
  )
  const classes = useStyles()
  const { choices } = useSelector(({ projectChoices }) => projectChoices)
  const {
    siteAndBuildings,
    buildingAndUnitsInfo,
    isFetchingSiteAndBuilding,
    createSiteAndBuildingError,
    isSavingSiteAndBuilding,
    isSavingBuildingAndUnits,
    isCreatingSiteAndBuilding,
    isDeletingSiteBuilding,
    siteId, // create siteId state
  } = useSelector(({ siteAndBuilding }) => siteAndBuilding)
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)
  const isProjectViewer = useMemo(
    () =>
      equal(systemAuthorization, SA.projectLevel) &&
      projects[+projectId]?.viewer,
    [systemAuthorization, projects, projectId],
  )
  const isGlobalViewer = useMemo(
    () => equal(systemAuthorization, SA.globalViewer),
    [systemAuthorization],
  )
  const hasOnlyViewPermission = isGlobalViewer || isProjectViewer

  useEffect(() => {
    if (isFetchingSiteAndBuilding) {
      setIsDirty(false)
      setHandleChange(false)
    }
  }, [isFetchingSiteAndBuilding])
  // Fetch choices and B&U info
  useEffect(() => {
    dispatch(getProjectChoices({ fieldName: siteAndBuildingChoices.join(',') }))
    dispatch(fetchSiteAndBuilding(projectId))
  }, [dispatch, projectId])
  // Fetch buildingAndUnitsInfo
  useEffect(() => {
    if (!buildingAndUnitsInfo && siteAndBuildings)
      dispatch(fetchBuildingUnitInformation({ siteId, projectId }))
  }, [dispatch, siteId, siteAndBuildings, buildingAndUnitsInfo, projectId])

  // Fetch project summary
  useEffect(() => {
    if (!siteId) {
      resetForm()
      dispatch(getProjectList(projectId))
    }
  }, [dispatch, siteId, resetForm, siteAndBuildings])

  useEffect(() => {
    if (siteAndBuildings) {
      if (lte(siteAndBuildings.length, 0)) {
        dispatch(addSiteAndBuilding())
      } else {
        const site = siteAndBuildings.find(({ id }) => equal(id, +siteId))
        if (!site?.isNewSite && !equal(site?.id, siteId))
          dispatch(setSiteId(siteAndBuildings[0]?.id))
      }
    }
  }, [siteAndBuildings, dispatch])
  // Prefill buildingAndUnitsInfo
  useEffect(() => {
    if (buildingAndUnitsInfo) {
      const { buildingAndUnits, total } = buildingAndUnitsInfo
      setBuildingAndUnitInfo(
        buildingAndUnits || [
          { ...clonedInitialBuildingAndUnitInfo, project: projectId },
        ],
      )
      setBuildingInfoTotal(total || { ...clonedInitialBuildingAndUnitInfo })
    }
  }, [buildingAndUnitsInfo])
  // Prefill fields from S&B data
  useEffect(() => {
    if (!isFetchingSiteAndBuilding && siteAndBuildings) {
      const siteAndBuildingData = siteAndBuildings.find((building) =>
        equal(building.id, +siteId),
      )
      if (siteAndBuildingData) {
        if (siteAndBuildingData.isNewSite) resetForm()
        entries(siteAndBuildingData).forEach(([fieldName, fieldValue]) => {
          if (
            !siteAndBuildingData.isNewSite ||
            (!equal(fieldValue, null) && !equal(fieldValue, ''))
          )
            setFieldValue(fieldName, fieldValue, 'SiteAndBuildings')
        })
      }
    }
  }, [
    setFieldValue,
    resetForm,
    isFetchingSiteAndBuilding,
    siteId,
    siteAndBuildings,
  ])
  // Prefill fields from project summary
  useEffect(() => {
    const siteAndBuildingData =
      siteAndBuildings?.find((building) => equal(building.id, +siteId)) || {}
    if (
      !isProjectListLoading &&
      currentProject &&
      siteAndBuildingData.isNewSite
    ) {
      prefillFields.forEach((fieldName) =>
        setFieldValue(fieldName, currentProject[fieldName], 'SiteAndBuildings'),
      )
    }
  }, [
    setFieldValue,
    isProjectListLoading,
    siteAndBuildings,
    projectList,
    currentProject,
    siteId,
  ])
  useEffect(() => {
    setHasBuildingAndUnitInfo(!equal(length(keys(buildingAndUnitsInfo))))
  }, [buildingAndUnitsInfo, setFieldValue])
  useEffect(() => {
    setFieldValue(
      'residentialBuildings',
      length(buildingAndUnitsInfo?.buildingAndUnits || []) || 0,
      'SiteAndBuildings',
    )
    if (buildingAndUnitsInfo?.total) {
      setFieldValue(
        'totalNumberOfUnits',
        buildingAndUnitsInfo?.total?.totalUnits,
        'SiteAndBuildings',
      )
    }
  }, [
    buildingAndUnitsInfo?.total,
    setFieldValue,
    length(buildingAndUnitsInfo?.buildingAndUnits),
  ])
  // Set errors on save
  useEffect(() => {
    setErrors(() => ({
      ...createSiteAndBuildingError,
    }))
  }, [createSiteAndBuildingError, setErrors])
  // Calculate parking ratio
  useEffect(() => {
    setFieldValue(
      'parkingRatio',
      ternary(
        values.totalNumberOfUnits && values.totalParkingSpaces,
        parseFloat(
          values.totalParkingSpaces / values.totalNumberOfUnits,
        ).toFixed(2),
        '',
      ),
      'SiteAndBuildings',
    )
  }, [values.totalParkingSpaces, values.totalNumberOfUnits, setFieldValue])
  // Calculate total Buildings
  useEffect(() => {
    setFieldValue(
      'totalBuildings',
      +values.residentialBuildings +
        +values.clubhouseBuildings +
        +values.otherBuildings ?? '0',
      'SiteAndBuildings',
    )
  }, [
    values.residentialBuildings,
    values.clubhouseBuildings,
    values.otherBuildings,
    values.totalBuildings,
  ])
  // Calculate total GSF
  useEffect(() => {
    setFieldValue(
      'totalGsf',
      +values.residentialGsf + +values.clubhouseGsf + +values.otherGsf ?? '',
      'SiteAndBuildings',
    )
  }, [
    values.residentialGsf,
    values.clubhouseGsf,
    values.otherGsf,
    values.totalGsf,
    setFieldValue,
  ])
  useEffect(() => {
    if (buildingInfoTotal.totalNumberOfUnits) {
      setFieldValue(
        'totalBuildings',
        buildingInfoTotal.totalNumberOfUnits,
        'SiteAndBuildings',
      )
      setFieldValue(
        'residentialBuildings',
        buildingInfoTotal.totalNumberOfUnits,
        'SiteAndBuildings',
      )
    }
  }, [buildingInfoTotal])

  const closeDeleteSiteModal = () => setIsDeleteSiteNotificationOpen(false)
  const saveBuildingAndUnitInfo = () => {
    dispatch(
      saveBuildingUnitInformation(
        buildingAndUnitInfo,
        siteId,
        buildingInfoTotal,
        projectId,
      ),
    )
  }
  const handleSave = useCallback(() => {
    const isFormValid = handleSubmit()
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
    } else {
      setIsDirty(false)
      setHandleChange(false)
      dispatch(
        ternary(
          siteId,
          saveSiteAndBuilding({ id: siteId, data: values, projectId }),
          createSiteAndBuilding(values, projectId),
        ),
      )
    }
  }, [dispatch, handleSubmit, projectId, siteId, values])

  useEffect(() => {
    if (buildingId?.id) {
      if (isHandleChange) {
        setIsSaveWarningOpen(true)
      } else {
        resetForm()
        setTimeout(() => {
          dispatch(setSiteId(buildingId?.id))
        }, 500)
        setErrors({})
      }
    } else if (buildingId?.add) {
      if (isHandleChange) {
        setIsSaveWarningOpen(true)
      } else {
        dispatch(addSiteAndBuilding())
        resetForm()
        setDummyData({})
      }
    }
  }, [buildingId])

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setBuildingId({ id: null, add: false })
  }
  const confirmSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    if (buildingId?.id) {
      dispatch(setSiteId(buildingId?.id))
    }
    setIsDirty(false)
    setHandleChange(false)
    setErrors({})
    if (buildingId?.add) {
      dispatch(addSiteAndBuilding())
      resetForm()
    }
    setBuildingId({ id: null, add: false })
  }
  const customFormControlFields = useCallback(
    ({ name, formControl }) => ({
      disabled:
        hasOnlyViewPermission ||
        ternary(
          equal(name, 'constructionType'),
          !equal(values.constructionType, 'NR'),
          checkIncludes(name, ['totalNumberOfUnits', 'residentialBuildings'])
            ? ternary(
                buildingAndUnitInfo.length === 0,
                false,
                hasBuildingAndUnitInfo,
              )
            : formControl.disabled,
        ),
      ...ternary(
        choices[name],
        { [optionsField[formControl.type]]: choices?.[name] },
        {},
      ),
    }),
    [
      buildingAndUnitInfo.length,
      choices,
      hasBuildingAndUnitInfo,
      hasOnlyViewPermission,
      values.constructionType,
    ],
  )

  const buildingAndUnitInfoActions = (
    <DKTButton
      variant="text"
      disableRipple
      sx={{ paddingLeft: 0, paddingRight: 0 }}
      onClick={() => setIsBuildingAndUnitInfoModalOpen(true)}
    >
      Building &amp; Unit Info
    </DKTButton>
  )
  const addBuildingAndUnitInfoRow = () => {
    if (!hasOnlyViewPermission)
      setBuildingAndUnitInfo((prevBuildingAndUnitInfo) => [
        ...prevBuildingAndUnitInfo,
        { ...clonedInitialBuildingAndUnitInfo, project: projectId },
      ])
  }

  const handleFieldChange = (...rest) => {
    handleChange(...rest)
    setHandleChange(true)
  }

  const renderFormFieldsWithOptions = useCallback(
    (formFieldsToRender) =>
      renderFormFields({
        errors,
        values,
        handleChange: handleFieldChange,
        customFormControlFields,
        formFields: formFieldsToRender,
      }),
    [customFormControlFields, errors, handleFieldChange, values],
  )
  const handleAddSite = useCallback(() => {
    setBuildingId({ ...buildingId, add: true })
  }, [dispatch, resetForm])
  const actionList = useMemo(
    () => [
      { label: 'Delete', onClick: () => setIsDeleteSiteNotificationOpen(true) },
    ],
    [],
  )
  const actions = useCallback(
    () => (
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Stack direction="row" gap={2} className={classes.rightButton}>
          {ternary(
            !hasOnlyViewPermission,
            <DKTButtonSelect
              options={actionList}
              disabled={isFetchingSiteAndBuilding || true}
            />,
            null,
          )}
          <DKTButton
            className={classes.rightButton}
            onClick={handleSave}
            disabled={
              hasOnlyViewPermission ||
              isSavingSiteAndBuilding ||
              isCreatingSiteAndBuilding ||
              isFetchingSiteAndBuilding
            }
          >
            {ternary(
              isSavingSiteAndBuilding || isCreatingSiteAndBuilding,
              'Saving...',
              'Save',
            )}
          </DKTButton>
        </Stack>
      </Box>
    ),
    [
      actionList,
      classes.addBtn,
      classes.rightButton,
      dispatch,
      handleAddSite,
      handleSave,
      hasOnlyViewPermission,
      isCreatingSiteAndBuilding,
      isSavingSiteAndBuilding,
      siteAndBuildings,
      siteId,
    ],
  )
  const handleDeleteSiteBuilding = () => {
    const body = {
      siteAndBuilding: [siteId],
    }
    if (gt(siteAndBuildings?.length, 1)) {
      dispatch(deleteSiteAndBuilding(body, closeDeleteSiteModal))
    } else {
      showToast("You can't delete this record.", 'error')
      closeDeleteSiteModal()
    }
  }
  const deleteSiteNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteSiteModal}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteSiteBuilding}>
        {ternary(
          isDeletingSiteBuilding,
          <DKTCircularProgress size={spinnerSize?.sm} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const activeSite = siteAndBuildings?.find((site) => equal(site?.id, siteId))

  const siteAddress = renderFormFieldsWithOptions(formSections.siteAddress)
  const developmentInformation = renderFormFieldsWithOptions(
    formSections.developmentInformation,
  )
  const siteInformation = renderFormFieldsWithOptions(
    formSections.siteInformation,
  )
  const amenities = renderFormFieldsWithOptions(formSections.amenities)
  const buildingStyle = renderFormFieldsWithOptions(formSections.buildingStyle)
  const buildingAndUnitInfoForm = renderFormFieldsWithOptions(
    formSections.buildingAndUnitInfo,
  )
  const unitInformation = renderFormFieldsWithOptions(
    formSections.unitInformation,
  )
  const utilityInformation = renderFormFieldsWithOptions(
    formSections.utilityInformation,
  )
  const otherUtilities = renderFormFieldsWithOptions(
    formSections.otherUtilities,
  )
  const securityFeatures = renderFormFieldsWithOptions(
    formSections.securityFeatures,
  )
  const greenBuildingCertification = renderFormFieldsWithOptions(
    formSections.greenBuildingCertification,
  )
  const comments = renderFormFieldsWithOptions({
    comments: {
      ...formSections.comments.comments,
    },
  })

  return (
    <InnerLayout
      contentTitle="Site and Building"
      actions={actions}
      maxWidth="md"
      title={currentProject?.projectName || 'New Project'}
      isShowMenu
    >
      {equal(isProjectListLoading, true) ||
      equal(isFetchingSiteAndBuilding, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isProjectListLoading, 'FAILED') ||
        equal(isFetchingSiteAndBuilding, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Site and Building data.
            Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12} xl={8}>
              <Grid
                container
                columnSpacing={{ sm: 4, md: 6, lg: 10 }}
                columnGap={{ sm: 0, xl: 9 }}
              >
                <Grid item lg={5} sm={6}>
                  <Grid container>
                    <Grid item>
                      <FormSectionDivider sectionTitle="Site Address" />
                      <DKTForm autoComplete="off">
                        <Grid container spacing={2}>
                          {siteAddress}
                        </Grid>
                      </DKTForm>
                    </Grid>
                    <Grid item xs={12}>
                      <FormSectionDivider sectionTitle="Development Information" />
                      <DKTForm autoComplete="off">
                        <Grid container spacing={2}>
                          {developmentInformation}
                        </Grid>
                      </DKTForm>
                    </Grid>
                    <Grid item>
                      <FormSectionDivider sectionTitle="Site Information" />
                      <DKTForm autoComplete="off">
                        <Grid container spacing={2}>
                          {siteInformation}
                        </Grid>
                      </DKTForm>
                    </Grid>
                    <Grid item>
                      <FormSectionDivider sectionTitle="Amenities" />
                      <Grid container spacing={2}>
                        {amenities}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item>
                      <FormSectionDivider sectionTitle="Building Style" />
                      <DKTForm autoComplete="off">
                        <Grid
                          container
                          spacing={2}
                          columnSpacing={{ sm: 2, xl: 10 }}
                        >
                          {buildingStyle}
                        </Grid>
                      </DKTForm>
                    </Grid>
                    <Grid item>
                      <FormSectionDivider
                        sectionTitle="Building Information"
                        rightSection={buildingAndUnitInfoActions}
                      />
                      <DKTForm autoComplete="off">
                        <Grid
                          container
                          spacing={2}
                          columnSpacing={{ sm: 2, xl: 10 }}
                        >
                          {buildingAndUnitInfoForm}
                        </Grid>
                      </DKTForm>
                    </Grid>
                    <Grid item>
                      <FormSectionDivider sectionTitle="Unit Information" />
                      <DKTForm autoComplete="off">
                        <Grid
                          container
                          spacing={2}
                          columnSpacing={{ sm: 2, xl: 10 }}
                          justifyContent="flex-end"
                        >
                          {unitInformation}
                        </Grid>
                      </DKTForm>
                    </Grid>
                    <Grid item>
                      <FormSectionDivider sectionTitle="Utility Information" />
                      <Grid container spacing={1}>
                        {utilityInformation}
                        <Grid item md={12}>
                          <InputLabel className={classes.inlineLabel}>
                            Other Utility
                          </InputLabel>
                        </Grid>
                        {otherUtilities}
                      </Grid>
                    </Grid>
                    <Grid item>
                      <FormSectionDivider sectionTitle="Security Features" />
                      <Grid container spacing={2}>
                        {securityFeatures}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <FormSectionDivider sectionTitle="Green Building Certification" />
                      <Grid container spacing={2}>
                        {greenBuildingCertification}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={11}>
                  <Box mt={3}>
                    <Typography
                      variant="body2"
                      color="gray.extraDark"
                      sx={{ fontWeight: 'medium' }}
                    >
                      Comments
                    </Typography>
                    {comments}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isBuildingAndUnitInfoModalOpen && (
            <BuildingInformation
              addBuildingAndUnitInfoRow={addBuildingAndUnitInfoRow}
              setBuildingInfoTotal={setBuildingInfoTotal}
              setBuildingAndUnitInfo={setBuildingAndUnitInfo}
              isOpen={isBuildingAndUnitInfoModalOpen}
              saveBuildingAndUnitInfo={saveBuildingAndUnitInfo}
              onClose={() => setIsBuildingAndUnitInfoModalOpen(false)}
              hasOnlyViewPermission={hasOnlyViewPermission}
              isSavingBuildingAndUnits={isSavingBuildingAndUnits}
              buildingInfoTotal={buildingInfoTotal}
              buildingAndUnitInfo={buildingAndUnitInfo}
            />
          )}
        </>
      )}
      <DKTDialog
        open={isDeleteSiteNotificationOpen}
        handleClose={closeDeleteSiteModal}
        title="&nbsp;"
        actions={deleteSiteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                {`Are you sure you want to delete ${activeSite?.uniqueCode}?`}
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isSaveWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isHandleChange} onSave={handleSave} />
    </InnerLayout>
  )
}

const FormSectionDivider = ({ sectionTitle, rightSection, ...rest }) => (
  <>
    <Stack
      {...rest}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      mt={3}
    >
      <Typography
        variant="body2"
        color="gray.extraDark"
        sx={{ fontWeight: 'medium' }}
      >
        {sectionTitle}
      </Typography>
      {rightSection}
    </Stack>
    <Divider sx={{ mb: 1 }} />
  </>
)
export default SiteAndBuildings
