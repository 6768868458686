import { g703InitialTotal } from '../../Description/trackers.description'
import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { checkIncludes, equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_G702_SPLIT_TRACKER,
  DELETE_CO_TRACKER_FAILED,
  DELETE_CO_TRACKER_REQUESTED,
  DELETE_CO_TRACKER_SUCCESS,
  DELETE_G702_SPLIT_TRACKER_FAILED,
  DELETE_G702_SPLIT_TRACKER_REQUESTED,
  DELETE_G702_SPLIT_TRACKER_SUCCESS,
  DELETE_G703_TRACKER_FAILED,
  DELETE_G703_TRACKER_REQUESTED,
  DELETE_G703_TRACKER_SUCCESS,
  DELETE_LIEN_WAIVERS_FAILED,
  DELETE_LIEN_WAIVERS_REQUESTED,
  DELETE_LIEN_WAIVERS_SUCCESS,
  DELETE_PCO_TRACKER_FAILED,
  DELETE_PCO_TRACKER_REQUESTED,
  DELETE_PCO_TRACKER_SUCCESS,
  DELETE_STORED_MATERIAL_FAILED,
  DELETE_STORED_MATERIAL_REQUESTED,
  DELETE_STORED_MATERIAL_SUCCESS,
  FETCH_CO_TRACKER_FAILED,
  FETCH_CO_TRACKER_REQUESTED,
  FETCH_CO_TRACKER_SUCCESS,
  FETCH_G702_SPLIT_TRACKER_FAILED,
  FETCH_G702_SPLIT_TRACKER_REQUESTED,
  FETCH_G702_SPLIT_TRACKER_SUCCESS,
  FETCH_G703_TRACKER_FAILED,
  FETCH_G703_TRACKER_REQUESTED,
  FETCH_G703_TRACKER_SUCCESS,
  FETCH_LIEN_WAIVERS_FAILED,
  FETCH_LIEN_WAIVERS_REQUESTED,
  FETCH_LIEN_WAIVERS_SUCCESS,
  FETCH_PCO_TRACKER_FAILED,
  FETCH_PCO_TRACKER_REQUESTED,
  FETCH_PCO_TRACKER_SUCCESS,
  FETCH_REPORT_G702_SPLIT_TRACKER_FAILED,
  FETCH_REPORT_G702_SPLIT_TRACKER_REQUESTED,
  FETCH_REPORT_G702_SPLIT_TRACKER_SUCCESS,
  FETCH_STORED_MATERIAL_FAILED,
  FETCH_STORED_MATERIAL_REQUESTED,
  FETCH_STORED_MATERIAL_SUCCESS,
  SAVE_CO_TRACKER_FAILED,
  SAVE_CO_TRACKER_REQUESTED,
  SAVE_CO_TRACKER_SUCCESS,
  SAVE_G702_SPLIT_TRACKER_FAILED,
  SAVE_G702_SPLIT_TRACKER_REQUESTED,
  SAVE_G702_SPLIT_TRACKER_SUCCESS,
  SAVE_G703_TRACKER_FAILED,
  SAVE_G703_TRACKER_REQUESTED,
  SAVE_G703_TRACKER_SUCCESS,
  SAVE_LIEN_WAIVERS_FAILED,
  SAVE_LIEN_WAIVERS_REQUESTED,
  SAVE_LIEN_WAIVERS_SUCCESS,
  SAVE_PCO_TRACKER_FAILED,
  SAVE_PCO_TRACKER_REQUESTED,
  SAVE_PCO_TRACKER_SUCCESS,
  SAVE_STORED_MATERIAL_FAILED,
  SAVE_STORED_MATERIAL_REQUESTED,
  SAVE_STORED_MATERIAL_SUCCESS,
  UPDATE_CO_TRACKER_FAILED,
  UPDATE_CO_TRACKER_REQUESTED,
  UPDATE_CO_TRACKER_SUCCESS,
  UPDATE_PCO_TRACKER_FAILED,
  UPDATE_PCO_TRACKER_REQUESTED,
  UPDATE_PCO_TRACKER_SUCCESS,
} from '../constants/reportTrackers'
import { updateSaveStatusAction } from './confirmation'

// Fetch G703 tracker
const fetchG703TrackerRequested = () => ({
  type: FETCH_G703_TRACKER_REQUESTED,
})
const fetchG703TrackerSuccess = (payload) => ({
  type: FETCH_G703_TRACKER_SUCCESS,
  payload,
})
const fetchG703TrackerFailed = (payload) => ({
  type: FETCH_G703_TRACKER_FAILED,
  payload,
})

export const getG703Tracker =
  ({ reportId, ocId, projectId, g703 }) =>
  async (dispatch) => {
    dispatch(fetchG703TrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.g703Tracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      const payload = ternary(data.G703Trackers, data, null)
      dispatch(fetchG703TrackerSuccess(payload))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(
          fetchG703TrackerSuccess(
            g703
              ? { G703Trackers: g703, total: getAllColumnsTotal(g703) }
              : null,
          ),
        )
        // for show last report data on G703Tracker
        // dispatch(getLastG703Tracker({ reportId, ocId, projectId, g703 }))
      }
      dispatch(fetchG703TrackerFailed(error.response))
    }
  }
// Fetch last G703 tracker
export const getLastG703Tracker =
  ({ reportId, ocId, projectId, g703 }) =>
  async (dispatch) => {
    dispatch(fetchG703TrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.g703Tracker}last-report/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      const payloadData = ternary(data.G703Trackers, data, null)
      dispatch(fetchG703TrackerSuccess(payloadData))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(
          fetchG703TrackerSuccess(
            g703
              ? { G703Trackers: g703, total: getAllColumnsTotal(g703) }
              : null,
          ),
        )
      }
      dispatch(fetchG703TrackerFailed(error.response.data))
    }
  }

const add = (...numbers) =>
  numbers.reduce((total, number = 0) => total + +number, 0)

const getAllColumnsTotal = (array) => {
  const totalObj = {
    ...(array?.reduce(
      (total, rowData) => ({
        original: add(rowData.original, total.original),
        current: add(rowData.current, total.current),
        thisPeriod: add(rowData.thisPeriod, total.thisPeriod),
        recommended: add(rowData.recommended, total.recommended),
        variance: add(
          +rowData.thisPeriod - +rowData.recommended,
          +total.variance,
        ),
        completedStored: add(rowData.completedStored, total.completedStored),
      }),
      g703InitialTotal,
    ) || g703InitialTotal),
  }
  return {
    ...totalObj,
    da: +(
      ((+totalObj.completedStored || 0) / (+totalObj.current || 0)) *
      100
    ).toFixed(2),
  }
}

// Save G703 tracker
const saveG703TrackerRequested = () => ({
  type: SAVE_G703_TRACKER_REQUESTED,
})
const saveG703TrackerSuccess = (payload) => ({
  type: SAVE_G703_TRACKER_SUCCESS,
  payload,
})
const saveG703TrackerFailed = (payload) => ({
  type: SAVE_G703_TRACKER_FAILED,
  payload,
})

export const saveG703Tracker =
  (payload, reportId, ocId, projectId) => async (dispatch, getState) => {
    dispatch(saveG703TrackerRequested())
    const { g703Tracker } = getState().reportTrackers
    const { data, error } = await api({
      endPoint: `${apiEndPoints.g703Tracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: ternary(g703Tracker, method.patch, method.post),
      data: payload,
      showToastMessage: true,
      toastMessage: 'G703 tracker saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        saveG703TrackerSuccess({
          ...g703Tracker,
          G703Trackers: data.sort((a, b) => +a.rowNumber - +b.rowNumber),
          total: getAllColumnsTotal(data),
        }),
      )
      return
    }
    if (error) {
      dispatch(saveG703TrackerFailed(error.response.data))
    }
  }

// Delete G703 tracker
const deleteG703TrackersRequested = () => ({
  type: DELETE_G703_TRACKER_REQUESTED,
})
export const deleteG703TrackersSuccess = (payload) => ({
  type: DELETE_G703_TRACKER_SUCCESS,
  payload,
})
const deleteG703TrackersFailed = (payload) => ({
  type: DELETE_G703_TRACKER_FAILED,
  payload,
})
export const deleteG703Trackers =
  ({ g703TrackersToDelete, reportId, ocId, g703, uniqueCode, projectId }) =>
  async (dispatch, getState) => {
    dispatch(deleteG703TrackersRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.g703Tracker}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: {
        gTracker: g703TrackersToDelete,
      },
      showToastMessage: true,
      toastMessage: 'G703 tracker deleted successfully!',
    })
    if (status) {
      const { g703Tracker } = getState().reportTrackers
      const g703TrackerAfterDeletion = g703.filter(
        ({ id, uniqueId }) =>
          !checkIncludes(id ?? uniqueId, g703TrackersToDelete),
      )
      const shouldFetch = equal(
        g703Tracker?.G703Trackers?.filter(
          ({ id }) => !checkIncludes(id, g703TrackersToDelete),
        ).length,
        0,
      )
      dispatch(
        deleteG703TrackersSuccess({
          ...g703Tracker,
          G703Trackers: g703TrackerAfterDeletion,
          total: getAllColumnsTotal(g703TrackerAfterDeletion),
        }),
      )
      if (shouldFetch)
        dispatch(
          getG703Tracker({
            uniqueCode,
            reportId,
            ocId,
            projectId,
            g703: g703TrackerAfterDeletion,
          }),
        )
      return
    }
    if (error) {
      dispatch(deleteG703TrackersFailed(error.response.data))
    }
  }

/* * * * * * * * * * * * * * * * * *
  Lien waivers tracker
* * * * * * * * * * * * * * * * * */
// Fetch Lien Waivers tracker
const fetchLienWaiversTrackerRequested = () => ({
  type: FETCH_LIEN_WAIVERS_REQUESTED,
})
const fetchLienWaiversTrackerSuccess = (payload) => ({
  type: FETCH_LIEN_WAIVERS_SUCCESS,
  payload,
})
const fetchLienWaiversTrackerFailed = (payload) => ({
  type: FETCH_LIEN_WAIVERS_FAILED,
  payload,
})

export const getLienWaiversTracker =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchLienWaiversTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.lienWaiversTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      dispatch(fetchLienWaiversTrackerSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchLienWaiversTrackerSuccess(null))
        // for show last report data on LienWaiversTracker
        // dispatch(getLastLienWaiversTracker({ reportId, ocId, projectId }))
      }
      dispatch(fetchLienWaiversTrackerFailed(error.response))
    }
  }
// Fetch last lien waivers tracker
export const getLastLienWaiversTracker =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchLienWaiversTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.lienWaiversTracker}last-report/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      dispatch(fetchLienWaiversTrackerSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchLienWaiversTrackerSuccess(null))
      }
      dispatch(fetchLienWaiversTrackerFailed(error.response.data))
    }
  }

// Save Lien Waivers tracker
const saveLienWaiversTrackerRequested = () => ({
  type: SAVE_LIEN_WAIVERS_REQUESTED,
})
const saveLienWaiversTrackerSuccess = (payload) => ({
  type: SAVE_LIEN_WAIVERS_SUCCESS,
  payload,
})
const saveLienWaiversTrackerFailed = (payload) => ({
  type: SAVE_LIEN_WAIVERS_FAILED,
  payload,
})

export const saveLienWaiversTracker =
  (payload, projectId, reportId, ocId) => async (dispatch, getState) => {
    dispatch(saveLienWaiversTrackerRequested())
    const { lienWaiversTracker } = getState().reportTrackers
    const { data, error } = await api({
      endPoint: `${apiEndPoints.lienWaiversTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: ternary(lienWaiversTracker, method.patch, method.post),
      data: payload,
      showToastMessage: true,
      toastMessage: 'Lien waivers tracker saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(saveLienWaiversTrackerSuccess(data))
      return
    }
    if (error) {
      dispatch(saveLienWaiversTrackerFailed(error.response.data))
    }
  }

// Delete Lien Waivers tracker
const deleteLienWaiversTrackersRequested = () => ({
  type: DELETE_LIEN_WAIVERS_REQUESTED,
})
export const deleteLienWaiversTrackersSuccess = (payload) => ({
  type: DELETE_LIEN_WAIVERS_SUCCESS,
  payload,
})
const deleteLienWaiversTrackersFailed = (payload) => ({
  type: DELETE_LIEN_WAIVERS_FAILED,
  payload,
})
export const deleteLienWaiversTrackers =
  ({ lienWaiversTrackersToDelete, reportId, ocId, tableData, projectId }) =>
  async (dispatch) => {
    dispatch(deleteLienWaiversTrackersRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.lienWaiversTracker}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: {
        lienWaiverTracker: lienWaiversTrackersToDelete,
      },
      showToastMessage: true,
      toastMessage: 'Lien waivers deleted successfully!',
    })
    if (status) {
      const lienWaiversAfterDeletion = tableData?.filter(
        ({ id, uniqueId }) =>
          !checkIncludes(id ?? uniqueId, lienWaiversTrackersToDelete),
      )
      dispatch(
        deleteLienWaiversTrackersSuccess(
          ternary(
            lienWaiversAfterDeletion?.length,
            lienWaiversAfterDeletion,
            null,
          ),
        ),
      )
      return
    }
    if (error) {
      dispatch(deleteLienWaiversTrackersFailed(error.response.data))
    }
  }

/* * * * * * * * * * * * * * * * * *
  Stored material tracker
* * * * * * * * * * * * * * * * * */
// Fetch stored material tracker
const fetchStoredMaterialTrackerRequested = () => ({
  type: FETCH_STORED_MATERIAL_REQUESTED,
})
const fetchStoredMaterialTrackerSuccess = (payload) => ({
  type: FETCH_STORED_MATERIAL_SUCCESS,
  payload,
})
const fetchStoredMaterialTrackerFailed = (payload) => ({
  type: FETCH_STORED_MATERIAL_FAILED,
  payload,
})

export const getStoredMaterialTracker =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchStoredMaterialTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.storedMaterialTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      dispatch(fetchStoredMaterialTrackerSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchStoredMaterialTrackerSuccess(null))
        // for show last report data on StoredMaterialTracker
        // dispatch(getLastStoredMaterialTracker({ reportId, ocId, projectId }))
      }
      dispatch(fetchStoredMaterialTrackerFailed(error.response))
    }
  }

export const getLastStoredMaterialTracker =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchStoredMaterialTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.storedMaterialTracker}last-report/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      dispatch(fetchStoredMaterialTrackerSuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchStoredMaterialTrackerSuccess(null))
      }
      dispatch(fetchStoredMaterialTrackerFailed(error.response.data))
    }
  }

// Save stored material tracker
const saveStoredMaterialTrackerRequested = () => ({
  type: SAVE_STORED_MATERIAL_REQUESTED,
})
const saveStoredMaterialTrackerSuccess = (payload) => ({
  type: SAVE_STORED_MATERIAL_SUCCESS,
  payload,
})
const saveStoredMaterialTrackerFailed = (payload) => ({
  type: SAVE_STORED_MATERIAL_FAILED,
  payload,
})

export const saveStoredMaterialTracker =
  (payload, projectId, reportId, ocId, dataTotal) =>
  async (dispatch, getState) => {
    dispatch(saveStoredMaterialTrackerRequested())
    const { storedMaterialTracker } = getState().reportTrackers
    const { data, error } = await api({
      endPoint: `${apiEndPoints.storedMaterialTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: ternary(
        storedMaterialTracker?.data?.length,
        method.patch,
        method.post,
      ),
      data: payload,
      showToastMessage: true,
      toastMessage: 'Stored material tracker saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        saveStoredMaterialTrackerSuccess({
          data,
          total: dataTotal,
        }),
      )
      return
    }
    if (error) {
      dispatch(saveStoredMaterialTrackerFailed(error.response.data))
    }
  }

// Delete stored material tracker
const deleteStoredMaterialTrackersRequested = () => ({
  type: DELETE_STORED_MATERIAL_REQUESTED,
})
export const deleteStoredMaterialTrackersSuccess = (payload) => ({
  type: DELETE_STORED_MATERIAL_SUCCESS,
  payload,
})
const deleteStoredMaterialTrackersFailed = (payload) => ({
  type: DELETE_STORED_MATERIAL_FAILED,
  payload,
})
export const deleteStoredMaterialTrackers =
  ({ storedMaterialTrackersToDelete, reportId, ocId, tableData, projectId }) =>
  async (dispatch) => {
    dispatch(deleteStoredMaterialTrackersRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.storedMaterialTracker}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: {
        storedMaterialTracker: storedMaterialTrackersToDelete,
      },
      showToastMessage: true,
      toastMessage: 'Stored material deleted successfully!',
    })
    if (status) {
      const storedMaterialAfterDeletion = tableData?.filter(
        ({ id, uniqueId }) =>
          !checkIncludes(id ?? uniqueId, storedMaterialTrackersToDelete),
      )
      dispatch(
        deleteStoredMaterialTrackersSuccess({
          data: ternary(
            storedMaterialAfterDeletion?.length,
            storedMaterialAfterDeletion,
            null,
          ),
          total: {
            amount: storedMaterialAfterDeletion?.reduce(
              (total, rowData) => total + +rowData.amount || 0,
              0,
            ),
          },
        }),
      )
      return
    }
    if (error) {
      dispatch(deleteStoredMaterialTrackersFailed(error.response.data))
    }
  }

/* CO tracker */
// Fetch CO tracker
const fetchCOTrackerRequested = () => ({
  type: FETCH_CO_TRACKER_REQUESTED,
})
const fetchCOTrackerSuccess = (payload) => ({
  type: FETCH_CO_TRACKER_SUCCESS,
  payload,
})
const fetchCOTrackerFailed = (payload) => ({
  type: FETCH_CO_TRACKER_FAILED,
  payload,
})

export const getCOTracker = (reportId, ocId, projectId) => async (dispatch) => {
  dispatch(fetchCOTrackerRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.coTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
    method: method.get,
  })
  if (data) {
    const payloadData = ternary(data.changeOrderTrackers, data, null)
    dispatch(fetchCOTrackerSuccess(payloadData))
  }
  if (error) {
    if (equal(error?.response?.status, 404)) {
      dispatch(fetchCOTrackerSuccess(null))
      // for show last report data on COTracker
      // dispatch(getLastCOTracker({ reportId, ocId, projectId }))
    }
    dispatch(fetchCOTrackerFailed(error.response))
  }
}
// Fetch last CO tracker
export const getLastCOTracker =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchCOTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.coTracker}last-report/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      const payloadData = ternary(data.changeOrderTrackers, data, null)
      dispatch(fetchCOTrackerSuccess(payloadData))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchCOTrackerSuccess(null))
      }
      dispatch(fetchCOTrackerFailed(error.response.data))
    }
  }

// Save CO tracker
const saveCOTrackerRequested = () => ({
  type: SAVE_CO_TRACKER_REQUESTED,
})
const saveCOTrackerSuccess = (payload) => ({
  type: SAVE_CO_TRACKER_SUCCESS,
  payload,
})
const saveCOTrackerFailed = (payload) => ({
  type: SAVE_CO_TRACKER_FAILED,
  payload,
})

export const saveCOTracker =
  (payload, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(saveCOTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.coTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Change order tracker saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        saveCOTrackerSuccess({
          changeOrderTrackers: data,
        }),
      )
      return
    }
    if (error) {
      dispatch(saveCOTrackerFailed(error.response.data))
    }
  }
// Update CO tracker
const updateCOTrackerRequested = () => ({
  type: UPDATE_CO_TRACKER_REQUESTED,
})
const updateCOTrackerSuccess = (payload) => ({
  type: UPDATE_CO_TRACKER_SUCCESS,
  payload,
})
const updateCOTrackerFailed = (payload) => ({
  type: UPDATE_CO_TRACKER_FAILED,
  payload,
})

export const updateCOTracker =
  (payload, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(updateCOTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.coTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.patch,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Change order tracker updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        updateCOTrackerSuccess({
          changeOrderTrackers: data,
        }),
      )
      return
    }
    if (error) {
      dispatch(updateCOTrackerFailed(error.response.data))
    }
  }
// Delete CO tracker
const deleteCOTrackersRequested = () => ({
  type: DELETE_CO_TRACKER_REQUESTED,
})
export const deleteCOTrackersSuccess = (payload) => ({
  type: DELETE_CO_TRACKER_SUCCESS,
  payload,
})
const deleteCOTrackersFailed = (payload) => ({
  type: DELETE_CO_TRACKER_FAILED,
  payload,
})
export const deleteCOTrackers =
  (body, reportId, ocId, handleDeleteSuccess, projectId) =>
  async (dispatch, getState) => {
    dispatch(deleteCOTrackersRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.coTracker}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: body,
      showToastMessage: true,
      toastMessage: 'Deleted successfully!',
    })
    if (status) {
      const { coTracker } = getState().reportTrackers
      const coTrackerAfterDeletion = coTracker?.changeOrderTrackers?.filter(
        ({ id }) => !checkIncludes(id, body?.coTracker),
      )
      if (coTrackerAfterDeletion?.length) {
        dispatch(
          deleteCOTrackersSuccess({
            changeOrderTrackers: coTrackerAfterDeletion,
          }),
        )
      } else {
        dispatch(deleteCOTrackersSuccess(null))
      }
      handleDeleteSuccess()
      return
    }
    if (error) {
      dispatch(deleteCOTrackersFailed(error.response.data))
    }
  }

/* PCO tracker */
// Fetch PCO tracker
const fetchPCOTrackerRequested = () => ({
  type: FETCH_PCO_TRACKER_REQUESTED,
})
const fetchPCOTrackerSuccess = (payload) => ({
  type: FETCH_PCO_TRACKER_SUCCESS,
  payload,
})
const fetchPCOTrackerFailed = (payload) => ({
  type: FETCH_PCO_TRACKER_FAILED,
  payload,
})

export const getPCOTracker =
  (reportId, ocId, projectId) => async (dispatch) => {
    dispatch(fetchPCOTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.pcoTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      const payloadData = ternary(data.changeOrderTrackers, data, null)
      dispatch(fetchPCOTrackerSuccess(payloadData))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchPCOTrackerSuccess(null))
        // for show last report data on PCOTracker
        // dispatch(getLastPCOTracker({ reportId, ocId, projectId }))
      }
      dispatch(fetchPCOTrackerFailed(error.response))
    }
  }
// Fetch last PCO tracker
export const getLastPCOTracker =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchPCOTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.pcoTracker}last-report/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      const payloadData = ternary(data.changeOrderTrackers, data, null)
      dispatch(fetchPCOTrackerSuccess(payloadData))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchPCOTrackerSuccess(null))
      }
      dispatch(fetchPCOTrackerFailed(error.response.data))
    }
  }

// Save PCO tracker
const savePCOTrackerRequested = () => ({
  type: SAVE_PCO_TRACKER_REQUESTED,
})
const savePCOTrackerSuccess = (payload) => ({
  type: SAVE_PCO_TRACKER_SUCCESS,
  payload,
})
const savePCOTrackerFailed = (payload) => ({
  type: SAVE_PCO_TRACKER_FAILED,
  payload,
})

export const savePCOTracker =
  (payload, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(savePCOTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.pcoTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Potential change order tracker saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        savePCOTrackerSuccess({
          changeOrderTrackers: data,
        }),
      )
      return
    }
    if (error) {
      dispatch(savePCOTrackerFailed(error.response.data))
    }
  }
// Update PCO tracker
const updatePCOTrackerRequested = () => ({
  type: UPDATE_PCO_TRACKER_REQUESTED,
})
const updatePCOTrackerSuccess = (payload) => ({
  type: UPDATE_PCO_TRACKER_SUCCESS,
  payload,
})
const updatePCOTrackerFailed = (payload) => ({
  type: UPDATE_PCO_TRACKER_FAILED,
  payload,
})

export const updatePCOTracker =
  (payload, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(updatePCOTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.pcoTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.patch,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Potential change order tracker updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        updatePCOTrackerSuccess({
          changeOrderTrackers: data,
        }),
      )
      return
    }
    if (error) {
      dispatch(updatePCOTrackerFailed(error.response.data))
    }
  }
// Delete PCO tracker
const deletePCOTrackersRequested = () => ({
  type: DELETE_PCO_TRACKER_REQUESTED,
})
export const deletePCOTrackersSuccess = (payload) => ({
  type: DELETE_PCO_TRACKER_SUCCESS,
  payload,
})
const deletePCOTrackersFailed = (payload) => ({
  type: DELETE_PCO_TRACKER_FAILED,
  payload,
})
export const deletePCOTrackers =
  (body, reportId, ocId, handleDeleteSuccess, projectId) =>
  async (dispatch, getState) => {
    dispatch(deletePCOTrackersRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.pcoTracker}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: body,
      showToastMessage: true,
      toastMessage: 'Deleted successfully!',
    })
    if (status) {
      const { pcoTracker } = getState().reportTrackers
      const pcoTrackerAfterDeletion = pcoTracker?.changeOrderTrackers?.filter(
        ({ id }) => !checkIncludes(id, body?.pcoTracker),
      )
      if (pcoTrackerAfterDeletion?.length) {
        dispatch(
          deletePCOTrackersSuccess({
            changeOrderTrackers: pcoTrackerAfterDeletion,
          }),
        )
      } else {
        dispatch(deletePCOTrackersSuccess(null))
      }
      handleDeleteSuccess()
      return
    }
    if (error) {
      dispatch(deletePCOTrackersFailed(error.response.data))
    }
  }

// G702 Split Tracker
const fetchG702SplitTrackerRequested = () => ({
  type: FETCH_G702_SPLIT_TRACKER_REQUESTED,
})
const fetchG702SplitTrackerSuccess = (payload) => ({
  type: FETCH_G702_SPLIT_TRACKER_SUCCESS,
  payload,
})
const fetchG702SplitTrackerFailed = (payload) => ({
  type: FETCH_G702_SPLIT_TRACKER_FAILED,
  payload,
})

export const getG702SplitTracker =
  ({ reportId, ocId, projectId, g702TrackerId }) =>
  async (dispatch) => {
    dispatch(fetchG702SplitTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.g702SplitTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}&uniqueCode=${g702TrackerId}`,
      method: method.get,
    })
    if (data) {
      dispatch(fetchG702SplitTrackerSuccess(data))
    }
    if (error) {
      dispatch(fetchG702SplitTrackerFailed(error.response))
    }
  }

// Save G702 Tracker tracker
const saveG702SplitTrackerRequested = () => ({
  type: SAVE_G702_SPLIT_TRACKER_REQUESTED,
})
const saveG702SplitTrackerSuccess = (payload) => ({
  type: SAVE_G702_SPLIT_TRACKER_SUCCESS,
  payload,
})
const saveG702SplitTrackerFailed = (payload) => ({
  type: SAVE_G702_SPLIT_TRACKER_FAILED,
  payload,
})

export const saveG702SplitTracker =
  (payload, reportId, ocId, projectId) => async (dispatch, getState) => {
    dispatch(saveG702SplitTrackerRequested())
    const { g702SplitTracker } = getState().reportTrackers
    const { data, error } = await api({
      endPoint: `${apiEndPoints.g702SplitTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: ternary(g702SplitTracker, method.patch, method.post),
      data: payload,
      showToastMessage: true,
      toastMessage: 'G702 Split tracker saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(saveG702SplitTrackerSuccess(data))
      return
    }
    if (error) {
      dispatch(saveG702SplitTrackerFailed(error.response.data))
    }
  }

// Delete G702 tracker
const deleteG702SplitTrackersRequested = () => ({
  type: DELETE_G702_SPLIT_TRACKER_REQUESTED,
})
export const deleteG702SplitTrackersSuccess = (payload) => ({
  type: DELETE_G702_SPLIT_TRACKER_SUCCESS,
  payload,
})
const deleteG702SplitTrackersFailed = (payload) => ({
  type: DELETE_G702_SPLIT_TRACKER_FAILED,
  payload,
})
export const deleteG702SplitTrackers =
  (reportId, ocId, projectId, G702SplitTrackerDeleteId) => async (dispatch) => {
    dispatch(deleteG702SplitTrackersRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.g702SplitTracker}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: {
        gTwoTracker: G702SplitTrackerDeleteId,
      },
      showToastMessage: true,
      toastMessage: 'G702 Split tracker deleted successfully!',
    })
    if (status) {
      dispatch(deleteG702SplitTrackersSuccess())
      return
    }
    if (error) {
      dispatch(deleteG702SplitTrackersFailed(error.response))
    }
  }

// G702 Split Tracker
const fetchReportG702SplitTrackerRequested = () => ({
  type: FETCH_REPORT_G702_SPLIT_TRACKER_REQUESTED,
})
const fetchReportG702SplitTrackerSuccess = (payload) => ({
  type: FETCH_REPORT_G702_SPLIT_TRACKER_SUCCESS,
  payload,
})
const fetchReportG702SplitTrackerFailed = (payload) => ({
  type: FETCH_REPORT_G702_SPLIT_TRACKER_FAILED,
  payload,
})

export const getReportG702SplitTracker =
  ({ reportId, ocId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchReportG702SplitTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.g702SplitTracker}list/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })
    if (data) {
      dispatch(fetchG702SplitTrackerRequested())
      dispatch(fetchReportG702SplitTrackerSuccess(data))
    }
    if (error) {
      dispatch(fetchReportG702SplitTrackerFailed(error.response))
    }
  }

export const clearG702SplitTracker = () => ({
  type: CLEAR_G702_SPLIT_TRACKER,
})
