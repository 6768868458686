export const FETCH_PROJECT_DASHBOARD_REQUESTED =
  'FETCH_PROJECT_DASHBOARD_REQUESTED'
export const FETCH_PROJECT_DASHBOARD_SUCCESS = 'FETCH_PROJECT_DASHBOARD_SUCCESS'
export const FETCH_PROJECT_DASHBOARD_FAILED = 'FETCH_PROJECT_DASHBOARD_FAILED'

export const UPLOAD_IMAGE_REQUESTED = 'UPLOAD_IMAGE_REQUESTED'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED'

export const DELETE_IMAGE_REQUESTED = 'DELETE_IMAGE_REQUESTED'
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_FAILED = 'DELETE_IMAGE_FAILED'

export const CLEAR_PROJECT_DASHBOARD_DATA = 'CLEAR_PROJECT_DASHBOARD_DATA'
