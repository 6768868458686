export const variants = {
  primary: 'contained',
  outlined: 'outlined',
  text: 'text',
  disabled: 'contained',
}

export const colors = {
  primary: 'primary',
  secondary: '',
}
