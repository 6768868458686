import { Box, FormControl, FormHelperText, InputLabel } from '@mui/material'
import classNames from 'classnames'
import React, { memo } from 'react'
import { useStyles } from '../Styles/dktTextArea.style'

import { isArray, ternary } from '../Utils/javascript'

const DKTTextArea = ({
  label,
  id = label?.toLowerCase(),
  type,
  error,
  className,
  showLabel = true,
  formControlProps,
  isRequired = false,
  rightSide = false,
  ...rest
}) => {
  const classes = useStyles()
  const { sx, ...restFormControlProps } = formControlProps || {}
  return (
    <FormControl
      required={isRequired}
      className={className}
      variant="standard"
      sx={{ width: '100%', ...(sx || {}) }}
      {...restFormControlProps}
    >
      <Box display="flex" alignItems="center">
        {showLabel && (
          <InputLabel
            shrink={!rightSide}
            htmlFor={id}
            required={isRequired}
            disabled={rest.disabled}
            sx={{
              fontWeight: 'medium',
              color: 'gray.extraDark',
              ...(rightSide
                ? { position: 'relative', marginTop: '-15px', fontSize: 14 }
                : {}),
            }}
          >
            {label}
          </InputLabel>
        )}
        {rightSide && <Box sx={{ marginLeft: 'auto' }}>{rightSide}</Box>}
      </Box>
      <textarea
        className={`${classes.textArea}  ${classNames({
          [classes.mtNone]: !label,
        })}`}
        type={type}
        id={id}
        {...rest}
        style={ternary(
          rest.disabled,
          { ...rest?.formTextAreaStyle, color: 'gray' },
          { ...rest?.formTextAreaStyle },
        )}
      />
      {isArray(error) ? (
        error.map((errorMessage, index) => (
          <FormHelperText error={!!error} key={index}>
            {errorMessage}
          </FormHelperText>
        ))
      ) : (
        <FormHelperText error={!!error}>{error}</FormHelperText>
      )}
    </FormControl>
  )
}

export default memo(DKTTextArea)
