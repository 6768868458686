import { equal, ternary } from '../../Utils/javascript'
import {
  ADD_BUDGET_CONTINGENCY_SUCCESS,
  DELETE_BUDGET_CONTINGENCY_FAILED,
  DELETE_BUDGET_CONTINGENCY_REQUESTED,
  DELETE_BUDGET_CONTINGENCY_SUCCESS,
  FETCH_BUDGET_CONTINGENCY_FAILED,
  FETCH_BUDGET_CONTINGENCY_LIST_FAILED,
  FETCH_BUDGET_CONTINGENCY_LIST_REQUESTED,
  FETCH_BUDGET_CONTINGENCY_LIST_SUCCESS,
  FETCH_BUDGET_CONTINGENCY_MAPPING_FAILED,
  FETCH_BUDGET_CONTINGENCY_MAPPING_REQUESTED,
  FETCH_BUDGET_CONTINGENCY_MAPPING_SUCCESS,
  FETCH_BUDGET_CONTINGENCY_REQUESTED,
  FETCH_BUDGET_CONTINGENCY_SUCCESS,
  SAVE_BUDGET_CONTINGENCY_FAILED,
  SAVE_BUDGET_CONTINGENCY_REQUESTED,
  SAVE_BUDGET_CONTINGENCY_SUCCESS,
  SET_BUDGET_CONTINGENCY_ID,
  RESET_BUDGET_CONTINGENCY,
} from '../constants/budgetContingency'

const initialState = {
  mappings: [],
  isLoadingMapping: false,
  fetchMappingError: null,
  isLoadingBudgetContingencyList: true,
  isLoadingBudgetContingency: false,
  isSavingBudgetContingency: false,
  isCreatingBudgetContingency: false,
  isDeletingBudgetContingency: false,
  fetchBudgetContingencyListError: null,
  fetchBudgetContingencyError: null,
  createBudgetContingencyError: null,
  saveBudgetContingencyError: null,
  budgetContingencyList: null,
  budgetContingency: null,
  budgetContingencyId: null,
  isNewBudgetContingency: false,
}

export const budgetContingency = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_BUDGET_CONTINGENCY_REQUESTED:
      return {
        ...state,
        isLoadingBudgetContingency: true,
      }
    case FETCH_BUDGET_CONTINGENCY_SUCCESS:
      return {
        ...state,
        isLoadingBudgetContingency: false,
        budgetContingency: action.payload,
      }
    case FETCH_BUDGET_CONTINGENCY_FAILED:
      return {
        ...state,
        isLoadingBudgetContingency: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchBudgetContingencyError: action.payload?.data,
      }
    case FETCH_BUDGET_CONTINGENCY_LIST_REQUESTED:
      return {
        ...state,
        isLoadingBudgetContingencyList: true,
      }
    case FETCH_BUDGET_CONTINGENCY_LIST_SUCCESS:
      return {
        ...state,
        isLoadingBudgetContingency: !!action?.payload[0]?.id,
        isLoadingBudgetContingencyList: false,
        budgetContingencyList: action.payload,
        budgetContingencyId: action?.payload[0]?.id,
      }
    case FETCH_BUDGET_CONTINGENCY_LIST_FAILED:
      return {
        ...state,
        isLoadingBudgetContingencyList: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchBudgetContingencyListError: action.payload?.data,
      }
    case SAVE_BUDGET_CONTINGENCY_REQUESTED:
      return {
        ...state,
        isSavingBudgetContingency: true,
        saveBudgetContingencyError: '',
      }
    case SAVE_BUDGET_CONTINGENCY_SUCCESS:
      return {
        ...state,
        isSavingBudgetContingency: false,
        budgetContingency: action.payload,
        saveBudgetContingencyError: '',
      }
    case SAVE_BUDGET_CONTINGENCY_FAILED:
      return {
        ...state,
        isSavingBudgetContingency: false,
        saveBudgetContingencyError: action.payload,
      }
    case ADD_BUDGET_CONTINGENCY_SUCCESS:
      return {
        ...state,
        budgetContingencyList: action.payload,
      }
    case SET_BUDGET_CONTINGENCY_ID:
      return {
        ...state,
        budgetContingency: null,
        budgetContingencyId: action.payload.budgetContingencyId,
        isNewBudgetContingency: action.payload.isNewBudgetContingency,
      }
    case FETCH_BUDGET_CONTINGENCY_MAPPING_REQUESTED:
      return {
        ...state,
        isLoadingMapping: true,
      }
    case FETCH_BUDGET_CONTINGENCY_MAPPING_SUCCESS:
      return {
        ...state,
        isLoadingMapping: false,
        mappings: action.payload,
      }
    case FETCH_BUDGET_CONTINGENCY_MAPPING_FAILED:
      return {
        ...state,
        isLoadingMapping: false,
        fetchMappingError: action.payload,
      }
    // Delete budget contingency
    case DELETE_BUDGET_CONTINGENCY_REQUESTED:
      return {
        ...state,
        isDeletingBudgetContingency: true,
      }
    case DELETE_BUDGET_CONTINGENCY_SUCCESS:
      return {
        ...state,
        isDeletingBudgetContingency: false,
        budgetContingencyList: action.payload,
        budgetContingencyId: action?.activeBudgetContingency,
        isNewBudgetContingency:
          action?.isNewBudgetContingency || state.isNewBudgetContingency,
      }
    case DELETE_BUDGET_CONTINGENCY_FAILED:
      return {
        ...state,
        isDeletingBudgetContingency: false,
      }
    case RESET_BUDGET_CONTINGENCY:
      return initialState
    default:
      return state
  }
}
