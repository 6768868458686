import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import {
  FETCH_USER_PROFILE_FAILED,
  FETCH_USER_PROFILE_REQUESTED,
  FETCH_USER_PROFILE_SUCCESS,
  UPDATE_USER_PASSWORD_FAILED,
  UPDATE_USER_PASSWORD_REQUESTED,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PROFILE_FAILED,
  UPDATE_USER_PROFILE_REQUESTED,
  UPDATE_USER_PROFILE_SUCCESS,
} from '../constants/userProfile'
import { updateSaveStatusAction } from './confirmation'

// Fetch user profile
const fetchUserProfileRequested = () => ({
  type: FETCH_USER_PROFILE_REQUESTED,
})
const fetchUserProfileSuccess = (payload) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload,
})
const fetchUserProfileFailed = (payload) => ({
  type: FETCH_USER_PROFILE_FAILED,
  payload,
})

export const getUserProfile = (payload) => async (dispatch) => {
  dispatch(fetchUserProfileRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.userProfile,
    method: method.get,
    data: payload,
    showToastMessage: false,
    toastMessage: 'Fetch profile successfully!',
  })
  if (data) {
    dispatch(fetchUserProfileSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchUserProfileFailed(error?.response?.data))
  }
}

// Update user profile
export const updateUserProfileRequested = () => ({
  type: UPDATE_USER_PROFILE_REQUESTED,
})
const updateUserProfileSuccess = (payload) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload,
})
const updateUserProfileFailed = (payload) => ({
  type: UPDATE_USER_PROFILE_FAILED,
  payload,
})

export const updateUserProfile = (payload) => async (dispatch) => {
  dispatch(updateUserProfileRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.updateProfile,
    method: method.patch,
    data: payload,
    showToastMessage: true,
    toastMessage: 'User profile updated successfully!',
  })
  setTimeout(() => {
    dispatch(updateSaveStatusAction(data, error))
  }, 700)
  if (data) {
    dispatch(updateUserProfileSuccess(data))
    return
  }
  if (error) {
    dispatch(updateUserProfileFailed(error.response.data))
  }
}

// Update user password
const updateUserPasswordRequested = () => ({
  type: UPDATE_USER_PASSWORD_REQUESTED,
})
const updateUserPasswordSuccess = (payload) => ({
  type: UPDATE_USER_PASSWORD_SUCCESS,
  payload,
})
export const updateUserPasswordFailed = (payload) => ({
  type: UPDATE_USER_PASSWORD_FAILED,
  payload,
})

export const updateUserPassword = (payload) => async (dispatch) => {
  dispatch(updateUserPasswordRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.changePassword,
    method: method.put,
    data: payload,
    showToastMessage: true,
    toastMessage: 'User password updated successfully!',
  })
  if (data) {
    dispatch(updateUserPasswordSuccess(data))
    return
  }
  if (error) {
    dispatch(updateUserPasswordFailed(error.response.data))
  }
}
