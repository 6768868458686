import { TableCell, TableRow } from '@mui/material'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  alwaysDisabledReportSections,
  components,
  reportSections,
  tiers,
} from '../../../Description/reportTierSettings.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/admin'
import {
  fetchReportTierSettings,
  handleReportTierSettingsChange,
  saveReportTierSettings,
} from '../../../Redux/actions/constructionMonitoringReport'
import DKTButton from '../../../Shared/DKTButton'
import DKTCheckbox from '../../../Shared/DKTCheckbox'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import {
  useExpandedRowStyles,
  useStyles,
} from '../../../Styles/reportTierSettings.style'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import {
  checkIncludes,
  checkUndefined,
  equal,
  notNull,
  ternary,
} from '../../../Utils/javascript'

const ExpandedRow = memo(({ name, data, expandableRowClass }) => {
  const { disabledFields, ...settings } = data
  const dispatch = useDispatch()
  const classes = useExpandedRowStyles()
  const renderTableCell = ({ key, value }) =>
    ternary(
      equal(key, 'reportSection'),
      <TableCell key={key}>{value}</TableCell>,
      <TableCell key={key}>
        {!checkUndefined(value) && (
          <DKTCheckbox
            name={name}
            checked={value}
            onChange={() =>
              dispatch(
                handleReportTierSettingsChange({
                  columnName: key,
                  columnValue: !value,
                  reportSection: data.reportSection,
                  isSubSection: true,
                }),
              )
            }
            disabled={
              equal(key, 'firstTier') || checkIncludes(key, disabledFields)
            }
          />
        )}
      </TableCell>,
    )
  return (
    <TableRow key={name} className={expandableRowClass}>
      <TableCell className={classes.hideOnPrint}></TableCell>
      {[
        'reportSection',
        'firstTier',
        'secondTier',
        'thirdTier',
        'includeInAppendix',
      ].map((key) => {
        const value = settings[key]
        return ternary(
          equal(key, 'includeInAppendix'),
          ternary(
            notNull(value) && !checkUndefined(value),
            renderTableCell({ key, value }),
            null,
          ),
          renderTableCell({ key, value }),
        )
      })}
    </TableRow>
  )
})

const ReportTierSettings = ({ renderSelect, items }) => {
  const classes = useStyles()
  const { values, handleChange } = useForm()
  const [rowsExpanded, setRowsExpanded] = useState([])
  const {
    isUnsavedData,
    setIsUnsavedData,
    discardUnsavedChanges,
    isUnsavedChangesModalOpen,
    closeUnsavedChangesModal,
  } = items

  const dispatch = useDispatch()
  const { isSavingReportTierSettings } = useSelector(
    ({ constructionMonitoringReport }) => constructionMonitoringReport,
  )

  useEffect(() => {
    dispatch(fetchReportTierSettings())
  }, [dispatch])

  const handleCheckBoxChange = useCallback(
    (e) => handleChange(e, { isCheckbox: true }),
    [handleChange],
  )

  const { reportTierSettings, isFetchingReportTierSettings } = useSelector(
    ({ constructionMonitoringReport }) => constructionMonitoringReport,
  )

  const options = useMemo(
    () => ({
      filter: false,
      filterType: 'dropdown',
      pagination: false,
      viewColumns: false,
      responsive: 'standard',
      expandableRows: true,
      expandableRowsHeader: false,
      expandableRowsOnClick: false,
      rowsExpanded: reportTierSettings.map((row, index) => index),
      selectableRows: 'none',
      onRowExpansionChange: (curExpanded, expandedRows) => {
        setRowsExpanded(expandedRows.map(({ dataIndex }) => dataIndex))
      },
      isRowExpandable: (dataIndex) =>
        !!Object.values(reportSections)[dataIndex]?.subSections,
      renderExpandableRow: (rowData) => {
        const [reportSection] = rowData
        const subSections = rowData[5]
        return subSections?.map((section) => {
          const name = `${reportSection}.${section.name}.tiers`
          const [firstTier] = tiers
          return (
            <ExpandedRow
              key={section.reportSection}
              name={name}
              data={section}
              checked={section.value}
              sectionName={section.name}
              includeInAppendix={section.includeInAppendix}
              handleCheckBoxChange={handleCheckBoxChange}
              firstTier={firstTier}
              values={values[name]}
              expandableRowClass={classes.expandableRow}
            />
          )
        })
      },
    }),
    [handleCheckBoxChange, rowsExpanded, values, classes.expandableRow],
  )

  const customBodyRender = useCallback(
    (value, tableMeta) => {
      const [reportSection] = tableMeta.rowData
      const name = `${reportSection}.tiers`
      const isFirstTier = equal(tableMeta.columnIndex, 1)
      const isPermitToDisable =
        alwaysDisabledReportSections?.includes(reportSection)

      return (
        <DKTCheckbox
          name={name}
          disabled={isFirstTier || isPermitToDisable}
          value={tableMeta.columnData.label}
          checked={value}
          onChange={() => {
            setIsUnsavedData(true)
            dispatch(
              handleReportTierSettingsChange({
                columnName: tableMeta.columnData.name,
                columnValue: !value,
                reportSection,
              }),
            )
          }}
        />
      )
    },
    [values],
  )

  const columns = [
    {
      name: 'reportSection',
      label: 'Report Section',
      options: {
        filter: true,
      },
    },
    {
      name: 'firstTier',
      label: 'Tier 01',
      options: {
        filter: true,
        customBodyRender,
      },
    },
    {
      name: 'secondTier',
      label: 'Tier 02',
      options: {
        filter: false,
        customBodyRender,
      },
    },
    {
      name: 'thirdTier',
      label: 'Tier 03',
      options: {
        filter: true,
        customBodyRender,
      },
    },
    {
      name: 'includeInAppendix',
      label: 'Include in Appendix',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'subSections',
      options: {
        display: false,
      },
    },
  ]

  const handleSave = () => {
    setIsUnsavedData(false)
    dispatch(saveReportTierSettings())
  }

  const actions = () => (
    <DKTButton onClick={handleSave} disabled={isSavingReportTierSettings}>
      {ternary(isSavingReportTierSettings, 'Saving...', 'Save')}
    </DKTButton>
  )

  return (
    <InnerLayout
      actionTitle="Manage Construction Monitoring Report Settings"
      actions={actions}
      contentTitle="Construction Monitoring Report (CMR)"
    >
      {renderSelect()}
      {!isFetchingReportTierSettings && (
        <DKTMuiDataTable
          data={reportTierSettings}
          options={options}
          columns={columns}
          components={components}
          className={classes.reportTierTable}
        />
      )}
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnsavedChangesModalOpen}
        onConfirm={discardUnsavedChanges}
        onCancel={closeUnsavedChangesModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isUnsavedData} onSave={handleSave} />
    </InnerLayout>
  )
}

export default ReportTierSettings
