// Fetch budget summary
export const FETCH_BUDGET_SUMMARY_REQUEST = 'FETCH_BUDGET_SUMMARY_REQUEST'
export const FETCH_BUDGET_SUMMARY_SUCCESS = 'FETCH_BUDGET_SUMMARY_SUCCESS'
export const FETCH_BUDGET_SUMMARY_FAILED = 'FETCH_BUDGET_SUMMARY_FAILED'

// Create budget summary
export const CREATE_BUDGET_SUMMARY_REQUESTED = 'CREATE_BUDGET_SUMMARY_REQUESTED'
export const CREATE_BUDGET_SUMMARY_SUCCESS = 'CREATE_BUDGET_SUMMARY_SUCCESS'
export const CREATE_BUDGET_SUMMARY_FAILED = 'CREATE_BUDGET_SUMMARY_FAILED'

// Update budget summary
export const UPDATE_BUDGET_SUMMARY_REQUESTED = 'UPDATE_BUDGET_SUMMARY_REQUESTED'
export const UPDATE_BUDGET_SUMMARY_SUCCESS = 'UPDATE_BUDGET_SUMMARY_SUCCESS'
export const UPDATE_BUDGET_SUMMARY_FAILED = 'UPDATE_BUDGET_SUMMARY_FAILED'

// Delete budget summary
export const DELETE_BUDGET_SUMMARY_REQUESTED = 'DELETE_BUDGET_SUMMARY_REQUESTED'
export const DELETE_BUDGET_SUMMARY_SUCCESS = 'DELETE_BUDGET_SUMMARY_SUCCESS'
export const DELETE_BUDGET_SUMMARY_FAILED = 'DELETE_BUDGET_SUMMARY_FAILED'

// Import from last report
export const IMPORT_FROM_LAST_REPORT_REQUESTED =
  'IMPORT_FROM_LAST_REPORT_REQUESTED'
export const IMPORT_FROM_LAST_REPORT_SUCCESS = 'IMPORT_FROM_LAST_REPORT_SUCCESS'
export const IMPORT_FROM_LAST_REPORT_FAILED = 'IMPORT_FROM_LAST_REPORT_FAILED'

// Fetch budget summary reference values
export const FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_REQUESTED =
  'FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_REQUESTED'
export const FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_SUCCESS =
  'FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_SUCCESS'
export const FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_FAILED =
  'FETCH_BUDGET_SUMMARY_REFERENCE_VALUES_FAILED'

// Fetch budget summary numbering
export const FETCH_BUDGET_SUMMARY_NUMBERING_REQUEST =
  'FETCH_BUDGET_SUMMARY_NUMBERING_REQUEST'
export const FETCH_BUDGET_SUMMARY_NUMBERING_SUCCESS =
  'FETCH_BUDGET_SUMMARY_NUMBERING_SUCCESS'
export const FETCH_BUDGET_SUMMARY_NUMBERING_FAILED =
  'FETCH_BUDGET_SUMMARY_NUMBERING_FAILED'

// CLEAR BUDGET SUMMARY
export const CLEAR_BUDGET_SUMMARY = 'CLEAR_BUDGET_SUMMARY'
