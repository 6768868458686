export const FETCH_EXECUTIVE_SUMMARY_REQUESTED =
  'FETCH_EXECUTIVE_SUMMARY_REQUESTED'
export const FETCH_EXECUTIVE_SUMMARY_SUCCESS = 'FETCH_EXECUTIVE_SUMMARY_SUCCESS'
export const FETCH_EXECUTIVE_SUMMARY_FAILED = 'FETCH_EXECUTIVE_SUMMARY_FAILED'

export const SAVE_EXECUTIVE_SUMMARY_REQUESTED =
  'SAVE_EXECUTIVE_SUMMARY_REQUESTED'
export const SAVE_EXECUTIVE_SUMMARY_SUCCESS = 'SAVE_EXECUTIVE_SUMMARY_SUCCESS'
export const SAVE_EXECUTIVE_SUMMARY_FAILED = 'SAVE_EXECUTIVE_SUMMARY_FAILED'

export const IMPORT_EXECUTIVE_SUMMARY_REQUESTED =
  'IMPORT_EXECUTIVE_SUMMARY_REQUESTED'
export const IMPORT_EXECUTIVE_SUMMARY_SUCCESS =
  'IMPORT_EXECUTIVE_SUMMARY_SUCCESS'
export const IMPORT_EXECUTIVE_SUMMARY_FAILED = 'IMPORT_EXECUTIVE_SUMMARY_FAILED'

export const IMPORT_OPEN_ISSUES_REQUESTED = 'IMPORT_OPEN_ISSUES_REQUESTED'
export const IMPORT_OPEN_ISSUES_SUCCESS = 'IMPORT_OPEN_ISSUES_SUCCESS'
export const IMPORT_OPEN_ISSUES_FAILED = 'IMPORT_OPEN_ISSUES_FAILED'

export const IMPORT_COMMENTS_REQUESTED = 'IMPORT_COMMENTS_REQUESTED'
export const IMPORT_COMMENTS_SUCCESS = 'IMPORT_COMMENTS_SUCCESS'
export const IMPORT_COMMENTS_FAILED = 'IMPORT_COMMENTS_FAILED'

export const IMPORT_QUALITY_OF_WORK_REQUESTED =
  'IMPORT_QUALITY_OF_WORK_REQUESTED'
export const IMPORT_QUALITY_OF_WORK_SUCCESS = 'IMPORT_QUALITY_OF_WORK_SUCCESS'
export const IMPORT_QUALITY_OF_WORK_FAILED = 'IMPORT_QUALITY_OF_WORK_FAILED'

// auto generate executive summary
export const FETCH_AUTO_GEN_ES_REQUESTED = 'FETCH_AUTO_GEN_ES_REQUESTED'
export const FETCH_AUTO_GEN_ES_SUCCESS = 'FETCH_AUTO_GEN_ES_SUCCESS'
export const FETCH_AUTO_GEN_ES_FAILED = 'FETCH_AUTO_GEN_ES_FAILED'

export const FETCH_AUTO_GEN_ES_VALUES_REQUESTED =
  'FETCH_AUTO_GEN_ES_VALUES_REQUESTED'
export const FETCH_AUTO_GEN_ES_VALUES_SUCCESS =
  'FETCH_AUTO_GEN_ES_VALUES_SUCCESS'
export const FETCH_AUTO_GEN_ES_VALUES_FAILED = 'FETCH_AUTO_GEN_ES_VALUES_FAILED'

export const SAVE_AUTO_GEN_ES_TEXT_REQUESTED = 'SAVE_AUTO_GEN_ES_TEXT_REQUESTED'
export const SAVE_AUTO_GEN_ES_TEXT_SUCCESS = 'SAVE_AUTO_GEN_ES_TEXT_SUCCESS'
export const SAVE_AUTO_GEN_ES_TEXT_FAILED = 'SAVE_AUTO_GEN_ES_TEXT_FAILED'

export const CLEAR_ES_GEN_MODAL_VALUES = 'CLEAR_ES_GEN_MODAL_VALUES'
