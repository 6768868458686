import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { submitZipCode } from '../Redux/actions/states'

import { BOOLEAN, password, STRING } from '../Utils/constant'
import {
  checkIncludes,
  entries,
  equal,
  gt,
  isArray,
  isEmpty,
  isEmptyString,
  keys,
  length,
  lowerCase,
  ternary,
  compareArrays,
} from '../Utils/javascript'
import { checkIfValidDate, confirmPassword, notEmpty } from '../Utils/regex'

let oldError = {}
let oldValues = {}
let checkSameValuesObj = {}
let tempDummyData = {}

const useForm = (formFields, clearReducerState = false) => {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})
  const [isDirty, setIsDirty] = useState(false)
  const [dummyData, setDummyData] = useState({})
  const [isNavigate, setIsNavigate] = useState(false)

  const dispatch = useDispatch()
  const { usStateCityData, usStateCityError } = useSelector(
    ({ states }) => states,
  )

  // Not disabled formFields name in arrays
  const fieldName = Object.keys(formFields ?? {})?.filter((value) => {
    let fieldName
    if (!formFields[value].disabled) {
      fieldName = value
    }
    return fieldName
  })

  // when component mount set isDirty to false
  useEffect(() => {
    setIsDirty(false)
    return () => {
      if (clearReducerState) dispatch({ type: clearReducerState })
    }
  }, [])

  // when oc-id changes set the tempDummyData
  useEffect(() => {
    tempDummyData = { ...dummyData }
  }, [dummyData])

  // when oc-id changes set checkSameValuesObj as empty object
  useEffect(() => {
    checkSameValuesObj = {}
  }, [values.ownerContractor])

  useEffect(() => {
    checkSameValuesObj = {}
    setIsNavigate(false)
  }, [isNavigate])
  // check state is dirty or not

  useEffect(() => {
    if (keys(checkSameValuesObj).length) {
      const data = entries(checkSameValuesObj)?.map(([name, value]) => {
        const res = []
        if (
          fieldName?.includes(name) ||
          (formFields !== undefined &&
            !isEmpty(formFields) &&
            !keys(formFields)?.includes(name))
        ) {
          if (keys(tempDummyData)?.includes(name)) {
            const compareDummyData = isEmptyString(tempDummyData[name])
              ? ''
              : isArray(tempDummyData[name])
              ? tempDummyData[name]
              : // eslint-disable-next-line no-restricted-globals
              isNaN(String(tempDummyData[name]))
              ? String(tempDummyData[name])
              : `${parseFloat(String(tempDummyData[name]))?.toFixed(4)}`

            const compareValue = isEmptyString(value)
              ? ''
              : isArray(values[name])
              ? values[name]
              : // eslint-disable-next-line no-restricted-globals
              isNaN(String(value))
              ? String(value)
              : `${parseFloat(String(value))?.toFixed(4)}`
            if (isArray(compareDummyData) && isArray(compareValue)) {
              const compareArrRes = compareArrays(
                compareDummyData,
                compareValue,
              )
              res.push(...compareArrRes)
            }
            res.push(String(compareDummyData) !== String(compareValue))
          } else if (keys(values)?.includes(name)) {
            res.push(true)
          }
        }
        return res
      })
      if (data?.flat().length) {
        if (data?.flat()?.some((data) => data === true)) {
          setIsDirty(true)
        } else {
          setIsDirty(false)
        }
      }
    }
  }, [checkSameValuesObj])
  // const timer = null

  // useEffect(() => {
  //   timer = setTimeout(() => {
  //     if (values.zipCode && equal(length(values.zipCode), 5))
  //       dispatch(submitZipCode({ zipCode: values.zipCode }))
  //   }, 200)
  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [dispatch, values.zipCode])

  useEffect(() => {
    if (
      usStateCityData &&
      usStateCityData?.usCitiesData?.city &&
      usStateCityData?.usCitiesData?.state
    ) {
      const cloneErrors = { ...errors }
      delete cloneErrors.state
      delete cloneErrors.city
      setErrors(cloneErrors)
      setValues({
        ...values,
        city: usStateCityData?.usCitiesData?.city,
        state: usStateCityData?.usCitiesData?.state,
      })
    }
  }, [usStateCityData])

  useEffect(() => {
    if (usStateCityError) {
      const cloneErrors = { ...errors, zipCode: usStateCityError?.zipCode }
      setErrors(cloneErrors)
    }
  }, [usStateCityError])

  useEffect(() => {
    oldError = errors
    oldValues = values
  }, [errors, values])

  const validateField = useCallback(
    ({ name, value, flag = false }) => {
      const error = { ...errors }
      const err = { ...oldError }
      const formField =
        formFields[name] || formFields?.[name?.split('.')?.shift()]
      const formValue = value ?? oldValues[name]
      if (!formField) return null

      const validateConfirmPassword = ({
        name,
        value,
        validateRequired = false,
      }) => {
        const passwordsMatching = confirmPassword(
          oldValues[formField.dependsOn],
          value,
        )
        error[name] = ternary(
          passwordsMatching && value,
          '',
          ternary(
            value,
            'Password are not matching',
            validateRequired ? `Required` : '',
          ),
        )
      }

      if (formField.isConfirmPassword) {
        validateConfirmPassword({ name, value, validateRequired: true })
      } else {
        if (equal(formField.type, password) && values[formField.dependsOn]) {
          validateConfirmPassword({
            name: formField.dependsOn,
            value,
          })
        }
        if (formField.isRequired && !notEmpty(value) && value !== 0) {
          error[name] = `Required`
        } else if (
          formField.isRequired &&
          equal(formField?.type, 'select') &&
          (equal(value, []) || !value)
        ) {
          error[name] = `Required`
        } else if (formField.isRequired && value?.length) {
          delete error[name]
          delete err[name]
        }
        if (
          formField?.isLimitLess &&
          !formField.validate &&
          !formField.isRequired
        ) {
          if (err[name]) {
            delete err[name]
          }
        }
        if (formField.validate) {
          const [isValid, errorMessage] = ternary(
            isArray(formField.validate),
            formField.validate,
            [formField.validate, `Invalid ${lowerCase(formField.label)}`],
          )
          if (isValid instanceof Function && !isValid(formValue)) {
            error[name] = ternary(
              !notEmpty(formValue),
              ternary(formField.isRequired, `Required`, null),
              errorMessage ||
                `Invalid ${lowerCase(
                  formFields?.[name?.split('.')?.shift()].label ??
                    formFields?.[name?.split('.')?.shift()]?.helperText,
                )}`,
            )
          } else {
            delete error[name]
            delete err[name]
          }
        }
      }
      setErrors({
        ...err,
        ...error,
      })
      return flag || formFields
        ? error
        : equal(length(Object.values(error).filter((val) => !!val)), 0)
    },
    [formFields, errors],
  )

  const handleChange = useCallback(
    (e, options) => {
      const { name, value, checked } = e.target
      // add onChange property to object
      checkSameValuesObj = {
        ...checkSameValuesObj,
        [name]: value,
      }

      const formField =
        formFields?.[name] || formFields?.[name.split('.').shift()]
      if (
        equal(formField?.type, 'checkbox') ||
        equal(options?.isCheckbox, true)
      ) {
        if (equal(formField?.valueType || options?.valueType, BOOLEAN)) {
          setValues((values) => ({ ...values, [name]: checked }))
          return
        }
        setValues((values) => {
          const checkBoxValue = values[name]
          return {
            ...values,
            [name]: ternary(
              checked,
              isArray(checkBoxValue) ? [...checkBoxValue, value] : [value],
              isArray(checkBoxValue)
                ? checkBoxValue.filter((cbValue) => !equal(cbValue, value))
                : [],
            ),
          }
        })
        return
      }
      if (equal(formField?.type, 'date') && value) {
        if (!checkIfValidDate(value)) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Invalid date' }))
          setValues((prevValues) => ({
            ...prevValues,
            [name]: value,
          }))
          return
        }
      }

      if (
        equal(name, 'workPhoneNumber') ||
        equal(name, 'cellPhone') ||
        formField?.isPhoneNumber
      ) {
        const valueData = value || ''
        if (equal(valueData.toString().length > 12, true, false)) return
        const maskDigit = valueData
          .replace(/\D/g, '')
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        const maskInputValue = !maskDigit[2]
          ? maskDigit[1]
          : `${maskDigit[1]}-${maskDigit[2]}${
              maskDigit[3] ? `-${maskDigit[3]}` : ''
            }`
        setValues((prevValues) => ({
          ...prevValues,
          [name]: maskInputValue,
        }))
      } else if (
        formField?.isTime &&
        equal(value?.length, 2) &&
        !equal(e.nativeEvent.inputType, 'deleteContentBackward')
      ) {
        setValues((prevValues) => ({ ...prevValues, [name]: `${value}:` }))
      } else if (formField?.isTime && value.length > 5) {
        // Do not set value
      } else {
        setValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }))
      }
      if (
        formField?.isRequired ||
        formField?.validate ||
        formField?.isConfirmPassword ||
        formField?.isLimitLess
      ) {
        validateField({
          name,
          value:
            formField?.isTime &&
            equal(typeof value, STRING) &&
            gt(value?.length, 5)
              ? value.substr(0, 5)
              : value,
        })
      } else if (oldError[name]) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
      }
    },
    [formFields],
  )

  const setFieldValue = useCallback(
    (name, value, comp) => {
      const formField = formFields?.[name]
      if (
        equal(formField?.type, 'checkbox') &&
        !equal(formField?.valueType, BOOLEAN)
      ) {
        setValues((values) => ({ ...values, [name]: value }))
        if (!isEmptyString(comp)) {
          setDummyData((values) => ({ ...values, [name]: value }))
        }
        return
      }
      handleChange({ target: { name, value } })
      if (!isEmptyString(comp)) {
        setDummyData((values) => ({ ...values, [name]: value }))
      }
    },
    [formFields],
  )

  const resetField = useCallback(
    (name) =>
      setFieldValue(
        name,
        ternary(equal(formFields[name]?.valueType, BOOLEAN), false, ''),
      ),
    [setFieldValue, formFields],
  )

  const handleSubmit = useCallback(
    ({ excludeValidation = [], flag = false, comp } = {}) => {
      let isValid = true
      const error = {}
      entries(flag || formFields).forEach(([name, field]) => {
        if (field.isRequired && !!field.defaultValue && !values[name]) {
          setFieldValue(name, field?.defaultValue)
        }
        if (
          !checkIncludes(name, excludeValidation) &&
          (field.isRequired || field.validate || field.isConfirmPassword)
        ) {
          const isFieldValid =
            validateField({
              name,
              value: ternary(
                field.isRequired && !!field.defaultValue && !values[name],
                field?.defaultValue,
                values[name],
              ),
              flag,
            }) || {}
          error[name] = isFieldValid[name]
          if (equal(field?.type, 'date') && values[name]) {
            if (!checkIfValidDate(values[name])) {
              error[name] = 'Invalid date'
            }
          }
          if (isFieldValid[name] || (!flag && !isFieldValid)) isValid = false
        }
      })
      if (!isValid) {
        setErrors((errors) => ({
          ...errors,
          ...error,
        }))
      }
      if (!isEmptyString(comp) && isValid) {
        checkSameValuesObj = {}
      }
      return isValid
    },
    [formFields, validateField, values],
  )

  const resetForm = useCallback(
    (otherFormFields) => {
      if (!otherFormFields) {
        setValues({})
        setErrors({})
        return
      }
      keys(otherFormFields || formFields).forEach((fieldName) =>
        resetField(fieldName),
      )
      setErrors({})
    },
    [formFields, resetField],
  )

  return {
    values,
    errors,
    setErrors,
    handleChange,
    handleSubmit,
    setValues,
    resetField,
    resetForm,
    setFieldValue,
    isDirty,
    setIsDirty,
    setDummyData,
    dummyData,
    setIsNavigate,
  }
}

export default useForm
