import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  addBtn: {
    marginLeft: 0,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  addressDialog: {
    cursor: 'pointer',
  },
  contactsTable: {
    '& .MuiPaper-elevation': {
      boxShadow: 'none',
      '& .MuiTableHead-root': {
        '& .MuiTableRow-root': {
          '& th': {
            background: theme.palette.gray.main,
            '& .MuiButton-root': {
              padding: 0,
              width: '100%',
              justifyContent: 'space-between',
              '&:hover': {
                background: 'trasparent',
              },
              '& > .tss-1h9t3sl-MUIDataTableHeadCell-sortAction': {
                width: '100%',
                justifyContent: 'space-between',
              },
            },
          },
        },
      },
    },
    '& .MuiButton-root': {
      marginLeft: 30,
      '& .MuiButton-startIcon': {
        marginLeft: 0,
      },
    },
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          backgroundColor: '#E5E5E5',
        },
      },
    },
  },
  dragIcon: {
    cursor: 'move',
    maxWidth: 'max-content',
  },
  input: {
    '& .MuiInputBase-root': {
      marginTop: '0 !important',
      paddingLeft: '0 !important',
      border: 'none',
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  projectDesc: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 250,
    height: 350,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '150px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '150px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },
  inputBorder: {
    marginTop: '0 !important',
    '& .MuiInputBase-root': {
      marginTop: '0 !important',
      paddingLeft: '16px !important',
      border: 'none',
    },
  },
  dateError: {
    position: 'relative',
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      left: 30,
      bottom: -3,
    },
  },
  permitTrackerTable: {
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        borderLeft: '1px solid #e0e0e0',
        '& .MuiTableCell-root': {
          borderRight: '1px solid #e0e0e0',
          padding: '0px 0px',
          '& .MuiFormControl-root': {
            height: '48px',
            display: 'block',
          },
          '& .MuiInputBase-root:nth-child(1)': {
            borderBottom: 0,
          },
          '& .MuiInputBase-root': {
            height: '48px',
            '& .MuiSelect-select': {
              padding: '12px 14px',
            },
          },
          '& div': {
            justifyContent: 'center',
          },
        },
      },
    },
  },
  permitTrackerTableNoData: {
    '& .MuiTableCell-root': {
      padding: '16px !important',
    },
  },
  permitTrackerModal: {
    '& .MuiPaper-root': {
      width: 'calc(100% - 128px)',
      '& .MuiPaper-root': {
        padding: 0,
        width: '100%',
        '& div .MuiTable-root': {
          minWidth: 1370,
        },
      },
    },
  },
}))
