import {
  FETCH_PROJECT_LIMITATION_FAILED,
  FETCH_PROJECT_LIMITATION_REQUESTED,
  FETCH_PROJECT_LIMITATION_SUCCESS,
  SAVE_PROJECT_LIMITATIONS_FAILED,
  SAVE_PROJECT_LIMITATIONS_REQUESTED,
  SAVE_PROJECT_LIMITATIONS_SUCCESS,
} from '../constants/projectSettingLimitation'

const initialState = {
  projectLimitation: '',
  isFetchingProjectLimitation: false,
  fetchProjectLimitationError: null,
  isSaveProjectLimitation: false,
  saveProjectLimitationError: null,
}

export const projectLimitation = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PROJECT_LIMITATION_REQUESTED:
      return {
        ...state,
        isFetchingProjectSettingLimitation: true,
      }
    case FETCH_PROJECT_LIMITATION_SUCCESS:
      return {
        ...state,
        isFetchingProjectSettingLimitation: false,
        fetchCompanyPersonnelError: null,
        projectLimitation: action.payload,
      }
    case FETCH_PROJECT_LIMITATION_FAILED:
      return {
        ...state,
        isFetchingProjectSettingLimitation: false,
        fetchProjectLimitationError: action.payload,
      }
    case SAVE_PROJECT_LIMITATIONS_REQUESTED:
      return {
        ...state,
        isSaveProjectLimitation: true,
      }
    case SAVE_PROJECT_LIMITATIONS_SUCCESS:
      return {
        ...state,
        isSaveProjectLimitation: false,
        fetchCompanyPersonnelError: null,
        projectLimitation: action.payload,
      }
    case SAVE_PROJECT_LIMITATIONS_FAILED:
      return {
        ...state,
        isSaveProjectLimitation: false,
        saveProjectLimitationError: action.payload,
      }
    default:
      return state
  }
}
