import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  notificationContent: {
    textAlign: 'center',
    position: 'relative',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
      textAlign: 'left',
      position: 'absolute',
      top: '-46px',
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  addressDialog: {
    cursor: 'pointer',
  },
  projectDesc: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 250,
    height: 350,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '150px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '150px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '&.description': {
      minHeight: 160,
      height: 250,
      maxHeight: 250,
      '& .ck-editor__main': {
        minHeight: 80,
      },
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },
  commonTable: {
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          backgroundColor: '#E5E5E5',
        },
      },
    },
    '& .MuiTableCell-body': {
      paddingLeft: 0,
      '& .MuiTypography-body1': {
        textAlign: 'left',
        maxWidth: '250px',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      },
    },
  },
  projectListTable: {
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          padding: '7px 16px',
        },
      },
    },
    '& .MuiTableCell-body': {
      padding: '7px 16px',
      '& .MuiButtonBase-root': {
        height: 'auto',
      },
    },
  },
  projectListTableClient: {
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          padding: '4px 16px',
          height: 48,
        },
      },
    },
    '& .MuiTableCell-body': {
      padding: '8px 16px',
    },
  },
  overflowTable: {
    overflow: 'auto !important',
    maxHeight: 'calc(100vh - 396px) !important',
    '& thead': {
      position: 'sticky',
      top: '-2px',
      zIndex: 999,
    },
  },
  stickyButton: {
    position: 'sticky',
    bottom: '-2px',
    zIndex: 999,
    backgroundColor: '#FFFFFF',
  },
  stateDropdown: {
    marginTop: '0 !important',
  },
  siteAddressColumn: {
    '& .MuiTableCell-root': {
      '&:nth-child(2)': {
        width: 300,
      },
      '&:nth-child(3)': {
        width: 140,
      },
      '&:nth-child(4)': {
        width: 60,
        '& .MuiSelect-select': {
          paddingRight: 0,
        },
      },
    },
  },
}))
