import { Typography, Box, styled } from '@mui/material'
const HeadingStyled = styled(Box)(({ theme }) => ({
  '& .MuiTypography-body2': {
    color: theme.palette.gray.extraDark,
    borderBottom: `1px solid ${theme.palette.gray.main}`,
  },
}))
const FormSectionDivider = ({ sectionTitle, children, ...rest }) => (
  <HeadingStyled {...rest}>
    <Typography
      variant="body2"
      color="gray.extraDark"
      sx={{ fontWeight: 'medium' }}
    >
      {sectionTitle}
      {children}
    </Typography>
  </HeadingStyled>
)

export default FormSectionDivider
