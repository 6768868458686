import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_G702_SPLIT_TRACKER,
  CLEAR_REPORT_TRACKER,
  DELETE_CO_TRACKER_FAILED,
  DELETE_CO_TRACKER_REQUESTED,
  DELETE_CO_TRACKER_SUCCESS,
  DELETE_G702_SPLIT_TRACKER_FAILED,
  DELETE_G702_SPLIT_TRACKER_REQUESTED,
  DELETE_G702_SPLIT_TRACKER_SUCCESS,
  DELETE_G703_TRACKER_FAILED,
  DELETE_G703_TRACKER_REQUESTED,
  DELETE_G703_TRACKER_SUCCESS,
  DELETE_LIEN_WAIVERS_FAILED,
  DELETE_LIEN_WAIVERS_REQUESTED,
  DELETE_LIEN_WAIVERS_SUCCESS,
  DELETE_PCO_TRACKER_FAILED,
  DELETE_PCO_TRACKER_REQUESTED,
  DELETE_PCO_TRACKER_SUCCESS,
  DELETE_STORED_MATERIAL_FAILED,
  DELETE_STORED_MATERIAL_REQUESTED,
  DELETE_STORED_MATERIAL_SUCCESS,
  FETCH_CO_TRACKER_FAILED,
  FETCH_CO_TRACKER_REQUESTED,
  FETCH_CO_TRACKER_SUCCESS,
  FETCH_G702_SPLIT_TRACKER_FAILED,
  FETCH_G702_SPLIT_TRACKER_REQUESTED,
  FETCH_G702_SPLIT_TRACKER_SUCCESS,
  FETCH_G703_TRACKER_FAILED,
  FETCH_G703_TRACKER_REQUESTED,
  FETCH_G703_TRACKER_SUCCESS,
  FETCH_LIEN_WAIVERS_FAILED,
  FETCH_LIEN_WAIVERS_REQUESTED,
  FETCH_LIEN_WAIVERS_SUCCESS,
  FETCH_PCO_TRACKER_FAILED,
  FETCH_PCO_TRACKER_REQUESTED,
  FETCH_PCO_TRACKER_SUCCESS,
  FETCH_REPORT_G702_SPLIT_TRACKER_FAILED,
  FETCH_REPORT_G702_SPLIT_TRACKER_REQUESTED,
  FETCH_REPORT_G702_SPLIT_TRACKER_SUCCESS,
  FETCH_STORED_MATERIAL_FAILED,
  FETCH_STORED_MATERIAL_REQUESTED,
  FETCH_STORED_MATERIAL_SUCCESS,
  SAVE_CO_TRACKER_FAILED,
  SAVE_CO_TRACKER_REQUESTED,
  SAVE_CO_TRACKER_SUCCESS,
  SAVE_G702_SPLIT_TRACKER_FAILED,
  SAVE_G702_SPLIT_TRACKER_REQUESTED,
  SAVE_G702_SPLIT_TRACKER_SUCCESS,
  SAVE_G703_TRACKER_FAILED,
  SAVE_G703_TRACKER_REQUESTED,
  SAVE_G703_TRACKER_SUCCESS,
  SAVE_LIEN_WAIVERS_FAILED,
  SAVE_LIEN_WAIVERS_REQUESTED,
  SAVE_LIEN_WAIVERS_SUCCESS,
  SAVE_PCO_TRACKER_FAILED,
  SAVE_PCO_TRACKER_REQUESTED,
  SAVE_PCO_TRACKER_SUCCESS,
  SAVE_STORED_MATERIAL_FAILED,
  SAVE_STORED_MATERIAL_REQUESTED,
  SAVE_STORED_MATERIAL_SUCCESS,
  UPDATE_CO_TRACKER_FAILED,
  UPDATE_CO_TRACKER_REQUESTED,
  UPDATE_CO_TRACKER_SUCCESS,
  UPDATE_PCO_TRACKER_FAILED,
  UPDATE_PCO_TRACKER_REQUESTED,
  UPDATE_PCO_TRACKER_SUCCESS,
} from '../constants/reportTrackers'

const initialState = {
  // G703 tracker
  isSavingG703Tracker: false,
  isFetchingG703Tracker: true,
  isDeletingG703Trackers: false,
  g703Tracker: null,
  fetchG703TrackerError: null,
  saveG703TrackerError: null,
  // CO tracker
  isSavingCOTracker: false,
  isFetchingCOTracker: true,
  isDeletingCOTrackers: false,
  coTracker: null,
  fetchCOTrackerError: null,
  saveCOTrackerError: null,
  // PCO tracker
  isSavingPCOTracker: false,
  isFetchingPCOTracker: true,
  isDeletingPCOTrackers: false,
  pcoTracker: null,
  fetchPCOTrackerError: null,
  savePCOTrackerError: null,
  // Lien waivers tracker
  isSavingLienWaiversTracker: false,
  isFetchingLienWaiversTracker: true,
  isDeletingLienWaiversTrackers: false,
  lienWaiversTracker: null,
  fetchLienWaiversTrackerError: null,
  saveLienWaiversTrackerError: null,
  deleteLienWaiversError: null,
  // Stored material tracker
  isSavingStoredMaterialTracker: false,
  isFetchingStoredMaterialTracker: true,
  isDeletingStoredMaterialTrackers: false,
  storedMaterialTracker: null,
  fetchStoredMaterialTrackerError: null,
  saveStoredMaterialTrackerError: null,
  deleteStoredMaterialError: null,
  // G702 Split tracker
  isSavingG702SplitTracker: false,
  isFetchingG702SplitTracker: false,
  g702SplitTracker: null,
  isDeletingG702SplitTracker: false,
  isFetchingG702SplitTrackerUniqueCodes: true,
  g702SplitTrackerUniqueCodes: [],
}

export const reportTrackers = (state = initialState, action = {}) => {
  switch (action.type) {
    /* G703 tracker */
    // Fetch G703 tracker
    case FETCH_G703_TRACKER_REQUESTED:
      return {
        ...state,
        isFetchingG703Tracker: true,
      }
    case FETCH_G703_TRACKER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const total = action.payload && action?.payload?.total
      // eslint-disable-next-line no-case-declarations
      const daTotal =
        action.payload &&
        action.payload.total.da &&
        +action.payload.total.da.toFixed(2)
      return {
        ...state,
        isFetchingG703Tracker: false,
        g703Tracker: {
          ...action?.payload,
          ...{
            ...total,
            da: daTotal ?? 0,
          },
        },
      }
    case FETCH_G703_TRACKER_FAILED:
      return {
        ...state,
        isFetchingG703Tracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchG703TrackerError: action.payload?.data,
      }
    // Save G703 tracker
    case SAVE_G703_TRACKER_REQUESTED:
      return {
        ...state,
        isSavingG703Tracker: true,
      }
    case SAVE_G703_TRACKER_SUCCESS:
      return {
        ...state,
        isSavingG703Tracker: false,
        g703Tracker: action.payload,
      }
    case SAVE_G703_TRACKER_FAILED:
      return {
        ...state,
        isSavingG703Tracker: false,
        saveG703TrackerError: action.payload,
      }
    // Delete G703 tracker
    case DELETE_G703_TRACKER_REQUESTED:
      return {
        ...state,
        isDeletingG703Trackers: true,
      }
    case DELETE_G703_TRACKER_SUCCESS:
      return {
        ...state,
        isDeletingG703Trackers: false,
        g703Tracker: action.payload,
      }
    case DELETE_G703_TRACKER_FAILED:
      return {
        ...state,
        isDeletingG703Trackers: false,
      }
    /* CO tracker */
    // Fetch CO tracker
    case FETCH_CO_TRACKER_REQUESTED:
      return {
        ...state,
        isFetchingCOTracker: true,
      }
    case FETCH_CO_TRACKER_SUCCESS:
      return {
        ...state,
        isFetchingCOTracker: false,
        coTracker: action?.payload,
      }
    case FETCH_CO_TRACKER_FAILED:
      return {
        ...state,
        isFetchingCOTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchCOTrackerError: action.payload?.data,
      }
    // Save CO tracker
    case SAVE_CO_TRACKER_REQUESTED:
      return {
        ...state,
        isSavingCOTracker: true,
      }
    case SAVE_CO_TRACKER_SUCCESS:
      return {
        ...state,
        isSavingCOTracker: false,
        coTracker: action.payload,
      }
    case SAVE_CO_TRACKER_FAILED:
      return {
        ...state,
        isSavingCOTracker: false,
        saveCOTrackerError: action.payload,
      }
    // Update CO tracker
    case UPDATE_CO_TRACKER_REQUESTED:
      return {
        ...state,
        isUpdatingCOTracker: true,
      }
    case UPDATE_CO_TRACKER_SUCCESS:
      return {
        ...state,
        isUpdatingCOTracker: false,
        coTracker: action.payload,
      }
    case UPDATE_CO_TRACKER_FAILED:
      return {
        ...state,
        isUpdatingCOTracker: false,
        saveCOTrackerError: action.payload,
      }
    // Delete CO tracker
    case DELETE_CO_TRACKER_REQUESTED:
      return {
        ...state,
        isDeletingCOTrackers: true,
      }
    case DELETE_CO_TRACKER_SUCCESS:
      return {
        ...state,
        isDeletingCOTrackers: false,
        coTracker: action.payload,
      }
    case DELETE_CO_TRACKER_FAILED:
      return {
        ...state,
        isDeletingCOTrackers: false,
      }
    /* PCO tracker */
    // Fetch PCO tracker
    case FETCH_PCO_TRACKER_REQUESTED:
      return {
        ...state,
        isFetchingPCOTracker: true,
      }
    case FETCH_PCO_TRACKER_SUCCESS:
      return {
        ...state,
        isFetchingPCOTracker: false,
        pcoTracker: action?.payload,
      }
    case FETCH_PCO_TRACKER_FAILED:
      return {
        ...state,
        isFetchingPCOTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchPCOTrackerError: action.payload?.data,
      }
    // Save PCO tracker
    case SAVE_PCO_TRACKER_REQUESTED:
      return {
        ...state,
        isSavingPCOTracker: true,
      }
    case SAVE_PCO_TRACKER_SUCCESS:
      return {
        ...state,
        isSavingPCOTracker: false,
        pcoTracker: action.payload,
      }
    case SAVE_PCO_TRACKER_FAILED:
      return {
        ...state,
        isSavingPCOTracker: false,
        savePCOTrackerError: action.payload,
      }
    // Update PCO tracker
    case UPDATE_PCO_TRACKER_REQUESTED:
      return {
        ...state,
        isUpdatingPCOTracker: true,
      }
    case UPDATE_PCO_TRACKER_SUCCESS:
      return {
        ...state,
        isUpdatingPCOTracker: false,
        pcoTracker: action.payload,
      }
    case UPDATE_PCO_TRACKER_FAILED:
      return {
        ...state,
        isUpdatingPCOTracker: false,
        savePCOTrackerError: action.payload,
      }
    // Delete PCO tracker
    case DELETE_PCO_TRACKER_REQUESTED:
      return {
        ...state,
        isDeletingPCOTrackers: true,
      }
    case DELETE_PCO_TRACKER_SUCCESS:
      return {
        ...state,
        isDeletingPCOTrackers: false,
        pcoTracker: action.payload,
      }
    case DELETE_PCO_TRACKER_FAILED:
      return {
        ...state,
        isDeletingPCOTrackers: false,
      }

    /* Lien Waiver tracker */
    // Fetch Lien Waivers tracker
    case FETCH_LIEN_WAIVERS_REQUESTED:
      return {
        ...state,
        isFetchingLienWaiversTracker: true,
      }
    case FETCH_LIEN_WAIVERS_SUCCESS:
      return {
        ...state,
        isFetchingLienWaiversTracker: false,
        lienWaiversTracker: action?.payload,
      }
    case FETCH_LIEN_WAIVERS_FAILED:
      return {
        ...state,
        isFetchingLienWaiversTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchLienWaiversTrackerError: action.payload?.data,
      }
    // Save Lien Waivers tracker
    case SAVE_LIEN_WAIVERS_REQUESTED:
      return {
        ...state,
        isSavingLienWaiversTracker: true,
      }
    case SAVE_LIEN_WAIVERS_SUCCESS:
      return {
        ...state,
        isSavingLienWaiversTracker: false,
        lienWaiversTracker: action.payload,
      }
    case SAVE_LIEN_WAIVERS_FAILED:
      return {
        ...state,
        isSavingLienWaiversTracker: false,
        saveLienWaiversTrackerError: action.payload,
      }
    // Delete Lien Waivers
    case DELETE_LIEN_WAIVERS_REQUESTED:
      return {
        ...state,
        isDeletingLienWaiversTrackers: true,
      }
    case DELETE_LIEN_WAIVERS_SUCCESS:
      return {
        ...state,
        isDeletingLienWaiversTrackers: false,
        lienWaiversTracker: action.payload,
      }
    case DELETE_LIEN_WAIVERS_FAILED:
      return {
        ...state,
        isDeletingLienWaiversTrackers: false,
        deleteLienWaiversError: action.payload,
      }

    /* Stored material tracker */
    // Fetch stored material tracker
    case FETCH_STORED_MATERIAL_REQUESTED:
      return {
        ...state,
        isFetchingStoredMaterialTracker: true,
      }
    case FETCH_STORED_MATERIAL_SUCCESS:
      return {
        ...state,
        isFetchingStoredMaterialTracker: false,
        storedMaterialTracker: action?.payload,
      }
    case FETCH_STORED_MATERIAL_FAILED:
      return {
        ...state,
        isFetchingStoredMaterialTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchStoredMaterialTrackerError: action.payload?.data,
      }
    // Save stored material tracker
    case SAVE_STORED_MATERIAL_REQUESTED:
      return {
        ...state,
        isSavingStoredMaterialTracker: true,
      }
    case SAVE_STORED_MATERIAL_SUCCESS:
      return {
        ...state,
        isSavingStoredMaterialTracker: false,
        storedMaterialTracker: action.payload,
      }
    case SAVE_STORED_MATERIAL_FAILED:
      return {
        ...state,
        isSavingStoredMaterialTracker: false,
        saveStoredMaterialTrackerError: action.payload,
      }
    // Delete stored material
    case DELETE_STORED_MATERIAL_REQUESTED:
      return {
        ...state,
        isDeletingStoredMaterialTrackers: true,
      }
    case DELETE_STORED_MATERIAL_SUCCESS:
      return {
        ...state,
        isDeletingStoredMaterialTrackers: false,
        storedMaterialTracker: action.payload,
      }
    case DELETE_STORED_MATERIAL_FAILED:
      return {
        ...state,
        isDeletingStoredMaterialTrackers: false,
        deleteStoredMaterialError: action.payload,
      }
    case FETCH_G702_SPLIT_TRACKER_REQUESTED:
      return {
        ...state,
        isFetchingG702SplitTracker: true,
      }
    case FETCH_G702_SPLIT_TRACKER_SUCCESS:
      return {
        ...state,
        isFetchingG702SplitTracker: false,
        g702SplitTracker: action?.payload,
      }
    case FETCH_G702_SPLIT_TRACKER_FAILED:
      return {
        ...state,
        isFetchingG702SplitTracker: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        g702SplitTracker: null,
      }
    // Save G703 tracker
    case SAVE_G702_SPLIT_TRACKER_REQUESTED:
      return {
        ...state,
        isSavingG702SplitTracker: true,
      }
    case SAVE_G702_SPLIT_TRACKER_SUCCESS:
      return {
        ...state,
        isSavingG702SplitTracker: false,
        g702SplitTracker: action?.payload,
      }
    case SAVE_G702_SPLIT_TRACKER_FAILED:
      return {
        ...state,
        isSavingG702SplitTracker: false,
      }
    // Delete stored material
    case DELETE_G702_SPLIT_TRACKER_REQUESTED:
      return {
        ...state,
        isDeletingG702SplitTracker: true,
      }
    case DELETE_G702_SPLIT_TRACKER_SUCCESS:
      return {
        ...state,
        isDeletingG702SplitTracker: false,
        g702SplitTracker: null,
      }
    case DELETE_G702_SPLIT_TRACKER_FAILED:
      return {
        ...state,
        isDeletingG702SplitTracker: false,
      }
    case FETCH_REPORT_G702_SPLIT_TRACKER_REQUESTED:
      return {
        ...state,
        isFetchingG702SplitTrackerUniqueCodes: true,
      }
    case FETCH_REPORT_G702_SPLIT_TRACKER_SUCCESS:
      return {
        ...state,
        isFetchingG702SplitTrackerUniqueCodes: false,
        g702SplitTrackerUniqueCodes: action?.payload,
      }
    case FETCH_REPORT_G702_SPLIT_TRACKER_FAILED:
      return {
        ...state,
        isFetchingG702SplitTrackerUniqueCodes: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        g702SplitTrackerUniqueCodes: [],
      }
    case CLEAR_G702_SPLIT_TRACKER:
      return {
        ...state,
        isSavingG702SplitTracker: false,
        isFetchingG702SplitTracker: false,
        g702SplitTracker: null,
        isDeletingG702SplitTracker: false,
        isFetchingG702SplitTrackerUniqueCodes: true,
        g702SplitTrackerUniqueCodes: [],
      }
    case CLEAR_REPORT_TRACKER:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
