import React from 'react'
import ReactRouterPrompt from 'react-router-prompt'
import { ErrorBoundary } from 'react-error-boundary'
import DKTConfirmNavigateShowModal from './DKTConfirmNavigateShowModal'
import DKTButton from './DKTButton'

const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <div role="alert">
    <p>Something went wrong:</p>
    <pre>{error.message}</pre>
    <DKTButton onClick={resetErrorBoundary}>Try again</DKTButton>
  </div>
)

const DKTReactRouterPrompt = ({ isDirty, onSave }) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <ReactRouterPrompt when={isDirty}>
      {({ isActive, onConfirm, onCancel }) => (
        <DKTConfirmNavigateShowModal
          isActive={isActive}
          onSave={onSave}
          onConfirm={onConfirm}
          onCancel={onCancel}
        />
      )}
    </ReactRouterPrompt>
  </ErrorBoundary>
)

export default DKTReactRouterPrompt
