import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import React, { memo, useLayoutEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { ExpandIcon } from '../../Assets/SVGs'
import { sidebarLinks } from '../../Description/projectSidebar.description'
import { equal, matchRoutesLink, ternary } from '../../Utils/javascript'

const StyledAccordion = styled(Accordion)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '& .MuiAccordionSummary-root': {
    padding: 0,
    minHeight: 'auto',
    paddingRight: 8,
    '&.Mui-expanded': {
      minHeight: 'auto',
      margin: 0,
    },
  },
  '& .MuiAccordionSummary-content': {
    paddingLeft: 20,
    marginBlock: 0,
    paddingBlock: 8,
    '&.Mui-expanded': {
      minHeight: 'auto',
      margin: 0,
    },
  },
  '& .MuiAccordionDetails-root': {
    paddingRight: '8px',
    paddingLeft: 21,
    paddingBlock: 8,
  },
}))

const Sidebar = ({ currentProject }) => {
  const location = useLocation()
  const { palette } = useTheme()
  const { slug } = useParams()
  const [disabledLink, setDisabledLink] = useState([])

  useLayoutEffect(() => {
    if (currentProject?.disabledFields) {
      const { disabledFields = [] } = currentProject
      setDisabledLink(disabledFields)
    }
  }, [currentProject?.disabledFields])

  // when link is active prevent default on same link
  const handlePreventDefault = (e, isActive) => {
    if (isActive) {
      e.preventDefault()
    }
  }

  return (
    <Box
      backgroundColor="gray.main"
      minWidth="250px"
      sx={{ position: 'fixed', top: 164, bottom: 0 }}
    >
      <List sx={{ pt: 5 }}>
        {sidebarLinks?.map(
          (
            {
              text,
              url,
              dropdownItems,
              end = false,
              isNewProject,
              sideBarLinkId,
            },
            index,
          ) => {
            const isActive = matchRoutesLink({
              path: `/projects/:slug/${url}`,
              location,
            })
            return (
              <ListItem
                key={index}
                sx={{
                  borderLeft: ternary(dropdownItems, 0, 4),
                  fontSize: '14px',
                  paddingRight: 0,
                  ...ternary(
                    dropdownItems,
                    { padding: 0 },
                    ternary(
                      isActive,
                      {
                        fontWeight: 500,
                        borderColor: 'primary.main',
                        color: 'primary.main',
                        backgroundColor: 'primary.light',
                      },
                      { borderColor: 'transparent', color: '#000' },
                    ),
                  ),
                }}
              >
                {ternary(
                  dropdownItems,
                  <Box width="100%">
                    <StyledAccordion>
                      <AccordionSummary expandIcon={<ExpandIcon />}>
                        <Typography fontWeight={400} fontSize={14} color="#000">
                          {text}
                        </Typography>
                      </AccordionSummary>
                      {dropdownItems?.map((dropdownItem, index) => {
                        const isDropdownItemActive = matchRoutesLink({
                          path: dropdownItem.url,
                          location,
                        })
                        return (
                          <AccordionDetails
                            key={index}
                            sx={{
                              fontSize: '14px',
                              borderLeft: 4,
                              ...ternary(
                                isDropdownItemActive,
                                {
                                  borderColor: 'primary.main',
                                  backgroundColor: 'primary.light',
                                  fontWeight: 500,
                                },
                                {
                                  borderColor: 'transparent',
                                },
                              ),
                            }}
                          >
                            <Link
                              to={dropdownItem.url}
                              style={{
                                display: 'block',
                                width: '100%',
                                paddingRight: '16px',
                                textDecoration: 'none',
                                color: ternary(
                                  isDropdownItemActive,
                                  palette.primary.main,
                                  '#000',
                                ),
                              }}
                            >
                              {dropdownItem.text}
                            </Link>
                          </AccordionDetails>
                        )
                      })}
                    </StyledAccordion>
                  </Box>,
                  ternary(
                    (!slug && isNewProject) ||
                      disabledLink?.some((link) => equal(link, sideBarLinkId)),
                    <Link
                      to={`/projects/${slug}/${url}`}
                      style={{
                        display: 'block',
                        width: '100%',
                        paddingRight: '16px',
                        textDecoration: 'none',
                        pointerEvents: 'none',
                        userSelect: 'none',
                        color: 'grey',
                      }}
                      end={end || false}
                    >
                      {text}
                    </Link>,
                    <Link
                      to={`/projects/${slug}/${url}`}
                      style={{
                        display: 'block',
                        width: '100%',
                        paddingRight: '16px',
                        textDecoration: 'none',
                        color: ternary(isActive, palette.primary.main, '#000'),
                      }}
                      end={end || false}
                      onClick={(e) => handlePreventDefault(e, isActive)}
                    >
                      {text}
                    </Link>,
                  ),
                )}
              </ListItem>
            )
          },
        )}
      </List>
    </Box>
  )
}

export default memo(Sidebar)
