import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import {
  FETCH_PROJECT_CHOICES_FAILED,
  FETCH_PROJECT_CHOICES_REQUESTED,
  FETCH_PROJECT_CHOICES_SUCCESS,
} from '../constants/choices'

const fetchProjectChoicesRequested = () => ({
  type: FETCH_PROJECT_CHOICES_REQUESTED,
})
const fetchProjectChoicesSuccess = (payload) => ({
  type: FETCH_PROJECT_CHOICES_SUCCESS,
  payload,
})
const fetchProjectChoicesFailed = (payload) => ({
  type: FETCH_PROJECT_CHOICES_FAILED,
  payload,
})
export const getProjectChoices =
  ({ fieldName }) =>
  async (dispatch, getState) => {
    const { choices } = getState().projectChoices
    dispatch(fetchProjectChoicesRequested())
    const { data, error } =
      fieldName &&
      (await api({
        data: { fieldName },
        method: method.get,
        endPoint: `${apiEndPoints.projectChoices}?fieldName=${fieldName}`,
      }))
    if (data) {
      dispatch(
        fetchProjectChoicesSuccess({
          ...choices,
          ...data,
        }),
      )
      return
    }
    if (error) {
      dispatch(fetchProjectChoicesFailed(error?.response?.data))
    }
  }
