import { equal, ternary } from '../../Utils/javascript'
import {
  ADD_CONSTRUCTION_SCHEDULE,
  CLEAR_CONSTRUCTION_SCHEDULE,
  CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_FAILED,
  CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_REQUESTED,
  CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_SUCCESS,
  DELETE_CONSTRUCTION_SCHEDULE_FAILED,
  DELETE_CONSTRUCTION_SCHEDULE_REQUESTED,
  DELETE_CONSTRUCTION_SCHEDULE_SUCCESS,
  FETCH_CONSTRUCTION_SCHEDULE_FAILED,
  FETCH_CONSTRUCTION_SCHEDULE_REQUESTED,
  FETCH_CONSTRUCTION_SCHEDULE_SUCCESS,
  FETCH_OWNER_CONTRACTOR_MAPPING_FAILED,
  FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED,
  FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS,
} from '../constants/constructionSchedule'

const initialState = {
  isCreateAndUpdateConstructionScheduleLoading: false,
  isConstructionScheduleListLoading: true,
  constructionScheduleList: [],
  ownerContractorMapping: [],
  isOwnerContractorMapping: false,
  activeConstruction: null,
  constructionScheduleError: null,
  isDeletingConstructionSchedule: false,
}

export const constructionScheduleReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    // Fetch construction schedule
    case FETCH_CONSTRUCTION_SCHEDULE_REQUESTED:
      return {
        ...state,
        isConstructionScheduleListLoading: true,
      }
    case FETCH_CONSTRUCTION_SCHEDULE_SUCCESS:
      return {
        ...state,
        isConstructionScheduleListLoading: false,
        constructionScheduleList: action?.payload,
        constructionScheduleError: null,
      }
    case FETCH_CONSTRUCTION_SCHEDULE_FAILED:
      return {
        ...state,
        isConstructionScheduleListLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        constructionScheduleList: [],
      }
    // Create and update construction schedule
    case CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateConstructionScheduleLoading: true,
      }
    case CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_SUCCESS:
      return {
        ...state,
        isCreateAndUpdateConstructionScheduleLoading: false,
        constructionScheduleList: action?.payload,
        activeConstruction: ternary(
          action?.activeConstruction,
          action?.activeConstruction,
          state?.activeConstruction,
        ),
        constructionScheduleError: null,
      }
    case CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_FAILED:
      return {
        ...state,
        isCreateAndUpdateConstructionScheduleLoading: false,
        constructionScheduleError: action.payload,
      }
    // Fetch owner contractor mapping
    case FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED:
      return {
        ...state,
        isOwnerContractorMapping: true,
      }
    case FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS:
      return {
        ...state,
        isOwnerContractorMapping: false,
        ownerContractorMapping: action?.payload,
      }
    case FETCH_OWNER_CONTRACTOR_MAPPING_FAILED:
      return {
        ...state,
        isOwnerContractorMapping: false,
      }
    // Add construction schedule
    case ADD_CONSTRUCTION_SCHEDULE:
      return {
        ...state,
        constructionScheduleList: action.payload,
      }
    // Delete construction schedule
    case DELETE_CONSTRUCTION_SCHEDULE_REQUESTED:
      return {
        ...state,
        isDeletingConstructionSchedule: true,
      }
    case DELETE_CONSTRUCTION_SCHEDULE_SUCCESS:
      return {
        ...state,
        isDeletingConstructionSchedule: false,
        constructionScheduleList: action.payload,
        activeConstruction: action?.activeConstruction,
      }
    case DELETE_CONSTRUCTION_SCHEDULE_FAILED:
      return {
        ...state,
        isDeletingConstructionSchedule: false,
      }
    case CLEAR_CONSTRUCTION_SCHEDULE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
