import { equal, ternary } from '../../Utils/javascript'
import {
  CREATE_AND_UPDATE_PROJECT_FAILED,
  CREATE_AND_UPDATE_PROJECT_REQUESTED,
  CREATE_AND_UPDATE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILED,
  DELETE_PROJECT_REQUESTED,
  DELETE_PROJECT_SUCCESS,
  FETCH_PROJECT_FIELD_CHOICES_REQUESTED,
  FETCH_PROJECT_LIST_FAILED,
  FETCH_PROJECT_LIST_REQUESTED,
  FETCH_PROJECT_LIST_SUCCESS,
  FETCH_PROJECT_SUCCESS,
  SET_FIELD_CHOICES_DATA,
  CLEAR_PROJECTS,
} from '../constants/projects'

const initialState = {
  isCreateAndUpdateProjectLoading: false,
  isProjectListLoading: true,
  projectList: [],
  currentProject: null,
  isProjectDeletingLoading: false,
  projectOptionChoices: {},
  projectError: '',
}

export const projectReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch projects list
    case FETCH_PROJECT_LIST_REQUESTED:
      return {
        ...state,
        isProjectListLoading: true,
        projectError: '',
      }
    case FETCH_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        isProjectListLoading: false,
        projectList: action?.payload,
      }
    case FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        isProjectListLoading: false,
        currentProject: action?.payload,
      }
    case FETCH_PROJECT_LIST_FAILED:
      return {
        ...state,
        isProjectListLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        projectList: [],
      }
    // Create and update projects
    case CREATE_AND_UPDATE_PROJECT_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateProjectLoading: true,
        projectError: '',
      }
    case CREATE_AND_UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        isCreateAndUpdateProjectLoading: false,
      }
    case CREATE_AND_UPDATE_PROJECT_FAILED:
      return {
        ...state,
        isCreateAndUpdateProjectLoading: false,
        projectError: action.payload,
      }
    // Delete projects
    case DELETE_PROJECT_REQUESTED:
      return {
        ...state,
        isProjectDeletingLoading: true,
      }
    case DELETE_PROJECT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const cloneProjectList = [...state.projectList]
      // eslint-disable-next-line no-case-declarations
      const updatedProjectList = cloneProjectList?.filter(
        (project) => !action?.payload?.includes(project?.id),
      )
      return {
        ...state,
        isProjectDeletingLoading: false,
        projectList: updatedProjectList,
      }
    case DELETE_PROJECT_FAILED:
      return {
        ...state,
        isProjectDeletingLoading: false,
      }
    case FETCH_PROJECT_FIELD_CHOICES_REQUESTED:
      return {
        ...state,
        isfetchProjectFieldChoicesLoading: true,
      }
    case SET_FIELD_CHOICES_DATA:
      return {
        ...state,
        projectOptionChoices: {
          ...state.projectOptionChoices,
          ...action.payload,
        },
      }
    case CLEAR_PROJECTS:
      return {
        ...state,
        projectList: [],
      }
    default:
      return state
  }
}
