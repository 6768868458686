import { Box, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import { InnerLayout } from '../../../Layout/rfp'
import DKTButton from '../../../Shared/DKTButton'
import {
  sendandReturnRFPNotification,
  updateRFP,
} from '../../../Redux/actions/rfp'
import DKTAccordion from '../../../Shared/DKTAccordion'
import BidsAndFinalSec from './BidsAndFinalSec'
import RfpDetailsAndConsultants from './RfpDetailsAndConsultants'
import { equal, isEmptyString, keys, ternary } from '../../../Utils/javascript'
import DKTDialog from '../../../Shared/DKTDialog'
import { rfpDetailsForm } from '../../../Description/rfpDetails.description'
import useForm from '../../../Hooks/useForm'
import { spinnerSize } from '../../../Utils/constant'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { showToast } from '../../../Utils/toastService'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { CLEAR_RFP_DATA } from '../../../Redux/constants/rfp'
import DKTTooltip from '../../../Shared/DKTTooltip'
import CreateNewRFP from './CreateNewRFP'

const useStyles = makeStyles(() => ({
  compInfoOuter: {
    '& .MuiAccordion-root': {
      marginBottom: 5,
      '& .MuiAccordionSummary-content': {
        margin: [[20, 0]],
      },
      '&.Mui-expanded': {
        '& .MuiAccordionSummary-root': {
          backgroundColor: '#1564FF',
          color: '#fff',
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: '#fff',
          },
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: 16,
      },
    },
  },
  compInfoAccordion: {
    '&.MuiAccordionSummary-root': {
      backgroundColor: '#C6C6C6',
      color: 'black',
      fontSize: '14px',
    },
  },
}))

const RfpProjectDashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const formValues = useForm(
    {
      ...rfpDetailsForm.requestForProposalDetails,
      ...rfpDetailsForm.services,
    },
    CLEAR_RFP_DATA,
  )

  const { values, setValues, dummyData, setDummyData, handleSubmit } =
    formValues

  const {
    rfpProjectData,
    isLoadingSingleRfp,
    sendRFPLoading,
    updateRFPLoading,
    rfpCompanyList,
    fetchBidsLoading,
  } = useSelector((state) => state.rfpData)

  const consultantNames = ternary(
    values?.selectedCompanies?.length,
    values.selectedCompanies,
    [],
  )
    .map(
      (id) => rfpCompanyList?.find((item) => equal(id, item?.id))?.companyName,
    )
    .filter((item) => !isEmptyString(item))

  useEffect(() => {
    if (keys(rfpProjectData)?.length) {
      const data = {
        ...rfpProjectData,
        finalSelectedConsultant:
          rfpProjectData?.finalSelectedConsultant || 'pending',
      }
      setValues(data)
      setDummyData(data)
    }
  }, [rfpProjectData])

  useEffect(() => {
    setIsDirty(() => {
      const data = keys(values)?.map(
        (key) => !equal(String(values[key]), String(dummyData[key])),
      )
      return data?.flat()?.some((data) => equal(data, true))
    })
  }, [values, dummyData])

  const handleOpenModal = (isOpen = false) => {
    setOpenModal(isOpen)
  }

  const handleOpenSendRFPModal = () => {
    const isFormValid = handleSubmit()
    if (
      !isFormValid ||
      !values?.scopeOfServices?.length ||
      !values?.selectedCompanies?.length
    ) {
      dispatch(updateOnSaveStatus({ cancel: true }))
      showToast(
        'Complete Required Fields (*), select Scope of Services, and Available Consultant to send the RFP notification',
      )
    } else {
      handleOpenModal(true)
    }
  }

  const handlePayload = (formData) =>
    Object.keys(formData).reduce((acc, key) => {
      acc[key] = formData[key] === '' ? null : formData[key]
      return acc
    }, {})

  const handleSave = useMemo(
    () => () => {
      const onSucces = () => {
        setIsDirty(false)
      }
      const payloadValues = { ...values }
      if (!equal(payloadValues?.finalSelectedConsultant, 'other')) {
        payloadValues.finalSelectedConsultantOtherNonAcreiq = null
      }
      const payload = handlePayload(payloadValues)
      dispatch(updateRFP(payload, onSucces))
    },
    [values, dispatch],
  )

  const sendRFP = () => {
    const onSucces = () => {
      setIsDirty(false)
      handleOpenModal()
    }
    dispatch(
      sendandReturnRFPNotification(
        { id: rfpProjectData?.id, rfp_link: window.location.pathname },
        onSucces,
      ),
    )
  }

  const actions = () => (
    <Stack direction="row" gap={2}>
      <DKTTooltip
        title={
          !equal(values?.finalSelectedConsultant, 'pending') ||
          values?.finalSelectedConsultantOtherNonAcreiq
            ? ''
            : isLoadingSingleRfp ||
              sendRFPLoading ||
              !values?.isSaved ||
              isDirty
            ? 'To enable this button, complete Steps 1, 2 & 3 and Save'
            : ''
        }
      >
        <Box sx={{ cursor: 'pointer' }}>
          <DKTButton
            onClick={handleOpenSendRFPModal}
            variant="outlined"
            sx={{ backgroundColor: 'white' }}
            disabled={
              isLoadingSingleRfp ||
              sendRFPLoading ||
              !values?.isSaved ||
              isDirty ||
              !equal(values?.finalSelectedConsultant, 'pending') ||
              values?.finalSelectedConsultantOtherNonAcreiq
            }
          >
            Send RFP Notification
          </DKTButton>
        </Box>
      </DKTTooltip>
      <DKTButton
        onClick={handleSave}
        disabled={updateRFPLoading || isLoadingSingleRfp}
      >
        {ternary(updateRFPLoading, 'Saving...', 'Save')}
      </DKTButton>
    </Stack>
  )

  const modalActions = (
    <DKTButton
      variant="contained"
      onClick={sendRFP}
      disabled={sendRFPLoading}
      sx={{ ml: 'auto' }}
      startIcon={
        sendRFPLoading && (
          <DKTCircularProgress size={spinnerSize?.sm} color="grey" />
        )
      }
    >
      Yes
    </DKTButton>
  )

  return (
    <>
      <InnerLayout actions={actions}>
        {equal(isLoadingSingleRfp, true) || equal(fetchBidsLoading, true) ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <DKTCircularProgress />
          </Stack>
        ) : equal(isLoadingSingleRfp, 'FAILED') ||
          equal(fetchBidsLoading, 'FAILED') ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching RFP Data. Please try
              contacting the admin or refreshing this page.
            </Typography>
          </Stack>
        ) : (
          <>
            <Box>
              <Stack pb={2} pt={1} backgroundColor="#fff" mb={2}>
                <Box mb={2}>
                  <Typography variant="h6" fontWeight="bold">
                    {values?.projectName}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    component="a"
                    href={values?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    {values?.fullAddress}
                  </Typography>
                  <EditIcon
                    fontSize="small"
                    color="primary"
                    onClick={() => setIsOpen(true)}
                    cursor="pointer"
                    sx={{ ml: 1, verticalAlign: 'text-bottom' }}
                  />
                </Box>
              </Stack>
            </Box>
            <Box>
              <Box className={classes.compInfoOuter}>
                <DKTAccordion
                  className={classes.compInfoAccordion}
                  accordionTitle="RFP Details & Consultant Options"
                  idTag="rfp-details-and-consultant-options"
                  defaultExpanded
                >
                  <RfpDetailsAndConsultants formValues={formValues} />
                </DKTAccordion>
                <DKTAccordion
                  className={classes.compInfoAccordion}
                  accordionTitle="Consultant Bids & Final Selection"
                  idTag="consultant-bids-and-final-selection"
                  defaultExpanded
                >
                  <BidsAndFinalSec formValues={formValues} />
                </DKTAccordion>
              </Box>
            </Box>
            <DKTDialog
              open={openModal}
              handleClose={() => handleOpenModal()}
              actions={modalActions}
              title="RFP Notification"
              maxWidth="xs"
            >
              <Typography
                variant="h6"
                color="#646464"
                textAlign="center"
                fontSize={18}
              >
                An RFP notification email will be sent to{' '}
                {consultantNames?.join(', ')}. Continue?
              </Typography>
            </DKTDialog>
            <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
          </>
        )}
      </InnerLayout>
      {isOpen && (
        <CreateNewRFP
          open={isOpen}
          handleClose={() => setIsOpen(false)}
          data={values}
        />
      )}
    </>
  )
}

export default RfpProjectDashboard
