import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  appendixColumns,
  options,
} from '../../../Description/appendix.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import {
  getAppendix,
  importAppendixComments,
  saveAppendix,
} from '../../../Redux/actions/appendix'
import { getReportChoices } from '../../../Redux/actions/reportChoices'
import DKTButton from '../../../Shared/DKTButton'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTEditor from '../../../Shared/DKTEditor'
import { useStyles } from '../../../Styles/appendix.style'
import { importButtonTooltip, spinnerSize } from '../../../Utils/constant'
import {
  equal,
  handleTextEditor,
  isArray,
  ternary,
} from '../../../Utils/javascript'
import Documents from './Documents'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTTooltip from '../../../Shared/DKTTooltip'
import DKTDialog from '../../../Shared/DKTDialog'
import { CLEAR_APPENDIX } from '../../../Redux/constants/appendix'
import usePermission from '../../../Hooks/usePermission'

const optionsFields = ['ownerContractor']

const Appendix = () => {
  const {
    appendix,
    isSavingAppendix,
    isImportingComments,
    comments,
    isFetchingAppendix,
  } = useSelector(({ appendix }) => appendix)
  const [data, setData] = useState(appendix)
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { slug: projectId, reportId } = useParams()
  const [ownerContractorId, setOwnerContractorId] = useState(null)
  const [ownerContractorUniqueCode, setOwnerContractorUniqueCode] =
    useState(null)
  const [dragDataDirty, setDragDataDirty] = useState(false)
  const [isImportWarningOpen, setIsImportWarningOpen] = useState(false)

  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { hasOnlyViewPermission } = usePermission()
  const { choices } = useSelector(({ reportChoices }) => reportChoices)
  const { values, setFieldValue, isDirty, setIsDirty } = useForm()

  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionsFields?.join(','),
        projectId,
      }),
    )
  }, [dispatch, projectId])

  useEffect(() => {
    if (reportId && ownerContractorId && projectId)
      dispatch(getAppendix(reportId, ownerContractorId, projectId))
  }, [dispatch, ownerContractorId])

  // For get dynamic OC list
  useEffect(() => {
    let activeContractor = null
    if (isArray(choices?.ownerContractor) && choices?.ownerContractor?.length) {
      activeContractor = choices?.ownerContractor[0]
    }
    setOwnerContractorId(activeContractor?.id)
    setOwnerContractorUniqueCode(activeContractor?.uniqueCode)
  }, [choices?.ownerContractor[0]?.id])

  // For set current owner-contractor description
  useEffect(() => {
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    setFieldValue('ownerContractorDescription', activeContractorDescription)
  }, [ownerContractorId])

  useEffect(() => {
    setFieldValue('comments', comments, 'comments')
  }, [comments])

  useEffect(() => {
    if (data?.length && data?.length > appendix?.length) {
      setIsDirty(true)
    }
  }, [data])

  // clear appendix data
  useEffect(
    () => () => {
      dispatch({ type: CLEAR_APPENDIX })
    },
    [],
  )

  /* IMPORT FROM LAST REPORT */
  const showImportWarning = () => setIsImportWarningOpen(true)
  const hideImportWarning = () => setIsImportWarningOpen(false)
  const handleImport = useCallback(
    (name) => {
      if (values.comments) {
        showImportWarning()
        return
      }
      dispatch(
        importAppendixComments({
          projectId,
          ocId: ownerContractorId,
          onSuccess: (value) => setFieldValue(name, value),
          reportId,
        }),
      )
    },
    [projectId, ownerContractorId, dispatch, values],
  )
  const handleImportOverWrite = () => {
    const onSuccess = (value) => {
      setFieldValue('comments', value)
      hideImportWarning()
    }
    dispatch(
      importAppendixComments({
        projectId,
        ocId: ownerContractorId,
        onSuccess,
        reportId,
      }),
    )
  }

  const importWarningActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={hideImportWarning}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleImportOverWrite}
        disabled={isImportingComments}
      >
        {ternary(
          isImportingComments,
          <DKTCircularProgress
            size={spinnerSize?.sm}
            {...ternary(
              isImportingComments,
              { color: theme.palette.gray.dark },
              {},
            )}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const addRow = () =>
    setData((prevData) => [
      ...prevData,
      {
        id: `${new Date().valueOf()}`,
        document: '',
        status: '',
        includeInAppendix: '',
        isEditable: true,
        isDraggable: true,
        isStatus: true,
        isIncludeInAppendix: true,
      },
    ])

  const onDragEnd = (result) => {
    const forbidRange = data?.filter((row) => !row?.isDraggable)?.length // Depends on the number of static rows in the appendix
    if (result?.destination?.index !== result?.source?.index) {
      setDragDataDirty(true)
    }
    if (result.destination && result.source) {
      if (forbidRange < result.destination.index) {
        setData((prevData) => {
          const clonedData = [...prevData]
          clonedData.splice(result.source.index, 1)
          clonedData.splice(
            result.destination.index,
            0,
            prevData[result.source.index],
          )

          return clonedData
        })
      }
    }
  }
  const handleSave = () => {
    setIsDirty(false)
    setDragDataDirty(false)
    dispatch(
      saveAppendix({
        payload: { data, comments: values?.comments },
        reportId,
        ocId: ownerContractorId,
        projectId,
        ownerContractorUniqueCode,
      }),
    )
  }

  const actionList = [{ label: 'Delete', onClick: () => null }]

  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" gap={2}>
          <DKTButtonSelect
            options={actionList}
            disabled={hasOnlyViewPermission || isFetchingAppendix || true}
          />
          <DKTButton
            className={classes.rightButton}
            onClick={handleSave}
            disabled={
              hasOnlyViewPermission || isSavingAppendix || isFetchingAppendix
            }
          >
            {ternary(isSavingAppendix, 'Saving...', 'Save')}
          </DKTButton>
        </Stack>
      </Box>
    </>
  )

  return (
    <InnerLayout
      contentTitle="APPENDIX"
      actions={actions}
      maxWidth="md"
      isShowMenu
    >
      {equal(isFetchingAppendix, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isFetchingAppendix, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Appendix data. Please try
            contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Grid container spacing={2} mt={6}>
            <Grid item xs={12} lg={10}>
              <Box className={classes.contactsTable}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Table
                    className={classes.commonTable}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        {appendixColumns?.map((column, index) => (
                          <TableCell key={index}>{column}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <Droppable droppableId="appendixDocumentRow">
                      {(provided) => (
                        <TableBody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <Documents
                            appendixColumns={appendixColumns}
                            data={data}
                            setData={setData}
                            options={options}
                            providedPlaceholder={provided.placeholder}
                            hasOnlyViewPermission={hasOnlyViewPermission}
                            setIsDirty={setIsDirty}
                          />
                        </TableBody>
                      )}
                    </Droppable>
                  </Table>
                </DragDropContext>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={3} alignItems="flex-end">
            <Grid item xs={6} md={6} lg={6}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ position: 'relative' }}
              >
                <DKTButton
                  variant="text"
                  color="primary"
                  sx={{ textTransform: 'initial', px: 1, ml: '10px', mt: 2 }}
                  startIcon={
                    <CircledPlusIcon disabled={hasOnlyViewPermission} />
                  }
                  onClick={addRow}
                  disabled={hasOnlyViewPermission}
                >
                  <Typography>New Document Row</Typography>
                </DKTButton>
              </Stack>
            </Grid>
          </Grid>
          {/* Comments */}
          <Grid container mt={8}>
            <Grid item xs={12} lg={10}>
              <Stack
                direction="row"
                alignItems="end"
                justifyContent="space-between"
                mb={1}
              >
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Comments
                </Typography>

                <DKTTooltip title={importButtonTooltip}>
                  <Box>
                    <DKTButton
                      variant="outlined"
                      onClick={() => handleImport('comments')}
                      sx={{
                        borderColor: '#ACACAC',
                        width: '91px',
                        height: '32px',
                        color: '#2D2D2D',
                        '&:hover': {
                          borderColor: '#000',
                        },
                      }}
                      disabled={hasOnlyViewPermission || isImportingComments}
                    >
                      {ternary(
                        isImportingComments,
                        <DKTCircularProgress color={theme.palette.gray.dark} />,
                        'Import',
                      )}
                    </DKTButton>
                  </Box>
                </DKTTooltip>
              </Stack>
              <DKTEditor
                value={values?.comments || ''}
                onChange={(event, editor) => {
                  handleTextEditor(editor, 'comments', setFieldValue)
                }}
                disabled={hasOnlyViewPermission}
                className={classes.projectDesc}
              />
            </Grid>
          </Grid>
        </>
      )}
      {/* Import content over-write warning */}
      <DKTDialog
        open={isImportWarningOpen}
        handleClose={hideImportWarning}
        title="&nbsp;"
        actions={importWarningActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Existing data will be overwritten.
              </Typography>
              <Typography variant="body1">Continue?</Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {!hasOnlyViewPermission && (
        <DKTReactRouterPrompt
          isDirty={isDirty || dragDataDirty}
          onSave={handleSave}
        />
      )}
    </InnerLayout>
  )
}

export default Appendix
