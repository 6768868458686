import { Button, styled } from '@mui/material'
import React from 'react'

import { colors, variants } from '../Description/dktButton.description'
import { equal, ternary } from '../Utils/javascript'

const StyledButton = styled(Button)(({ theme, size }) => ({
  borderRadius: 0,
  fontSize: 14,
  textTransform: 'initial',
  height: ternary(equal(size, 'sm'), 32, 40),
  fontWeight: 400,
  paddingInline: theme.spacing(4),
}))

const DKTButton = ({
  children,
  variant = 'primary',
  size = 'md',
  color,
  ...rest
}) => (
  <StyledButton
    variant={variants[variant] || 'contained'}
    color={colors[variant] || color}
    disableElevation
    size={size}
    {...rest}
  >
    {children}
  </StyledButton>
)

export default DKTButton
