import { Box, Grid } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { formFields } from '../../Description/forgotPassword.description'
import useForm from '../../Hooks/useForm'
import { sendResetPasswordMail } from '../../Redux/actions/auth'
import DKTButton from '../../Shared/DKTButton'
import { useStyles } from '../../Styles/forgotPassword.style'
import { ternary } from '../../Utils/javascript'
import { renderFormFields } from '../../Utils/renderFormFields'
import companyLogo from '../../Assets/company-logo.jpg'
import loginImage from '../../Assets/login.jpg'

const ForgotPassword = () => {
  const { isSendingMail } = useSelector((state) => state.auth)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { values, handleChange, handleSubmit, errors } = useForm(formFields)

  const sendMail = (e) => {
    e.preventDefault()
    const isFormValid = handleSubmit()
    if (!isFormValid) return
    dispatch(sendResetPasswordMail(values))
  }

  return (
    <Grid container className={classes.loginContainer}>
      <Grid item md={5}>
        <img src={loginImage} alt="company-logo" loading="lazy" />
      </Grid>
      <Grid item md={7} px={10} className={classes.loginForm}>
        <Box component="form" onSubmit={sendMail}>
          <img src={companyLogo} alt="company-logo" loading="lazy" />
          <Grid container mb={1}>
            {renderFormFields({ errors, formFields, values, handleChange })}
          </Grid>

          <Link to="/login" className={classes.forgotPasswordLink}>
            <DKTButton type="button" variant="outlined">
              Back
            </DKTButton>
          </Link>
          <DKTButton type="submit" disabled={isSendingMail}>
            {ternary(isSendingMail, 'Sending...', 'Send')}
          </DKTButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword
