import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useStyles } from '../../../Styles/constructionSummary.style'
import { importButtonTooltip, spinnerSize } from '../../../Utils/constant'
import { ternary } from '../../../Utils/javascript'
import DKTTooltip from '../../../Shared/DKTTooltip'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { importFromLastReport } from '../../../Redux/actions/constructionSummary'
import DKTDialog from '../../../Shared/DKTDialog'
import { ManpowerTrackerButton } from './ConstructionSummary'
import DKTEditor from '../../../Shared/DKTEditor'

const FreeForm = ({
  handleCollectDataFromChild,
  ownerContractorId,
  dataForUpdate,
  constructionSummaryData,
  hasOnlyViewPermission,
  setFreeFormIsDirty,
  showHideManpowerTracker,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { slug: projectId, reportId } = useParams()
  const dispatch = useDispatch()
  const importFieldName = useRef(null)
  const [descriptionData, setDescriptionData] = useState('')
  const [isImportWarningOpen, setIsImportWarningOpen] = useState(false)
  const { isImporting } = useSelector(
    ({ constructionSummary }) => constructionSummary,
  )
  useEffect(() => {
    if (constructionSummaryData) {
      setDescriptionData(constructionSummaryData?.freeForm)
    }
  }, [constructionSummaryData])

  useEffect(() => {
    if (dataForUpdate?.freeForm && !descriptionData) {
      setDescriptionData(dataForUpdate?.freeForm)
    }
  }, [dataForUpdate])

  const handleChange = (data) => {
    setDescriptionData(data)
    handleCollectDataFromChild({
      freeForm: data || '',
    })
  }
  if (descriptionData !== constructionSummaryData?.freeForm) {
    setFreeFormIsDirty(true)
  } else {
    setFreeFormIsDirty(false)
  }
  /* IMPORT FROM LAST REPORT */
  const showImportWarning = () => setIsImportWarningOpen(true)
  const hideImportWarning = () => setIsImportWarningOpen(false)
  const handleImport = useCallback(
    (name) => {
      importFieldName.current = null
      if (descriptionData) {
        importFieldName.current = name
        showImportWarning()
        return
      }
      dispatch(
        importFromLastReport({
          query: name,
          projectId,
          reportId,
          ocId: ownerContractorId,
          onSuccess: (value) => handleChange(value),
        }),
      )
    },
    [projectId, ownerContractorId, dispatch, descriptionData],
  )
  const handleImportOverWrite = () => {
    if (importFieldName.current) {
      const onSuccess = (value) => {
        handleChange(value)
        hideImportWarning()
      }
      dispatch(
        importFromLastReport({
          query: importFieldName.current,
          projectId,
          ocId: ownerContractorId,
          reportId,
          onSuccess,
        }),
      )
    }
  }
  const importWarningActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={hideImportWarning}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleImportOverWrite}
        disabled={Object.values(isImporting).some((value) => value)}
      >
        {ternary(
          Object.values(isImporting).some((value) => value),
          <DKTCircularProgress
            size={spinnerSize?.sm}
            color={theme.palette.gray.dark}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{ marginBlock: 2 }}
        spacing={2}
      >
        <Box>
          <ManpowerTrackerButton
            showHideManpowerTracker={showHideManpowerTracker}
            sx={{ marginTop: '6px' }}
          />
        </Box>
        <DKTTooltip title={importButtonTooltip}>
          <Box>
            <DKTButton
              variant="outlined"
              onClick={() => handleImport('freeForm')}
              sx={{
                maxWidth: 'max-content',
                borderColor: '#ACACAC',
                color: '#2D2D2D',
                width: '91px',
                height: '32px',
                '&:hover': {
                  borderColor: '#000',
                },
              }}
              disabled={hasOnlyViewPermission || isImporting?.freeForm}
            >
              {ternary(
                isImporting?.freeForm,
                <DKTCircularProgress
                  size={spinnerSize?.sm}
                  color={theme.palette.gray.dark}
                />,
                'Import',
              )}
            </DKTButton>
          </Box>
        </DKTTooltip>
      </Stack>
      <DKTEditor
        value={descriptionData || ''}
        onChange={(event, editor) => {
          const data = editor.getData()
          handleChange(data)
        }}
        disabled={hasOnlyViewPermission}
        className={classes.freeForm}
      />
      {/* Import content over-write warning */}
      <DKTDialog
        open={isImportWarningOpen}
        handleClose={hideImportWarning}
        title="&nbsp;"
        actions={importWarningActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Existing data will be overwritten.
              </Typography>
              <Typography variant="body1">Continue?</Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
    </>
  )
}

export default FreeForm
