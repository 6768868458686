import { Box, CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  labelCircularProgress: {
    position: 'relative',
    display: 'inline-flex',
  },
  progressValue: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const DKTLabelCircularProgress = ({ progress }) => {
  const classes = useStyles()
  return (
    <Box className={classes.labelCircularProgress}>
      <CircularProgress variant="determinate" value={progress} />
      <Box className={classes.progressValue}>
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export default DKTLabelCircularProgress
