import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  resetButton: {
    whiteSpace: 'nowrap',
    padding: 0,
    width: '100%',
  },
  datatTable: {
    '& .MuiToolbar-root': {
      backgroundColor: '#F4F4F4',
      padding: 10,
    },
    '& tbody': {
      '& .MuiButtonBase-root': {
        height: 'auto',
        padding: '0',
        lineHeight: '0.7',
        fontSize: 14,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      },
      '& p': {
        fontSize: 12,
        marginTop: 5,
        lineHeight: '1',
      },
    },
  },
  selectType: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderBottom: '1px solid #C4C4C4',
    },
  },
  projectTab: {
    boxShadow: 'none',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .MuiCardContent-root': {
      padding: '16px 16px 12px ',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  oneCard: {
    border: '2px solid #d1e1ff',
    boxShadow: '0 0 3px #d1e1ff',
    '&.MuiTypography-body1 ': {
      color: '#ff3838',
    },
  },
  projectList: {
    height: '100%',
    '& .MuiPaper-root': {
      '& .tss-1cdcmys-MUIDataTable-responsiveBase': {
        height: '100% !important',
      },
    },
  },
  selectBox: {
    marginLeft: 'auto',
    '& .MuiInputBase-root': {
      marginTop: 0,
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      backgroundColor: 'transparent',
      borderColor: '#1564FF',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
  imageWrapper: {
    '& img': {
      width: '100%',
      objectFit: 'cover',
    },
  },
  imagePlaceholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}))
