// Fetch issues log
export const FETCH_ISSUES_LOG_REQUESTED = 'FETCH_ISSUES_LOG_REQUESTED'
export const FETCH_ISSUES_LOG_SUCCESS = 'FETCH_ISSUES_LOG_SUCCESS'
export const FETCH_ISSUES_LOG_FAILED = 'FETCH_ISSUES_LOG_FAILED'

// Add Update issues log
export const ADD_UPDATE_ISSUE_REQUESTED = 'ADD_UPDATE_ISSUE_REQUESTED'
export const ADD_UPDATE_ISSUE_SUCCESS = 'ADD_UPDATE_ISSUE_SUCCESS'
export const ADD_UPDATE_ISSUE_FAILED = 'ADD_UPDATE_ISSUE_FAILED'

// Delete issues log
export const DELETE_ISSUE_REQUESTED = 'DELETE_ISSUE_REQUESTED'
export const DELETE_ISSUE_SUCCESS = 'DELETE_ISSUE_SUCCESS'
export const DELETE_ISSUE_FAILED = 'DELETE_ISSUE_FAILED'

// Clear issues log
export const CLEAR_ISSUES_LOG = 'CLEAR_ISSUES_LOG'
