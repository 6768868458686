import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  confirmNavigationSHowModalContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))
