import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_APPENDIX,
  FETCH_APPENDIX_FAILED,
  FETCH_APPENDIX_REQUESTED,
  FETCH_APPENDIX_SUCCESS,
  IMPORT_APPENDIX_COMMENTS_COMMENTS_REQUESTED,
  IMPORT_APPENDIX_REPORT_COMMENTS_FAILED,
  IMPORT_APPENDIX_REPORT_COMMENTS_SUCCESS,
  SAVE_APPENDIX_FAILED,
  SAVE_APPENDIX_REQUESTED,
  SAVE_APPENDIX_SUCCESS,
} from '../constants/appendix'

const initialState = {
  isSavingAppendix: false,
  isFetchingAppendix: true,
  appendix: [],
  appendixId: null,
  appendixError: null,
  // Import from last report
  isImportingComments: false,
  comments: null,
}

export const appendix = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch appendix
    case FETCH_APPENDIX_REQUESTED:
      return {
        ...state,
        isFetchingAppendix: true,
      }
    case FETCH_APPENDIX_SUCCESS:
      return {
        ...state,
        isFetchingAppendix: false,
        appendix: action?.payload?.appendix,
        appendixId: action?.payload?.id,
        comments: action?.payload?.comments,
        appendixError: null,
      }
    case FETCH_APPENDIX_FAILED:
      return {
        ...state,
        isFetchingAppendix: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        appendixError: action.payload?.data,
      }
    // Create appendix
    case SAVE_APPENDIX_REQUESTED:
      return {
        ...state,
        isSavingAppendix: true,
      }
    case SAVE_APPENDIX_SUCCESS:
      return {
        ...state,
        isSavingAppendix: false,
        appendix: action.payload.appendix,
        appendixId: action?.payload.id,
        comments: action?.payload?.comments,
        appendixError: null,
      }
    case SAVE_APPENDIX_FAILED:
      return {
        ...state,
        isSavingAppendix: false,
        appendixError: action.payload,
      }
    // Import construction schedule report comments from last report
    case IMPORT_APPENDIX_COMMENTS_COMMENTS_REQUESTED:
      return {
        ...state,
        isImportingComments: true,
      }
    case IMPORT_APPENDIX_REPORT_COMMENTS_SUCCESS:
      return {
        ...state,
        isImportingComments: false,
      }
    case IMPORT_APPENDIX_REPORT_COMMENTS_FAILED:
      return {
        ...state,
        isImportingComments: false,
      }
    case CLEAR_APPENDIX:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
