import { equal, ternary } from '../../Utils/javascript'
import {
  FETCH_EXECUTIVE_SUMMARY_FAILED,
  FETCH_EXECUTIVE_SUMMARY_REQUESTED,
  FETCH_EXECUTIVE_SUMMARY_SUCCESS,
  IMPORT_COMMENTS_FAILED,
  IMPORT_COMMENTS_REQUESTED,
  IMPORT_COMMENTS_SUCCESS,
  IMPORT_EXECUTIVE_SUMMARY_FAILED,
  IMPORT_EXECUTIVE_SUMMARY_REQUESTED,
  IMPORT_EXECUTIVE_SUMMARY_SUCCESS,
  IMPORT_OPEN_ISSUES_FAILED,
  IMPORT_OPEN_ISSUES_REQUESTED,
  IMPORT_OPEN_ISSUES_SUCCESS,
  IMPORT_QUALITY_OF_WORK_FAILED,
  IMPORT_QUALITY_OF_WORK_REQUESTED,
  IMPORT_QUALITY_OF_WORK_SUCCESS,
  SAVE_EXECUTIVE_SUMMARY_FAILED,
  SAVE_EXECUTIVE_SUMMARY_REQUESTED,
  SAVE_EXECUTIVE_SUMMARY_SUCCESS,
  FETCH_AUTO_GEN_ES_FAILED,
  FETCH_AUTO_GEN_ES_REQUESTED,
  FETCH_AUTO_GEN_ES_SUCCESS,
  FETCH_AUTO_GEN_ES_VALUES_FAILED,
  FETCH_AUTO_GEN_ES_VALUES_REQUESTED,
  FETCH_AUTO_GEN_ES_VALUES_SUCCESS,
  SAVE_AUTO_GEN_ES_TEXT_FAILED,
  SAVE_AUTO_GEN_ES_TEXT_REQUESTED,
  SAVE_AUTO_GEN_ES_TEXT_SUCCESS,
  CLEAR_ES_GEN_MODAL_VALUES,
} from '../constants/executiveSummary'

const initialState = {
  executiveSummary: {},
  isLoadingExecutiveSummary: true,
  isSavingExecutiveSummary: false,
  isImportingExecutiveSummary: false,
  isImportingOpenIssues: false,
  isImportingComments: false,
  isImportingQualityOfWork: false,
  fetchExecutiveSummaryError: null,
  saveExecutiveSummaryError: null,
  importExecutiveSummaryError: null,
  importOpenIssuesError: null,
  importCommentsError: null,
  importQualityOfWorkError: null,
  comments: '',
  openIssues: '',
  importedExecutiveSummary: '',
  qualityOfWork: '',
  // auto gen es
  isLoadingAutoGenEsText: false,
  autoGenEsText: '',
  isSavingAutoGenEs: false,
  isLoadingAutoGenEsValues: false,
  autoGenEsValues: {},
}

export const executiveSummary = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_EXECUTIVE_SUMMARY_REQUESTED:
      return {
        ...state,
        isLoadingExecutiveSummary: true,
      }
    case FETCH_EXECUTIVE_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoadingExecutiveSummary: false,
        fetchExecutiveSummaryError: null,
        executiveSummary: action.payload,
      }
    case FETCH_EXECUTIVE_SUMMARY_FAILED:
      return {
        ...state,
        isLoadingExecutiveSummary: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchExecutiveSummaryError: action.payload?.data,
      }
    // Save executive summary
    case SAVE_EXECUTIVE_SUMMARY_REQUESTED:
      return {
        ...state,
        isSavingExecutiveSummary: true,
      }
    case SAVE_EXECUTIVE_SUMMARY_SUCCESS:
      return {
        ...state,
        isSavingExecutiveSummary: false,
        saveExecutiveSummaryError: null,
        executiveSummary: action.payload,
      }
    case SAVE_EXECUTIVE_SUMMARY_FAILED:
      return {
        ...state,
        isSavingExecutiveSummary: false,
        saveExecutiveSummaryError: action.payload,
      }
    // Import executive summary
    case IMPORT_EXECUTIVE_SUMMARY_REQUESTED:
      return {
        ...state,
        isImportingExecutiveSummary: true,
      }
    case IMPORT_EXECUTIVE_SUMMARY_SUCCESS:
      return {
        ...state,
        isImportingExecutiveSummary: false,
        importExecutiveSummaryError: null,
        importedExecutiveSummary: action.payload,
      }
    case IMPORT_EXECUTIVE_SUMMARY_FAILED:
      return {
        ...state,
        isImportingExecutiveSummary: false,
        importExecutiveSummaryError: action.payload,
      }
    // Import open issues
    case IMPORT_OPEN_ISSUES_REQUESTED:
      return {
        ...state,
        isImportingOpenIssues: true,
      }
    case IMPORT_OPEN_ISSUES_SUCCESS:
      return {
        ...state,
        isImportingOpenIssues: false,
        importOpenIssuesError: null,
        openIssues: action.payload,
      }
    case IMPORT_OPEN_ISSUES_FAILED:
      return {
        ...state,
        isImportingOpenIssues: false,
        importOpenIssuesError: action.payload,
      }
    // Import comments
    case IMPORT_COMMENTS_REQUESTED:
      return {
        ...state,
        isImportingComments: true,
      }
    case IMPORT_COMMENTS_SUCCESS:
      return {
        ...state,
        isImportingComments: false,
        importCommentsError: null,
        comments: action.payload,
      }
    case IMPORT_COMMENTS_FAILED:
      return {
        ...state,
        isImportingComments: false,
        importCommentsError: action.payload,
      }
    // Import qualityOfWork
    case IMPORT_QUALITY_OF_WORK_REQUESTED:
      return {
        ...state,
        isImportingQualityOfWork: true,
      }
    case IMPORT_QUALITY_OF_WORK_SUCCESS:
      return {
        ...state,
        isImportingQualityOfWork: false,
        importQualityOfWorkError: null,
        qualityOfWork: action.payload,
      }
    case IMPORT_QUALITY_OF_WORK_FAILED:
      return {
        ...state,
        isImportingQualityOfWork: false,
        importQualityOfWorkError: action.payload,
      }
    // auto generate executive summary
    case FETCH_AUTO_GEN_ES_REQUESTED:
      return {
        ...state,
        isLoadingAutoGenEsText: true,
      }
    case FETCH_AUTO_GEN_ES_SUCCESS:
      return {
        ...state,
        isLoadingAutoGenEsText: false,
        autoGenEsText: action.payload,
      }
    case FETCH_AUTO_GEN_ES_FAILED:
      return {
        ...state,
        isLoadingAutoGenEsText: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        autoGenEsText: '',
      }
    case FETCH_AUTO_GEN_ES_VALUES_REQUESTED:
      return {
        ...state,
        isLoadingAutoGenEsValues: true,
      }
    case FETCH_AUTO_GEN_ES_VALUES_SUCCESS:
      return {
        ...state,
        isLoadingAutoGenEsValues: false,
        autoGenEsValues: action.payload,
      }
    case FETCH_AUTO_GEN_ES_VALUES_FAILED:
      return {
        ...state,
        isLoadingAutoGenEsValues: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        autoGenEsValues: {},
      }
    case SAVE_AUTO_GEN_ES_TEXT_REQUESTED:
      return {
        ...state,
        isSavingAutoGenEs: true,
      }
    case SAVE_AUTO_GEN_ES_TEXT_SUCCESS:
      return {
        ...state,
        isSavingAutoGenEs: false,
      }
    case SAVE_AUTO_GEN_ES_TEXT_FAILED:
      return {
        ...state,
        isSavingAutoGenEs: false,
      }

    case CLEAR_ES_GEN_MODAL_VALUES:
      return {
        ...state,
        isLoadingAutoGenEsText: false,
        autoGenEsText: '',
        isSavingAutoGenEs: false,
        isLoadingAutoGenEsValues: false,
        autoGenEsValues: {},
      }
    default:
      return state
  }
}
