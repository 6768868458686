import { Stack, Typography } from '@mui/material'
import React, { memo } from 'react'

const ActionsBar = ({ actionTitle, children, sx }) => (
  <Stack
    direction="row"
    height="50px"
    width="100%"
    backgroundColor="gray.main"
    px={3}
    boxSizing="border-box"
    alignItems="center"
    justifyContent="space-between"
    sx={sx}
  >
    <Typography variant="subtitle2" color="gray.medium" fontWeight={400}>
      {actionTitle}
    </Typography>
    {children}
  </Stack>
)

export default memo(ActionsBar)
