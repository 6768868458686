import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import {
  FETCH_REPORT_TYPE_FAILED,
  FETCH_REPORT_TYPE_REQUESTED,
  FETCH_REPORT_TYPE_SUCCESS,
} from '../constants/reportType'

const fetchReportTypeRequested = () => ({
  type: FETCH_REPORT_TYPE_REQUESTED,
})
const fetchReportTypeSuccess = (payload) => ({
  type: FETCH_REPORT_TYPE_SUCCESS,
  payload,
})
const fetchReportTypeFailed = (payload) => ({
  type: FETCH_REPORT_TYPE_FAILED,
  payload,
})

export const getReportType = () => async (dispatch) => {
  dispatch(fetchReportTypeRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.reportType}`,
    method: method.get,
    showToastMessage: false,
    toastMessage: 'Report Type Fetch successfully!',
  })
  if (data) {
    dispatch(fetchReportTypeSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchReportTypeFailed(error.response.data))
  }
}
