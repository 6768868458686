import {
  FETCH_PROJECT_CHOICES_FAILED,
  FETCH_PROJECT_CHOICES_REQUESTED,
  FETCH_PROJECT_CHOICES_SUCCESS,
} from '../constants/choices'

const initialState = {
  choices: {},
  isLoadingChoices: false,
  choicesError: null,
}

export const projectChoices = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PROJECT_CHOICES_REQUESTED:
      return {
        ...state,
        isLoadingChoices: true,
      }
    case FETCH_PROJECT_CHOICES_SUCCESS:
      return {
        ...state,
        choices: { ...state.choices, ...action.payload },
        isLoadingChoices: false,
      }
    case FETCH_PROJECT_CHOICES_FAILED:
      return {
        ...state,
        isLoadingChoices: false,
        choicesError: action.payload,
      }
    default:
      return state
  }
}
