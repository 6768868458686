import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_COVER_LETTER_VALUE,
  FETCH_CMR_COVER_LETTER_FAILED,
  FETCH_CMR_COVER_LETTER_REQUESTED,
  FETCH_CMR_COVER_LETTER_SUCCESS,
  FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_FAILED,
  FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_REQUESTED,
  FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_SUCCESS,
  UPDATE_COVER_LETTER_FAILED,
  UPDATE_COVER_LETTER_REQUESTED,
  UPDATE_COVER_LETTER_SUCCESS,
  FETCH_SIGNATURE_FAILED,
  FETCH_SIGNATURE_REQUESTED,
  FETCH_SIGNATURE_SUCCESS,
  FETCH_CLIENT_NAME_SUCCESS,
} from '../constants/coverLetterAndPhoto'

const initialState = {
  // CMR-Cover letter data
  isCMRCoverLetterLoading: true,
  CMRCoverLetterData: {},
  // CMR-Cover letter default data
  isCMRCoverLetterDefaultValueLoading: false,
  CMRCoverLetterDefaultData: {},
  isCoverLetterUpdateLoading: false,
  isSignatureLoading: true,
  signatures: [],
  clientsDetails: null,
}

export const coverLetterAndPhoto = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_CMR_COVER_LETTER_REQUESTED:
      return {
        ...state,
        isCMRCoverLetterLoading: true,
      }
    case FETCH_CMR_COVER_LETTER_SUCCESS:
      return {
        ...state,
        isCMRCoverLetterLoading: false,
        isCMRCoverLetterDefaultValueLoading: false,
        CMRCoverLetterData: action.payload,
      }
    case FETCH_CMR_COVER_LETTER_FAILED:
      return {
        ...state,
        isCMRCoverLetterLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        CMRCoverLetterData: {},
      }
    case FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_REQUESTED:
      return {
        ...state,
        isCMRCoverLetterDefaultValueLoading: true,
      }
    case FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_SUCCESS:
      return {
        ...state,
        isCMRCoverLetterDefaultValueLoading: false,
        CMRCoverLetterDefaultData: action.payload,
      }
    case FETCH_DEFAULT_VALUE_FOR_CMR_COVER_LETTER_FAILED:
      return {
        ...state,
        isCMRCoverLetterDefaultValueLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        CMRCoverLetterDefaultData: {},
      }
    case CLEAR_COVER_LETTER_VALUE:
      return {
        ...state,
        isCMRCoverLetterLoading: false,
        isCMRCoverLetterDefaultValueLoading: false,
        CMRCoverLetterDefaultData: {},
        CMRCoverLetterData: {},
      }
    // save cover letter
    case UPDATE_COVER_LETTER_REQUESTED:
      return {
        ...state,
        isCoverLetterUpdateLoading: true,
      }
    case UPDATE_COVER_LETTER_SUCCESS:
      return {
        ...state,
        isCoverLetterUpdateLoading: false,
        CMRCoverLetterData: action.payload,
      }
    case UPDATE_COVER_LETTER_FAILED:
      return {
        ...state,
        isCoverLetterUpdateLoading: false,
      }
    // FETCH SIGNATURES
    case FETCH_SIGNATURE_REQUESTED:
      return {
        ...state,
        isSignatureLoading: true,
        signatures: [],
      }
    case FETCH_SIGNATURE_SUCCESS:
      return {
        ...state,
        isSignatureLoading: false,
        signatures: action.payload,
      }
    case FETCH_SIGNATURE_FAILED:
      return {
        ...state,
        isSignatureLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        signatures: [],
      }

    case FETCH_CLIENT_NAME_SUCCESS:
      return {
        ...state,
        clientsDetails: action.payload,
      }
    default:
      return state
  }
}
