import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import StarRateIcon from '@mui/icons-material/StarRate'
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import classNames from 'classnames'
import FormSectionDivider from '../../../Shared/FormSectionDivider'
import { addAndRemoveFavoriteCompany } from '../../../Redux/actions/rfp'
import {
  checkIncludes,
  entries,
  equal,
  ternary,
} from '../../../Utils/javascript'
import DKTCurrencyTextField from '../../../Shared/DKTCurrencyTextField'
import {
  rfpDetailsForm,
  columns,
} from '../../../Description/rfpDetails.description'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTInput from '../../../Shared/DKTInput'
import { renderFormFields } from '../../../Utils/renderFormFields'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTCheckbox from '../../../Shared/DKTCheckbox'
import { useStyles } from '../../../Styles/rfc.style'
import DKTRadioGroup from '../../../Shared/DKTRadioGroup'

const RfpDetailsAndConsultants = ({ formValues }) => {
  const dispatch = useDispatch()

  const [dynamicOptions, setDynamicOptions] = useState({})
  const [companyList, setCompanyList] = useState([])

  const { rfpCompanyList, isLoadingCompanyList } = useSelector(
    ({ rfpData }) => rfpData,
  )
  const { choices } = useSelector(({ projectChoices }) => projectChoices)
  const classes = useStyles()
  const { values, setFieldValue, errors, handleChange } = formValues

  useEffect(() => {
    setCompanyList(rfpCompanyList)
  }, [rfpCompanyList])

  useEffect(() => {
    const dynamicOpts = {
      constructionType: choices?.constructionType,
      projectType: choices?.projectType,
      parcelType: choices?.parcelType,
    }
    setDynamicOptions(dynamicOpts)
  }, [choices])

  const mapFormFields = ({ formFields }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            options,
            isConfirmPassword,
            isSeparate,
            separateSectionTitle,
            isPopUp,
            popupText,
            dependsOn,
            defaultValue,
            type,
            ...formField
          },
        ],
        index,
      ) => {
        const FormControl = ternary(equal(type, 'select'), DKTSelect, DKTInput)
        return equal(type, 'radioGroup') ? (
          <DKTRadioGroup
            row
            name={name}
            value={formField?.fields
              ?.map(({ value }) => value)
              .find((item) => !!checkIncludes(item, values.scopeOfServices))}
            onClick={(e) => {
              const newValue = e.target.value
              const currentValues = values.scopeOfServices || []

              const fieldValues = formField?.fields
                .map(({ value }) => value)
                ?.filter((item) => item !== newValue)

              setFieldValue(
                'scopeOfServices',
                currentValues.includes(newValue)
                  ? currentValues.filter((item) => item !== newValue)
                  : [
                      ...currentValues.filter(
                        (item) => item !== fieldValues[0],
                      ),
                      newValue,
                    ],
              )
            }}
            fields={formField?.fields}
            labelSX={formField.labelSX}
            className={classes.radioLabel}
          />
        ) : (
          ternary(
            equal(type, 'checkbox'),
            formField?.fields?.map((item) => (
              <Box key={index} pl={2}>
                <DKTCheckbox
                  label={item?.label}
                  showCheckBox={item?.showCheckBox}
                  name="scopeOfServices"
                  sx={item?.sx}
                  onChange={handleChange}
                  checked={!!checkIncludes(item?.value, values.scopeOfServices)}
                  value={item?.value || ''}
                  {...item}
                  className={classNames({
                    [classes.labelledCheckbox]: formField.labelledCheckbox,
                  })}
                />
              </Box>
            )),
            <Grid key={index} item xs={formField.xs ?? 12}>
              {ternary(
                formField.isCurrency || formField?.formatDigits,
                <DKTCurrencyTextField
                  label={formField.label}
                  isRequired={isRequired}
                  variant="standard"
                  value={(values && values[name]) ?? '0'}
                  error={errors[name]}
                  currencySymbol={formField?.isCurrency ? '$' : ''}
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  decimalPlaces={formField?.decimalPlaces || 2}
                  onChange={(event, value) =>
                    handleChange({ target: { name, value } })
                  }
                  disabled={formField?.disabled}
                  sx={formField?.sx}
                />,
                <FormControl
                  {...formField}
                  type={type}
                  options={ternary(
                    isDynamicOptions,
                    dynamicOptions[name] || [],
                    options,
                  )}
                  isRequired={isRequired}
                  disabled={formField?.disabled}
                  id={name}
                  name={name}
                  value={(values && values[name]) || defaultValue || ''}
                  onChange={handleChange}
                  error={errors[name]}
                />,
              )}
            </Grid>,
          )
        )
      },
    )

  const renderCurrentFormFields = (formFields) => (
    <Grid container spacing={2}>
      {mapFormFields({ formFields })}
    </Grid>
  )

  const handleFavorite = (e, companyId, status) => {
    const data = {
      company_id: companyId,
      action: status ? 'remove' : 'add',
    }
    dispatch(addAndRemoveFavoriteCompany(data))
    e.stopPropagation()
  }

  const customBodyRender = useCallback(
    (value, tableMeta) => {
      switch (tableMeta?.columnData?.name) {
        case 'id':
          return (
            <DKTCheckbox
              name="selectedCompanies"
              value={value}
              handleChange={(e) => {
                const { checked, value: checkboxValue } = e.target
                const selected = [...(values.selectedCompanies || [])]

                setFieldValue(
                  'selectedCompanies',
                  ternary(
                    checked,
                    [...selected, +checkboxValue],
                    selected.filter((item) => !equal(item, +checkboxValue)),
                  ),
                )
              }}
              checked={checkIncludes(value, values?.selectedCompanies) || false}
              disabled={
                values?.selectedCompanies?.length >= 3 &&
                !checkIncludes(value, values?.selectedCompanies)
              }
            />
          )
        case 'isFavorite':
          return (
            <Box
              onClick={(e) => handleFavorite(e, tableMeta?.rowData?.[0], value)}
              sx={{ cursor: 'pointer', display: 'flex' }}
            >
              {value ? (
                <StarRateIcon style={{ color: '#FFEA00' }} />
              ) : (
                <StarOutlineOutlinedIcon />
              )}
            </Box>
          )
        case 'companyName':
          return (
            <Typography variant="text" sx={{ color: '#1564FF' }}>
              {value}
            </Typography>
          )
        default:
          return '---'
      }
    },
    [values.selectedCompanies],
  )

  const tableColumns = columns?.map((company) => ({
    ...company,
    options: {
      ...company.options,
      customBodyRender,
      customHeadRender: () => null,
    },
  }))

  const tableOptions = {
    print: false,
    download: false,
    viewColumns: false,
    filter: false,
    filterType: 'checkbox',
    responsive: 'standard',
    selectableRowsHeader: false,
    selectableRows: false,
    textLabels: {
      body: {
        noMatch: isLoadingCompanyList ? (
          <DKTCircularProgress />
        ) : (
          'Sorry, there is no matching data to display'
        ),
      },
    },
  }

  return (
    <Box sx={{ marginTop: '16px' }}>
      <Grid container>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={6}>
              <FormSectionDivider
                sectionTitle="STEP 01: Enter RFP Details"
                sx={{
                  marginBottom: '24px',
                  '& .MuiTypography-root': {
                    borderBottomColor: '#D9C666 !important',
                  },
                }}
              />
              <Grid container margin={0}>
                {renderCurrentFormFields(
                  rfpDetailsForm.requestForProposalDetails,
                )}
              </Grid>
            </Grid>
            <Grid item xs={6} paddingLeft="46px">
              <FormSectionDivider
                sectionTitle="STEP 02: Select Scope of Services"
                sx={{
                  marginBottom: '36px',
                  '& .MuiTypography-root': {
                    borderBottomColor: '#D9C666 !important',
                  },
                }}
              />
              <Box pl={1.5}>
                {mapFormFields({ formFields: rfpDetailsForm.services })}
              </Box>
            </Grid>
            <Grid item xs={12} paddingTop="32px">
              <Box>
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Notes to Consultant
                </Typography>
                {renderFormFields({
                  values,
                  errors,
                  handleChange,
                  formFields: {
                    comments: {
                      ...rfpDetailsForm?.commentField?.comments,
                    },
                  },
                })}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} paddingLeft="36px">
          <FormSectionDivider
            sectionTitle={`STEP 03: Select Consultants (Selected ${
              values?.selectedCompanies?.length || 0
            }/3)`}
            sx={{
              marginBottom: '24px',
              '& .MuiTypography-root': {
                borderBottomColor: '#D9C666 !important',
              },
            }}
          />
          <Box
            sx={{
              marginTop: '-58px',
              '.MuiToolbar-root': {
                minHeight: '0 !important',
                '.MuiButtonBase-root': {
                  padding: 0,
                  position: 'relative',
                },
                '& >div:first-child': {
                  position: 'absolute',
                  right: 12,
                  top: 0,
                  backgroundColor: '#ffffff',
                },
              },
              '& .MuiPaper-root': {
                backgroundColor: 'transparent',
              },
              '& table': {
                '&:first-child': {
                  marginTop: '10px',
                },
              },
            }}
          >
            <DKTMuiDataTable
              data={companyList}
              options={tableOptions}
              columns={tableColumns}
              className={classes.rfpDetailsTable}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RfpDetailsAndConsultants
