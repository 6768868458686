import { BOOLEAN } from '../Utils/constant'
import { emailValidation, mobileNumber, validatePassword } from '../Utils/regex'

export const formFields = {
  editProfileInfo: {
    firstName: {
      type: 'text',
      label: 'First Name',
      isRequired: true,
      placeholder: 'Enter first name',
    },
    lastName: {
      type: 'text',
      label: 'Last Name',
      isRequired: true,
      placeholder: 'Enter last name',
    },
    email: {
      type: 'text',
      label: 'Email Address',
      isRequired: true,
      placeholder: 'Enter valid email address',
      validate: [emailValidation],
    },
    workPhoneNumber: {
      type: 'text',
      label: 'Work Phone (W)',
      isRequired: true,
      placeholder: 'Enter work phone number',
      xs: 6,
      validate: [
        mobileNumber,
        'Work phone number should be xxx-xxx-xxxx format',
      ],
    },
    extension: {
      type: 'text',
      label: 'Extension',
      placeholder: 'Enter extension',
      xs: 6,
    },
    cellPhone: {
      type: 'text',
      label: 'Cell Phone (C)',
      placeholder: 'Enter cell phone number',
      validate: [
        mobileNumber,
        'cell phone number should be xxx-xxx-xxxx format',
      ],
    },
    city: {
      type: 'text',
      label: 'City',
      placeholder: 'Enter city',
      xs: 7,
    },
    state: {
      type: 'select',
      label: 'State',
      displayEmpty: true,
      placeholder: 'Select',
      xs: 5,
      isDynamicOptions: true,
    },
    is2faEnabled: {
      type: 'checkbox',
      valueType: BOOLEAN,
      fields: [
        {
          label: 'Enable Two factor authentication',
          value: 'is2faEnabled',
          labelledCheckbox: true,
        },
      ],
    },
  },
  changePasswordInfo: {
    oldPassword: {
      type: 'password',
      label: 'Old Password',
      placeholder: 'Enter old password',
      isRequired: true,
    },
    newPassword: {
      type: 'password',
      label: 'New Password',
      isRequired: true,
      validate: [
        validatePassword,
        'Password must be at least 8 characters long and contain a mix of uppercase letters, lowercase letters, digits, and special characters.',
      ],
      placeholder: 'Enter new password',
      dependsOn: 'confirmPassword',
    },
    confirmPassword: {
      type: 'password',
      label: 'Re-enter New Password',
      isRequired: true,
      placeholder: 'Enter confirm password',
      isConfirmPassword: true,
      dependsOn: 'newPassword',
    },
  },
}

export const accordionData = {
  editProfile: {
    accordionTitle: 'Edit Profile',
    idTag: 'panel1a',
  },
  changePassword: {
    accordionTitle: 'Change Password',
    idTag: 'panel2a',
  },
}
