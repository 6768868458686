import { administrator, consultantPortal } from '../Utils/constant'
import { equal } from '../Utils/javascript'

export const sidebarLinks = (systemAuthorization, portalType) =>
  [
    {
      text: 'Company Information',
      url: '/admin/company-information',
    },
    {
      text: 'Company Personnel',
      url: '/admin/company-personnel',
    },
    {
      text: 'Project Assignments',
      url: '/admin/project-assignments',
    },
    ...(equal(systemAuthorization, administrator) &&
    equal(portalType, consultantPortal)
      ? [
          {
            text: 'Report Settings',
            dropdownItems: [
              {
                text: 'Construction Monitoring Report',
                url: '/admin/construction-monitoring-report',
              },
            ],
          },
          { text: 'Client Access', url: '/admin/client-access' },
        ]
      : []),
  ].filter(Boolean)
