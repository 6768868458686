import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  forgotPasswordLink: {
    display: 'block',
    maxWidth: 'max-content',
    textDecoration: 'none',
    fontSize: '13px',
    marginTop: 10,
    marginBottom: 25,
    fontFamily: 'Roboto',
    color: theme.palette.primary.main,
  },
  loginContainer: {
    '& > .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& img': {
      width: '100%',
      height: '100vh',
      objectFit: 'cover',
    },
  },
  loginForm: {
    '& form': {
      margin: 'auto 0',
      width: '100%',
      maxWidth: '350px',
      '& img': {
        height: 75,
        width: 'auto',
        marginBottom: 100,
      },
    },
  },
  copyrightText: {
    fontSize: 12,
    color: '#515151',
    marginBottom: 60,
    '& span': {
      fontSize: 20,
      verticalAlign: 'middle',
    },
  },

  loginFormMain: {
    '& .MuiFormControl-root': {
      '&.passwordInput': {
        '& .MuiInputBase-root': {
          marginTop: 25,
        },
      },
      '&:has(.MuiCheckbox-root)': {
        marginTop: 10,
      },
      '& .MuiCheckbox-root': {
        padding: 0,
        paddingLeft: 7,
      },
    },
    '& .MuiButtonBase-root.MuiButton-contained': {
      marginTop: 25,
    },
  },
}))
