import { createTheme } from '@mui/material'
import toast from 'react-hot-toast'

const theme = createTheme()

export const showToast = (
  message = 'Something went wrong! Try again later',
  messageType = 'error',
  position = 'top-center',
  reverseOrder = true,
) =>
  toast(message, {
    position,
    reverseOrder,
    type: messageType,
    style: {
      border: `1px solid ${theme.palette[messageType].main}`,
      padding: '0 16px',
      color: `${theme.palette[messageType].main}`,
    },
    iconTheme: {
      primary: `${theme.palette[messageType].main}`,
      secondary: `#FFF`,
    },
  })
