import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  projectSettingCoverLetterAccordion: {
    '&.MuiAccordionSummary-root': {
      backgroundColor: '#C6C6C6',
      color: 'black',
      fontSize: '14px',
    },
  },
  projectDesc: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 250,
    height: 250,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '150px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '150px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },
  subjectAndSalutationFields: {
    '&.MuiGrid-item': {
      paddingLeft: 0,
      '& .MuiGrid-root': {
        marginLeft: 0,
      },
    },
  },
  label: {
    '&.MuiTypography-root': {
      fontSize: 16,
      lineHeight: '1.4375em',
      fontWeight: 500,
      transformOrigin: ' top left',
      transform: 'scale(0.75)',
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
}))
