import { Box, Grid, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
import AddIcon from '@mui/icons-material/Add'
import { useParams } from 'react-router-dom'
import { formFields } from '../../Description/ownerContractor.description'
import useForm from '../../Hooks/useForm'
import { InnerLayout } from '../../Layout/project'
import {
  addOwnerContractor,
  createAndUpdateOwnerContractor,
  deleteOwnerContractor,
  getOwnerContractor,
  getSiteBuildingMapping,
  searchContractor,
} from '../../Redux/actions/ownerContractor'
import {
  fetchProjectFieldChoices,
  getProjectList,
} from '../../Redux/actions/projects'
import DKTButton from '../../Shared/DKTButton'
import DKTCurrencyTextField from '../../Shared/DKTCurrencyTextField'
import DKTInput from '../../Shared/DKTInput'
import DKTSelect from '../../Shared/DKTSelect'
import DKTTextArea from '../../Shared/DKTTextArea'
import { SA, spinnerSize } from '../../Utils/constant'
import {
  entries,
  equal,
  gt,
  handleBlurAll,
  isArray,
  ternary,
} from '../../Utils/javascript'
import DKTSearchableSelect from '../../Shared/DKTSearchableSelect'
import CreateContact from './projectDirectory/CreateContact'
import DKTButtonSelect from '../../Shared/DKTButtonSelect'
import DKTDialog from '../../Shared/DKTDialog'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import { showToast } from '../../Utils/toastService'
import DKTConfirmNavigateShowModal from '../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../Redux/actions/confirmation'
import { CLEAR_OWNER_CONTRACTOR } from '../../Redux/constants/ownerContractor'
import DKTForm from '../../Shared/DKTForm'

const btnStyle = makeStyles(() => ({
  addBtn: {
    marginLeft: 230,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  inlineLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 'bold',
      color: '#2D2D2D',
      fontSize: 14,
      marginBottom: 8,
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))

const SelectMenuButton = ({
  children,
  setIsCompanyModalOpen = () => {},
  ...props
}) => (
  <components.MenuList {...props}>
    {children}
    <button
      type="button"
      onClick={() => setIsCompanyModalOpen(true)}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        marginTop: '10px',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        border: '0.5px solid lightgrey',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        cursor: 'pointer',
        width: '100%',
      }}
    >
      <AddIcon /> Add/Edit Contractor
    </button>
  </components.MenuList>
)
const CustomOption = ({ children, ...rest }) => (
  <components.Option {...rest} ref={rest?.innerRef}>
    <Stack direction="row" justifyContent="space-between" alignItems="baseline">
      <Stack direction="row" spacing={1} alignItems="baseline">
        <Typography>{children}</Typography>
        <Stack direction="row" ml={1} alignItems="baseline" spacing={0.5}>
          <Typography fontSize={12} lineHeight="14px">
            {rest?.data?.city}
          </Typography>
          <Typography fontSize={12} lineHeight="14px">
            {rest?.data?.state}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  </components.Option>
)
const optionsFields = [
  'contractType',
  'durationType',
  'aiaFormAgreement',
  'liquidatedDamages',
]

const OwnerContractor = () => {
  const dispatch = useDispatch()
  const { slug } = useParams()
  const classes = btnStyle()

  const [projectOptionChoicesData, setProjectOptionChoicesData] = useState({})
  const [ownerContractorId, setOwnerContractorId] = useState(null)
  const [contractor, setContractor] = useState({})
  const [IsCompanyModalOpen, setIsCompanyModalOpen] = useState(false)
  const [deleteNotificationOpen, setOpenDeleteNotification] =
    React.useState(false)
  const [isSaveWarningOpen, setIsSaveWarningOpen] = useState(false)
  const [tempOcData, setTempOcData] = useState({
    id: null,
    add: false,
  })
  const { projectOptionChoices, currentProject } = useSelector(
    ({ projects }) => projects,
  )

  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)
  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+slug]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission = isGlobalViewer || isProjectViewer

  const {
    ownerContractorList,
    isCreateAndUpdateOwnerContractorLoading,
    siteBuildingMapping,
    activeContractor,
    ownerContractorError,
    searchResults,
    isSearchingContractor,
    isDeletingContractor,
    isOwnerContractorListLoading,
  } = useSelector(({ ownerContractor }) => ownerContractor)

  const ref = useRef()
  const {
    values,
    errors,
    handleChange,
    setValues,
    handleSubmit,
    resetForm,
    setErrors,
    isDirty,
    setIsDirty,
    setDummyData,
  } = useForm(
    {
      ...formFields.OwnerContractorFields,
      ...formFields.SiteBuildingMapping,
    },
    CLEAR_OWNER_CONTRACTOR,
  )

  useEffect(() => {
    dispatch(fetchProjectFieldChoices(optionsFields))
    dispatch(getOwnerContractor(slug))
    dispatch(getProjectList(slug))
  }, [dispatch, slug])

  useEffect(() => {
    if (ownerContractorError) {
      const errorData = { ...errors }
      Object.entries(ownerContractorError).forEach(
        ([errorName, [errorDesc]]) => {
          errorData[errorName] = errorDesc
        },
      )
      setErrors(errorData)
    }
    return () => {
      setErrors({})
    }
  }, [ownerContractorError, isDeletingContractor])

  useEffect(() => {
    if (!ownerContractorList?.length) {
      handleAddNewOwnerContractor()
    }
  }, [ownerContractorList, isDeletingContractor])

  useEffect(() => {
    if (
      !isCreateAndUpdateOwnerContractorLoading &&
      ownerContractorList?.length
    ) {
      if (activeContractor) {
        setOwnerContractorId(activeContractor)
      } else {
        setOwnerContractorId(
          ownerContractorList[ownerContractorList.length - 1]?.id,
        )
      }
    }
  }, [activeContractor, ownerContractorList, isDeletingContractor])

  useEffect(() => {
    let dynamicOption = {}
    if (projectOptionChoices) {
      dynamicOption = projectOptionChoices
    }
    const dynamicOptionForMapping = (ary) => {
      const res = ary?.map((sts) => ({
        value: `${sts?.id}`,
        label: sts?.uniqueCode,
      }))
      return res
    }
    const mapping = dynamicOptionForMapping(siteBuildingMapping)
    setProjectOptionChoicesData({
      ...dynamicOption,
      mapping,
    })
  }, [projectOptionChoices, siteBuildingMapping])

  useEffect(() => {
    if (ownerContractorList?.length && slug && ownerContractorId) {
      const populateValue = ownerContractorList[ownerContractorList.length - 1]

      const populateFinal = {
        ...populateValue,
        mapping:
          isArray(populateValue?.mapping) && populateValue?.mapping?.length
            ? populateValue?.mapping?.map((mapEle) => `${mapEle}`)
            : equal(projectOptionChoicesData?.mapping?.length, 1)
            ? [projectOptionChoicesData?.mapping[0]?.value]
            : [],
      }
      setValues(populateFinal)
      setDummyData(populateFinal)
    }
  }, [
    ownerContractorList,
    setValues,
    ownerContractorId,
    isDeletingContractor,
    projectOptionChoicesData,
  ])

  useEffect(() => {
    const currentContractor = ownerContractorList?.find((project) =>
      equal(project?.id, +ownerContractorId),
    )
    if (currentContractor) {
      if (currentContractor?.uniqueCode || currentContractor?.isNewContractor) {
        dispatch(getSiteBuildingMapping(slug, currentContractor?.uniqueCode))
      }
    }
  }, [dispatch, slug, ownerContractorId, isDeletingContractor])

  useEffect(() => {
    dispatch(searchContractor(null, 'isPopulate'))
  }, [dispatch, slug, isDeletingContractor])

  useEffect(() => {
    const currentContractor = ownerContractorList?.find((project) =>
      equal(project?.id, +ownerContractorId),
    )
    if (searchResults?.length) {
      const contractorObj = searchResults?.find(
        (res) => `${res?.value}` === currentContractor?.contractor,
      )
      setContractor(contractorObj)
    }
  }, [
    dispatch,
    setValues,
    slug,
    searchResults,
    ownerContractorList,
    ownerContractorId,
  ])
  useEffect(() => {
    if (tempOcData?.id) {
      if (isDirty) {
        setIsSaveWarningOpen(true)
      } else {
        resetForm()
        setTimeout(() => {
          setOwnerContractorId(tempOcData?.id)
        }, 500)
        setErrors({})
      }
    } else if (tempOcData?.add) {
      if (isDirty) {
        setIsSaveWarningOpen(true)
      } else {
        handleAddNewOwnerContractor()
        resetForm()
      }
    }
  }, [tempOcData])

  useEffect(() => {
    setIsDirty(!!ownerContractorError)
  }, [ownerContractorError, ownerContractorList])

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          { isRequired, isDynamicOptions, defaultValue, options, ...formField },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <Grid key={index} item xs={formField.xs ?? 12}>
            {ternary(
              equal(name, 'contractor') && equal(formField?.type, 'select'),
              <DKTSearchableSelect
                innerRef={ref}
                isLoading={isSearchingContractor}
                options={searchResults || []}
                onChange={(option) => {
                  const e = {
                    target: {
                      name: 'contractor',
                      value: option?.value || '',
                    },
                  }
                  handleChange(e)
                  setContractor(option)
                }}
                isClearable
                value={contractor || ''}
                components={{
                  MenuList: (props, ...args) =>
                    SelectMenuButton(
                      { ...props, setIsCompanyModalOpen },
                      ...args,
                    ),
                  Option: (args) => CustomOption(args),
                }}
                isDisabled={hasOnlyViewPermission || formField?.disabled}
                {...formField}
              />,
              ternary(
                formField.isCurrency,
                <DKTCurrencyTextField
                  label={formField.label}
                  variant="standard"
                  value={(values && values[name]) ?? ''}
                  isRequired={isRequired}
                  error={errors[name]}
                  currencySymbol="$"
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={(event, value) =>
                    handleChange({ target: { name, value } })
                  }
                  disabled={hasOnlyViewPermission || formField?.disabled}
                />,
                <FormControl
                  {...formField}
                  isRequired={isRequired}
                  options={projectOptionChoicesData[name] || options || []}
                  id={name}
                  name={name}
                  value={(values && values[name]) || defaultValue || ''}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  error={errors[name]}
                  disabled={hasOnlyViewPermission || formField.disabled}
                />,
              ),
            )}
          </Grid>
        )
      },
    )
  const renderFormFields = (formFields) => (
    <Grid container spacing={2}>
      {isArray(formFields)
        ? formFields?.map((formField) =>
            mapFormFields({
              formFields: formField?.data,
              makeFieldNameUnique: true,
              id: formField?.id,
            }),
          )
        : mapFormFields({ formFields })}
    </Grid>
  )

  const handleAddNewOwnerContractor = () => {
    if (hasOnlyViewPermission) return
    const id = new Date().getTime()
    dispatch(addOwnerContractor(id))
    setOwnerContractorId(id)
    resetForm()
  }
  const handleClose = () => {
    setIsCompanyModalOpen(false)
    setOpenDeleteNotification(false)
  }

  const handleSave = () => {
    const isFormValid = handleSubmit()
    const filteredObj = { ...values }
    Object.keys(filteredObj).forEach((key) => {
      if (equal(filteredObj[key], undefined) || equal(filteredObj[key], '')) {
        delete filteredObj[key]
        filteredObj[key] = null
      }
    })
    if (
      equal(filteredObj?.mapping?.length, 0) ||
      equal(filteredObj?.mapping, null)
    ) {
      delete filteredObj.mapping
      filteredObj.isDeleted = true
    }
    const body = {
      ...filteredObj,
      project: slug,
    }
    if (isFormValid) {
      dispatch(createAndUpdateOwnerContractor(body, ownerContractorId))
    } else {
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }
  const handleDeleteContractor = () => {
    const body = {
      ownerContractor: [ownerContractorId],
    }
    if (gt(ownerContractorList?.length, 1)) {
      dispatch(deleteOwnerContractor(body, handleClose))
    } else {
      showToast("You can't delete this record.", 'error')
      handleClose()
    }
  }
  const actionList = [
    { label: 'Delete', onClick: () => setOpenDeleteNotification(true) },
  ]
  const actions = () => (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Stack direction="row" gap={2} className={classes.rightButton}>
        {ternary(
          !hasOnlyViewPermission,
          <DKTButtonSelect
            options={actionList}
            disabled={
              hasOnlyViewPermission ||
              isCreateAndUpdateOwnerContractorLoading ||
              isOwnerContractorListLoading ||
              isSearchingContractor ||
              true
            }
          />,
          null,
        )}
        <DKTButton
          onClick={handleSave}
          onMouseOver={handleBlurAll}
          disabled={
            hasOnlyViewPermission ||
            isCreateAndUpdateOwnerContractorLoading ||
            isOwnerContractorListLoading ||
            isSearchingContractor
          }
        >
          {ternary(
            isCreateAndUpdateOwnerContractorLoading,
            'Saving...',
            'Save',
          )}
        </DKTButton>
      </Stack>
    </Box>
  )
  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteContractor}>
        {ternary(
          isDeletingContractor,
          <DKTCircularProgress size={spinnerSize?.sm} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const activeOwnerContractor = ownerContractorList?.find((ownerContractor) =>
    equal(ownerContractor?.id, ownerContractorId),
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setTempOcData({
      id: null,
      add: false,
    })
  }
  const confirmSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setOwnerContractorId(tempOcData?.id)
    setIsDirty(false)
    setErrors({})
    if (tempOcData?.add) {
      handleAddNewOwnerContractor()
      resetForm()
    }
    setTempOcData({
      id: null,
      add: false,
    })
  }

  return (
    <InnerLayout
      contentTitle="OWNER CONTRACTOR AGREEMENT"
      actions={actions}
      maxWidth="md"
      title={currentProject?.projectName || 'New Project'}
      isShowMenu
    >
      {equal(isOwnerContractorListLoading, true) ||
      equal(isSearchingContractor, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isOwnerContractorListLoading, 'FAILED') ||
        equal(isSearchingContractor, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Owner contractor agreement
            data. Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <Grid container>
          <Grid item xs={12} xl={8}>
            <Grid
              container
              columnSpacing={10}
              columnGap={{ sm: 0, xl: 11 }}
              rowSpacing={3}
            >
              <Grid item lg={5} sm={6} sx={{ mt: 4 }}>
                <DKTForm autoComplete="off">
                  {renderFormFields(formFields.OwnerContractorFields)}
                </DKTForm>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Comments
                </Typography>
                <DKTTextArea
                  rows={6}
                  name="comments"
                  placeholder="Placeholder Text"
                  value={values?.comments || ''}
                  onChange={handleChange}
                  disabled={hasOnlyViewPermission}
                />
                <CreateContact
                  open={IsCompanyModalOpen}
                  onClose={handleClose}
                  onSave={() => dispatch(searchContractor(null))}
                />
              </Grid>
              <DKTDialog
                open={deleteNotificationOpen}
                handleClose={handleClose}
                title="&nbsp;"
                actions={deleteNotificationAction}
                maxWidth="xs"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box className={classes.deleteNotificationContent}>
                      <Typography variant="h5">
                        {`Are you sure you want to delete ${activeOwnerContractor?.uniqueCode}?`}
                      </Typography>
                      <Typography variant="body1">
                        You can&apos;t undo this action
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </DKTDialog>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isSaveWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
    </InnerLayout>
  )
}

export default OwnerContractor
