import {
  CREATE_AND_UPDATE_PROJECT_TEAM_FAILED,
  CREATE_AND_UPDATE_PROJECT_TEAM_REQUESTED,
  CREATE_AND_UPDATE_PROJECT_TEAM_SUCCESS,
  DELETE_PROJECT_TEAM_FAILED,
  DELETE_PROJECT_TEAM_REQUESTED,
  DELETE_PROJECT_TEAM_SUCCESS,
  FETCH_PROJECT_TEAM_FAILED,
  FETCH_PROJECT_TEAM_REQUESTED,
  FETCH_PROJECT_TEAM_SUCCESS,
  FETCH_REPORT_TIER_FAILED,
  FETCH_REPORT_TIER_REQUESTED,
  FETCH_REPORT_TIER_SUCCESS,
  SET_REPORT_TIER_FAILED,
  SET_REPORT_TIER_REQUESTED,
  SET_REPORT_TIER_SUCCESS,
} from '../constants/projectSettings'

const initialState = {
  isProjectTeamListLoading: false,
  projectTeamList: [],
  isProjectTeamDeletingLoading: false,
  isCreateAndUpdateProjectTeamLoading: false,
  isReportTierLoading: false,
  isSetReportTierLoading: false,
  activeReportTier: null,
}

export const projectSettingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch project team
    case FETCH_PROJECT_TEAM_REQUESTED:
      return {
        ...state,
        isProjectTeamListLoading: true,
      }
    case FETCH_PROJECT_TEAM_SUCCESS:
      return {
        ...state,
        isProjectTeamListLoading: false,
        projectTeamList: action?.payload,
      }
    case FETCH_PROJECT_TEAM_FAILED:
      return {
        ...state,
        isProjectTeamListLoading: false,
        projectTeamList: [],
      }
    // Create and update project team
    case CREATE_AND_UPDATE_PROJECT_TEAM_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateProjectTeamLoading: true,
      }
    case CREATE_AND_UPDATE_PROJECT_TEAM_SUCCESS:
      return {
        ...state,
        isCreateAndUpdateProjectTeamLoading: false,
        projectTeamList: action.payload,
      }
    case CREATE_AND_UPDATE_PROJECT_TEAM_FAILED:
      return {
        ...state,
        isCreateAndUpdateProjectTeamLoading: false,
      }
    // Delete project team
    case DELETE_PROJECT_TEAM_REQUESTED:
      return {
        ...state,
        isProjectTeamDeletingLoading: true,
      }
    case DELETE_PROJECT_TEAM_SUCCESS:
      return {
        ...state,
        isProjectTeamDeletingLoading: false,
        projectTeamList: action.payload,
      }
    case DELETE_PROJECT_TEAM_FAILED:
      return {
        ...state,
        isProjectTeamDeletingLoading: false,
      }
    // Set Report tier
    case SET_REPORT_TIER_REQUESTED:
      return {
        ...state,
        isSetReportTierLoading: true,
      }
    case SET_REPORT_TIER_SUCCESS:
      return {
        ...state,
        isSetReportTierLoading: false,
      }
    case SET_REPORT_TIER_FAILED:
      return {
        ...state,
        isSetReportTierLoading: false,
      }
    // Fetch Report tier
    case FETCH_REPORT_TIER_REQUESTED:
      return {
        ...state,
        isReportTierLoading: true,
      }
    case FETCH_REPORT_TIER_SUCCESS:
      return {
        ...state,
        isReportTierLoading: false,
        activeReportTier: action.payload,
      }
    case FETCH_REPORT_TIER_FAILED:
      return {
        ...state,
        isReportTierLoading: false,
      }

    default:
      return state
  }
}
