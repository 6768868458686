import {
  FETCH_COMPANY_FAILED,
  FETCH_COMPANY_KEY_PERSONNEL_FAILED,
  FETCH_COMPANY_KEY_PERSONNEL_REQUESTED,
  FETCH_COMPANY_KEY_PERSONNEL_SUCCESS,
  FETCH_COMPANY_REQUESTED,
  FETCH_COMPANY_SERVICE_OFFERED_FAILED,
  FETCH_COMPANY_SERVICE_OFFERED_REQUESTED,
  FETCH_COMPANY_SERVICE_OFFERED_SUCCESS,
  FETCH_COMPANY_SERVICE_STATE_LOCATION_FAILED,
  FETCH_COMPANY_SERVICE_STATE_LOCATION_REQUESTED,
  FETCH_COMPANY_SERVICE_STATE_LOCATION_SUCCESS,
  FETCH_COMPANY_SERVICE_US_TERRITORIES_FAILED,
  FETCH_COMPANY_SERVICE_US_TERRITORIES_REQUESTED,
  FETCH_COMPANY_SERVICE_US_TERRITORIES_SUCCESS,
  FETCH_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILED,
  UPDATE_COMPANY_REQUESTED,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_KEY_PERSONNEL_FAILED,
  UPDATE_KEY_PERSONNEL_REQUESTED,
  UPDATE_KEY_PERSONNEL_SUCCESS,
} from '../constants/companyInformation'

const initialState = {
  isCompanyLoading: false,
  companyData: [],
  isUpdateCompanyLoading: false,
  updateCompanyError: '',
  companyServiceOffered: [],
  isServiceOfferedLoading: false,
  companyServiceStateLocation: [],
  isServiceStateLocationLoading: '',
  companyServiceUsTerritories: [],
  isServiceUsTerritoriesLoading: '',
  // key-personnel
  isKeyPersonnelFetchLoading: false,
  keyPersonnel: {},
  isKeyPersonnelSaveLoading: false,
  keyPersonnelError: null,
}

export const companyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch company
    case FETCH_COMPANY_REQUESTED:
      return {
        ...state,
        isCompanyLoading: true,
      }
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        companyData: action.payload,
        isCompanyLoading: false,
        updateCompanyError: '',
      }
    case FETCH_COMPANY_FAILED:
      return {
        ...state,
        isCompanyLoading: false,
      }
    // Fetch company service offered
    case FETCH_COMPANY_SERVICE_OFFERED_REQUESTED:
      return {
        ...state,
        isServiceOfferedLoading: true,
      }
    case FETCH_COMPANY_SERVICE_OFFERED_SUCCESS:
      return {
        ...state,
        companyServiceOffered: action.payload,
        isServiceOfferedLoading: false,
      }
    case FETCH_COMPANY_SERVICE_OFFERED_FAILED:
      return {
        ...state,
        isServiceOfferedLoading: false,
      }
    // Fetch company service state location
    case FETCH_COMPANY_SERVICE_STATE_LOCATION_REQUESTED:
      return {
        ...state,
        isServiceStateLocationLoading: true,
      }
    case FETCH_COMPANY_SERVICE_STATE_LOCATION_SUCCESS:
      return {
        ...state,
        companyServiceStateLocation: action.payload,
        isServiceStateLocationLoading: false,
      }
    case FETCH_COMPANY_SERVICE_STATE_LOCATION_FAILED:
      return {
        ...state,
        isServiceStateLocationLoading: false,
      }
    // Fetch company service us territories
    case FETCH_COMPANY_SERVICE_US_TERRITORIES_REQUESTED:
      return {
        ...state,
        isServiceUsTerritoriesLoading: true,
      }
    case FETCH_COMPANY_SERVICE_US_TERRITORIES_SUCCESS:
      return {
        ...state,
        companyServiceUsTerritories: action.payload,
        isServiceUsTerritoriesLoading: false,
      }
    case FETCH_COMPANY_SERVICE_US_TERRITORIES_FAILED:
      return {
        ...state,
        isServiceUsTerritoriesLoading: false,
      }
    // Update company
    case UPDATE_COMPANY_REQUESTED:
      return {
        ...state,
        isUpdateCompanyLoading: true,
      }
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        isUpdateCompanyLoading: false,
        updateCompanyError: '',
        companyData: [action.payload],
      }
    case UPDATE_COMPANY_FAILED:
      return {
        ...state,
        isUpdateCompanyLoading: false,
        updateCompanyError: action.payload,
      }
    // Fetch company key personnel
    case FETCH_COMPANY_KEY_PERSONNEL_REQUESTED:
      return {
        ...state,
        isKeyPersonnelFetchLoading: true,
      }
    case FETCH_COMPANY_KEY_PERSONNEL_SUCCESS:
      return {
        ...state,
        keyPersonnel: action.payload,
        isKeyPersonnelFetchLoading: false,
        keyPersonnelError: '',
      }
    case FETCH_COMPANY_KEY_PERSONNEL_FAILED:
      return {
        ...state,
        isKeyPersonnelFetchLoading: false,
      }
    // update company key personnel
    case UPDATE_KEY_PERSONNEL_REQUESTED:
      return {
        ...state,
        isKeyPersonnelSaveLoading: true,
      }
    case UPDATE_KEY_PERSONNEL_SUCCESS:
      return {
        ...state,
        keyPersonnel: action.payload,
        isKeyPersonnelSaveLoading: false,
        keyPersonnelError: '',
      }
    case UPDATE_KEY_PERSONNEL_FAILED:
      return {
        ...state,
        isKeyPersonnelSaveLoading: false,
        keyPersonnelError: action.payload,
      }
    default:
      return state
  }
}
