import { InputAdornment } from '@mui/material'

export const optionFields = ['ownerContractor']
export const choiceFields = [
  'useValueFromBudgetSummaryCo',
  'useValueFromBudgetSummaryCoAndPco',
]

const percentageAdornment = <InputAdornment position="end">%</InputAdornment>

export const formFields = {
  hardCostContingency: {
    hardCostContingency: {
      type: 'text',
      label: 'Original HCC',
      xs: 6,
      disabled: true,
      isCurrency: true,
    },
    hccPerOfContractSum: {
      type: 'text',
      label: '(% of Contract Sum)',
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      xs: 6,
      disabled: true,
    },
    revisedHcc: {
      type: 'text',
      label: 'Revised HCC (By Reallocations)',
      isCurrency: true,
      xs: 6,
      // isRequired: true,
    },
    revisedHccPerOfContractSum: {
      type: 'text',
      label: '(% of Contract Sum)',
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      xs: 6,
      sx: {
        mb: 2,
      },
      disabled: true,
    },
    useValueFromBudgetSummaryCo: {
      type: 'select',
      label: 'Use Value from Budget Summary CO',
      placeholder: 'Select',
      displayEmpty: true,
      isDynamicOptions: true,
      options: [],
      xs: 12,
    },
    remainingHcc: {
      type: 'text',
      label: 'Remaining HCC (after COs)',
      xs: 6,
      decimalPlaces: 2,
      formatDigits: true,
      isLimitLess: true,
      isCurrency: true,
    },
    remainingHccPerOfRevisedHcc: {
      type: 'text',
      label: '(% of Revised HCC)',
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      xs: 6,
      sx: {
        mb: 2,
      },
      disabled: true,
    },
    useValueFromBudgetSummaryCoAndPco: {
      type: 'select',
      label: 'Use Value from Budget Summary CO and PCO',
      placeholder: 'Select',
      isDynamicOptions: true,
      displayEmpty: true,
      options: [],
      xs: 12,
    },
    remaining: {
      type: 'text',
      label: 'Remaining (after COs and PCOs)',
      xs: 6,
      decimalPlaces: 2,
      formatDigits: true,
      isLimitLess: true,
      isCurrency: true,
    },
    remainingPerOfRevisedHcc: {
      type: 'text',
      label: '(% of Revised HCC)',
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      xs: 6,
      sx: {
        mb: 2,
      },
      disabled: true,
    },
  },
  hccComments: {
    hccComments: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 10,
    },
  },
  contractorContingency: {
    originalContractorContingency: {
      type: 'text',
      label: 'Original GC Contingency',
      xs: 6,
      isCurrency: true,
    },
    contractorPerOfContractSum: {
      type: 'text',
      label: '(% of Contract Sum)',
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      xs: 6,
      disabled: true,
    },
    remainingContractorContingency: {
      type: 'text',
      label: 'Remaining GC Contingency',
      xs: 6,
      isCurrency: true,
    },
    originalContractorPerOfContractSum: {
      type: 'text',
      label: '(% of Original GC Contingency)',
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      xs: 6,
      disabled: true,
    },
  },
  contractorComments: {
    contractorContingencyComments: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 12,
    },
  },
  softCostContingency: {
    softCostContingency: {
      type: 'text',
      label: 'Soft Cost Contingency (SCC)',
      xs: 6,
      isCurrency: true,
      disabled: true,
      tooltip:
        'Project → Project Info → Budget and Contingency → Soft Cost Contingency (SCC)',
    },
    sccPerOfContractSum: {
      type: 'text',
      label: '(% of Contract Sum)',
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      xs: 6,
      disabled: true,
    },
    revisedScc: {
      type: 'text',
      label: 'Revised SCC (By Reallocations)',
      xs: 6,
      isCurrency: true,
    },
    revisedSccPerOfContractSum: {
      type: 'text',
      label: '(% of Contract Sum)',
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      xs: 6,
      disabled: true,
    },
    remainingScc: {
      type: 'text',
      label: 'Remaining Soft Cost Contingency',
      xs: 6,
      isCurrency: true,
    },
    remainingSccPerOfRevisedScc: {
      type: 'text',
      label: '(% of Revised SCC)',
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      xs: 6,
      sx: {
        mb: 2,
      },
      disabled: true,
    },
  },
  sccComments: {
    sccComments: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 12,
    },
  },
}
