import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { removeStateFn, saveStateFn } from '../../Utils/localStorage'
import { showToast } from '../../Utils/toastService'
import {
  FETCH_SYSTEM_FAILED,
  FETCH_SYSTEM_REQUESTED,
  FETCH_SYSTEM_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_FAILED,
  LOGIN_REQUESTED,
  LOGIN_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_SUCCESS,
  USER_ACTIVATION_FAILED,
  USER_ACTIVATION_REQUESTED,
  USER_ACTIVATION_SUCCESS,
  USER_LOGGED_OUT,
  GET_OTP_SUCCESS,
  CHECK_LINK_EXPIRE_REQUESTED,
  CHECK_LINK_EXPIRE_SUCCESS,
  CHECK_LINK_EXPIRE_FAILED,
  RESEND_EMAIL_REQUESTED,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAILED,
} from '../constants/auth'
import { replaceLocation } from '../../Utils/window'
import { ternary } from '../../Utils/javascript'

const loginRequested = () => ({ type: LOGIN_REQUESTED })
export const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload })
const loginFailed = (payload) => ({ type: LOGIN_FAILED, payload })
const getOtpSuccess = (payload) => ({ type: GET_OTP_SUCCESS, payload })

export const login =
  ({ rememberUsername, navigate, renderCaptcha = () => {}, ...bodyData }) =>
  async (dispatch) => {
    dispatch(loginRequested())
    const { data, error } = await api({
      endPoint: `${
        apiEndPoints[ternary(bodyData?.otp, 'twoFactorAuthentication', 'login')]
      }`,
      method: method.post,
      data: bodyData,
    })
    if (data?.detail) {
      saveStateFn(
        'loginInfo',
        JSON.stringify({ ...bodyData, rememberUsername }),
      )
      dispatch(getOtpSuccess())
      navigate(`/two-factor-auth`)
    } else if (data) {
      if (rememberUsername) {
        saveStateFn('rememberUsername', bodyData.email)
      } else {
        removeStateFn('rememberUsername')
      }
      removeStateFn('loginInfo')
      const { access, refresh, systemAuthorization, projects } = data
      const projectList = projects?.reduce(
        (allProjects, project) => ({
          ...allProjects,
          [project.project]: { ...project },
        }),
        {},
      )
      const payload = {
        accessToken: access,
        refreshToken: refresh,
        systemAuthorization,
        projects: projectList,
      }
      const localAuthPayload = {
        accessToken: access,
        refreshToken: refresh,
      }
      dispatch(loginSuccess(payload))
      saveStateFn('auth', JSON.stringify(localAuthPayload))
      showToast('Logged in successfully!', 'success')
      return
    }
    if (error) {
      showToast(error.response?.data?.detail)
      renderCaptcha()
      dispatch(loginFailed(error.response?.data))
    }
  }

const forgotPasswordRequested = () => ({ type: FORGOT_PASSWORD_REQUESTED })
const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS,
})
const forgotPasswordFailed = (payload) => ({
  type: FORGOT_PASSWORD_FAILED,
  payload,
})
export const sendResetPasswordMail = (payload) => async (dispatch) => {
  dispatch(forgotPasswordRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.forgotPassword,
    method: method.post,
    data: payload,
    showToastMessage: true,
  })
  if (data) {
    dispatch(forgotPasswordSuccess())
    return
  }
  if (error) {
    dispatch(forgotPasswordFailed(error.response.data))
  }
}

const resetPasswordRequested = () => ({ type: RESET_PASSWORD_REQUESTED })
const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
})
const resetPasswordFailed = (payload) => ({
  type: RESET_PASSWORD_FAILED,
  payload,
})
export const resetPassword =
  ({ navigate, ...payload }) =>
  async (dispatch) => {
    dispatch(resetPasswordRequested())
    const { data, error } = await api({
      endPoint: apiEndPoints.resetPassword,
      method: method.post,
      data: payload,
      showToastMessage: true,
    })
    if (data) {
      dispatch(resetPasswordSuccess())
      navigate('/login')
      return
    }
    if (error) {
      dispatch(resetPasswordFailed(error.response.data))
    }
  }

// Activate user
const userActivationRequested = () => ({ type: USER_ACTIVATION_REQUESTED })
const userActivationSuccess = () => ({
  type: USER_ACTIVATION_SUCCESS,
})
const userActivationFailed = (payload) => ({
  type: USER_ACTIVATION_FAILED,
  payload,
})
export const activateUser =
  ({ navigate, ...payload }) =>
  async (dispatch) => {
    dispatch(userActivationRequested())
    const { data, error } = await api({
      endPoint: apiEndPoints.activateUser,
      method: method.post,
      data: payload,
      showToastMessage: true,
    })
    if (data) {
      dispatch(userActivationSuccess())
      navigate('/login')
      return
    }
    if (error) {
      dispatch(userActivationFailed(error.response.data))
    }
  }

// User logout
export const userLogout = () => ({ type: USER_LOGGED_OUT })

// System authorization
const fetchSystemAuthorizationRequested = () => ({
  type: FETCH_SYSTEM_REQUESTED,
})
export const fetchSystemAuthorizationSuccess = (payload) => ({
  type: FETCH_SYSTEM_SUCCESS,
  payload,
})
const fetchSystemAuthorizationFailed = (payload) => ({
  type: FETCH_SYSTEM_FAILED,
  payload,
})

export const getAuthorization = () => async (dispatch) => {
  dispatch(fetchSystemAuthorizationRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.systemAuthorization}`,
    method: method.get,
    showToastMessage: false,
    toastMessage: 'System authorization Fetch successfully!',
  })
  if (data) {
    const { systemAuthorization, projects, portalType } = data
    const projectList = projects?.reduce(
      (allProjects, project) => ({
        ...allProjects,
        [project.project]: { ...project },
      }),
      {},
    )
    const payload = {
      systemAuthorization,
      projects: projectList,
      portalType,
    }
    dispatch(fetchSystemAuthorizationSuccess(payload))
    return
  }
  if (error) {
    showToast('Something went wrong!', 'error')
    dispatch(userLogout())
    removeStateFn('auth')
    removeStateFn('company-id')
    replaceLocation('/login')
    dispatch(fetchSystemAuthorizationFailed(error?.response?.data))
  }
}

export const resendOtp = (payload) => async () => {
  await api({
    endPoint: `${apiEndPoints.recentOTP}`,
    method: method.post,
    showToastMessage: true,
    data: payload,
    toastMessage: 'An OTP has been successfully sent to your email!',
  })
}

const checkLinkExpireRequested = () => ({
  type: CHECK_LINK_EXPIRE_REQUESTED,
})
export const checkLinkExpireSuccess = (payload) => ({
  type: CHECK_LINK_EXPIRE_SUCCESS,
  payload,
})
const checkLinkExpireFailed = (payload) => ({
  type: CHECK_LINK_EXPIRE_FAILED,
  payload,
})

export const checkLinkExpire = (token) => async (dispatch) => {
  dispatch(checkLinkExpireRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.resetPassword}${token}`,
    method: method.get,
    showToastMessage: false,
  })
  if (data) {
    dispatch(checkLinkExpireSuccess(data))
    return
  }
  if (error) {
    dispatch(checkLinkExpireFailed())
  }
}

const resendEmailRequested = () => ({
  type: RESEND_EMAIL_REQUESTED,
})
export const resendEmailSuccess = () => ({
  type: RESEND_EMAIL_SUCCESS,
})
const resendEmailFailed = () => ({
  type: RESEND_EMAIL_FAILED,
})

export const resendLink = (payload) => async (dispatch) => {
  dispatch(resendEmailRequested())
  const { data, error } = await api({
    endPoint: apiEndPoints.resendMail,
    method: method.post,
    data: { token: payload },
    showToastMessage: true,
  })
  if (data) {
    dispatch(resendEmailSuccess())
  } else if (error) {
    dispatch(resendEmailFailed())
  }
}
