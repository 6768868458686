import {
  Box,
  Grid,
  Stack,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Delete } from '@mui/icons-material'
import { CircledPlusIcon, DragIcon } from '../../../Assets/SVGs'
import {
  addressFields,
  columns,
  columnsKeys,
  prefillFormFields,
  selectedRowFormFields,
} from '../../../Description/projectDirectory.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/project'
import {
  addProjectDirectory,
  createProjectDirectory,
  deleteProjectDirectory,
  deleteProjectDirectoryContact,
  getProjectDirectory,
  getProjectDirectoryList,
  getProjectDirectoryMapping,
  resetCurrentProjectDirectory,
  saveProjectDirectory,
  setProjectDirectoryId,
  sortContactTypes,
} from '../../../Redux/actions/projectDirectory'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTSortableTable from '../../../Shared/DKTSortableTable'
import { useStyles } from '../../../Styles/projectDirectory.style'
import {
  BOOLEAN,
  NUMBER,
  SA,
  spinnerSize,
  swapWarningOC,
} from '../../../Utils/constant'
import {
  checkIncludes,
  equal,
  gt,
  isArray,
  length,
  ternary,
} from '../../../Utils/javascript'
import { showToast } from '../../../Utils/toastService'
import CreateContact from './CreateContact'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { CLEAR_PROJECT_DIRECTORY } from '../../../Redux/constants/projectDirectory'
import DKTCheckbox from '../../../Shared/DKTCheckbox'

const btnStyles = makeStyles(() => ({
  addBtn: {
    marginLeft: 230,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },

  inlineLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 'bold',
      color: '#2D2D2D',
      fontSize: 14,
      marginBottom: 8,
    },
  },
  '& .selectedRow': {
    backgroundColor: '#00000014',
  },
}))

const getFullAddress = (contactType) =>
  addressFields
    .filter((addressField) => contactType[addressField])
    .map((addressField) => contactType[addressField])
    .join(', ')

const ProjectDirectory = () => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false)
  const [contactTypeToEdit, setContactTypeToEdit] = useState(null)
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [isDragFieldDirty, setIsDragFieldDirty] = useState(false)
  const [deleteContactUnSave, setDeleteContactUnSave] = useState(false)
  const [isSaveWarningOpen, setIsSaveWarningOpen] = useState(false)
  const [tempPDData, setTempPDData] = useState({ id: null, add: false })
  const [isAddNewContactType, setIsAddNewContactType] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const {
    values,
    setFieldValue,
    resetForm,
    handleSubmit,
    setErrors,
    setValues,
    setDummyData,
    setIsDirty,
    isDirty,
  } = useForm()
  const {
    values: selectedRows,
    handleChange: handleRowSelectionChange,
    setValues: setSelectedRows,
  } = useForm(selectedRowFormFields)
  const dispatch = useDispatch()
  const { slug: projectId } = useParams()
  const classes = useStyles()
  const btnClasses = btnStyles()

  const {
    projectDirectoryList,
    projectDirectory,
    projectDirectoryId,
    isNewProjectDirectory,
    mappings,
    isSavingProjectDirectory,
    isDeletingProjectDirectory,
    isCreatingProjectDirectory,
    isLoadingProjectDirectoryList,
    saveProjectDirectoryError,
    isLoadingProjectDirectory,
  } = useSelector(({ projectDirectory }) => projectDirectory)
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)
  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+projectId]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission = isGlobalViewer || isProjectViewer
  // Set errors on save
  useEffect(() => {
    if (saveProjectDirectoryError)
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...saveProjectDirectoryError,
      }))
  }, [saveProjectDirectoryError, setErrors])

  // Fetch mappings and choices
  useEffect(() => {
    dispatch(getProjectDirectoryList(projectId))
  }, [dispatch, projectId])
  useEffect(() => {
    if (!projectDirectoryId) return
    if (
      !equal(projectDirectory?.id, +projectDirectoryId) &&
      !isNewProjectDirectory
    )
      dispatch(getProjectDirectory(projectDirectoryId))
    const projectDirectoryValues = {}
    if (projectDirectory)
      prefillFormFields.forEach((fieldName) => {
        const fieldValue = projectDirectory[fieldName]
        projectDirectoryValues[fieldName] = checkIncludes(typeof fieldValue, [
          BOOLEAN,
          NUMBER,
        ])
          ? fieldValue.toString()
          : fieldValue
      })
    setValues(projectDirectoryValues)
    setDummyData(projectDirectoryValues)
  }, [
    projectDirectory?.id,
    projectDirectory?.description,
    projectDirectory?.mapping,
    dispatch,
    projectDirectoryId,
    setFieldValue,
    isNewProjectDirectory,
    resetForm,
  ])

  useEffect(() => {
    if (isNewProjectDirectory) {
      resetForm()
      dispatch(resetCurrentProjectDirectory())
    }
    setErrors({})
    if (!projectDirectoryId) {
      resetForm()

      if (
        projectDirectoryList &&
        isArray(projectDirectoryList) &&
        projectDirectoryList[0]
      )
        dispatch(setProjectDirectoryId(projectDirectoryList[0]?.id, false))
    }
  }, [projectDirectoryId, isNewProjectDirectory, projectDirectoryList])

  useEffect(() => {
    if (mappings?.length) {
      setValues((prevValues) => ({
        ...prevValues,
        mapping:
          isArray(projectDirectory?.mapping) &&
          projectDirectory?.mapping?.length
            ? projectDirectory?.mapping?.map((mapEle) => `${mapEle}`)
            : equal(mappings?.length, 1)
            ? [`${mappings[0]?.value}`]
            : [],
      }))
      setDummyData((prevValues) => ({
        ...prevValues,
        mapping:
          isArray(projectDirectory?.mapping) &&
          projectDirectory?.mapping?.length
            ? projectDirectory?.mapping?.map((mapEle) => `${mapEle}`)
            : equal(mappings?.length, 1)
            ? [`${mappings[0]?.value}`]
            : [],
      }))
    }
  }, [
    projectDirectory,
    mappings,
    setFieldValue,
    isNewProjectDirectory,
    projectDirectoryId,
  ])

  useEffect(() => {
    if (
      !hasOnlyViewPermission &&
      !isLoadingProjectDirectoryList &&
      isArray(projectDirectoryList) &&
      equal(length(projectDirectoryList), 0)
    )
      dispatch(addProjectDirectory())
  }, [projectDirectoryList, isLoadingProjectDirectoryList, dispatch])

  useEffect(() => {
    dispatch(getProjectDirectoryMapping(projectId))
  }, [projectDirectory, isNewProjectDirectory, projectId, dispatch])

  // Set errors on save
  useEffect(() => {
    if (saveProjectDirectoryError)
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...saveProjectDirectoryError,
      }))
  }, [saveProjectDirectoryError, setErrors])

  useEffect(() => {
    if (tempPDData?.id) {
      if (isDirty || isDragFieldDirty || deleteContactUnSave) {
        setIsSaveWarningOpen(true)
      } else {
        resetForm()
        setTimeout(() => {
          dispatch(
            setProjectDirectoryId(
              tempPDData?.id,
              tempPDData?.isNewProjectDirectory,
            ),
          )
        }, 500)
        setErrors({})
      }
    } else if (tempPDData?.add) {
      if (isDirty || isDragFieldDirty || deleteContactUnSave) {
        setIsSaveWarningOpen(true)
      } else {
        handleAddProjectDirectory()
        resetForm()
      }
    }
  }, [tempPDData])

  useEffect(
    () => () => {
      dispatch({ type: CLEAR_PROJECT_DIRECTORY })
    },
    [],
  )

  const handleSetIsDirtyForm = () => {
    setIsDirty(false)
    setIsDragFieldDirty(false)
    setDeleteContactUnSave(false)
  }
  const handleSave = (showToastMessage = true) => {
    const isFormValid = handleSubmit()
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
    } else {
      handleSetIsDirtyForm()
      const filteredObj = { ...values }
      Object.keys(filteredObj).forEach((key) => {
        if (equal(filteredObj[key], undefined) || equal(filteredObj[key], '')) {
          delete filteredObj[key]
          filteredObj[key] = null
        }
      })
      if (
        equal(filteredObj?.mapping?.length, 0) ||
        equal(filteredObj?.mapping, null)
      ) {
        delete filteredObj.mapping
        filteredObj.isDeleted = true
      }
      if (isNewProjectDirectory) {
        dispatch(
          createProjectDirectory(filteredObj, projectId, showToastMessage),
        )
        return
      }
      dispatch(
        saveProjectDirectory(
          values,
          projectDirectoryId,
          projectId,
          showToastMessage,
        ),
      )
    }
  }

  const closeDeleteProjectDirectoryModal = () =>
    setIsDeleteNotificationOpen(false)

  const actionList = [
    { label: 'Delete', onClick: () => setIsDeleteNotificationOpen(true) },
  ]

  const sortableTableActions = () => (
    <IconButton
      color="error"
      onClick={() => setIsDeleteModalOpen(true)}
      sx={{ marginRight: 1 }}
    >
      <Delete color="error" />
    </IconButton>
  )

  const handleDeleteProjectDirectory = () => {
    const body = {
      projectDirectory: [projectDirectoryId],
    }
    if (gt(projectDirectoryList?.length, 1)) {
      dispatch(deleteProjectDirectory(body, closeDeleteProjectDirectoryModal))
    } else {
      showToast("You can't delete this record.", 'error')
      closeDeleteProjectDirectoryModal()
    }
  }

  const handleAddProjectDirectory = () => {
    if (!projectDirectory?.isOcAvailable) {
      showToast(swapWarningOC)
      return
    }
    dispatch(addProjectDirectory())
  }

  const removeProjectDirectory = useCallback(
    (rowId) => {
      if (rowId) {
        const filteredContacts = projectDirectory.contact.filter(
          (row) => !checkIncludes(`${row.id}`, rowId),
        )
        dispatch(
          deleteProjectDirectoryContact({
            ...projectDirectory,
            contact: filteredContacts,
          }),
        )
      }
      setSelectedRows((values) => ({ ...values, list: [] }))
      setDeleteContactUnSave(true)
    },
    [projectDirectory, dispatch, setSelectedRows],
  )

  const handleRemoveProjectDirectoryTableRows = () => {
    if (selectedRows.list) removeProjectDirectory(selectedRows.list)
    setIsDeleteModalOpen(false)
  }

  const deleteNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteProjectDirectoryModal}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteProjectDirectory}>
        {ternary(
          isDeletingProjectDirectory,
          <DKTCircularProgress size={spinnerSize?.sm} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const deleteModalActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => setIsDeleteModalOpen(false)}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleRemoveProjectDirectoryTableRows}
      >
        Yes
      </DKTButton>
    </>
  )

  const actions = () => (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Stack direction="row" gap={2} className={btnClasses.rightButton}>
        {!hasOnlyViewPermission && (
          <DKTButtonSelect
            options={actionList}
            disabled={isLoadingProjectDirectoryList || true}
          />
        )}
        <DKTButton
          className={btnClasses.rightButton}
          onClick={handleSave}
          disabled={
            hasOnlyViewPermission ||
            isSavingProjectDirectory ||
            isCreatingProjectDirectory ||
            isLoadingProjectDirectoryList
          }
        >
          {ternary(
            isSavingProjectDirectory || isCreatingProjectDirectory,
            'Saving...',
            'Save',
          )}
        </DKTButton>
      </Stack>
    </Box>
  )

  const handleEditContactType = (contactType) => {
    setContactTypeToEdit(contactType)
    setIsContactModalOpen(true)
  }

  const handleAddContact = () => {
    setIsAddNewContactType(true)
    setContactTypeToEdit(null)
    setIsContactModalOpen(true)
  }
  const handleCloseContactModal = () => {
    setIsAddNewContactType(false)
    setIsContactModalOpen(false)
  }

  const onSort = useCallback(
    (sortedData) => {
      dispatch(sortContactTypes(sortedData))
    },
    [dispatch],
  )

  const customRowCellsRender = (row) => (
    <>
      {!hasOnlyViewPermission && (
        <TableCell key={row?.id}>
          <Box className={classes.dragIcon}>
            <DragIcon />
            <DKTCheckbox
              sx={{ p: 0, ml: 2 }}
              name="list"
              value={row?.id}
              handleChange={handleRowSelectionChange}
              checked={
                checkIncludes(row?.id?.toString(), selectedRows?.list) || false
              }
            />
          </Box>
        </TableCell>
      )}
      {columnsKeys.map((columnName, index) => (
        <TableCell
          key={index}
          className={ternary(
            equal(columnName, 'address'),
            classes.addressTableCell,
            '',
          )}
        >
          {ternary(
            equal(columnName, 'contactType'),
            <div className={classes.contactType}>
              <Typography
                sx={{ cursor: 'pointer', display: 'inline' }}
                onClick={() => handleEditContactType(row)}
              >
                {row.contactTypeLabel}
              </Typography>
            </div>,
            <div className={classes.tableCellWidth}>
              {ternary(
                equal(columnName, 'address'),
                getFullAddress(row),
                row[columnName],
              )}
            </div>,
          )}
        </TableCell>
      ))}
    </>
  )

  const activeProjectDirectory = projectDirectoryList?.find(
    (projectDirectory) => equal(projectDirectory?.id, projectDirectoryId),
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setTempPDData({ id: null, add: false })
  }
  const confirmSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    dispatch(
      setProjectDirectoryId(tempPDData?.id, tempPDData?.isNewProjectDirectory),
    )
    setIsDirty(false)
    setErrors({})
    if (tempPDData?.add) {
      handleAddProjectDirectory()
      resetForm()
    }
    setTempPDData({ id: null, add: false })
  }

  return (
    <InnerLayout
      contentTitle="Project directory"
      actions={actions}
      maxWidth="md"
    >
      {equal(isLoadingProjectDirectoryList, true) ||
      equal(isLoadingProjectDirectory, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isLoadingProjectDirectoryList, 'FAILED') ||
        equal(isLoadingProjectDirectory, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Project directory data.
            Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <Box className={classes.contactsTable}>
            <DKTSortableTable
              onSort={onSort}
              columns={ternary(
                hasOnlyViewPermission,
                columns.slice(1),
                columns,
              )}
              data={projectDirectory?.contact || []}
              customRowCellsRender={customRowCellsRender}
              selectedRows={ternary(
                selectedRows.list,
                length(selectedRows.list),
                0,
              )}
              selectedRowsList={selectedRows?.list}
              rowIdentifier="id"
              preventDrag={hasOnlyViewPermission}
              setIsDragFieldDirty={setIsDragFieldDirty}
              actions={sortableTableActions}
              loading={isLoadingProjectDirectory}
            />
          </Box>
          <DKTButton
            variant="text"
            color="primary"
            sx={{ textTransform: 'initial', px: 1, mt: 1 }}
            startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
            onClick={handleAddContact}
            disabled={hasOnlyViewPermission}
          >
            <Typography>Add Contact Type</Typography>
          </DKTButton>
          <CreateContact
            open={isContactModalOpen}
            contactTypeToEdit={contactTypeToEdit}
            onClose={handleCloseContactModal}
            hasOnlyViewPermission={hasOnlyViewPermission}
            isAddNewContactType={isAddNewContactType}
            onSave={() => handleSave(false)}
          />
        </>
      )}
      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={closeDeleteProjectDirectoryModal}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                {`Are you sure you want to delete ${activeProjectDirectory?.uniqueCode}?`}
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      <DKTDialog
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        title="&nbsp;"
        actions={deleteModalActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isSaveWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt
        isDirty={isDirty || isDragFieldDirty || deleteContactUnSave}
        onSave={handleSave}
      />
    </InnerLayout>
  )
}

export default ProjectDirectory
