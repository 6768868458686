import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ListManager } from 'react-beautiful-dnd-grid'
import { Delete } from '@mui/icons-material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { useTheme } from '@mui/styles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useMediaQuery from '@mui/material/useMediaQuery'
import { optionsFields } from '../../Description/photographs.description'
import { InnerLayout } from '../../Layout/reportMonitoring'
import { useStyles, useStylesIcon } from '../../Styles/photographs.style'
import { getReportChoices } from '../../Redux/actions/reportChoices'
import DKTButton from '../../Shared/DKTButton'
import DKTButtonSelect from '../../Shared/DKTButtonSelect'
import {
  checkUndefined,
  equal,
  handleDownloadImage,
  isArray,
  keys,
  ternary,
} from '../../Utils/javascript'
import DKTFileInput from '../../Shared/DKTFileInput'
import DKTTextArea from '../../Shared/DKTTextArea'
import { CircledPlusIcon, DeleteIcon, DragIcon } from '../../Assets/SVGs'
import {
  createPhotographs,
  deletePhotographs,
  getPhotographs,
  updatePhotographs,
} from '../../Redux/actions/photographs'
import DKTDialog from '../../Shared/DKTDialog'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import { spinnerSize } from '../../Utils/constant'
// import DKTCheckbox from '../../Shared/DKTCheckbox'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import { CLEAR_PHOTOGRAPHS } from '../../Redux/constants/photographs'
import usePermission from '../../Hooks/usePermission'

const ImageRender = memo(({ photo }) => (
  <Box
    sx={{
      position: 'relative',
      paddingBottom: '56.2%',
    }}
  >
    <LazyLoadImage
      src={photo}
      alt="Photograph"
      loading="lazy"
      style={{
        objectFit: 'cover',
        position: 'absolute',
        width: '100%',
        height: '100% ',
        zIndex: '-1',
        backgroundColor: '#F4F4F4',
      }}
    />
  </Box>
))

const ListElement = memo(
  ({
    handleDelete,
    item,
    // handleSelectedCoverPhoto,
    // selectedCoverPhoto,
    handleChangeTextBox,
    textBoxValue,
    hasOnlyViewPermission,
    currentProject,
  }) => {
    const iconClasses = useStylesIcon()
    return (
      <>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          {!hasOnlyViewPermission && (
            <Box
              sx={{
                position: 'absolute',
                left: '10px',
                top: '10px',
                zIndex: '5',
                background: '#ffffffe6',
                borderRadius: 0,
                padding: '0px 4px 2px 4px',
              }}
            >
              <DragIcon />
            </Box>
          )}
          {!hasOnlyViewPermission && (
            <Stack
              direction="row"
              spacing={0.5}
              sx={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                zIndex: '5',
              }}
            >
              <IconButton
                sx={{
                  background: '#ffffffe6',
                  borderRadius: 0,
                  padding: '4px',
                }}
                onClick={() =>
                  handleDownloadImage(
                    item?.photo,
                    `${currentProject?.projectName} photographs ${item?.sortOrder}`,
                  )
                }
                className={iconClasses.iconWrapper}
              >
                <CloudDownloadIcon />
              </IconButton>
              <IconButton
                className={iconClasses.iconWrapper}
                onClick={() => handleDelete(item?.id)}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          )}
          {/* <Stack
            sx={{
              position: 'absolute',
              left: '0px',
              bottom: '-5px',
              background: '#ffffffe6',
              paddingRight: '4px',
              cursor: 'pointer',
            }}
            direction="row"
            alignItems="center"
          >
            <DKTCheckbox
              sx={{ padding: '3px' }}
              name="list"
              handleChange={() => handleSelectedCoverPhoto(item?.id)}
              checked={equal(selectedCoverPhoto, item?.id)}
              disabled={hasOnlyViewPermission}
            />
            <Typography
              onClick={() => handleSelectedCoverPhoto(item?.id)}
              sx={{
                color: '#3E3E3E',
                fontSize: '14px',
                cursor: 'pointer !important',
                '& :hover': {
                  background: '#fff',
                },
              }}
            >
              Select for Cover Page
            </Typography>
          </Stack> */}
          <ImageRender photo={item?.photo} />
        </Box>
        <Box>
          <DKTTextArea
            value={
              checkUndefined(textBoxValue[item.id])
                ? item?.textBox
                : textBoxValue[item.id]
            }
            name="textBox"
            id={item?.id}
            rows={4}
            placeholder="Placeholder Text"
            onChange={handleChangeTextBox}
            disabled={hasOnlyViewPermission}
          />
        </Box>
      </>
    )
  },
)
const Photographs = () => {
  const [ownerContractorId, setOwnerContractorId] = useState(null)
  const [photographs, setPhotographs] = useState([])
  const [selectedPhotograph, setSelectedPhotograph] = useState(null)
  const [selectedCoverPhoto, setSelectedCoverPhoto] = useState(null)
  const [textBoxValue, setTextBoxValue] = useState({})
  const [deleteNotificationOpen, setOpenDeleteNotification] =
    React.useState(false)
  const [photographList, setPhotographList] = useState([])
  const [photographsUploadIsDirty, setPhotographsUploadIsDirty] =
    useState(false)
  const [tempDummyValues, setTempDummyValues] = useState([])
  const [isDirty, setIsDirty] = useState(false)
  const [dragPhotoIsDirty, setDragPhotoIsDirty] = useState(false)
  const [compressedLoader, setCompressedLoader] = useState(false)

  const { slug: projectId, reportId } = useParams()
  const dispatch = useDispatch()
  const classes = useStyles()
  const theme = useTheme()
  const isScreenSmall = useMediaQuery('(min-width:1330px)')
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)
  const {
    photographsList,
    isPhotographDeleting,
    isPhotographListLoading,
    isPhotographListCreateOrUpdating,
  } = useSelector(({ photographs }) => photographs)
  const { currentProject } = useSelector(({ projects }) => projects)
  const { hasOnlyViewPermission } = usePermission()
  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionsFields?.join(','),
        projectId,
      }),
    )
  }, [dispatch, projectId])
  // Fetch photographs list
  useEffect(() => {
    if (reportId && ownerContractorId && projectId) {
      dispatch(getPhotographs(reportId, ownerContractorId, projectId))
    }
  }, [dispatch, reportId, ownerContractorId, projectId])

  useEffect(() => {
    setPhotographList(photographsList)
  }, [photographsList])

  // Set current cover photo
  useEffect(() => {
    const currentCoverPhotoId = photographsList?.find(
      (photo) => photo?.isCoverPage,
    )
    setSelectedCoverPhoto(currentCoverPhotoId?.id)
  }, [photographsList])

  useEffect(() => {
    let activeContractor = null
    if (isArray(choices?.ownerContractor) && choices?.ownerContractor?.length) {
      activeContractor = choices?.ownerContractor[0]
    }
    setOwnerContractorId(activeContractor?.id)
  }, [choices?.ownerContractor[0]?.id])

  useEffect(() => {
    if (photographs.length) {
      setPhotographsUploadIsDirty(true)
    } else {
      setPhotographsUploadIsDirty(false)
    }
  }, [photographs])

  const tempChangeData = tempDummyValues.filter(
    (item, index) => tempDummyValues.indexOf(item) === index,
  )
  const result = tempChangeData?.map((id) => {
    const item = photographList?.find((x) => x.id === Number(id))
    const res = []
    if (keys(textBoxValue).length) {
      res.push(item?.textBox !== textBoxValue[id])
    }
    return res
  })
  const selectValueDirty = []
  const item = photographList?.find((x) => x.id === Number(selectedCoverPhoto))
  if (selectedCoverPhoto !== undefined && selectedCoverPhoto !== null) {
    selectValueDirty.push(item.isCoverPage !== true)
  }
  useEffect(() => {
    const mergeArr = [...result, ...selectValueDirty]
    if (mergeArr.flat().some((value) => value === true)) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }, [selectedCoverPhoto, textBoxValue])

  // clear photographs
  useEffect(
    () => () => {
      dispatch({ type: CLEAR_PHOTOGRAPHS })
    },
    [],
  )

  // Close all popup
  const handleClose = () => {
    setSelectedPhotograph(null)
    setOpenDeleteNotification(false)
  }
  const onMultipleFileUpload = (base64Files) => {
    setPhotographs([...photographs, ...base64Files])
  }

  const handleRemovePhotograph = () => {
    setPhotographs([])
  }

  const handleSelectedCoverPhoto = (id) => {
    setSelectedCoverPhoto(id)
  }
  // Delete photographs
  const handleDeletePhotographs = () => {
    const body = {
      photograph: ternary(isArray(selectedPhotograph), selectedPhotograph, [
        selectedPhotograph,
      ]),
    }
    dispatch(
      deletePhotographs(
        body,
        reportId,
        ownerContractorId,
        projectId,
        handleClose,
      ),
    )
  }

  const handleDelete = (id) => {
    setOpenDeleteNotification(true)
    setSelectedPhotograph(id)
  }

  const handleDeleteAll = () => {
    setOpenDeleteNotification(true)
    setSelectedPhotograph(photographList?.map(({ id }) => id))
  }
  // Re order list
  const handleReorderList = (sourceIndex, destinationIndex) => {
    if (hasOnlyViewPermission) return
    if (photographList[sourceIndex].sortOrder !== destinationIndex + 1) {
      setDragPhotoIsDirty(true)
    }
    setPhotographList((prevData) => {
      const clonedData = [...prevData]
      clonedData.splice(sourceIndex, 1)
      clonedData.splice(destinationIndex, 0, prevData[sourceIndex])
      return clonedData
    })
  }

  // add placeholder text
  const handleChangeTextBox = (e) => {
    const { value, id } = e.target
    setTempDummyValues([...tempDummyValues, id])
    const cloneTextBox = { ...textBoxValue }
    setTextBoxValue({ ...cloneTextBox, ...{ [id]: value } })
  }

  // Upload dragged or drop image
  const handleUploadPhotoGraph = () => {
    const formData = new FormData()

    photographs.forEach((photo) => {
      formData.append('photos_list', photo)
    })
    dispatch(
      createPhotographs(formData, reportId, ownerContractorId, projectId),
    ).then(() => {
      setPhotographs([])
    })
  }
  const setDirtyStateFalse = () => {
    setIsDirty(false)
    setPhotographsUploadIsDirty(false)
    setDragPhotoIsDirty(false)
  }
  // Update photographs list data
  const handleUpdate = () => {
    const body = photographList?.map((data) => ({
      id: data?.id,
      textBox: ternary(
        checkUndefined(textBoxValue[data?.id]),
        data?.textBox,
        textBoxValue[data?.id],
      ),
      isCoverPage: ternary(equal(selectedCoverPhoto, data?.id), true, false),
      report: reportId,
      ownerContractor: ownerContractorId,
      photo: data?.photo,
    }))
    dispatch(updatePhotographs(body, reportId, ownerContractorId, projectId))
    setDirtyStateFalse()
  }
  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" gap={2}>
          <DKTButtonSelect
            options={actionList}
            disabled={hasOnlyViewPermission || isPhotographListLoading || true}
          />
          <DKTButton
            className={classes.rightButton}
            onClick={handleUpdate}
            disabled={
              hasOnlyViewPermission ||
              isPhotographListCreateOrUpdating ||
              isPhotographListLoading ||
              !photographList?.length ||
              photographs?.length
            }
          >
            {ternary(isPhotographListCreateOrUpdating, 'Saving...', 'Save')}
          </DKTButton>
        </Stack>
      </Box>
    </>
  )
  const actionList = [{ label: 'Delete', onClick: () => null }]

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeletePhotographs}>
        {ternary(
          isPhotographDeleting,
          <DKTCircularProgress
            size={spinnerSize?.sm}
            color={theme.palette.gray.dark}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const onSave = () => {
    if (photographsUploadIsDirty) {
      handleUploadPhotoGraph()
    }
    if (isDirty || dragPhotoIsDirty) {
      handleUpdate()
    }
  }
  return (
    <InnerLayout
      contentTitle="PHOTOGRAPHS"
      actions={actions}
      maxWidth="md"
      isShowMenu
    >
      {equal(isPhotographListLoading, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isPhotographListLoading, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Photographs data. Please try
            contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <Grid container item xs={12} lg={10}>
          <Grid container spacing={2} mt={3}>
            {!hasOnlyViewPermission && (
              <Grid item xs={12} lg={12}>
                {!!photographList?.length && (
                  <Box sx={{ mb: 2, display: 'flex', justifyContent: 'end' }}>
                    <DKTButton
                      type="button"
                      variant="outlined"
                      onClick={handleDeleteAll}
                    >
                      Delete All
                    </DKTButton>
                  </Box>
                )}
                <Box component="form" enctype="multipart/form-data">
                  <DKTFileInput
                    onMultipleFileUpload={onMultipleFileUpload}
                    multiple
                    removeUploadLimit
                    setCompressedLoader={setCompressedLoader}
                  />

                  {ternary(
                    photographs?.length && !compressedLoader,
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Box mx={2}>
                        {`${photographs?.length} photo${
                          photographs?.length === 1 ? '' : 's'
                        } uploaded`}
                      </Box>
                      <IconButton
                        color="error"
                        onClick={handleRemovePhotograph}
                      >
                        <Delete />
                      </IconButton>
                      <Box sx={{ marginLeft: 'auto' }}>
                        {ternary(
                          isPhotographListCreateOrUpdating,
                          <DKTCircularProgress size={spinnerSize?.sm} />,
                          <DKTButton
                            variant="text"
                            color="primary"
                            sx={{ textTransform: 'initial' }}
                            startIcon={<CircledPlusIcon />}
                            onClick={handleUploadPhotoGraph}
                          >
                            Upload Successful, Add Photo?
                          </DKTButton>,
                        )}
                      </Box>
                    </Stack>,
                    compressedLoader && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                      >
                        <DKTCircularProgress size={spinnerSize?.sm} />
                        <Box mx={2}>
                          Please wait while the images are being compressed...
                        </Box>
                      </Stack>
                    ),
                  )}
                </Box>
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <div className={classes.imageBox}>
                {ternary(
                  !isPhotographListLoading,
                  <ListManager
                    items={photographList}
                    direction={ternary(
                      !isScreenSmall,
                      'vertical',
                      'horizontal',
                    )}
                    maxItems={2}
                    render={(item) => (
                      <ListElement
                        item={item}
                        handleDelete={handleDelete}
                        handleSelectedCoverPhoto={handleSelectedCoverPhoto}
                        selectedCoverPhoto={selectedCoverPhoto}
                        handleChangeTextBox={handleChangeTextBox}
                        textBoxValue={textBoxValue}
                        hasOnlyViewPermission={hasOnlyViewPermission}
                        isScreenSmall={isScreenSmall}
                        currentProject={currentProject}
                      />
                    )}
                    onDragEnd={handleReorderList}
                  />,
                  <DKTCircularProgress />,
                )}
              </div>
              {ternary(
                !photographList?.length && !isPhotographListLoading,
                <Box>
                  <Typography textAlign="center">
                    Sorry, there is no photograph to display
                  </Typography>
                </Box>,
                null,
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <DKTDialog
        open={deleteNotificationOpen}
        handleClose={handleClose}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {!hasOnlyViewPermission && (
        <DKTReactRouterPrompt
          isDirty={photographsUploadIsDirty || isDirty || dragPhotoIsDirty}
          onSave={onSave}
        />
      )}
    </InnerLayout>
  )
}

export default Photographs
