import { Box, Grid, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { components } from 'react-select'
import {
  formFields,
  architecturalConsultants,
} from '../../Description/ownerArchitect.description'
import useForm from '../../Hooks/useForm'
import { InnerLayout } from '../../Layout/project'
import DKTButton from '../../Shared/DKTButton'
import DKTInput from '../../Shared/DKTInput'
import DKTSelect from '../../Shared/DKTSelect'
import DKTSearchableSelect from '../../Shared/DKTSearchableSelect'
import {
  entries,
  equal,
  gt,
  handleBlurAll,
  isArray,
  ternary,
} from '../../Utils/javascript'
import {
  fetchProjectFieldChoices,
  getProjectList,
} from '../../Redux/actions/projects'
import {
  createAndUpdateOwnerArchitect,
  getOwnerArchitect,
  getOwnerContractorMapping,
  addOwnerArchitect,
  searchArchitect,
  deleteOwnerArchitect,
} from '../../Redux/actions/ownerArchitect'
import { renderFormFields as renderFormFieldsControl } from '../../Utils/renderFormFields'
import DKTTextArea from '../../Shared/DKTTextArea'
import CreateContact from './projectDirectory/CreateContact'
import { SA, spinnerSize, swapWarningOC } from '../../Utils/constant'
import DKTCurrencyTextField from '../../Shared/DKTCurrencyTextField'
import { showToast } from '../../Utils/toastService'
import DKTDialog from '../../Shared/DKTDialog'
import DKTButtonSelect from '../../Shared/DKTButtonSelect'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../Redux/actions/confirmation'
import { CLEAR_OWNER_ARCHITECT } from '../../Redux/constants/ownerArchitect'
import DKTForm from '../../Shared/DKTForm'

const optionsFields = [
  'aiaFormAgreement,siteVisitFrequency,architecturalConsultants',
]
const allFormFields = entries(formFields).reduce(
  (result, formField) => {
    const formFieldValue = formField[1]
    return {
      ...result,
      ...formFieldValue,
    }
  },
  { architecturalConsultants },
)

const btnStyle = makeStyles(() => ({
  addBtn: {
    marginLeft: 230,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  inlineLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 'bold',
      color: '#2D2D2D',
      fontSize: 14,
      marginBottom: 8,
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))

const SelectMenuButton = ({
  children,
  setIsCompanyModalOpen = () => {},
  ...props
}) => (
  <components.MenuList {...props}>
    {children}
    <button
      type="button"
      onClick={() => setIsCompanyModalOpen(true)}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '5px',
        marginTop: '10px',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        border: '0.5px solid lightgrey',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        cursor: 'pointer',
        width: '100%',
      }}
    >
      <AddIcon /> Add/Edit Architect
    </button>
  </components.MenuList>
)
const CustomOption = ({ children, ...rest }) => (
  <components.Option {...rest} ref={rest?.innerRef}>
    <Stack direction="row" justifyContent="space-between" alignItems="baseline">
      <Stack direction="row" spacing={1} alignItems="baseline">
        <Typography>{children}</Typography>
        <Stack direction="row" ml={1} alignItems="baseline" spacing={0.5}>
          <Typography fontSize={12} lineHeight="14px">
            {rest?.data?.city}
          </Typography>
          <Typography fontSize={12} lineHeight="14px">
            {rest?.data?.state}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  </components.Option>
)
const OwnerArchitect = () => {
  const dispatch = useDispatch()
  const { slug } = useParams()
  const classes = btnStyle()

  const [projectOptionChoicesData, setProjectOptionChoicesData] = useState({})
  const [ownerArchitectId, setOwnerArchitectId] = useState(null)
  const [architect, setArchitect] = useState({})
  const [IsCompanyModalOpen, setIsCompanyModalOpen] = useState(false)
  const [deleteNotificationOpen, setOpenDeleteNotification] =
    React.useState(false)
  const [isSaveWarningOpen, setIsSaveWarningOpen] = useState(false)
  const [tempOAData, setTempOAData] = useState({ id: null, add: false })
  const { projectOptionChoices, currentProject } = useSelector(
    ({ projects }) => projects,
  )

  const {
    ownerArchitectList,
    isCreateAndUpdateOwnerArchitectLoading,
    ownerContractorMapping,
    activeArchitect,
    ownerArchitectError,
    searchResults,
    isSearchingArchitect,
    isDeletingArchitect,
    isOwnerArchitectListLoading,
  } = useSelector(({ ownerArchitect }) => ownerArchitect)

  const ref = useRef()
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)
  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+slug]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission = isGlobalViewer || isProjectViewer

  const {
    values,
    errors,
    handleChange,
    setValues,
    handleSubmit,
    resetForm,
    setErrors,
    isDirty,
    setIsDirty,
    setDummyData,
  } = useForm(allFormFields, CLEAR_OWNER_ARCHITECT)

  useEffect(() => {
    dispatch(fetchProjectFieldChoices(optionsFields))
    dispatch(getProjectList(slug))
    setValues({ ...values, project: slug })
    setDummyData({ ...values, project: slug })
  }, [dispatch, setValues, slug])

  useEffect(() => {
    dispatch(getOwnerArchitect(slug))
  }, [dispatch, slug])

  useEffect(() => {
    dispatch(searchArchitect(null, 'isPopulate'))
  }, [dispatch, slug, isDeletingArchitect])

  useEffect(() => {
    const currentArchitect = ownerArchitectList?.find((project) =>
      equal(project?.id, +ownerArchitectId),
    )
    if (searchResults?.length) {
      const architectObj = searchResults?.find(
        (res) => `${res?.value}` === currentArchitect?.architect,
      )
      setArchitect(architectObj)
    }
  }, [
    dispatch,
    setValues,
    slug,
    searchResults,
    ownerArchitectList,
    ownerArchitectId,
  ])

  useEffect(() => {
    if (ownerArchitectError) {
      const errorData = { ...errors }
      Object.entries(ownerArchitectError).forEach(
        ([errorName, [errorDesc]]) => {
          errorData[errorName] = errorDesc
        },
      )
      setErrors(errorData)
    }
    return () => {
      setErrors({})
    }
  }, [ownerArchitectError, isDeletingArchitect])

  useEffect(() => {
    if (!ownerArchitectList?.length) {
      handleAddNewOwnerArchitect()
    }
  }, [ownerArchitectList, isDeletingArchitect])

  useEffect(() => {
    if (!isCreateAndUpdateOwnerArchitectLoading) {
      if (activeArchitect) {
        setOwnerArchitectId(activeArchitect)
      } else {
        setOwnerArchitectId(ownerArchitectList[0]?.id)
      }
    }
  }, [activeArchitect, ownerArchitectList[0]?.id, isDeletingArchitect])

  useEffect(() => {
    let dynamicOption = {}
    if (projectOptionChoices) {
      dynamicOption = projectOptionChoices
    }
    const dynamicOptionForMapping = (ary) => {
      const res = ary?.map((sts) => ({
        value: `${sts?.id}`,
        label: sts?.uniqueCode,
      }))
      return res
    }
    const mapping = dynamicOptionForMapping(ownerContractorMapping)
    setProjectOptionChoicesData({
      ...dynamicOption,
      mapping,
    })
  }, [projectOptionChoices, ownerContractorMapping])

  useEffect(() => {
    if (ownerArchitectList?.length && slug) {
      const populateValue = ownerArchitectList?.find((project) =>
        equal(project?.id, +ownerArchitectId),
      )
      const populateFinal = {
        ...populateValue,
        mapping:
          isArray(populateValue?.mapping) && populateValue?.mapping?.length
            ? populateValue?.mapping?.map((mapEle) => `${mapEle}`)
            : equal(projectOptionChoicesData?.mapping?.length, 1)
            ? [projectOptionChoicesData?.mapping[0]?.value]
            : [],
      }
      setValues(populateFinal)
      setDummyData(populateFinal)
    }
  }, [
    ownerArchitectList,
    setValues,
    ownerArchitectId,
    isDeletingArchitect,
    projectOptionChoicesData,
  ])

  useEffect(() => {
    const currentArchitect = ownerArchitectList?.find((project) =>
      equal(project?.id, +ownerArchitectId),
    )
    if (currentArchitect) {
      if (currentArchitect?.uniqueCode || currentArchitect?.isNewArchitect) {
        dispatch(getOwnerContractorMapping(slug, currentArchitect?.uniqueCode))
      }
    }
  }, [dispatch, slug, ownerArchitectId, isDeletingArchitect])

  useEffect(() => {
    if (tempOAData?.id) {
      if (isDirty) {
        setIsSaveWarningOpen(true)
      } else {
        resetForm()
        setTimeout(() => {
          setOwnerArchitectId(tempOAData?.id)
        }, 500)
        setErrors({})
      }
    } else if (tempOAData?.add) {
      if (isDirty) {
        setIsSaveWarningOpen(true)
      } else {
        handleAddNewOwnerArchitect()
        resetForm()
      }
    }
  }, [tempOAData])

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          { isRequired, isDynamicOptions, defaultValue, options, ...formField },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <Grid key={index} item xs={formField.xs ?? 12}>
            {ternary(
              equal(name, 'architect') && equal(formField?.type, 'select'),
              <DKTSearchableSelect
                innerRef={ref}
                isLoading={isSearchingArchitect}
                options={[...searchResults] || []}
                onChange={(option) => {
                  const e = {
                    target: { name: 'architect', value: option?.value || '' },
                  }
                  handleChange(e)
                  setArchitect(option)
                }}
                isClearable
                value={architect || ''}
                components={{
                  MenuList: (props, ...args) =>
                    SelectMenuButton(
                      { ...props, setIsCompanyModalOpen },
                      ...args,
                    ),
                  Option: (args) => CustomOption(args),
                }}
                isDisabled={hasOnlyViewPermission || formField?.disabled}
                {...formField}
              />,
              ternary(
                formField.isCurrency,
                <DKTCurrencyTextField
                  label={formField.label}
                  variant="standard"
                  isRequired={isRequired}
                  error={errors[name]}
                  value={(values && values[name]) ?? ''}
                  currencySymbol="$"
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={(event, value) =>
                    handleChange({ target: { name, value } })
                  }
                  disabled={hasOnlyViewPermission || formField?.disabled}
                />,
                <FormControl
                  {...formField}
                  isRequired={isRequired}
                  options={projectOptionChoicesData[name] || options || []}
                  id={name}
                  name={name}
                  value={(values && values[name]) || defaultValue || ''}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  error={errors[name]}
                  disabled={hasOnlyViewPermission || formField.disabled}
                />,
              ),
            )}
          </Grid>
        )
      },
    )

  const renderFormFields = useCallback(
    (formFields, dynamicFormFields = false) => (
      <Grid container spacing={2}>
        {isArray(formFields)
          ? dynamicFormFields
            ? formFields?.map((formField) =>
                renderFormFieldsControl({
                  formFields: {
                    test: { type: 'checkbox', ...formField },
                  },
                }),
              )
            : formFields?.map((formField) =>
                mapFormFields({
                  formFields: formField?.data,
                  makeFieldNameUnique: true,
                  id: formField?.id,
                }),
              )
          : mapFormFields({ formFields })}
      </Grid>
    ),
    [
      projectOptionChoicesData,
      values,
      errors,
      isSearchingArchitect,
      architect,
      ownerArchitectId,
      searchResults,
    ],
  )

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setTempOAData({ id: null, add: false })
  }
  const confirmSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setOwnerArchitectId(tempOAData?.id)
    setIsDirty(false)
    setErrors({})
    if (tempOAData?.add) {
      handleAddNewOwnerArchitect()
      resetForm()
    }
    setTempOAData({ id: null, add: false })
  }

  const handleAddNewOwnerArchitect = () => {
    if (hasOnlyViewPermission) return
    if (ownerArchitectList?.length && slug) {
      const populateValue = ownerArchitectList?.find((project) =>
        equal(project?.id, +ownerArchitectId),
      )
      if (!populateValue?.isOcAvailable) {
        showToast(swapWarningOC)
        return
      }
    }
    const id = new Date().getTime()
    dispatch(addOwnerArchitect(id))
    setOwnerArchitectId(id)
    resetForm()
  }
  const handleClose = () => {
    setIsCompanyModalOpen(false)
    setOpenDeleteNotification(false)
  }

  const handleSave = () => {
    const isFormValid = handleSubmit({ flag: formFields })
    const filteredObj = { ...values }
    Object.keys(filteredObj).forEach((key) => {
      if (equal(filteredObj[key], undefined) || equal(filteredObj[key], '')) {
        delete filteredObj[key]
        filteredObj[key] = null
      }
    })
    if (
      equal(filteredObj?.mapping?.length, 0) ||
      equal(filteredObj?.mapping, null)
    ) {
      delete filteredObj.mapping
      filteredObj.isDeleted = true
    }
    const body = {
      ...filteredObj,
      project: slug,
    }
    if (isFormValid) {
      setIsDirty(false)
      dispatch(createAndUpdateOwnerArchitect(body, ownerArchitectId))
    } else {
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }

  const customFormControlFields = ({ name, formControl }) => ({
    ...ternary(
      equal(name, 'architecturalConsultants'),
      { fields: projectOptionChoices?.architecturalConsultants },
      {},
    ),
    disabled: hasOnlyViewPermission || formControl.disabled,
  })

  const handleDeleteArchitect = () => {
    const body = {
      ownerArchitect: [ownerArchitectId],
    }
    if (gt(ownerArchitectList?.length, 1)) {
      dispatch(deleteOwnerArchitect(body, handleClose))
    } else {
      showToast("You can't delete this record.", 'error')
      handleClose()
    }
  }
  const actionList = [
    { label: 'Delete', onClick: () => setOpenDeleteNotification(true) },
  ]

  const actions = () => (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Stack direction="row" gap={2} className={classes.rightButton}>
        {ternary(
          !hasOnlyViewPermission,
          <DKTButtonSelect
            options={actionList}
            disabled={
              hasOnlyViewPermission ||
              isCreateAndUpdateOwnerArchitectLoading ||
              isOwnerArchitectListLoading ||
              isSearchingArchitect ||
              true
            }
          />,
          null,
        )}
        <DKTButton
          className={classes.rightButton}
          onClick={handleSave}
          onMouseOver={handleBlurAll}
          disabled={
            hasOnlyViewPermission ||
            isCreateAndUpdateOwnerArchitectLoading ||
            isOwnerArchitectListLoading ||
            isSearchingArchitect
          }
        >
          {ternary(isCreateAndUpdateOwnerArchitectLoading, 'Saving...', 'Save')}
        </DKTButton>
      </Stack>
    </Box>
  )

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteArchitect}>
        {ternary(
          isDeletingArchitect,
          <DKTCircularProgress size={spinnerSize?.sm} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const activeOwnerArchitect = ownerArchitectList?.find((ownerArchitect) =>
    equal(ownerArchitect?.id, ownerArchitectId),
  )

  const architecturalConsultantsFormFields = renderFormFieldsControl({
    formFields: { architecturalConsultants },
    values,
    errors,
    handleChange,
    customFormControlFields,
  })

  return (
    <InnerLayout
      contentTitle="Owner Architect AGREEMENT"
      actions={actions}
      maxWidth="md"
      title={currentProject?.projectName || 'New Project'}
      isShowMenu
    >
      {equal(isOwnerArchitectListLoading, true) ||
      equal(isSearchingArchitect, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isOwnerArchitectListLoading, 'FAILED') ||
        equal(isSearchingArchitect, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Owner Architect Agreement
            data. Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <Grid container>
          <Grid item xs={12} xl={8}>
            <Grid
              container
              columnSpacing={10}
              columnGap={{ sm: 0, xl: 11 }}
              rowSpacing={3}
            >
              <Grid item lg={5} sm={6} sx={{ mt: 4 }}>
                <DKTForm autoComplete="off">
                  {renderFormFields(formFields.OwnerArchitectFields)}
                  <Grid container mb={1} mt={3}>
                    {architecturalConsultantsFormFields}
                  </Grid>
                </DKTForm>
              </Grid>
              <Grid item xs={11}>
                <Typography
                  variant="body2"
                  color="gray.extraDark"
                  sx={{ fontWeight: 'medium' }}
                >
                  Comments
                </Typography>
                <DKTTextArea
                  rows={6}
                  name="comments"
                  placeholder="Placeholder Text"
                  value={values?.comments || ''}
                  onChange={handleChange}
                  disabled={hasOnlyViewPermission}
                />
              </Grid>
              <CreateContact
                open={IsCompanyModalOpen}
                onClose={handleClose}
                onSave={() => dispatch(searchArchitect(null))}
              />
              <DKTDialog
                open={deleteNotificationOpen}
                handleClose={handleClose}
                title="&nbsp;"
                actions={deleteNotificationAction}
                maxWidth="xs"
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box className={classes.deleteNotificationContent}>
                      <Typography variant="h5">
                        {`Are you sure you want to delete ${activeOwnerArchitect?.uniqueCode}?`}
                      </Typography>
                      <Typography variant="body1">
                        You can&apos;t undo this action
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </DKTDialog>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isSaveWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
    </InnerLayout>
  )
}

export default OwnerArchitect
