import { SET_ON_SAVE_STATUS } from '../constants/confirmation'

const initialState = {
  navigateConfirm: { save: false, cancel: false, loader: false },
}

export const confirmationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ON_SAVE_STATUS:
      return {
        ...state,
        navigateConfirm: { ...state.onSaveStatus, ...action.payload },
      }
    default:
      return state
  }
}
