import { emailValidation } from '../Utils/regex'

export const formFields = {
  email: {
    type: 'text',
    label: 'Email Address',
    placeholder: 'Email Address',
    isRequired: true,
    showLabel: false,
    validate: [emailValidation],
  },
}
