import { Box, Grid, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  formFields,
  optionsFields,
} from '../../Description/budgetContingency.description'
import useForm from '../../Hooks/useForm'

import { InnerLayout } from '../../Layout/project'
import {
  addBudgetContingency,
  deleteBudgetContingency,
  getBudgetContingency,
  getBudgetContingencyList,
  getBudgetContingencyMapping,
  saveBudgetContingency,
  setBudgetContingencyId,
  resetValues,
} from '../../Redux/actions/budgetContingency'
import { getProjectChoices } from '../../Redux/actions/choices'
import DKTButton from '../../Shared/DKTButton'
import DKTDialog from '../../Shared/DKTDialog'
import {
  BOOLEAN,
  NUMBER,
  SA,
  spinnerSize,
  swapWarning,
  swapWarningOC,
} from '../../Utils/constant'
import {
  checkIncludes,
  entries,
  equal,
  gt,
  handleBlurAll,
  isArray,
  length,
  ternary,
} from '../../Utils/javascript'
import { renderFormFields } from '../../Utils/renderFormFields'
import { showToast } from '../../Utils/toastService'
import DKTButtonSelect from '../../Shared/DKTButtonSelect'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import DKTConfirmNavigateShowModal from '../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import DKTForm from '../../Shared/DKTForm'
import { updateOnSaveStatus } from '../../Redux/actions/confirmation'

const useStyles = makeStyles(() => ({
  addBtn: {
    marginLeft: 230,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  inlineLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 'bold',
      color: '#2D2D2D',
      fontSize: 14,
      marginBottom: 8,
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))

const allFormFields = entries(formFields).reduce((result, formField) => {
  const formFieldValue = formField[1]
  return {
    ...result,
    ...formFieldValue,
  }
})

const BudgetContingency = () => {
  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    resetForm,
    handleSubmit,
    setErrors,
    isDirty,
    setIsDirty,
    setDummyData,
  } = useForm(allFormFields)
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [isSaveWarningOpen, setIsSaveWarningOpen] = useState(false)
  const [tempBcData, setTempBcData] = useState({
    id: null,
    uniqueCode: null,
    isNewEnvironmental: false,
    add: false,
  })
  const [isSwapMappingNotificationOpen, setSwapMappingNotification] =
    useState(false)
  const [isHandleChange, setHandleChange] = useState(false)

  const dispatch = useDispatch()
  const { slug: projectId } = useParams()
  const classes = useStyles()
  const {
    budgetContingencyList,
    budgetContingency,
    budgetContingencyId,
    isNewBudgetContingency,
    mappings,
    isSavingBudgetContingency,
    isCreatingBudgetContingency,
    isDeletingBudgetContingency,
    isLoadingBudgetContingencyList,
    saveBudgetContingencyError,
    isLoadingBudgetContingency,
  } = useSelector(({ budgetContingency }) => budgetContingency)
  const { choices, isLoadingChoices } = useSelector(
    ({ projectChoices }) => projectChoices,
  )
  const { isProjectListLoading } = useSelector(({ projects }) => projects)

  const { systemAuthorization, projects, isSystemAuthorizationLoading } =
    useSelector(({ auth }) => auth)
  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+projectId]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission = isGlobalViewer || isProjectViewer

  // Fetch mappings and choices
  useEffect(() => {
    dispatch(getProjectChoices({ fieldName: optionsFields.join(',') }))
    dispatch(getBudgetContingencyList(projectId))
    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(resetValues())
    }
  }, [])

  useEffect(() => {
    if (!budgetContingencyId) return
    if (
      !equal(budgetContingency?.id, +budgetContingencyId) &&
      !isNewBudgetContingency
    )
      dispatch(getBudgetContingency(budgetContingencyId))
    if (budgetContingency)
      entries(budgetContingency).forEach(([fieldName, fieldValue]) => {
        if (fieldName === 'mapping') {
          setFieldValue(
            'mapping',
            fieldValue?.map((e) => `${e}`),
            'budgetContingency',
          )
        } else {
          setFieldValue(
            fieldName,
            checkIncludes(typeof fieldValue, [BOOLEAN, NUMBER])
              ? fieldValue.toString()
              : fieldValue,
            'BudgetContingency',
          )
        }
        setDummyData((prev) => ({
          ...prev,
          fieldName: checkIncludes(typeof fieldValue, [BOOLEAN, NUMBER])
            ? fieldValue.toString()
            : fieldValue,
        }))
      })
  }, [
    budgetContingency,
    dispatch,
    budgetContingencyId,
    setFieldValue,
    isNewBudgetContingency,
    resetForm,
  ])

  useEffect(() => {
    if (equal(mappings?.length, 1)) {
      setFieldValue('mapping', [`${mappings[0]?.value}`], 'BudgetContingency')
    }
  }, [mappings])

  useEffect(() => {
    if (isNewBudgetContingency) resetForm()
    if (!budgetContingencyId) {
      resetForm()

      if (
        budgetContingencyList &&
        isArray(budgetContingencyList) &&
        budgetContingencyList[0]
      )
        dispatch(setBudgetContingencyId(budgetContingencyList[0]?.id, false))
    }
  }, [budgetContingencyId, isNewBudgetContingency, budgetContingencyList])

  useEffect(() => {
    dispatch(setBudgetContingencyId(budgetContingencyList?.[0]?.id, false))
  }, [])

  useEffect(() => {
    if (
      !hasOnlyViewPermission &&
      !isLoadingBudgetContingencyList &&
      isArray(budgetContingencyList) &&
      equal(length(budgetContingencyList), 0)
    )
      dispatch(addBudgetContingency())
  }, [budgetContingencyList, isLoadingBudgetContingencyList, dispatch])

  useEffect(() => {
    if (budgetContingency || isNewBudgetContingency)
      dispatch(getBudgetContingencyMapping(projectId))
  }, [budgetContingency, isNewBudgetContingency, projectId, dispatch])

  // Set errors on save
  useEffect(() => {
    if (saveBudgetContingencyError)
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...saveBudgetContingencyError,
      }))
  }, [saveBudgetContingencyError, setErrors])

  useEffect(() => {
    setFieldValue(
      'hardCostContingencyPerScheduleOfValues',
      ternary(
        values.hardCostContingency && values.scheduleOfValues,
        (+values.hardCostContingency / +values.scheduleOfValues) * 100,
        0,
      ),
    )
  }, [setFieldValue, values.hardCostContingency, values.scheduleOfValues])

  useEffect(() => {
    setFieldValue(
      'totalContingency',
      parseFloat(
        (+values.hardCostContingency || 0) +
          (+values.contractorContingency || 0),
      )?.toFixed(2),
    )
  }, [setFieldValue, values.hardCostContingency, values.contractorContingency])

  useEffect(() => {
    setFieldValue(
      'contractorContingencyPerScheduleOfValues',
      ternary(
        values.contractorContingency && values.scheduleOfValues,
        (+values.contractorContingency / +values.scheduleOfValues) * 100,
        0,
      ),
    )
  }, [setFieldValue, values.contractorContingency, values.scheduleOfValues])

  useEffect(() => {
    setFieldValue(
      'totalContingencyPerScheduleOfValues',
      ternary(
        values.totalContingency && values.scheduleOfValues,
        (+values.totalContingency / +values.scheduleOfValues) * 100,
        0,
      ),
    )
  }, [setFieldValue, values.totalContingency, values.scheduleOfValues])

  useEffect(() => {
    setFieldValue(
      'softCostContingencyPerScheduleOfValues',
      ternary(
        values.softCostContingency && values.scheduleOfValues,
        (+values.softCostContingency / +values.scheduleOfValues) * 100,
        0,
      ),
    )
  }, [setFieldValue, values.softCostContingency, values.scheduleOfValues])

  useEffect(() => {
    if (
      isLoadingBudgetContingencyList ||
      isLoadingBudgetContingency ||
      isLoadingChoices ||
      isSystemAuthorizationLoading ||
      isProjectListLoading
    ) {
      setIsDirty(false)
    }
  }, [
    isLoadingBudgetContingencyList,
    isLoadingBudgetContingency,
    isLoadingChoices,
    isSystemAuthorizationLoading,
    isProjectListLoading,
  ])

  useEffect(() => {
    if (tempBcData?.id) {
      if (isDirty && isHandleChange) {
        setIsSaveWarningOpen(true)
      } else {
        resetForm()
        setTimeout(() => {
          dispatch(
            setBudgetContingencyId(
              tempBcData?.id,
              tempBcData?.isNewBudgetContingency,
            ),
          )
        }, 500)
        setErrors({})
      }
    } else if (tempBcData?.add) {
      if (isDirty && isHandleChange) {
        setIsSaveWarningOpen(true)
      } else {
        handleAddBudgetContingency()
        resetForm()
      }
    }
  }, [tempBcData])
  const handleSave = () => {
    const isFormValid = handleSubmit()
    setIsDirty(false)
    setHandleChange(false)
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
    } else {
      if (
        checkIncludes(values?.mapping, activeBudgetContingency?.usedMapping)
      ) {
        setSwapMappingNotification(true)
        return
      }
      const filteredObj = { ...values }
      Object.keys(filteredObj).forEach((key) => {
        if (equal(filteredObj[key], undefined) || equal(filteredObj[key], '')) {
          delete filteredObj[key]
          filteredObj[key] = null
        }
      })
      dispatch(
        saveBudgetContingency(filteredObj, budgetContingencyId, projectId),
      )
    }
  }

  const handleConfirmMapping = () => {
    const isFormValid = handleSubmit()
    if (!isFormValid) return
    const filteredObj = { ...values }
    Object.keys(filteredObj).forEach((key) => {
      if (equal(filteredObj[key], undefined) || equal(filteredObj[key], '')) {
        delete filteredObj[key]
        filteredObj[key] = null
      }
    })
    dispatch(saveBudgetContingency(filteredObj, budgetContingencyId, projectId))
    setSwapMappingNotification(false)
  }
  const closeDeleteNotification = () => setIsDeleteNotificationOpen(false)
  const closeSwapMappingNotification = () => setSwapMappingNotification(false)

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    setTempBcData({
      id: null,
      uniqueCode: null,
      isNewEnvironmental: false,
      add: false,
    })
  }
  const confirmSaveWarningModal = () => {
    setIsSaveWarningOpen(false)
    dispatch(
      setBudgetContingencyId(
        tempBcData?.id,
        tempBcData?.isNewBudgetContingency,
      ),
    )
    setIsDirty(false)
    setHandleChange(false)
    setErrors({})
    if (tempBcData?.add) {
      handleAddBudgetContingency()
      resetForm()
    }

    setTempBcData({
      id: null,
      uniqueCode: null,
      isNewEnvironmental: false,
      add: false,
    })
  }
  const handleAddBudgetContingency = () => {
    if (!budgetContingency?.isOcAvailable) {
      showToast(swapWarningOC)
      return
    }
    dispatch(addBudgetContingency())
  }

  const actions = () => (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Stack direction="row" gap={2} className={classes.rightButton}>
        <DKTButtonSelect
          options={actionList}
          disabled={isLoadingBudgetContingencyList || true}
        />{' '}
        <DKTButton
          className={classes.rightButton}
          onClick={handleSave}
          onMouseOver={handleBlurAll}
          disabled={
            hasOnlyViewPermission ||
            isSavingBudgetContingency ||
            isCreatingBudgetContingency ||
            isLoadingBudgetContingencyList
          }
        >
          {ternary(
            isSavingBudgetContingency || isCreatingBudgetContingency,
            'Saving...',
            'Save',
          )}
        </DKTButton>
      </Stack>
    </Box>
  )

  const handleDeleteSiteBuilding = () => {
    const body = {
      budgetAndContingency: [budgetContingencyId],
    }
    if (gt(budgetContingencyList?.length, 1)) {
      dispatch(deleteBudgetContingency(body, closeDeleteNotification))
    } else {
      showToast("You can't delete this record.", 'error')
      closeDeleteNotification()
    }
  }

  const deleteNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteNotification}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteSiteBuilding}>
        {ternary(
          isDeletingBudgetContingency,
          <DKTCircularProgress size={spinnerSize?.sm} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  useEffect(() => {
    setFieldValue(
      'hardCostContingencyPerScheduleOfValues',
      ternary(
        values.hardCostContingency && values.scheduleOfValues,
        (+values.hardCostContingency / +values.scheduleOfValues) * 100,
        0,
      ),
      'BudgetContingency',
    )
  }, [setFieldValue, values.hardCostContingency, values.scheduleOfValues])

  useEffect(() => {
    setFieldValue(
      'totalContingency',
      (+values.hardCostContingency || 0) + (+values.contractorContingency || 0),
      'BudgetContingency',
    )
  }, [setFieldValue, values.hardCostContingency, values.contractorContingency])

  useEffect(() => {
    setFieldValue(
      'contractorContingencyPerScheduleOfValues',
      ternary(
        values.contractorContingency && values.scheduleOfValues,
        (+values.contractorContingency / +values.scheduleOfValues) * 100,
        0,
      ),
      'BudgetContingency',
    )
  }, [setFieldValue, values.contractorContingency, values.scheduleOfValues])

  useEffect(() => {
    setFieldValue(
      'totalContingencyPerScheduleOfValues',
      ternary(
        values.totalContingency && values.scheduleOfValues,
        (+values.totalContingency / +values.scheduleOfValues) * 100,
        0,
      ),
      'BudgetContingency',
    )
  }, [setFieldValue, values.totalContingency, values.scheduleOfValues])

  useEffect(() => {
    setFieldValue(
      'softCostContingencyPerScheduleOfValues',
      ternary(
        values.softCostContingency && values.scheduleOfValues,
        (+values.softCostContingency / +values.scheduleOfValues) * 100,
        0,
      ),
      'BudgetContingency',
    )
  }, [setFieldValue, values.softCostContingency, values.scheduleOfValues])

  const customFormControlFields = ({ name, formControl }) => ({
    options: ternary(
      equal(name, 'mapping'),
      mappings,
      optionsFields?.includes(name) ? choices?.[name] : formControl.options,
    ),
    disabled: hasOnlyViewPermission || formControl.disabled,
  })

  const handleFieldChange = (...rest) => {
    handleChange(...rest)
    setHandleChange(true)
  }

  const renderFormFieldsWithOptions = useCallback(
    (formFieldsToRender) =>
      renderFormFields({
        errors,
        formFields: formFieldsToRender,
        values,
        handleChange: handleFieldChange,
        customFormControlFields,
      }),
    [errors, values, handleFieldChange, customFormControlFields],
  )

  const swapMappingNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeSwapMappingNotification}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleConfirmMapping}>
        Yes
      </DKTButton>
    </>
  )

  const actionList = [
    { label: 'Delete', onClick: () => setIsDeleteNotificationOpen(true) },
  ]

  const activeBudgetContingency = budgetContingencyList?.find(
    (budgetContingency) => equal(budgetContingency?.id, budgetContingencyId),
  )

  const hardCostContingencyFormFields = renderFormFieldsWithOptions(
    formFields.hardCostContingency,
  )
  const commentsFormFields = renderFormFieldsWithOptions(formFields.comments)
  const scheduleOfValuesFormFields = renderFormFieldsWithOptions(
    formFields.scheduleOfValues,
  )

  return (
    <InnerLayout
      contentTitle="Budget & Contingency"
      actions={actions}
      maxWidth="md"
      isShowMenu
    >
      {equal(isLoadingBudgetContingencyList, true) ||
      equal(isLoadingBudgetContingency, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isLoadingBudgetContingencyList, 'FAILED') ||
        equal(isLoadingBudgetContingency, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Budget & Contingency data.
            Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} xl={8}>
            <Grid
              container
              columnSpacing={10}
              columnGap={{ sm: 0, xl: 11 }}
              rowSpacing={3}
            >
              <Grid item lg={5} sm={6}>
                <DKTForm autoComplete="off">
                  <Grid container spacing={2}>
                    {scheduleOfValuesFormFields}
                  </Grid>
                </DKTForm>
              </Grid>
              <Grid item lg={5} sm={6}>
                <DKTForm autoComplete="off">
                  <Grid container spacing={2}>
                    {hardCostContingencyFormFields}
                  </Grid>
                </DKTForm>
              </Grid>
              <Grid item xs={11}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      color="gray.extraDark"
                      sx={{ fontWeight: 'medium' }}
                    >
                      Comments
                    </Typography>
                  </Grid>
                  {commentsFormFields}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={closeDeleteNotification}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                {`Are you sure you want to delete ${activeBudgetContingency?.uniqueCode}?`}
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isSaveWarningOpen && isHandleChange}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt
        isDirty={isDirty && isHandleChange}
        onSave={handleSave}
      />
      <DKTDialog
        open={isSwapMappingNotificationOpen}
        handleClose={closeSwapMappingNotification}
        title="&nbsp;"
        actions={swapMappingNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">{swapWarning}</Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
    </InnerLayout>
  )
}

export default BudgetContingency
