// Fetch third party reports list
export const FETCH_THIRD_PARTY_REPORTS_LIST_REQUESTED =
  'FETCH_THIRD_PARTY_REPORTS_LIST_REQUESTED'
export const FETCH_THIRD_PARTY_REPORTS_LIST_SUCCESS =
  'FETCH_THIRD_PARTY_REPORTS_LIST_SUCCESS'
export const FETCH_THIRD_PARTY_REPORTS_LIST_FAILED =
  'FETCH_THIRD_PARTY_REPORTS_LIST_FAILED'

// Create third party reports
export const CREATE_THIRD_PARTY_REPORTS_REQUESTED =
  'CREATE_THIRD_PARTY_REPORTS_REQUESTED'
export const CREATE_THIRD_PARTY_REPORTS_SUCCESS =
  'CREATE_THIRD_PARTY_REPORTS_SUCCESS'
export const CREATE_THIRD_PARTY_REPORTS_FAILED =
  'CREATE_THIRD_PARTY_REPORTS_FAILED'

// Update third party reports
export const UPDATE_THIRD_PARTY_REPORTS_REQUESTED =
  'UPDATE_THIRD_PARTY_REPORTS_REQUESTED'
export const UPDATE_THIRD_PARTY_REPORTS_SUCCESS =
  'UPDATE_THIRD_PARTY_REPORTS_SUCCESS'
export const UPDATE_THIRD_PARTY_REPORTS_FAILED =
  'UPDATE_THIRD_PARTY_REPORTS_FAILED'

// Delete third party reports
export const DELETE_THIRD_PARTY_REPORTS_REQUESTED =
  'DELETE_THIRD_PARTY_REPORTS_REQUESTED'
export const DELETE_THIRD_PARTY_REPORTS_SUCCESS =
  'DELETE_THIRD_PARTY_REPORTS_SUCCESS'
export const DELETE_THIRD_PARTY_REPORTS_FAILED =
  'DELETE_THIRD_PARTY_REPORTS_FAILED'

/** *******************************
  Third party report section
******************************** */
// Fetch third party reports section list
export const FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_REQUESTED =
  'FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_REQUESTED'
export const FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_SUCCESS =
  'FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_SUCCESS'
export const FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_FAILED =
  'FETCH_THIRD_PARTY_REPORTS_SECTION_LIST_FAILED'

// Create third party reports section
export const CREATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED =
  'CREATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED'
export const CREATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS =
  'CREATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS'
export const CREATE_THIRD_PARTY_REPORTS_SECTION_FAILED =
  'CREATE_THIRD_PARTY_REPORTS_SECTION_FAILED'

/** *******************************
  Import third party reports
******************************** */
// Import third party reports
export const IMPORT_THIRD_PARTY_REPORT_REQUESTED =
  'IMPORT_THIRD_PARTY_REPORT_REQUESTED'
export const IMPORT_THIRD_PARTY_REPORT_SUCCESS =
  'IMPORT_THIRD_PARTY_REPORT_SUCCESS'
export const IMPORT_THIRD_PARTY_REPORT_FAILED =
  'IMPORT_THIRD_PARTY_REPORT_FAILED'

// update third party reports section
export const UPDATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED =
  'UPDATE_THIRD_PARTY_REPORTS_SECTION_REQUESTED'
export const UPDATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS =
  'UPDATE_THIRD_PARTY_REPORTS_SECTION_SUCCESS'
export const UPDATE_THIRD_PARTY_REPORTS_SECTION_FAILED =
  'UPDATE_THIRD_PARTY_REPORTS_SECTION_FAILED'
export const CLEAR_THIRD_PARTY_REPORT_LIST = 'CLEAR_THIRD_PARTY_REPORT_LIST'
