import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  reportTierTable: {
    '& table': {
      '& td:first-child': {
        '& button': {
          visibility: 'hidden',
        },
      },
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
          fontWeight: 700,
          padding: '7px 16px',
        },
      },
    },
  },
  expandableRow: {
    '& .MuiTableCell-root': {
      height: 44,
      borderBottom: 0,
    },
  },
}))

export const useExpandedRowStyles = makeStyles(() => ({
  hideOnPrint: {
    '@media print': {
      '&': {
        display: 'none',
      },
    },
  },
}))
