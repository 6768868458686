import React, { useEffect, useState } from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import Marker from './Marker'
import { mapKey } from '../../Utils/constant'

const Index = ({ data, height }) => {
  const [map, setMap] = useState(null)

  const defaultState = {
    center: { lat: 37.0902, lng: -95.7129 },
    zoom: 4,
  }

  const onMapLoad = (map) => {
    setMap(map)
  }

  const extendBoundsWithLocation = (bounds, location) => {
    bounds.extend(
      new window.google.maps.LatLng(location.latitude, location.longitude),
    )
  }

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds()
      if (data?.length) {
        data?.forEach((location) => {
          if (location?.project) {
            location?.project?.forEach(({ latitude, longitude }) => {
              extendBoundsWithLocation(bounds, { latitude, longitude })
            })
          } else {
            extendBoundsWithLocation(bounds, {
              latitude: location?.latitude,
              longitude: location?.longitude,
            })
          }
        })
        map.fitBounds(bounds)
      }
    }
  }, [map, data])

  const renderMarker = ({
    latitude,
    longitude,
    key,
    id,
    projectName,
    status,
    ...rest
  }) => (
    <Marker
      position={{
        lat: Number(latitude),
        lng: Number(longitude),
      }}
      id={id}
      projectName={projectName || ''}
      status={status || 'Active'}
      key={`project-marker-${key}`}
      {...rest}
    />
  )

  return (
    <LoadScript googleMapsApiKey={mapKey} libraries={['places']}>
      <GoogleMap
        center={defaultState.center}
        zoom={defaultState.zoom}
        onLoad={onMapLoad}
        cursor="Arrow"
        mapContainerStyle={{ height, width: '100%' }}
      >
        {data?.map(
          ({ latitude, status, longitude, id, projectName, ...rest }) =>
            rest?.project
              ? rest?.project?.map(({ latitude, longitude }, index) =>
                  renderMarker({
                    latitude,
                    longitude,
                    status: 'Active',
                    key: index,
                    showTooltip: false,
                  }),
                )
              : renderMarker({
                  latitude,
                  longitude,
                  key: id,
                  id,
                  projectName,
                  status,
                }),
        )}
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(Index)
