import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'

import ActionsBar from '../admin/ActionsBar'
import Navbar from '../admin/Navbar'
import PageTitleBar from '../admin/PageTitleBar'

export const OuterLayout = ({ children, title }) => (
  <Stack direction="column" minHeight="100vh">
    <Box>
      <Navbar />
    </Box>
    <PageTitleBar title={title} />
    {children}
  </Stack>
)

export const InnerLayout = ({
  actionTitle,
  contentTitle,
  actionSx = {},
  actions: Actions = () => null,
  maxWidth = '100%',
  children,
  className,
}) => (
  <>
    <ActionsBar sx={actionSx} actionTitle={actionTitle}>
      <Actions />
    </ActionsBar>
    <Stack
      direction="row"
      flex={1}
      sx={{ maxHeight: '100%', overflow: 'hidden' }}
    >
      <Box px={5} py={4} width="100%" sx={{ overflowY: 'auto' }}>
        <Container
          width="100%"
          maxWidth={maxWidth}
          sx={{ m: 0, p: 0, position: 'relative' }}
        >
          <Typography
            component="h6"
            fontSize={14}
            fontWeight={700}
            textAlign="center"
            textTransform="uppercase"
            mb={4}
            className={className}
          >
            {contentTitle}
          </Typography>
          {children}
        </Container>
      </Box>
    </Stack>
  </>
)
