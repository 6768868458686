import {
  initialReportTierSettings,
  initialReportTierSubSection,
} from '../../Description/reportTierSettings.description'
import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import {
  checkIncludes,
  checkUndefined,
  equal,
  isEmptyString,
  ternary,
} from '../../Utils/javascript'
import { loadStateFn } from '../../Utils/localStorage'
import {
  CREATE_CONSTRUCTION_MONITORING_REPORT_FAILED,
  CREATE_CONSTRUCTION_MONITORING_REPORT_REQUESTED,
  CREATE_CONSTRUCTION_MONITORING_REPORT_SUCCESS,
  FETCH_CONSTRUCTION_MONITORING_REPORT_FAILED,
  FETCH_CONSTRUCTION_MONITORING_REPORT_REQUESTED,
  FETCH_CONSTRUCTION_MONITORING_REPORT_SUCCESS,
  FETCH_CONSTRUCTION_SUMMARY_FORMAT_FAILED,
  FETCH_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED,
  FETCH_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS,
  FETCH_ES_FAILED,
  FETCH_ES_REQUESTED,
  FETCH_ES_SUCCESS,
  FETCH_LIMITATIONS_FAILED,
  FETCH_LIMITATIONS_REQUESTED,
  FETCH_LIMITATIONS_SUCCESS,
  FETCH_REPORT_TIER_SETTINGS_FAILED,
  FETCH_REPORT_TIER_SETTINGS_REQUESTED,
  FETCH_REPORT_TIER_SETTINGS_SUCCESS,
  HANDLE_REPORT_TIER_SETTINGS_CHANGE,
  SAVE_CONSTRUCTION_SUMMARY_FORMAT_FAILED,
  SAVE_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED,
  SAVE_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS,
  SAVE_ES_FAILED,
  SAVE_ES_REQUESTED,
  SAVE_ES_SUCCESS,
  SAVE_LIMITATIONS_FAILED,
  SAVE_LIMITATIONS_REQUESTED,
  SAVE_LIMITATIONS_SUCCESS,
  SAVE_REPORT_TIER_SETTINGS_FAILED,
  SAVE_REPORT_TIER_SETTINGS_REQUESTED,
  SAVE_REPORT_TIER_SETTINGS_SUCCESS,
} from '../constants/constructionMonitoringReport'
import { updateSaveStatusAction } from './confirmation'

const createCMRRequested = () => ({
  type: CREATE_CONSTRUCTION_MONITORING_REPORT_REQUESTED,
})
const createCMRFailed = (payload) => ({
  type: CREATE_CONSTRUCTION_MONITORING_REPORT_FAILED,
  payload,
})
const createCMRSuccess = (payload) => ({
  type: CREATE_CONSTRUCTION_MONITORING_REPORT_SUCCESS,
  payload,
})
export const createConstructionMonitoringReport = () => async (dispatch) => {
  const CONSTRUCTION_MONITORING_REPORT = 1
  dispatch(createCMRRequested())
  const { data, error } = await api({
    data: {
      companyInformation: +loadStateFn('company-id'),
      reportType: CONSTRUCTION_MONITORING_REPORT,
    },
    method: method.post,
    endPoint: apiEndPoints.report,
  })
  if (data) {
    dispatch(createCMRSuccess(data))
    return
  }
  if (error) {
    dispatch(createCMRFailed(error.response.data))
  }
}

const fetchCMRRequested = () => ({
  type: FETCH_CONSTRUCTION_MONITORING_REPORT_REQUESTED,
})
const fetchCMRFailed = (payload) => ({
  type: FETCH_CONSTRUCTION_MONITORING_REPORT_FAILED,
  payload,
})
const fetchCMRSuccess = (payload) => ({
  type: FETCH_CONSTRUCTION_MONITORING_REPORT_SUCCESS,
  payload,
})
export const fetchCMR = (payload) => async (dispatch) => {
  const CMR_TYPE_ID = 1
  dispatch(fetchCMRRequested())
  const { data, error } = await api({
    data: payload,
    method: method.get,
    endPoint: `${apiEndPoints.report}${CMR_TYPE_ID}`,
  })
  if (data) {
    dispatch(fetchCMRSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchCMRFailed(error.response.data))
    if (equal(error.response?.status, 404))
      dispatch(createConstructionMonitoringReport())
  }
}

const fetchLimitationsRequested = () => ({
  type: FETCH_LIMITATIONS_REQUESTED,
})
const fetchLimitationsFailed = (payload) => ({
  type: FETCH_LIMITATIONS_FAILED,
  payload,
})
const fetchLimitationsSuccess = (payload) => ({
  type: FETCH_LIMITATIONS_SUCCESS,
  payload,
})
export const fetchLimitations = (payload) => async (dispatch) => {
  dispatch(fetchLimitationsRequested())
  const { data, error } = await api({
    data: payload,
    method: method.get,
    endPoint: apiEndPoints.limitations,
  })
  if (data) {
    dispatch(fetchLimitationsSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchLimitationsFailed(error.response.data))
  }
}

const fetchReportTierSettingsRequested = () => ({
  type: FETCH_REPORT_TIER_SETTINGS_REQUESTED,
})
const fetchReportTierSettingsFailed = (payload) => ({
  type: FETCH_REPORT_TIER_SETTINGS_FAILED,
  payload,
})
const fetchReportTierSettingsSuccess = (payload) => ({
  type: FETCH_REPORT_TIER_SETTINGS_SUCCESS,
  payload,
})
export const fetchReportTierSettings = (payload) => async (dispatch) => {
  dispatch(fetchReportTierSettingsRequested())
  const { data, error } = await api({
    data: payload,
    method: method.get,
    endPoint: apiEndPoints.reportTierSettings,
  })
  const reportTierSetting = data?.reportTierSetting?.map((setting) => ({
    ...setting,
    subSections: setting.subSections?.map((subSection) => ({
      ...initialReportTierSubSection,
      ...subSection,
    })),
  }))
  const cloneReportTierSettings = reportTierSetting && [...reportTierSetting]
  const cloneData = []
  cloneReportTierSettings?.forEach((item) => {
    if (
      equal(item?.reportSection, 'Project Information') &&
      item?.subSections
    ) {
      let obj = {}
      item?.subSections?.forEach((value) => {
        if (equal(value?.reportSection, 'Project Summary')) {
          obj = {
            disabledFields: ['secondTier', 'thirdTier'],
            firstTier: true,
            includeInAppendix: null,
            subSections: value?.subSections,
            reportSection: 'Project Summary',
            secondTier: true,
            thirdTier: true,
          }
        } else {
          obj?.subSections?.push(value)
        }
      })
      cloneData?.push(obj)
    } else {
      let cloneItem = {
        ...item,
      }
      if (
        equal(item?.reportSection, 'Executive Summary') ||
        equal(item?.reportSection, 'Budget Summary')
      ) {
        cloneItem = {
          disabledFields: ['secondTier', 'thirdTier'],
          firstTier: true,
          includeInAppendix: null,
          reportSection: item?.reportSection,
          secondTier: true,
          thirdTier: true,
          subSections: undefined,
        }
      } else if (equal(item?.reportSection, 'Project Summary')) {
        if (!isEmptyString(item?.subSections)) {
          cloneItem = {
            disabledFields: ['secondTier', 'thirdTier'],
            firstTier: true,
            includeInAppendix: null,
            subSections: item?.subSections,
            reportSection: 'Project Summary',
            secondTier: true,
            thirdTier: true,
          }
        } else {
          cloneItem = {
            disabledFields: ['secondTier', 'thirdTier'],
            firstTier: true,
            includeInAppendix: null,
            subSections: initialReportTierSettings?.[0]?.subSections?.map(
              (section) => section,
            ),
            reportSection: 'Project Summary',
            secondTier: true,
            thirdTier: true,
          }
        }
      } else {
        cloneItem = {
          ...cloneItem,
          subSections: undefined,
        }
      }
      cloneData?.push(cloneItem)
    }
  })
  if (data) {
    dispatch(fetchReportTierSettingsSuccess(cloneData))
    return
  }
  if (error) {
    dispatch(fetchReportTierSettingsFailed(error.response.data))
  }
}

export const fetchConstructionSummaryFormatRequested = () => ({
  type: FETCH_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED,
})
const fetchConstructionSummaryFormatFailed = (payload) => ({
  type: FETCH_CONSTRUCTION_SUMMARY_FORMAT_FAILED,
  payload,
})
const fetchConstructionSummaryFormatSuccess = (payload) => ({
  type: FETCH_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS,
  payload,
})
export const fetchConstructionSummaryFormat = (payload) => async (dispatch) => {
  dispatch(fetchConstructionSummaryFormatRequested())
  const { data, error } = await api({
    data: payload,
    method: method.get,
    endPoint: apiEndPoints.constructionSummaryFormat,
  })
  if (data) {
    dispatch(
      fetchConstructionSummaryFormatSuccess(data?.constructionSummaryFormat),
    )
    return
  }
  if (error) {
    dispatch(fetchConstructionSummaryFormatFailed(error.response))
  }
}

const saveLimitationsRequested = () => ({
  type: SAVE_LIMITATIONS_REQUESTED,
})
const saveLimitationsFailed = (payload) => ({
  type: SAVE_LIMITATIONS_FAILED,
  payload,
})
const saveLimitationsSuccess = (payload) => ({
  type: SAVE_LIMITATIONS_SUCCESS,
  payload,
})
export const saveLimitations = (payload) => async (dispatch, getState) => {
  const { constructionMonitoringReport, limitations } =
    getState().constructionMonitoringReport
  if (!constructionMonitoringReport.id) return
  dispatch(saveLimitationsRequested())
  const { data, error } = await api({
    data: {
      ...payload,
      companyReportSetting: constructionMonitoringReport.id,
    },
    method: ternary(limitations.limitation, method.patch, method.post),
    endPoint: apiEndPoints.limitations,
    showToastMessage: true,
    toastMessage: 'Saved successfully!',
  })
  setTimeout(() => {
    dispatch(updateSaveStatusAction(data, error))
  }, 700)
  if (data) {
    dispatch(saveLimitationsSuccess(data))
    return
  }
  if (error) {
    dispatch(saveLimitationsFailed(error.response.data))
  }
}

const saveReportTierSettingsRequested = () => ({
  type: SAVE_REPORT_TIER_SETTINGS_REQUESTED,
})
const saveReportTierSettingsFailed = (payload) => ({
  type: SAVE_REPORT_TIER_SETTINGS_FAILED,
  payload,
})
const saveReportTierSettingsSuccess = (payload) => ({
  type: SAVE_REPORT_TIER_SETTINGS_SUCCESS,
  payload,
})
export const saveReportTierSettings = () => async (dispatch, getState) => {
  const {
    reportTierSettings: reportTierSetting,
    constructionMonitoringReport,
    isSavedReportTierSettings,
  } = getState().constructionMonitoringReport
  if (!constructionMonitoringReport.id) return
  dispatch(saveReportTierSettingsRequested())
  const { data, error } = await api({
    data: {
      reportTierSetting,
      companyReportSetting: constructionMonitoringReport.id,
    },
    method: ternary(isSavedReportTierSettings, method.patch, method.post),
    endPoint: apiEndPoints.reportTierSettings,
    showToastMessage: true,
    toastMessage: 'Saved successfully!',
  })
  setTimeout(() => {
    dispatch(updateSaveStatusAction(data, error))
  }, 700)
  if (data) {
    dispatch(saveReportTierSettingsSuccess(data))
    return
  }
  if (error) {
    dispatch(saveReportTierSettingsFailed(error.response.data))
  }
}

const saveConstructionSummaryFormatRequested = () => ({
  type: SAVE_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED,
})
const saveConstructionSummaryFormatFailed = (payload) => ({
  type: SAVE_CONSTRUCTION_SUMMARY_FORMAT_FAILED,
  payload,
})
const saveConstructionSummaryFormatSuccess = (payload) => ({
  type: SAVE_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS,
  payload,
})
export const saveConstructionSummaryFormat =
  (payload) => async (dispatch, getState) => {
    const { constructionMonitoringReport, constructionSummaryFormat } =
      getState().constructionMonitoringReport
    if (!constructionMonitoringReport.id) return
    dispatch(saveConstructionSummaryFormatRequested())
    const { data, error } = await api({
      data: {
        companyReportSetting: constructionMonitoringReport.id,
        constructionSummaryFormat: payload,
      },
      method: ternary(constructionSummaryFormat, method.patch, method.post),
      endPoint: apiEndPoints.constructionSummaryFormat,
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(
        saveConstructionSummaryFormatSuccess(data?.constructionSummaryFormat),
      )
      return
    }
    if (error) {
      dispatch(saveConstructionSummaryFormatFailed(error.response.data))
    }
  }

const reportTierSettingsChange = (payload) => ({
  type: HANDLE_REPORT_TIER_SETTINGS_CHANGE,
  payload,
})

export const handleReportTierSettingsChange =
  ({ columnName, columnValue, reportSection, isSubSection }) =>
  (dispatch, getState) => {
    const { reportTierSettings } = getState().constructionMonitoringReport
    const updatedSettings = reportTierSettings.map((setting) =>
      ternary(
        !isSubSection && equal(setting.reportSection, reportSection),
        {
          ...setting,
          [columnName]: columnValue,
          subSections: setting.subSections?.map((subSection) => ({
            ...subSection,
            ...ternary(
              !checkIncludes(columnName, subSection.disabledFields) &&
                !checkUndefined(subSection[columnName]),
              { [columnName]: columnValue },
              {},
            ),
          })),
        },
        ternary(
          isSubSection,
          {
            ...setting,
            subSections: setting.subSections?.map((subSectionSetting) =>
              ternary(
                equal(subSectionSetting.reportSection, reportSection),
                {
                  ...subSectionSetting,
                  [columnName]: columnValue,
                },
                subSectionSetting,
              ),
            ),
          },
          setting,
        ),
      ),
    )
    dispatch(reportTierSettingsChange(updatedSettings))
  }

const fetchESRequested = () => ({
  type: FETCH_ES_REQUESTED,
})
const fetchESSuccess = (payload) => ({
  type: FETCH_ES_SUCCESS,
  payload,
})
const fetchESFailed = (payload) => ({
  type: FETCH_ES_FAILED,
  payload,
})
export const fetchExecutiveSummary = (payload) => async (dispatch) => {
  dispatch(fetchESRequested())
  const { data, error } = await api({
    data: payload,
    method: method.get,
    endPoint: apiEndPoints.companyES,
  })
  if (data) {
    dispatch(fetchESSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchESFailed(error.response))
  }
}

const saveESRequested = () => ({
  type: SAVE_ES_REQUESTED,
})
const saveESSuccess = (payload) => ({
  type: SAVE_ES_SUCCESS,
  payload,
})
const saveESFailed = (payload) => ({
  type: SAVE_ES_FAILED,
  payload,
})

export const saveExecutiveSummary =
  ({ executiveSummary, onSuccess }) =>
  async (dispatch, getState) => {
    const { constructionMonitoringReport } =
      getState().constructionMonitoringReport
    if (!constructionMonitoringReport.id) return
    dispatch(saveESRequested())
    const { data, error } = await api({
      data: {
        executiveSummary,
        companyReportSetting: constructionMonitoringReport.id,
      },
      method: method.patch,
      endPoint: apiEndPoints.companyES,
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(saveESSuccess(data))
      onSuccess()
      return
    }
    if (error) {
      dispatch(saveESFailed(error.response))
    }
  }
