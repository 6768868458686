import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import React from 'react'

const DKTRadioGroup = ({
  name,
  label,
  value,
  fields,
  radioProps,
  row = false,
  handleChange,
  showLabel = true,
  id,
  isRequired,
  labelSX = {},
  defaultValue,
  className,
  ...rest
}) => (
  <FormControl>
    {showLabel && label && (
      <InputLabel
        shrink
        htmlFor={id}
        required={isRequired}
        disabled={rest.disabled}
        sx={{ fontWeight: 'medium', color: 'gray.extraDark', ...labelSX }}
      >
        {label}
      </InputLabel>
    )}
    <RadioGroup
      name={name}
      value={value || defaultValue || null}
      onChange={handleChange}
      row={row}
      {...rest}
    >
      {fields.map((option, index) => (
        <FormControlLabel
          key={index}
          value={option?.value || defaultValue || ''}
          control={<Radio {...radioProps} />}
          color="secondary"
          label={option?.label || ''}
          disabled={rest?.disabled}
          className={className}
        />
      ))}
    </RadioGroup>
  </FormControl>
)

export default DKTRadioGroup
