import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, lt, ternary } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  ADD_CONSTRUCTION_SCHEDULE,
  CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_FAILED,
  CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_REQUESTED,
  CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_SUCCESS,
  FETCH_OWNER_CONTRACTOR_MAPPING_FAILED,
  FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED,
  FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS,
  FETCH_CONSTRUCTION_SCHEDULE_REQUESTED,
  FETCH_CONSTRUCTION_SCHEDULE_SUCCESS,
  FETCH_CONSTRUCTION_SCHEDULE_FAILED,
  DELETE_CONSTRUCTION_SCHEDULE_FAILED,
  DELETE_CONSTRUCTION_SCHEDULE_SUCCESS,
  DELETE_CONSTRUCTION_SCHEDULE_REQUESTED,
} from '../constants/constructionSchedule'
import { updateSaveStatusAction } from './confirmation'

let timer = null
const UNIQUE_CODE_PREFIX = 'SC'
const getUniqueCode = (code) =>
  `${UNIQUE_CODE_PREFIX}-${ternary(lt(code, 10), `0${code}`, code)}`

// Fetch construction schedule list
const fetchConstructionScheduleRequested = () => ({
  type: FETCH_CONSTRUCTION_SCHEDULE_REQUESTED,
})
export const fetchConstructionScheduleSuccess = (payload) => ({
  type: FETCH_CONSTRUCTION_SCHEDULE_SUCCESS,
  payload,
})
const fetchConstructionScheduleFailed = (payload) => ({
  type: FETCH_CONSTRUCTION_SCHEDULE_FAILED,
  payload,
})

export const getConstructionSchedule = (projectId) => async (dispatch) => {
  dispatch(fetchConstructionScheduleRequested())
  const { data, error } = await api({
    endPoint: `${apiEndPoints.constructionSchedule}?projectId=${projectId}`,
    method: method.get,
    showToastMessage: false,
    toastMessage: 'construction schedule Fetch successfully!',
  })
  if (data) {
    dispatch(fetchConstructionScheduleSuccess(data))
    return
  }
  if (error) {
    dispatch(fetchConstructionScheduleFailed(error.response))
  }
}
// Create and update construction schedule
const createAndUpdateConstructionScheduleRequested = () => ({
  type: CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_REQUESTED,
})
const createAndUpdateConstructionScheduleSuccess = (payload) => (dispatch) => {
  dispatch({
    type: CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_SUCCESS,
    payload: [{ ...payload }],
    activeConstruction: payload?.id,
  })
}
const createAndUpdateConstructionScheduleFailed = (payload) => ({
  type: CREATE_AND_UPDATE_CONSTRUCTION_SCHEDULE_FAILED,
  payload,
})

export const createAndUpdateConstructionSchedule =
  (payload, id) => async (dispatch) => {
    dispatch(createAndUpdateConstructionScheduleRequested())
    const updatedPayload = { ...payload }
    delete updatedPayload.isNewConstructionSchedule
    const { data, error, statusCode } = await api({
      endPoint: `${apiEndPoints.constructionSchedule}${id}/?projectId=${payload.project}`,
      method: method.patch,
      data: updatedPayload,
      showToastMessage: true,
      toastMessage: 'Construction schedule updated successfully!',
    })
    const cloneData = ternary(statusCode, null, data)
    setTimeout(() => {
      dispatch(updateSaveStatusAction(cloneData, error))
    }, 700)
    if (data) {
      dispatch(createAndUpdateConstructionScheduleSuccess(data))
      return
    }
    if (error) {
      dispatch(createAndUpdateConstructionScheduleFailed(error.response.data))
    }
  }

// Fetch owner contractor mapping
const fetchOwnerContractorRequested = () => ({
  type: FETCH_OWNER_CONTRACTOR_MAPPING_REQUESTED,
})
export const fetchOwnerContractorSuccess = (payload) => ({
  type: FETCH_OWNER_CONTRACTOR_MAPPING_SUCCESS,
  payload,
})
const fetchOwnerContractorFailed = (payload) => ({
  type: FETCH_OWNER_CONTRACTOR_MAPPING_FAILED,
  payload,
})

export const getOwnerContractorMapping =
  (id, uniqueCode) => async (dispatch) => {
    dispatch(fetchOwnerContractorRequested())
    const { data, error } = await api({
      endPoint: ternary(
        uniqueCode,
        `${apiEndPoints.constructionSchedule}mapping/${id}/?uniqueCode=${uniqueCode}`,
        `${apiEndPoints.constructionSchedule}mapping/${id}/`,
      ),
      method: method.get,
      showToastMessage: false,
      toastMessage: 'Owner Contractor mapping Fetch successfully!',
    })
    if (data) {
      const dataWithUpdatedKeys = data?.sort((a, b) =>
        a?.uniqueCode > b?.uniqueCode ? 1 : -1,
      )
      dispatch(fetchOwnerContractorSuccess(dataWithUpdatedKeys))
      const toast = () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          if (!dataWithUpdatedKeys?.length) {
            showToast(
              'Complete ‘Owner-Contractor’ prior to entering data on ‘Construction Schedule’ page. ',
              'error',
              'top-right',
            )
          }
        }, 2000)
      }
      toast()
      return
    }
    if (error) {
      dispatch(fetchOwnerContractorFailed(error.response.data))
    }
  }

// Add new construction schedule
export const addConstructionScheduleSuccess = (payload) => ({
  type: ADD_CONSTRUCTION_SCHEDULE,
  payload,
})

export const addConstructionSchedule = (id) => (dispatch, getState) => {
  const state = getState()
  const constructionScheduleList = [
    ...state.constructionSchedule.constructionScheduleList,
  ]
  const sortedScheduleList = constructionScheduleList?.sort((a, b) =>
    a?.uniqueCode > b?.uniqueCode ? 1 : -1,
  )
  const lastIndexConstructionSchedule = sortedScheduleList
    ?.slice(-1)[0]
    ?.uniqueCode?.split('-')[1]
  dispatch(
    addConstructionScheduleSuccess([
      ...constructionScheduleList,
      {
        id,
        isNewConstructionSchedule: true,
        uniqueCode: getUniqueCode(
          ternary(
            lastIndexConstructionSchedule,
            +lastIndexConstructionSchedule + 1,
            '1',
          ),
        ),
      },
    ]),
  )
}

// Delete construction schedule
const deleteConstructionScheduleRequested = () => ({
  type: DELETE_CONSTRUCTION_SCHEDULE_REQUESTED,
})
export const deleteConstructionScheduleSuccess =
  (payload) => (dispatch, getState) => {
    const state = getState()
    const constructionScheduleList = [
      ...state.constructionSchedule.constructionScheduleList,
    ]
    const index = constructionScheduleList?.findIndex(({ id }) =>
      equal(id, payload?.constructionSchedule[0]),
    )
    if (~index) {
      constructionScheduleList.splice(index, 1)
    }
    dispatch({
      type: DELETE_CONSTRUCTION_SCHEDULE_SUCCESS,
      payload: constructionScheduleList,
      activeConstruction: constructionScheduleList[0]?.id,
    })
  }
const deleteConstructionScheduleFailed = (payload) => ({
  type: DELETE_CONSTRUCTION_SCHEDULE_FAILED,
  payload,
})

export const deleteConstructionSchedule =
  (payload, handleClose) => async (dispatch) => {
    dispatch(deleteConstructionScheduleRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.deleteConstructionSchedule}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Construction schedule deleted successfully!',
    })
    if (data) {
      handleClose()
      dispatch(deleteConstructionScheduleSuccess(payload))
      return
    }
    if (error) {
      dispatch(deleteConstructionScheduleFailed(error.response.data))
    }
  }
