import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, ternary } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  CLEAR_ES_GEN_MODAL_VALUES,
  FETCH_AUTO_GEN_ES_FAILED,
  FETCH_AUTO_GEN_ES_REQUESTED,
  FETCH_AUTO_GEN_ES_SUCCESS,
  FETCH_AUTO_GEN_ES_VALUES_FAILED,
  FETCH_AUTO_GEN_ES_VALUES_REQUESTED,
  FETCH_AUTO_GEN_ES_VALUES_SUCCESS,
  FETCH_EXECUTIVE_SUMMARY_FAILED,
  FETCH_EXECUTIVE_SUMMARY_REQUESTED,
  FETCH_EXECUTIVE_SUMMARY_SUCCESS,
  IMPORT_COMMENTS_FAILED,
  IMPORT_COMMENTS_REQUESTED,
  IMPORT_COMMENTS_SUCCESS,
  IMPORT_EXECUTIVE_SUMMARY_FAILED,
  IMPORT_EXECUTIVE_SUMMARY_REQUESTED,
  IMPORT_EXECUTIVE_SUMMARY_SUCCESS,
  IMPORT_OPEN_ISSUES_FAILED,
  IMPORT_OPEN_ISSUES_REQUESTED,
  IMPORT_OPEN_ISSUES_SUCCESS,
  IMPORT_QUALITY_OF_WORK_FAILED,
  IMPORT_QUALITY_OF_WORK_REQUESTED,
  IMPORT_QUALITY_OF_WORK_SUCCESS,
  SAVE_AUTO_GEN_ES_TEXT_FAILED,
  SAVE_AUTO_GEN_ES_TEXT_REQUESTED,
  SAVE_AUTO_GEN_ES_TEXT_SUCCESS,
  SAVE_EXECUTIVE_SUMMARY_FAILED,
  SAVE_EXECUTIVE_SUMMARY_REQUESTED,
  SAVE_EXECUTIVE_SUMMARY_SUCCESS,
} from '../constants/executiveSummary'
import { updateSaveStatusAction } from './confirmation'

/* FETCH EXECUTIVE SUMMARY */
const fetchExecutiveSummaryRequested = () => ({
  type: FETCH_EXECUTIVE_SUMMARY_REQUESTED,
})
const fetchExecutiveSummarySuccess = (payload) => ({
  type: FETCH_EXECUTIVE_SUMMARY_SUCCESS,
  payload,
})
const fetchExecutiveSummaryFailed = (payload) => ({
  type: FETCH_EXECUTIVE_SUMMARY_FAILED,
  payload,
})
export const getExecutiveSummary =
  ({ reportId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchExecutiveSummaryRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.executiveSummary}?reportId=${reportId}&projectId=${projectId}`,
    })
    if (data) {
      dispatch(fetchExecutiveSummarySuccess(data))
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchExecutiveSummarySuccess({}))
      }
      dispatch(fetchExecutiveSummaryFailed(error.response))
    }
  }

/* SAVE EXECUTIVE SUMMARY */
const saveExecutiveSummaryRequested = () => ({
  type: SAVE_EXECUTIVE_SUMMARY_REQUESTED,
})
export const saveExecutiveSummarySuccess = (payload) => ({
  type: SAVE_EXECUTIVE_SUMMARY_SUCCESS,
  payload,
})
const saveExecutiveSummaryFailed = (payload) => ({
  type: SAVE_EXECUTIVE_SUMMARY_FAILED,
  payload,
})
export const saveExecutiveSummary =
  (payload, reportId, projectId, esId) => async (dispatch) => {
    dispatch(saveExecutiveSummaryRequested())
    const { data, error } = await api({
      method: ternary(esId, method.patch, method.post),
      endPoint: `${apiEndPoints.executiveSummary}?reportId=${reportId}&projectId=${projectId}`,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(saveExecutiveSummarySuccess(data))
    }
    if (error) {
      dispatch(saveExecutiveSummaryFailed(error.response.data))
    }
  }

/* IMPORT EXECUTIVE SUMMARY */
const importExecutiveSummaryRequested = () => ({
  type: IMPORT_EXECUTIVE_SUMMARY_REQUESTED,
})
const importExecutiveSummarySuccess = (payload) => ({
  type: IMPORT_EXECUTIVE_SUMMARY_SUCCESS,
  payload,
})
const importExecutiveSummaryFailed = (payload) => ({
  type: IMPORT_EXECUTIVE_SUMMARY_FAILED,
  payload,
})
export const importExecutiveSummary =
  (projectId, ocId, callback, reportId) => async (dispatch) => {
    dispatch(importExecutiveSummaryRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.importLastReport}?query=executiveSummary&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
    })
    if (data) {
      dispatch(importExecutiveSummarySuccess(data))
      callback(data.executiveSummary)
    }
    if (error) {
      showToast(
        error.response?.data?.detail || 'Failed to import executive summary',
        'error',
      )
      dispatch(importExecutiveSummaryFailed(error.response.data))
    }
  }

/* IMPORT OPEN ISSUES */
const importOpenIssuesRequested = () => ({
  type: IMPORT_OPEN_ISSUES_REQUESTED,
})
const importOpenIssuesSuccess = (payload) => ({
  type: IMPORT_OPEN_ISSUES_SUCCESS,
  payload,
})
const importOpenIssuesFailed = (payload) => ({
  type: IMPORT_OPEN_ISSUES_FAILED,
  payload,
})
export const importOpenIssues =
  (projectId, ocId, callback, reportId) => async (dispatch) => {
    dispatch(importOpenIssuesRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.importLastReport}?query=openIssues&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
    })
    if (data) {
      dispatch(importOpenIssuesSuccess(data))
      callback(data.openIssues)
    }
    if (error) {
      showToast(
        error.response?.data?.detail || 'Failed to import open issues',
        'error',
      )
      dispatch(importOpenIssuesFailed(error.response.data))
    }
  }

/* IMPORT COMMENTS */
const importCommentsRequested = () => ({
  type: IMPORT_COMMENTS_REQUESTED,
})
const importCommentsSuccess = (payload) => ({
  type: IMPORT_COMMENTS_SUCCESS,
  payload,
})
const importCommentsFailed = (payload) => ({
  type: IMPORT_COMMENTS_FAILED,
  payload,
})
export const importComments =
  (projectId, ocId, callback, reportId) => async (dispatch) => {
    dispatch(importCommentsRequested())

    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.importLastReport}?query=comments&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
    })
    if (data) {
      dispatch(importCommentsSuccess(data))
      callback(data.comments)
    }
    if (error) {
      showToast(
        error.response?.data?.detail || 'Failed to import comments',
        'error',
      )
      dispatch(importCommentsFailed(error.response.data))
    }
  }

/* IMPORT openIssues */
const importQualityOfWorkRequested = () => ({
  type: IMPORT_QUALITY_OF_WORK_REQUESTED,
})
const importQualityOfWorkSuccess = (payload) => ({
  type: IMPORT_QUALITY_OF_WORK_SUCCESS,
  payload,
})
const importQualityOfWorkFailed = (payload) => ({
  type: IMPORT_QUALITY_OF_WORK_FAILED,
  payload,
})

export const importQualityOfWork =
  (projectId, ocId, callback, reportId) => async (dispatch) => {
    dispatch(importQualityOfWorkRequested())

    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.importLastReport}?query=qualityOfWork&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
    })
    if (data) {
      dispatch(importQualityOfWorkSuccess(data))
      callback(data.qualityOfWork)
    }
    if (error) {
      showToast(
        error.response?.data?.detail || 'Failed to import qualityOfWork',
        'error',
      )
      dispatch(importQualityOfWorkFailed(error.response.data))
    }
  }

// auto generate executive summary
const fetchAutoGenESRequested = () => ({
  type: FETCH_AUTO_GEN_ES_REQUESTED,
})
const fetchAutoGenESSuccess = (payload) => ({
  type: FETCH_AUTO_GEN_ES_SUCCESS,
  payload,
})
const fetchAutoGenESFailed = (payload) => ({
  type: FETCH_AUTO_GEN_ES_FAILED,
  payload,
})

export const getAutoGenESText =
  ({ reportId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchAutoGenESRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.autoGenEsText}?reportId=${reportId}&projectId=${projectId}`,
    })

    if (data) {
      dispatch(fetchAutoGenESSuccess(data?.text))
    }
    if (error) {
      dispatch(fetchAutoGenESFailed(error.response))
    }
  }

const fetchAutoGenESValuesRequested = () => ({
  type: FETCH_AUTO_GEN_ES_VALUES_REQUESTED,
})
const fetchAutoGenESValuesSuccess = (payload) => ({
  type: FETCH_AUTO_GEN_ES_VALUES_SUCCESS,
  payload,
})
const fetchAutoGenESValuesFailed = (payload) => ({
  type: FETCH_AUTO_GEN_ES_VALUES_FAILED,
  payload,
})

export const getAutoGenESValues =
  ({ reportId, projectId }) =>
  async (dispatch) => {
    dispatch(fetchAutoGenESValuesRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.autoGenEsValues}?reportId=${reportId}&projectId=${projectId}`,
    })

    if (data) {
      dispatch(fetchAutoGenESValuesSuccess(data))
    }
    if (error) {
      dispatch(fetchAutoGenESValuesFailed(error.response))
    }
  }

const saveAutoGenEsTextRequested = () => ({
  type: SAVE_AUTO_GEN_ES_TEXT_REQUESTED,
})
export const saveAutoGenEsTextSuccess = (payload) => ({
  type: SAVE_AUTO_GEN_ES_TEXT_SUCCESS,
  payload,
})
const saveAutoGenEsTextFailed = (payload) => ({
  type: SAVE_AUTO_GEN_ES_TEXT_FAILED,
  payload,
})
export const saveAutoGenEsText =
  (payload, reportId, projectId, onSuccess) => async (dispatch) => {
    dispatch(saveAutoGenEsTextRequested())
    const { data, error } = await api({
      method: method.patch,
      endPoint: `${apiEndPoints.autoGenEsText}?reportId=${reportId}&projectId=${projectId}`,
      data: payload,
      showToastMessage: true,
      toastMessage:
        'Auto generated executive summary text Saved and Exported successfully!',
    })

    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(saveAutoGenEsTextSuccess(data?.text))
      onSuccess()
    }
    if (error) {
      dispatch(saveAutoGenEsTextFailed(error.response))
    }
  }

export const clearEsGenModalValues = () => ({
  type: CLEAR_ES_GEN_MODAL_VALUES,
})
