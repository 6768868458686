import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { equal } from '../../../Utils/javascript'
import { clientPortal } from '../../../Utils/constant'
import RfpProjectDashboard from './RfpProjectDashboard'
import RFPOverviewConsultant from './RFPOverviewConsultant'
import {
  fetchBidsData,
  getRfpCompanyList,
  getScopeServiceOpts,
  getSingleRfpData,
} from '../../../Redux/actions/rfp'
import { getProjectChoices } from '../../../Redux/actions/choices'

const RFPProjectOverview = () => {
  const { portalType } = useSelector(({ auth }) => auth)
  const { rfpId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (rfpId) {
      dispatch(getSingleRfpData(rfpId))
      dispatch(fetchBidsData({ rfpId }))
      dispatch(getRfpCompanyList({ rfpId }))
    }
    dispatch(getScopeServiceOpts())
    dispatch(
      getProjectChoices({
        fieldName: 'constructionType,projectType,parcelType',
      }),
    )
  }, [rfpId])

  return equal(portalType, clientPortal) ? (
    <RfpProjectDashboard />
  ) : (
    <RFPOverviewConsultant />
  )
}

export default RFPProjectOverview
