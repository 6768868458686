import { RemoveCircleOutline } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import React, { useCallback, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { DragIcon } from '../../../Assets/SVGs'
import {
  deleteThirdPartyReport,
  importThirdPartyReport,
} from '../../../Redux/actions/thirdPartyReport'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTTooltip from '../../../Shared/DKTTooltip'
import { useStyles } from '../../../Styles/thirdPartyReports.style'
import { importButtonTooltip, spinnerSize } from '../../../Utils/constant'
import { equal, ternary } from '../../../Utils/javascript'
import { showToast } from '../../../Utils/toastService'
import DKTEditor from '../../../Shared/DKTEditor'

const ThirdPartyReport = ({
  thirdPartyReport,
  index,
  values,
  ocId,
  data,
  setData,
  hasOnlyViewPermission,
  setIsDirty,
}) => {
  const [isImportWarningOpen, setIsImportWarningOpen] = useState(false)
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)

  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { slug: projectId, reportId } = useParams()
  const { isImporting, isThirdPartyReportDeleting } = useSelector(
    ({ thirdPartyReport }) => thirdPartyReport,
  )

  const openDeleteNotification = () => setIsDeleteNotificationOpen(true)
  const closeDeleteNotification = () => setIsDeleteNotificationOpen(false)

  const handleDescriptionChange = (value = '') => {
    setData(() => {
      const clonedData = [...data]
      clonedData[index].description = value
      return clonedData
    })
    setIsDirty(true)
  }

  /* IMPORT FROM LAST REPORT */
  const showImportWarning = () => setIsImportWarningOpen(true)
  const hideImportWarning = () => setIsImportWarningOpen(false)
  const handleImport = useCallback(
    (name) => {
      if (thirdPartyReport.description) {
        showImportWarning()
        return
      }

      dispatch(
        importThirdPartyReport({
          thirdPartyReportSections: name,
          projectId,
          ocId,
          onSuccess: (value) => handleDescriptionChange(value),
          reportId,
        }),
      )
    },
    [projectId, projectId, reportId, ocId, dispatch, values],
  )
  const handleImportOverWrite = () => {
    if (thirdPartyReport.thirdPartyReportSection.id) {
      const onSuccess = (value) => {
        handleDescriptionChange(value)
        hideImportWarning()
      }
      dispatch(
        importThirdPartyReport({
          thirdPartyReportSections: thirdPartyReport.thirdPartyReportSection.id,
          projectId,
          ocId,
          onSuccess,
          reportId,
        }),
      )
    }
  }
  const importWarningActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={hideImportWarning}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleImportOverWrite}
        disabled={Object.values(isImporting).some((value) => value)}
      >
        {ternary(
          Object.values(isImporting).some((value) => value),
          <DKTCircularProgress
            size={spinnerSize?.sm}
            color={theme.palette.gray.dark}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const handleDelete = () => {
    if (thirdPartyReport.id) {
      // If 3rd party report is saved
      dispatch(
        deleteThirdPartyReport(
          {
            thirdPartyReport: [thirdPartyReport.id],
          },
          reportId,
          ocId,
          projectId,
          data,
          closeDeleteNotification,
        ),
      )
      return
    }
    // if 3rd party report is local
    setData((prevData) => {
      const clonedData = [...prevData]
      const reportToDelete = clonedData.findIndex(({ uniqueId }) =>
        equal(uniqueId, thirdPartyReport.uniqueId),
      )
      if (~reportToDelete) {
        clonedData.splice(reportToDelete, 1)
        showToast('Deleted successfully!', 'success')
      }
      return clonedData
    })
  }

  const deleteNotificationActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteNotification}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleDelete}
        disabled={isThirdPartyReportDeleting}
      >
        {ternary(
          isThirdPartyReportDeleting,
          <DKTCircularProgress color={theme.palette.gray.dark} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  return (
    <Draggable
      draggableId={(
        thirdPartyReport.id || thirdPartyReport.uniqueId
      ).toString()}
      index={index}
      isDragDisabled={hasOnlyViewPermission}
    >
      {(provided) => (
        <Grid
          key={thirdPartyReport.id || thirdPartyReport.uniqueId}
          item
          xs={12}
          mt={2}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Stack
            direction="row"
            alignItems="end"
            justifyContent="space-between"
            mb={1}
          >
            <Box {...provided.dragHandleProps}>
              {!hasOnlyViewPermission && <DragIcon />}
              <Typography
                variant="body2"
                component="span"
                color="gray.extraDark"
                sx={{
                  fontWeight: 'medium',
                  ml: ternary(hasOnlyViewPermission, 0, 1.5),
                }}
              >
                {thirdPartyReport.thirdPartyReportSection.name}
              </Typography>
            </Box>

            <Stack direction="row" spacing={1}>
              <DKTTooltip title={importButtonTooltip}>
                <Box>
                  <DKTButton
                    variant="outlined"
                    sx={{
                      borderColor: '#ACACAC',
                      color: '#2D2D2D',
                      width: '91px',
                      height: '32px',
                      '&:hover': {
                        borderColor: '#000',
                      },
                    }}
                    onClick={() =>
                      handleImport(thirdPartyReport.thirdPartyReportSection.id)
                    }
                    disabled={
                      hasOnlyViewPermission ||
                      isImporting[thirdPartyReport.thirdPartyReportSection.id]
                    }
                  >
                    {ternary(
                      isImporting[thirdPartyReport.thirdPartyReportSection.id],
                      <DKTCircularProgress
                        size={spinnerSize?.sm}
                        color={theme.palette.gray.dark}
                      />,
                      'Import',
                    )}
                  </DKTButton>
                </Box>
              </DKTTooltip>
              {!hasOnlyViewPermission && (
                <IconButton
                  onClick={openDeleteNotification}
                  disabled={hasOnlyViewPermission}
                >
                  <RemoveCircleOutline
                    color={ternary(hasOnlyViewPermission, 'gray', 'primary')}
                  />
                </IconButton>
              )}
            </Stack>
          </Stack>
          <Grid
            item
            xs={12}
            sx={ternary(
              hasOnlyViewPermission,
              { pointerEvents: 'none', userSelect: 'none' },
              {},
            )}
          >
            <DKTEditor
              value={thirdPartyReport.description || ''}
              onChange={(event, editor) => {
                const data = editor.getData()
                handleDescriptionChange(data)
              }}
              disabled={hasOnlyViewPermission}
              className={classes.section}
            />
          </Grid>

          {/* Import content over-write warning */}
          <DKTDialog
            open={isImportWarningOpen}
            handleClose={hideImportWarning}
            title="&nbsp;"
            actions={importWarningActions}
            maxWidth="xs"
          >
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.deleteNotificationContent}>
                  <Typography variant="h5">
                    Existing data will be overwritten.
                  </Typography>
                  <Typography variant="body1">Continue?</Typography>
                </Box>
              </Grid>
            </Grid>
          </DKTDialog>

          <DKTDialog
            open={isDeleteNotificationOpen}
            handleClose={closeDeleteNotification}
            title="&nbsp;"
            actions={deleteNotificationActions}
            maxWidth="xs"
          >
            <Grid container>
              <Grid item xs={12}>
                <Box className={classes.deleteNotificationContent}>
                  <Typography variant="h5">
                    Are you sure you want to delete?
                  </Typography>
                  <Typography variant="body1">
                    You can&apos;t undo this action
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </DKTDialog>
        </Grid>
      )}
    </Draggable>
  )
}

export default ThirdPartyReport
