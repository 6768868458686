import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import {
  deleteReportAttachmentPdf,
  getPdfList,
  updateReportAttachmentsPdfList,
  uploadPdfInAttachments,
} from '../../../Redux/actions/attachments'
import DKTButton from '../../../Shared/DKTButton'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { useStyles } from '../../../Styles/attachments.style'
import { equal, isArray } from '../../../Utils/javascript'
import Attachments from './Attachments'
import usePermission from '../../../Hooks/usePermission'

const ReportAttachments = () => {
  const [listPdf, setListPdf] = useState([])
  const [uploadPdf, setUploadPdf] = useState([])
  const [ownerContractorId, setOwnerContractorId] = useState(null)
  const [edit, setEdit] = useState()
  const [isDirty, setIsDirty] = useState(false)
  const [dragDataDirty, setDragDataDirty] = useState(false)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)

  const { slug: projectId, reportId } = useParams()
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)
  const { reportPdfList, isUpdatePdfListLoading, isFetchPdfListLoading } =
    useSelector(({ attachments }) => attachments)
  const { hasOnlyViewPermission } = usePermission()

  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    dispatch(
      getPdfList({
        projectId,
        ocId: choices?.ownerContractor[0]?.id,
        reportId,
      }),
    )
  }, [choices?.ownerContractor[0]?.id])

  useEffect(() => {
    let activeContractor = null
    if (isArray(choices?.ownerContractor) && choices?.ownerContractor?.length) {
      activeContractor = choices?.ownerContractor[0]
    }
    setOwnerContractorId(activeContractor?.id)
  }, [choices?.ownerContractor[0]?.id])

  useEffect(() => {
    setListPdf(reportPdfList?.map((pdf) => ({ ...pdf, isDraggable: true })))
  }, [reportPdfList])

  useEffect(() => {
    const res = listPdf?.map((pdf) => {
      const oldData = reportPdfList?.find((item) => equal(item.id, pdf.id))
      return !equal(pdf.filename, oldData.filename)
    })
    if (res?.flat().length) {
      setIsDirty(res?.flat()?.some((data) => data === true))
    }
  }, [listPdf])

  const handleUpdate = () => {
    setIsDirty(false)
    setDragDataDirty(false)
    dispatch(
      updateReportAttachmentsPdfList({
        payload: listPdf,
        reportId,
        ocId: ownerContractorId,
        projectId,
      }),
    )
  }

  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack direction="row"></Stack>
        <Stack direction="row" gap={2}>
          <DKTButton
            className={classes.rightButton}
            onClick={handleUpdate}
            disabled={
              !listPdf?.length ||
              isUpdatePdfListLoading ||
              isFetchPdfListLoading ||
              hasOnlyViewPermission
            }
          >
            {isUpdatePdfListLoading ? 'Saving...' : 'Save'}
          </DKTButton>
        </Stack>
      </Box>
    </>
  )

  const handleUploadPdf = () => {
    const formData = new FormData()

    uploadPdf.forEach((pdfFile) => {
      formData.append('attachment_file', pdfFile)
    })
    dispatch(
      uploadPdfInAttachments({
        payload: formData,
        reportId,
        ocId: ownerContractorId,
        projectId,
        setCancelTokenSource,
        setUploadPdf,
      }),
    )
  }
  const handleDeletePdf = (selected, setSelected) => {
    dispatch(
      deleteReportAttachmentPdf({
        payload: {
          attachment: selected,
        },
        projectId,
        ocId: choices?.ownerContractor[0]?.id,
        reportId,
      }),
    ).then(() => {
      dispatch(
        getPdfList({
          projectId,
          ocId: choices?.ownerContractor[0]?.id,
          reportId,
          isLoading: false,
        }),
      )
      setSelected([])
    })
  }

  return (
    <InnerLayout
      contentTitle="Attachments"
      actions={actions}
      maxWidth="md"
      isShowMenu
    >
      <Attachments
        {...{
          listPdf,
          setListPdf,
          edit,
          setEdit,
          handleDeletePdf,
          uploadPdf,
          setUploadPdf,
          setDragDataDirty,
          handleUploadPdf,
          cancelTokenSource,
          hasOnlyViewPermission,
        }}
      />
      {!hasOnlyViewPermission && (
        <DKTReactRouterPrompt
          isDirty={isDirty || dragDataDirty}
          onSave={handleUpdate}
        />
      )}
    </InnerLayout>
  )
}

export default React.memo(ReportAttachments)
