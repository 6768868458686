// Fetch project team
export const FETCH_PROJECT_TEAM_REQUESTED = 'FETCH_PROJECT_TEAM_REQUESTED'
export const FETCH_PROJECT_TEAM_SUCCESS = 'FETCH_PROJECT_TEAM_SUCCESS'
export const FETCH_PROJECT_TEAM_FAILED = 'FETCH_PROJECT_TEAM_FAILED'

// Create and update project team
export const CREATE_AND_UPDATE_PROJECT_TEAM_REQUESTED =
  'CREATE_AND_UPDATE_PROJECT_TEAM_REQUESTED'
export const CREATE_AND_UPDATE_PROJECT_TEAM_SUCCESS =
  'CREATE_AND_UPDATE_PROJECT_TEAM_SUCCESS'
export const CREATE_AND_UPDATE_PROJECT_TEAM_FAILED =
  'CREATE_AND_UPDATE_PROJECT_TEAM_FAILED'

// Delete project team
export const DELETE_PROJECT_TEAM_REQUESTED = 'DELETE_PROJECT_TEAM_REQUESTED'
export const DELETE_PROJECT_TEAM_SUCCESS = 'DELETE_PROJECT_TEAM_SUCCESS'
export const DELETE_PROJECT_TEAM_FAILED = 'DELETE_PROJECT_TEAM_FAILED'

// Fetch report tier
export const FETCH_REPORT_TIER_REQUESTED = 'FETCH_REPORT_TIER_REQUESTED'
export const FETCH_REPORT_TIER_SUCCESS = 'FETCH_REPORT_TIER_SUCCESS'
export const FETCH_REPORT_TIER_FAILED = 'FETCH_REPORT_TIER_FAILED'

// set report tier
export const SET_REPORT_TIER_REQUESTED = 'SET_REPORT_TIER_REQUESTED'
export const SET_REPORT_TIER_SUCCESS = 'SET_REPORT_TIER_SUCCESS'
export const SET_REPORT_TIER_FAILED = 'SET_REPORT_TIER_FAILED'
