import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  textArea: {
    marginTop: theme.spacing(3),
    resize: 'vertical',
    padding: [[10, 18]],
    borderRadius: 0,
    borderColor: '#C4C4C4',
    boxSizing: 'border-box',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    letterSpacing: '0.00938em',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  mtNone: {
    marginTop: 0,
  },
}))
