import { equal, ternary } from '../../Utils/javascript'
import {
  ADD_PROJECT_DIRECTORY_SUCCESS,
  CLEAR_PROJECT_DIRECTORY,
  CREATE_PROJECT_DIRECTORY_FAILED,
  CREATE_PROJECT_DIRECTORY_REQUESTED,
  CREATE_PROJECT_DIRECTORY_SUCCESS,
  DELETE_PROJECT_DIRECTORY_CONTACT,
  DELETE_PROJECT_DIRECTORY_FAILED,
  DELETE_PROJECT_DIRECTORY_REQUESTED,
  DELETE_PROJECT_DIRECTORY_SUCCESS,
  FETCH_PROJECT_DIRECTORY_FAILED,
  FETCH_PROJECT_DIRECTORY_LIST_FAILED,
  FETCH_PROJECT_DIRECTORY_LIST_REQUESTED,
  FETCH_PROJECT_DIRECTORY_LIST_SUCCESS,
  FETCH_PROJECT_DIRECTORY_MAPPING_FAILED,
  FETCH_PROJECT_DIRECTORY_MAPPING_REQUESTED,
  FETCH_PROJECT_DIRECTORY_MAPPING_SUCCESS,
  FETCH_PROJECT_DIRECTORY_REQUESTED,
  FETCH_PROJECT_DIRECTORY_SUCCESS,
  RESET_CURRENT_PROJECT_DIRECTORY,
  SAVE_CONTACT_TYPE_FAILED,
  SAVE_CONTACT_TYPE_REQUESTED,
  SAVE_CONTACT_TYPE_SUCCESS,
  SAVE_PROJECT_DIRECTORY_FAILED,
  SAVE_PROJECT_DIRECTORY_REQUESTED,
  SAVE_PROJECT_DIRECTORY_SUCCESS,
  SEARCH_COMPANY_FAILED,
  SEARCH_COMPANY_REQUESTED,
  SEARCH_COMPANY_SUCCESS,
  SET_PROJECT_DIRECTORY_ID,
  SORT_CONTACT_TYPE_SUCCESS,
  UPDATE_COMPANY_NAME_FAILED,
  UPDATE_COMPANY_NAME_REQUESTED,
  UPDATE_COMPANY_NAME_SUCCESS,
} from '../constants/projectDirectory'

const initialState = {
  mappings: [],
  searchResults: [],
  companyNameList: [],
  isLoadingMapping: false,
  fetchMappingError: null,
  isLoadingProjectDirectoryList: true,
  isLoadingProjectDirectory: false,
  isSavingProjectDirectory: false,
  isDeletingProjectDirectory: false,
  isSavingContactType: false,
  isCreatingProjectDirectory: false,
  isSearchingCompany: false,
  fetchProjectDirectoryListError: null,
  fetchProjectDirectoryError: null,
  createProjectDirectoryError: null,
  saveProjectDirectoryError: null,
  saveContactTypeError: null,
  searchCompanyError: null,
  projectDirectoryList: null,
  projectDirectory: null,
  projectDirectoryId: null,
  isNewProjectDirectory: false,
  isUpdatingCompanyName: false,
}

export const projectDirectory = (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_PROJECT_DIRECTORY_REQUESTED:
      return {
        ...state,
        isCreatingProjectDirectory: true,
      }
    case CREATE_PROJECT_DIRECTORY_SUCCESS:
      return {
        ...state,
        isCreatingProjectDirectory: false,
        projectDirectoryList: action.payload.projectDirectoryList,
        projectDirectory: action.payload.projectDirectory,
      }
    case CREATE_PROJECT_DIRECTORY_FAILED:
      return {
        ...state,
        isCreatingProjectDirectory: false,
        createProjectDirectoryError: action.payload,
      }
    case FETCH_PROJECT_DIRECTORY_REQUESTED:
      return {
        ...state,
        isLoadingProjectDirectory: true,
      }
    case FETCH_PROJECT_DIRECTORY_SUCCESS:
      return {
        ...state,
        isLoadingProjectDirectory: false,
        projectDirectory: action.payload,
      }
    case FETCH_PROJECT_DIRECTORY_FAILED:
      return {
        ...state,
        isLoadingProjectDirectory: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchProjectDirectoryError: action.payload?.data,
      }
    case FETCH_PROJECT_DIRECTORY_LIST_REQUESTED:
      return {
        ...state,
        isLoadingProjectDirectoryList: true,
      }
    case FETCH_PROJECT_DIRECTORY_LIST_SUCCESS:
      return {
        ...state,
        isLoadingProjectDirectoryList: false,
        projectDirectoryList: action.payload,
      }
    case FETCH_PROJECT_DIRECTORY_LIST_FAILED:
      return {
        ...state,
        isLoadingProjectDirectoryList: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),

        fetchProjectDirectoryListError: action.payload?.data,
      }
    case SAVE_PROJECT_DIRECTORY_REQUESTED:
      return {
        ...state,
        isSavingProjectDirectory: true,
      }
    case SAVE_PROJECT_DIRECTORY_SUCCESS:
      return {
        ...state,
        isSavingProjectDirectory: false,
        projectDirectory: action.payload,
      }
    case SAVE_PROJECT_DIRECTORY_FAILED:
      return {
        ...state,
        isSavingProjectDirectory: false,
        saveProjectDirectoryError: action.payload,
      }
    case ADD_PROJECT_DIRECTORY_SUCCESS:
      return {
        ...state,
        projectDirectoryList: action.payload.projectDirectoryList,
        projectDirectory: action.payload.projectDirectory,
      }
    case SET_PROJECT_DIRECTORY_ID:
      return {
        ...state,
        projectDirectoryId: action.payload.projectDirectoryId,
        isNewProjectDirectory: action.payload.isNewProjectDirectory,
      }
    case FETCH_PROJECT_DIRECTORY_MAPPING_REQUESTED:
      return {
        ...state,
        isLoadingMapping: true,
      }
    case FETCH_PROJECT_DIRECTORY_MAPPING_SUCCESS:
      return {
        ...state,
        isLoadingMapping: false,
        mappings: action.payload,
      }
    case FETCH_PROJECT_DIRECTORY_MAPPING_FAILED:
      return {
        ...state,
        isLoadingMapping: false,
        fetchMappingError: action.payload,
      }

    // Contact Type
    case SAVE_CONTACT_TYPE_REQUESTED:
      return {
        ...state,
        isSavingContactType: true,
      }
    case SAVE_CONTACT_TYPE_SUCCESS:
      return {
        ...state,
        isSavingContactType: false,
        projectDirectory: action.payload,
      }
    case SAVE_CONTACT_TYPE_FAILED:
      return {
        ...state,
        isSavingContactType: false,
        saveContactTypeError: action.payload,
      }
    case SEARCH_COMPANY_REQUESTED:
      return {
        ...state,
        isSearchingCompany: true,
      }
    case SEARCH_COMPANY_SUCCESS:
      return {
        ...state,
        isSearchingCompany: false,
        searchResults: action.payload?.dataWithLabelValue,
        companyNameList: action.payload?.data,
      }
    case SEARCH_COMPANY_FAILED:
      return {
        ...state,
        isSearchingCompany: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        searchCompanyError: action.payload?.data,
      }
    case SORT_CONTACT_TYPE_SUCCESS:
      return {
        ...state,
        projectDirectory: action.payload,
      }

    // Updated company name of project directory
    case UPDATE_COMPANY_NAME_REQUESTED:
      return {
        ...state,
        isUpdatingCompanyName: true,
      }
    case UPDATE_COMPANY_NAME_SUCCESS:
      return {
        ...state,
        isUpdatingCompanyName: false,
        searchResults: action.payload,
      }
    case UPDATE_COMPANY_NAME_FAILED:
      return {
        ...state,
        isUpdatingCompanyName: false,
      }

    // Delete project directory
    case DELETE_PROJECT_DIRECTORY_REQUESTED:
      return {
        ...state,
        isDeletingProjectDirectory: true,
      }
    case DELETE_PROJECT_DIRECTORY_SUCCESS:
      return {
        ...state,
        isDeletingProjectDirectory: false,
        projectDirectoryList: action.payload,
        projectDirectoryId: action?.activeProjectDirectory,
        isNewProjectDirectory:
          action.isNewProjectDirectory || state.isNewProjectDirectory,
      }
    case DELETE_PROJECT_DIRECTORY_FAILED:
      return {
        ...state,
        isDeletingProjectDirectory: false,
      }
    // Delete project directory contact
    case DELETE_PROJECT_DIRECTORY_CONTACT:
      return {
        ...state,
        projectDirectory: action.payload,
      }
    case RESET_CURRENT_PROJECT_DIRECTORY:
      return {
        ...state,
        projectDirectory: null,
      }
    case CLEAR_PROJECT_DIRECTORY:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
