import { Grid } from '@mui/material'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { companyFieldList } from '../../../Description/createCompanyPersonnel.description'
import useForm from '../../../Hooks/useForm'
import {
  createPersonnel,
  generatePassword,
  resetPersonnelPassword,
  updateCompanyPersonnel,
} from '../../../Redux/actions/companyPersonnel'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
import { useStyles } from '../../../Styles/createCompanyPersonnel.style'
import { entries, equal, keys, ternary } from '../../../Utils/javascript'
import { renderFormFields } from '../../../Utils/renderFormFields'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import DKTForm from '../../../Shared/DKTForm'

const CreateCompanyPersonnel = ({ open, handleClose, personnelToEdit }) => {
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const classes = useStyles()

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    resetField,
    resetForm,
    setFieldValue,
    setErrors,
    setValues,
  } = useForm(companyFieldList)
  const { isCreatingPersonnel, isUpdatingPersonnel, isNotifying } = useSelector(
    (state) => state.companyPersonnel,
  )

  const {
    isResettingPassword,
    createPersonnelError,
    updatePersonnelError,
    resetPasswordError,
  } = useSelector(({ companyPersonnel }) => companyPersonnel)

  const isLoading = useMemo(
    () => isCreatingPersonnel || !!(personnelToEdit && isUpdatingPersonnel),
    [isCreatingPersonnel, personnelToEdit, isUpdatingPersonnel],
  )
  const isDisabled =
    keys(personnelToEdit)?.length && !personnelToEdit?.isCompanyPersonnel
  const dispatch = useDispatch()

  useEffect(() => {
    if (createPersonnelError || updatePersonnelError || resetPasswordError) {
      setErrors(
        createPersonnelError || updatePersonnelError || resetPasswordError,
      )
    }
  }, [createPersonnelError, updatePersonnelError])

  useEffect(() => {
    const onSuccess = (generatedPassword) => {
      if (generatedPassword) setFieldValue('password', generatedPassword)
    }

    if (values.autoCreatePassword) {
      dispatch(generatePassword({ onSuccess }))
      return
    }
    resetField('password')
  }, [values.autoCreatePassword, resetField, dispatch, setFieldValue])

  useEffect(() => {
    if (!personnelToEdit) {
      resetForm()
    }
    if (!open) {
      setErrors({})
    }
  }, [open, personnelToEdit, resetForm])

  useEffect(() => {
    if (keys(personnelToEdit).length) {
      const res = keys(values)?.map(
        (name) => values[name] !== personnelToEdit[name],
      )
      setIsUnSavedChanges(
        ternary(
          res.some((val) => val === true),
          true,
          false,
        ),
      )
    } else if (open && Object.values(values).length) {
      setIsUnSavedChanges(true)
    }
  }, [values, personnelToEdit])

  const handleSave = useCallback(
    ({ notify = false } = {}) => {
      const flag = companyFieldList
      const isFormValid = handleSubmit(
        ternary(
          personnelToEdit,
          { excludeValidation: ['password'], flag },
          { flag },
        ),
      )
      if (!isFormValid) {
        dispatch(updateOnSaveStatus({ cancel: true }))
        return
      }
      setIsUnSavedChanges(false)
      const {
        firstName,
        lastName,
        email,
        workPhoneNumber,
        extension,
        cellPhone,
        password,
      } = values
      const personnelData = {
        firstName,
        lastName,
        email,
        workPhoneNumber,
        extension,
        cellPhone,
        password,
        isCompanyPersonnel: true,
      }

      if (personnelToEdit) {
        dispatch(
          updateCompanyPersonnel(
            { ...personnelData, id: personnelToEdit.id },
            notify,
            { onSuccess: handleClose },
          ),
        )
      } else {
        dispatch(
          createPersonnel(personnelData, notify, { onSuccess: handleClose }),
        )
      }
    },
    [personnelToEdit, handleClose, dispatch, handleSubmit, values],
  )

  const handleSaveAndNotify = () => {
    setIsUnSavedChanges(false)
    handleSave({ notify: true })
  }

  const handleResetPassword = () => {
    const isFormValid = handleSubmit({
      flag: { password: companyFieldList.password },
    })
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
      return
    }
    setIsUnSavedChanges(false)
    dispatch(
      resetPersonnelPassword(
        {
          email: personnelToEdit.email,
          temporaryPassword: values.password,
        },
        { onSuccess: handleClose },
      ),
    )
  }

  const dialogActions = (
    <>
      <DKTButton
        variant="outlined"
        onClick={ternary(personnelToEdit, handleResetPassword, handleSave)}
        disabled={
          isDisabled || isLoading || (personnelToEdit && isResettingPassword)
        }
      >
        {ternary(
          personnelToEdit,
          ternary(
            isResettingPassword,
            'Resetting password...',
            'Reset password',
          ),
          ternary(isLoading && !isNotifying, 'Saving...', 'Save'),
        )}
      </DKTButton>
      <DKTButton
        variant="contained"
        onClick={handleSaveAndNotify}
        disabled={isLoading || isDisabled}
        disableElevation
      >
        {ternary(
          isNotifying,
          'Saving...',
          ternary(personnelToEdit, 'Save', 'Save & Notify User'),
        )}
      </DKTButton>
    </>
  )

  useEffect(() => {
    if (personnelToEdit) {
      const updateValues = {}
      entries(personnelToEdit).forEach(([fieldName, fieldValue]) => {
        if (companyFieldList[fieldName]) {
          updateValues[fieldName] = ternary(personnelToEdit, fieldValue, '')
        }
      })
      setErrors({})
      setValues(updateValues)
    }
  }, [personnelToEdit, setFieldValue])

  const customFormControlFields = ({ name, formControl }) => ({
    type:
      equal(name, 'password') && !personnelToEdit ? 'text' : formControl.type,
    disabled: equal(name, 'password') && values.autoCreatePassword,
    ...ternary(isDisabled, { disabled: true }, {}),
  })
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    handleClose()
  }
  const handleCloseAddPhase = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    handleClose()
  }

  const onSave = () => {
    if (personnelToEdit) {
      if (values?.password) {
        handleResetPassword()
      } else {
        handleSave()
      }
    } else {
      handleSave()
    }
  }

  return (
    <>
      <DKTDialog
        open={open}
        handleClose={handleCloseAddPhase}
        title={ternary(
          personnelToEdit,
          'Update Company Personnel',
          'Company Personnel',
        )}
        actions={dialogActions}
        maxWidth="xs"
        className={classes.companyPersonalModal}
      >
        <DKTForm autoComplete="off">
          <Grid container spacing={2}>
            {renderFormFields({
              values,
              errors,
              handleChange,
              formFields: companyFieldList,
              customFormControlFields,
            })}
          </Grid>
        </DKTForm>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      <DKTConfirmNavigateShowModal
        isActive={isUnSavedWarningOpen}
        onConfirm={confirmSaveWarningModal}
        onCancel={handleCloseSaveWarningModal}
        onSave={onSave}
      />
      <DKTReactRouterPrompt isDirty={isUnSavedChanges} onSave={onSave} />
    </>
  )
}

export default memo(CreateCompanyPersonnel)
