import { InputAdornment } from '@mui/material'
import { lt } from '../Utils/javascript'

const percentageAdornment = <InputAdornment position="end">%</InputAdornment>

export const formFields = {
  scheduleOfValues: {
    scheduleOfValues: {
      type: 'text',
      label: 'Schedule of Values (SoV)',
      xs: 6,
      isCurrency: true,
    },
    siteWork: {
      type: 'text',
      label: 'Site Work',
      xs: 6,
      isCurrency: true,
    },
    hardCostPerUnit: {
      type: 'text',
      label: 'Hard Cost Per Unit',
      xs: 6,
      disabled: true,
      isCurrency: true,
      isLimitLess: true,
      validate: [
        (value) => lt(value, 999999999999999),
        'You have exceeded the limit, the value should be less than 999,999,999,999,999.',
      ],
    },
    structure: {
      type: 'text',
      label: 'Structure',
      xs: 6,
      isCurrency: true,
    },
    hardCostPerSf: {
      type: 'text',
      label: 'Hard Cost Per SF',
      xs: 6,
      disabled: true,
      isCurrency: true,
      isLimitLess: true,
      validate: [
        (value) => lt(value, 999999999999999),
        'You have exceeded the limit, the value should be less than 999,999,999,999,999.',
      ],
    },
    generalRequirements: {
      type: 'text',
      label: 'General Requirements',
      xs: 6,
      isCurrency: true,
    },
    prevailingWage: {
      type: 'select',
      label: 'Prevailing Wage',
      options: [],
      xs: 6,
      placeholder: 'Select',
      displayEmpty: true,
    },
    generalConditions: {
      type: 'text',
      label: 'General Conditions',
      xs: 6,
      isCurrency: true,
    },
    unionWage: {
      type: 'select',
      label: 'Union Wage',
      options: [],
      xs: 6,
      placeholder: 'Select',
      displayEmpty: true,
    },
    overheadProfit: {
      type: 'text',
      label: 'Overhead/Profit',
      xs: 6,
      isCurrency: true,
    },
  },
  hardCostContingency: {
    hardCostContingency: {
      type: 'text',
      label: 'Hard Cost Contingency (HCC)',
      xs: 6,
      isCurrency: true,
    },
    hardCostContingencyPerScheduleOfValues: {
      type: 'text',
      label: '% of SoV',
      xs: 6,
      disabled: true,
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      isLimitLess: true,
      validate: [
        (value) => lt(value, 999999999999999),
        'You have exceeded the limit, the value should be less than 999,999,999,999,999.',
      ],
    },
    contractorContingency: {
      type: 'text',
      label: 'Contractor Contingency',
      xs: 6,
      isCurrency: true,
    },
    contractorContingencyPerScheduleOfValues: {
      type: 'text',
      label: '% of SoV',
      xs: 6,
      disabled: true,
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      isLimitLess: true,
      validate: [
        (value) => lt(value, 999999999999999),
        'You have exceeded the limit, the value should be less than 999,999,999,999,999.',
      ],
    },
    totalContingency: {
      type: 'text',
      label: 'Total Contingency',
      xs: 6,
      disabled: true,
      isCurrency: true,
      isLimitLess: true,
      validate: [
        (value) => lt(value, 999999999999999),
        'You have exceeded the limit, the value should be less than 999,999,999,999,999.',
      ],
    },
    totalContingencyPerScheduleOfValues: {
      type: 'text',
      label: '% of SoV',
      xs: 6,
      disabled: true,
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      isLimitLess: true,
      validate: [
        (value) => lt(value, 999999999999999),
        'You have exceeded the limit, the value should be less than 999,999,999,999,999.',
      ],
    },
    softCostContingency: {
      type: 'text',
      label: 'Soft Cost Contingency (SCC)',
      xs: 6,
      isCurrency: true,
    },
    softCostContingencyPerScheduleOfValues: {
      type: 'text',
      label: '% of SoV',
      xs: 6,
      disabled: true,
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      isLimitLess: true,
      validate: [
        (value) => lt(value, 999999999999999),
        'You have exceeded the limit, the value should be less than 999,999,999,999,999.',
      ],
    },
    developmentHardCost: {
      type: 'text',
      label: 'Development Hard Cost',
      xs: 6,
      isCurrency: true,
    },
    developmentSoftCost: {
      type: 'text',
      label: 'Development Soft Cost',
      xs: 6,
      isCurrency: true,
    },
  },
  comments: {
    comments: {
      type: 'textarea',
      placeholder: 'Placeholder Text',
      rows: 6,
    },
  },
}

export const optionsFields = ['prevailingWage', 'unionWage']
