import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal, lt, ternary } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  ADD_BUDGET_CONTINGENCY_SUCCESS,
  DELETE_BUDGET_CONTINGENCY_FAILED,
  DELETE_BUDGET_CONTINGENCY_REQUESTED,
  DELETE_BUDGET_CONTINGENCY_SUCCESS,
  FETCH_BUDGET_CONTINGENCY_FAILED,
  FETCH_BUDGET_CONTINGENCY_LIST_FAILED,
  FETCH_BUDGET_CONTINGENCY_LIST_REQUESTED,
  FETCH_BUDGET_CONTINGENCY_LIST_SUCCESS,
  FETCH_BUDGET_CONTINGENCY_MAPPING_FAILED,
  FETCH_BUDGET_CONTINGENCY_MAPPING_REQUESTED,
  FETCH_BUDGET_CONTINGENCY_MAPPING_SUCCESS,
  FETCH_BUDGET_CONTINGENCY_REQUESTED,
  FETCH_BUDGET_CONTINGENCY_SUCCESS,
  SAVE_BUDGET_CONTINGENCY_FAILED,
  SAVE_BUDGET_CONTINGENCY_REQUESTED,
  SAVE_BUDGET_CONTINGENCY_SUCCESS,
  SET_BUDGET_CONTINGENCY_ID,
  RESET_BUDGET_CONTINGENCY,
} from '../constants/budgetContingency'
import { updateSaveStatusAction } from './confirmation'

const UNIQUE_CODE_PREFIX = 'BC'
const getUniqueCode = (code) =>
  `${UNIQUE_CODE_PREFIX}-${ternary(lt(code, 10), `0${code}`, code)}`

const fetchBudgetContingencyRequested = () => ({
  type: FETCH_BUDGET_CONTINGENCY_REQUESTED,
})
const fetchBudgetContingencySuccess = (payload) => ({
  type: FETCH_BUDGET_CONTINGENCY_SUCCESS,
  payload,
})
const fetchBudgetContingencyFailed = (payload) => ({
  type: FETCH_BUDGET_CONTINGENCY_FAILED,
  payload,
})

export const getBudgetContingency =
  (budgetContingencyId) => async (dispatch) => {
    dispatch(fetchBudgetContingencyRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.budgetContingency}${budgetContingencyId}`,
    })
    if (data) {
      dispatch(fetchBudgetContingencySuccess(data))
    }
    if (error) {
      dispatch(fetchBudgetContingencyFailed(error.response))
    }
  }

const fetchBudgetContingencyListRequested = () => ({
  type: FETCH_BUDGET_CONTINGENCY_LIST_REQUESTED,
})
const fetchBudgetContingencyListSuccess = (payload) => ({
  type: FETCH_BUDGET_CONTINGENCY_LIST_SUCCESS,
  payload,
})
const fetchBudgetContingencyListFailed = (payload) => ({
  type: FETCH_BUDGET_CONTINGENCY_LIST_FAILED,
  payload,
})

export const getBudgetContingencyList = (projectId) => async (dispatch) => {
  dispatch(fetchBudgetContingencyListRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.budgetContingency}?projectId=${projectId}`,
  })
  if (data) {
    const modifiedData = data?.map(({ id, uniqueCode }) => ({ id, uniqueCode }))
    dispatch(fetchBudgetContingencyListSuccess(modifiedData))
  }
  if (error) {
    dispatch(fetchBudgetContingencyListFailed(error.response))
  }
}

const saveBudgetContingencyRequested = () => ({
  type: SAVE_BUDGET_CONTINGENCY_REQUESTED,
})
const saveBudgetContingencySuccess = (payload) => ({
  type: SAVE_BUDGET_CONTINGENCY_SUCCESS,
  payload,
})
const saveBudgetContingencyFailed = (payload) => ({
  type: SAVE_BUDGET_CONTINGENCY_FAILED,
  payload,
})

export const saveBudgetContingency =
  (payload, budgetContingencyId, project) => async (dispatch) => {
    dispatch(saveBudgetContingencyRequested())
    const { data, error } = await api({
      method: method.patch,
      endPoint: `${apiEndPoints.budgetContingency}${budgetContingencyId}/?projectId=${project}`,
      data: {
        ...payload,
        project,
      },
      showToastMessage: true,
      toastMessage: 'Budget & Contingency updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(getBudgetContingencyMapping(project))
      dispatch(saveBudgetContingencySuccess(data))
    }
    if (error) {
      dispatch(saveBudgetContingencyFailed(error.response.data))
    }
  }

const addBudgetContingencySuccess = (payload) => ({
  type: ADD_BUDGET_CONTINGENCY_SUCCESS,
  payload,
})

export const addBudgetContingency = () => (dispatch, getState) => {
  const { budgetContingencyList } = getState().budgetContingency
  const id = new Date().getTime()
  const sortedBudgetContingencyList = budgetContingencyList?.sort((a, b) =>
    a?.uniqueCode > b?.uniqueCode ? 1 : -1,
  )
  const lastIndexBudgetContingency = sortedBudgetContingencyList
    ?.slice(-1)[0]
    ?.uniqueCode?.split('-')[1]
  dispatch(
    addBudgetContingencySuccess([
      ...(budgetContingencyList || []),
      {
        id,
        isNewBudgetContingency: true,
        uniqueCode: getUniqueCode(
          ternary(
            lastIndexBudgetContingency,
            +lastIndexBudgetContingency + 1,
            '1',
          ),
        ),
      },
    ]),
  )
  dispatch(setBudgetContingencyId(id, true))
}

export const setBudgetContingencyId = (
  budgetContingencyId,
  isNewBudgetContingency,
) => ({
  type: SET_BUDGET_CONTINGENCY_ID,
  payload: { budgetContingencyId, isNewBudgetContingency },
})

const fetchBudgetContingencyMappingRequested = () => ({
  type: FETCH_BUDGET_CONTINGENCY_MAPPING_REQUESTED,
})
const fetchBudgetContingencyMappingSuccess = (payload) => ({
  type: FETCH_BUDGET_CONTINGENCY_MAPPING_SUCCESS,
  payload,
})
const fetchBudgetContingencyMappingFailed = (payload) => ({
  type: FETCH_BUDGET_CONTINGENCY_MAPPING_FAILED,
  payload,
})

export const getBudgetContingencyMapping =
  (projectId) => async (dispatch, getState) => {
    const { budgetContingency, isNewBudgetContingency } =
      getState().budgetContingency
    dispatch(fetchBudgetContingencyMappingRequested())
    const { data, error } = await api({
      method: method.get,
      endPoint: `${
        apiEndPoints.budgetContingency
      }mapping/${projectId}/${ternary(
        budgetContingency?.uniqueCode && !isNewBudgetContingency,
        `?uniqueCode=${budgetContingency?.uniqueCode}`,
        '',
      )}`,
    })
    if (data) {
      const dataWithUpdatedKeys = data
        ?.sort((a, b) => (a?.uniqueCode > b?.uniqueCode ? 1 : -1))
        ?.map(({ uniqueCode, id } = {}) => ({
          label: uniqueCode,
          value: id.toString(),
        }))
      dispatch(fetchBudgetContingencyMappingSuccess(dataWithUpdatedKeys))
      if (!dataWithUpdatedKeys?.length && isNewBudgetContingency) {
        showToast(
          'Complete ‘Owner-Contractor’ prior to entering data on ‘Budget & Contingency’ page. ',
          'error',
          'top-right',
        )
      }
    }
    if (error) {
      dispatch(fetchBudgetContingencyMappingFailed(error.response.data))
    }
  }

// Delete budget contingency
const deleteBudgetContingencyRequested = () => ({
  type: DELETE_BUDGET_CONTINGENCY_REQUESTED,
})
export const deleteBudgetContingencySuccess =
  (payload) => (dispatch, getState) => {
    const { budgetContingencyList } = getState().budgetContingency
    const index = budgetContingencyList?.findIndex(({ id }) =>
      equal(id, payload?.budgetAndContingency[0]),
    )
    if (~index) {
      budgetContingencyList.splice(index, 1)
    }
    dispatch({
      type: DELETE_BUDGET_CONTINGENCY_SUCCESS,
      payload: budgetContingencyList,
      activeBudgetContingency: budgetContingencyList[0]?.id,
      isNewBudgetContingency: budgetContingencyList[0]?.isNewBudgetContingency,
    })
  }
const deleteBudgetContingencyFailed = (payload) => ({
  type: DELETE_BUDGET_CONTINGENCY_FAILED,
  payload,
})

export const deleteBudgetContingency =
  (payload, handleClose) => async (dispatch) => {
    dispatch(deleteBudgetContingencyRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.deleteBudgetContingency}`,
      method: method.delete,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Budget & contingency deleted successfully!',
    })
    if (data) {
      handleClose()
      dispatch(deleteBudgetContingencySuccess(payload))
      return
    }
    if (error) {
      dispatch(deleteBudgetContingencyFailed(error.response.data))
    }
  }

export const resetValues = () => ({
  type: RESET_BUDGET_CONTINGENCY,
})
