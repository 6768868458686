import { Stack } from '@mui/material'
import { memo, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { getReportChoices } from '../Redux/actions/reportChoices'
import { getReportList } from '../Redux/actions/reportMonitoring'
import DKTCircularProgress from '../Shared/DKTCircularProgress'
import { clientPortal, spinnerSize } from '../Utils/constant'

import { equal, ternary } from '../Utils/javascript'

const RestrictUnauthorizedReport = ({
  children,
  sideBarLinkId,
  requiredRoles,
}) => {
  const { slug: projectId, reportId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)
  const { portalType } = useSelector(({ auth }) => auth)

  useLayoutEffect(() => {
    if (
      equal(portalType, clientPortal) &&
      !requiredRoles?.includes(clientPortal)
    ) {
      navigate('/dashboard')
    }
  }, [requiredRoles, portalType, clientPortal])

  useLayoutEffect(() => {
    dispatch(
      getReportChoices({
        formNames: 'ownerContractor',
        projectId,
      }),
    )
  }, [dispatch, projectId])

  useLayoutEffect(() => {
    if (projectId && choices?.ownerContractor && reportId)
      dispatch(
        getReportList(projectId, choices?.ownerContractor[0]?.id, reportId),
      )
  }, [dispatch, projectId, choices?.ownerContractor])

  const isAccess = reportData?.disabledFields?.includes(sideBarLinkId)

  if (!reportData)
    return (
      <Stack height="100vh" alignItems="center" justifyContent="center">
        <DKTCircularProgress size={spinnerSize.lg} />
      </Stack>
    )

  return ternary(
    isAccess,
    <Navigate to={`/projects/${projectId}/project-reports/`} replace />,
    children,
  )
}

export default memo(RestrictUnauthorizedReport)
