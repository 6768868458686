import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  addBtn: {
    marginLeft: 0,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
  rightButton: {
    marginLeft: 'auto !important',
  },
  inlineLabel: {
    '&.MuiInputLabel-root': {
      fontWeight: 'bold',
      color: '#2D2D2D',
      fontSize: 14,
      marginBottom: 8,
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  budgetSummaryNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#646464',
      fontSize: 15,
      fontWeight: 500,
      marginBottom: 15,
      maxWidth: '38ch',
      marginInline: 'auto',
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  payDesc: {
    '& h6': {
      backgroundColor: '#C4C4C4',
      fontSize: 14,
      color: '#00000',
      padding: 10,
      marginTop: 10,
      weight: 500,
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  input: {
    '& .MuiInputBase-root': {
      marginTop: '0 !important',
      paddingLeft: '0 !important',
      border: 'none',
    },
  },
  budgetSummaryTrackerModal: {
    '& .MuiPaper-root': {
      width: 'calc(100% - 128px)',
      '& .MuiPaper-root': {
        padding: 0,
        width: '100%',
        '& div .MuiTable-root': {
          minWidth: 1370,
        },
      },
    },
  },
  summaryTableData: {
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        borderLeft: '1px solid #e0e0e0',
        '& .MuiTableCell-root': {
          borderRight: '1px solid #e0e0e0',
          padding: '4px 16px',
          '&:first-child': {
            padding: '4px 0',
          },
          '& .MuiFormControl-root': {
            display: 'block',
          },
          '& .MuiInputBase-root:nth-child(1)': {
            borderBottom: 0,
          },
          '& .MuiInputBase-root': {
            paddingLeft: '0px !important',
            '& .MuiSelect-select': {
              padding: '12px 14px',
            },
          },
          '& div': {
            justifyContent: 'center',
          },
        },
      },
    },
    '& div': {
      '& .MuiTableFooter-root': {
        '& .MuiTableCell-root': {
          borderBottom: '1px solid #e0e0e0',
          padding: '10px 16px',
          '& .MuiInputBase-root': {
            paddingLeft: '0px !important',
          },
        },
      },
    },
  },
  summaryTableNoData: {
    '& .MuiTableCell-root': {
      padding: '16px !important',
    },
  },
  budgetSummaryTable: {
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          '&:first-child': {
            padding: '0 10px',
          },
        },
      },
    },
    '& .MuiTableCell-body': {
      padding: '8px 10px',
    },
    '& .isCheckBox': {
      '& table': {
        '& .MuiTableHead-root': {
          '& .MuiTableRow-root': {
            '& .MuiTableCell-root': {
              padding: '8px 10px',
            },
          },
        },
      },
    },
  },

  coCommentTextEditor: {
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      height: 250,
    },
  },
  pcoCommentTextEditor: {
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      height: 260,
    },
  },
  storedCommentTextEditor: {
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      height: 310,
    },
  },
  radioLabel: {
    '& .MuiButtonBase-root': {
      paddingRight: '4px',
    },
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
  },
  projectDesc: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 305,
    height: 305,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '210px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '210px',
      height: 'auto',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },
  labelledCheckbox: {
    '&.MuiFormControl-root': {
      marginLeft: '1px',
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: 'auto',
    },
    '& .MuiInputLabel-root': {
      color: '#3E3E3E',
      position: 'inherit',
      fontSize: 13,
      transform: 'translate(0)',
      whiteSpace: 'normal',
      width: 'max-content',
    },
  },
  g703Table: {
    '& div': {
      '& tbody': {
        '& tr': {
          '& td': {
            padding: '7px 16px',
            '&:first-child': {
              padding: '7px 0 7px 4px',
            },
          },
        },
      },
      '& tfoot': {
        '& td': {
          padding: '11px 16px',
        },
      },
    },
  },
}))
