import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  notificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  assignedRole: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&.MuiTypography-root': {
      fontSize: '14px',
    },
  },
  assignedRoleDisabled: {
    color: theme.palette.gray.extraDark,
    '&.MuiTypography-root': {
      fontSize: '14px',
    },
  },
  projectSettingOuter: {
    '& .MuiAccordion-root': {
      marginBottom: 5,
      '& .MuiAccordionSummary-content': {
        margin: [[20, 0]],
      },
      '&.Mui-expanded': {
        '& .MuiAccordionSummary-root': {
          backgroundColor: '#1564FF',
          color: '#fff',
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: '#fff',
          },
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: 16,
      },
    },
  },
  projectSettingAccordion: {
    '&.MuiAccordionSummary-root': {
      backgroundColor: '#C6C6C6',
      color: 'black',
      fontSize: '14px',
    },
  },
  limitationTextarea: {
    '& textarea': {
      resize: 'vertical',
      padding: [[10, 18]],
      fontStyle: 'italic',
      fontFamily: 'Roboto',
      fontWeight: 500,
      borderRadius: 0,
      borderColor: '#C4C4C4',
      color: '#646464',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  limitation: {
    border: '1px solid #C4C4C4',
    overflow: 'hidden',
    resize: 'vertical',
    position: 'relative',
    minHeight: 250,
    height: 450,
    '& h6': {
      backgroundColor: '#E5E5E5',
      fontSize: 14,
      color: '#333333',
      padding: 10,
    },
    '& .ck-sticky-panel': {
      padding: 10,
      marginBottom: 20,
      bottom: 5,
      width: '100%',
      '& .ck-toolbar': {
        border: 0,
        boxShadow: '0 0 6px rgb(0 0 0 / 20%)',
        backgroundColor: '#fff',
        '& .ck-dropdown__panel_se': {
          bottom: '100%',
          top: 'auto',
        },
      },
    },
    '& .ck-editor': {
      display: 'flex',
      flexDirection: 'column-reverse',
      height: '100%',
      justifyContent: 'space-between',
    },
    '& .ck-editor__main': {
      height: '100%',
      overflow: 'auto',
      minHeight: '150px',
      maxHeight: 'fit-content',
    },
    '& .ck-content': {
      border: '0 !important',
      boxShadow: 'none !important',
      overflow: 'auto',
      minHeight: '150px',
      '& p': {
        marginBottom: '20px !important',
      },
    },
    '& .ck-sticky-panel .ck-sticky-panel__content_sticky': {
      position: 'static !important',
    },
    '& .ck-sticky-panel__content': {
      border: 'none !important',
    },
  },

  settingTable: {
    '& table': {
      '& tbody': {
        '& td': {
          height: 56.5,
          boxSizing: 'border-box',
        },
      },
    },
  },
}))
