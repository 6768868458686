import { Delete } from '@mui/icons-material'
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Stack,
  TableFooter,
  TableRow,
  TableCell,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  storedMaterialOptions,
  storedMaterialChoices,
  storedMaterialColumns,
  initialStoredMaterial,
} from '../../../Description/trackers.description'
import { getProjectChoices } from '../../../Redux/actions/choices'
import {
  deleteStoredMaterialTrackers,
  getStoredMaterialTracker,
  saveStoredMaterialTracker,
} from '../../../Redux/actions/reportTrackers'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTCurrencyTextField from '../../../Shared/DKTCurrencyTextField'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTInput from '../../../Shared/DKTInput'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTSelect from '../../../Shared/DKTSelect'
import { useStyles } from '../../../Styles/budgetSummary.style'
import { SA, publish } from '../../../Utils/constant'
import { equal, keys, ternary } from '../../../Utils/javascript'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTForm from '../../../Shared/DKTForm'

export const useTableBodyStyle = makeStyles(() => ({
  stateDropdown: {
    marginTop: '0 !important',
    height: '45px',
    '& .MuiSelect-select': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  inputBorder: {
    marginTop: '0 !important',
    '& .MuiInputBase-root': {
      marginTop: '0 !important',
      paddingLeft: '16px !important',
      border: 'none',
    },
  },
}))

const CustomBody = memo(
  ({ columnName, value, readOnly, rowIndex, handleChange }) => {
    const classes = useTableBodyStyle()
    const { choices } = useSelector(({ projectChoices }) => projectChoices)
    switch (columnName) {
      case 'description':
        return (
          <DKTInput
            type="text"
            value={value}
            disabled={readOnly}
            onChange={(e) => handleChange(rowIndex, columnName, e.target.value)}
            placeholder="type text"
            showLabel={false}
            className={classes.inputBorder}
          />
        )
      case 'amount':
        return (
          <DKTCurrencyTextField
            name={columnName}
            value={value}
            disabled={readOnly}
            currencySymbol="$"
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(event, value) =>
              handleChange(rowIndex, columnName, value)
            }
            showLabel={false}
            className={classes.inputBorder}
          />
        )
      default:
        return (
          <DKTSelect
            value={value}
            className={classes.stateDropdown}
            disabled={readOnly}
            onChange={(e) => handleChange(rowIndex, columnName, e.target.value)}
            options={choices[columnName] || []}
            placeholder="Select"
            displayEmpty
            showLabel={false}
            sx={{ margin: 0 }}
          />
        )
    }
  },
)

const StoredMaterial = ({ open, onClose, ownerContractor, uniqueCode }) => {
  const {
    storedMaterialTracker,
    isSavingStoredMaterialTracker,
    isDeletingStoredMaterialTrackers,
    isFetchingStoredMaterialTracker,
  } = useSelector(({ reportTrackers }) => reportTrackers)
  const { reportId } = useParams()
  const [data, setData] = useState(
    storedMaterialTracker?.data || [
      {
        ...initialStoredMaterial,
        report: reportId,
        ownerContractor,
        uniqueCode,
      },
    ],
  )
  const [dataTotal, setDataTotal] = useState({
    amount: storedMaterialTracker?.total?.amount || 0,
  })
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const recordsToRemove = useRef([])
  const setSelectedRows = useRef(() => {})
  const { slug: projectId } = useParams()
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)

  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+projectId]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission =
    isGlobalViewer ||
    isProjectViewer ||
    ternary(
      reportData?.isSecondLastReport,
      equal(reportData?.lastReportStatus, publish),
      !reportData?.isLastReport,
    )

  useEffect(() => {
    dispatch(getProjectChoices({ fieldName: storedMaterialChoices.join(',') }))
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getStoredMaterialTracker({
        uniqueCode,
        reportId,
        ocId: ownerContractor,
        projectId,
      }),
    )
  }, [dispatch, uniqueCode, ownerContractor])

  useEffect(() => {
    setData(
      storedMaterialTracker?.data || [
        {
          ...initialStoredMaterial,
          report: reportId,
          ownerContractor,
          uniqueCode,
          uniqueId: new Date().valueOf(), // To delete local row
        },
      ],
    )
    setDataTotal({
      amount: storedMaterialTracker?.total?.amount || 0,
    })
  }, [storedMaterialTracker])

  const closeDeleteNotification = () => setIsDeleteNotificationOpen(false)
  const openDeleteNotification = () => setIsDeleteNotificationOpen(true)

  const handleChange = useCallback(
    (row, columnName, value) => {
      setIsUnSavedChanges(true)
      setData((prevData) => {
        const clonedData = [...prevData]
        if (clonedData[row]) {
          clonedData[row][columnName] = value
        }

        if (equal(columnName, 'amount')) {
          setDataTotal({
            amount: clonedData.reduce(
              (total, rowData) => total + +rowData[columnName] || 0,
              0,
            ),
          })
        }
        return clonedData
      })
    },
    [dataTotal],
  )

  const addRow = () => {
    setData((prevData) => [
      ...prevData,
      {
        ...initialStoredMaterial,
        report: reportId,
        ownerContractor,
        uniqueCode,
        uniqueId: new Date().valueOf(), // To delete local row
      },
    ])
  }

  const customBodyRender = (columnName, readOnly, value, tableData) => {
    const { rowIndex } = tableData
    return (
      <DKTForm autoComplete="off">
        <CustomBody
          columnName={columnName}
          value={value}
          readOnly={readOnly || hasOnlyViewPermission}
          rowIndex={rowIndex}
          handleChange={handleChange}
        />
      </DKTForm>
    )
  }
  const columns = storedMaterialColumns.map((column) => ({
    ...column,
    options: {
      ...column.options,
      customBodyRender: (...props) =>
        customBodyRender(column.name, false, ...props),
    },
  }))

  const customToolbarSelect = (
    selectedRows,
    displayData,
    setSelectedTableRows,
  ) => {
    const selectedRecords = keys(selectedRows.lookup)
    const selectedRecordIds = selectedRecords.map(
      (rowToDelete) => data[rowToDelete]?.id ?? data[rowToDelete]?.uniqueId,
    )
    setSelectedRows.current = setSelectedTableRows
    const setRecordsToRemove = () => {
      recordsToRemove.current = selectedRecordIds
    }
    return (
      <Box>
        <IconButton
          sx={{ mr: 1 }}
          onClick={() => {
            setRecordsToRemove()
            openDeleteNotification()
          }}
        >
          <Delete color="error" />
        </IconButton>
      </Box>
    )
  }

  const customTableBodyFooterRender = () => (
    <TableFooter>
      <TableRow>
        {hasOnlyViewPermission ||
          (!equal(storedMaterialOptions.selectableRows, 'none') && (
            <TableCell></TableCell>
          ))}
        {columns
          .filter(({ options: { display = true } }) => display)
          .map((column) => (
            <TableCell>
              {equal(column.name, 'amount') && (
                <CustomBody
                  columnName={column.name}
                  value={dataTotal[column.name]}
                  readOnly
                  handleChange={() => null}
                  isLimitLess
                />
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableFooter>
  )

  const options = {
    ...storedMaterialOptions,
    customToolbarSelect,
    customTableBodyFooterRender,
    rowsPerPage: 5,
    selectableRows: ternary(
      hasOnlyViewPermission,
      'none',
      storedMaterialOptions.selectableRows || 'multiple',
    ),
  }
  const filterData = data?.map((value) => ({
    ...value,
    report: value?.report || reportId,
  }))
  const handleSave = () => {
    setIsUnSavedChanges(false)
    dispatch(
      saveStoredMaterialTracker(
        filterData,
        projectId,
        reportId,
        ownerContractor,
        dataTotal,
      ),
    )
  }

  const handleDelete = () => {
    dispatch(
      deleteStoredMaterialTrackers({
        storedMaterialTrackersToDelete: recordsToRemove.current,
        reportId,
        tableData: data,
        ocId: ownerContractor,
        projectId,
      }),
    ).then(() => {
      setSelectedRows.current([])
      closeDeleteNotification()
    })
  }

  const deleteNotificationActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteNotification}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleDelete}
        disabled={isDeletingStoredMaterialTrackers}
      >
        {ternary(
          isDeletingStoredMaterialTrackers,
          <DKTCircularProgress color={theme.palette.gray.dark} />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
  }

  const handleCloseStoredMaterial = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
  }

  return (
    <DKTDialog
      title="Stored Material Tracker"
      open={open}
      showCloseButton
      handleClose={handleCloseStoredMaterial}
      maxWidth="xl"
      className={classes.budgetSummaryTrackerModal}
    >
      {equal(isFetchingStoredMaterialTracker, true) ? (
        <Stack alignItems="center" justifyContent="center">
          <DKTCircularProgress />
        </Stack>
      ) : equal(isFetchingStoredMaterialTracker, 'FAILED') ? (
        <Stack alignItems="center" justifyContent="center">
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Stored Material Tracker
            data. Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <DKTMuiDataTable
            columns={columns}
            options={options}
            data={data || [{}]}
            className={ternary(
              data?.length,
              classes.summaryTableData,
              classNames(classes.summaryTableData, classes.summaryTableNoData),
            )}
          />
          <DKTButton
            variant="text"
            startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
            onClick={addRow}
            disabled={hasOnlyViewPermission}
          >
            Add row
          </DKTButton>
          <Box textAlign="right">
            <DKTButton
              onClick={handleSave}
              disabled={hasOnlyViewPermission || isSavingStoredMaterialTracker}
            >
              {ternary(
                isSavingStoredMaterialTracker,
                <DKTCircularProgress color={theme.palette.gray.dark} />,
                'Save',
              )}
            </DKTButton>
          </Box>
        </>
      )}
      <DKTDialog
        open={isDeleteNotificationOpen}
        handleClose={closeDeleteNotification}
        title="&nbsp;"
        actions={deleteNotificationActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      {!hasOnlyViewPermission && (
        <>
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={handleSave}
          />
          <DKTReactRouterPrompt
            isDirty={isUnSavedChanges}
            onSave={handleSave}
          />
        </>
      )}
    </DKTDialog>
  )
}
export default memo(StoredMaterial)
