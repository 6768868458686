import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  reportListTable: {
    '& tbody': {
      '& td': {
        '& .MuiTypography-root': {
          fontSize: 14,
        },
        '& .MuiButtonBase-root': {
          padding: 0,
          paddingLeft: 9,
          height: 'auto',
        },
      },
    },
  },
  projectList: {
    maxHeight: 468,
    overflowY: 'auto',
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))
