import { Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { actionTitles } from '../../../Description/admin.description'
import {
  columns,
  options,
} from '../../../Description/companyPersonnel.description'
import { InnerLayout } from '../../../Layout/admin'
import {
  deleteCompanyPersonnel,
  fetchCompanyPersonnel,
} from '../../../Redux/actions/companyPersonnel'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTTableViewCol from '../../../Shared/DKTTableViewCol'
import { useStyles } from '../../../Styles/companyPersonnel.style'
import { equal, keys, ternary } from '../../../Utils/javascript'
import CreateCompanyPersonnel from './CreateCompanyPersonnel'

const CompanyPersonnel = () => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [data, setData] = useState()
  const [personnelToEdit, setPersonnelToEdit] = useState(null)

  const [deleteNotificationOpen, setOpenDeleteNotification] =
    React.useState(false)

  const personnelToRemove = useRef(null)
  const setSelectedRows = useRef(() => {})
  const dispatch = useDispatch()
  const { companyPersonnelList } = useSelector(
    (state) => state.companyPersonnel,
  )

  useEffect(() => {
    dispatch(fetchCompanyPersonnel())
  }, [dispatch])

  useEffect(() => {
    setData(companyPersonnelList)
  }, [companyPersonnelList])

  const dataForTable = data?.map((value) => {
    value = {
      ...value,
      isCompanyPersonnel: ternary(value?.isCompanyPersonnel, 'User', 'Admin'),
    }
    return value
  })
  const handleClickOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = () => {
    setOpen(false)
    setPersonnelToEdit(null)
    setOpenDeleteNotification(false)
  }

  const handleDeleteTableRow = () => {
    dispatch(deleteCompanyPersonnel(personnelToRemove.current))
    setOpenDeleteNotification(false)
    setSelectedRows.current([])
  }

  const actions = () => (
    <Stack direction="row" gap={2}>
      <DKTButton onClick={handleClickOpen}>Create New</DKTButton>
    </Stack>
  )

  const customToolbarSelect = useCallback(
    (selectedRows, displayData, setSelectedTableRows) => {
      const selectedPersonnel = keys(selectedRows.lookup)
      const selectedPersonnelIds = selectedPersonnel.map(
        (rowToDelete) => companyPersonnelList[rowToDelete]?.id,
      )
      setSelectedRows.current = setSelectedTableRows
      const setPersonnelToRemove = () => {
        personnelToRemove.current = selectedPersonnelIds
      }

      return (
        <Box>
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => {
              setPersonnelToRemove()
              setOpenDeleteNotification(true)
            }}
          >
            <Delete color="error" />
          </IconButton>
        </Box>
      )
    },
    [companyPersonnelList],
  )

  const tableOptions = {
    ...options,
    customToolbarSelect,
    isRowSelectable: (dataIndex) =>
      companyPersonnelList[dataIndex]?.isCompanyPersonnel,
    selectableRows: 'multiple',
  }

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteTableRow}>
        Yes
      </DKTButton>
    </>
  )

  const handleEditPersonnel = useCallback(
    (personnelId) => {
      const personnel = companyPersonnelList.find((personnel) =>
        equal(personnel.id, personnelId),
      )
      const clonePersonnel = { ...personnel, password: '' }
      setPersonnelToEdit(clonePersonnel)
      handleClickOpen()
    },
    [handleClickOpen, companyPersonnelList],
  )

  const customBodyRender = useCallback(
    (value, tableMeta) => (
      <Typography
        className={classes.companyPersonnelName}
        onClick={() => handleEditPersonnel(tableMeta.rowData[0])}
      >
        {value}
      </Typography>
    ),
    [handleEditPersonnel, classes],
  )

  const tableColumns = columns.map((personnel) =>
    ternary(
      equal(personnel.name, 'firstName') || equal(personnel.name, 'lastName'),
      {
        ...personnel,
        options: {
          ...personnel.options,
          customBodyRender,
        },
      },
      personnel,
    ),
  )

  const components = {
    TableViewCol: DKTTableViewCol,
  }

  return (
    <InnerLayout
      actionTitle={actionTitles.companyPersonnel}
      contentTitle="COMPANY Personnel"
      actions={actions}
    >
      <Box className={classes.companyPersonnelTable}>
        <DKTMuiDataTable
          data={dataForTable}
          options={tableOptions}
          columns={tableColumns}
          className={classes.companyPersonnelTable}
          components={components}
        />
      </Box>

      <CreateCompanyPersonnel
        open={open}
        handleClose={handleClose}
        personnelToEdit={personnelToEdit}
      />

      <DKTDialog
        open={deleteNotificationOpen}
        handleClose={handleClose}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
    </InnerLayout>
  )
}

export default CompanyPersonnel
