import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { checkIncludes, equal, length } from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  CLEAR_CONSTRUCTION_SUMMARY,
  CREATE_CONSTRUCTION_SUMMARY_FAILED,
  CREATE_CONSTRUCTION_SUMMARY_REQUESTED,
  CREATE_CONSTRUCTION_SUMMARY_SUCCESS,
  CREATE_UPDATE_MANPOWER_TRACKER_FAILED,
  CREATE_UPDATE_MANPOWER_TRACKER_REQUESTED,
  CREATE_UPDATE_MANPOWER_TRACKER_SUCCESS,
  DELETE_MANPOWER_TRACKER_FAILED,
  DELETE_MANPOWER_TRACKER_REQUESTED,
  DELETE_MANPOWER_TRACKER_SUCCESS,
  FETCH_CONSTRUCTION_SUMMARY_FAILED,
  FETCH_CONSTRUCTION_SUMMARY_REQUESTED,
  FETCH_CONSTRUCTION_SUMMARY_SUCCESS,
  FETCH_MANPOWER_TRACKER_FAILED,
  FETCH_MANPOWER_TRACKER_REQUESTED,
  FETCH_MANPOWER_TRACKER_SUCCESS,
  IMPORT_FROM_LAST_REPORT_FAILED,
  IMPORT_FROM_LAST_REPORT_REQUESTED,
  IMPORT_FROM_LAST_REPORT_SUCCESS,
  IMPORT_PHASE_BASED_COMMENTS_FAILED,
  IMPORT_PHASE_BASED_COMMENTS_REQUESTED,
  IMPORT_PHASE_BASED_COMMENTS_SUCCESS,
  UPDATE_CONSTRUCTION_SUMMARY_FAILED,
  UPDATE_CONSTRUCTION_SUMMARY_REQUESTED,
  UPDATE_CONSTRUCTION_SUMMARY_SUCCESS,
} from '../constants/constructionSummary'
import { updateSaveStatusAction } from './confirmation'

// Fetch construction summary data
export const fetchConstructionSummaryDataRequested = () => ({
  type: FETCH_CONSTRUCTION_SUMMARY_REQUESTED,
})
export const fetchConstructionSummaryDataSuccess = (payload) => ({
  type: FETCH_CONSTRUCTION_SUMMARY_SUCCESS,
  payload,
})
const fetchConstructionSummaryDataFailed = (payload) => ({
  type: FETCH_CONSTRUCTION_SUMMARY_FAILED,
  payload,
})

export const getConstructionSummary =
  (reportId, ocId, projectId) => async (dispatch) => {
    dispatch(fetchConstructionSummaryDataRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.constructionSummary}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
      showToastMessage: false,
      toastMessage: 'Construction summary fetch successfully!',
    })
    if (data) {
      dispatch(fetchConstructionSummaryDataSuccess(data))
      return
    }
    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(
          fetchConstructionSummaryDataSuccess({
            freeForm: '',
            narrativeBased: [],
            phaseBased: null,
          }),
        )
      }
      dispatch(fetchConstructionSummaryDataFailed(error.response))
    }
  }
// Create construction summary
const createConstructionSummaryRequested = () => ({
  type: CREATE_CONSTRUCTION_SUMMARY_REQUESTED,
})
const createConstructionSummarySuccess = (payload) => ({
  type: CREATE_CONSTRUCTION_SUMMARY_SUCCESS,
  payload,
})
const createConstructionSummaryFailed = (payload) => ({
  type: CREATE_CONSTRUCTION_SUMMARY_FAILED,
  payload,
})

export const createConstructionSummary =
  (payload, reportId, ocId, projectId) => async (dispatch) => {
    dispatch(createConstructionSummaryRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.constructionSummary}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Construction summary created successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(createConstructionSummarySuccess(data))
      return
    }
    if (error) {
      dispatch(createConstructionSummaryFailed(error.response.data))
    }
  }

// Update construction summary
const updateConstructionSummaryRequested = () => ({
  type: UPDATE_CONSTRUCTION_SUMMARY_REQUESTED,
})
const updateConstructionSummarySuccess = (payload) => ({
  type: UPDATE_CONSTRUCTION_SUMMARY_SUCCESS,
  payload,
})
const updateConstructionSummaryFailed = (payload) => ({
  type: UPDATE_CONSTRUCTION_SUMMARY_FAILED,
  payload,
})

export const updateConstructionSummary =
  (payload, constructionSummaryId, reportId, ocId, projectId) =>
  async (dispatch) => {
    dispatch(updateConstructionSummaryRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.constructionSummary}${constructionSummaryId}/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.patch,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Construction summary updated successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)
    if (data) {
      dispatch(updateConstructionSummarySuccess(data))
      return
    }
    if (error) {
      dispatch(updateConstructionSummaryFailed(error.response.data))
    }
  }

// Import from last report for construction summary
const importFormLastReportRequested = (query) => ({
  type: IMPORT_FROM_LAST_REPORT_REQUESTED,
  payload: query,
})

const importFormLastReportSuccess = (payload) => ({
  type: IMPORT_FROM_LAST_REPORT_SUCCESS,
  payload,
})
const importFormLastReportFailed = (payload) => ({
  type: IMPORT_FROM_LAST_REPORT_FAILED,
  payload,
})
export const importFromLastReport =
  ({ query, projectId, ocId, reportId, onSuccess = () => null }) =>
  async (dispatch) => {
    const [firstLetter] = query
    const restWord = query.substr(1, length(query) - 1)
    const pascalCaseQuery = `${firstLetter.toUpperCase()}${restWord}`
    dispatch(importFormLastReportRequested(query))

    const { data, error } = await api({
      endPoint: `${apiEndPoints.importLastReport}?query=${query}&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
      method: method.get,
    })

    if (data) {
      dispatch(
        importFormLastReportSuccess({
          pascalCaseQuery,
          query,
          data,
        }),
      )
      onSuccess(data[query])
    }
    if (error) {
      showToast('Failed to import comments')
      dispatch(
        importFormLastReportFailed({
          pascalCaseQuery,
          query,
          data: error.response.data,
        }),
      )
    }
  }

// Import phase based comments from last report
const importPhaseBasedCommentsRequested = () => ({
  type: IMPORT_PHASE_BASED_COMMENTS_REQUESTED,
})
const importPhaseBasedCommentsSuccess = (payload) => ({
  type: IMPORT_PHASE_BASED_COMMENTS_SUCCESS,
  payload,
})
const importPhaseBasedCommentsFailed = (payload) => ({
  type: IMPORT_PHASE_BASED_COMMENTS_FAILED,
  payload,
})
export const importPhaseBasedComments =
  ({ projectId, ocId, reportId, onSuccess = () => null }) =>
  async (dispatch) => {
    dispatch(importPhaseBasedCommentsRequested())

    const { data, error } = await api({
      endPoint: `${apiEndPoints.importLastReport}?query=constructionSummaryComments&projectId=${projectId}&ocId=${ocId}&reportId=${reportId}`,
      method: method.get,
    })
    if (data) {
      dispatch(importPhaseBasedCommentsSuccess(data))
      onSuccess(data?.constructionSummaryComments)
    }
    if (error) {
      showToast('Failed to import comments')
      dispatch(importPhaseBasedCommentsFailed(error.response.data))
    }
  }

// clear construction summary
export const clearConstructionSummary = () => ({
  type: CLEAR_CONSTRUCTION_SUMMARY,
})

// Labor tracker
export const fetchManpowerTrackerRequested = () => ({
  type: FETCH_MANPOWER_TRACKER_REQUESTED,
})
export const fetchManpowerTrackerSuccess = (payload) => ({
  type: FETCH_MANPOWER_TRACKER_SUCCESS,
  payload,
})
const fetchManpowerTrackerFailed = (payload) => ({
  type: FETCH_MANPOWER_TRACKER_FAILED,
  payload,
})

export const getManpowerTracker =
  (reportId, ocId, projectId) => async (dispatch) => {
    dispatch(fetchManpowerTrackerRequested())
    const { data, error } = await api({
      endPoint: `${apiEndPoints.manpowerTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.get,
    })

    if (data) {
      dispatch(fetchManpowerTrackerSuccess(data))
      return
    }
    if (error) {
      dispatch(fetchManpowerTrackerFailed(error.response))
    }
  }

const createUpdateManpowerTrackerRequested = () => ({
  type: CREATE_UPDATE_MANPOWER_TRACKER_REQUESTED,
})
const createUpdateManpowerTrackerSuccess = (payload) => ({
  type: CREATE_UPDATE_MANPOWER_TRACKER_SUCCESS,
  payload,
})
const createUpdateManpowerTrackerFailed = (payload) => ({
  type: CREATE_UPDATE_MANPOWER_TRACKER_FAILED,
  payload,
})

export const createUpdateManpowerTracker =
  (payload, reportId, ocId, projectId) => async (dispatch, getState) => {
    dispatch(createUpdateManpowerTrackerRequested())
    const { manpowerTrackerData } = getState().constructionSummary
    const { data, error } = await api({
      endPoint: `${apiEndPoints.manpowerTracker}?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: manpowerTrackerData?.length ? method.patch : method.post,
      data: payload,
      showToastMessage: true,
      toastMessage: 'Labor tracker saved successfully!',
    })
    setTimeout(() => {
      dispatch(updateSaveStatusAction(data, error))
    }, 700)

    if (data) {
      dispatch(createUpdateManpowerTrackerSuccess(data))
      return
    }
    if (error) {
      dispatch(createUpdateManpowerTrackerFailed(error.response.data))
    }
  }

// Delete tracker
const deleteManpowerTrackerRequested = () => ({
  type: DELETE_MANPOWER_TRACKER_REQUESTED,
})
export const deleteManpowerTrackerSuccess = (payload) => ({
  type: DELETE_MANPOWER_TRACKER_SUCCESS,
  payload,
})
const deleteManpowerTrackerFailed = (payload) => ({
  type: DELETE_MANPOWER_TRACKER_FAILED,
  payload,
})

export const deleteManpowerTracker =
  ({ manpowerToDelete, reportId, ocId, projectId, setData }) =>
  async (dispatch, getState) => {
    dispatch(deleteManpowerTrackerRequested())
    const { status, error } = await api({
      endPoint: `${apiEndPoints.manpowerTracker}delete/?reportId=${reportId}&ocId=${ocId}&projectId=${projectId}`,
      method: method.delete,
      data: { manpowerTracker: manpowerToDelete },
      showToastMessage: true,
      toastMessage: 'Labor tracker deleted successfully!',
    })
    if (status) {
      const { manpowerTrackerData } = getState().constructionSummary
      const manpowerTrackerDataAfterDeletion = manpowerTrackerData.filter(
        ({ id }) => !checkIncludes(id, manpowerToDelete),
      )
      setData(manpowerTrackerDataAfterDeletion || [])
      dispatch(deleteManpowerTrackerSuccess(manpowerTrackerDataAfterDeletion))
    }
    if (error) {
      dispatch(deleteManpowerTrackerFailed(error.response.data))
    }
  }
