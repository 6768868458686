import React, { useCallback, useEffect } from 'react'
import { InfoWindowF, MarkerF } from '@react-google-maps/api'
import { useDispatch, useSelector } from 'react-redux'
import {
  setMarkerHover,
  clearProjectData,
} from '../../Redux/actions/globalDashboard'
import { equal } from '../../Utils/javascript'
import gray from '../../Assets/gray-dot.png'
import blue from '../../Assets/blue-dot.png'
import orange from '../../Assets/orange-dot.png'

const Index = ({ position, status, id, projectName, showTooltip = true }) => {
  const dispatch = useDispatch()
  const { projectId, activeMarker } = useSelector(
    ({ globalDashboard }) => globalDashboard,
  )

  useEffect(() => {
    if (projectId && showTooltip) {
      dispatch(setMarkerHover(projectId))
    }
  }, [projectId])

  const handleActiveMarker = (marker) => {
    if (showTooltip) dispatch(setMarkerHover(marker))
  }
  const handleMouseOut = () => {
    if (showTooltip) dispatch(setMarkerHover(null))
  }

  const handleInfoWindowClose = () => {
    dispatch(clearProjectData())
  }
  const getMarkerColor = useCallback(
    (status) => {
      let color = ''
      if (status) {
        if (equal(status, 'Active')) {
          color = orange
        }
        if (equal(status, 'Completed')) {
          color = blue
        }
        if (equal(status, 'New')) {
          color = gray
        }
      }
      return color
    },
    [status],
  )

  return (
    <MarkerF
      position={position}
      icon={{
        url: getMarkerColor(status),
      }}
      options={{ disableAutoPan: false }}
      cursor="pointer"
      onMouseOver={() => handleActiveMarker(id)}
      onMouseOut={() => handleMouseOut()}
    >
      {equal(activeMarker, id) ? (
        <InfoWindowF onCloseClick={handleInfoWindowClose} position={position}>
          <div>{projectName}</div>
        </InfoWindowF>
      ) : null}
    </MarkerF>
  )
}

export default React.memo(Index)
