import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal } from '../../Utils/javascript'
import {
  FETCH_TOTAL_COUNTS_REQUESTED,
  FETCH_TOTAL_COUNTS_SUCCESS,
  FETCH_TOTAL_COUNTS_FAILED,
  FETCH_PROJECTS_LIST_REQUESTED,
  FETCH_PROJECTS_LIST_SUCCESS,
  FETCH_PROJECTS_LIST_FAILED,
  ADD_REMOVE_FAVORITE_REQUEST,
  ADD_REMOVE_FAVORITE_SUCCESS,
  ADD_REMOVE_FAVORITE_FAILED,
  FETCH_FILTER_PROJECTS_REQUESTED,
  FETCH_FILTER_PROJECTS_SUCCESS,
  FETCH_FILTER_PROJECTS_FAILED,
  FETCH_SEARCH_PROJECT_REQUESTED,
  FETCH_SEARCH_PROJECT_SUCCESS,
  FETCH_SEARCH_PROJECT_FAILED,
  CLEAR_PROJECT_DATA,
  SET_PROJECT_DATA,
  SET_MARKER_HOVER,
  CLEAR_MARKER_HOVER,
} from '../constants/globalDashboard'

// fetch total counts of tiles
const fetchTotalCountsOfTilesRequested = () => ({
  type: FETCH_TOTAL_COUNTS_REQUESTED,
})
const fetchTotalCountsOfTilesSuccess = (payload) => ({
  type: FETCH_TOTAL_COUNTS_SUCCESS,
  payload,
})
const fetchTotalCountsOfTilesFailed = (payload) => ({
  type: FETCH_TOTAL_COUNTS_FAILED,
  payload,
})

export const getTotalCountsOfTiles = () => async (dispatch) => {
  dispatch(fetchTotalCountsOfTilesRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.globalDashboard}`,
  })
  const projectLists = data?.projects?.projectList
  const totalCounts = {
    ...data?.projects?.projectCountStatusWise,
    totalProjects: data?.projects?.totalProjects,
    unpublishedReports: data?.projects?.unpublishedReportProjectCount,
  }
  if (data) {
    dispatch(fetchTotalCountsOfTilesSuccess({ projectLists, totalCounts }))
  }
  if (error) {
    if (equal(error?.response?.status, 404)) {
      dispatch(fetchTotalCountsOfTilesSuccess({}))
    }
    dispatch(fetchTotalCountsOfTilesFailed(error.response.data))
  }
}

// fetch projects by status
const fetchProjectsListRequested = () => ({
  type: FETCH_PROJECTS_LIST_REQUESTED,
})
const fetchProjectsListSuccess = (payload) => ({
  type: FETCH_PROJECTS_LIST_SUCCESS,
  payload,
})
const fetchProjectsListFailed = (payload) => ({
  type: FETCH_PROJECTS_LIST_FAILED,
  payload,
})

export const getProjectsList = (queryParams) => async (dispatch) => {
  dispatch(fetchProjectsListRequested())

  const queryString = new URLSearchParams(queryParams)
    .toString()
    .replace(/%28/g, '(')
    .replace(/%29/g, ')')

  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.globalDashboard}?${queryString}`,
  })
  if (data) {
    dispatch(fetchProjectsListSuccess(data))
  }
  if (error) {
    if (equal(error?.response?.status, 404)) {
      dispatch(fetchProjectsListSuccess([]))
    }
    dispatch(fetchProjectsListFailed(error.response.data))
  }
}

// addAndRemoveFavorite
const addAndRemoveFavoriteRequest = () => ({
  type: ADD_REMOVE_FAVORITE_REQUEST,
})
const addAndRemoveFavoriteSuccess = () => ({
  type: ADD_REMOVE_FAVORITE_SUCCESS,
})
const addAndRemoveFavoriteFailed = (payload) => ({
  type: ADD_REMOVE_FAVORITE_FAILED,
  payload,
})

export const addAndRemoveFavorite = (payload) => async (dispatch) => {
  dispatch(addAndRemoveFavoriteRequest())
  const { data, error } = await api({
    method: method.post,
    endPoint: `${apiEndPoints.favorites}`,
    data: payload,
  })
  if (data) {
    dispatch(addAndRemoveFavoriteSuccess(data))
  }
  if (error) {
    if (equal(error?.response?.status, 404)) {
      dispatch(addAndRemoveFavoriteFailed(error.response.data))
    }
    dispatch(addAndRemoveFavoriteFailed(error.response.data))
  }
}

// filter based on dropDown fields
const fetchFilterProjectsRequested = () => ({
  type: FETCH_FILTER_PROJECTS_REQUESTED,
})
const fetchFilterProjectsSuccess = (payload) => ({
  type: FETCH_FILTER_PROJECTS_SUCCESS,
  payload,
})
const fetchFilterProjectsFailed = (payload) => ({
  type: FETCH_FILTER_PROJECTS_FAILED,
  payload,
})

export const filterProjects = (payload) => async (dispatch) => {
  dispatch(fetchFilterProjectsRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.globalDashboard}?projectType=${payload?.projectType}&projectStatus=${payload?.projectStatus}&constructionType=${payload?.constructionType}`,
  })
  if (data) {
    dispatch(fetchFilterProjectsSuccess(data))
  }
  if (error) {
    dispatch(fetchFilterProjectsFailed(error.response.data))
  }
}

// search projects
const searchProjectRequested = () => ({
  type: FETCH_SEARCH_PROJECT_REQUESTED,
})
const searchProjectSuccess = (payload) => ({
  type: FETCH_SEARCH_PROJECT_SUCCESS,
  payload,
})
const searchProjectFailed = (payload) => ({
  type: FETCH_SEARCH_PROJECT_FAILED,
  payload,
})

export const searchProject = (searchQuery) => async (dispatch) => {
  dispatch(searchProjectRequested())
  const { data, error } = await api({
    method: method.get,
    endPoint: `${apiEndPoints.globalDashboard}?search=${searchQuery}`,
  })
  if (data) {
    dispatch(searchProjectSuccess(data))
  }
  if (error) {
    dispatch(searchProjectFailed(error.response.data))
  }
}

// onClickRowData
export const setProjectData = (payload) => ({
  type: SET_PROJECT_DATA,
  payload,
})
export const clearProjectData = () => ({
  type: CLEAR_PROJECT_DATA,
})
// onMouseHover
export const setMarkerHover = (payload) => ({
  type: SET_MARKER_HOVER,
  payload,
})
export const clearMarkerHover = () => ({
  type: CLEAR_MARKER_HOVER,
})
