import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_CONTINGENCY_REDUCER,
  DELETE_CONTINGENCY_FAILED,
  DELETE_CONTINGENCY_REQUESTED,
  DELETE_CONTINGENCY_SUCCESS,
  FETCH_CONTINGENCY_FAILED,
  FETCH_CONTINGENCY_REFERENCE_VALUES_FAILED,
  FETCH_CONTINGENCY_REFERENCE_VALUES_REQUESTED,
  FETCH_CONTINGENCY_REFERENCE_VALUES_SUCCESS,
  FETCH_CONTINGENCY_REQUESTED,
  FETCH_CONTINGENCY_SUCCESS,
  IMPORT_CONTINGENCY_FROM_LAST_REPORT_FAILED,
  IMPORT_CONTINGENCY_FROM_LAST_REPORT_REQUESTED,
  IMPORT_CONTINGENCY_FROM_LAST_REPORT_SUCCESS,
  SAVE_CONTINGENCY_FAILED,
  SAVE_CONTINGENCY_REQUESTED,
  SAVE_CONTINGENCY_SUCCESS,
} from '../constants/contingency'

const initialState = {
  isSavingContingency: false,
  isFetchingContingency: true,
  isFetchingContingencyReferenceValues: true,
  contingency: {},
  contingencyReference: null,
  contingencyReferenceError: null,
  contingencyError: null,
  isDeletingContingency: false,
  // Import from last report
  isImporting: {
    sccComments: false,
    hccComments: false,
  },
  importedSccComments: null,
  importedHccComments: null,
}

export const contingency = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch contingency reference values
    case FETCH_CONTINGENCY_REFERENCE_VALUES_REQUESTED:
      return {
        ...state,
        isFetchingContingencyReferenceValues: true,
      }
    case FETCH_CONTINGENCY_REFERENCE_VALUES_SUCCESS:
      return {
        ...state,
        isFetchingContingencyReferenceValues: false,
        contingencyReference: action?.payload,
        contingencyReferenceError: null,
      }
    case FETCH_CONTINGENCY_REFERENCE_VALUES_FAILED:
      return {
        ...state,
        isFetchingContingencyReferenceValues: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        contingencyReferenceError: action.payload?.data,
      }
    // Fetch contingency
    case FETCH_CONTINGENCY_REQUESTED:
      return {
        ...state,
        isFetchingContingency: true,
      }
    case FETCH_CONTINGENCY_SUCCESS:
      return {
        ...state,
        isFetchingContingency: false,
        contingency: action?.payload,
        contingencyError: '',
      }
    case FETCH_CONTINGENCY_FAILED:
      return {
        ...state,
        isFetchingContingency: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        contingencyError: action.payload?.data,
      }
    // Create contingency
    case SAVE_CONTINGENCY_REQUESTED:
      return {
        ...state,
        isSavingContingency: true,
        contingencyError: '',
      }
    case SAVE_CONTINGENCY_SUCCESS:
      return {
        ...state,
        isSavingContingency: false,
        contingency: action.payload,
        contingencyError: '',
      }
    case SAVE_CONTINGENCY_FAILED:
      return {
        ...state,
        isSavingContingency: false,
        contingencyError: action.payload,
      }
    // Delete contingency
    case DELETE_CONTINGENCY_REQUESTED:
      return {
        ...state,
        isDeletingContingency: true,
      }
    case DELETE_CONTINGENCY_SUCCESS:
      return {
        ...state,
        contingency: action?.payload,
        isDeletingContingency: false,
      }
    case DELETE_CONTINGENCY_FAILED:
      return {
        ...state,
        isDeletingContingency: false,
      }

    // Import contingency from last report
    case IMPORT_CONTINGENCY_FROM_LAST_REPORT_REQUESTED:
      return {
        ...state,
        isImporting: {
          ...state.isImporting,
          [action.payload]: true,
        },
      }
    case IMPORT_CONTINGENCY_FROM_LAST_REPORT_SUCCESS:
      return {
        ...state,
        [`imported${action.payload.pascalCaseQuery}`]: action.payload.data,
        isImporting: {
          ...state.isImporting,
          [action.payload.query]: false,
        },
      }
    case IMPORT_CONTINGENCY_FROM_LAST_REPORT_FAILED:
      return {
        ...state,
        [`import${action.payload.pascalCaseQuery}Error`]: action.payload.data,
        isImporting: {
          ...state.isImporting,
          [action.payload.query]: false,
        },
      }
    case CLEAR_CONTINGENCY_REDUCER:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
