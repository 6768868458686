// Fetch list of rfp
export const FETCH_RFP_LIST_REQUESTED = 'FETCH_RFP_LIST_REQUESTED'
export const FETCH_RFP_LIST_SUCCESS = 'FETCH_RFP_LIST_SUCCESS'
export const FETCH_RFP_LIST_FAILED = 'FETCH_RFP_LIST_FAILED'

// create new rfp
export const CREATE_NEW_RFP_REQUESTED = 'CREATE_NEW_RFP_REQUESTED'
export const CREATE_NEW_RFP_SUCCESS = 'CREATE_NEW_RFP_SUCCESS'
export const CREATE_NEW_RFP_FAILED = 'CREATE_NEW_RFP_FAILED'

// delete rfp
export const DELETE_RFP_REQUESTED = 'DELETE_RFP_REQUESTED'
export const DELETE_RFP_SUCCESS = 'DELETE_RFP_SUCCESS'
export const DELETE_RFP_FAILED = 'DELETE_RFP_FAILED'

export const ADD_NEW_RFP_TO_EXISTING = 'ADD_NEW_RFP_TO_EXISTING'
export const UPDATE_RFP_ADRESS = 'UPDATE_RFP_ADRESS'

// get single rfp project data
export const GET_SINGLE_RFP_DATA_REQUESTED = 'GET_SINGLE_RFP_DATA_REQUESTED'
export const GET_SINGLE_RFP_DATA_SUCCESS = 'GET_SINGLE_RFP_DATA_SUCCESS'
export const GET_SINGLE_RFP_DATA_FAILED = 'GET_SINGLE_RFP_DATA_FAILED'

// get scope of services options
export const GET_SCOPE_SERVICE_OPTS_REQUESTED =
  'GET_SCOPE_SERVICE_OPTS_REQUESTED'
export const GET_SCOPE_SERVICE_OPTS_SUCCESS = 'GET_SCOPE_SERVICE_OPTS_SUCCESS'
export const GET_SCOPE_SERVICE_OPTS_FAILED = 'GET_SCOPE_SERVICE_OPTS_FAILED'

// Get rfp company list
export const GET_RFP_COMPANY_LIST_REQUESTED = 'GET_RFP_COMPANY_LIST_REQUESTED'
export const GET_RFP_COMPANY_LIST_SUCCESS = 'GET_RFP_COMPANY_LIST_SUCCESS'
export const GET_RFP_COMPANY_LIST_FAILED = 'GET_RFP_COMPANY_LIST_FAILED'

// Add or remove from company favorite
export const ADD_REMOVE_COMPANY_REQUESTED = 'ADD_REMOVE_COMPANY_REQUESTED'
export const ADD_REMOVE_COMPANY_SUCCESS = 'ADD_REMOVE_COMPANY_SUCCESS'
export const ADD_REMOVE_COMPANY_FAILED = 'ADD_REMOVE_COMPANY_FAILED'

// Update RPF data
export const UPDATE_RPF_REQUESTED = 'UPDATE_RPF_REQUESTED'
export const UPDATE_RPF_SUCCESS = 'UPDATE_RPF_SUCCESS'
export const UPDATE_RPF_FAILED = 'UPDATE_RPF_FAILED'

// Send RPF Notification
export const SEND_RPF_REQUESTED = 'SEND_RPF_REQUESTED'
export const SEND_RPF_SUCCESS = 'SEND_RPF_SUCCESS'
export const SEND_RPF_FAILED = 'SEND_RPF_FAILED'

// Send RPF Notification
export const BUIDS_REQUESTED = 'BUIDS_REQUESTED'
export const BUIDS_SUCCESS = 'BUIDS_SUCCESS'
export const BUIDS_FAILED = 'BUIDS_FAILED'

export const CLEAR_RFP_DATA = 'CLEAR_RFP_DATA'
