import { Box, Stack } from '@mui/material'
import React from 'react'

import { useSelector } from 'react-redux'
import ActionsBar from '../admin/ActionsBar'
import Navbar from '../admin/Navbar'
import PageTitleBar from './PageTitleBar'
import MainLayout from '../main'
import { equal } from '../../Utils/javascript'
import { clientPortal } from '../../Utils/constant'

export const OuterLayout = ({ children, title }) => {
  const { portalType } = useSelector(({ auth }) => auth)

  return (
    <Stack direction="column" minHeight="100vh">
      <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 999 }}>
        <Navbar />
        <PageTitleBar
          title={title}
          isShowMenu={equal(portalType, clientPortal)}
        />
      </Box>
      <MainLayout>{children}</MainLayout>
    </Stack>
  )
}

export const InnerLayout = ({
  actionTitle,
  actions: Actions = () => null,
  maxWidth = '100%',
  children,
  showActionBar = true,
  sx,
}) => (
  <>
    {showActionBar && (
      <Box sx={{ position: 'fixed', top: 114, left: 0, right: 0, zIndex: 999 }}>
        <ActionsBar actionTitle={actionTitle}>
          <Actions />
        </ActionsBar>
      </Box>
    )}
    <Stack
      direction="row"
      flex={1}
      sx={{ maxHeight: '100%', overflow: 'hidden' }}
    >
      <Box
        {...(maxWidth && { px: 5, py: 4 })}
        width="100%"
        sx={{
          marginTop: '146px',
          background: '#ffffff',
          ...sx,
        }}
      >
        <Box width="100%" sx={{ m: 0, position: 'relative' }}>
          {children}
        </Box>
      </Box>
    </Stack>
  </>
)
