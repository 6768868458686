import React, { useState, useEffect, memo } from 'react'
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  Stack,
} from '@mui/material'
import { RemoveCircleOutline } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import DKTDialog from '../../../Shared/DKTDialog'
import { useStyles } from '../../../Styles/reportChart.style'
import { initialEconomicsField } from '../../../Description/performanceChart.description'
import { equal, gt, keys, ternary } from '../../../Utils/javascript'
import DKTInput from '../../../Shared/DKTInput'
import DKTButton from '../../../Shared/DKTButton'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import { isValidDateFormat } from '../../../Utils/regex'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { createEconomicCompletion } from '../../../Redux/actions/reportMonitoring'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'

const EconomicCompletion = ({
  open = false,
  onClose,
  rowCount,
  projectId,
  isEconomicDataLoading,
}) => {
  const [data, setData] = useState({})
  const [removeRowId, setRemoveRowId] = useState(null)
  const [isDeleteNotificationOpen, setIsDeleteNotificationOpen] =
    useState(false)
  const [errors, setErrors] = useState({})
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)

  const dispatch = useDispatch()

  const { drawProgressData, economicCompletionData } = useSelector(
    ({ reportMonitoring }) => reportMonitoring,
  )
  const classes = useStyles()

  useEffect(() => {
    if (rowCount && !keys(economicCompletionData)?.length) {
      setData(
        Array.from({ length: rowCount }, (_, index) => {
          const timestamp = index + 1
          return [timestamp, { month: '', economicCompletion: '' }]
        }).reduce((obj, [key, value]) => {
          obj[key] = value
          return obj
        }, {}),
      )
    }
    if (keys(economicCompletionData)?.length) {
      if (
        drawProgressData?.progress_value &&
        gt(rowCount, keys(economicCompletionData)?.length)
      ) {
        const extraAddRows = rowCount - keys(economicCompletionData).length
        const newData = Array.from({ length: extraAddRows }, (_, index) => {
          const timestamp = keys(economicCompletionData).length + index + 1
          return [timestamp, { month: '', economicCompletion: '' }]
        }).reduce((obj, [key, value]) => {
          obj[key] = value
          return obj
        }, {})
        setData({ ...economicCompletionData, ...newData })
      } else {
        setData(economicCompletionData)
      }
    }
  }, [rowCount, economicCompletionData, drawProgressData, open])

  const checkValidate = (val) => {
    const errorId = {}
    keys(val)?.forEach((key) => {
      const cloneMonth = val[key]?.month
      const cloneEconomic = val[key]?.economicCompletion
      if (cloneMonth && !isValidDateFormat(cloneMonth)) {
        errorId[key] = {
          ...errorId[key],
          month: 'Required',
        }
      }

      if ((cloneEconomic && cloneEconomic < 0) || cloneEconomic > 100) {
        errorId[key] = {
          ...errorId[key],
          economicCompletion: 'Required',
        }
      }
    })
    setErrors({ ...errorId })
    return !keys(errorId)?.length
  }

  const openDeleteNotification = (rowId) => {
    setIsDeleteNotificationOpen(true)
    setRemoveRowId(rowId)
  }
  const closeDeleteNotification = () => setIsDeleteNotificationOpen(false)

  const removeRow = () => {
    const cloneData = { ...data }
    const cloneErrors = { ...errors }
    delete cloneData[removeRowId]
    delete cloneErrors[removeRowId]
    setData(cloneData)
    setErrors({ ...cloneErrors })
    closeDeleteNotification()
  }

  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    onClose()
    setErrors({})
  }

  const deleteNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteNotification}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={removeRow}>
        Yes
      </DKTButton>
    </>
  )

  const handleChange = (e, rowId) => {
    const { name, value } = e.target
    setIsUnSavedChanges(true)
    const cloneData = { ...data }
    cloneData[rowId] = {
      ...cloneData[rowId],
      [name]: value,
    }
    setData(cloneData)
  }

  const handleAddNewRow = () => {
    setData({
      ...data,
      [(keys(data).length || 0) + 1]: {
        month: '',
        economicCompletion: '',
        isNewRow: true,
      },
    })
  }

  const handleSave = () => {
    const isValidForm = checkValidate(data)
    if (!isValidForm) {
      dispatch(updateOnSaveStatus({ cancel: true }))
      return
    }
    const payload = {
      economicCompletion: { ...data },
      projectId,
    }
    if (isValidForm) {
      dispatch(createEconomicCompletion(payload, projectId))
      setIsUnSavedChanges(false)
      onClose()
    }
  }

  const handleCloseEconomicCompletion = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    onClose()
    setErrors({})
  }

  return (
    <DKTDialog
      open={open}
      handleClose={handleCloseEconomicCompletion}
      title="Economic Completion"
      className={classes.economicCompletion}
      infoIcon
      infoText={
        <List className={classes.termsWrapper}>
          <ListItem>
            Month column inputs have a six character limit. User may consider
            these formats: 01, Jan 24, 01/24, 01-24
          </ListItem>
          <ListItem>Percentage values must range from 0-100</ListItem>
        </List>
      }
    >
      {equal(isEconomicDataLoading, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: '100%' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Economic data. Please try
            contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <>
          <TableContainer sx={{ maxHeight: '418px', overflow: 'auto' }}>
            <Table className={classes.commonTable} aria-label="simple table">
              <TableHead
                sx={{ position: 'sticky', top: 0, left: 0, zIndex: 999 }}
              >
                <TableRow>
                  <TableCell width="23%">Month</TableCell>
                  <TableCell>Economic Completion %</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keys(data)?.map((id, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {initialEconomicsField?.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>
                        <DKTInput
                          placeholder={cell.placeholder}
                          type={cell.type}
                          name={cell.name}
                          value={data[id][cell.name] ? data[id][cell.name] : ''}
                          className={
                            errors[id] && errors[id][cell.name]
                              ? classes.errorInput
                              : classes.economicInput
                          }
                          onChange={(event) => handleChange(event, id)}
                          endAdornment={ternary(
                            equal(cell.name, 'economicCompletion'),
                            <InputAdornment
                              position="start"
                              className={classes.InputAdornmentIcon}
                            >
                              %
                            </InputAdornment>,
                            null,
                          )}
                        />
                      </TableCell>
                    ))}
                    <TableCell>
                      {ternary(
                        data[id]?.isNewRow,
                        <IconButton onClick={() => openDeleteNotification(id)}>
                          <RemoveCircleOutline color="primary" />
                        </IconButton>,
                        null,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {!keys(data)?.length && (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      sx={{
                        fontSize: '1rem',
                        textAlign: 'center',
                      }}
                    >
                      Sorry, there is no matching data to display
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <DKTButton
            variant="text"
            color="primary"
            className={classes.addRow}
            startIcon={<CircledPlusIcon />}
            onClick={handleAddNewRow}
          >
            Add Row
          </DKTButton>
          <DKTButton
            sx={{ marginTop: '24px', display: 'flex', marginLeft: 'auto' }}
            onClick={handleSave}
          >
            Save
          </DKTButton>
          <DKTDialog
            open={isDeleteNotificationOpen}
            handleClose={() => setIsDeleteNotificationOpen(false)}
            title="&nbsp;"
            actions={deleteNotificationAction}
            maxWidth="xs"
          >
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </DKTDialog>
          {/* show modal when tries to navigate without save data */}
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={handleSave}
          />
          <DKTReactRouterPrompt
            isDirty={isUnSavedChanges}
            onSave={handleSave}
          />
        </>
      )}
    </DKTDialog>
  )
}

export default memo(EconomicCompletion)
