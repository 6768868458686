import { Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  cmrItem,
  cmrItems,
} from '../../../Description/constructionMonitoringReport.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/admin'
import { fetchCMR } from '../../../Redux/actions/constructionMonitoringReport'
import DKTSelect from '../../../Shared/DKTSelect'
import ConstructionSummaryFormat from './ConstructionSummaryFormat'
import Limitations from './Limitations'
import ReportTierSettings from './ReportTierSettings'
import { ternary } from '../../../Utils/javascript'
import ExecutiveSummary from './ExecutiveSummary'

const ConstructionReport = () => {
  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState(false)
  const [isUnsavedData, setIsUnsavedData] = useState(false)

  const { values, handleChange } = useForm({ cmrItem })
  const discardedPersonnelName = useRef('')

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCMR())
  }, [dispatch])

  const openUnsavedChangesModal = () => setIsUnsavedChangesModalOpen(true)
  const closeUnsavedChangesModal = () => setIsUnsavedChangesModalOpen(false)

  const discardUnsavedChanges = () => {
    setIsUnsavedData(false)
    const eventWithTarget = {
      target: { name: 'cmrItem', value: discardedPersonnelName.current },
    }
    handleChange(eventWithTarget)
    discardedPersonnelName.current = ''
    closeUnsavedChangesModal()
  }

  const showUnsavedChangesModal = (e) => {
    openUnsavedChangesModal()
    discardedPersonnelName.current = e.target.value
  }
  const items = {
    isUnsavedData,
    setIsUnsavedData,
    discardUnsavedChanges,
    isUnsavedChangesModalOpen,
    closeUnsavedChangesModal,
  }
  const showCMRItemDetails = () => {
    switch (values.cmrItem) {
      case cmrItems.limitations:
        return <Limitations renderSelect={renderSelect} items={items} />
      case cmrItems.constructionSummaryFormat:
        return (
          <ConstructionSummaryFormat
            renderSelect={renderSelect}
            items={items}
          />
        )
      case cmrItems.reportTierSettings:
        return <ReportTierSettings renderSelect={renderSelect} items={items} />
      case cmrItems.executiveSummary:
        return <ExecutiveSummary renderSelect={renderSelect} items={items} />
      default:
        return (
          <InnerLayout
            actionTitle="Manage Construction Monitoring Report Settings"
            contentTitle="Construction Monitoring Report (CMR)"
          >
            {renderSelect()}
          </InnerLayout>
        )
    }
  }

  const renderSelect = () => (
    <Grid container>
      <Grid item xs={3}>
        <DKTSelect
          {...cmrItem}
          name="cmrItem"
          id="cmrItem"
          value={values.cmrItem || ''}
          onChange={ternary(
            values.cmrItem && isUnsavedData,
            showUnsavedChangesModal,
            handleChange,
          )}
        />
      </Grid>
    </Grid>
  )

  return <>{showCMRItemDetails()}</>
}

export default ConstructionReport
