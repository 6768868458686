import { makeStyles } from '@mui/styles'
import theme from '../Theme'

export const useStyles = makeStyles(() => ({
  commonTable: {
    '& .MuiTableHead-root': {
      '& .MuiTableRow-root': {
        '& th': {
          backgroundColor: '#E5E5E5',
        },
      },
    },
    '& .MuiTableCell-body': {
      paddingLeft: 0,
      padding: '8px 8px 8px',
      '& .MuiInputBase-root': {
        lineHeight: 0,
        '& .MuiInputBase-input': {
          padding: 0,
        },
      },
      '& .MuiTypography-body1': {
        textAlign: 'left',
        maxWidth: '250px',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      },
    },
  },
  economicCompletion: {
    '& .MuiPaper-root ': {
      maxWidth: 428,
      padding: '41px 33px 46px',
      '& .MuiDialogContent-root': {
        padding: '20px 0 0 !important',
      },
    },
  },
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
  select: {
    marginTop: '4px',
    width: 'auto',
    minWidth: 169,
    '& .MuiSelect-select': {
      padding: '6px 0px 6px 12px',
      fontSize: 14,
    },
  },
  pieChart: {
    height: 150,
    width: 150,
    margin: '40px auto',
    [theme.breakpoints.down('1300')]: {
      height: 100,
      width: 100,
    },
  },
  chartWrapper: {
    marginLeft: '-24px',
    height: 405,
  },
  removeDrawWrapper: {
    marginLeft: '-24px',
    height: 445,
  },
  addRow: {
    textTransform: 'initial',
    paddingLeft: '20px',
    marginTop: '16px',
    display: 'flex',
  },
  economicInput: {
    '& .MuiInputBase-root': {
      marginTop: '0 !important',
      paddingLeft: '16px !important',
      border: 'none',
      color: '#1564FF',
    },
  },
  errorInput: {
    '& .MuiInputBase-root': {
      marginTop: '0 !important',
      paddingLeft: '16px !important',
      border: 'none',
      color: 'red',
    },
  },
  InputAdornmentIcon: {
    '& p': {
      color: '#1564FF',
    },
  },
  termsWrapper: {
    paddingBottom: '32px',
    paddingTop: 0,
    '& .MuiListItem-root': {
      fontSize: 14,
      lineHeight: '16px',
      color: '#ffffff',
      fontWeight: '500',
      padding: '10px 0 0 16px',
      '&:before': {
        content: "''",
        position: 'absolute',
        height: 6,
        width: 6,
        borderRadius: '100%',
        backgroundColor: '#ffffff',
        top: 16,
        left: 0,
      },
    },
  },
}))
