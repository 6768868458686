import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import { equal } from '../../Utils/javascript'
import {
  FETCH_PROJECTS_LIST_FAILED,
  FETCH_PROJECTS_LIST_REQUESTED,
  FETCH_PROJECTS_LIST_SUCCESS,
} from '../constants/clientAccessDashboard'

// fetch projects by status
const fetchProjectsListRequested = () => ({
  type: FETCH_PROJECTS_LIST_REQUESTED,
})
const fetchProjectsListSuccess = (payload) => ({
  type: FETCH_PROJECTS_LIST_SUCCESS,
  payload,
})
const fetchProjectsListFailed = (payload) => ({
  type: FETCH_PROJECTS_LIST_FAILED,
  payload,
})

export const getProjectsList =
  (queryParams = '') =>
  async (dispatch) => {
    dispatch(fetchProjectsListRequested())

    const queryString = new URLSearchParams(queryParams)
      .toString()
      .replace(/%28/g, '(')
      .replace(/%29/g, ')')

    const { data, error } = await api({
      method: method.get,
      endPoint: `${apiEndPoints.clientAccessDashboard}?${queryString}`,
    })

    const counts = {
      ...data?.projects?.projectCountStatusWise,
      totalProjects: data?.projects?.totalProjects,
    }
    const projectList = data?.projects?.projectList
    const clientName = data?.projects?.clientName

    if (data) {
      dispatch(
        fetchProjectsListSuccess({
          counts,
          projectList,
          clientName,
        }),
      )
    }

    if (error) {
      if (equal(error?.response?.status, 404)) {
        dispatch(fetchProjectsListSuccess([]))
      }
      dispatch(fetchProjectsListFailed(error.response.data))
    }
  }
