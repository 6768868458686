import { Delete } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  formFields,
  initialLocationField,
} from '../../../Description/companyInformation.description'
import useForm from '../../../Hooks/useForm'
import {
  getCompany,
  getCompanyServiceOffered,
  getCompanyServiceStateLocation,
  getCompanyServiceUsTerritories,
  submitUpdateCompany,
} from '../../../Redux/actions/companyInformation'
import { getStateList } from '../../../Redux/actions/states'
import DKTButton from '../../../Shared/DKTButton'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTFileInput from '../../../Shared/DKTFileInput'
import DKTInput from '../../../Shared/DKTInput'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTForm from '../../../Shared/DKTForm'
import {
  clientPortal,
  companyLogoError,
  selectAllOption,
} from '../../../Utils/constant'
import {
  entries,
  equal,
  isArray,
  keys,
  ternary,
  typeOf,
} from '../../../Utils/javascript'
import { loadStateFn } from '../../../Utils/localStorage'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import FormSectionDivider from '../../../Shared/FormSectionDivider'

const useStyles = makeStyles(() => ({
  deleteNotificationContent: {
    textAlign: 'center',
    '& h5': {
      color: '#000',
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 15,
    },
    '& p': {
      color: '#949494',
      fontSize: 14,
    },
  },
}))

const CompanyInformation = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const companyId = loadStateFn('company-id')
  const [locationFields, setLocationFields] = useState([])
  const [companyLogo, setCompanyLogo] = useState()
  const [populateState, setPopulateState] = useState()
  const [populateServiceOffered, setPopulateServiceOffered] = useState([])
  const [populateServiceStateLocation, setPopulateServiceStateLocation] =
    useState([])
  const [populateServiceUsTerritories, setPopulateServiceUsTerritories] =
    useState([])
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [selectedOfficeLocation, setSelectedOfficeLocation] = useState(null)
  const [deleteNotificationOpen, setOpenDeleteNotification] = React.useState({
    popupOpen: false,
  })
  const [companyLogoDirty, setCompanyLogoDirty] = useState(false)
  const [deleteItemDirty, setDeleteItemDirty] = useState(false)

  const { portalType } = useSelector(({ auth }) => auth)
  const isClientCompany = equal(portalType, clientPortal)

  const formFiled = keys(formFields.servicesAndLocations).reduce(
    (result, key) => {
      if (
        !(
          formFields.servicesAndLocations[key]?.isAdminAccess && isClientCompany
        )
      ) {
        result[key] = formFields.servicesAndLocations[key]
      }
      return result
    },
    {},
  )

  const {
    values,
    errors,
    setErrors,
    handleChange,
    setValues,
    handleSubmit,
    isDirty,
    setIsDirty,
    setDummyData,
    setFieldValue,
  } = useForm({ ...formFields.companyInfo, ...formFiled })
  const [inputFileError, setInputFileError] = useState(false)
  const { state } = useSelector(({ states }) => states)
  const {
    isUpdateCompanyLoading,
    companyData = [],
    companyServiceOffered,
    companyServiceStateLocation,
    companyServiceUsTerritories,
    updateCompanyError,
  } = useSelector(({ company }) => company)

  useEffect(() => {
    if (updateCompanyError) {
      const errorData = { ...errors }
      Object.entries(updateCompanyError).forEach(([errorName, [errorDesc]]) => {
        errorData[errorName] = errorDesc
      })
      setErrors(errorData)
    }
    return () => {
      setErrors({})
    }
  }, [updateCompanyError])

  useEffect(() => {
    const oldState = state?.find((sts) =>
      equal(sts?.label, companyData[0]?.state),
    )
    setPopulateState(oldState)
  }, [companyData, state])

  const serviceId = useCallback((data, resAry) => {
    const res = data?.map((loc) => {
      const id = resAry?.find((el) => equal(el?.name, loc))?.id
      return id
    })
    return res
  }, [])

  useEffect(() => {
    if (companyData && companyData[0]) {
      if (companyServiceOffered.length) {
        const oldServiceOffered = serviceId(
          companyData[0]?.serviceOffered,
          companyServiceOffered,
        )
        setPopulateServiceOffered(oldServiceOffered)
      }
      if (companyServiceStateLocation.length) {
        const oldServiceStateLocation = serviceId(
          companyData[0]?.serviceStateLocation,
          companyServiceStateLocation,
        )
        setPopulateServiceStateLocation(oldServiceStateLocation)
      }
      if (companyServiceUsTerritories.length) {
        const oldServiceUsTerritories = serviceId(
          companyData[0]?.serviceUsTerritories,
          companyServiceUsTerritories,
        )
        setPopulateServiceUsTerritories(oldServiceUsTerritories)
      }
    }
  }, [
    companyData,
    companyServiceOffered,
    companyServiceStateLocation,
    companyServiceUsTerritories,
  ])

  useEffect(() => {
    const dynamicOptionForState = (states) => {
      const res = states?.map((sts) => ({
        value: sts?.value,
        label: sts?.value,
      }))
      return res
    }
    const dynamicOptionArray = (services) => {
      const res = services?.map((service) => ({
        value: service?.id,
        label: service?.name,
      }))
      return [selectAllOption, ...res]
    }
    const serviceOffered = dynamicOptionArray(companyServiceOffered)
    const serviceStateLocation = dynamicOptionArray(companyServiceStateLocation)
    const serviceUsTerritories = dynamicOptionArray(companyServiceUsTerritories)
    const stateList = dynamicOptionForState(state)
    setDynamicOptions({
      state: stateList,
      serviceOffered,
      serviceStateLocation,
      serviceUsTerritories,
    })
  }, [
    companyServiceOffered,
    companyServiceStateLocation,
    companyServiceUsTerritories,
    state,
  ])

  useEffect(() => {
    dispatch(getStateList())
    dispatch(getCompanyServiceOffered())
    dispatch(getCompanyServiceStateLocation())
    dispatch(getCompanyServiceUsTerritories())
    dispatch(getCompany(companyId))
    return () => {
      setValues({})
      setDummyData({})
      setLocationFields([])
      setCompanyLogo()
      setSelectedOfficeLocation(null)
    }
  }, [companyId, dispatch, setValues])

  useEffect(() => {
    if (companyData[0]) {
      const populateObj = {}
      if (populateState?.value) {
        populateObj.state = populateState?.value
      }
      if (populateServiceOffered?.length) {
        populateObj.serviceOffered = populateServiceOffered
      }
      if (populateServiceStateLocation?.length) {
        populateObj.serviceStateLocation = populateServiceStateLocation
      }
      if (populateServiceUsTerritories?.length) {
        populateObj.serviceUsTerritories = populateServiceUsTerritories
      }
      let tempValue = {
        ...companyData[0],
        ...populateObj,
      }
      setCompanyLogo(
        ternary(companyData[0]?.companyLogo, companyData[0]?.companyLogo, null),
      )
      if (companyData[0]?.officeLocations) {
        const locationData = []
        companyData[0]?.officeLocations?.forEach((loc, index) => {
          const otherState = state?.find((sts) =>
            equal(sts?.label, loc?.otherOfficeState),
          )?.value
          const updatedLocation = addOtherLocation(index, locationData)
          locationData.push(updatedLocation)
          tempValue = {
            ...tempValue,
            [`otherOfficeCity.${index}`]: loc?.otherOfficeCity,
            [`otherOfficeState.${index}`]: otherState || '',
          }
        })
        setLocationFields([...locationData])
      }
      setValues((prevValues) => ({
        ...prevValues,
        ...tempValue,
      }))
      setDummyData((prevValues) => ({
        ...prevValues,
        ...tempValue,
      }))
    }
  }, [
    companyData[0]?.officeLocations?.length,
    companyData?.length,
    populateServiceOffered?.length,
    populateServiceStateLocation?.length,
    populateServiceUsTerritories?.length,
    populateState?.value,
    setValues,
  ])
  useEffect(() => {
    if (
      companyLogo !== undefined &&
      companyData[0]?.companyLogo !== undefined
    ) {
      setCompanyLogoDirty(companyLogo !== companyData[0]?.companyLogo)
    }
  }, [companyLogo, dispatch])

  const handleUpdate = (e) => {
    const { name, value } = e.target

    if (equal(value?.at(-1), 'all')) {
      setFieldValue(
        name,
        ternary(
          equal(values?.[name].length, dynamicOptions[name].length - 1),
          [],
          dynamicOptions[name]
            ?.filter((option) => !equal(option.value, 'all'))
            .map((option) => option.value),
        ) || [],
      )
    } else {
      handleChange(e)
    }
  }

  const addOtherLocation = (index, locationData = false) => {
    const location = locationData || [...locationFields]
    location[index] = { data: initialLocationField, id: index }
    if (!locationData) setLocationFields([...location])
    return [...location]
  }

  const removeLocationField = (id) => {
    setOpenDeleteNotification({ popupOpen: true, id })
  }

  const handleDelete = (id) => {
    const cloneValues = { ...values }
    if (equal(id, 'companyLogo')) {
      delete cloneValues.companyLogo
      setValues(cloneValues)
      setCompanyLogo(null)
      handleClose()
      return
    }
    delete cloneValues[`otherOfficeCity.${id}`]
    delete cloneValues[`otherOfficeState.${id}`]
    setValues(cloneValues)
    setDeleteItemDirty(true)
    setSelectedOfficeLocation({ value: cloneValues, id })
  }

  const afterDelete = useCallback((id) => {
    setLocationFields((locationFields) =>
      locationFields.filter((locationField) => !equal(locationField.id, id)),
    )
    handleClose()
  }, [])

  useEffect(() => {
    if (selectedOfficeLocation) {
      afterDelete(selectedOfficeLocation?.id)
    }
  }, [afterDelete, selectedOfficeLocation])

  const onFileUpload = (logo) => {
    setCompanyLogo(logo)
    setInputFileError(false)
  }

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          { isRequired, isDynamicOptions, defaultValue, options, ...formField },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <>
            <Grid key={index} item xs={formField.xs ?? 12}>
              <FormControl
                {...formField}
                options={ternary(
                  isDynamicOptions,
                  ternary(
                    formFields?.otherOfficeState,
                    dynamicOptions?.state || [],
                    dynamicOptions[name] || [],
                  ),
                  options,
                )}
                id={name}
                name={name}
                value={(values && values[name]) || defaultValue || ''}
                onChange={(e) => {
                  handleUpdate(e)
                }}
                error={errors[name]}
              />
            </Grid>
            {makeFieldNameUnique && name.startsWith('otherOfficeState') && (
              <Grid item xs={2}>
                <IconButton
                  color="error"
                  onClick={() => removeLocationField(id)}
                  sx={{ marginTop: '16px' }}
                >
                  <Delete />
                </IconButton>
              </Grid>
            )}
          </>
        )
      },
    )

  const renderFormFields = (formFields) => (
    <Grid container spacing={2} alignItems="center">
      {isArray(formFields)
        ? formFields?.map((formField) =>
            mapFormFields({
              formFields: formField?.data,
              makeFieldNameUnique: true,
              id: formField?.id,
            }),
          )
        : mapFormFields({ formFields })}
    </Grid>
  )
  const saveCompanyInfo = () => {
    const isFormValid = handleSubmit({
      flag: { ...formFields.companyInfo, ...formFiled },
      comp: 'Company Information',
    })
    if (isFormValid) {
      setIsDirty(false)
      setCompanyLogoDirty(false)
      setDeleteItemDirty(false)
      const cloneValues = entries(values)
      const otherLocation = cloneValues
        .filter(
          (flt) =>
            flt[0].startsWith('otherOfficeCity') ||
            flt[0].startsWith('otherOfficeState'),
        )
        .map((el, index, ary) => {
          const findCityStateIndex = ary?.filter(
            (data) => +data[0].split('.')[1] === index,
          )
          return findCityStateIndex
        })
        .filter((ele) => !!ele?.length)
        ?.map((row) =>
          // eslint-disable-next-line array-callback-return, consistent-return
          row.map((element) => {
            if (element[0].startsWith('otherOfficeCity')) {
              return {
                otherOfficeCity: element[1],
              }
            }
            if (element[0].startsWith('otherOfficeState')) {
              return {
                otherOfficeState: element[1],
              }
            }
          }),
        )
        ?.map((sts) => ({ ...sts[0], ...sts[1] }))
      const formData = new FormData()
      cloneValues.forEach((data) => {
        if (
          data[0].startsWith('otherOfficeCity') ||
          data[0].startsWith('otherOfficeState') ||
          data[0].startsWith('companyLogo') ||
          data[0].startsWith('officeLocations') ||
          data[0].startsWith('isClearServiceUsTerritories') ||
          data[0].startsWith('isDeleted') ||
          data[0].startsWith('favorite')
        ) {
          return
        }
        if (
          equal(data[0], 'serviceOffered') ||
          equal(data[0], 'serviceStateLocation') ||
          equal(data[0], 'serviceUsTerritories')
        ) {
          if (data[1].length) {
            data[1].forEach((ele) => {
              const dataKey = `${data[0]}`
              formData.append(dataKey, ele)
            })
          }
          if (data[0] === 'serviceUsTerritories') {
            formData.append(`isClearServiceUsTerritories`, !data[1].length)
          }
        } else if (
          equal(data[0], 'websiteUrl') &&
          !data[1].startsWith('https://')
        ) {
          formData.append(data[0], `https://${data[1]}`)
        } else {
          formData.append(data[0], data[1])
        }
      })
      if (
        !typeOf(companyLogo, 'string') &&
        !equal(companyLogo, undefined) &&
        !equal(companyLogo, null)
      ) {
        formData.append('companyLogo', companyLogo)
      } else if (equal(companyLogo, null)) {
        formData.append('isDeleted', true)
      }
      if (otherLocation?.length) {
        otherLocation.forEach((element, index) => {
          formData.append(
            `officeLocations[${index}]otherOfficeCity`,
            element?.otherOfficeCity || '',
          )
          if (element?.otherOfficeState) {
            formData.append(
              `officeLocations[${index}]otherOfficeState`,
              element?.otherOfficeState,
            )
          }
        })
      }
      dispatch(submitUpdateCompany(formData, companyData[0]?.id))
    } else {
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }

  const handleDeleteLogo = () => {
    setOpenDeleteNotification({ popupOpen: true, id: 'companyLogo' })
  }
  const handleClose = () => {
    setOpenDeleteNotification(false)
  }

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={() => handleDelete(deleteNotificationOpen?.id)}
      >
        Yes
      </DKTButton>
    </>
  )

  return (
    <>
      <Grid container mt="10px" spacing={3.5}>
        <Grid item xs={4}>
          <FormSectionDivider sectionTitle="Contact Information" />
          <Box sx={{ marginTop: '12px' }}>
            <DKTForm autoComplete="off">
              {renderFormFields(formFields.companyInfo)}
            </DKTForm>
          </Box>
        </Grid>
        {!isClientCompany && (
          <>
            <Grid item xs={4}>
              <Stack direction="column" height="100%" spacing="118px">
                <Box
                  component="form"
                  enctype="multipart/form-data"
                  position="relative"
                >
                  <FormSectionDivider sectionTitle="Company Logo" />
                  <Box sx={{ marginTop: '36px' }}>
                    <DKTFileInput
                      onFileUpload={onFileUpload}
                      defaultImage={companyLogo}
                      errorText={ternary(
                        inputFileError,
                        companyLogoError,
                        null,
                      )}
                      isCompLogo
                      height="175px"
                    />
                  </Box>
                  {ternary(
                    companyLogo,
                    <IconButton
                      color="error"
                      sx={{ position: 'absolute', top: -10, right: 0 }}
                      onClick={handleDeleteLogo}
                    >
                      <Delete />
                    </IconButton>,
                    null,
                  )}
                </Box>
                <Box>
                  <FormSectionDivider sectionTitle="Scope of Services & Locations" />
                  <Box sx={{ marginTop: '34px' }}>
                    <DKTForm autoComplete="off">
                      {renderFormFields(formFields.servicesAndLocations)}
                    </DKTForm>
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <FormSectionDivider sectionTitle="Office Locations" />
                <DKTForm autoComplete="off" sx={{ marginTop: '12px' }}>
                  {renderFormFields(locationFields)}
                </DKTForm>
                <DKTButton
                  variant="text"
                  color="primary"
                  sx={{ textTransform: 'initial', px: 1, mt: '37px' }}
                  startIcon={<CircledPlusIcon />}
                  onClick={() => addOtherLocation(locationFields.length)}
                >
                  <Typography>Add Office Location</Typography>
                </DKTButton>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      <DKTButton
        onClick={saveCompanyInfo}
        disabled={isUpdateCompanyLoading}
        variant="outlined"
        sx={{ marginTop: '24px' }}
      >
        {ternary(isUpdateCompanyLoading, 'Saving...', 'Save')}
      </DKTButton>
      <DKTDialog
        open={deleteNotificationOpen?.popupOpen || false}
        handleClose={handleClose}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can’t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      <DKTReactRouterPrompt
        isDirty={isDirty || companyLogoDirty || deleteItemDirty}
        onSave={saveCompanyInfo}
      />
    </>
  )
}

export default memo(CompanyInformation)
