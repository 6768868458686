import { ButtonGroup } from '@mui/material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles, useTheme } from '@mui/styles'
import * as React from 'react'

import { ExpandIcon } from '../Assets/SVGs'
import { ternary } from '../Utils/javascript'
import DKTButton from './DKTButton'

const useStyles = makeStyles((theme) => ({
  actionButton: {
    '&.MuiButtonBase-root': {
      background: theme.palette.background.paper,
      borderRadius: 0,
      padding: '0 0 0 12px',
      textTransform: 'Capitalize',
      height: 40,
      '& svg': {
        padding: 12,
        marginLeft: 12,
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        '& path': {
          stroke: theme.palette.primary.main,
        },
      },
    },
  },
}))

const DKTButtonSelect = ({ options, ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <ButtonGroup
        variant="contained"
        aria-label="split button"
        disableElevation
        {...rest}
      >
        <DKTButton
          id="basic-button"
          aria-controls={ternary(open, 'basic-menu', undefined)}
          aria-haspopup="true"
          aria-expanded={ternary(open, 'true', undefined)}
          onClick={handleClick}
          className={classes.actionButton}
          variant="outlined"
          sx={
            rest.disabled
              ? {
                  borderColor: `${theme.palette.gray.dark} !important`,
                  '& svg': {
                    borderLeft: `1px solid ${theme.palette.gray.dark} !important`,
                    '& path': {
                      stroke: `${theme.palette.gray.dark} !important`,
                    },
                  },
                }
              : {
                  borderColor: theme.palette.primary.main,
                }
          }
        >
          Actions
          <ExpandIcon />
        </DKTButton>
      </ButtonGroup>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableScrollLock
      >
        {options.map(({ label, onClick, ...rest }, index) => (
          <MenuItem
            key={index}
            style={{ padding: '5px 35px' }}
            onClick={() => {
              onClick(handleClose())
            }}
            {...rest}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default DKTButtonSelect
