import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { Stack, Grid, Typography, Box, IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Delete } from '@mui/icons-material'
import { InnerLayout } from '../../../Layout/admin'
import { actionTitles } from '../../../Description/admin.description'
import DKTButton from '../../../Shared/DKTButton'
import DKTSelect from '../../../Shared/DKTSelect'
import {
  options,
  columns,
  associatedClientColumnsDesc,
  formFields,
} from '../../../Description/admin.clientAccess.description'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import DKTMuiDataTable from '../../../Shared/DKTMuiDataTable'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import {
  entries,
  equal,
  keys,
  scrollToElement,
  ternary,
} from '../../../Utils/javascript'
import ProjectAccessModal from './ProjectAccessModal'
import DKTInput from '../../../Shared/DKTInput'
import useForm from '../../../Hooks/useForm'
import { useStyles } from '../../../Styles/clientAccess.style'
import {
  clearClientAccessData,
  clientAccessProjectDelete,
  getAssociatedClientsWithProjects,
  getClientAccessProjectList,
  getClientCompany,
} from '../../../Redux/actions/clientAccess'
import { getProjectList } from '../../../Redux/actions/projects'
import { getProjectChoices } from '../../../Redux/actions/choices'
import { emptyTableCellPlaceholder } from '../../../Description/reportMonitoring.description'
import DKTDialog from '../../../Shared/DKTDialog'

const ClientAccess = () => {
  const [open, setOpen] = useState(false)
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [projectAccessEdit, setProjectAccessEdit] = useState(null)
  const [deleteNotificationOpen, setDeleteNotificationOpen] = useState(false)
  const projectToRemove = useRef(null)
  const setSelectedRows = useRef(() => {})
  const tableRef = useRef()

  const dispatch = useDispatch()

  const {
    clientCompanies,
    isLoadingProjectAccessList,
    projectAccessList,
    associatedClientsWithProjects,
    isLoadingAssociatedClientsWithProjects,
  } = useSelector(({ clientAccess }) => clientAccess)

  const { projectList } = useSelector(({ projects }) => projects)
  const { choices } = useSelector(({ projectChoices }) => projectChoices)

  const { values, errors, handleChange } = useForm(formFields?.clientName)

  const classes = useStyles()

  useEffect(() => {
    dispatch(getClientCompany())
    dispatch(getProjectList())
    dispatch(getProjectChoices({ fieldName: 'clientAccessEngagementType' }))

    return () => {
      dispatch(clearClientAccessData())
    }
  }, [dispatch])

  const dynamicOption = (arr) =>
    arr?.map((sts) => ({
      label: sts?.projectName || sts?.companyName,
      value: sts?.id?.toString(),
    }))

  useEffect(() => {
    const dynamicOpt = {
      projectName: dynamicOption(projectList),
      client: ternary(clientCompanies?.length, dynamicOption(clientCompanies), [
        { label: 'No company available', disabled: true },
      ]),
      engagementType: choices?.clientAccessEngagementType,
    }

    setDynamicOptions((prevOptions) => ({ ...prevOptions, ...dynamicOpt }))
  }, [projectList, clientCompanies, choices?.clientAccessEngagementType])

  useEffect(() => {
    if (!open) return

    const res = projectList?.map((item) => {
      const isAlreadyUsedId = projectAccessList?.find(({ id }) =>
        equal(id, item?.id),
      )?.id

      return {
        label: item?.projectName || item?.companyName,
        value: item?.id?.toString(),
        disabled:
          isAlreadyUsedId && !equal(isAlreadyUsedId, projectAccessEdit?.id),
      }
    })

    setDynamicOptions((prevOptions) => ({
      ...prevOptions,
      projectName: ternary(res?.length, res, [
        { label: 'No projects available', disabled: true },
      ]),
    }))
  }, [open])

  useEffect(() => {
    if (values?.client) {
      dispatch(getClientAccessProjectList(values?.client))
    }
  }, [values?.client])

  const actions = useCallback(
    () => (
      <Stack direction="row" gap={2}>
        <DKTButton>Save</DKTButton>
      </Stack>
    ),
    [],
  )

  const handleOpenCloseProjectAccessModal = useCallback(
    (status) => setOpen(status),
    [],
  )

  const handleOpenCloseDeleteNotification = useCallback(
    (status) => setDeleteNotificationOpen(status),
    [],
  )

  const handleAddEditProjectAccessModalOpen = useCallback(
    (id, action = 'add') => {
      handleOpenCloseProjectAccessModal(true)

      if (equal(action, 'edit')) {
        const editData = projectAccessList?.find((item) => equal(item?.id, id))
        setProjectAccessEdit(editData)
      }
    },
    [handleOpenCloseProjectAccessModal, projectAccessList],
  )

  const handleGetProjectAssociatedClients = useCallback(
    (projectId) => {
      setTimeout(() => {
        if (tableRef.current) {
          scrollToElement(tableRef.current)
        }
      }, 100)
      dispatch(getAssociatedClientsWithProjects(projectId))
    },
    [dispatch, tableRef],
  )

  const customBodyRender = useCallback(
    (value, tableMeta) => (
      <DKTButton
        variant="text"
        onClick={() => {
          ternary(
            equal(tableMeta?.columnData?.name, 'associatedClients')
              ? handleGetProjectAssociatedClients(tableMeta?.rowData[0])
              : handleAddEditProjectAccessModalOpen(
                  tableMeta?.rowData[0],
                  'edit',
                ),
          )
        }}
        sx={{
          textAlign: 'left',
          paddingLeft: '0px !important',
          marginLeft: '0px',
          justifyContent: 'start',
        }}
      >
        {value}
      </DKTButton>
    ),
    [handleGetProjectAssociatedClients, handleAddEditProjectAccessModalOpen],
  )

  const customBodyRenderForClientAssociatedTable = useCallback(
    (value, tableMeta) => {
      switch (tableMeta?.columnData?.name) {
        case 'projectName':
          return (
            <Typography
              sx={{
                textAlign: 'left',
                paddingLeft: '0px !important',
                marginLeft: '0px',
              }}
            >
              {associatedClientsWithProjects?.[tableMeta?.rowIndex]?.project
                ?.projectName || emptyTableCellPlaceholder}
            </Typography>
          )
        case 'clientCompany':
          return (
            associatedClientsWithProjects?.[tableMeta?.rowIndex]?.company
              ?.companyName || emptyTableCellPlaceholder
          )
        case 'city':
          return (
            associatedClientsWithProjects?.[tableMeta?.rowIndex]?.company
              ?.city || emptyTableCellPlaceholder
          )
        case 'state':
          return (
            associatedClientsWithProjects?.[tableMeta?.rowIndex]?.company
              ?.state || emptyTableCellPlaceholder
          )
        case 'engagementType':
          return (
            associatedClientsWithProjects?.[tableMeta?.rowIndex]
              ?.engagementType || emptyTableCellPlaceholder
          )
        default:
          return emptyTableCellPlaceholder
      }
    },
    [associatedClientsWithProjects],
  )

  const customToolbarSelect = useCallback(
    (selectedRows, displayData, setSelectedTableRows) => {
      const selectedProject = keys(selectedRows.lookup)
      const selectedProjectIds = selectedProject.map(
        (rowToDelete) => projectAccessList[rowToDelete]?.id,
      )
      setSelectedRows.current = setSelectedTableRows
      const setProjectToRemove = () => {
        projectToRemove.current = selectedProjectIds
      }

      return (
        <Box>
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => {
              setProjectToRemove()
              handleOpenCloseDeleteNotification(true)
            }}
          >
            <Delete color="error" />
          </IconButton>
        </Box>
      )
    },
    [projectAccessList],
  )

  const tableOptionsForProjectAccess = useMemo(
    () => ({
      ...options,
      textLabels: {
        body: {
          noMatch: isLoadingProjectAccessList ? (
            <DKTCircularProgress />
          ) : (
            'Sorry, there is no matching data to display'
          ),
        },
      },
      customToolbarSelect,
      selectableRows: 'multiple',
    }),
    [isLoadingProjectAccessList, customToolbarSelect],
  )

  const tableOptions = useMemo(
    () => ({
      ...options,
      search: false,
      textLabels: {
        body: {
          noMatch: isLoadingAssociatedClientsWithProjects ? (
            <DKTCircularProgress />
          ) : (
            'Sorry, there is no matching data to display'
          ),
        },
      },
    }),
    [isLoadingAssociatedClientsWithProjects],
  )

  const tableColumns = useMemo(
    () =>
      columns?.map((project) =>
        ternary(
          equal(project.name, 'projectName') ||
            equal(project.name, 'engagementType') ||
            equal(project.name, 'associatedClients'),
          { ...project, options: { ...project.options, customBodyRender } },
          project,
        ),
      ),
    [columns, customBodyRender],
  )

  const associatedClientColumns = useMemo(
    () =>
      associatedClientColumnsDesc?.map((project) => ({
        ...project,
        options: {
          ...project.options,
          customBodyRender: customBodyRenderForClientAssociatedTable,
        },
      })),
    [associatedClientColumnsDesc, customBodyRenderForClientAssociatedTable],
  )

  const mapFormFields = useCallback(
    ({ formFields, values, errors, handleChange }) =>
      entries(formFields).map(
        (
          [
            name,
            {
              isRequired,
              isDynamicOptions,
              options,
              defaultValue,
              ...formField
            },
          ],
          index,
        ) => {
          const FormControl = ternary(
            equal(formField.type, 'select'),
            DKTSelect,
            DKTInput,
          )

          return (
            <Grid
              key={index}
              item
              lg={formField.lg ?? 12}
              xs={formField.xs ?? 12}
            >
              <FormControl
                {...formField}
                options={ternary(
                  isDynamicOptions,
                  dynamicOptions[name] || [],
                  options,
                )}
                isRequired={isRequired}
                disabled={formField?.disabled}
                id={name}
                name={name}
                value={(values && values[name]) || defaultValue || ''}
                onChange={handleChange}
                error={errors[name]}
              />
            </Grid>
          )
        },
      ),
    [dynamicOptions],
  )

  const handleDeleteTableRow = useCallback(() => {
    const payload = {
      projects: projectToRemove?.current,
    }

    dispatch(clientAccessProjectDelete({ payload, companyId: values?.client }))
    handleOpenCloseDeleteNotification(false)
    setSelectedRows.current([])
  }, [projectToRemove, dispatch, values?.client])

  const deleteNotificationAction = useMemo(
    () => (
      <>
        <DKTButton
          variant="contained"
          disableElevation
          onClick={() => handleOpenCloseDeleteNotification(false)}
        >
          No
        </DKTButton>
        <DKTButton variant="outlined" onClick={handleDeleteTableRow}>
          Yes
        </DKTButton>
      </>
    ),
    [handleDeleteTableRow],
  )

  return (
    <InnerLayout
      contentTitle="Client Access"
      actionTitle={actionTitles.ClientAccess}
      actions={actions}
    >
      <Grid container spacing={2} alignItems="end" mt={6}>
        {mapFormFields({
          formFields: formFields?.clientName,
          values,
          errors,
          handleChange,
        })}
        <Grid item xs={9}>
          <DKTButton
            variant="text"
            color="primary"
            sx={{ textTransform: 'initial', padding: '6px 12px' }}
            disabled={!values?.client}
            startIcon={<CircledPlusIcon disabled={!values?.client} />}
            onClick={handleAddEditProjectAccessModalOpen}
          >
            <Typography>Grant Project Access </Typography>
          </DKTButton>
        </Grid>
      </Grid>
      {values?.client && (
        <Box className={classes.projectList}>
          <DKTMuiDataTable
            data={projectAccessList || [{}]}
            options={tableOptionsForProjectAccess}
            columns={tableColumns}
            className={`${classes.reportListTable}`}
          />
        </Box>
      )}
      <Box ref={tableRef}>
        {(isLoadingAssociatedClientsWithProjects ||
          !!associatedClientsWithProjects?.length) && (
          <>
            <Stack backgroundColor="#C4C4C4" p={2.3} mt={9}>
              <Typography variant="body2" fontWeight="500">
                Associated Clients
              </Typography>
            </Stack>
            <DKTMuiDataTable
              data={associatedClientsWithProjects || [{}]}
              options={tableOptions}
              columns={associatedClientColumns}
              className={classes.reportListTable}
            />
          </>
        )}
      </Box>
      <ProjectAccessModal
        open={open}
        handleClose={() => handleOpenCloseProjectAccessModal(false)}
        projectAccessEdit={projectAccessEdit}
        setProjectAccessEdit={setProjectAccessEdit}
        mapFormFields={mapFormFields}
        client={values?.client}
        projectList={projectList}
      />
      <DKTDialog
        open={deleteNotificationOpen}
        handleClose={() => handleOpenCloseDeleteNotification(false)}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
    </InnerLayout>
  )
}

export default ClientAccess
