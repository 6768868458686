export const CREATE_PROJECT_DIRECTORY_REQUESTED =
  'CREATE_PROJECT_DIRECTORY_REQUESTED'
export const CREATE_PROJECT_DIRECTORY_SUCCESS =
  'CREATE_PROJECT_DIRECTORY_SUCCESS'
export const CREATE_PROJECT_DIRECTORY_FAILED = 'CREATE_PROJECT_DIRECTORY_FAILED'

export const FETCH_PROJECT_DIRECTORY_REQUESTED =
  'FETCH_PROJECT_DIRECTORY_REQUESTED'
export const FETCH_PROJECT_DIRECTORY_SUCCESS = 'FETCH_PROJECT_DIRECTORY_SUCCESS'
export const FETCH_PROJECT_DIRECTORY_FAILED = 'FETCH_PROJECT_DIRECTORY_FAILED'

export const FETCH_PROJECT_DIRECTORY_LIST_REQUESTED =
  'FETCH_PROJECT_DIRECTORY_LIST_REQUESTED'
export const FETCH_PROJECT_DIRECTORY_LIST_SUCCESS =
  'FETCH_PROJECT_DIRECTORY_LIST_SUCCESS'
export const FETCH_PROJECT_DIRECTORY_LIST_FAILED =
  'FETCH_PROJECT_DIRECTORY_LIST_FAILED'

export const SAVE_PROJECT_DIRECTORY_REQUESTED =
  'SAVE_PROJECT_DIRECTORY_REQUESTED'
export const SAVE_PROJECT_DIRECTORY_SUCCESS = 'SAVE_PROJECT_DIRECTORY_SUCCESS'
export const SAVE_PROJECT_DIRECTORY_FAILED = 'SAVE_PROJECT_DIRECTORY_FAILED'

export const ADD_PROJECT_DIRECTORY_SUCCESS = 'ADD_PROJECT_DIRECTORY_SUCCESS'

export const SET_PROJECT_DIRECTORY_ID = 'SET_PROJECT_DIRECTORY_ID'

export const FETCH_PROJECT_DIRECTORY_MAPPING_REQUESTED =
  'FETCH_PROJECT_DIRECTORY_MAPPING_REQUESTED'
export const FETCH_PROJECT_DIRECTORY_MAPPING_SUCCESS =
  'FETCH_PROJECT_DIRECTORY_MAPPING_SUCCESS'
export const FETCH_PROJECT_DIRECTORY_MAPPING_FAILED =
  'FETCH_PROJECT_DIRECTORY_MAPPING_FAILED'

export const SAVE_CONTACT_TYPE_REQUESTED = 'SAVE_CONTACT_TYPE_REQUESTED'
export const SAVE_CONTACT_TYPE_SUCCESS = 'SAVE_CONTACT_TYPE_SUCCESS'
export const SAVE_CONTACT_TYPE_FAILED = 'SAVE_CONTACT_TYPE_FAILED'

export const SEARCH_COMPANY_REQUESTED = 'SEARCH_COMPANY_REQUESTED'
export const SEARCH_COMPANY_SUCCESS = 'SEARCH_COMPANY_SUCCESS'
export const SEARCH_COMPANY_FAILED = 'SEARCH_COMPANY_FAILED'

export const SORT_CONTACT_TYPE_SUCCESS = 'SORT_CONTACT_TYPE_SUCCESS'

// Delete project directory
export const DELETE_PROJECT_DIRECTORY_REQUESTED =
  'DELETE_PROJECT_DIRECTORY_REQUESTED'
export const DELETE_PROJECT_DIRECTORY_SUCCESS =
  'DELETE_PROJECT_DIRECTORY_SUCCESS'
export const DELETE_PROJECT_DIRECTORY_FAILED = 'DELETE_PROJECT_DIRECTORY_FAILED'

export const RESET_CURRENT_PROJECT_DIRECTORY = 'RESET_CURRENT_PROJECT_DIRECTORY'

// Update company name of project directory
export const UPDATE_COMPANY_NAME_REQUESTED = 'UPDATE_COMPANY_NAME_REQUESTED'
export const UPDATE_COMPANY_NAME_SUCCESS = 'UPDATE_COMPANY_NAME_SUCCESS'
export const UPDATE_COMPANY_NAME_FAILED = 'UPDATE_COMPANY_NAME_FAILED'

// Delete project directory contact
export const DELETE_PROJECT_DIRECTORY_CONTACT =
  'DELETE_PROJECT_DIRECTORY_CONTACT'

// clear project directory
export const CLEAR_PROJECT_DIRECTORY = 'CLEAR_PROJECT_DIRECTORY'
