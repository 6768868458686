import {
  FETCH_REPORT_TYPE_FAILED,
  FETCH_REPORT_TYPE_REQUESTED,
  FETCH_REPORT_TYPE_SUCCESS,
} from '../constants/reportType'

const initialState = {
  reportTypeList: [],
  isLoadingReportType: false,
  reportTypeError: null,
}

export const reportTypeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_REPORT_TYPE_REQUESTED:
      return {
        ...state,
        isLoadingReportType: true,
      }
    case FETCH_REPORT_TYPE_SUCCESS:
      return {
        ...state,
        reportTypeList: action.payload,
        isLoadingReportType: false,
      }
    case FETCH_REPORT_TYPE_FAILED:
      return {
        ...state,
        isLoadingReportType: false,
        reportTypeError: action.payload,
      }
    default:
      return state
  }
}
