import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DKTButton from '../../../Shared/DKTButton'
import { equal, ternary } from '../../../Utils/javascript'
import { useStyles } from '../../../Styles/projectSetting.style'
import { clientPortal } from '../../../Utils/constant'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import {
  fetchProjectLimitation,
  saveProjectLimitations,
} from '../../../Redux/actions/projectSettingLimitation'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import DKTEditor from '../../../Shared/DKTEditor'

const ProjectSettingLimitation = () => {
  const [limitations, setLimitations] = useState('')
  const [isUnsavedData, setIsUnsavedData] = useState(false)

  const { portalType } = useSelector(({ auth }) => auth)
  const {
    isFetchingProjectLimitation,
    projectLimitation,
    isSaveProjectLimitation,
  } = useSelector(({ projectLimitation }) => projectLimitation)
  const classes = useStyles()
  const { slug } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (slug) {
      dispatch(fetchProjectLimitation({ projectId: slug }))
    }
  }, [dispatch, slug])

  useEffect(() => {
    setLimitations(projectLimitation)
  }, [projectLimitation])

  useEffect(() => {
    if (limitations && projectLimitation) {
      setIsUnsavedData(
        ternary(!equal(limitations, projectLimitation), true, false),
      )
    }
  }, [limitations])

  const handleSave = () => {
    if (limitations) {
      setIsUnsavedData(false)
      dispatch(saveProjectLimitations(slug, limitations))
    } else {
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={9}>
          <div className={classes.limitationTextarea}>
            <Grid item xs={12}>
              <DKTEditor
                value={limitations || ''}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  setLimitations(data)
                }}
                className={classes.limitation}
                disabled={equal(portalType, clientPortal)}
              />
            </Grid>
          </div>
        </Grid>
      </Grid>
      {!equal(portalType, clientPortal) && (
        <DKTButton
          sx={{ m: 2, marginLeft: 0 }}
          onClick={handleSave}
          variant="outlined"
          disabled={isFetchingProjectLimitation || isSaveProjectLimitation}
        >
          {ternary(isSaveProjectLimitation, 'Saving...', 'Save')}
        </DKTButton>
      )}
      {/* show modal when tries to navigate without save data */}
      <DKTReactRouterPrompt isDirty={isUnsavedData} onSave={handleSave} />
    </>
  )
}

export default ProjectSettingLimitation
