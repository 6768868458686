import {
  CLEAR_REPORT_CHOICES,
  FETCH_REPORT_CHOICES_FAILED,
  FETCH_REPORT_CHOICES_REQUESTED,
  FETCH_REPORT_CHOICES_SUCCESS,
} from '../constants/reportChoices'

const initialState = {
  choices: null,
  isLoadingChoices: false,
  choicesError: null,
}

export const reportChoicesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_REPORT_CHOICES_REQUESTED:
      return {
        ...state,
        isLoadingChoices: true,
      }
    case FETCH_REPORT_CHOICES_SUCCESS:
      return {
        ...state,
        choices: { ...state.choices, ...action.payload },
        isLoadingChoices: false,
      }
    case FETCH_REPORT_CHOICES_FAILED:
      return {
        ...state,
        isLoadingChoices: false,
        choicesError: action.payload,
      }
    case CLEAR_REPORT_CHOICES:
      return initialState
    default:
      return state
  }
}
